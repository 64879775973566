import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div id=\"editor\" class=\"mx-auto w-full h-screen\" style=\"max-height:700px;\"></div>\n{{yield (hash saveImage=this.saveImage restoreImage=this.doRestoreState)}}", {"contents":"<div id=\"editor\" class=\"mx-auto w-full h-screen\" style=\"max-height:700px;\"></div>\n{{yield (hash saveImage=this.saveImage restoreImage=this.doRestoreState)}}","moduleName":"weldnote/components/schematic-image-editor.hbs","parseOptions":{"srcName":"weldnote/components/schematic-image-editor.hbs"}});
import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Component from '@ember/component';
import { isEmpty } from '@ember/utils';
import English from 'weldnote/src-weldnote/photoeditor/languages/english';
import Portuguese from 'weldnote/src-weldnote/photoeditor/languages/portuguese';
import { get, set, action } from '@ember/object';
import { hash } from 'rsvp';

const events = PhotoEditorSDK.UI.DesktopUI.Events;
const renderTypes = PhotoEditorSDK.RenderType;

const textColorPallete = [
  new PhotoEditorSDK.Color(255, 255, 255, 1.0),
  new PhotoEditorSDK.Color(255, 0, 0, 1.0),
  new PhotoEditorSDK.Color(0, 255, 0, 1.0),
  new PhotoEditorSDK.Color(0, 0, 255, 1.0),
  new PhotoEditorSDK.Color(0, 0, 0, 1.0),
];

@classic
export default class SchematicImageEditor extends Component {
  @service
  loading;

  @service
  ajax;

  @service
  intl;

  language = 'en';

  categories = ['arrows', 'dimensions', 'backing'];

  enableSnappingPosition = false;

  editorInstance = null;

  onSave() {
    // default empty
  }

  init() {
    super.init(...arguments);
    set(this, 'arrows', [
      'arrowleft',
      'arrowright',
      'arrowshorizontal',
      'arrowdown',
      'arrowup',
      'arrowsvertical',
    ]);
    set(this, 'dimensions', [
      'anglehalfleft',
      'anglehalfright',
      'arrowlinedown',
      'arrowlineleft',
      'arrowlineright',
      'arrowlineup',
      'dimensiondiagonalinside',
      'dimensiondiagonaloutside',
      'dimensionhorizontalinside',
      'dimensionhorizontaloutside',
    ]);
    set(this, 'base-plates-offcenter', [
      'double-J-groove-right-down',
      'double-J-groove-right-up',
      'double-V-groove-right-down',
      'double-V-groove-right-up',
      'V-double-groove-right-down',
      'V-double-groove-right-up',
    ]);
    set(this, 'base-plates-standard', [
      'double-J-groove-right-center',
      'double-V-groove-right-center',
      'J-groove-right',
      'square-groove-right',
      'V-double-groove-right-center',
      'V-double-groove',
      'V-groove-right',
    ]);
    set(this, 'backing', ['circle', 'rectangle']);
    set(this, 'passes', [
      'welding-pass-fillet-concave',
      'welding-pass-fillet-convex',
      'welding-pass-normal-3P',
      'welding-pass-normal-6P',
      'welding-pass-normal-10P',
      'welding-pass-normal',
      'welding-pass-thin',
      'welding-pass-wide',
    ]);
  }

  getCategoryIcons(category) {
    let categoryElements = get(this, category);
    let { intl } = this;
    return {
      identifier: category,
      defaultName: intl.t(`components.schematic-image-editor.categories.${category}`),
      metaData: {
        backgroundImage: `stickers/${category}/background.png`,
      },

      stickers: categoryElements.map((element) => {
        return {
          identifier: `${element}`,
          defaultName: `${element}`,
          resizeMode: 'unrestricted',
          tintMode: 'solid',
          images: {
            mediaThumb: {
              uris: [`stickers/${category}/stickers/${element}_thumb.png`],
              width: 50,
              height: 50,
            },

            mediaBase: {
              uris: [`stickers/${category}/stickers/${element}.png`],
              width: 400,
              height: 400,
            },
          },
        };
      }),
    };
  }

  loadCategories() {
    let { categories } = this;
    let result = [];
    categories.forEach((category) => {
      result.pushObject(this.getCategoryIcons(category));
    });
    return result;
  }

  getLanguage() {
    if (this.language === 'en') {
      return English;
    } else if (this.language === 'pt' || this.language === 'br') {
      return Portuguese;
    }
    return English;
  }

  loadEditor(image) {
    let container = document.getElementById('editor');
    let editor = new PhotoEditorSDK.UI.DesktopUI({
      container,
      license:
        '{"api_token":"-A3BpLqyV_OceWs0kxSa9w","app_identifiers":["*.weldnote.com","localhost","*.indusuite.com","127.0.0.1"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":false,"expires_at":null,"features":["camera","library","export","customassets","whitelabel","adjustment","sticker","text"],"issued_at":1651671840,"minimum_sdk_version":"1.0","owner":"ESAB, Lda.","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"kXG4KSJ0RIjpOKGHLSFHMg+voCHBLTzhoMuq4r9Pvq89SLEqwPI80uHdUgD8/AcLKFDVQ3efx6ZQVaU9nE4DBbfrwNKWDZeZ9puI8nIpHOz+zGJkrAWD4XDYCLPqlikBIU8cxdcqeS23VC1k6M+oy9J+B14tOxTty61JHPeVEc9C76tjuuDGa5PTuH1QDGdRx8cBpMud/xeGdjqenehzKWI8+9Gh/eZjpnrKYPo6dqhnYCJxY+gIJBWu04z8gLl/Fyi13QyrHKcuKkWixrgdGuzhbI265rqviQw6Al5otxohAnWvDsnYEl/xt7RBn8OoX8EHIXZALu8EQn1r27PfhWT0x+2Wnbg2UHQZZoGFYFPvVU0yv1bV+s8Q6S4RMyJL/IHJFHgin2qw/4r75TECERPL9UKJMfK54hzH2/EUv8zS9+nCcMM39YA5wwiQdjW/GoY+A2Qr7t9jYX1cXe3cjUhowVE8Ci9yO3yaHhrxVU+OicKLqRnjcPab3PKQDFdVrXDd6an5pB0mDKFaNvC6SqB9xHr2/YOuHXTdjz5/BPU5Pid/M5jNj3LeSzoMkvvdqltqLgOsnI6ZYIqGRRKwj/wtk9bpzsXbEKBapRnNgMrwq5MvwXp339VOLCX98+00p7Pel2Dn1AfrjLclknjrX1ivRowo/LpopYtZLQzSwYc="}',

      editor: {
        image,
        enableExport: false,
        tools: ['sticker', 'text', 'adjustments'],
        defaultControl: 'adjustments',
        export: {
          type: renderTypes.DATAURL,
          format: 'image/png',
          download: false,
        },

        controlsOptions: {
          sticker: {
            iconPath: 'stickers/icon/icon.png',
            activeIconPath: 'stickers/icon/icon-active.png',
            categories: this.loadCategories(),
            replaceCategories: true, // `categories` replaces all other categories / stickers,
            customUploadDisabled: true,
          },

          text: {
            colors: textColorPallete,
            backgroundColors: [],
          },
        },

        enablePositionSnapping: this.enableSnappingPosition,
        snappingOptions: {
          position: {
            threshold: 10,
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },

          rotation: {
            angles: [0, 90, 180, 270],
          },
        },
      },

      assets: {
        // This should be the absolute path to your `assets` directory
        baseUrl: '/assets/photoeditor',
      },

      extensions: {
        languages: {
          en: this.getLanguage(),
        },
      },

      language: 'en',
    });
    if (!editor) {
      window.alert(
        'Sorry, there was a problem loading the Schematic Editor, please refresh the page and try again'
      );
    }
    this.editorInstance = editor;
    this.restoreState(editor);
  }

  restoreState(editor) {
    let { loading, state } = this;
    if (!isEmpty(state)) {
      // Shouldn't need this, but haven't figured out where sometimes
      // the state comes transformed as string and sometimes as JSON Object
      let jsonState = typeof state === 'string' ? JSON.parse(state) : state;
      editor.on(events.EDITOR_READY, () => {
        this.editorInstance
          .deserialize(jsonState)
          .then(() => {
            loading.unblock();
          })
          .catch(() => {
            loading.unblock();
          });
      });
    } else {
      loading.unblock();
    }
  }

  didInsertElement() {
    super.didInsertElement(...arguments);

    let { loading, imagePath } = this;
    let image = new Image();
    loading.block();
    image.onload = () => {
      this.loadEditor(image);
    };
    image.onerror = () => {
      window.alert(this.intl.t('components.schematic-image-editor.messages.error-loading'));
      loading.unblock();
    };
    // Required because the S3 Images have cross-origin headers
    image.crossOrigin = 'anonymous';
    image.src = imagePath;
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    if (this.editorInstance) {
      this.editorInstance.dispose();
    }
  }

  @action
  saveImage() {
    let { editorInstance } = this;
    hash({
      state: editorInstance.serialize({ image: false }),
      image: editorInstance.export(false),
    }).then(({ state, image }) => {
      this.onSave({
        state,
        image: image.split(',')[1],
      });
    });
  }

  @action
  doRestoreState(state) {
    this.restoreState(state);
  }
}
