import Model, { hasMany, attr, AsyncHasMany } from '@ember-data/model';
import Permission from './permission';

export default class Role extends Model {
  @attr('string')
  declare name?: string;

  @hasMany('permission')
  declare permissions: AsyncHasMany<Permission>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    role: Role;
  }
}
