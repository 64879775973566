import { service } from '@ember/service';
import EditRoute from 'weldnote/routes/_base/edit';

export default class FitterRoute extends EditRoute {
  @service
  store;

  modelName = 'fitter';

  listRoute = 'fitters';

  model({ id }) {
    return this.store.findRecord('fitter', id, {
      include: 'company,fitter-report,person',
    });
  }
}
