import { action } from '@ember/object';
import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class NewController extends Controller {
  @service
  router;

  queryParams = ['component'];

  @action
  goToModel(weld) {
    this.router.transitionTo('project.welds.weld', weld, {
      queryParams: {
        section: 'welds',
      },
    });
  }
}
