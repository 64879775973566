import { get } from '@ember/object';
import { belongsTo, attr } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import FluxSpecification from './flux-specification';
import IndustryCode from './industry-code';

export default class Flux extends BaseModel {
  @belongsTo('flux-specification', { async: false })
  declare specification: FluxSpecification;

  @attr('string')
  declare flux?: string;

  @belongsTo('industry-code')
  declare code: IndustryCode;

  @attr('boolean')
  declare system?: boolean;

  get visualLabel(): string {
    let specification: string = '';
    if (this.specification && !isEmpty(get(this.specification, 'id'))) {
      specification = get(this.specification, 'visualLabel');
    }
    if (isEmpty(specification)) {
      specification = '';
    }

    let { flux } = this;
    if (isEmpty(flux)) {
      flux = '';
    }

    let result = `${specification} - ${flux}`;
    return result;
  }

  validations = {
    code: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        }
      },
    },
    specification: { custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      }, },
    flux: { presence: true },
  };

  metadata = {
    modelName: 'flux',
    code: {
      required: true,
    },

    specification: {
      required: true,
    },

    flux: {
      required: true,
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    flux: Flux;
  }
}
