import { attr, hasMany, SyncHasMany } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import TestStage from './test-stage';

export default class TestInspectionClass extends BaseModel {
  @attr('string')
  declare name?: string;

  @attr('string')
  declare description?: string;

  @hasMany('test-stage', { async: false })
  declare stages: SyncHasMany<TestStage>;

  get visualLabel() {
    return this.name;
  }

  validations = {
    name: {
      presence: {
        message(_key: string, _value: any, model: any): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'test-inspection-class',
    name: {
      required: true,
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'test-inspection-class': TestInspectionClass;
  }
}
