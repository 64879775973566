import Model, { attr } from '@ember-data/model';

export default class Feature extends Model {
  @attr('string')
  declare name: string;

  @attr('string')
  declare description?: string;

  @attr('boolean')
  declare activeForAll: boolean;

  get visualLabel(): string {
    return this.name || '';
  }

  metadata = {
    modelName: 'feature',
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    feature: Feature;
  }
}
