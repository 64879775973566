import Controller from '@ember/controller';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { set } from '@ember/object';
import Constants from 'weldnote/utils/constants';

const { ARCHIVAL_STATE } = Constants;

export default class WeldingBooksArchivedController extends Controller {
  @service
  intl;

  @service('weldnote-data')
  data;

  page = 1;

  get weldingBooks() {
    return this.model.list;
  }

  get weldingBookCount() {
    return this.model.count;
  }

  get gridColumns() {
    let { intl } = this;
    let designationLabel = intl.t('model.welding-book.designation.label');
    let projectLabel = intl.t('model.welding-book.weldingProject.label');
    let customerLabel = intl.t('model.welding-book.customer.label');
    let supplierLabel = intl.t('model.welding-book.supplier.label');
    let standardLabel = intl.t('model.welding-book.standard.label');
    let componentLabel = intl.t('model.welding-book.component.label');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'designation',
        label: designationLabel,
        cellComponent: 'weldnote-grid/welding-book-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
        width: '250px',
      },
      {
        valuePath: 'projectName',
        cellComponent: 'weldnote-grid/project-link-from-welding-book',
        label: projectLabel,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'customer',
        cellComponent: 'weldnote-grid/project-client-link',
        label: customerLabel,
        breakpoints: ['tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'supplier',
        label: supplierLabel,
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'standard',
        label: standardLabel,
        breakpoints: ['desktop'],
        sortable: false,
      },
      {
        valuePath: 'componentName',
        label: componentLabel,
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
    ];
  }

  loadData = task(async (pageNumber = 1) => {
    let results = await this.data.listAllProjects(
      { page: pageNumber },
      {
        archivalState: ARCHIVAL_STATE.ARCHIVED,
      }
    );
    set(this, 'model', results);
  });
}
