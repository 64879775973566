import classic from 'ember-classic-decorator';
import { set, action } from '@ember/object';
import EditController from 'weldnote/controllers/_base/edit';
import { service } from '@ember/service';

@classic
export default class WeldingBookController extends EditController {
  @service
  router;

  queryParams = ['section', 'weld'];

  section = 'details';

  weld = null;

  @action
  revisionCreated(book) {
    this.router.transitionTo('welding-book', book);
  }

  @action
  goToRevision(book) {
    this.router.transitionTo('welding-book', book, { queryParams: { section: 'revision' } });
  }

  @action
  onEditWeld(weld) {
    set(this, 'weld', weld);
  }
}
