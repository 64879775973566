import { attr, belongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import NonDestructiveTesting from './non-destructive-testing';
import { WeldTypeGroup } from '../config/types/weld-type-group';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

export default class TestStageTest extends BaseModel {
  @attr('number')
  declare percentage?: number;

  @belongsTo('non-destructive-testing', { async: false })
  declare typeOfTest: NonDestructiveTesting;

  @belongsTo('test-stage', { async: false })
  declare testStage: TestStageTest;

  @attr('string', { defaultValue: WeldTypeGroup.ALL })
  declare weldTypeGroup: WeldTypeGroup;

  get visualLabel() {
    return `${this.typeOfTest.shortDesignation} - ${this.percentage}`;
  }

  validations = {
    percentage: {
      presence: {
        message(_key: string, _value: any, model: any): string {
          return model.translate('generic.error.input-value');
        },
      },

      numericality: {
        allowBlank: false,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 100,
      },
    },

    typeOfTest: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'test-stage-test',
    percentage: {
      required: true,
    },

    typeOfTest: {
      required: true,
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'test-stage-test': TestStageTest;
  }
}
