import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class ProjectClient extends BaseModel {
  @attr('string')
  declare name?: string;

  get visualLabel(): string {
    return this.name || '';
  }

  validations = {
    name: {
      presence: {
        message(_key: string, _value: string, model: ProjectClient): string {
          return model.intl.t('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'project-client',
    name: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-client': ProjectClient;
  }
}
