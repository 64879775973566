import EditRoute from 'weldnote/routes/_base/edit';
import { service } from '@ember/service';

export default class WeldingMapRoute extends EditRoute {
  @service
  store;

  modelName = 'welding-map';

  listRoute = 'welding-maps';

  model({ id }) {
    return this.store.findRecord('welding-map', id, {
      include:
        'piping-pieces,plate-pieces,company,pqr-standard,pqr-examining-body,pqr-weld-type,pqr-welding-process-root,pqr-welding-process-fill,pqr-welding-details,pqr-base-material1-group,pqr-base-material2-group,pqr-filler-material-root,pqr-filler-material-fill,pqr-filler-material-group-root,pqr-filler-material-group-fill,certificate-standard,certificate-examining-body,certificate-weld-type,certificate-welding-process-root,certificate-welding-process-fill,certificate-welding-details,certificate-base-material1-group,certificate-base-material2-group,certificate-filler-material-group-root,certificate-filler-material-group-fill,certificate-filler-material-group-cap,certificate-welding-position-root,certificate-welding-position-fill,certificate-welders,wps-standard,wps-weld-type,wps-welding-process-root,wps-welding-process-fill,wps-welding-details,wps-base-material1-group,wps-base-material2-group,wps-filler-material-root,wps-filler-material-fill,wps-filler-material-group-root,wps-filler-material-group-fill,wps-welding-procedure-specifications,pqr-welding-process-cap,pqr-filler-material-cap,certificate-welding-process-cap,certificate-welding-position-cap,wps-welding-process-cap,wps-filler-material-cap,pqr-special-requirements,wps-special-requirements,certificate-special-requirements,pqr-welding-position-root,pqr-welding-position-fill,pqr-welding-position-cap',
    });
  }
}
