import WpsValidationType from 'weldnote/src-weldnote/types/wps/wps-validation';

export default class NullWpsValidation implements WpsValidationType {
  get fillerMaterialRootInvalidMessage(): string {
    return '';
  }

  fillerMaterialGuideCapInvalidMessage: any;

  isFillerMaterialGuideCapValid: any;

  fillerMaterialGuideFillInvalidMessage: any;

  isFillerMaterialGuideFillValid: any;

  fillerMaterialGuideRootInvalidMessage: any;

  isFillerMaterialGuideRootValid: any;

  fillerMaterialElectrodeFormCapInvalidMessage: any;

  isFillerMaterialElectrodeFormCapValid: any;

  fillerMaterialElectrodeFormFillInvalidMessage: any;

  isFillerMaterialElectrodeFormFillValid: any;

  fillerMaterialElectrodeFormRootInvalidMessage: any;

  isFillerMaterialElectrodeFormRootValid: any;

  overlayNumberOfLayerInvalidMessage: any;

  isOverlayNumberOfLayerValid: any;

  impactTestTemperatureInvalidMessage: any;

  isImpactTestTemperatureValid: any;

  hardnessRequirementsInvalidMessage: any;

  isHardnessRequirementsValid: any;

  thicknessCapMaximumInvalidMessage: any;

  isThicknessCapMaximumValid: any;

  thicknessCapMinimumInvalidMessage: any;

  isThicknessCapMinimumValid: any;

  thicknessFillMaximumInvalidMessage: any;

  isThicknessFillMaximumValid: any;

  thicknessFillMinimumInvalidMessage: any;

  isThicknessFillMinimumValid: any;

  thicknessRootMaximumInvalidMessage: any;

  isThicknessRootMaximumValid: any;

  thicknessRootMinimumInvalidMessage: any;

  isThicknessRootMinimumValid: any;

  weldingPositionCapInvalidMessage: any;

  isWeldingPositionCapValid: any;

  weldingPositionFillInvalidMessage: any;

  isWeldingPositionFillValid: any;

  weldingPositionRootInvalidMessage: any;

  isWeldingPositionRootValid: any;

  weldingPositionCapDirectionInvalidMessage: any;

  isWeldingPositionCapDirectionValid: any;

  weldingPositionFillDirectionInvalidMessage: any;

  isWeldingPositionFillDirectionValid: any;

  weldingPositionRootDirectionInvalidMessage: any;

  isWeldingPositionRootDirectionValid: any;

  backingFluxCommercialDesignationInvalidMessage: any;

  isBackingFluxCommercialDesignationValid: any;

  shieldingCapFluxCommercialDesignationInvalidMessage: any;

  isShieldingCapFluxCommercialDesignationValid: any;

  shieldingFillFluxCommercialDesignationInvalidMessage: any;

  isShieldingFillFluxCommercialDesignationValid: any;

  shieldingRootFluxCommercialDesignationInvalidMessage: any;

  isShieldingRootFluxCommercialDesignationValid: any;

  backingFluxInvalidMessage: any;

  isBackingFluxValid: any;

  shieldingCapFluxInvalidMessage: any;

  isShieldingCapFluxValid: any;

  shieldingFillFluxInvalidMessage: any;

  isShieldingFillFluxValid: any;

  shieldingRootFluxInvalidMessage: any;

  isShieldingRootFluxValid: any;

  backingGasInvalidMessage: any;

  isBackingGasValid: any;

  shieldingCapGasInvalidMessage: any;

  isShieldingCapGasValid: any;

  shieldingFillGasInvalidMessage: any;

  isShieldingFillGasValid: any;

  shieldingRootGasInvalidMessage: any;

  isShieldingRootGasValid: any;

  backingInvalidMessage: any;

  isBackingValid: any;

  shieldingCapInvalidMessageForProcess: any;

  isShieldingCapValidForProcess: any;

  shieldingCapInvalidMessage: any;

  isShieldingCapValid: any;

  shieldingFillInvalidMessageForProcess: any;

  isShieldingFillValidForProcess: any;

  shieldingFillInvalidMessage: any;

  isShieldingFillValid: any;

  shieldingRootInvalidMessageForProcess: any;

  isShieldingRootValidForProcess: any;

  shieldingRootInvalidMessage: any;

  isShieldingRootValid: any;

  fillerMaterialCapCommercialDesignationInvalidMessage: any;

  isFillerMaterialCapCommercialDesignationValid: any;

  fillerMaterialFillCommercialDesignationInvalidMessage: any;

  isFillerMaterialFillCommercialDesignationValid: any;

  fillerMaterialRootCommercialDesignationInvalidMessage: any;

  isFillerMaterialRootCommercialDesignationValid: any;

  fillerMaterialCapAWSInvalidMessage: any;

  isFillerMaterialCapAWSValid: any;

  fillerMaterialCapInvalidMessage: any;

  isFillerMaterialCapValid: any;

  fillerMaterialFillAWSInvalidMessage: any;

  isFillerMaterialFillAWSValid: any;

  fillerMaterialFillInvalidMessage: any;

  isFillerMaterialFillValid: any;

  fillerMaterialRootAWSInvalidMessage: any;

  isFillerMaterialRootAWSValid: any;

  isFillerMaterialRootValid: any;

  productTypeInvalidMessage: any;

  isProductTypeValid: any;

  overlayTypeInvalidMessage: any;

  isOverlayTypeValid: any;

  baseMaterial2InvalidMessage: any;

  isBaseMaterial2Valid: any;

  baseMaterial1InvalidMessage: any;

  isBaseMaterial1Valid: any;

  weldTypeInvalidMessage: any;

  isWeldTypeValid: any;

  arcTransferModeCapInvalidMessage: any;

  isArcTransferModeCapValid: any;

  arcTransferModeFillInvalidMessage: any;

  isArcTransferModeFillValid: any;

  arcTransferModeRootInvalidMessage: any;

  isArcTransferModeRootValid: any;

  typeCurrentCapInvalidMessage: any;

  isTypeCurrentCapValid: any;

  typeCurrentFillInvalidMessage: any;

  isTypeCurrentFillValid: any;

  typeCurrentRootInvalidMessage: any;

  isTypeCurrentRootValid: any;

  processMecanizationCapInvalidMessage: any;

  isProcessMecanizationCapValid: any;

  processMecanizationFillInvalidMessage: any;

  isProcessMecanizationFillValid: any;

  processMecanizationRootInvalidMessage: any;

  isProcessMecanizationRootValid: any;

  weldingDetailsInvalidMessage: any;

  isWeldingDetailsValid: any;

  weldingProcessCapInvalidMessage: any;

  isWeldingProcessCapValid: any;

  weldingProcessFillInvalidMessage: any;

  isWeldingProcessFillValid: any;

  weldingProcessRootInvalidMessage: any;

  isWeldingProcessRootValid: any;

  postHeatTimeMaximumPresenceInvalidMessage(_value: any): string {
    return '';
  }

  isPostHeatTimeMaximumPresenceValid(_value: any): boolean {
    return true;
  }

  postHeatTimeMinimumPresenceInvalidMessage(_value: any): string {
    return '';
  }

  isPostHeatTimeMinimumPresenceValid(_value: any): boolean {
    return true;
  }

  postHeatTimeInvalidMessage(_value: any): string {
    return '';
  }

  isPostHeatTimeValid(_value: any): boolean {
    return true;
  }

  baseMaterial2ThicknessInvalidMessage(_value: any): string {
    return '';
  }

  isBaseMaterial2ThicknessValid(_bm2: number): boolean {
    return true;
  }

  baseMaterial1ThicknessInvalidMessage(_value: any): string {
    return '';
  }

  isBaseMaterial1ThicknessValid(_bm1: number): boolean {
    return true;
  }

  numberOfElectrodesCapInvalidMessage: any;

  isNumberElectrodesCapValid: any;

  numberOfElectrodesFillInvalidMessage: any;

  isNumberElectrodesFillValid: any;

  gasNozzleDiameterCapInvalidMessage: any;

  isGasNozzleDiameterCapValid: any;

  gasNozzleDiameterFillInvalidMessage: any;

  isGasNozzleDiameterFillValid: any;

  gasNozzleDiameterRootInvalidMessage: any;

  isGasNozzleDiameterRootValid: any;

  postHeatMaximumPresenceInvalidMessage(_value: any): string {
    return '';
  }

  isPostHeatMaximumPresenceValid(_value: any): boolean {
    return true;
  }

  postHeatMinimumPresenceInvalidMessage(_value: any): string {
    return '';
  }

  isPostHeatMinimumPresenceValid(_value: any): boolean {
    return true;
  }

  postHeatTemperatureInvalidMessage(_value: any): string {
    return '';
  }

  isPostHeatTemperatureValid(_value: any): boolean {
    return true;
  }

  interpassTemperatureMaximumPresenceInvalidMessage(_value: any): string {
    return '';
  }

  isInterpassTemperatureMaximumPresenceValid(_value: any): boolean {
    return true;
  }

  interpassTemperatureMinimumPresenceInvalidMessage(_value: any): string {
    return '';
  }

  isInterpassTemperatureMinimumPresenceValid(_value: any): boolean {
    return true;
  }

  interpassTemperatureInvalidMessage(_value: any): string {
    return '';
  }

  isInterpassTemperatureValid(_value: any) {
    return true;
  }

  preheatMaximumPresenceInvalidMessage(_value: any): string {
    return '';
  }

  isPreheatMaximumPresenceValid(_value: any): boolean {
    return true;
  }

  preheatMinimumPresenceInvalidMessage(_value: any): string {
    return '';
  }

  isPreheatMinimumPresenceValid(_value: any): boolean {
    return true;
  }

  preheatTemperatureInvalidMessage(_value: any): string {
    return '';
  }

  isPreheatTemperatureValid(_value: any): boolean {
    return true;
  }

  pWHTMaximumPresenceInvalidMessage(_value: any): string {
    return '';
  }

  isPWHTMaximumPresenceValid(_value: any): boolean {
    return true;
  }

  pWHTMinimumPresenceInvalidMessage(_value: any): string {
    return '';
  }

  isPWHTMinimumPresenceValid(_value: any): boolean {
    return true;
  }

  pwthTemperatureInvalidMessage(_value: any): string {
    return '';
  }

  isPwhtTemperatureValid(_value: any): boolean {
    return true;
  }

  branchAngleInvalidMessage(_value: any): string {
    return '';
  }

  isBranchAngleValid(_value: any): boolean {
    return true;
  }

  pWHTTimeMaximumPresenceInvalidMessage(_value: any): string {
    return '';
  }

  isPWHTTimeMaximumPresenceValid(_value: any): boolean {
    return true;
  }

  pWHTTimeMinimumPresenceInvalidMessage(_value: any): string {
    return '';
  }

  isPWHTTimeMinimumPresenceValid(_value: any): boolean {
    return true;
  }

  pwthTimeInvalidMessage(_value: any): string {
    return '';
  }

  isPwhtTimeValid(_value: any): boolean {
    return true;
  }

  numberOfElectrodesRootInvalidMessage: any;

  isNumberElectrodesRootValid: any;

  fillerMaterialDiameterCapInvalidMessage: any;

  isFillerMaterialDiameterCapValid: any;

  fillerMaterialDiameterFillInvalidMessage: any;

  isFillerMaterialDiameterFillValid: any;

  fillerMaterialDiameterRootInvalidMessage: any;

  isFillerMaterialDiameterRootValid: any;

  thicknessTotalMinimumInvalidMessage: any;

  isThicknessTotalMinimumValid: any;

  get diameterForPipeInvalidMessage(): any {
    return '';
  }

  isDiameterValidForPipe(_value: any): boolean {
    return false;
  }

  diameterInvalidMessage(_value: any): string {
    return '';
  }

  isDiameterValid(_value: any): boolean {
    return false;
  }

  get thicknessTotalMaximumInvalidMessage(): string {
    return '';
  }

  get isThicknessTotalMaximumValid(): boolean {
    return false;
  }
}
