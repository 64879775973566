import Helper from '@ember/component/helper';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

export function inCollection(vars, params) {
  let { collection, model } = params;

  if (isEmpty(collection) || collection.get('length') === 0) {
    return false;
  }
  if (isEmpty(model)) {
    return false;
  }

  if (collection.findBy('id', get(model, 'id'))) {
    return true;
  }
  return false;
}

export default Helper.helper(inCollection);
