import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import ListController from 'weldnote/controllers/_base/list-js';
import { set, action, computed } from '@ember/object';
import { task } from 'ember-concurrency';

@classic
export default class NonDestructiveTestingsController extends ListController {
  modelName = 'non-destructive-testing';

  currentPage = 1;

  @service('weldnote-data')
  data;

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = this.currentPage;

      let result = await this.data.listAllNonDestructiveTesting(options);
      set(this, 'model', result);
      set(this, 'totalCount', result.meta.records);
    }
  );

  @computed
  get gridColumns() {
    let designationLabel = this.getAttributeLabel('designation');
    let shortDesignationLabel = this.getAttributeLabel('shortDesignation');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'designation',
        label: designationLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/non-destructive-testing-link',
      },
      {
        valuePath: 'shortDesignation',
        label: shortDesignationLabel,
        sortable: false,
      },
    ];
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }
}
