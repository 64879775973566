import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import PostWeldTreatmentMethod from './post-weld-treatment-method';
import FileUpload from './file-upload';

export default class PwhtReportCertificate extends BaseModel {
  @attr('string')
  declare reportNumber?: string;

  @attr('number')
  declare treatmentTime?: number;

  @attr('number')
  declare treatmentTemperature?: number;

  @attr('number')
  declare heatingRate?: number;

  @attr('number')
  declare coolingRate?: number;

  @belongsTo('post-weld-treatment-method')
  declare method: AsyncBelongsTo<PostWeldTreatmentMethod>;

  @belongsTo('file-upload')
  declare certificate: AsyncBelongsTo<FileUpload>;

  @attr('date')
  declare reportDate?: Date;

  get visualLabel(): string {
    return this.reportNumber || '';
  }

  validations = {
    reportNumber: { presence: true },
    treatmentTime: {
      numericality: {
        allowBlank: false,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 999.99,
      },
    },

    treatmentTemperature: {
      numericality: {
        allowBlank: false,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },
    },

    heatingRate: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    coolingRate: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    reportDate: { presence: true },
  };

  metadata = {
    modelName: 'pwht-report-certificate',
    listRoute: 'pwht-report-certificates',
    editRoute: 'pwht-report-certificate',
    reportNumber: { required: true },
    treatmentTime: { required: true },
    treatmentTemperature: { required: true },
    reportDate: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'pwht-report-certificate': PwhtReportCertificate;
  }
}
