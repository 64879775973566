import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class WeldingProcedureSpecificationPwpsToStandardWpsRoute extends Route {
  @service('weldnote-data')
  data;

  @service session;

  @service
  router;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  model({ id }) {
    return this.data.loadAllDataWPS(id);
  }

  afterModel(model) {
    if (!model.isPreliminary) {
      this.router.transitionTo('welding-procedure-specifications');
    }
  }
}
