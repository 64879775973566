import Model, { belongsTo, attr } from '@ember-data/model';
import WeldLayerConfiguration from './weld-layer-configuration';

export default class GrooveDesignWeldLayerCopy extends Model {
  @attr('string')
  declare description?: string;

  @attr('string')
  declare longDescription?: string;

  @belongsTo('weld-layer-configuration', { async: false })
  declare weldLayerConfiguration: WeldLayerConfiguration;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'groove-design-weld-layer-copy': GrooveDesignWeldLayerCopy;
  }
}
