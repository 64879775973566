import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { get, set, action } from '@ember/object';

export default class NewRoute extends Route {
  @service
  store;

  model() {
    return this.store.createRecord('project-component', {
      project: this.modelFor('project'),
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    set(controller, 'project', this.modelFor('project'));
    set(controller, 'component', model);
  }

  @action
  willTransition() {
    let { controller } = this;
    let model = get(controller, 'model');
    if (get(model, 'isNew')) {
      model.destroyRecord();
    }
    return true;
  }
}
