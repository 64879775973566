import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import Constants from 'weldnote/utils/constants';
import BaseMaterialCertificate from './base-material-certificate';
import Project from './project';

const { PROJECT_REFERENCE_TYPE } = Constants;

export default class ProjectBaseMaterialCertificate extends BaseModel {
  @belongsTo('project')
  declare project: AsyncBelongsTo<Project>;

  @belongsTo('base-material-certificate')
  declare certificate: AsyncBelongsTo<BaseMaterialCertificate>;

  @attr('string')
  declare heatNumber?: string;

  @attr('string')
  declare baseMaterialLabel?: string;

  @attr('string')
  declare pdfPath?: string;

  @attr('string')
  declare referenceType?: string;

  get visualLabel() {
    return this.heatNumber;
  }

  validations = {
    project: { presence: true },
    certificate: { presence: true },
  };

  metadata = {
    modelName: 'project-base-material-certificate',
    project: { required: true },
    certificate: { required: true },
  };

  get isManualReference(): boolean {
    return this.referenceType === PROJECT_REFERENCE_TYPE.MANUAL;
  }

  get isAutomaticReference(): boolean {
    return this.referenceType === PROJECT_REFERENCE_TYPE.REFERENCE;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-base-material-certificate': ProjectBaseMaterialCertificate;
  }
}
