import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import BaseMaterial from 'weldnote/models/base-material';
import FillerMaterialCommercialDesignation from 'weldnote/models/filler-material-commercial-designation';
import ProcessMecanization from 'weldnote/models/process-mecanization';
import TypeCurrentPolarity from 'weldnote/models/type-current-polarity';
import WeldType from 'weldnote/models/weld-type';
import WeldingDetail from 'weldnote/models/welding-detail';
import WeldingPosition from 'weldnote/models/welding-position';
import WeldingProcess from 'weldnote/models/welding-process';
import Constants from 'weldnote/utils/constants';
import { PqrApprovalRangeInterface } from './pqr-approval-range-interface';

const { PRODUCT_TYPE } = Constants;

export default class NotSupportedApprovalRanges implements PqrApprovalRangeInterface {
  constructor() {}

  weldType(testPiece: WeldType) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  productType(testPiece: string) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
  }

  weldingProcess(testPiece: WeldingProcess) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  weldingDetails(testPieces: WeldingDetail[] = []) {
    return testPieces;
  }

  processMecanization(testPiece: ProcessMecanization) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  transferMode(testPiece: ArcTransferMode) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  typeCurrentAndPolarity(testPiece: TypeCurrentPolarity) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  diameter(/* testPiece */) {
    return this._emptyRange;
  }

  weldedThickness(/* testPiece */) {
    return this._emptyRange;
  }

  baseMaterialThickness(/* thickness1, thickness2 */) {
    return {
      material1: this._emptyRange,
      material2: this._emptyRange,
    };
  }

  baseMaterial1Thickness() {
    return this._emptyRange;
  }

  baseMaterial2Thickness() {
    return this._emptyRange;
  }

  weldingPosition(testPiece: WeldingPosition /* direction */) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  preHeat() {
    return this._emptyRange;
  }

  postHeat() {
    return this._emptyRange;
  }

  interpass() {
    return this._emptyRange;
  }

  pwhtTemperature() {
    return this._emptyRange;
  }

  pwhtTime() {
    return this._emptyRange;
  }

  get _emptyRange() {
    return {
      min: null,
      max: null,
    };
  }

  thicknessProcess(/* testPiece, layer */) {
    return this._emptyRange;
  }

  baseMaterial(baseMaterial1: BaseMaterial, baseMaterial2: BaseMaterial) {
    if (isEmpty(baseMaterial1) || isEmpty(baseMaterial2)) {
      return {
        material1: [],
        material2: [],
      };
    }

    return {
      material1: [get(baseMaterial1, 'materialGroup')],
      material2: [get(baseMaterial2, 'materialGroup')],
    };
  }

  branchAngle(/* testPiece */) {
    return this._emptyRange;
  }

  fillerMaterialRootCommercialDesignation(designation: FillerMaterialCommercialDesignation) {
    if (isEmpty(designation)) {
      return [];
    }
    return [designation];
  }

  fillerMaterialFillCommercialDesignation(designation: FillerMaterialCommercialDesignation) {
    if (isEmpty(designation)) {
      return [];
    }
    return [designation];
  }

  fillerMaterialCapCommercialDesignation(designation: FillerMaterialCommercialDesignation) {
    if (isEmpty(designation)) {
      return [];
    }
    return [designation];
  }

  throatThickness() {
    return this._emptyRange;
  }

  pjpButtWeldBaseMaterial1Thickness() {
    return this._emptyRange;
  }

  pjpButtWeldBaseMaterial2Thickness() {
    return this._emptyRange;
  }

  baseMaterial1FilletWeldThickness() {
    return this._emptyRange;
  }

  baseMaterial2FilletWeldThickness() {
    return this._emptyRange;
  }

  shielding() {
    return null;
  }
}
