import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { isEmpty } from '@ember/utils';
import { set, get, action, computed } from '@ember/object';
import { t } from 'ember-intl';
import Constants from 'weldnote/utils/constants';
import { task, timeout } from 'ember-concurrency';

const { WELDER_CERTIFICATE_REVALIDATION_DEADLINES, CERTIFICATE_INTERNAL_REVALIDATION_TYPE } =
  Constants;

@classic
export default class TodayController extends Controller {
  queryParams = ['page'];

  page = 1;

  @service
  intl;

  @service
  store;

  @service
  userSession;

  @service('welder-certificate-data')
  certificateData;

  @alias('model.list')
  certificates;

  @t('welder-certificate-expiration.internally-expire-today-title')
  certificateExpirationTitle;

  @computed('certificateData.deadlineOptions.[]')
  get selectedDeadline() {
    let options = get(this, 'certificateData.deadlineOptions');
    return options.findBy('key', WELDER_CERTIFICATE_REVALIDATION_DEADLINES.TODAY);
  }

  reloadDataTask = task(
    {
      drop: true,
    },
    async (searchOptions = {}) => {
      let options = {};
      options.page = this.page;
      if (!isEmpty(searchOptions.company)) {
        options.company = searchOptions.company;
      }
      if (!isEmpty(searchOptions.deadlineTime)) {
        options.deadlineTime = searchOptions.deadlineTime;
      } else {
        options.deadlineTime = 1;
      }

      await timeout(100);

      let results = await this.certificateData.getWelderCertificatesToInternallyValidateCustom(
        options
      );
      set(this, 'model', results);
    }
  );

  @action
  postValidate() {
    this.certificateData.clearCacheAll();
  }

  @action
  validateSingle(
    certificate,
    type = CERTIFICATE_INTERNAL_REVALIDATION_TYPE.REGULAR,
    report1 = '',
    report2 = '',
    weldingDate
  ) {
    if (!isEmpty(certificate)) {
      let validationParameters = {
        type,
      };
      if (!isEmpty(report1)) {
        validationParameters.report1 = report1;
      }
      if (!isEmpty(report2)) {
        validationParameters.report2 = report2;
      }
      if (!isEmpty(weldingDate)) {
        validationParameters.weldingDate = weldingDate;
      }
      return certificate.validateInternallyToday(validationParameters);
    }
  }
}
