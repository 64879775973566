import Transform from '@ember-data/serializer/transform';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'decimal-value': DecimalValue;
  }
}

export type DecimalValue = {
  value: number;
};

export default class DecimalValueTransform extends Transform {
  @service
  userSession: any;

  deserialize(serialized: any): DecimalValue {
    // There's no need to deserialize as the backend will always send decimals with dot a never with a comma
    return serialized;
  }

  serialize(deserialized: any) {
    // This shouldn't have to happen as the app should deal with this, but just in case
    // add this second layer of protection
    let { userSession } = this;
    let separator = get(userSession, 'decimalSeparator');
    if (separator === ',') {
      if (!isEmpty(deserialized)) {
        let noComma = `${deserialized}`.replace(separator, '.');
        return parseFloat(noComma);
      }
    } else {
      if (!isEmpty(deserialized)) {
        return parseFloat(deserialized);
      }
    }
    return deserialized;
  }
}
