import Helper from '@ember/component/helper';
import { isEmpty } from '@ember/utils';
import moment from 'moment';

export function dateBirthAge(params, hash) {
  if (!isEmpty(hash.value)) {
    let momentDate = moment(hash.value);
    let age = moment().diff(momentDate, 'years');
    let dateString = momentDate.format(hash.format);
    let { intl } = hash;
    return `${dateString} - ${age} ${intl.t('helpers.years')}`;
  }
  return '';
}

export default Helper.helper(dateBirthAge);
