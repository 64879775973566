import ApplicationAdapter from './application';
import { service } from '@ember/service';

/**
 * This adapter is here only for the purpose of working around an issue
 * that we couldn't query the account twice and have the second call make a network call
 * we always got the response from cache
 */
export default class AccountAdapter extends ApplicationAdapter {
  // This is called ajaxService because the application adapter already
  // has an ajax function... having an ajax service clashes with the thing
  @service('ajax') ajaxService;

  queryRecord(store, type, query) {
    return this.ajaxService.request(`/api/accounts/${query.id}?include=${query.include}`);
  }
}
