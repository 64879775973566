import { belongsTo, attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class HardnessTestLine extends BaseModel {
  @attr('number')
  declare orderNum?: number;

  @attr('number')
  declare baseMetalHardnessNumber?: number;

  @attr('number')
  declare baseMetalHardnessValue1?: number;

  @attr('number')
  declare baseMetalHardnessValue2?: number;

  @attr('number')
  declare baseMetalHardnessValue3?: number;

  @attr('number')
  declare heatAffectedHardnessNumber?: number;

  @attr('number')
  declare heatAffectedHardnessValue1?: number;

  @attr('number')
  declare heatAffectedHardnessValue2?: number;

  @attr('number')
  declare heatAffectedHardnessValue3?: number;

  @attr('number')
  declare depositedMetalHardnessNumber?: number;

  @attr('number')
  declare depositedMetalHardnessValue1?: number;

  @attr('number')
  declare depositedMetalHardnessValue2?: number;

  @attr('number')
  declare depositedMetalHardnessValue3?: number;

  @attr('number')
  declare heatAffectedZone2HardnessNumber?: number;

  @attr('number')
  declare heatAffectedZone2HardnessValue1?: number;

  @attr('number')
  declare heatAffectedZone2HardnessValue2?: number;

  @attr('number')
  declare heatAffectedZone2HardnessValue3?: number;

  @attr('number')
  declare baseMetal2HardnessNumber?: number;

  @attr('number')
  declare baseMetal2HardnessValue1?: number;

  @attr('number')
  declare baseMetal2HardnessValue2?: number;

  @attr('number')
  declare baseMetal2HardnessValue3?: number;

  @belongsTo('pqr')
  declare pqr: any;

  get visualLabel() {
    return this.baseMetalHardnessNumber;
  }

  validations = {
    pqr: { presence: true },
    baseMetalHardnessNumber: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    baseMetalHardnessValue1: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    baseMetalHardnessValue2: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    baseMetalHardnessValue3: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    heatAffectedHardnessNumber: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    heatAffectedHardnessValue1: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    heatAffectedHardnessValue2: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    heatAffectedHardnessValue3: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    depositedMetalHardnessNumber: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    depositedMetalHardnessValue1: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    depositedMetalHardnessValue2: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    depositedMetalHardnessValue3: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    heatAffectedZone2HardnessNumber: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
        onlyInteger: true,
      },
    },

    heatAffectedZone2HardnessValue1: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
        onlyInteger: true,
      },
    },

    heatAffectedZone2HardnessValue2: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
        onlyInteger: true,
      },
    },

    heatAffectedZone2HardnessValue3: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
        onlyInteger: true,
      },
    },

    baseMetal2HardnessNumber: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
        onlyInteger: true,
      },
    },

    baseMetal2HardnessValue1: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
        onlyInteger: true,
      },
    },

    baseMetal2HardnessValue2: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
        onlyInteger: true,
      },
    },

    baseMetal2HardnessValue3: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
        onlyInteger: true,
      },
    },
  };

  metadata = {
    modelName: 'hardness-test-line',
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'hardness-test-line': HardnessTestLine;
  }
}
