import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ModalDialog\n  @targetAttachment={{this.targetAttachment}}\n  @translucentOverlay={{true}}\n  @onClose={{@onClose}}\n  @wrapperClass={{concat this.wrapperClasses @wrapperClass}}\n  @overlayClass={{concat this.overlayClasses @overlayClass}}\n  @containerClass={{concat this.containerClasses \"fix-width-ie11 \" @containerClass}}\n>\n  <div class=\"shadow-md\" data-test-id={{@data-id}}>\n    <div class=\"ui-panel-dark {{@titleClasses}}\" data-test-title>\n      {{@title}}\n      {{yield (hash title=(component \"blank-template\"))}}\n    </div>\n    <div class=\"{{this.bodyVariantClasses}} {{@bodyClasses}}\" data-test-body>\n      {{yield (hash body=(component \"blank-template\"))}}\n    </div>\n    <div class=\"px-5 py-6 bg-off-white border-t border-gray-400\" data-test-footer>\n      {{yield (hash footer=(component \"blank-template\"))}}\n    </div>\n  </div>\n</ModalDialog>", {"contents":"<ModalDialog\n  @targetAttachment={{this.targetAttachment}}\n  @translucentOverlay={{true}}\n  @onClose={{@onClose}}\n  @wrapperClass={{concat this.wrapperClasses @wrapperClass}}\n  @overlayClass={{concat this.overlayClasses @overlayClass}}\n  @containerClass={{concat this.containerClasses \"fix-width-ie11 \" @containerClass}}\n>\n  <div class=\"shadow-md\" data-test-id={{@data-id}}>\n    <div class=\"ui-panel-dark {{@titleClasses}}\" data-test-title>\n      {{@title}}\n      {{yield (hash title=(component \"blank-template\"))}}\n    </div>\n    <div class=\"{{this.bodyVariantClasses}} {{@bodyClasses}}\" data-test-body>\n      {{yield (hash body=(component \"blank-template\"))}}\n    </div>\n    <div class=\"px-5 py-6 bg-off-white border-t border-gray-400\" data-test-footer>\n      {{yield (hash footer=(component \"blank-template\"))}}\n    </div>\n  </div>\n</ModalDialog>","moduleName":"weldnote/components/ui-modal.hbs","parseOptions":{"srcName":"weldnote/components/ui-modal.hbs"}});
import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { isEmpty } from '@ember/utils';

@classic
export default class UiModal extends Component {
  type = 'regular';

  @computed('type')
  get targetAttachment() {
    if (this.type === 'regular') {
      return 'center';
    } else if (this.type === 'full-width') {
      return 'none';
    }
  }

  @computed('type')
  get containerClasses() {
    if (this.type === 'full-width') {
      return 'centered-scrolling-container ';
    }
    return '';
  }

  @computed('type')
  get wrapperClasses() {
    if (this.type === 'full-width') {
      return 'centered-scrolling-wrapper ';
    }
    return '';
  }

  @computed('type')
  get overlayClasses() {
    if (this.type === 'full-width') {
      return 'centered-scrolling-overlay ';
    }
    return '';
  }

  bodyVariant = 'default';

  @computed('bodyVariant')
  get bodyVariantClasses() {
    if (isEmpty(this.bodyVariant)) {
      return 'px-5 py-6 bg-white';
    }
    if (this.bodyVariant === 'default') {
      return 'px-5 py-6 bg-white';
    }

    return '';
  }
}
