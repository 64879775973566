import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { fromMilimeterSecondToMeterMinute } from 'weldnote/utils/unit-system/speed';
import Constants from 'weldnote/utils/constants';

const { UNIT_SYSTEM } = Constants;

export default Helper.extend({
  userSession: service(),

  compute(params) {
    let unitSystem = get(this, 'userSession.unitSystem');
    let [value] = params;
    if (isEmpty(value)) {
      return '';
    }

    if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
      return value;
    } else if (unitSystem === UNIT_SYSTEM.METRIC) {
      return fromMilimeterSecondToMeterMinute(value);
    }

    return value;
  },
});
