import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class ElectrodeType extends BaseModel {
  @attr('string')
  declare shortDesignation: string;

  @attr('string')
  declare longDesignation?: string;

  @attr('boolean')
  declare system: boolean;

  get visualLabel(): string {
    return this.shortDesignation || '';
  }

  validations = {
    shortDesignation: { presence: true },
    longDesignation: { presence: true },
  };

  metadata = {
    modelName: 'electrode-type',
    shortDesignation: { required: true },
    longDesignation: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'electrode-type': ElectrodeType;
  }
}
