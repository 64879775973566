import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { get } from '@ember/object';
import NewRoute from 'weldnote/routes/_base/new';

@classic
export default class _NewRoute extends NewRoute {
  @service
  userSession;


  @service
  router;

  modelName = 'base-material';

  beforeModel() {
    super.beforeModel(...arguments);
    let session = this.userSession;
    if (!get(session, 'canCreateBaseMaterial')) {
      this.router.transitionTo('base-materials');
    }
  }
}
