import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.newVersionChecker.isNewVersion}}\n  <div class=\"alert alert-info alert-dismissable animated fadeIn new-version-notifier\">\n    <button aria-hidden=\"true\" data-dismiss=\"alert\" class=\"close\" type=\"button\">×</button>\n    {{t \"components.new-version-notifier.message\"}}\n    <button class=\"btn btn-xs btn-primary m-l-md\" type=\"button\" {{on \"click\" this.reload}}><FaIcon @icon=\"refresh\" />\n      {{t \"components.new-version-notifier.reload-btn\"}}</button>\n  </div>\n{{/if}}", {"contents":"{{#if this.newVersionChecker.isNewVersion}}\n  <div class=\"alert alert-info alert-dismissable animated fadeIn new-version-notifier\">\n    <button aria-hidden=\"true\" data-dismiss=\"alert\" class=\"close\" type=\"button\">×</button>\n    {{t \"components.new-version-notifier.message\"}}\n    <button class=\"btn btn-xs btn-primary m-l-md\" type=\"button\" {{on \"click\" this.reload}}><FaIcon @icon=\"refresh\" />\n      {{t \"components.new-version-notifier.reload-btn\"}}</button>\n  </div>\n{{/if}}","moduleName":"weldnote/components/new-version-notifier.hbs","parseOptions":{"srcName":"weldnote/components/new-version-notifier.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import ENV from 'weldnote/config/environment';

const { environment } = ENV;

export default class NewVersionNotifier extends Component {
  @service
  newVersionChecker;

  @action
  reload() {
    window.location.reload();
  }

  constructor() {
    super(...arguments);
    if (environment !== 'test') {
      this.newVersionChecker.updateVersionTask.perform();
    }
  }
}
