import Model, { attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { get } from '@ember/object';
import Staff from './staff';
import Feature from './feature';
import Role from './role';
import Company from './company';
import { isEmpty } from '@ember/utils';
import FileUpload from './file-upload';

export default class Account extends Model {
  @attr('string')
  declare email?: string;

  @attr('string')
  declare name?: string;

  @attr('string')
  declare language?: string;

  @attr('string')
  declare client?: string;

  @attr('boolean')
  declare admin?: boolean;

  @attr('string')
  declare decimalSeparator?: string;

  @hasMany('feature', { async: false })
  declare features: SyncHasMany<Feature>;

  @hasMany('role', { async: false })
  declare roles: SyncHasMany<Role>;

  @belongsTo('staff', { async: false })
  declare staff: Staff;

  @attr('string')
  declare unitSystem?: string;

  @attr('string')
  declare dateFormat?: string;

  @attr('boolean')
  declare accountLocked?: boolean;

  @attr('string')
  declare clientId?: string;

  @attr('string')
  declare daysUntilEndOfTrial?: string;

  @attr('date')
  declare accountValidUntil?: Date;

  @attr('string')
  declare subscriptionType?: string;

  @attr()
  declare subscriptionInfo?: string;

  @attr('boolean')
  declare emailExpiringCertificatesToday?: boolean;

  @attr('boolean')
  declare emailExpiringCertificates30Days?: boolean;

  @attr('string')
  declare subscriptionPlan?: string;

  @hasMany('company', { async: false })
  declare companiesSubscribed: SyncHasMany<Company>;

  @belongsTo('file-upload', { async: false })
  declare signature: FileUpload;

  @attr('string')
  declare renderedSignaturePath?: string;

  @attr('string')
  declare notesRole?: string;

  @attr('string')
  declare assemblyRole?: string;

  hasPermission(permissionId: string): boolean {
    let hasPermission = false;
    this.roles.forEach((role) => {
      if (get(role, 'permissions').isAny('permissionId', permissionId)) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  get hasRoles(): boolean {
    let counter = 0;
    this.roles.forEach((i) => {
      if (i) {
        counter += 1;
      }
    });
    return counter > 0;
  }

  get hasSubscriptionPlan(): boolean {
    return !isEmpty(this.subscriptionPlan);
  }

  get isTrial(): boolean {
    return this.subscriptionType === 'TRIAL';
  }

  get isPaying(): boolean {
    return this.subscriptionType === 'ACTIVE';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    account: Account;
  }
}
