import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import {
  isFilletWeld,
  isButtWeld,
  isOverlayWeld,
  isPartialPenetrationButtWeld,
} from 'weldnote/utils/rules/weld-type';
import { isSingleLayer, isMultiLayer } from 'weldnote/utils/rules/welding-detail';
import { calculateShielding } from 'weldnote/utils/certification-rules';
import { isSMAW, isGMAW, isGTAW, isPAW } from 'weldnote/utils/rules/welding-process';

import Constants from 'weldnote/utils/constants';

const { PRODUCT_TYPE, UNIT_SYSTEM, WELDING_DETAILS, ARC_TRANSFER_MODES, WELD_TYPES } = Constants;

export default class AwsB21ApprovalRanges {
  constructor(
    pqr,
    {
      allWeldTypes,
      allBaseMaterials,
      allWeldingDetails,
      allProcessMecanizations,
      allTypeCurrentPolarity,
      allWeldingPositions,
      allTransferModes,
      unitSystem = UNIT_SYSTEM.METRIC,
    }
  ) {
    this.pqr = pqr;
    let industryCodeId = get(this.standard, 'code.id');

    this.allWeldTypes = allWeldTypes.filter((weldType) => {
      return get(weldType, 'industryCode.id') === industryCodeId;
    });
    this._baseMaterials = allBaseMaterials;
    this.allWeldingDetails = allWeldingDetails;
    this.allProcessMecanizations = allProcessMecanizations;
    this.allTypeCurrentPolarity = allTypeCurrentPolarity;
    this._weldingPositions = allWeldingPositions;
    this._transferModes = allTransferModes;
    this.unitSystem = unitSystem;
    if (unitSystem === null) {
      throw new Error('Unit System is required for AWS B2.1 Approval Ranges');
    }
  }

  get allWeldingPositions() {
    return this._weldingPositions.filter(
      (position) => get(position, 'industryCode.id') === this.industryCodeId
    );
  }

  get allBaseMaterials() {
    return this._baseMaterials.filter(
      (baseMaterial) => get(baseMaterial, 'code.id') === this.industryCodeId
    );
  }

  get allTransferModes() {
    return this._transferModes.filter(
      (transferMode) => get(transferMode, 'industryCode.id') === this.industryCodeId
    );
  }

  get industryCodeId() {
    return get(this.standard, 'code.id');
  }

  get standard() {
    return this.pqr.get('standard');
  }

  get weldTypeTestPiece() {
    return this.pqr.get('weldTypeTestPiece');
  }

  get baseMaterial1ThicknessValue() {
    return this.pqr.get('baseMaterial1Thickness');
  }

  get baseMaterial1() {
    return this.pqr.get('baseMaterial1TestPiece');
  }

  get baseMaterial2ThicknessValue() {
    return this.pqr.get('baseMaterial2Thickness');
  }

  get baseMaterial2() {
    return this.pqr.get('baseMaterial2TestPiece');
  }

  get weldingDetailsValues() {
    return this.pqr.get('weldingDetailsTestPiece');
  }

  get weldingProcessRoot() {
    return this.pqr.get('weldingProcessRootTestPiece');
  }

  get weldingProcessFill() {
    return this.pqr.get('weldingProcessFillTestPiece');
  }

  get weldingProcessCap() {
    return this.pqr.get('weldingProcessCapTestPiece');
  }

  get transferModeRoot() {
    return this.pqr.get('arcTransferModeRootTestPiece');
  }

  get transferModeFill() {
    return this.pqr.get('arcTransferModeFillTestPiece');
  }

  get transferModeCap() {
    return this.pqr.get('arcTransferModeCapTestPiece');
  }

  get impactTemperature() {
    return this.pqr.get('impactTestTemperature');
  }

  get impactRequired() {
    return this.pqr.get('impactTestRequired');
  }

  weldType(testPiece) {
    if (isButtWeld(testPiece)) {
      return this._findWeldTypes([
        WELD_TYPES.BUTT_WELD,
        WELD_TYPES.PARTIAL_PENETRATION_BUTT_WELD,
        WELD_TYPES.FILLET_WELD,
      ]);
    } else if (isPartialPenetrationButtWeld(testPiece)) {
      return this._findWeldTypes([
        WELD_TYPES.PARTIAL_PENETRATION_BUTT_WELD,
        WELD_TYPES.FILLET_WELD,
      ]);
    } else if (isFilletWeld(testPiece)) {
      return this._findWeldTypes([WELD_TYPES.OVERLAY_WELD, WELD_TYPES.FILLET_WELD]);
    } else if (isOverlayWeld(testPiece)) {
      return [testPiece];
    }
    if (!isEmpty(testPiece)) {
      return [testPiece];
    }
    return [];
  }

  _findWeldTypes(types = []) {
    let result = [];
    types.forEach((weldType) => {
      result.push(this.allWeldTypes.findBy('weldType', weldType));
    });
    return result;
  }

  productType(testPiece) {
    if (testPiece === PRODUCT_TYPE.PIPE) {
      return [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
    } else if (testPiece === PRODUCT_TYPE.PLATE) {
      return [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
    }
  }

  weldingProcess(testPiece) {
    if (!isEmpty(testPiece)) {
      return [testPiece];
    }
    return [];
  }

  _isWeldingProcessGMAWShort(layer) {
    let weldingProcess = null;
    let transferMode = null;
    if (layer === 'ROOT') {
      weldingProcess = this.weldingProcessRoot;
      transferMode = this.transferModeRoot;
    } else if (layer === 'FILL') {
      weldingProcess = this.weldingProcessFill;
      transferMode = this.transferModeFill;
    } else if (layer === 'CAP') {
      weldingProcess = this.weldingProcessCap;
      transferMode = this.transferModeCap;
    }
    if (!isEmpty(weldingProcess) && !isEmpty(transferMode)) {
      return (
        get(weldingProcess, 'shortDesignation') === 'GMAW' &&
        get(transferMode, 'transferMode') === ARC_TRANSFER_MODES.SHORT_ARC
      );
    }
    return false;
  }

  get isWeldingProcessGMAWShort() {
    let { weldingProcessRoot: root, weldingProcessFill: fill, weldingProcessCap: cap } = this;
    if (root != null && fill != null && cap != null) {
      return (
        this._isWeldingProcessGMAWShort('ROOT') &&
        this._isWeldingProcessGMAWShort('FILL') &&
        this._isWeldingProcessGMAWShort('CAP')
      );
    } else if (root != null && fill != null) {
      return this._isWeldingProcessGMAWShort('ROOT') && this._isWeldingProcessGMAWShort('FILL');
    } else {
      return this._isWeldingProcessGMAWShort('ROOT');
    }
  }

  _findWeldingDetail(detail) {
    return this.allWeldingDetails.findBy('shortDesignation', detail);
  }

  _findListWeldingDetails(details = []) {
    let results = [];
    details.forEach((d) => {
      let detail = this._findWeldingDetail(d);
      if (detail) {
        results.push(detail);
      }
    });
    return results;
  }

  _isWeldingDetail(testPiece, detail) {
    return testPiece.findBy('shortDesignation', detail);
  }

  weldingDetails(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
    let results = [];
    if (this.impactRequirements) {
      if (this.isMultiLayer) {
        results.push(this._findWeldingDetail(WELDING_DETAILS.MULTI_LAYER));
      } else if (this.isSingleLayer) {
        results.push(this._findWeldingDetail(WELDING_DETAILS.SINGLE_LAYER));
      }
    } else {
      if (this.isMultiLayer) {
        results.push(this._findWeldingDetail(WELDING_DETAILS.SINGLE_LAYER));
        results.push(this._findWeldingDetail(WELDING_DETAILS.MULTI_LAYER));
      } else if (this.isSingleLayer) {
        results.push(this._findWeldingDetail(WELDING_DETAILS.SINGLE_LAYER));
        results.push(this._findWeldingDetail(WELDING_DETAILS.MULTI_LAYER));
      }
    }

    if (this._isWeldingDetail(testPiece, WELDING_DETAILS.SINGLE_SIDE_NO_BACKING)) {
      this._findListWeldingDetails([
        WELDING_DETAILS.SINGLE_SIDE_NO_BACKING,
        WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
        WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING,
        WELDING_DETAILS.BOTH_SIDES,
      ]).forEach((d) => results.pushObject(d));
    } else if (this._isWeldingDetail(testPiece, WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING)) {
      this._findListWeldingDetails([
        WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
        WELDING_DETAILS.BOTH_SIDES,
      ]).forEach((d) => results.pushObject(d));
    } else if (this._isWeldingDetail(testPiece, WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING)) {
      this._findListWeldingDetails([
        WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
        WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING,
        WELDING_DETAILS.BOTH_SIDES,
      ]).forEach((d) => results.pushObject(d));
    } else if (this._isWeldingDetail(testPiece, WELDING_DETAILS.BOTH_SIDES)) {
      this._findListWeldingDetails([
        WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
        WELDING_DETAILS.BOTH_SIDES,
      ]).forEach((d) => results.pushObject(d));
    } else if (this._isWeldingDetail(testPiece, WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING)) {
      this._findListWeldingDetails([WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING]).forEach((d) =>
        results.pushObject(d)
      );
    }

    if (this._isWeldingDetail(testPiece, WELDING_DETAILS.CONSUMABLE_INSERT)) {
      this._findListWeldingDetails([WELDING_DETAILS.CONSUMABLE_INSERT]).forEach((d) =>
        results.pushObject(d)
      );
    }

    return results;
  }

  processMecanization(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return this.allProcessMecanizations;
  }

  transferMode(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
    let transferMode = get(testPiece, 'transferMode');
    if (transferMode === ARC_TRANSFER_MODES.SHORT_ARC) {
      return [testPiece];
    } else {
      return this.allTransferModes.filter((t) => {
        return get(t, 'transferMode') !== ARC_TRANSFER_MODES.SHORT_ARC;
      });
    }
  }

  typeCurrentAndPolarity(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return this.allTypeCurrentPolarity;
  }

  diameter(/* testPiece */) {
    return this._toRange(0, null);
  }

  weldedThickness(testPiece) {
    let testPieceNumber = parseFloat(testPiece, 10);
    if (isNaN(testPieceNumber)) {
      return this._emptyRange;
    }
    if (this.isMetricSystem) {
      return this._weldedThicknessMetric(testPieceNumber);
    } else if (this.isImperialSystem) {
      return this._weldedThicknessImperial(testPieceNumber);
    }
    return this._emptyRange;
  }

  baseMaterialThickness(thickness1, thickness2) {
    return {
      material1: this.baseMaterial1Thickness(thickness1),
      material2: this.baseMaterial2Thickness(thickness2),
    };
  }

  baseMaterial1Thickness(value) {
    return this._baseMaterialThickness(value);
  }

  baseMaterial2Thickness(value) {
    return this._baseMaterialThickness(value);
  }

  _baseMaterialThickness(value) {
    let testPiece = parseFloat(value, 10);
    if (isNaN(testPiece)) {
      return this._emptyRange;
    }
    if (this.isMetricSystem) {
      if (this.isFilletWeld) {
        return this._toRange(0, null);
      } else if (this.isOverlayWeld) {
        if (testPiece < 25) {
          return this._toRange(testPiece, null);
        } else if (testPiece >= 25) {
          return this._toRange(25, null);
        }
      } else if (this.isButtWeldOrPartialPenetrationWeld) {
        let min = null;
        let max = null;
        if (testPiece < 1.5) {
          min = 0.5 * testPiece;
          max = 2 * testPiece;
        } else if (testPiece >= 1.5 && testPiece <= 10) {
          min = 1.5;
          max = 2 * testPiece;
        } else if (testPiece > 10 && testPiece < 19) {
          min = 5;
          max = 2 * testPiece;
        } else if (testPiece >= 19 && testPiece < 38) {
          min = 5;
          max = 2 * testPiece;
        } else if (testPiece >= 38 && testPiece < 152) {
          min = 5;
          max = 203;
        } else if (testPiece >= 152) {
          min = 25;
          max = 1.33 * testPiece;
        }
        if (this.impactRequirements && testPiece < 16) {
          min = testPiece;
        }
        return this._toRange(min, max);
      }
    } else if (this.isImperialSystem) {
      if (this.isFilletWeld) {
        return this._toRange(0, null);
      } else if (this.isOverlayWeld) {
        if (testPiece < 1) {
          return this._toRange(testPiece, null);
        } else if (testPiece >= 1) {
          return this._toRange(1, null);
        }
      } else if (this.isButtWeldOrPartialPenetrationWeld) {
        let min = null;
        let max = null;
        if (testPiece < 0.0625) {
          min = 0.5 * testPiece;
          max = 2 * testPiece;
        } else if (testPiece >= 0.0625 && testPiece <= 0.375) {
          min = 0.0625;
          max = 2 * testPiece;
        } else if (testPiece > 0.375 && testPiece < 0.75) {
          min = 0.1875;
          max = 2 * testPiece;
        } else if (testPiece >= 0.75 && testPiece < 1.5) {
          min = 0.1875;
          max = 2 * testPiece;
        } else if (testPiece >= 1.5 && testPiece < 6) {
          min = 0.1875;
          max = 8;
        } else if (testPiece >= 6) {
          min = 1;
          max = 1.33 * testPiece;
        }
        if (this.impactRequirements && testPiece < 0.625) {
          min = testPiece;
        }
        return this._toRange(min, max);
      }
    }
    return this._emptyRange;
  }

  _findWeldingPosition(code = '') {
    return this.allWeldingPositions
      .filter((p) => {
        return get(p, 'industryCode.id') === get(this.standard, 'code.id');
      })
      .findBy('code', code);
  }

  _findWeldingPositions(codes = []) {
    let results = [];
    codes.forEach((position) => {
      results.push(
        this.allWeldingPositions
          .filter((p) => {
            return get(p, 'industryCode.id') === get(this.standard, 'code.id');
          })
          .findBy('code', get(position, 'code'))
      );
    });
    return results;
  }

  weldingPosition(testPiece /* direction */) {
    if (isEmpty(testPiece)) {
      return [];
    }
    let currentPosition = get(testPiece, 'code');

    if (this.isOverlayWeld) {
      return [testPiece, this._findWeldingPosition('1G')];
    }

    if (this.impactRequirements) {
      if (this.isButtWeldOrPartialPenetrationWeld) {
        let verticalPositions = ['3G', '5G', '6G', '3F', '5F'];
        if (verticalPositions.includes(currentPosition)) {
          return this._findWeldingPositions([
            '1G',
            '2G',
            '3G',
            '4G',
            '5G',
            '6G',
            '1F',
            '2F',
            '3F',
            '4F',
            '5F',
          ]);
        }
        let nonVerticalPositions = ['1G', '2G', '4G'];
        if (nonVerticalPositions.includes(currentPosition)) {
          return this._findWeldingPositions(['1G', '2G', '4G', '1F', '2F', '4F']);
        }
      } else if (this.isFilletWeld) {
        let verticalPositions = ['3F', '5F'];
        if (verticalPositions.includes(currentPosition)) {
          return this._findWeldingPositions(['1F', '2F', '3F', '4F', '5F']);
        }
        let nonVerticalPositions = ['1F', '2F', '4F'];
        if (nonVerticalPositions.includes(currentPosition)) {
          return this._findWeldingPositions(['1F', '2F', '4F']);
        }
      }
    } else {
      return this.allWeldingPositions.filter((position) => {
        let code = get(position, 'code');
        let validPositions = ['1G', '2G', '3G', '4G', '5G', '6G', '1F', '2F', '3F', '4F', '5F'];
        return validPositions.includes(code);
      });
    }

    return [testPiece];
  }

  preHeat(testPiece) {
    let value = parseFloat(testPiece, 10);
    if (!isNaN(value)) {
      if (this.isMetricSystem) {
        return this._toRange(value - 38, null);
      } else if (this.isImperialSystem) {
        return this._toRange(value - 100, null);
      }
    }
    return this._emptyRange;
  }

  postHeat(/* testPiece */) {
    return this._emptyRange;
  }

  get isBaseMaterialSpecialGroupInterpass() {
    let material1 = this.baseMaterial1;
    let material2 = this.baseMaterial2;

    if (!isEmpty(material1)) {
      let baseMaterial1Group = get(material1, 'materialGroup.groupNumber');
      if (!isEmpty(baseMaterial1Group)) {
        return ['23', '24', '26', '27'].includes(baseMaterial1Group);
      }
    }

    if (!isEmpty(material2)) {
      let baseMaterial2Group = get(material2, 'materialGroup.groupNumber');
      if (!isEmpty(baseMaterial2Group)) {
        return ['23', '24', '26', '27'].includes(baseMaterial2Group);
      }
    }

    return false;
  }

  get isWeldingProcessSpecialForInterpass() {
    return (
      this._isSpecialInterpassWeldingProcess(this.weldingProcessRoot) ||
      this._isSpecialInterpassWeldingProcess(this.weldingProcessFill) ||
      this._isSpecialInterpassWeldingProcess(this.weldingProcessCap)
    );
  }

  _isSpecialInterpassWeldingProcess(weldingProcess) {
    if (!isEmpty(weldingProcess)) {
      return (
        isGTAW(weldingProcess) ||
        isGMAW(weldingProcess) ||
        isPAW(weldingProcess) ||
        isSMAW(weldingProcess)
      );
    }
    return false;
  }

  get isSpecialInterpassCase() {
    return this.isBaseMaterialSpecialGroupInterpass && this.isWeldingProcessSpecialForInterpass;
  }

  interpass(testPiece) {
    let value = parseFloat(testPiece, 10);
    if (isNaN(value)) {
      return this._emptyRange;
    }

    if (this.isSpecialInterpassCase) {
      if (this.isMetricSystem) {
        return this._toRange(0, value + 38);
      } else if (this.isImperialSystem) {
        return this._toRange(0, value + 100);
      }
    }
    if (this.impactRequirements) {
      if (this.isMetricSystem) {
        return this._toRange(0, value + 38);
      } else if (this.isImperialSystem) {
        return this._toRange(0, value + 100);
      }
    } else {
      return this._toRange(0, null);
    }

    return this._emptyRange;
  }

  pwhtTemperature(testPiece) {
    let value = parseFloat(testPiece, 10);
    if (isNaN(value)) {
      return this._emptyRange;
    }

    return this._toRange(value, value);
  }

  pwhtTime(testPiece) {
    let value = parseFloat(testPiece, 10);
    if (isNaN(value)) {
      return this._emptyRange;
    }
    return this._toRange(0, value * 1.25);
  }

  get isMetricSystem() {
    return this.unitSystem === UNIT_SYSTEM.METRIC;
  }

  get isImperialSystem() {
    return this.unitSystem === UNIT_SYSTEM.IMPERIAL;
  }

  _toRange(min, max) {
    return {
      min,
      max,
    };
  }

  get _emptyRange() {
    return {
      min: null,
      max: null,
    };
  }

  get isButtWeldOrPartialPenetrationWeld() {
    return (
      isButtWeld(this.weldTypeTestPiece) || isPartialPenetrationButtWeld(this.weldTypeTestPiece)
    );
  }

  get isFilletWeld() {
    return isFilletWeld(this.weldTypeTestPiece);
  }

  get isOverlayWeld() {
    return isOverlayWeld(this.weldTypeTestPiece);
  }

  get isMultiLayer() {
    return isMultiLayer(this.weldingDetailsValues);
  }

  get isSingleLayer() {
    return isSingleLayer(this.weldingDetailsValues);
  }

  get impactRequirements() {
    let { impactTemperature, impactRequired } = this;
    return impactRequired && !isNaN(parseFloat(impactTemperature));
  }

  get smallestBaseMaterialThickness() {
    let bm1Thickness = parseFloat(this.baseMaterial1ThicknessValue, 10);
    let bm2Thickness = parseFloat(this.baseMaterial2ThicknessValue, 10);

    if (!isNaN(bm1Thickness) && !isNaN(bm2Thickness)) {
      return bm1Thickness <= bm2Thickness ? bm1Thickness : bm2Thickness;
    } else if (!isNaN(bm1Thickness)) {
      return bm1Thickness;
    } else if (!isNaN(bm2Thickness)) {
      return bm2Thickness;
    }
    return null;
  }

  _weldedThicknessMetric(testPiece) {
    let { smallestBaseMaterialThickness } = this;
    let min = null;
    let max = null;
    if (this.isButtWeldOrPartialPenetrationWeld) {
      if (testPiece < 19) {
        min = 0;
        max = 2 * testPiece;
      } else if (
        testPiece >= 19 &&
        smallestBaseMaterialThickness >= 19 &&
        smallestBaseMaterialThickness < 38
      ) {
        min = 0;
        max = 2 * smallestBaseMaterialThickness;
      } else if (
        testPiece >= 19 &&
        smallestBaseMaterialThickness >= 38 &&
        smallestBaseMaterialThickness < 152
      ) {
        min = 0;
        max = 203;
      } else if (testPiece >= 19 && testPiece < 152 && smallestBaseMaterialThickness >= 152) {
        min = 0;
        max = 203;
      } else if (testPiece >= 152 && smallestBaseMaterialThickness >= 152) {
        min = 0;
        max = 1.33 * testPiece;
      }
    } else if (this.isFilletWeld) {
      if (this.isSingleLayer) {
        min = 0;
        max = testPiece;
      } else if (this.isMultiLayer) {
        if (this.isWeldingProcessGMAWShort) {
          min = testPiece * 0.5;
          max = null;
        } else {
          min = testPiece * 0.5;
          max = testPiece * 1.1;
        }
      }
    } else if (this.isOverlayWeld) {
      min = 0;
      max = null;
    }

    if (this.isWeldingProcessGMAWShort) {
      if (testPiece < 13) {
        max = 1.1 * testPiece;
      }
    }
    return this._toRange(min, max);
  }

  _weldedThicknessImperial(testPiece) {
    let { smallestBaseMaterialThickness } = this;
    let min = null;
    let max = null;
    if (this.isButtWeldOrPartialPenetrationWeld) {
      if (testPiece < 0.75) {
        min = 0;
        max = 2 * testPiece;
      } else if (
        testPiece >= 0.75 &&
        smallestBaseMaterialThickness >= 0.75 &&
        smallestBaseMaterialThickness < 1.5
      ) {
        min = 0;
        max = 2 * smallestBaseMaterialThickness;
      } else if (
        testPiece >= 0.75 &&
        smallestBaseMaterialThickness >= 1.5 &&
        smallestBaseMaterialThickness < 6
      ) {
        min = 0;
        max = 8;
      } else if (testPiece >= 0.75 && testPiece < 6 && smallestBaseMaterialThickness >= 6) {
        min = 0;
        max = 8;
      } else if (testPiece >= 6 && smallestBaseMaterialThickness >= 6) {
        min = 0;
        max = 1.33 * testPiece;
      }
    } else if (this.isFilletWeld) {
      if (this.isSingleLayer) {
        min = 0;
        max = testPiece;
      } else if (this.isMultiLayer) {
        if (this.isWeldingProcessGMAWShort) {
          min = testPiece * 0.5;
          max = null;
        } else {
          min = testPiece * 0.5;
          max = testPiece * 1.1;
        }
      }
    } else if (this.isOverlayWeld) {
      min = 0;
      max = null;
    }
    if (this.isWeldingProcessGMAWShort) {
      if (testPiece < 0.5) {
        max = 1.1 * testPiece;
      }
    }
    return this._toRange(min, max);
  }

  thicknessProcess(testPiece, layer) {
    let testPieceNumber = parseFloat(testPiece, 10);
    if (isNaN(testPieceNumber)) {
      return this._emptyRange;
    }
    if (this.isMetricSystem) {
      return this._thicknessProcessMetric(testPieceNumber);
    } else if (this.isImperialSystem) {
      return this._thicknessProcessImperial(testPieceNumber);
    }
    return this._emptyRange;
  }

  _thicknessProcessMetric(testPiece, layer) {
    let { smallestBaseMaterialThickness } = this;
    let min = null;
    let max = null;
    if (this.isButtWeldOrPartialPenetrationWeld) {
      if (testPiece < 19) {
        min = 0;
        max = 2 * testPiece;
      } else if (
        testPiece >= 19 &&
        smallestBaseMaterialThickness >= 19 &&
        smallestBaseMaterialThickness < 38
      ) {
        min = 0;
        max = 2 * smallestBaseMaterialThickness;
      } else if (
        testPiece >= 19 &&
        smallestBaseMaterialThickness >= 38 &&
        smallestBaseMaterialThickness < 152
      ) {
        min = 0;
        max = 203;
      } else if (testPiece >= 19 && testPiece < 152 && smallestBaseMaterialThickness >= 152) {
        min = 0;
        max = 203;
      } else if (testPiece >= 152 && smallestBaseMaterialThickness >= 152) {
        min = 0;
        max = 1.33 * testPiece;
      }
    } else if (this.isFilletWeld) {
      if (this.isSingleLayer) {
        min = 0;
        max = testPiece;
      } else if (this.isMultiLayer) {
        if (this.isWeldingProcessGMAWShort) {
          min = testPiece * 0.5;
          max = null;
        } else {
          min = testPiece * 0.5;
          max = testPiece * 1.1;
        }
      }
    } else if (this.isOverlayWeld) {
      min = 0;
      max = null;
    }

    if (this._isWeldingProcessGMAWShort(layer)) {
      if (testPiece < 13) {
        max = 1.1 * testPiece;
      }
    }
    return this._toRange(min, max);
  }

  _thicknessProcessImperial(testPiece, layer) {
    let { smallestBaseMaterialThickness } = this;
    let min = null;
    let max = null;
    if (this.isButtWeldOrPartialPenetrationWeld) {
      if (testPiece < 0.75) {
        min = 0;
        max = 2 * testPiece;
      } else if (
        testPiece >= 0.75 &&
        smallestBaseMaterialThickness >= 0.75 &&
        smallestBaseMaterialThickness < 1.5
      ) {
        min = 0;
        max = 2 * smallestBaseMaterialThickness;
      } else if (
        testPiece >= 0.75 &&
        smallestBaseMaterialThickness >= 1.5 &&
        smallestBaseMaterialThickness < 6
      ) {
        min = 0;
        max = 8;
      } else if (testPiece >= 0.75 && testPiece < 6 && smallestBaseMaterialThickness >= 6) {
        min = 0;
        max = 8;
      } else if (testPiece >= 6 && smallestBaseMaterialThickness >= 6) {
        min = 0;
        max = 1.33 * testPiece;
      }
    } else if (this.isFilletWeld) {
      if (this.isSingleLayer) {
        min = 0;
        max = testPiece;
      } else if (this.isMultiLayer) {
        if (this.isWeldingProcessGMAWShort) {
          min = testPiece * 0.5;
          max = null;
        } else {
          min = testPiece * 0.5;
          max = testPiece * 1.1;
        }
      }
    } else if (this.isOverlayWeld) {
      min = 0;
      max = null;
    }
    if (this._isWeldingProcessGMAWShort(layer)) {
      if (testPiece < 0.5) {
        max = 1.1 * testPiece;
      }
    }
    return this._toRange(min, max);
  }

  _getAllMaterialsOfNumber(number) {
    return this.allBaseMaterials.filter((material) => {
      return get(material, 'groupNumber') === number;
    });
  }

  _getAllMaterialsOfNumberAndSmaller(number) {
    let numberParsed = parseInt(number, 10);
    if (isNaN(numberParsed)) {
      // Hack for the situation of groupNumber being 5X
      if (number === '5A' || number === '5B' || number === '5C') {
        numberParsed = 5;
      }
    }
    if (isNaN(numberParsed)) {
      return [];
    }
    return this.allBaseMaterials.filter((material) => {
      let groupNumberAsNumber = parseInt(get(material, 'groupNumber'), 10);
      if (isNaN(groupNumberAsNumber)) {
        return false;
      }
      return groupNumberAsNumber <= numberParsed;
    });
  }

  baseMaterial(baseMaterial1, baseMaterial2) {
    if (isEmpty(baseMaterial1) || isEmpty(baseMaterial2)) {
      return {
        material1: [],
        material2: [],
      };
    }

    let baseMaterialGroup1 = get(baseMaterial1, 'materialGroup.groupNumber');
    let baseMaterialGroup2 = get(baseMaterial2, 'materialGroup.groupNumber');

    let baseMaterial1Id = get(baseMaterial1, 'id');
    let baseMaterial2Id = get(baseMaterial2, 'id');

    let isSameMaterial = baseMaterial1Id === baseMaterial1Id;

    let baseMaterial1ApprovalRange = [];
    let baseMaterial2ApprovalRange = [];

    if (this.impactRequirements) {
      if (isSameMaterial) {
        // Approval Range is the test piece groups
        baseMaterial1ApprovalRange.push(get(baseMaterial1, 'materialGroup'));
        baseMaterial2ApprovalRange.push(get(baseMaterial2, 'materialGroup'));
      } else {
        baseMaterial1ApprovalRange.push(get(baseMaterial1, 'materialGroup'));
        baseMaterial1ApprovalRange.push(get(baseMaterial2, 'materialGroup'));
        baseMaterial2ApprovalRange.push(get(baseMaterial2, 'materialGroup'));
        baseMaterial2ApprovalRange.push(get(baseMaterial1, 'materialGroup'));
      }
    } else {
      if (isSameMaterial) {
        let specialGroups = ['1', '3', '4', '5A'];
        if (
          specialGroups.includes(baseMaterialGroup1) &&
          specialGroups.includes(baseMaterialGroup2)
        ) {
          baseMaterial1ApprovalRange = this._getAllMaterialsOfNumber(baseMaterialGroup1);
          baseMaterial2ApprovalRange = this._getAllMaterialsOfNumberAndSmaller(baseMaterialGroup2);
        } else {
          baseMaterial1ApprovalRange = this._getAllMaterialsOfNumber(baseMaterialGroup1);
          baseMaterial2ApprovalRange = this._getAllMaterialsOfNumber(baseMaterialGroup2);
        }
      } else {
        baseMaterial1ApprovalRange = this._getAllMaterialsOfNumber(baseMaterialGroup1);
        baseMaterial2ApprovalRange = this._getAllMaterialsOfNumber(baseMaterialGroup2);
      }
    }

    return {
      material1: baseMaterial1ApprovalRange,
      material2: baseMaterial2ApprovalRange,
    };
  }

  branchAngle(/* testPiece */) {
    return this._emptyRange;
  }

  fillerMaterialRootCommercialDesignation(designation) {
    return [];
  }

  fillerMaterialFillCommercialDesignation(designation) {
    return [];
  }

  fillerMaterialCapCommercialDesignation(designation) {
    return [];
  }

  throatThickness(thickness) {
    return this._emptyRange;
  }

  pjpButtWeldBaseMaterial1Thickness() {
    return this.baseMaterialThickness(this.baseMaterial1ThicknessValue);
  }

  pjpButtWeldBaseMaterial2Thickness() {
    return this.baseMaterialThickness(this.baseMaterial2ThicknessValue);
  }

  baseMaterial1FilletWeldThickness() {
    return this._baseMaterialFilletWeldThickness();
  }

  baseMaterial2FilletWeldThickness() {
    return this._baseMaterialFilletWeldThickness();
  }

  _baseMaterialFilletWeldThickness() {
    let { smallestBaseMaterialThickness } = this;
    if (this.isMetricSystem) {
      if (smallestBaseMaterialThickness > 10) {
        return this._toRange(0, null);
      } else {
        return this._baseMaterialThickness(smallestBaseMaterialThickness);
      }
    } else if (this.isImperialSystem) {
      if (smallestBaseMaterialThickness > 0.375) {
        return this._toRange(0, null);
      } else {
        return this._baseMaterialThickness(smallestBaseMaterialThickness);
      }
    }
    return this._emptyRange;
  }

  shielding(testPiece) {
    return calculateShielding(testPiece);
  }

  productType(testPiece) {
    let result = {
      productType: [],
      restrictions: [],
    };
    if (testPiece === PRODUCT_TYPE.PIPE || testPiece === PRODUCT_TYPE.PLATE) {
      result.productType = [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
    }
    return result;
  }
}
