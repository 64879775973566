import { get } from '@ember/object';
import Constants from 'weldnote/utils/constants';

const { WELDING_LAYER_OPTIONS } = Constants;

// Filters the correct layer from the the allLayers array so that it matches
// if the document has the fill or cap layer
export function calculateLayerOptions(allLayers = [], hasFillLayer = false, hasCapLayer = false) {
  if (hasCapLayer) {
    return allLayers;
  } else if (hasFillLayer) {
    return allLayers.filter((e) => {
      if (get(e, 'key') === WELDING_LAYER_OPTIONS.CAP) {
        return false;
      }
      return true;
    });
  } else {
    return allLayers.filter((e) => {
      if (
        get(e, 'key') === WELDING_LAYER_OPTIONS.CAP ||
        get(e, 'key') === WELDING_LAYER_OPTIONS.FILL
      ) {
        return false;
      }
      return true;
    });
  }
}
