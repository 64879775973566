import { belongsTo, attr } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';

export default class BendTestLine extends BaseModel {
  @attr('number')
  declare orderNum?: number;

  @attr('string')
  declare type?: string;

  @attr('number')
  declare bendAngle?: number;

  @attr('number')
  declare elongation?: number;

  @attr('string')
  declare results?: string;

  @attr('string')
  declare testType?: string;

  @belongsTo('pqr')
  declare pqr: any;

  metadata = {
    modelName: 'bend-test-line',
  };

  isLineEmpty(): boolean {
    let allEmpty =
      isEmpty(this.type) &&
      isEmpty(this.bendAngle) &&
      isEmpty(this.elongation) &&
      isEmpty(this.testType) &&
      isEmpty(this.results);
    return allEmpty;
  }

  validations = {
    type: {
      length: {
        maximum: 30,
      },
    },

    bendAngle: {
      numericality: {
        onlyInteger: true,
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 360,
      },
    },

    elongation: {
      numericality: {
        onlyInteger: true,
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 100,
      },
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'bend-test-line': BendTestLine;
  }
}
