import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class WeldingProcedureSpecificationPwpsToCertificateRoute extends Route {
  @service('weldnote-data')
  data;

  @service session;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  model({ id }) {
    return this.data.loadAllDataWPS(id);
  }
}
