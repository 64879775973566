
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class HighlightedPassHelperService extends Service {
  @tracked
  highlightedPassId = '';

  isHighlighted(guid) {
    return guid === this.highlightedPassId;
  }
}
