import { service } from '@ember/service';
import classic from 'ember-classic-decorator';
import ListController from 'weldnote/controllers/_base/list-js';

export default class ConstructionStandardsController extends ListController {
  modelName = 'construction-standard';

  @service
  userSession;
}
