import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class PreparationMethod extends BaseModel {
  @attr('string')
  declare method: string;

  @attr('boolean')
  declare system: boolean;

  get visualLabel(): string {
    return this.method || '';
  }

  validations = {
    method: {
      presence: {
        presence: {
          message(_key: string, _value: string, model: PreparationMethod): string {
            return model.intl.t('generic.error.input-value');
          },
        },
      },
    },
  };

  metadata = {
    modelName: 'preparation-method',
    method: {
      required: true,
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'preparation-method': PreparationMethod;
  }
}
