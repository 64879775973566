import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import FillerMaterial from './filler-material';
import FillerMaterialCommercialDesignation from './filler-material-commercial-designation';
import FileUpload from './file-upload';

export default class FillerMaterialCertificate extends BaseModel {
  @belongsTo('filler-material')
  declare fillerMaterial: FillerMaterial;

  @belongsTo('filler-material-commercial-designation')
  declare commercialDesignation: FillerMaterialCommercialDesignation;

  @attr('string')
  declare batchNumber?: string;

  @belongsTo('file-upload')
  declare certificate: AsyncBelongsTo<FileUpload>;

  @attr('string')
  declare certificatePath?: string;

  @attr('decimal-value')
  declare diameter: number;

  get visualLabel(): string {
    return this.batchNumber || '';
  }

  validations = {
    fillerMaterial: { presence: true },
    batchNumber: { presence: true },
  };

  metadata = {
    modelName: 'filler-material-certificate',
    listRoute: 'filler-material-certificates',
    editRoute: 'filler-material-certificate',
    fillerMaterial: { required: true },
    batchNumber: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'filler-material-certificate': FillerMaterialCertificate;
  }
}
