import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { getProperties, action, computed, set } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class BaseMaterialGroupsController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  totalCount = 0;

  currentPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'base-material-group';

  isLoading = false;

  @computed
  get gridColumns() {
    let nameLabel = this.getAttributeLabel('groupName');
    let codeLabel = this.getAttributeLabel('code');
    let descriptionLabel = this.getAttributeLabel('description');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'groupName',
        label: nameLabel,
        cellComponent: 'weldnote-grid/base-material-group-link',
      },
      {
        valuePath: 'code.code',
        label: codeLabel,
      },
      {
        valuePath: 'description',
        label: descriptionLabel,
        sortable: false,
        width: '550px',
        breakpoints: ['laptop', 'desktop'],
      },
    ];
  }

  buildFilters() {
    let filters = getProperties(this, 'name', 'code');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllBaseMaterialGroups(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setName(name) {
    set(this, 'name', name);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setCode(code) {
    set(this, 'code', code);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  clearFields() {
    this.resetPage();
    this.setProperties({
      name: null,
      code: null,
    });
    this.loadData.perform();
  }
}
