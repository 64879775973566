import { hasMany, belongsTo, attr, AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import FileUpload from './file-upload';
import WeldingDetail from './welding-detail';
import WeldType from './weld-type';

export default class GrooveDesign extends BaseModel {
  @attr('string')
  declare description?: string;

  @attr('string')
  declare longDescription?: string;

  @belongsTo('file-upload')
  declare image: AsyncBelongsTo<FileUpload>;

  @attr()
  declare urlImage?: string;

  @attr('boolean')
  declare system?: boolean;

  @hasMany('welding-detail')
  declare weldingDetails: AsyncHasMany<WeldingDetail>;

  @hasMany('weld-type')
  declare weldTypes: AsyncHasMany<WeldType>;

  @attr('string')
  declare grooveType?: string;

  @attr('string')
  declare jointType?: string;

  @attr()
  declare imageState?: string;

  @attr('string')
  declare imageSituation?: string;

  @belongsTo('file-upload')
  declare renderedImage: AsyncBelongsTo<FileUpload>;

  @attr('string')
  declare renderedImagePath?: string;

  @attr('date')
  declare modifiedOn: string;

  get visualLabel(): string {
    return this.description || '';
  }

  validations = {
    description: { presence: true },
    longDescription: { presence: true },
    image: {
      custom: {
        validation(_key: string, value: FileUpload): boolean {
          if (value) {
            if (!isEmpty(get(value, 'id'))) {
              return true;
            }
          }
          return false;
        },

        message(_key: string, _value: string, model: GrooveDesign): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'groove-design',
    description: { required: true },
    longDescription: { required: true },
    image: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'groove-design': GrooveDesign;
  }
}
