import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericSearchLov\n  @label={{@label}}\n  @availableOptions={{@availableOptions}}\n  @selected={{@selectedComponent}}\n  @onChange={{@onComponentSelected}}\n  @renderInPlace={{true}}\n  @errors={{@errors}}\n  @disabled={{@disabled}}\n  @isRequired={{@isRequired}}\n  @searchEnabled={{true}}\n  @matcher={{this.componentNumberAndNameMatcher}}\n  ...attributes\n  as |component|\n>\n  {{component.componentNumber}}\n  -\n  {{component.componentName}}\n</GenericSearchLov>", {"contents":"<GenericSearchLov\n  @label={{@label}}\n  @availableOptions={{@availableOptions}}\n  @selected={{@selectedComponent}}\n  @onChange={{@onComponentSelected}}\n  @renderInPlace={{true}}\n  @errors={{@errors}}\n  @disabled={{@disabled}}\n  @isRequired={{@isRequired}}\n  @searchEnabled={{true}}\n  @matcher={{this.componentNumberAndNameMatcher}}\n  ...attributes\n  as |component|\n>\n  {{component.componentNumber}}\n  -\n  {{component.componentName}}\n</GenericSearchLov>","moduleName":"weldnote/components/model-project/component-chooser-with-options.hbs","parseOptions":{"srcName":"weldnote/components/model-project/component-chooser-with-options.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ProjectComponentChooser extends Component {
  fetchedComponentsProjectId = null;

  @action
  componentNumberAndNameMatcher(projectComponent, searchTerm) {
    return `${projectComponent.componentNumber} - ${projectComponent.componentName}`.indexOf(
      searchTerm
    );
  }
}
