import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield}}", {"contents":"{{yield}}","moduleName":"weldnote/components/ui-form/generic-group.hbs","parseOptions":{"srcName":"weldnote/components/ui-form/generic-group.hbs"}});
import classic from 'ember-classic-decorator';
import { classNames, attributeBindings, classNameBindings } from '@ember-decorators/component';
import { notEmpty } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@classNames('form-group')
@classNameBindings('hasError')
@attributeBindings('data-test-id')
export default class GenericGroup extends Component {
  errors = null;

  @notEmpty('errors')
  hasError;
}
