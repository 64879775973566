import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';
import Constants from 'weldnote/utils/constants';
import CertificateFillerRules from 'weldnote/utils/certificate-filler-material-rules-asme';
import {
  isButtWeld,
  isOverlayWeld,
  isFilletWeld,
  isPartialPenetrationButtWeld,
} from 'weldnote/utils/rules/weld-type';
import { calculateShielding } from 'weldnote/utils/certification-rules';
import moment from 'moment';
import WelderCertificate from 'weldnote/models/welder-certificate';
import ElectrodeType from 'weldnote/models/electrode-type';
import FillerMaterial from 'weldnote/models/filler-material';
import FillerMaterialGroup from 'weldnote/models/filler-material-group';
import WeldingPosition from 'weldnote/models/welding-position';
import { NumericalRange, WelderCertificateApprovalRange } from './wopq-range-interface';
import WeldType from 'weldnote/models/weld-type';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import WeldingDetail from 'weldnote/models/welding-detail';
import BaseMaterial from 'weldnote/models/base-material';
import BaseMaterialGroup from 'weldnote/models/base-material-group';
import IndustryCode from 'weldnote/models/industry-code';
import WeldingProcess from 'weldnote/models/welding-process';
import ProcessMecanization from 'weldnote/models/process-mecanization';
import { isGMAW } from 'weldnote/utils/rules/welding-process';
import {
  AutomaticJointTracking,
  AutomaticArcVoltageControl,
  TestPieceValuesSingleProcess,
  VisualControl,
  WeldingOperatorSpecificWeldingDetails,
} from '../types';

const { UNIT_SYSTEM, WELDING_DETAILS, PRODUCT_TYPE } = Constants;

export default class WeldingOperatorAsmeIxApprovalRanges implements WelderCertificateApprovalRange {
  private certificate: WelderCertificate;

  private unitSystem: string;

  private allElectrodes: ElectrodeType[];

  private allWeldingDetails: WeldingDetail[];

  private allBaseMaterials: BaseMaterialGroup[];

  private allTransferModes: ArcTransferMode[];

  private allWeldingPositions: WeldingPosition[];

  private allFillerMaterials: FillerMaterialGroup[];

  private allWeldTypes: WeldType[];

  private allWeldingProcesses: WeldingProcess[];

  constructor(certificate: WelderCertificate, data: any, unitSystem = UNIT_SYSTEM.METRIC) {
    this.certificate = certificate;
    this.unitSystem = unitSystem;
    this.allElectrodes = data.allElectrodes;
    this.allWeldingDetails = data.allWeldingDetails;
    this.allWeldingProcesses = data.allWeldingProcesses.filter(
      (process: WeldingProcess) =>
        this.getIndustryCode(get(process, 'industryCode')) === this.industryCodeId
    );
    this.allBaseMaterials = data.allBaseMaterials.filter((material: BaseMaterialGroup) => {
      this.getIndustryCode(get(material, 'code')) === this.industryCodeId;
    });
    this.allTransferModes = data.allTransferModes.filter(
      (transferMode: ArcTransferMode) =>
        this.getIndustryCode(get(transferMode, 'industryCode')) === this.industryCodeId
    );
    this.allWeldingPositions = data.allWeldingPositions.filter(
      (position: WeldingPosition) =>
        this.getIndustryCode(get(position, 'industryCode')) === this.industryCodeId
    );
    this.allFillerMaterials = data.allFillerMaterials.filter(
      (material: FillerMaterialGroup) =>
        this.getIndustryCode(get(material, 'code')) === this.industryCodeId
    );
    this.allWeldTypes = data.allWeldTypes.filter((weldType: WeldType) => {
      return this.getIndustryCode(get(weldType, 'industryCode')) === this.industryCodeId;
    });
  }

  processMecanization(testPiece: ProcessMecanization): ProcessMecanization[] {
    if (!testPiece && isEmpty(testPiece)) {
      return [];
    }
    if (testPiece.isAutomatic) {
      return [testPiece];
    }
    if (testPiece.isMechanized) {
      return [testPiece];
    }
    return [];
  }

  getIndustryCode(code: IndustryCode | undefined): string {
    if (code) {
      return get(code, 'id');
    }
    return '';
  }

  get industryCodeId() {
    let { standard } = this;
    let code = get(standard, 'code');
    if (code) {
      return get(code, 'id');
    }
    return '';
  }

  get standard() {
    return this.certificate.get('standard');
  }

  get diameterValue() {
    return this.certificate.get('diameterTestPiece');
  }

  get weldingProcessRootName() {
    let weldingProcess = this.certificate.get('weldingProcessRootTestPiece');
    if (!isEmpty(weldingProcess)) {
      return weldingProcess.shortDesignation;
    }
    return '';
  }

  get weldingProcessFillName() {
    let weldingProcess = this.certificate.get('weldingProcessFillTestPiece');
    if (!isEmpty(weldingProcess)) {
      return weldingProcess.shortDesignation;
    }
    return '';
  }

  get weldingProcessCapName() {
    let weldingProcess = this.certificate.get('weldingProcessCapTestPiece');
    if (!isEmpty(weldingProcess)) {
      return weldingProcess.shortDesignation;
    }
    return '';
  }

  get weldTypeTestPiece() {
    return this.certificate.get('weldTypeTestPiece');
  }

  get weldTypeCode() {
    let weldType = this.certificate.get('weldTypeTestPiece');
    if (!isEmpty(weldType)) {
      return weldType.weldType;
    }
    return '';
  }

  get supplementaryFilletWeldTestRequired() {
    return this.certificate.get('supplementaryFilletWeldTestRequired');
  }

  get requalificationPeriod() {
    return this.certificate.get('requalificationPeriod');
  }

  electrodeTypeRoot(
    electrode: ElectrodeType,
    processValues: TestPieceValuesSingleProcess
  ): ElectrodeType[] {
    return this.electrodeType(electrode, processValues);
  }

  electrodeTypeFill(
    electrode: ElectrodeType,
    processValues: TestPieceValuesSingleProcess
  ): ElectrodeType[] {
    return this.electrodeType(electrode, processValues);
  }

  electrodeTypeCap(
    electrode: ElectrodeType,
    processValues: TestPieceValuesSingleProcess
  ): ElectrodeType[] {
    return this.electrodeType(electrode, processValues);
  }

  electrodeType(
    electrode: ElectrodeType,
    _processValues: TestPieceValuesSingleProcess
  ): ElectrodeType[] {
    if (isEmpty(electrode)) {
      return [];
    }
    let { shortDesignation } = electrode;
    return this._filterElectrodeTypes([shortDesignation]);
  }

  _filterElectrodeTypes(electrodeCodes: string[]): ElectrodeType[] {
    let results: ElectrodeType[] = [];
    electrodeCodes.forEach((designation) => {
      let candidate = this.allElectrodes.findBy('shortDesignation', designation);
      if (candidate) {
        results.pushObject(candidate);
      }
    });
    return results;
  }

  get _emptyRange(): NumericalRange {
    return {
      min: null,
      max: null,
    };
  }

  get _allRange(): NumericalRange {
    return {
      min: 0,
      max: null,
    };
  }

  get isFilletWeld() {
    return isFilletWeld(this.weldTypeTestPiece);
  }

  get isButtWeld() {
    return isButtWeld(this.weldTypeTestPiece);
  }

  get isOverlayWeld() {
    return isOverlayWeld(this.weldTypeTestPiece);
  }

  get isPartialPenetrationButtWeld() {
    return isPartialPenetrationButtWeld(this.weldTypeTestPiece);
  }

  get productType() {
    return this.certificate.get('productTypeTestPiece');
  }

  get isPipe() {
    let { productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PIPE;
    }
    return false;
  }

  get isMetric() {
    return this.unitSystem === UNIT_SYSTEM.METRIC;
  }

  get isImperial() {
    return this.unitSystem === UNIT_SYSTEM.IMPERIAL;
  }

  thickness(testPiece: number): NumericalRange {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    return this._allRange;
  }

  weldType(testPiece: WeldType): WeldType[] {
    if (isEmpty(testPiece)) {
      return [];
    }

    return this.allWeldTypes;
  }

  transferMode(testPiece: ArcTransferMode): ArcTransferMode[] {
    if (isEmpty(testPiece)) {
      return [];
    }

    return this.allTransferModes;
  }

  weldingDetails(testPiece: WeldingDetail[]): WeldingDetail[] {
    if (isEmpty(testPiece)) {
      return [];
    }

    let results: WeldingDetail[] = [];
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_NO_BACKING)) {
      results.pushObjects(
        this.allWeldingDetails.filter((detail) => {
          let { shortDesignation } = detail;
          return (
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_NO_BACKING ||
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING ||
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING ||
            shortDesignation === WELDING_DETAILS.BOTH_SIDES
          );
        })
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING)) {
      results.pushObjects(
        this.allWeldingDetails.filter((detail) => {
          let { shortDesignation } = detail;
          return (
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING ||
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING ||
            shortDesignation === WELDING_DETAILS.BOTH_SIDES
          );
        })
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING)) {
      results.pushObjects(
        this.allWeldingDetails.filter((detail) => {
          let { shortDesignation } = detail;
          return (
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING ||
            shortDesignation === WELDING_DETAILS.BOTH_SIDES
          );
        })
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_LAYER)) {
      results.pushObjects(
        this.allWeldingDetails.filter((detail) => {
          let { shortDesignation } = detail;
          return (
            shortDesignation === WELDING_DETAILS.MULTI_LAYER ||
            shortDesignation === WELDING_DETAILS.SINGLE_LAYER
          );
        })
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.MULTI_LAYER)) {
      results.pushObjects(
        this.allWeldingDetails.filter((detail) => {
          let { shortDesignation } = detail;
          return (
            shortDesignation === WELDING_DETAILS.MULTI_LAYER ||
            shortDesignation === WELDING_DETAILS.SINGLE_LAYER
          );
        })
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING)) {
      results.pushObjects(
        this.allWeldingDetails.filter((detail) => {
          let { shortDesignation } = detail;
          return (
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING ||
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING ||
            shortDesignation === WELDING_DETAILS.BOTH_SIDES
          );
        })
      );
    }

    // Consumable Insert is always qualified
    results.pushObjects(
      this.allWeldingDetails.filter((detail) => {
        let { shortDesignation } = detail;
        return shortDesignation === WELDING_DETAILS.CONSUMABLE_INSERT;
      })
    );

    return results;
  }

  _filterBaseMaterials(wanted: string[]) {
    let results: BaseMaterialGroup[] = [];
    wanted.forEach((position) => {
      let candidate = this.allBaseMaterials.findBy('groupName', position);
      if (candidate) {
        results.pushObject(candidate);
      }
    });
    return results;
  }

  baseMaterial(testPiece: BaseMaterial): BaseMaterialGroup[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    return this.allBaseMaterials;
  }

  _filterProcessByName(processes: string[]) {
    let result: WeldingProcess[] = [];
    processes.forEach((processName) => {
      let process = this.allWeldingProcesses.findBy('shortDesignation', processName);
      if (process) {
        result.pushObject(process);
      }
    });
    return result;
  }

  _calculateWeldingProcess(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    let result: WeldingProcess[] = [];
    if (isGMAW(process)) {
      result = this._filterProcessByName(['GMAW', 'FCAW']);
    }

    if (isEmpty(result)) {
      result = [process];
    }
    return result;
  }

  weldingProcessRoot(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }

    return this._calculateWeldingProcess(process);
  }

  weldingProcessFill(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    return this._calculateWeldingProcess(process);
  }

  weldingProcessCap(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    return this._calculateWeldingProcess(process);
  }

  shielding(process: WeldingProcess) {
    if (isEmpty(process)) {
      return null;
    }

    return calculateShielding(process.shortDesignation);
  }

  diameter(testPiece: number): NumericalRange {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    return this._allRange;
  }

  _filterPositions(positions: string[]) {
    return this.allWeldingPositions.filter((position: WeldingPosition) => {
      let { code } = position;
      if (code) {
        return positions.indexOf(code) >= 0;
      } else {
        return false;
      }
    });
  }

  weldingPosition(
    testPiece: WeldingPosition,
    processValues: TestPieceValuesSingleProcess
  ): WeldingPosition[] {
    if (isEmpty(testPiece)) {
      return [];
    }

    let results = [];

    let weldingPosition = testPiece.code;
    if (!weldingPosition) {
      return [];
    }

    if (processValues.processMecanization.isPresent()) {
      if (processValues.processMecanization.get().isAutomatic) {
        return this.allWeldingPositions.filter((position: WeldingPosition) => !position.isMultiple);
      } else if (processValues.processMecanization.get().isMechanized) {
        if (weldingPosition === '1G') {
          results = this._filterPositions(['1G', '1F']);
        } else if (weldingPosition === '2G') {
          results = this._filterPositions(['1G', '2G', '1F', '2F']);
        } else if (weldingPosition === '2G+3G+4G') {
          results = this._filterPositions([
            '1G',
            '2G',
            '3G',
            '4G',
            '5G',
            '6G',
            '1F',
            '2F',
            '3F',
            '4F',
            '5F',
          ]);
        } else if (weldingPosition === '3G') {
          results = this._filterPositions(['1G', '3G', '1F', '2F', '3F']);
        } else if (weldingPosition === '4G') {
          results = this._filterPositions(['1G', '4G', '1F', '2F', '4F']);
        } else if (weldingPosition === '3G+4G') {
          results = this._filterPositions(['1G', '3G', '4G', '1F', '2F', '3F', '4F', '5F']);
        } else if (weldingPosition === '5G') {
          results = this._filterPositions(['1G', '3G', '4G', '5G', '1F', '2F', '3F', '4F', '5F']);
        } else if (weldingPosition === '5G+2G') {
          results = this._filterPositions([
            '1G',
            '2G',
            '3G',
            '4G',
            '5G',
            '6G',
            '1F',
            '2F',
            '3F',
            '4F',
            '5F',
          ]);
        } else if (weldingPosition === '6G') {
          results = this._filterPositions([
            '1G',
            '2G',
            '3G',
            '4G',
            '5G',
            '6G',
            '1F',
            '2F',
            '3F',
            '4F',
            '5F',
          ]);
        } else if (weldingPosition === '6GR') {
          results = this._filterPositions([
            '1G',
            '2G',
            '3G',
            '4G',
            '5G',
            '6G',
            '6GR',
            '1F',
            '2F',
            '3F',
            '4F',
            '5F',
          ]);
        } else if (weldingPosition === '1F') {
          results = this._filterPositions(['1F']);
        } else if (weldingPosition === '2F') {
          results = this._filterPositions(['1F', '2F']);
        } else if (weldingPosition === '3F') {
          results = this._filterPositions(['1F', '2F', '3F']);
        } else if (weldingPosition === '4F') {
          results = this._filterPositions(['1F', '2F', '4F']);
        } else if (weldingPosition === '3F+4F') {
          results = this._filterPositions(['1F', '2F', '3F', '4F', '5F']);
        } else if (weldingPosition === '5F') {
          results = this._filterPositions(['1F', '2F', '3F', '4F', '5F']);
        } else {
          results = this._filterPositions([weldingPosition]);
        }
        return results;
      }
    }

    return [];
  }

  _filterFillerMaterialGroups(groupToSelect: string[]) {
    let results: FillerMaterialGroup[] = [];
    groupToSelect.forEach((groupCode) => {
      let candidate = this.allFillerMaterials.findBy('groupName', groupCode);
      if (candidate) {
        results.pushObject(candidate);
      }
    });
    return results;
  }

  fillerMaterial(testPiece: FillerMaterial): FillerMaterialGroup[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    let group: FillerMaterialGroup = get(testPiece, 'group');
    let groupCode: string = '';
    if (group) {
      groupCode = get(group, 'groupName') || '';
    }

    let results: FillerMaterialGroup[] = [];
    if (CertificateFillerRules.isFillerMaterialGroup1(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial1Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroup2(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial1Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial2Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroup3(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial1Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial2Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial3Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroup4(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial1Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial2Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial3Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial4Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroup5(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial1Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial5Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroup6(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial6Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroupRange30to40(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial30to40Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroup50(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial50Groups())
      );
    } else {
      results.pushObjects(this._filterFillerMaterialGroups([groupCode]));
    }
    return results;
  }

  internalDeadline(previousDate: Date): Date | null {
    if (isEmpty(previousDate)) {
      return null;
    }
    let newDate = moment(previousDate).add(6, 'M');
    return newDate.toDate();
  }

  externalDeadline(previousDate: Date): Date | null {
    if (isEmpty(previousDate)) {
      return null;
    }

    return null;
  }

  automaticJointTracking(
    testPiece: AutomaticJointTracking | null,
    process: TestPieceValuesSingleProcess
  ): AutomaticJointTracking[] {
    if (process.processMecanization.isPresent()) {
      let processMecanization = process.processMecanization.get();
      if (processMecanization.isAutomatic) {
        return [];
      } else if (processMecanization.isMechanized) {
        if (testPiece === AutomaticJointTracking.WITH) {
          return [AutomaticJointTracking.WITH];
        } else if (testPiece === AutomaticJointTracking.WITHOUT) {
          return [AutomaticJointTracking.WITH, AutomaticJointTracking.WITHOUT];
        }
      }
    }
    return [];
  }

  automaticVoltageControl(
    testPiece: AutomaticArcVoltageControl | null,
    process: TestPieceValuesSingleProcess
  ): AutomaticArcVoltageControl[] {
    if (process.processMecanization.isPresent()) {
      let processMecanization = process.processMecanization.get();
      if (processMecanization.isAutomatic) {
        return [];
      } else if (processMecanization.isMechanized) {
        if (testPiece === AutomaticArcVoltageControl.WITH) {
          return [AutomaticArcVoltageControl.WITH];
        } else if (testPiece === AutomaticArcVoltageControl.WITHOUT) {
          return [AutomaticArcVoltageControl.WITH, AutomaticArcVoltageControl.WITHOUT];
        }
      }
    }
    return [];
  }

  visualControl(
    testPiece: VisualControl | null,
    _process: TestPieceValuesSingleProcess
  ): VisualControl[] {
    if (!testPiece) {
      return [];
    }
    if (testPiece === VisualControl.DIRECT) {
      return [VisualControl.DIRECT];
    } else if (testPiece === VisualControl.REMOTE) {
      return [VisualControl.REMOTE];
    }
    return [];
  }

  weldingDetailsForOperator(
    _testPiece: WeldingOperatorSpecificWeldingDetails,
    _process: TestPieceValuesSingleProcess
  ): WeldingOperatorSpecificWeldingDetails[] {
    return [];
  }
}
