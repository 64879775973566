import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"progress\">\n  <div\n    class=\"progress-bar progress-bar-{{this.type}}\"\n    role=\"progressbar\"\n    aria-valuenow=\"{{this.percentage}}\"\n    aria-valuemin=\"0\"\n    aria-valuemax=\"100\"\n    style=\"{{progress-width this.percentage 20}}\"\n  >\n    {{#if (has-block)}}\n      {{yield (hash percentage=this.percentage part=@part total=@total)}}\n    {{else}}\n      {{this.percentage}}% ({{@part}}\n      /\n      {{this.total}})\n    {{/if}}\n  </div>\n</div>", {"contents":"<div class=\"progress\">\n  <div\n    class=\"progress-bar progress-bar-{{this.type}}\"\n    role=\"progressbar\"\n    aria-valuenow=\"{{this.percentage}}\"\n    aria-valuemin=\"0\"\n    aria-valuemax=\"100\"\n    style=\"{{progress-width this.percentage 20}}\"\n  >\n    {{#if (has-block)}}\n      {{yield (hash percentage=this.percentage part=@part total=@total)}}\n    {{else}}\n      {{this.percentage}}% ({{@part}}\n      /\n      {{this.total}})\n    {{/if}}\n  </div>\n</div>","moduleName":"weldnote/components/weld-progress-bar.hbs","parseOptions":{"srcName":"weldnote/components/weld-progress-bar.hbs"}});
import Component from '@glimmer/component';

export default class WeldProgressBar extends Component {
  get type() {
    let progress = parseInt(this.percentage);
    if (!isNaN(progress)) {
      if (progress < 30) {
        return 'danger';
      } else if (progress >= 30 && progress < 60) {
        return 'warning';
      } else if (progress > 60 && progress < 100) {
        return 'navy-light';
      } else {
        return 'primary';
      }
    }
    return 'primary';
  }

  get percentage() {
    let totalWelds = this.args.total;
    let partCompleted = this.args.part;
    let total = parseInt(totalWelds);
    let completed = parseInt(partCompleted);
    if (isNaN(total) || isNaN(completed)) {
      return 0;
    }
    if (total === 0 && completed === 0) {
      return 0;
    }
    return Math.round((completed / total) * 100);
  }
}
