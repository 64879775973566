import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';

@classic
export default class WeldersRoute extends ListRoute {
  @service('weldnote-data')
  data;

  @service('helpdesk-widget')
  helpdesk;

  model() {
    return this.data.listAllWelders();
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('totalCount', model.meta.records);
  }

  activate() {
    this.helpdesk.setSearchItems('Welders');
  }
}
