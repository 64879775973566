import { helper } from '@ember/component/helper';
import { isEmpty } from '@ember/utils';
import moment from 'moment';

export function daysUntil(params, hash) {
  let [startPoint, target] = params;
  let { format } = hash;
  if (!isEmpty(startPoint) && !isEmpty(target)) {
    if (isEmpty(format)) {
      format = 'DD/MM/YYYY';
    }
    let momentStart = moment(startPoint, format);
    let momentEnd = moment(target, format);
    return momentEnd.diff(momentStart, 'days');
  }
  return '';
}

export default helper(daysUntil);
