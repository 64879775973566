/*
Overide the tooltip-on-element from emeber-tooltips as per
https://github.com/sir-dunxalot/ember-tooltips#setting-defaults
-*/
import TooltipOnElementComponent from 'ember-tooltips/components/tooltip-on-element';

export default TooltipOnElementComponent.extend({
  effect: 'fade',
  enableLazyRendering: true,
});
