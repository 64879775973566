import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import { memberAction } from 'ember-api-actions';
import Constants from 'weldnote/utils/constants';
import FileUpload from './file-upload';
import Company from './company';
import Person from './person';

const { ARCHIVAL_STATE } = Constants;

export default class Welder extends BaseModel {
  @attr('string')
  declare welderName?: string;

  @attr('string')
  declare welderStamp?: string;

  @attr('string')
  declare welderId?: string;

  @attr('string')
  declare welderIdType?: string;

  @attr('date')
  declare dateBirth?: Date;

  @belongsTo('file-upload')
  declare welderReport: AsyncBelongsTo<FileUpload>;

  @attr('string')
  declare welderReportPath?: string;

  @belongsTo('company')
  declare company: AsyncBelongsTo<Company>;

  @belongsTo('person')
  declare person: AsyncBelongsTo<Person>;

  @attr('number')
  declare totalWelds?: number;

  @attr('number')
  declare goodWelds?: number;

  @attr('number')
  declare repairedWelds?: number;

  @attr('number')
  declare repairRate?: number;

  @attr('string')
  declare archivalState?: string;

  get visualLabel(): string {
    return this.welderName || '';
  }

  validations = {
    welderName: { presence: true },
    welderStamp: { presence: true },
  };

  metadata = {
    modelName: 'welder',
    welderName: { required: true },
    welderStamp: { required: true },
  };

  get name() {
    return this.welderName;
  }

  get stamp(): string {
    return this.welderStamp || '';
  }

  get isArchived() {
    return this.archivalState === ARCHIVAL_STATE.ARCHIVED;
  }

  get isActive() {
    return this.archivalState === ARCHIVAL_STATE.ACTIVE;
  }

  createWelderReport = memberAction({ path: 'createWelderReport' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    welder: Welder;
  }
}
