import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"form-group {{if this.hasErrors 'has-error'}}\" ...attributes>\n  {{#if this.shouldRenderLabel}}\n    <label class=\"control-label\">{{@label}} {{#if @required}}*{{/if}}</label>\n  {{/if}}\n  <PowerSelect\n    @triggerClass={{concat \"form-control \" @cssTriggerClass}}\n    @options={{@options}}\n    @searchEnabled={{this.isSearchEnabled}}\n    @disabled={{@disabled}}\n    @allowClear={{this.allowClearOption}}\n    @selected={{@selected}}\n    @searchField={{@searchField}}\n    @placeholder={{@placeholder}}\n    @renderInPlace={{@renderInPlace}}\n    @onChange={{@onChange}}\n    as |option|\n  >\n    {{yield option}}\n  </PowerSelect>\n  {{#if this.hasHelp}}\n    <span class=\"help-block {{@helpTextClasses}}\">{{@helpText}}</span>\n  {{/if}}\n  {{#if this.hasErrors}}\n    {{#each @errors as |error|}}\n      <span class=\"help-block\">{{error}}</span>\n    {{/each}}\n  {{/if}}\n</div>", {"contents":"<div class=\"form-group {{if this.hasErrors 'has-error'}}\" ...attributes>\n  {{#if this.shouldRenderLabel}}\n    <label class=\"control-label\">{{@label}} {{#if @required}}*{{/if}}</label>\n  {{/if}}\n  <PowerSelect\n    @triggerClass={{concat \"form-control \" @cssTriggerClass}}\n    @options={{@options}}\n    @searchEnabled={{this.isSearchEnabled}}\n    @disabled={{@disabled}}\n    @allowClear={{this.allowClearOption}}\n    @selected={{@selected}}\n    @searchField={{@searchField}}\n    @placeholder={{@placeholder}}\n    @renderInPlace={{@renderInPlace}}\n    @onChange={{@onChange}}\n    as |option|\n  >\n    {{yield option}}\n  </PowerSelect>\n  {{#if this.hasHelp}}\n    <span class=\"help-block {{@helpTextClasses}}\">{{@helpText}}</span>\n  {{/if}}\n  {{#if this.hasErrors}}\n    {{#each @errors as |error|}}\n      <span class=\"help-block\">{{error}}</span>\n    {{/each}}\n  {{/if}}\n</div>","moduleName":"weldnote/components/generic-lov.hbs","parseOptions":{"srcName":"weldnote/components/generic-lov.hbs"}});
import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class GenericLov extends Component {
  get shouldRenderLabel() {
    return this.args.renderLabel ?? true;
  }

  get allowClearOption() {
    return this.args.allowClear ?? true;
  }

  get hasHelp() {
    return !isEmpty(this.args.helpText);
  }

  get hasErrors() {
    return !isEmpty(this.args.errors);
  }

  get isSearchEnabled() {
    return this.args.searchEnabled ?? true;
  }
}
