import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { UnauthorizedError } from '@ember-data/adapter/error';
import * as Sentry from '@sentry/browser';
import { didCancel } from 'ember-concurrency';

const VISITED_PAGES_PREFIX = '/notes';

@classic
export default class ApplicationRoute extends Route {
  @service
  session;

  @service
  userSession;

  @service
  loading;

  @service
  ajax;

  @service
  intl;

  @service
  orgApi;

  @service
  router;

  @service
  analytics;

  sentErrors = [];

  constructor() {
    super(...arguments);

    let { router, analytics } = this;
    router.on('routeDidChange', () => {
      let page = `${VISITED_PAGES_PREFIX}${router.currentURL}`;
      let title = router.currentRouteName || 'unknown';

      analytics.trackPage({ page, title });
    });
  }

  async beforeModel() {
    super.beforeModel(...arguments);
    await this.session.setup();
    this.intl.setLocale('en-us');
    let { session, userSession } = this;
    if (session.isAuthenticated) {
      // Due to the fact that logout needs to go to the azure logout page and also invalidate the session
      // we might find ourselves here, but in the middle of the logout process...
      // if that's the case, we don't want to proceed and load the user
      // The conceptual correct approach is to check the router for the logout route
      // but testing reveals that probably the router is not yet activated here, so the first condition basically
      // always fails. As such, we check the url where we are and if it's the logout one.. we abort any further content
      // loading
      if (this.router.isActive('logout') || window?.location?.pathname?.includes('/logout')) {
        return;
      }
      await this.orgApi.syncAccount();
      try {
        await userSession.loadUser.perform();
      } catch (e) {
        if (!didCancel(e)) {
          // re-throw the non-cancelation error
          throw e;
        }
      }
    }
  }

  @action
  async error(error, transition) {
    if (
      error instanceof UnauthorizedError ||
      (error && error.message === 'Unauthenticated') ||
      (error && error.status === 401)
    ) {
      window.alert(
        `We're sorry, an unexpected error occured and your login is invalid.\n\nYou will have to login again.\n\nPlease contact support if this error persists.`
      );

      let tokenData = this.session.data?.authenticated;
      Sentry.withScope(function (scope) {
        scope.setTag('msal-accessToken', tokenData?.accessToken);
        scope.setTag('msal-refreshToken', tokenData?.refresh);
        scope.setTag('msal-expiresOn', tokenData?.expiresOn);

        Sentry.captureMessage('Received 401 from backend, user was forcefully logged out', 'info');
      });

      await this.session.invalidate();
    } else {
      let stringifiedError = error.toString();
      if (!this.sentErrors.includes(stringifiedError)) {
        Sentry.captureException(new Error(error));
        this.sentErrors.push(stringifiedError);
      }
      throw error;
    }
  }
}
