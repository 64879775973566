export default class UrlBuilder {
  private url: string;

  private parameters: any;

  constructor(url: string) {
    this.url = url;
    this.parameters = [];
  }

  addParameter(parameterName: string, value: any) {
    this.parameters.push({
      name: parameterName,
      value,
    });
  }

  build(): string {
    let { url } = this;
    for (let i = 0; i < this.parameters.length; i++) {
      let currentParameter = this.parameters[i];
      let parameterName = currentParameter.name;
      let parameterValue = currentParameter.value;
      if (i === 0) {
        url += `?${parameterName}=${parameterValue}`;
      } else {
        url += `&${parameterName}=${parameterValue}`;
      }
    }
    return url;
  }
}
