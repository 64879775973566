import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { oneWay, and } from '@ember/object/computed';
import Component from '@ember/component';
import { later } from '@ember/runloop';
import { set, get, action, computed } from '@ember/object';
import { isPermissionDenied } from 'weldnote/utils/json-api-errors';
import EVENTS from 'weldnote/utils/analytics-events';

@classic
export default class BaseEdit extends Component {
  @service
  intl;

  @service
  store;

  @service
  analytics;

  @service
  alertMessage;

  @service
  loading;

  showLoading = true;

  isUserEditing = false;

  @computed('isUserEditing')
  get isEditing() {
    return this.isUserEditing;
  }

  title = '';

  createAction = null;

  isComponentSaving = false;

  @and('model.isSaving', 'isComponentSaving')
  isModelSaving;

  @computed('model.isNew', 'model.visualLabel')
  get titleValue() {
    if (this.get('model.isNew')) {
      let modelLabel = this.intl.t(`model.${this.get('model.metadata.modelName')}.model-name`);
      return this.intl.t('edit-view.new-item', { name: modelLabel });
    }
    return this.get('model.visualLabel');
  }

  @oneWay('model.hasDirtyAttributes')
  isDirty;

  @computed('isDirty')
  get subtitle() {
    let isDirty = get(this, 'isDirty');
    let changedAttributes = get(this, 'model').changedAttributes();
    // Something's odd, changedAttributes returns empty, but hasDirtyAttributes returns true?
    if (isDirty && Object.keys(changedAttributes).length > 0) {
      return '*';
    } else {
      return '';
    }
  }

  getActionLabel(title) {
    return this.intl.t(title, { name: get(this, 'model.visualLabel') });
  }

  getModelLabel(label) {
    return this.intl.t(`model.${get(this, 'model.metadata.modelName')}.${label}`);
  }

  init() {
    super.init(...arguments);
    set(this, 'title', get(this, 'titleValue'));
  }

  _markSaving() {
    set(this, 'isComponentSaving', true);
  }

  _finishSaving() {
    set(this, 'isComponentSaving', false);
  }

  _showSaveSuccessMessage() {
    let translation = this.getActionLabel('edit-view.actions.saved');
    this.alertMessage.success(translation);
  }

  _updateTitle() {
    let newTitle = this.titleValue;
    set(this, 'title', newTitle);
  }

  _stopEditing() {
    set(this, 'isUserEditing', false);
  }

  postCancelEditModel() {
    /* Hook to override */
  }

  // The breadcrumb for a given model
  @computed('model')
  get breadcrumbs() {
    return [
      {
        route: this.listRoute,
        label: this.getModelLabel('model-plural'),
      },
      {
        label: this.titleValue,
      },
    ];
  }

  @action
  deleteModel() {
    let { model, alertMessage, loading, intl } = this;
    let deleteInfo = {
      id: model.get('id'),
      type: model.get('metadata.modelName'),
    };
    let translation = this.getActionLabel('edit-view.actions.deleted');
    loading.block();
    model.deleteRecord();
    model
      .save()
      .then(() => {
        alertMessage.success(translation);
        if (this.postDelete) {
          this.postDelete(deleteInfo);
        }
        this.deleteInstance();
      })
      .catch((reason) => {
        // https://github.com/emberjs/data/pull/3859
        // It appears that I need to upgrade to ember data 2.2 to have this issue fixed
        model.rollbackAttributes();
        if (isPermissionDenied(reason)) {
          alertMessage.error(intl.t('edit-view.actions.permission-denied'));
        } else {
          if (reason && reason.errors) {
            if (reason.errors[0].code === '23503') {
              let foreignKeyMessage = intl.t('edit-view.actions.foreign-key-error', {
                name: this.get('model.visualLabel'),
              });
              alertMessage.error(foreignKeyMessage);
              set(this, 'isShowingModal', false);
            } else {
              alertMessage.error(
                intl.t('edit-view.actions.generic-delete-error', {
                  name: this.get('model.visualLabel'),
                })
              );
            }
          } else {
            alertMessage.error(
              intl.t('edit-view.actions.generic-delete-error', {
                name: this.get('model.visualLabel'),
              })
            );
          }
        }
      })
      .finally(() => {
        loading.unblock();
        this.toggleProperty('isShowingModal');
      });
  }

  @action
  saveModel(stopEditing = false) {
    let { model, loading, alertMessage, intl } = this;
    let modelIsNew = model.get('isNew');
    if (this.beforeSave) {
      this.beforeSave();
    }

    if (model.validate()) {
      loading.block();
      this._markSaving();
      model
        .save()
        .then((model) => {
          this._showSaveSuccessMessage();
          if (stopEditing) {
            this._stopEditing();
          }
          this._updateTitle();
          if (this._postSave) {
            this._postSave(model, { isNew: modelIsNew });
          }
          if (modelIsNew) {
            get(this, 'instanceCreated')();
          }
        })
        .catch((reason) => {
          if (isPermissionDenied(reason)) {
            alertMessage.error(intl.t('edit-view.actions.permission-denied'));
          } else {
            if (reason && reason.errors) {
              if (reason.errors[0].code === '23000') {
                let duplicateMessage = intl.t('edit-view.actions.unique-violation');
                if (this.customDuplicateMessage) {
                  let customError = this.customDuplicateMessage();
                  let errors = get(model, 'errors');
                  errors.add(customError.attribute, customError.message);
                  duplicateMessage = customError.message;
                }
                alertMessage.error(duplicateMessage);
              } else {
                alertMessage.error(
                  intl.t('edit-view.actions.generic-error', {
                    name: get(this, 'model.visualLabel'),
                  })
                );
              }
            } else {
              alertMessage.error(
                intl.t('edit-view.actions.error', { name: get(this, 'titleValue') })
              );
            }
          }
        })
        .finally(() => {
          this._finishSaving();
          loading.unblock();
        });
    } else {
      if (this.validationFailed) {
        this.validationFailed(this.model);
        let errors = [];
        get(this, 'model.errors').forEach((error) => {
          errors.pushObject(error);
        });
        this.analytics.trackEvent(EVENTS.VALIDATION_FAILED, {
          id: get(this, 'model.id'),
          type: get(this, 'model.metadata.modelName'),
          errors,
        });
      }
      alertMessage.error(intl.t('edit-view.actions.validation-failed'));
    }
  }

  @action
  editModel() {
    if (this.showLoading) {
      this.loading.block();
    }
    if (this.beforeEditModel) {
      this.beforeEditModel();
    }
    set(this, 'isUserEditing', true);
    if (this.showLoading) {
      later(
        this,
        () => {
          this.loading.unblock();
        },
        600
      );
    }
  }

  @action
  confirmDeleteModel() {
    this.toggleProperty('isShowingModal');
  }

  @action
  toggleModal() {
    this.toggleProperty('isShowingModal');
  }

  @action
  cancelEditModel() {
    if (this.showLoading) {
      this.loading.block();
    }
    this.model.rollbackAttributes();
    set(this, 'isUserEditing', false);
    this.postCancelEditModel();
    if (this.showLoading) {
      later(
        this,
        () => {
          this.loading.unblock();
        },
        1000
      );
    }
  }
}
