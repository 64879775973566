import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class TestStageName extends BaseModel {
  @attr('string')
  declare name?: string;

  @attr('number')
  declare displayOrder?: string;

  get visualLabel() {
    return this.name;
  }

  validations = {
    name: {
      presence: {
        message(_key: string, _value: string, model: TestStageName): string {
          return model.intl.t('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'test-stage-name',
    listRoute: 'test-stage-names',
    editRoute: 'test-stage-name',

    name: {
      required: true,
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'test-stage-name': TestStageName;
  }
}
