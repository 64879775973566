import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';

@classic
export default class ArchivedRoute extends ListRoute {
  @service
  welderCertificateData;

  model(params) {
    return this.welderCertificateData.getWelderCertificatesArchived({
      page: params.page,
    });
  }
}
