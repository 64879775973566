import Mixin from '@ember/object/mixin';
import { isEmpty } from '@ember/utils';
import { computed, set } from '@ember/object';
import { service } from '@ember/service';

export default Mixin.create({
  fileService: service(),

  imagePreviewFetch(url, urlFetchFlag, collectionProperty, imageProperty) {
    if (isEmpty(this.get(url)) && !this.get(urlFetchFlag)) {
      this.set(urlFetchFlag, true);
      let imageCollection = this.get(`model.${collectionProperty}`);
      if (imageCollection && imageCollection !== null) {
        imageCollection
          .then((collection) => {
            if (!isEmpty(collection)) {
              let firstObject = collection.get('firstObject');
              firstObject
                .get(imageProperty)
                .then((file) => {
                  this.fileService.getFileRetrieveRequestForUpload(file).then((result) => {
                    this.set(url, result.url);
                  });
                })
                .finally(() => {
                  this.set(urlFetchFlag, false);
                });
            }
          })
          .catch(() => {
            this.set(urlFetchFlag, false);
          });
      }
    }
    return this.get(url);
  },

  grooveDesignUrl: null,
  grooveDesignUrlFetching: false,
  grooveDesignImageLink: computed('model.grooveDesigns.[]', 'grooveDesignUrl', function () {
    return this.imagePreviewFetch(
      'grooveDesignUrl',
      'grooveDesignUrlFetching',
      'grooveDesigns',
      'image'
    );
  }),

  resetGrooveDesignImagePreview() {
    set(this, 'grooveDesignUrl', null);
    set(this, 'grooveDesignUrlFetching', false);
  },

  weldLayerUrl: null,
  weldLayerUrlFetching: false,
  weldLayerImageLink: computed('model.weldLayerConfigurations.[]', 'weldLayerUrl', function () {
    return this.imagePreviewFetch(
      'weldLayerUrl',
      'weldLayerUrlFetching',
      'weldLayerConfigurations',
      'upload'
    );
  }),

  resetWeldLayerImagePreview() {
    set(this, 'weldLayerUrl', null);
    set(this, 'weldLayerUrlFetching', false);
  },
});
