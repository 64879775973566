import { isEmpty } from '@ember/utils';
import { isStandardASME, isStandardAWS, isStandardISO } from 'weldnote/utils/standards';
import BaseMaterialRulesISO from 'weldnote/utils/pqr-base-material-rules-iso';
import { formatRangeWith2Decimals } from 'weldnote/utils/unit-system/format';
import Constants from 'weldnote/utils/constants';
import { NumericalRange } from 'weldnote/src-weldnote/wopq/approval-ranges/wopq-range-interface';
import PqrStandard from 'weldnote/models/pqr-standard';

const { WELD_TYPES, UNIT_SYSTEM } = Constants;

function _fixDecimals(result: NumericalRange, unitSystem = UNIT_SYSTEM.METRIC) {
  if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
    return result;
  }
  return formatRangeWith2Decimals(result);
}

function calculateISO15614ApprovalRange(
  testPiece: number,
  {
    baseMaterial1Group,
    baseMaterial2Group,
  }: {
    baseMaterial1Group: string;
    baseMaterial2Group: string;
  }
) {
  let result: NumericalRange = {
    min: null,
    max: null,
  };
  if (!isEmpty(baseMaterial1Group) && !isEmpty(baseMaterial2Group)) {
    if (isCorrectGroupISO15614(baseMaterial1Group) && isCorrectGroupISO15614(baseMaterial2Group)) {
      result.min = 0;
      result.max = testPiece;
    } else {
      result.min = 0;
      result.max = testPiece + 50;
    }
  } else {
    result.min = 0;
    result.max = testPiece;
  }
  return result;
}

function isCorrectGroupISO15614(group: string): boolean {
  return (
    BaseMaterialRulesISO.isGroup8(group) ||
    BaseMaterialRulesISO.isGroupExactly40(group) ||
    BaseMaterialRulesISO.isGroupExactly48(group) ||
    BaseMaterialRulesISO.isGroup10(group)
  );
}

export function calculateInterpassApprovalRange(
  interpass: number,
  {
    standard,
    interpassMinimum,
    weldType,
    impactRequirements,
    unitSystem,
    baseMaterial1Group,
    baseMaterial2Group,
  }: {
    standard: PqrStandard;
    interpassMinimum: number;
    weldType: string;
    impactRequirements: boolean;
    unitSystem: string;
    baseMaterial1Group: string;
    baseMaterial2Group: string;
  }
) {
  let result: NumericalRange = {
    min: null,
    max: null,
  };

  let interpassValue = interpass;
  if (isStandardISO(standard)) {
    if (!isNaN(interpassValue)) {
      result = calculateISO15614ApprovalRange(interpassValue, {
        baseMaterial1Group,
        baseMaterial2Group,
      });
    }
  } else if (isStandardASME(standard)) {
    if (!isNaN(interpassValue)) {
      if (interpassValue > 0) {
        if (weldType === WELD_TYPES.OVERLAY_WELD) {
          result.min = 0;
          result.max = interpassValue;
        } else {
          result.min = 0;
          if (unitSystem === UNIT_SYSTEM.METRIC) {
            result.max = interpassValue + 55;
          } else if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
            result.max = interpassValue + 100;
          }
        }
      }
    }
  } else if (isStandardAWS(standard)) {
    if (impactRequirements) {
      result.max = interpassValue + 56;
    }
  }

  // Don't update the minimum if there was a previous value there
  if (interpassMinimum > 0 && result.min === 0) {
    result.min = interpassMinimum;
  }

  return _fixDecimals(result, unitSystem);
}
