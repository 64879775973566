import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class MiscellaneousCertificateDuplicateRoute extends Route {
  @service
  store;

  model({ id }) {
    return this.store.findRecord('miscellaneous-certificate', id);
  }
}
