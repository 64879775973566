import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { service } from '@ember/service';
import { computed } from '@ember/object';
import { UnauthorizedError } from '@ember-data/adapter/error';

export default class ApplicationAdapter extends JSONAPIAdapter {
  @service session;

  namespace = 'api';

  @computed('session.{data.authenticated.accessToken,isAuthenticated}')
  get headers() {
    let headers = {
      'Client-Application': 'weldcloud-notes'
    };
    if (this.session.isAuthenticated) {
      headers.Authorization = `Bearer ${this.session.data.authenticated?.accessToken}`;
    }
    return headers;
  }

  /**
   * Goal of overriding this is to catch a 401 response from the server and throw an error that can be caught in time to request a new token
   * from azure. Otherwise we're really signed out and might need to reload
   */
  handleResponse(status, headers, _payload, requestData) {
    if (status === 401) {
      return new UnauthorizedError();
    }
    return super.handleResponse(status, headers, _payload, requestData);
  }
}
