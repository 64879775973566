import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import Project from './project';
import ProjectDocument from './project-document';

export default class ProjectDocumentLink extends BaseModel {
  @belongsTo('project')
  declare project: AsyncBelongsTo<Project>;

  @belongsTo('project-document')
  declare document: AsyncBelongsTo<ProjectDocument>;

  @attr('string')
  declare documentTitle?: string;

  @attr('string')
  declare documentNumber?: string;

  @attr('string')
  declare pdfPath?: string;

  get visualLabel() {
    return this.documentTitle;
  }

  validations = {
    project: { presence: true },
    document: { presence: true },
  };

  metadata = {
    modelName: 'project-document-link',
    project: { required: true },
    document: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-document-link': ProjectDocumentLink;
  }
}
