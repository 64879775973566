import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { isEmpty } from '@ember/utils';
import { t } from 'ember-intl';
import { task, timeout } from 'ember-concurrency';
import { set, action } from '@ember/object';

@classic
export default class ExpiredController extends Controller {
  queryParams = ['page'];

  page = 1;

  @service
  intl;

  @service
  userSession;

  @service
  welderCertificateData;

  @alias('model.list')
  certificates;

  @t('welder-certificate-expiration.externally-expired-title')
  certificateExpirationTitle;

  reloadDataTask = task(
    {
      drop: true,
    },
    async (searchOptions = {}) => {
      let options = {};
      options.page = this.page;
      if (!isEmpty(searchOptions.company)) {
        options.company = searchOptions.company;
      }
      await timeout(400);
      let results =
        await this.welderCertificateData.getWelderCertificatesToExternallyValidateExpired(options);
      set(this, 'model', results);
    }
  );

  @action
  postValidate() {
    this.welderCertificateData.clearCacheExternal();
  }

  @action
  validateSingle(certificate, report1 = '', report2 = '') {
    if (!isEmpty(certificate)) {
      return certificate.validateExternallyExpired({
        report1,
        report2,
      });
    }
  }
}
