import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import ListController from 'weldnote/controllers/_base/list-js';

@classic
export default class StaffPositionsController extends ListController {
  @service
  userSession;

  modelName = 'staff-position';
}
