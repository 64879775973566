import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"form-group {{if this.hasErrors \"has-error\"}}\">\n  <label title=\"{{@title}}\" class=\"control-label\" for=\"{{concat @id \"_input\"}}\">\n    {{@label}}\n    {{#if this.isRequired}}*{{/if}}\n  </label>\n  <input\n    value={{@value}}\n    {{on \"input\" (pick \"target.value\" @onUpdateValue)}}\n    {{on \"keyup\" this.onKeyDown}}\n    class=\"form-control\"\n    placeholder={{@placeholder}}\n    type=\"number\"\n    disabled={{@disabled}}\n    required={{this.isRequired}}\n    id={{if @id (concat @id \"_input\")}}\n  />\n  {{#each @errors as |error|}}\n    <span class=\"help-block\" data-test=\"error-messages\">{{error}}</span>\n  {{/each}}\n  {{#if this.hasHelp}}\n    <span class=\"help-block\">{{@helpText}}</span>\n  {{/if}}\n</div>", {"contents":"<div class=\"form-group {{if this.hasErrors \"has-error\"}}\">\n  <label title=\"{{@title}}\" class=\"control-label\" for=\"{{concat @id \"_input\"}}\">\n    {{@label}}\n    {{#if this.isRequired}}*{{/if}}\n  </label>\n  <input\n    value={{@value}}\n    {{on \"input\" (pick \"target.value\" @onUpdateValue)}}\n    {{on \"keyup\" this.onKeyDown}}\n    class=\"form-control\"\n    placeholder={{@placeholder}}\n    type=\"number\"\n    disabled={{@disabled}}\n    required={{this.isRequired}}\n    id={{if @id (concat @id \"_input\")}}\n  />\n  {{#each @errors as |error|}}\n    <span class=\"help-block\" data-test=\"error-messages\">{{error}}</span>\n  {{/each}}\n  {{#if this.hasHelp}}\n    <span class=\"help-block\">{{@helpText}}</span>\n  {{/if}}\n</div>","moduleName":"weldnote/components/generic-numeric-field.hbs","parseOptions":{"srcName":"weldnote/components/generic-numeric-field.hbs"}});
import classic from 'ember-classic-decorator';
import { notEmpty, equal } from '@ember/object/computed';
import Component from '@ember/component';
import { set, get, action } from '@ember/object';

const ENTER_KEY = 13;

@classic
export default class GenericNumericField extends Component {
  errors = null;

  required = false;

  @notEmpty('errors')
  hasErrors;

  @notEmpty('helpText')
  hasHelp;

  @equal('required', true)
  isRequired;

  init() {
    super.init(...arguments);
    set(this, 'errors', []);
  }

  @action
  onKeyDown(event) {
    if (this.onEnter) {
      let { keyCode } = event;
      let {
        target: { value },
      } = event;
      if (value) {
        if (keyCode === ENTER_KEY) {
          get(this, 'onEnter')(value);
        }
      }
    }
  }
}
