import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { get, getProperties, action, computed, set } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class ExaminingBodiesController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  sort = null;

  name = '';

  dir = 'asc';

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'examining-body';

  isLoading = false;

  @computed('totalCount', 'result.meta.records')
  get examiningBodyCount() {
    if (!isEmpty(get(this, 'result.meta.records'))) {
      return this.result.meta.records;
    }
    return this.totalCount;
  }

  @computed
  get gridColumns() {
    let nameLabel = this.getAttributeLabel('name');
    let addressLabel = this.getAttributeLabel('address');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'name',
        label: nameLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/examining-body-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'address',
        label: addressLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
    ];
  }

  buildFilters() {
    let filters = getProperties(this, 'name');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      set(this, 'isLoading', true);
      let options = {};

      let { dir = 'asc', sort } = getProperties(this, 'dir', 'sort');
      if (!isEmpty(sort)) {
        options.sort = {};
        options.sort[sort] = dir;
      }

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let result = await this.data.listAllExaminingBodies(options, this.buildFilters());
      set(this, 'model', result);
    }
  );

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setName(name) {
    set(this, 'name', name);
    this.resetPage();
  }

  @action
  clearFields() {
    this.resetPage();
    if (!isEmpty(this.name)) {
      set(this, 'name', null);
      this.loadData.perform();
    }
  }
}
