import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"form-group {{if this.hasError \"has-error\"}}\">\n  <label title=\"{{this.title}}\" class=\"control-label\">\n    {{@label}}\n    {{#if this.isRequired}}*{{/if}}\n  </label>\n  <Pikaday::PikadayInput\n    @value={{readonly this.value}}\n    @disabled={{this.disabled}}\n    class=\"form-control\"\n    @placeholder={{this.placeholder}}\n    @useUTC={{true}}\n    @format={{this.format}}\n    @onSelection={{this.onUpdateValue}}\n    @maxDate={{@maxDate}}\n  />\n  {{#if this.hasError}}\n    {{#each this.errors as |error|}}\n      <span class=\"help-block\">{{error}}</span>\n    {{/each}}\n  {{else}}\n    {{#if this.hasHelp}}\n      <span class=\"help-block\">{{this.helpText}}</span>\n    {{/if}}\n  {{/if}}\n</div>", {"contents":"<div class=\"form-group {{if this.hasError \"has-error\"}}\">\n  <label title=\"{{this.title}}\" class=\"control-label\">\n    {{@label}}\n    {{#if this.isRequired}}*{{/if}}\n  </label>\n  <Pikaday::PikadayInput\n    @value={{readonly this.value}}\n    @disabled={{this.disabled}}\n    class=\"form-control\"\n    @placeholder={{this.placeholder}}\n    @useUTC={{true}}\n    @format={{this.format}}\n    @onSelection={{this.onUpdateValue}}\n    @maxDate={{@maxDate}}\n  />\n  {{#if this.hasError}}\n    {{#each this.errors as |error|}}\n      <span class=\"help-block\">{{error}}</span>\n    {{/each}}\n  {{else}}\n    {{#if this.hasHelp}}\n      <span class=\"help-block\">{{this.helpText}}</span>\n    {{/if}}\n  {{/if}}\n</div>","moduleName":"weldnote/components/generic-date-field.hbs","parseOptions":{"srcName":"weldnote/components/generic-date-field.hbs"}});
import classic from 'ember-classic-decorator';
import { notEmpty, equal } from '@ember/object/computed';
import Component from '@ember/component';
import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Constants from 'weldnote/utils/constants';

const { DEFAULT_DATE_FORMAT } = Constants;

@classic
export default class GenericDateField extends Component {
  required = false;

  errors = null;

  helpText = null;

  @notEmpty('errors')
  hasError;

  @equal('required', true)
  isRequired;

  @notEmpty('helpText')
  hasHelp;

  defaultFormat = DEFAULT_DATE_FORMAT;

  @computed('defaultFormat', 'dateFormat')
  get format() {
    if (!isEmpty(this.dateFormat)) {
      return this.dateFormat;
    }
    return this.defaultFormat;
  }
}
