import { get, computed } from '@ember/object';
import { A as emberArray } from '@ember/array';
import { isEmpty } from '@ember/utils';

/**
 * @public
 * Returns all elements in the collection that match the given value
 */
export default function filterFromCollectionByKey(collectionKey, propName, valueKey) {
  return computed(`${collectionKey}.[]`, valueKey, {
    get() {
      return emberArray(get(this, collectionKey)).filterBy(propName, get(this, valueKey));
    },
  });
}

/**
 * @public
 * Returns all elements in the collection that are in a given range, if not, use the values that match a given key
 */
export function filterFromCollectionByKeyInRange(collectionKey, propName, valueKey, rangeKey) {
  return computed(`${collectionKey}.[]`, valueKey, rangeKey, {
    get() {
      let values = get(this, rangeKey);
      if (!isEmpty(values)) {
        return emberArray(get(this, collectionKey)).filter((item) => {
          let collectionId = get(item, 'id');
          return values.includes(parseInt(collectionId));
        });
      } else {
        return emberArray(get(this, collectionKey)).filterBy(propName, get(this, valueKey));
      }
    },
  });
}
