import Route from '@ember/routing/route';
import { get, set, action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';

export default class NewRoute extends Route {
  @service('project-weld-change-tracker')
  tracker;

  @service
  store;

  model({ component }) {
    if (!isEmpty(component)) {
      let componentToAdd = this.store.peekRecord('project-component', component);
      return this.store.createRecord('project-weld', {
        project: this.modelFor('project'),
        component: componentToAdd,
      });
    } else {
      return this.store.createRecord('project-weld', {
        project: this.modelFor('project'),
      });
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    this.tracker.trackWeld(model);
    set(controller, 'project', this.modelFor('project'));
    set(controller, 'weld', model);
  }

  @action
  willTransition(transition) {
    let {
      controller: { model },
      store,
      tracker,
    } = this;
    if (tracker.didWeldChange()) {
      transition.abort();
      tracker.displaySaveAlert();
    } else {
      tracker.clearState();
      if (model.isNew) {
        store.unloadRecord(model);
      }
      // Bubble the `willTransition` action so that
      // parent routes can decide whether or not to abort.
      return true;
    }
  }
}
