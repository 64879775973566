import { A } from '@ember/array';
import EmberObject from '@ember/object';
import Service, { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';

export default Service.extend({
  ajax: service(),

  TASK_STATE: {
    INITIAL: 100,
    IN_PROGRESS: 200,
    FINISHED: 250,
    ABORT: 500,
  },

  currentTasks: A(),

  parseTask(currentTask) {
    let parsedDescription = currentTask.progressDescription;
    try {
      parsedDescription = JSON.parse(currentTask.progressDescription);
    } catch (e) {
      parsedDescription = currentTask.progressDescription;
      // Not a valid JSON, could happen.. let it go unparsed
    }
    let parsedResult = currentTask.result;
    if (!isEmpty(parsedResult)) {
      try {
        parsedResult = JSON.parse(parsedResult);
      } catch (e) {
        parsedResult = currentTask.result;
      }
    }
    return {
      contextName: currentTask.contextName,
      contextId: currentTask.contextId,
      result: parsedResult,
      progressDescription: parsedDescription,
      state: currentTask.state,
      status: currentTask.status,
      progress: currentTask.progress,
    };
  },

  monitorTask: task(function* (taskIdentifier) {
    if (this.hasTask(taskIdentifier)) {
      if (this.isTaskFinished(taskIdentifier)) {
        return;
      }
    }

    let currentTask = null;
    // If the first time, create a task
    if (!this.hasTask(taskIdentifier)) {
      let taskStatus = yield this.getTaskProgress(taskIdentifier);
      currentTask = EmberObject.create();
      Object.assign(currentTask, taskStatus);
      this.currentTasks.pushObject(currentTask);
    } else {
      currentTask = this.getTask(taskIdentifier);
    }

    if (this.isTaskFinished(taskIdentifier)) {
      currentTask.setProperties(this.parseTask(currentTask));
      return;
    }

    while (!this.isTaskFinished(taskIdentifier)) {
      let taskStatus = yield this.getTaskProgress(taskIdentifier);
      currentTask = this.getTask(taskIdentifier);
      if (!isEmpty(currentTask)) {
        currentTask.setProperties(this.parseTask(taskStatus));
        yield timeout(2000);
      } else {
        console.log(`Could not retrieve task ${taskIdentifier}`);
        console.log('Current tasks are');
        console.log(this.currentTasks);
        return;
      }
    }
  }),

  isTaskFinished(taskId) {
    let currentTask = this.getTask(taskId);
    if (!isEmpty(currentTask)) {
      let progress = parseInt(currentTask.progress, 10);
      let currentState = parseInt(currentTask.state, 10);
      if (
        progress === 100 ||
        currentState === this.TASK_STATE.FINISHED ||
        currentState === this.TASK_STATE.ABORT
      ) {
        return true;
      }
    }
    return false;
  },

  hasTask(taskId) {
    let possibleTask = this.currentTasks.findBy('taskIdentifier', taskId);
    return !isEmpty(possibleTask);
  },

  hasTaskByContext(context, contextId) {
    let task = this.currentTasks.find((item) => {
      if (item.get('contextName') === context) {
        if (item.get('contextId') === contextId) {
          return true;
        }
      }
    });

    if (!isEmpty(task)) {
      return true;
    }
    return false;
  },

  getTaskByContext(context, contextId) {
    let task = this.currentTasks.find((item) => {
      if (item.get('contextName') === context) {
        if (item.get('contextId') === contextId) {
          return item;
        }
      }
    });
    if (!isEmpty(task)) {
      return task;
    }
    return null;
  },

  addTask(taskId, taskInfo) {
    if (!isEmpty(taskId)) {
      if (!this.hasTask(taskId)) {
        this.currentTasks.pushObject(taskInfo);
      }
    }
  },

  getTask(taskId) {
    if (this.hasTask(taskId)) {
      return this.currentTasks.findBy('taskIdentifier', taskId);
    }
  },

  getUrlContent(url) {
    return this.ajax.request(url, {
      method: 'GET',
    });
  },

  removeTask(taskId) {
    let task = this.getTask(taskId);
    this.currentTasks.removeObject(task);
  },

  removeTaskByContext(context, contextId) {
    let task = this.currentTasks.find((item) => {
      if (item.get('contextName') === context) {
        if (item.get('contextId') === contextId) {
          return true;
        }
      }
    });

    if (!isEmpty(task)) {
      this.currentTasks.removeObject(task);
    }
  },

  getTaskProgress(taskId) {
    return this.getUrlContent(`/api/task-progress/${taskId}`);
  },

  clearTasks() {
    this.currentTasks.clear();
  },

  isTaskRunning(task) {
    let { progress, state, status } = task;
    if (progress === 100 || progress === '100') {
      return false;
    }
    // Abort
    if (status === '500' || status === 500) {
      return false;
    }
    // Finished
    if (state === '250' || state === 250) {
      return false;
    }
    // State = Abort
    if (state === '500' || state === 500) {
      return false;
    }
    return true;
  },
});
