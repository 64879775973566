import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Constants from 'weldnote/utils/constants';
import moment from 'moment';
import { isGMAW } from 'weldnote/utils/rules/welding-process';
import {
  isFilletWeld,
  isButtWeld,
  isOverlayWeld,
  isPartialPenetrationButtWeld,
} from 'weldnote/utils/rules/weld-type';
import { calculateShielding } from 'weldnote/utils/certification-rules';
import WelderCertificate from 'weldnote/models/welder-certificate';
import WeldType from 'weldnote/models/weld-type';
import BaseMaterialGroup from 'weldnote/models/base-material-group';
import WeldingDetail from 'weldnote/models/welding-detail';
import FillerMaterialGroup from 'weldnote/models/filler-material-group';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import IndustryCode from 'weldnote/models/industry-code';
import WeldingProcess from 'weldnote/models/welding-process';
import WeldingPosition from 'weldnote/models/welding-position';
import { NumericalRange, WelderCertificateApprovalRange } from './wopq-range-interface';
import BaseMaterial from 'weldnote/models/base-material';
import FillerMaterial from 'weldnote/models/filler-material';
import ElectrodeType from 'weldnote/models/electrode-type';
import ProcessMecanization from 'weldnote/models/process-mecanization';
import {
  isAutomaticMecanization,
  isMechanizedMecanization,
} from 'weldnote/utils/rules/process-mecanization';
import {
  AutomaticJointTracking,
  TestPieceValuesSingleProcess,
  AutomaticArcVoltageControl,
  VisualControl,
  WeldingOperatorSpecificWeldingDetails,
} from '../types';
import {
  isSingleSideNoBacking,
  isSingleSideMaterialBacking,
  isSingleSideGasBacking,
} from 'weldnote/utils/rules/welding-detail';

const { PRODUCT_TYPE, UNIT_SYSTEM, WELDING_DETAILS } = Constants;

export default class AwsB21ApprovalRanges implements WelderCertificateApprovalRange {
  private certificate: WelderCertificate;

  private _weldTypes: WeldType[];

  private _baseMaterials: BaseMaterialGroup[];

  private allWeldingDetails: WeldingDetail[];

  private _fillerMaterials: FillerMaterialGroup[];

  private _weldingProcesses: WeldingProcess[];

  private _weldingPositions: WeldingPosition[];

  private _transferModes: ArcTransferMode[];

  private unitSystem: string;

  constructor(certificate: WelderCertificate, data: any, unitSystem: string) {
    this.certificate = certificate;
    this._weldTypes = data.allWeldTypes;
    this._baseMaterials = data.allBaseMaterials;
    this.allWeldingDetails = data.allWeldingDetails;
    this._fillerMaterials = data.allFillerMaterials;
    this._weldingProcesses = data.allWeldingProcesses;
    this._weldingPositions = data.allWeldingPositions;
    this._transferModes = data.allTransferModes;
    this.unitSystem = unitSystem;
    if (unitSystem === null) {
      throw new Error('Unit System is required for AWS B2.1 Approval Ranges');
    }
  }

  processMecanization(testPiece: ProcessMecanization): ProcessMecanization[] {
    if (!testPiece && isEmpty(testPiece)) {
      return [];
    }
    if (isAutomaticMecanization(testPiece)) {
      return [testPiece];
    }
    if (isMechanizedMecanization(testPiece)) {
      return [testPiece];
    }
    return [];
  }

  electrodeType(
    electrode: ElectrodeType,
    _processValues: TestPieceValuesSingleProcess
  ): ElectrodeType[] {
    return this.electrodeTypeRoot(electrode);
  }

  getIndustryCode(code: IndustryCode | undefined): string {
    if (code) {
      return get(code, 'id');
    }
    return '';
  }

  get industryCodeId() {
    let { standard } = this;
    let code = get(standard, 'code');
    if (code) {
      return get(code, 'id');
    }
    return '';
  }

  get allWeldingProcesses() {
    return this._weldingProcesses.filter(
      (process) => this.getIndustryCode(get(process, 'industryCode')) === this.industryCodeId
    );
  }

  get allWeldingPositions() {
    return this._weldingPositions.filter(
      (position) => this.getIndustryCode(get(position, 'industryCode')) === this.industryCodeId
    );
  }

  get allTransferModes() {
    return this._transferModes.filter(
      (transferMode) =>
        this.getIndustryCode(get(transferMode, 'industryCode')) === this.industryCodeId
    );
  }

  get allWeldTypes() {
    return this._weldTypes.filter(
      (weldType) => this.getIndustryCode(get(weldType, 'industryCode')) === this.industryCodeId
    );
  }

  get allBaseMaterials() {
    return this._baseMaterials.filter(
      (baseMaterial) => this.getIndustryCode(get(baseMaterial, 'code')) === this.industryCodeId
    );
  }

  get allFillerMaterials() {
    return this._fillerMaterials.filter(
      (fillerMaterial) => this.getIndustryCode(get(fillerMaterial, 'code')) === this.industryCodeId
    );
  }

  get standard() {
    return this.certificate.get('standard');
  }

  get productType() {
    return this.certificate.get('productTypeTestPiece');
  }

  get isPipe() {
    let { productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PIPE;
    }
    return false;
  }

  get isPlate() {
    let { productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PLATE;
    }
    return false;
  }

  get isPipePlate() {
    let { productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PIPE_PLATE;
    }
    return false;
  }

  get requalificationPeriod() {
    return this.certificate.get('requalificationPeriod');
  }

  get weldTypeTestPiece() {
    return this.certificate.get('weldTypeTestPiece');
  }

  get transferModeRoot() {
    return this.certificate.get('arcTransferModeRootTestPiece');
  }

  get transferModeFill() {
    return this.certificate.get('arcTransferModeFillTestPiece');
  }

  get transferModeCap() {
    return this.certificate.get('arcTransferModeCapTestPiece');
  }

  get isMetric() {
    return this.unitSystem === UNIT_SYSTEM.METRIC;
  }

  get isImperial() {
    return this.unitSystem === UNIT_SYSTEM.IMPERIAL;
  }

  _findWeldingPositions(codes: string[] = []): WeldingPosition[] {
    let results: WeldingPosition[] = [];
    codes.forEach((position) => {
      let foundPosition = this.allWeldingPositions.findBy('code', position);
      if (foundPosition) {
        results.push(foundPosition);
      }
    });
    return results;
  }

  weldingPosition(position: WeldingPosition): WeldingPosition[] {
    if (isEmpty(position)) {
      return [];
    }
    let { code } = position;
    if (!code) {
      return [];
    }
    let result = [code];
    if (this.isPlate) {
      if (code === '1G') {
        result = ['1G', '1F'];
      } else if (code === '2G') {
        result = ['1G', '1F', '2G', '2F'];
      } else if (code === '3G') {
        result = ['1G', '3G', '1F', '2F', '3F'];
      } else if (code === '4G') {
        result = ['1G', '4G', '1F', '2F', '4F'];
      } else if (code === '3G+4G') {
        result = ['1G', '3G', '4G', '1F', '2F', '3F', '4F'];
      } else if (code === '2G+3G+4G') {
        result = ['1G', '2G', '3G', '4G', '5G', '6G', '1F', '2F', '3F', '4F', '5F'];
      } else if (code === '1F') {
        result = ['1F'];
      } else if (code === '2F') {
        result = ['1F', '2F'];
      } else if (code === '3F') {
        result = ['1F', '2F', '3F'];
      } else if (code === '4F') {
        result = ['1F', '2F', '4F'];
      } else if (code === '3F+4F') {
        result = ['1F', '2F', '3F', '4F', '5F'];
      } else {
        result = [code];
      }
    } else if (this.isPipe || this.isPipePlate) {
      if (code === '1G') {
        result = ['1G', '1F', '2F'];
      } else if (code === '2G') {
        result = ['1G', '1F', '2G', '2F'];
      } else if (code === '3G') {
        result = ['1G', '3G', '1F', '2F', '3F'];
      } else if (code === '4G') {
        result = ['1G', '4G', '1F', '2F', '4F'];
      } else if (code === '5G') {
        result = ['1G', '3G', '4G', '5G', '1F', '2F', '3F', '4F', '5F'];
      } else if (code === '6G') {
        result = ['1G', '2G', '3G', '4G', '5G', '6G', '1F', '2F', '3F', '4F', '5F'];
      } else if (code === '1F') {
        result = ['1F'];
      } else if (code === '2F') {
        result = ['1F', '2F'];
      } else if (code === '3F') {
        result = ['1F', '2F', '3F'];
      } else if (code === '4F') {
        result = ['1F', '2F', '4F'];
      } else if (code === '5F') {
        result = ['1F', '2F', '3F', '4F', '5F'];
      } else {
        result = [code];
      }
    }

    return this._findWeldingPositions(result);
  }

  internalDeadline(previousDate: Date): Date | null {
    if (isEmpty(previousDate)) {
      return null;
    }
    let newDate = moment(previousDate).add(6, 'M');
    return newDate.toDate();
  }

  externalDeadline(previousDate: Date): Date | null {
    if (isEmpty(previousDate)) {
      return null;
    }

    return null;
  }

  _filterProcessByName(processes: string[]) {
    let result: WeldingProcess[] = [];
    processes.forEach((processName) => {
      let process = this.allWeldingProcesses.findBy('shortDesignation', processName);
      if (process) {
        result.pushObject(process);
      }
    });
    return result;
  }

  _calculateWeldingProcess(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    let result: WeldingProcess[] = [];
    if (isGMAW(process)) {
      result = this._filterProcessByName(['GMAW', 'FCAW']);
    }

    if (isEmpty(result)) {
      result = [process];
    }
    return result;
  }

  weldingProcessRoot(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }

    return this._calculateWeldingProcess(process);
  }

  weldingProcessFill(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    return this._calculateWeldingProcess(process);
  }

  weldingProcessCap(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    return this._calculateWeldingProcess(process);
  }

  _findWeldingDetails(details: string[] = []) {
    let results: WeldingDetail[] = [];
    details.forEach((detail) => {
      let detailInstance = this.allWeldingDetails.findBy('shortDesignation', detail);
      if (detailInstance) {
        results.pushObject(detailInstance);
      }
    });
    return results;
  }

  weldingDetails(details: WeldingDetail[]): WeldingDetail[] {
    let result: WeldingDetail[] = [];
    if (isSingleSideNoBacking(details)) {
      result.pushObjects(
        this._findWeldingDetails([
          WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
          WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING,
          WELDING_DETAILS.BOTH_SIDES,
        ])
      );
    } else if (isSingleSideGasBacking(details)) {
      result.pushObjects(
        this._findWeldingDetails([
          WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
          WELDING_DETAILS.BOTH_SIDES,
        ])
      );
    } else if (isSingleSideMaterialBacking(details)) {
      result.pushObjects(this._findWeldingDetails([WELDING_DETAILS.BOTH_SIDES]));
    }

    details.forEach((position) => {
      result.pushObject(position);
    });

    return result;
  }

  _toRange(min: number, max: number | null): NumericalRange {
    return {
      min,
      max,
    };
  }

  get _emptyRange(): NumericalRange {
    return {
      min: null,
      max: null,
    };
  }

  get _fullRange(): NumericalRange {
    return {
      min: 0,
      max: null,
    };
  }

  diameter(testPiece: number): NumericalRange {
    if (!testPiece) {
      return this._emptyRange;
    }
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    return this._fullRange;
  }

  get isButtWeldOrPartialPenetrationWeld() {
    return (
      isButtWeld(this.weldTypeTestPiece) || isPartialPenetrationButtWeld(this.weldTypeTestPiece)
    );
  }

  get isFilletWeld() {
    return isFilletWeld(this.weldTypeTestPiece);
  }

  get isOverlayWeld() {
    return isOverlayWeld(this.weldTypeTestPiece);
  }

  thickness(testPiece: number): NumericalRange {
    if (!testPiece) {
      return this._emptyRange;
    }

    return this._fullRange;
  }

  baseMaterial(testPiece: BaseMaterial): BaseMaterialGroup[] {
    if (!testPiece && isEmpty(testPiece)) {
      return [];
    }

    let materialGroup = get(testPiece, 'materialGroup');
    let groupNumber = '';
    if (materialGroup) {
      groupNumber = get(materialGroup, 'groupNumber') || '';
    }
    let groups1 = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '34',
      '41',
      '42',
      '43',
      '44',
      '45',
      '46',
      '47',
    ];
    if (groups1.includes(groupNumber)) {
      return this.allBaseMaterials.filter((material) => {
        let groupNumber = get(material, 'groupNumber');
        if (groupNumber) {
          return groups1.includes(groupNumber);
        } else {
          return false;
        }
      });
    }

    let groups2 = ['21', '22', '23', '24', '25', '26', '27'];
    if (groups2.includes(groupNumber)) {
      return this.allBaseMaterials.filter((material) => {
        let groupNumber = get(material, 'groupNumber');
        if (groupNumber) {
          return groups2.includes(groupNumber);
        } else {
          return false;
        }
      });
    }

    let groups3 = ['31', '32', '33', '35'];
    if (groups3.includes(groupNumber)) {
      return this.allBaseMaterials.filter((material) => {
        return get(material, 'groupNumber') === groupNumber;
      });
    }

    let groups4 = ['34', '42'];
    if (groups4.includes(groupNumber)) {
      return this.allBaseMaterials.filter((material) => {
        let groupNumber = get(material, 'groupNumber');
        if (groupNumber) {
          return ['34', '41', '42', '43', '44', '45', '46', '47'].includes(groupNumber);
        } else {
          return false;
        }
      });
    }

    let groups5 = ['51', '52', '53', '54', '61', '62'];
    if (groups5.includes(groupNumber)) {
      return this.allBaseMaterials.filter((material) => {
        let groupNumber = get(material, 'groupNumber');
        if (groupNumber) {
          return groups5.includes(groupNumber);
        } else {
          return false;
        }
      });
    }

    let groups6 = ['81', '83'];
    if (groups6.includes(groupNumber)) {
      return this.allBaseMaterials.filter((material) => {
        let groupNumber = get(material, 'groupNumber');
        if (groupNumber) {
          return groups6.includes(groupNumber);
        } else {
          return false;
        }
      });
    }

    // TP = AR
    return this.allBaseMaterials.filter((material) => get(material, 'groupNumber') === groupNumber);
  }

  parseGroupNumber(number: string | undefined): number {
    if (isEmpty(number)) {
      return NaN;
    }
    if (!number) {
      return NaN;
    }
    let result = parseInt(number, 10);
    if (!isNaN(result)) {
      return result;
    }
    let match = number.match(/\d+/g);
    if (match && !isEmpty(match)) {
      let [numericValue] = match;
      return parseInt(numericValue);
    }
    return NaN;
  }

  fillerMaterial(testPiece: FillerMaterial): FillerMaterialGroup[] {
    if (!testPiece && isEmpty(testPiece)) {
      return [];
    }

    let group = get(testPiece, 'group');
    let groupNumber = this.parseGroupNumber(get(group, 'groupNumber'));
    if (isNaN(groupNumber)) {
      return [get(testPiece, 'group')];
    }

    if (groupNumber >= 1 && groupNumber <= 5) {
      return this.allFillerMaterials.filter((m) => {
        return this.parseGroupNumber(m.groupNumber) <= groupNumber;
      });
    }

    if (groupNumber === 6) {
      return this.allFillerMaterials.filter((m) => {
        return this.parseGroupNumber(m.groupNumber) === 6;
      });
    }

    if (groupNumber >= 20 && groupNumber <= 29) {
      return this.allFillerMaterials.filter((m) => {
        let currentGroupNumber = this.parseGroupNumber(m.groupNumber);
        return currentGroupNumber >= 20 && currentGroupNumber <= 29;
      });
    }

    if (groupNumber >= 30 && groupNumber <= 39) {
      return [group];
    }

    if (groupNumber >= 40 && groupNumber <= 49) {
      return this.allFillerMaterials.filter((m) => {
        let currentGroupNumber = this.parseGroupNumber(m.groupNumber);
        return (
          (currentGroupNumber >= 1 && currentGroupNumber <= 5) ||
          (currentGroupNumber >= 40 && currentGroupNumber <= 49)
        );
      });
    }

    if (groupNumber >= 50 && groupNumber <= 59) {
      return this.allFillerMaterials.filter((m) => {
        let currentGroupNumber = this.parseGroupNumber(m.groupNumber);
        return currentGroupNumber >= 50 && currentGroupNumber <= 59;
      });
    }

    if (groupNumber === 61) {
      return this.allFillerMaterials.filter((m) => {
        let currentGroupNumber = this.parseGroupNumber(m.groupNumber);
        return currentGroupNumber === 61;
      });
    }

    if (groupNumber === 71) {
      return [group];
    }

    if (groupNumber === 91) {
      return this.allFillerMaterials.filter((m) => {
        let currentGroupNumber = this.parseGroupNumber(m.groupNumber);
        return currentGroupNumber === 91;
      });
    }

    return [group];
  }

  weldType(testPiece: WeldType): WeldType[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    if (isButtWeld(testPiece)) {
      return this.allWeldTypes.filter(
        (weldType) =>
          isPartialPenetrationButtWeld(weldType) || isFilletWeld(weldType) || isButtWeld(weldType)
      );
    } else if (isFilletWeld(testPiece)) {
      return [testPiece];
    } else if (isOverlayWeld(testPiece)) {
      return [testPiece];
    } else if (isPartialPenetrationButtWeld(testPiece)) {
      return this.allWeldTypes.filter(
        (weldType) => isFilletWeld(weldType) || isPartialPenetrationButtWeld(weldType)
      );
    }
    return [testPiece];
  }

  shielding(testPiece: WeldingProcess): string | null {
    if (testPiece) {
      let designation = get(testPiece, 'shortDesignation');
      return calculateShielding(designation);
    } else {
      return null;
    }
  }

  transferMode(testPiece: ArcTransferMode): ArcTransferMode[] {
    if (!testPiece) {
      return [];
    }

    if (testPiece.isShortArc) {
      return [testPiece];
    }

    if (testPiece.isGlobular || testPiece.isSpray || testPiece.isPulsedSpray) {
      return this.allTransferModes.filter((t) => t.isSpray || t.isGlobular || t.isPulsedSpray);
    }

    return [testPiece];
  }

  electrodeTypeRoot(testPiece: ElectrodeType): ElectrodeType[] {
    if (!testPiece) {
      return [];
    }
    return [testPiece];
  }

  electrodeTypeFill(testPiece: ElectrodeType): ElectrodeType[] {
    if (!testPiece) {
      return [];
    }
    return [testPiece];
  }

  electrodeTypeCap(testPiece: ElectrodeType): ElectrodeType[] {
    if (!testPiece) {
      return [];
    }
    return [testPiece];
  }

  automaticJointTracking(
    testPiece: AutomaticJointTracking | null,
    process: TestPieceValuesSingleProcess
  ): AutomaticJointTracking[] {
    if (process.processMecanization.isPresent()) {
      let processMecanization = process.processMecanization.get();
      if (processMecanization.isAutomatic) {
        return [];
      } else if (processMecanization.isMechanized) {
        if (testPiece === AutomaticJointTracking.WITH) {
          return [AutomaticJointTracking.WITH];
        } else if (testPiece === AutomaticJointTracking.WITHOUT) {
          return [AutomaticJointTracking.WITH, AutomaticJointTracking.WITHOUT];
        }
      }
    }
    return [];
  }

  automaticVoltageControl(
    testPiece: AutomaticArcVoltageControl | null,
    process: TestPieceValuesSingleProcess
  ): AutomaticArcVoltageControl[] {
    if (process.processMecanization.isPresent()) {
      let processMecanization = process.processMecanization.get();
      if (processMecanization.isAutomatic) {
        return [];
      } else if (processMecanization.isMechanized) {
        if (testPiece === AutomaticArcVoltageControl.WITH) {
          return [AutomaticArcVoltageControl.WITH];
        } else if (testPiece === AutomaticArcVoltageControl.WITHOUT) {
          return [AutomaticArcVoltageControl.WITH, AutomaticArcVoltageControl.WITHOUT];
        }
      }
    }
    return [];
  }

  visualControl(
    testPiece: VisualControl | null,
    _process: TestPieceValuesSingleProcess
  ): VisualControl[] {
    if (!testPiece) {
      return [];
    }
    if (testPiece === VisualControl.DIRECT) {
      return [VisualControl.DIRECT];
    } else if (testPiece === VisualControl.REMOTE) {
      return [VisualControl.REMOTE];
    }
    return [];
  }

  weldingDetailsForOperator(
    _testPiece: WeldingOperatorSpecificWeldingDetails,
    _process: TestPieceValuesSingleProcess
  ): WeldingOperatorSpecificWeldingDetails[] {
    return [];
  }
}
