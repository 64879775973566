export default {
  COMPONENT_DRAWING: {
    TITLE: 'title',
    REFERENCE_NUMBER: 'referenceNumber',
    DRAWING_CONTENT: 'drawingContent',
    REVISION: 'revision',
    REVISION_STATUS: 'revisionStatus',
    REFERENCED_DRAWING: 'referencedDrawing',
  },
};
