import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class TestLocation extends BaseModel {
  metadata = {
    modelName: 'test-location',
    listRoute: 'test-locations',
    editRoute: 'test-location',
  };

  validations = {
    name: {
      presence: {
        presence: {
          message(_key: string, _value: string, model: TestLocation): string {
            return model.intl.t('generic.error.input-value');
          },
        },
      },
    },
  };

  @attr('string')
  declare name?: string;

  get visualLabel() {
    return this.name;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'test-location': TestLocation;
  }
}
