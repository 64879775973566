import Service, { service } from '@ember/service';

export default class AlertMessageService extends Service {
  @service
  declare toast: any;

  /**
   * Displays a success (green) message popup
   *
   * @param message - The text message to display
   * @param title - The title of the message
   * @param options - Options to pass to the underlying toast service
   */
  success(message: string, title = '', options = {}) {
    this.toast.success(message, title, options);
  }

  /**
   * Displays an info (blue) message popup
   *
   * @param message - The text message to display
   * @param title - The title of the message
   * @param options - Options to pass to the underlying toast service
   */
  info(message: string, title = '', options = {}) {
    this.toast.info(message, title, options);
  }

  /**
   * Displays an error (red) message popup
   *
   * @param message - The text message to display
   * @param title - The title of the message
   * @param options - Options to pass to the underlying toast service
   */
  error(message: string, title = '', options = {}) {
    this.toast.error(message, title, options);
  }
}
