import {
  isStandardISO,
  isStandardASME,
  isStandardAWS,
  isStandardAWSB21,
} from 'weldnote/utils/standards';
import { isButtWeld } from 'weldnote/utils/rules/weld-type';
import Constants from 'weldnote/utils/constants';

const { PRODUCT_TYPE, UNIT_SYSTEM } = Constants;

/*
  Return the Product Type approval range with the following structure:
  {
    productType: [] // Array with the product types
    restrictions: [] // Array of objects with restrictions that apply to any of the values in the `productType`key
    structure if the following:

    productType: null, // The product type to which this applies
      diameter: {
        unitSystem: null, // The unit system for the restriction
        value: null, // the minimum value of the diameter (inclusive)
      },
      weldingPositions: [], // welding positions (if any) that must exist
  }
 */
export function calculatePqrProductTypeApprovalRange(
  standard,
  weldType,
  testPiece,
  unitSystem = UNIT_SYSTEM.METRIC
) {
  let result = {
    productType: [],
    restrictions: [],
  };
  // Restrictions array includes objects with the following type
  /* {
      productType: null,
      diameter: {
        unitSystem: null,
        value: null,
      },
      weldingPositions: [],
    }
  */
  if (isStandardISO(standard)) {
    if (isButtWeld(weldType) && testPiece === PRODUCT_TYPE.PIPE) {
      result.productType = [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
    } else if (testPiece === PRODUCT_TYPE.PLATE) {
      result.productType = [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
      result.restrictions.pushObject({
        productType: PRODUCT_TYPE.PIPE,
        diameter: {
          value: 150,
          unitSystem: UNIT_SYSTEM.METRIC,
        },

        weldingPositions: ['PA', 'PC'],
      });
      result.restrictions.pushObject({
        productType: PRODUCT_TYPE.PIPE,
        diameter: {
          value: 500,
          unitSystem: UNIT_SYSTEM.METRIC,
        },
      });
    } else if (testPiece === PRODUCT_TYPE.PIPE) {
      result.productType = [PRODUCT_TYPE.PIPE];
    }
  } else if (isStandardASME(standard) || isStandardAWSB21(standard)) {
    if (testPiece === PRODUCT_TYPE.PIPE || testPiece === PRODUCT_TYPE.PLATE) {
      result.productType = [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
    }
  } else if (isStandardAWS(standard)) {
    if (testPiece === PRODUCT_TYPE.PIPE) {
      result.productType = [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
    } else if (testPiece === PRODUCT_TYPE.PLATE) {
      result.productType = [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
      if (unitSystem === UNIT_SYSTEM.METRIC) {
        result.restrictions.pushObject({
          productType: PRODUCT_TYPE.PIPE,
          diameter: {
            value: 600,
            unitSystem: UNIT_SYSTEM.METRIC,
          },
        });
      } else if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
        result.restrictions.pushObject({
          productType: PRODUCT_TYPE.PIPE,
          diameter: {
            value: 24,
            unitSystem: UNIT_SYSTEM.IMPERIAL,
          },
        });
      }
    }
  } else {
    result.productType = [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
  }
  return result;
}
