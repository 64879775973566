import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import { memberAction } from 'ember-api-actions';
import ExaminingBody from './examining-body';
import Person from './person';
import FileUpload from './file-upload';

export default class Inspector extends BaseModel {
  @attr('string')
  declare inspectorName?: string;

  @attr('string')
  declare inspectorStamp?: string;

  @attr('string')
  declare inspectorId?: string;

  @attr('string')
  declare inspectorType?: string;

  @attr('date')
  declare dateBirth?: Date;

  @belongsTo('examining-body')
  declare examiningBody: AsyncBelongsTo<ExaminingBody>;

  @belongsTo('person')
  declare person: AsyncBelongsTo<Person>;

  @belongsTo('file-upload')
  declare inspectorReport: AsyncBelongsTo<FileUpload>;

  @attr('string')
  declare inspectorReportPath: string;

  get visualLabel(): string {
    return this.inspectorName || '';
  }

  validations = {
    inspectorName: { presence: true },
    inspectorStamp: { presence: true },
  };

  metadata = {
    modelName: 'inspector',
    inspectorName: { required: true },
    inspectorStamp: { required: true },
  };

  get name() {
    return this.inspectorName;
  }

  get stamp() {
    return this.inspectorStamp;
  }

  createInspectorReport = memberAction({ path: 'createInspectorReport' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    inspector: Inspector;
  }
}
