import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class IndexRoute extends Route {
  @service('weldnote-data')
  data;

  model() {
    let welds = this.data.queryProjectWelds(
      {
        project: get(this.modelFor('project'), 'id'),
      },
      'component,wps,inspections,inspections.report,inspections.test,inspections.testLocation,inspections.testStageName,weldersRootLayer,weldersFillLayer,weldersCapLayer'
    );
    return welds;
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    set(controller, 'project', this.modelFor('project'));
    set(controller, 'welds', model);
    set(controller, 'totalCount', get(model, 'meta.records'));
  }
}
