import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';

export default class PostWeldTreatmentMethodsRoute extends ListRoute {
  @service
  store;

  model() {
    return this.store.findAll('post-weld-treatment-method');
  }
}
