import Helper from '@ember/component/helper';
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import Const from 'weldnote/utils/constants';

const { DEFAULT_DATE_FORMAT } = Const;

export function formatDate(params, hash) {
  let { format, date, time } = hash;
  if (!format) {
    format = DEFAULT_DATE_FORMAT;
  }
  if (!isEmpty(date)) {
    if (time) {
      date = moment(parseFloat(date));
    }
    return moment(date).format(format);
  }
  return '';
}

export default Helper.helper(formatDate);
