import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class IeisEditRoute extends Route {
  @service
  userSession;

  @service
  store;

  @service
  router;

  beforeModel() {
    super.beforeModel(...arguments);
    let { userSession: session } = this;
    if (!session.hasFeature('iies')) {
      this.router.transitionTo('index');
    }
  }

  model({ id }) {
    return this.store.findRecord('ieis', id, {
      include: 'wps,lineClass,referenceDrawing,componentDrawing',
    });
  }
}
