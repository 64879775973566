export default {
  PROJECT_WELD: {
    WELD_NUMBER: 'weldNumber',
    SOURCE_WELD: 'sourceWeld',
    PREVIOUS_WELD: 'previousWeld',
    PROJECT: 'project',
    COMPONENT: 'component',
    WELD_COMPONENT_NUMBER: 'weldComponentNumber',
    WELD_TYPE: 'weldType',
    BASE_MATERIAL1: 'baseMaterial1',
    BASE_MATERIAL2: 'baseMaterial2',
    SPECIFIC_BASE_MATERIAL1: 'specificBaseMaterial1',
    SPECIFIC_BASE_MATERIAL2: 'specificBaseMaterial2',
    WPS: 'wps',
    FILLER_MATERIAL_ROOT: 'fillerMaterialRoot',
    FILLER_MATERIAL_FILL: 'fillerMaterialFill',
    FILLER_MATERIAL_CAP: 'fillerMaterialCap',
    SPECIFIC_FILLER_MATERIAL_PROCESS1: 'specificFillerMaterialProcess1',
    SPECIFIC_FILLER_MATERIAL_PROCESS2: 'specificFillerMaterialProcess2',
    SPECIFIC_FILLER_MATERIAL_PROCESS3: 'specificFillerMaterialProcess3',
    PWHT_REPORT: 'pwhtReport',
    FITTER: 'fitter',
    TACK_WELDER: 'tackWelder',
    WELDERS_ROOT_LAYER: 'weldersRootLayer',
    WELDERS_FILL_LAYER: 'weldersFillLayer',
    WELDERS_CAP_LAYER: 'weldersCapLayer',
    FIT_DATE: 'fitDate',
    WELD_DATE: 'weldDate',
    ROOT_WELD_DATE: 'rootWeldDate',
    FILL_WELD_DATE: 'fillWeldDate',
    CAP_WELD_DATE: 'capWeldDate',
    PWHT_DATE_COMPLETED: 'pwhtDateCompleted',
    TEST_DATE: 'testDate',
    PROCESSED_DATE: 'processedDate',
    SHIPPED_DATE: 'shippedDate',
    STATUS: 'status',
    TYPE: 'type',
    REQUIRES_PWHT: 'requiresPwht',
    COMPLETED_DATE: 'completedDate',
    COMPLETED_STATUS: 'completedStatus',
  },
};
