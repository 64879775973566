import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import { getProperties, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import { memberAction } from 'ember-api-actions';
import Constants from 'weldnote/utils/constants';
import GeneralDrawing from './general-drawing';
import ComponentDrawing from './component-drawing';
import ProjectLineClass from './project-line-class';
import Project from './project';
import TestInspectionClass from './test-inspection-class';

const { COMPONENT_SITUATION } = Constants;

export default class ProjectComponent extends BaseModel {
  @attr('string')
  declare componentNumber?: string;

  @attr('string')
  declare componentName?: string;

  @belongsTo('general-drawing', { async: false })
  declare originalDrawing: GeneralDrawing;

  @belongsTo('component-drawing', { async: false })
  declare componentDrawing: ComponentDrawing;

  @belongsTo('project-line-class')
  declare lineClass: AsyncBelongsTo<ProjectLineClass>;

  @belongsTo('project')
  declare project: AsyncBelongsTo<Project>;

  @attr('date')
  declare completedDate?: Date;

  @attr('date')
  declare testedDate?: Date;

  @attr('date')
  declare processedDate?: Date;

  @attr('date')
  declare shippedDate?: Date;

  @attr('date')
  declare lastRefresh?: Date;

  @attr('date')
  declare lastWeldUpdate?: Date;

  @attr('number')
  declare progressState?: number;

  @attr()
  declare statistics?: any;

  @attr('boolean')
  declare outdated?: boolean;

  @attr('string')
  declare situation?: string;

  @belongsTo('test-inspection-class')
  declare testInspectionClass: TestInspectionClass;

  @computed('componentNumber', 'componentName')
  get visualLabel() {
    let { componentName, componentNumber } = getProperties(
      this,
      'componentName',
      'componentNumber'
    );
    if (!isEmpty(this.componentName)) {
      return `${componentNumber} - ${componentName}`;
    }
    return componentNumber;
  }

  metadata = {
    modelName: 'project-component',
    listRoute: 'project-components',
    editRoute: 'project-component',
  };

  get isComponentDeleted(): boolean {
    return this.situation === COMPONENT_SITUATION.DELETED;
  }

  updateStatistics = memberAction({ path: 'updateStatistics', type: 'PUT' });

  restoreComponent = memberAction({ path: 'restoreComponent', type: 'PUT' });

  duplicateComponent = memberAction({ path: 'duplicateComponent', type: 'PUT' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-component': ProjectComponent;
  }
}
