import { isEmpty } from '@ember/utils';
import { assert } from '@ember/debug';
import { get, set } from '@ember/object';

export default class ModelSetter {
  model = null;

  constructor(model) {
    this.model = model;
  }

  getModelValue(attribute) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    return get(this, `model.${attribute}`);
  }

  setModelValue(attribute, value) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    set(this, `model.${attribute}`, value);
  }

  setModelDecimalValue(attribute, value) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    let separator = get(this, 'userSession.decimalSeparator');
    if (separator === ',') {
      if (!isEmpty(value)) {
        value = `${value}`.replace(',', '.');
      }
    }
    set(this, `model.${attribute}`, value);
  }

  setModelCollection(attribute, values) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    let collection = get(this, `model.${attribute}`);
    collection.clear();
    if (!isEmpty(values)) {
      values.forEach((value) => {
        collection.pushObject(value);
      });
    }
  }

  setModelCollectionValue(attribute, value) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    get(this, `model.${attribute}`).clear();
    if (!isEmpty(value)) {
      get(this, `model.${attribute}`).pushObject(value);
    }
  }

  clearModelCollection(attribute) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    let collection = get(this, `model.${attribute}`);
    collection.clear();
  }
}
