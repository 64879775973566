import Constants from 'weldnote/utils/constants';
import { isAllowedByPlan as wpsPlan } from './wps-plan';
import { isAllowedByPlan as certificatePlan } from './certificate-plan';
import { isAllowedByPlan as qualityPlan } from './quality-plan';

const { WELDNOTE_PLANS } = Constants;

export default function getPlan(currentPlan = '') {
  if (currentPlan === WELDNOTE_PLANS.WPS_ONLY) {
    return wpsPlan;
  } else if (currentPlan === WELDNOTE_PLANS.CERTIFICATES) {
    return certificatePlan;
  } else if (currentPlan === WELDNOTE_PLANS.QUALITY) {
    return qualityPlan;
  }
  return qualityPlan;
}
