import { service } from '@ember/service';
import { set } from '@ember/object';
import EditRoute from 'weldnote/routes/_base/edit';

export default class ProjectRoute extends EditRoute {
  @service
  store;

  modelName = 'project';

  model(params) {
    return this.store.findRecord('project', params.project_id, {
      include:
        'standard,supplier,welder-standard,construction-standard,project-client,cover-picture',
    });
  }
}
