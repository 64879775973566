import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Constants from 'weldnote/utils/constants';
import {
  isStandardASME,
  isStandardAWSB21,
  isStandardAWSD11,
  isStandardISO15614,
  isStandardISO15613,
  isStandardISO15611,
  isStandardISO15612,
  isStandardAWSD143,
} from 'weldnote/utils/standards';
import AsmeIXApprovalRanges from './asme-ix';
import AwsB21ApprovalRanges from './aws-b2-1';
import NotSupportedStandardApprovalRanges from './not-supported';
import AwsD11ApprovalRanges from './aws-d1-1';
import Iso156141ApprovalRanges from './iso-15614-1';
import Iso15613ApprovalRanges from './iso-15613';
import Iso15611ApprovalRanges from './iso-15611';
import AwsD143ApprovalRanges from './aws-d143';
import Iso15612ApprovalRanges from './iso-15612';
import WeldingProcess from 'weldnote/models/welding-process';
import PQRModel from 'weldnote/models/pqr';
import FillerMaterialCommercialDesignation from 'weldnote/models/filler-material-commercial-designation';
import BaseMaterial from 'weldnote/models/base-material';
import TypeCurrentPolarity from 'weldnote/models/type-current-polarity';
import ProcessMecanization from 'weldnote/models/process-mecanization';
import WeldingDetail from 'weldnote/models/welding-detail';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import WeldingPosition from 'weldnote/models/welding-position';
import WeldType from 'weldnote/models/weld-type';
import BaseMaterialGroup from 'weldnote/models/base-material-group';
import IndustryCode from 'weldnote/models/industry-code';

const { UNIT_SYSTEM, OVERLAY_TYPE_OPTIONS } = Constants;

export default class PqrApprovalRange {
  approvalRange: any;

  pqr: any;

  unitSystem: string;

  data: any;

  allWeldingDetails: WeldingDetail[];

  allProcessMecanizations: ProcessMecanization[];

  allBaseMaterials: BaseMaterialGroup[];

  allWeldTypes: WeldType[];

  allTypeCurrentPolarity: TypeCurrentPolarity[];

  allWeldingPositions: WeldingPosition[];

  allTransferModes: ArcTransferMode[];

  constructor(pqr: PQRModel, data: any, unitSystem = UNIT_SYSTEM.METRIC) {
    this.pqr = pqr;
    this.data = data;
    let standard = get(pqr, 'standard');
    let industryCodeId = '';
    if (standard) {
      industryCodeId = this.getIndustryCode(get(standard, 'code'));
    }
    this.allWeldTypes = data.weldTypeOptions.filter((weldType: WeldType) => {
      return this.getIndustryCode(get(weldType, 'industryCode')) === industryCodeId;
    });
    this.allWeldingDetails = data.weldingDetailOptions;
    this.allBaseMaterials = data.allBaseMaterialGroups;
    this.allProcessMecanizations = data.processMecanizationOptions;
    this.allTypeCurrentPolarity = data.typeCurrentPolarityOptions;
    this.allWeldingPositions = data.weldingPositionOptions.filter((position: WeldingPosition) => {
      return this.getIndustryCode(get(position, 'industryCode')) === industryCodeId;
    });
    this.allTransferModes = data.arcTransferModeOptions.filter((transferMode: ArcTransferMode) => {
      return this.getIndustryCode(get(transferMode, 'industryCode')) === industryCodeId;
    });
    this.unitSystem = unitSystem;
    let allData = {
      allWeldTypes: data.weldTypeOptions,
      allBaseMaterials: data.allBaseMaterialGroups,
      allProcessMecanizations: data.processMecanizationOptions,
      allTypeCurrentPolarity: data.typeCurrentPolarityOptions,
      allWeldingPositions: data.weldingPositionOptions,
      allTransferModes: data.arcTransferModeOptions,
      allWeldingDetails: data.weldingDetailOptions,
      allWeldingProcesses: data.weldingProcessOptions,
      unitSystem,
    };

    if (isStandardASME(this.standard)) {
      this.approvalRange = new AsmeIXApprovalRanges(pqr, allData);
    } else if (isStandardAWSD11(this.standard)) {
      this.approvalRange = new AwsD11ApprovalRanges(pqr, allData);
    } else if (isStandardISO15614(this.standard)) {
      this.approvalRange = new Iso156141ApprovalRanges(pqr, allData);
    } else if (isStandardAWSB21(this.standard)) {
      this.approvalRange = new AwsB21ApprovalRanges(pqr, allData);
    } else if (isStandardISO15613(this.standard)) {
      this.approvalRange = new Iso15613ApprovalRanges(pqr, allData);
    } else if (isStandardISO15611(this.standard)) {
      this.approvalRange = new Iso15611ApprovalRanges(pqr, allData);
    } else if (isStandardAWSD143(this.standard)) {
      this.approvalRange = new AwsD143ApprovalRanges(pqr, allData);
    } else if (isStandardISO15612(this.standard)) {
      this.approvalRange = new Iso15612ApprovalRanges(pqr, allData);
    } else {
      this.approvalRange = new NotSupportedStandardApprovalRanges();
    }
  }

  getIndustryCode(code: IndustryCode | undefined): string {
    if (code) {
      return get(code, 'id');
    }
    return '';
  }

  get standard() {
    return this.pqr.get('standard');
  }

  get productTypeValue() {
    return this.pqr.get('productTypeTestPiece');
  }

  get weldTypeValue() {
    return this.pqr.get('weldTypeTestPiece');
  }

  get weldTypeCode() {
    let weldType = this.pqr.get('weldTypeTestPiece');
    if (!isEmpty(weldType)) {
      return weldType.weldType;
    }
    return '';
  }

  get impactRequirements() {
    let { impactTemperature, impactRequired } = this;
    return !isEmpty(impactRequired) && impactRequired && !isNaN(parseFloat(impactTemperature));
  }

  get impactTemperature() {
    return this.pqr.get('impactTestTemperature');
  }

  get impactTestReport() {
    return this.pqr.get('impactTestReport');
  }

  get impactRequired() {
    return this.pqr.get('impactTestRequired');
  }

  get diameterValue() {
    return this.pqr.get('diameterTestPiece');
  }

  get overlayType() {
    return this.pqr.get('overlayType');
  }

  get weldingProcessRoot() {
    return this.pqr.get('weldingProcessRootTestPiece');
  }

  get layerType() {
    return this.pqr.getLayerType();
  }

  get rootLayerType() {
    return this.pqr.get('thicknessRootLayerTestPiece');
  }

  get fillLayerType() {
    return this.pqr.get('thicknessFillLayerTestPiece');
  }

  get capLayerType() {
    return this.pqr.get('thicknessCapLayerTestPiece');
  }

  get weldingProcessRootName() {
    let weldingProcess = this.pqr.get('weldingProcessRootTestPiece');
    if (!isEmpty(weldingProcess)) {
      return weldingProcess.shortDesignation;
    }
    return '';
  }

  get weldingProcessFill() {
    return this.pqr.get('weldingProcessFillTestPiece');
  }

  get weldingProcessFillName() {
    let weldingProcess = this.pqr.get('weldingProcessFillTestPiece');
    if (!isEmpty(weldingProcess)) {
      return weldingProcess.shortDesignation;
    }
    return '';
  }

  get weldingProcessCap() {
    return this.pqr.get('weldingProcessCapTestPiece');
  }

  get weldingProcessCapName() {
    let weldingProcess = this.pqr.get('weldingProcessCapTestPiece');
    if (!isEmpty(weldingProcess)) {
      return weldingProcess.shortDesignation;
    }
    return '';
  }

  get pwhtTimeTestPiece() {
    return this.pqr.get('postWeldHeatTreatmentTimeTestPiece');
  }

  get weldingProcessRootDesignation() {
    let weldingProcess = this.pqr.get('weldingProcessRootTestPiece');
    if (!isEmpty(weldingProcess)) {
      return get(weldingProcess, 'shortDesignation');
    }
    return null;
  }

  get weldingProcessFillDesignation() {
    let weldingProcess = this.pqr.get('weldingProcessFillTestPiece');
    if (!isEmpty(weldingProcess)) {
      return get(weldingProcess, 'shortDesignation');
    }
    return null;
  }

  get weldingProcessCapDesignation() {
    let weldingProcess = this.pqr.get('weldingProcessCapTestPiece');
    if (!isEmpty(weldingProcess)) {
      return get(weldingProcess, 'shortDesignation');
    }
    return null;
  }

  get baseMaterial1() {
    return this.pqr.get('baseMaterial1TestPiece');
  }

  get baseMaterial1Thickness() {
    return this.pqr.get('baseMaterial1Thickness');
  }

  get baseMaterial1ThicknessApprovalRangeMinimum() {
    return this.pqr.get('baseMaterial1ThicknessMinimumApprovalRange');
  }

  get baseMaterial1ThicknessApprovalRangeMaximum() {
    return this.pqr.get('baseMaterial1ThicknessMaximumApprovalRange');
  }

  get baseMaterial2Thickness() {
    return this.pqr.get('baseMaterial2Thickness');
  }

  get baseMaterial2ThicknessApprovalRangeMinimum() {
    return this.pqr.get('baseMaterial2ThicknessMinimumApprovalRange');
  }

  get baseMaterial2ThicknessApprovalRangeMaximum() {
    return this.pqr.get('baseMaterial2ThicknessMaximumApprovalRange');
  }

  get baseMaterial1Diameter() {
    return this.pqr.get('baseMaterial1Diameter');
  }

  get baseMaterial2Diameter() {
    return this.pqr.get('baseMaterial2Diameter');
  }

  get baseMaterial2() {
    return this.pqr.get('baseMaterial2TestPiece');
  }

  get baseMaterial1GroupName() {
    let baseMaterial1 = this.pqr.get('baseMaterial1TestPiece');
    if (!isEmpty(baseMaterial1)) {
      let materialGroup = get(baseMaterial1, 'materialGroup.groupName');
      if (!isEmpty(materialGroup)) {
        return materialGroup;
      }
    }
    return '';
  }

  get baseMaterial2GroupName() {
    let baseMaterial2 = this.pqr.get('baseMaterial2TestPiece');
    if (!isEmpty(baseMaterial2)) {
      let materialGroup = get(baseMaterial2, 'materialGroup.groupName');
      if (!isEmpty(materialGroup)) {
        return materialGroup;
      }
    }
    return '';
  }

  get smallestBaseMaterialThickness() {
    let { baseMaterial1Thickness, baseMaterial2Thickness } = this;
    let smallestBaseMaterialThickness = null;

    if (!isNaN(baseMaterial1Thickness) && !isNaN(baseMaterial2Thickness)) {
      if (baseMaterial1Thickness < baseMaterial2Thickness) {
        smallestBaseMaterialThickness = baseMaterial1Thickness;
      } else {
        smallestBaseMaterialThickness = baseMaterial2Thickness;
      }
    } else if (!isNaN(baseMaterial1Thickness)) {
      smallestBaseMaterialThickness = baseMaterial1Thickness;
    } else {
      smallestBaseMaterialThickness = this.weldedThickness;
    }

    return smallestBaseMaterialThickness;
  }

  get interpassMinimumApprovalRange() {
    return this.pqr.get('interpassTemperatureMinimumApprovalRange');
  }

  get isOverlayHardFacing() {
    let overlayType = this.pqr.get('overlayType');
    return overlayType === OVERLAY_TYPE_OPTIONS.HARD_FACING;
  }

  get weldedThickness() {
    return this.pqr.get('thicknessTotalTestPiece');
  }

  weldingProcess(testPiece: WeldingProcess) {
    if (this.approvalRange) {
      return this.approvalRange.weldingProcess(testPiece);
    } else {
      if (isEmpty(testPiece)) {
        return [];
      }
      return [testPiece];
    }
  }

  /*
  Return the Product Type approval range with the following structure:
  {
    productType: [] // Array with the product types
    restrictions: [] // Array of objects with restrictions that apply to any of the values in the `productType`key
    structure if the following:

    productType: null, // The product type to which this applies
      diameter: {
        unitSystem: null, // The unit system for the restriction
        value: null, // the minimum value of the diameter (inclusive)
      },
      weldingPositions: [], // welding positions (if any) that must exist
  }
 */
  productType(testPiece: string): string[] {
    return this.approvalRange.productType(testPiece);
  }

  weldType(weldType: WeldType) {
    return this.approvalRange.weldType(weldType);
  }

  weldingPosition(position: WeldingPosition, direction: string) {
    return this.approvalRange.weldingPosition(position, direction);
  }

  arcTransferModeRoot(transferMode: ArcTransferMode) {
    return this.approvalRange.transferMode(transferMode, this.weldingProcessRoot);
  }

  arcTransferModeFill(transferMode: ArcTransferMode) {
    return this.approvalRange.transferMode(transferMode, this.weldingProcessFill);
  }

  arcTransferModeCap(transferMode: ArcTransferMode) {
    return this.approvalRange.transferMode(transferMode, this.weldingProcessCap);
  }

  weldingThickness(thickness: Number) {
    return this.approvalRange.weldedThickness(thickness);
  }

  weldingThicknessRoot(thickness: Number) {
    return this.approvalRange.thicknessProcess(thickness, this.rootLayerType);
  }

  weldingThicknessFill(thickness: Number) {
    return this.approvalRange.thicknessProcess(thickness, this.fillLayerType);
  }

  weldingThicknessCap(thickness: Number) {
    return this.approvalRange.thicknessProcess(thickness, this.capLayerType);
  }

  weldingDetails(weldingDetails: WeldingDetail[]) {
    return this.approvalRange.weldingDetails(weldingDetails);
  }

  processMecanizationRoot(processMecanization: ProcessMecanization) {
    return this.approvalRange.processMecanization(processMecanization, this.weldingProcessRoot);
  }

  processMecanizationFill(processMecanization: ProcessMecanization) {
    return this.approvalRange.processMecanization(processMecanization, this.weldingProcessFill);
  }

  processMecanizationCap(processMecanization: ProcessMecanization) {
    return this.approvalRange.processMecanization(processMecanization, this.weldingProcessCap);
  }

  typeCurrentPolarity(typeCurrent: TypeCurrentPolarity) {
    return this.approvalRange.typeCurrentAndPolarity(typeCurrent);
  }

  preHeat(preHeat: Number) {
    return this.approvalRange.preHeat(preHeat);
  }

  interpass(interpass: Number) {
    return this.approvalRange.interpass(interpass);
  }

  postHeat(postHeat: Number) {
    return this.approvalRange.postHeat(postHeat);
  }

  pwhtTemperature(pwht: Number) {
    return this.approvalRange.pwhtTemperature(pwht);
  }

  pwhtTime(time: Number) {
    return this.approvalRange.pwhtTime(time);
  }

  baseMaterial(baseMaterial1: BaseMaterial, baseMaterial2: BaseMaterial) {
    return this.approvalRange.baseMaterial(baseMaterial1, baseMaterial2);
  }

  baseMaterialThickness(thickness1: Number, thickness2: Number) {
    return this.approvalRange.baseMaterialThickness(thickness1, thickness2);
  }

  branchAngle(testPiece: Number) {
    return this.approvalRange.branchAngle(testPiece);
  }

  fillerMaterialRootCommercialDesignation(designation: FillerMaterialCommercialDesignation) {
    return this.approvalRange.fillerMaterialRootCommercialDesignation(designation);
  }

  fillerMaterialFillCommercialDesignation(designation: FillerMaterialCommercialDesignation) {
    return this.approvalRange.fillerMaterialFillCommercialDesignation(designation);
  }

  fillerMaterialCapCommercialDesignation(designation: FillerMaterialCommercialDesignation) {
    return this.approvalRange.fillerMaterialCapCommercialDesignation(designation);
  }

  throatThickness(thickness: Number) {
    return this.approvalRange.throatThickness(thickness);
  }

  diameter(diameter: Number) {
    return this.approvalRange.diameter(diameter);
  }

  pjpButtWeldBaseMaterial1Thickness() {
    return this.approvalRange.pjpButtWeldBaseMaterial1Thickness();
  }

  pjpButtWeldBaseMaterial2Thickness() {
    return this.approvalRange.pjpButtWeldBaseMaterial2Thickness();
  }

  baseMaterial1FilletWeldThickness() {
    return this.approvalRange.baseMaterial1FilletWeldThickness();
  }

  baseMaterial2FilletWeldThickness() {
    return this.approvalRange.baseMaterial2FilletWeldThickness();
  }

  shielding(weldingProcessCode: string) {
    return this.approvalRange.shielding(weldingProcessCode);
  }
}
