import { get } from '@ember/object';
import { A } from '@ember/array';
import { isEmpty } from '@ember/utils';
import {
  isFilletWeld,
  isButtWeld,
  isOverlayWeld,
  isPartialPenetrationButtWeld,
} from 'weldnote/utils/rules/weld-type';
import { isSingleLayer, isMultiLayer } from 'weldnote/utils/rules/welding-detail';
import { calculateShielding } from 'weldnote/utils/certification-rules';
import WeldingPositionApprovalRange from 'weldnote/utils/approval-range/pqr/welding-position';
import { formatRangeWith2Decimals } from 'weldnote/utils/unit-system/format';
import BaseMaterialRulesASME from 'weldnote/utils/pqr-base-material-rules-asme';

import Constants from 'weldnote/utils/constants';

const {
  PRODUCT_TYPE,
  UNIT_SYSTEM,
  WELDING_DETAILS,
  ARC_TRANSFER_MODES,
  WELD_TYPES,
  OVERLAY_TYPE_OPTIONS,
} = Constants;
const { isGroup } = BaseMaterialRulesASME;

export default class AsmeIXApprovalRanges {
  constructor(
    pqr,
    {
      allWeldTypes,
      allBaseMaterials,
      allWeldingDetails,
      allProcessMecanizations,
      allTypeCurrentPolarity,
      allWeldingPositions,
      allTransferModes,
      unitSystem = UNIT_SYSTEM.METRIC,
    }
  ) {
    this.pqr = pqr;
    let industryCodeId = get(this.standard, 'code.id');

    this.allWeldTypes = allWeldTypes.filter((weldType) => {
      return get(weldType, 'industryCode.id') === industryCodeId;
    });
    this._baseMaterials = allBaseMaterials;
    this.allWeldingDetails = allWeldingDetails;
    this.allProcessMecanizations = allProcessMecanizations;
    this.allTypeCurrentPolarity = allTypeCurrentPolarity;
    this._weldingPositions = allWeldingPositions;
    this._transferModes = allTransferModes;
    this.unitSystem = unitSystem;
    if (unitSystem === null) {
      throw new Error('Unit System is required for AWS B2.1 Approval Ranges');
    }
  }

  get allWeldingPositions() {
    return this._weldingPositions.filter(
      (position) => get(position, 'industryCode.id') === this.industryCodeId
    );
  }

  get allBaseMaterials() {
    return this._baseMaterials.filter(
      (baseMaterial) => get(baseMaterial, 'code.id') === this.industryCodeId
    );
  }

  get allTransferModes() {
    return this._transferModes.filter(
      (transferMode) => get(transferMode, 'industryCode.id') === this.industryCodeId
    );
  }

  get industryCodeId() {
    return get(this.standard, 'code.id');
  }

  get standard() {
    return this.pqr.get('standard');
  }

  get productTypeTestPiece() {
    return this.pqr.get('productTypeTestPiece');
  }

  get weldTypeTestPiece() {
    return this.pqr.get('weldTypeTestPiece');
  }

  get weldTypeCode() {
    return this.pqr.get('weldTypeTestPiece.weldType');
  }

  get baseMaterial1ThicknessValue() {
    return this.pqr.get('baseMaterial1Thickness');
  }

  get baseMaterial1() {
    return this.pqr.get('baseMaterial1TestPiece');
  }

  get baseMaterial2ThicknessValue() {
    return this.pqr.get('baseMaterial2Thickness');
  }

  get baseMaterial2() {
    return this.pqr.get('baseMaterial2TestPiece');
  }

  get weldingDetailsValues() {
    return this.pqr.get('weldingDetailsTestPiece');
  }

  get weldingProcessRoot() {
    return this.pqr.get('weldingProcessRootTestPiece');
  }

  get weldingProcessFill() {
    return this.pqr.get('weldingProcessFillTestPiece');
  }

  get weldingProcessCap() {
    return this.pqr.get('weldingProcessCapTestPiece');
  }

  get transferModeRoot() {
    return this.pqr.get('arcTransferModeRootTestPiece');
  }

  get transferModeFill() {
    return this.pqr.get('arcTransferModeFillTestPiece');
  }

  get transferModeCap() {
    return this.pqr.get('arcTransferModeCapTestPiece');
  }

  get impactTemperature() {
    return this.pqr.get('impactTestTemperature');
  }

  get impactRequired() {
    return this.pqr.get('impactTestRequired');
  }

  get interpassMinimum() {
    return this.pqr.get('interpassTemperatureMinimumApprovalRange');
  }

  filterWeldTypes(allTypes, listToFilter, industryCode) {
    let result = A();
    allTypes
      .filter((item) => {
        return get(item, 'industryCode.id') === industryCode;
      })
      .forEach((item) => {
        let name = get(item, 'weldType');
        if (listToFilter.indexOf(name) > -1) {
          result.pushObject(item);
        }
      });
    return result;
  }

  weldType(testPiece) {
    let result = A();
    let { industryCodeId } = this;

    if (isEmpty(testPiece)) {
      return [];
    }

    let curryedFilterTypes = (allWeldTypes, industryCode) => (listToFilter) =>
      this.filterWeldTypes(allWeldTypes, listToFilter, industryCode);
    let filterCurrentWeldTypes = curryedFilterTypes(this.allWeldTypes, industryCodeId);
    if (isFilletWeld(testPiece)) {
      return filterCurrentWeldTypes([WELD_TYPES.FILLET_WELD]);
    }
    if (isButtWeld(testPiece)) {
      return filterCurrentWeldTypes([
        WELD_TYPES.FILLET_WELD,
        WELD_TYPES.BUTT_WELD,
        WELD_TYPES.PARTIAL_PENETRATION_BUTT_WELD,
      ]);
    }
    if (isPartialPenetrationButtWeld(testPiece)) {
      return filterCurrentWeldTypes([
        WELD_TYPES.FILLET_WELD,
        WELD_TYPES.PARTIAL_PENETRATION_BUTT_WELD,
      ]);
    }

    // If no other rules is found, test piece qualifies itself
    if (isEmpty(result) && !isEmpty(testPiece)) {
      result.pushObject(testPiece);
    }

    return result;
  }

  _findWeldTypes(types = []) {
    let result = [];
    types.forEach((weldType) => {
      result.push(this.allWeldTypes.findBy('weldType', weldType));
    });
    return result;
  }

  productType(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
  }

  weldingProcess(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  _findWeldingDetail(detail) {
    return this.allWeldingDetails.findBy('shortDesignation', detail);
  }

  _findListWeldingDetails(details = []) {
    let results = [];
    details.forEach((d) => {
      let detail = this._findWeldingDetail(d);
      if (detail) {
        results.push(detail);
      }
    });
    return results;
  }

  _isWeldingDetail(testPiece, detail) {
    return testPiece.findBy('shortDesignation', detail);
  }

  weldingDetails(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
    let resultDetailNames = A();
    let result = A();
    let { weldTypeCode: weldType } = this;
    if (weldType === WELD_TYPES.OVERLAY_WELD) {
      testPiece.forEach((detail) => {
        let designation = detail.get('shortDesignation');
        resultDetailNames.pushObject(designation);
      });
    } else {
      testPiece.forEach((detail) => {
        let designation = detail.get('shortDesignation');
        if (
          designation === WELDING_DETAILS.SINGLE_SIDE_NO_BACKING ||
          designation === WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING ||
          designation === WELDING_DETAILS.BOTH_SIDES ||
          designation === WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING
        ) {
          [
            WELDING_DETAILS.SINGLE_SIDE_NO_BACKING,
            WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
            WELDING_DETAILS.BOTH_SIDES,
            WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING,
          ].forEach((currentDetail) => {
            resultDetailNames.pushObject(currentDetail);
          });
        } else if (
          designation === WELDING_DETAILS.SINGLE_LAYER ||
          designation === WELDING_DETAILS.MULTI_LAYER
        ) {
          if (this.impactRequirements) {
            resultDetailNames.pushObject(designation);
          } else {
            resultDetailNames.pushObject(WELDING_DETAILS.SINGLE_LAYER);
            resultDetailNames.pushObject(WELDING_DETAILS.MULTI_LAYER);
          }
        } else if (designation === WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING) {
          [
            WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING,
            WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
            WELDING_DETAILS.BOTH_SIDES,
          ].forEach((currentDetail) => {
            resultDetailNames.pushObject(currentDetail);
          });
        } else {
          resultDetailNames.pushObject(designation);
        }
      });
    }
    resultDetailNames.uniq().forEach((name) => {
      let position = this.allWeldingDetails.findBy('shortDesignation', name);
      if (position) {
        result.pushObject(position);
      }
    });
    return result;
  }

  processMecanization(testPiece) {
    if (!isEmpty(testPiece)) {
      return this.allProcessMecanizations;
    }
    return [];
  }

  transferMode(testPiece, weldingProcess) {
    if (isEmpty(testPiece) || isEmpty(weldingProcess)) {
      return [];
    }
    let weldingProcessName = get(weldingProcess, 'shortDesignation');
    let weldingProcesses = ['GMAW', 'FCAW', 'MCAW', 'FCAW-S'];
    if (!isEmpty(weldingProcessName) && weldingProcesses.indexOf(weldingProcessName) > -1) {
      let arcModeName = testPiece.get('transferMode');
      if (
        arcModeName === ARC_TRANSFER_MODES.SPRAY ||
        arcModeName === ARC_TRANSFER_MODES.GLOBULAR ||
        arcModeName === ARC_TRANSFER_MODES.PULSED_SPRAY
      ) {
        return this.allTransferModes.filter((mode) => {
          if (
            mode.get('transferMode') === ARC_TRANSFER_MODES.SPRAY ||
            mode.get('transferMode') === ARC_TRANSFER_MODES.GLOBULAR ||
            mode.get('transferMode') === ARC_TRANSFER_MODES.PULSED_SPRAY
          ) {
            return true;
          }
        });
      } else {
        return [testPiece];
      }
    } else {
      return [];
    }
  }

  filterTypeCurrent(allCurrents, toFilter) {
    let result = [];
    toFilter.forEach((type) => {
      allCurrents.forEach((current) => {
        if (current.get('typeCurrent') === type) {
          result.pushObject(current);
          return;
        }
      });
    });
    return result;
  }

  typeCurrentAndPolarity(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }

    if (this.impactRequirements) {
      return [testPiece];
    } else {
      return this.filterTypeCurrent(this.allTypeCurrentPolarity, ['DC+', 'DC-', 'Pulsed', 'AC']);
    }
  }

  diameter(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    return this._toRange(0, null);
  }

  weldedThickness(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    return this._calculateWeldingThicknessRange(testPiece);
  }

  _calculateWeldingThicknessRange(testPiece) {
    let thickness = parseFloat(testPiece);
    if (isNaN(thickness)) {
      return this._emptyRange;
    }

    let { smallestBaseMaterialThickness } = this;
    let result = {};

    if (this.isFilletWeld) {
      result.min = 0;
      result.max = null;
      return result;
    }

    result.min = 0;
    if (this.isMetricSystem) {
      if (thickness >= 0 && thickness < 19) {
        result.max = 2 * thickness;
      } else if (thickness >= 19) {
        if (smallestBaseMaterialThickness >= 19 && smallestBaseMaterialThickness < 38) {
          result.max = 2 * smallestBaseMaterialThickness;
        } else if (smallestBaseMaterialThickness >= 38 && smallestBaseMaterialThickness <= 150) {
          result.max = 200;
        } else if (smallestBaseMaterialThickness > 150) {
          result.max = 1.33 * smallestBaseMaterialThickness;
        }
      }
    } else if (this.isImperialSystem) {
      if (thickness >= 0 && thickness < 0.75) {
        result.max = 2 * thickness;
      } else if (thickness >= 0.75) {
        if (smallestBaseMaterialThickness >= 0.75 && smallestBaseMaterialThickness < 1.5) {
          result.max = 2 * smallestBaseMaterialThickness;
        } else if (smallestBaseMaterialThickness >= 1.5 && smallestBaseMaterialThickness <= 6) {
          result.max = 8;
        } else if (smallestBaseMaterialThickness > 6) {
          result.max = 1.33 * smallestBaseMaterialThickness;
        }
      }
    }
    return result;
  }

  baseMaterialThickness(thickness1, thickness2) {
    if (isEmpty(thickness1)) {
      return {
        material1: this._emptyRange,
        material2: this._emptyRange,
      };
    }

    if (this.isFilletWeld) {
      return {
        material1: {
          min: 0,
          max: null,
        },

        material2: {
          min: 0,
          max: null,
        },
      };
    }

    let resultThickness1 = null;
    let resultThickness2 = null;
    if (this.isMetricSystem) {
      if (this.isOverlayWeld) {
        resultThickness1 = this._thicknessASMEOverlayWeldMetricSystem(thickness1);
        resultThickness2 = this._thicknessASMEOverlayWeldMetricSystem(thickness2);
      } else {
        resultThickness1 = this._thicknessASMEOtherWeldTypesMetricSystem(
          thickness1,
          this.impactRequirements
        );
        resultThickness2 = this._thicknessASMEOtherWeldTypesMetricSystem(
          thickness2,
          this.impactRequirements
        );
      }
    } else if (this.isImperialSystem) {
      if (this.isOverlayWeld) {
        resultThickness1 = this._thicknessASMEOverlayWeldImperialSystem(thickness1);
        resultThickness2 = this._thicknessASMEOverlayWeldImperialSystem(thickness2);
      } else {
        resultThickness1 = this._thicknessASMEOtherWeldTypesImperialSystem(
          thickness1,
          this.impactRequirements
        );
        resultThickness2 = this._thicknessASMEOtherWeldTypesImperialSystem(
          thickness2,
          this.impactRequirements
        );
      }
    }

    let thicknessesDifferent = false;
    let thickness1Numeric = parseFloat(thickness1);
    let thickness2Numeric = parseFloat(thickness2);
    if (
      !isEmpty(thickness1) &&
      !isEmpty(thickness2) &&
      !isNaN(thickness1Numeric) &&
      !isNaN(thickness2Numeric) &&
      thickness1Numeric !== thickness2Numeric
    ) {
      thicknessesDifferent = Math.abs(thickness1 - thickness2) > Number.EPSILON;
    }
    // Special cases for ASME
    if (thicknessesDifferent) {
      if (thickness1Numeric > thickness2Numeric) {
        let groups = ['8', '41', '42', '43', '44', '45', '46', '49', '51', '52', '53', '61', '62'];
        let isOneOfGroups = groups.find((item) => {
          return isGroup(item, this.baseMaterial1GroupName);
        });
        if (!isEmpty(isOneOfGroups)) {
          if (this.isMetricSystem) {
            if (thickness1Numeric > 6) {
              resultThickness1.max = null;
            }
          } else if (this.isImperialSystem) {
            if (thickness1Numeric > 0.25) {
              resultThickness1.max = null;
            }
          }
        } else {
          if (this.isMetricSystem) {
            if (thickness1Numeric > 38) {
              resultThickness1.max = null;
            }
          } else if (this.isImperialSystem) {
            if (thickness1Numeric > 1.5) {
              resultThickness1.max = null;
            }
          }
        }
      } else {
        let groups = ['8', '41', '42', '43', '44', '45', '46', '49', '51', '52', '53', '61', '62'];
        let isOneOfGroups = groups.find((item) => {
          return isGroup(item, this.baseMaterial2GroupName);
        });
        if (!isEmpty(isOneOfGroups)) {
          if (this.isMetricSystem) {
            if (thickness2Numeric > 6) {
              resultThickness2.max = null;
            }
          } else if (this.isImperialSystem) {
            if (thickness2Numeric > 0.25) {
              resultThickness2.max = null;
            }
          }
        } else {
          if (this.isMetricSystem) {
            if (thickness2Numeric > 38) {
              resultThickness2.max = null;
            }
          } else if (this.isImperialSystem) {
            if (thickness2Numeric > 1.5) {
              resultThickness2.max = null;
            }
          }
        }
      }
    }
    return {
      material1: resultThickness1,
      material2: resultThickness2,
    };
  }

  _thicknessASMEOverlayWeldMetricSystem(thickness) {
    let result = this._defaultResult();
    if (isNaN(thickness)) {
      return this._emptyRange;
    }
    if (thickness < 25) {
      result.min = thickness;
      result.max = null;
    } else {
      result.min = 25;
      result.max = null;
    }
    return result;
  }

  _thicknessASMEOverlayWeldImperialSystem(thickness) {
    let result = this._defaultResult();
    if (isNaN(thickness)) {
      return this._emptyRange;
    }
    if (thickness < 1) {
      result.min = thickness;
      result.max = null;
    } else {
      result.min = 1;
      result.max = null;
    }
    return result;
  }

  _thicknessASMEOtherWeldTypesMetricSystem(thickness, impactRequirements) {
    let result = this._defaultResult();
    if (isNaN(thickness)) {
      return this._emptyRange;
    }
    if (thickness < 1.5) {
      result.min = thickness;
      result.max = 2 * thickness;
    } else if (thickness >= 1.5 && thickness <= 10) {
      result.min = 1.5;
      result.max = 2 * thickness;
    } else if (thickness > 10 && thickness < 38) {
      result.min = 5;
      result.max = 2 * thickness;
    } else if (thickness >= 38 && thickness <= 150) {
      result.min = 5;
      result.max = 200;
    } else {
      result.min = 5;
      result.max = 1.33 * thickness;
    }
    // Override Minimum Thickness because of Impact Requirements
    if (impactRequirements) {
      if (thickness < 6) {
        result.min = thickness * 0.5;
      } else {
        result.min = Math.min(16, thickness);
      }
    }
    return result;
  }

  _thicknessASMEOtherWeldTypesImperialSystem(thickness, impactRequirements) {
    let result = this._defaultResult();
    if (isNaN(thickness)) {
      return this._emptyRange;
    }
    if (thickness < 0.0625) {
      result.min = thickness;
      result.max = 2 * thickness;
    } else if (thickness <= 0.375 && thickness >= 0.0625) {
      result.min = 0.0625;
      result.max = 2 * thickness;
    } else if (thickness > 0.375 && thickness < 1.5) {
      result.min = 0.1875;
      result.max = 2 * thickness;
    } else if (thickness >= 1.5 && thickness <= 6) {
      result.min = 0.1875;
      result.max = 8;
    } else if (thickness > 6) {
      result.min = 0.1875;
      result.max = 1.33 * thickness;
    }
    // Override Minimum Thickness because of Impact Requirements
    if (impactRequirements) {
      if (thickness < 0.25) {
        result.min = thickness * 0.5;
      } else {
        result.min = Math.min(0.625, thickness);
      }
    }
    return result;
  }

  _defaultResult() {
    return {
      min: 0,
      max: null,
    };
  }

  _findWeldingPosition(code = '') {
    return this.allWeldingPositions
      .filter((p) => {
        return get(p, 'industryCode.id') === get(this.standard, 'code.id');
      })
      .findBy('code', code);
  }

  weldingPosition(testPiece, direction) {
    if (isEmpty(testPiece)) {
      return [];
    }

    let calculator = new WeldingPositionApprovalRange(this.allWeldingPositions, {
      standard: this.standard,
      weldType: this.weldTypeCode,
      impactRequirements: this.impactRequirements,
      productType: this.productTypeTestPiece,
    });

    return calculator.calculateWeldingPositions(testPiece, direction);
  }

  fixDecimals(result, unitSystem = UNIT_SYSTEM.METRIC) {
    if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
      return result;
    }
    return formatRangeWith2Decimals(result);
  }

  preHeat(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    let result = {
      min: null,
      max: null,
    };

    if (this.isMetricSystem) {
      result.min = Math.max(0, testPiece - 55);
      if (this.impactRequirements) {
        result.max = testPiece + 55;
      } else {
        result.max = null;
      }
    } else if (this.isImperialSystem) {
      result.min = Math.max(0, testPiece - 100);
      if (this.impactRequirements) {
        result.max = testPiece + 55;
      } else {
        result.max = null;
      }
    }

    return this.fixDecimals(result, this.unitSystem);
  }

  postHeat(/* testPiece */) {
    return this._emptyRange;
  }

  interpass(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    let result = {
      min: null,
      max: null,
    };

    let interpassValue = parseInt(testPiece, 10);
    if (interpassValue > 0) {
      if (this.weldTypeCode === WELD_TYPES.OVERLAY_WELD) {
        result.min = 0;
        result.max = interpassValue;
      } else {
        result.min = 0;
        if (this.isMetricSystem) {
          result.max = interpassValue + 55;
        } else if (this.isImperialSystem) {
          result.max = interpassValue + 100;
        }
      }
    }

    // Don't update the minimum if there was a previous value there
    if (this.interpassMinimum > 0 && result.min === 0) {
      result.min = this.interpassMinimum;
    }

    return this.fixDecimals(result, this.unitSystem);
  }

  pwhtTemperature(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    return this._toRange(testPiece, testPiece);
  }

  pwhtTime(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    let time = parseFloat(testPiece);
    let result = {
      min: null,
      max: null,
    };
    if (!isNaN(time)) {
      result.min = 0;
      result.max = time * 1.25;
    }

    return this.fixDecimals(result);
  }

  get isMetricSystem() {
    return this.unitSystem === UNIT_SYSTEM.METRIC;
  }

  get isImperialSystem() {
    return this.unitSystem === UNIT_SYSTEM.IMPERIAL;
  }

  _toRange(min, max) {
    return {
      min,
      max,
    };
  }

  get _emptyRange() {
    return {
      min: null,
      max: null,
    };
  }

  get isButtWeldOrPartialPenetrationWeld() {
    return (
      isButtWeld(this.weldTypeTestPiece) || isPartialPenetrationButtWeld(this.weldTypeTestPiece)
    );
  }

  get isFilletWeld() {
    return isFilletWeld(this.weldTypeTestPiece);
  }

  get isOverlayWeld() {
    return isOverlayWeld(this.weldTypeTestPiece);
  }

  get isMultiLayer() {
    return isMultiLayer(this.weldingDetailsValues);
  }

  get isSingleLayer() {
    return isSingleLayer(this.weldingDetailsValues);
  }

  get impactRequirements() {
    let { impactTemperature, impactRequired } = this;
    return impactRequired && !isNaN(parseFloat(impactTemperature));
  }

  get isOverlayHardFacing() {
    let overlayType = this.pqr.get('overlayType');
    return overlayType === OVERLAY_TYPE_OPTIONS.HARD_FACING;
  }

  get baseMaterial1ThicknessApprovalRangeMinimum() {
    return this.pqr.get('baseMaterial1ThicknessMinimumApprovalRange');
  }

  get baseMaterial1ThicknessApprovalRangeMaximum() {
    return this.pqr.get('baseMaterial1ThicknessMaximumApprovalRange');
  }

  get baseMaterial2ThicknessApprovalRangeMinimum() {
    return this.pqr.get('baseMaterial2ThicknessMinimumApprovalRange');
  }

  get baseMaterial2ThicknessApprovalRangeMaximum() {
    return this.pqr.get('baseMaterial2ThicknessMaximumApprovalRange');
  }

  get smallestBaseMaterialThickness() {
    let bm1Thickness = parseFloat(this.baseMaterial1ThicknessValue, 10);
    let bm2Thickness = parseFloat(this.baseMaterial2ThicknessValue, 10);

    if (!isNaN(bm1Thickness) && !isNaN(bm2Thickness)) {
      return bm1Thickness <= bm2Thickness ? bm1Thickness : bm2Thickness;
    } else if (!isNaN(bm1Thickness)) {
      return bm1Thickness;
    } else if (!isNaN(bm2Thickness)) {
      return bm2Thickness;
    }
    return null;
  }

  thicknessProcess(testPiece, layer) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    return this._calculateWeldingThicknessRange(testPiece);
  }

  getBaseMaterialGroupName(baseMaterial) {
    if (!isEmpty(baseMaterial)) {
      let materialGroup = get(baseMaterial, 'materialGroup.groupName');
      if (!isEmpty(materialGroup)) {
        return materialGroup;
      }
    }
    return '';
  }

  get baseMaterial1GroupName() {
    let baseMaterial1 = this.pqr.get('baseMaterial1TestPiece');
    if (!isEmpty(baseMaterial1)) {
      let materialGroup = get(baseMaterial1, 'materialGroup.groupName');
      if (!isEmpty(materialGroup)) {
        return materialGroup;
      }
    }
    return '';
  }

  get baseMaterial2GroupName() {
    let baseMaterial2 = this.pqr.get('baseMaterial2TestPiece');
    if (!isEmpty(baseMaterial2)) {
      let materialGroup = get(baseMaterial2, 'materialGroup.groupName');
      if (!isEmpty(materialGroup)) {
        return materialGroup;
      }
    }
    return '';
  }

  baseMaterial(baseMaterial1, baseMaterial2) {
    if (isEmpty(baseMaterial1) || isEmpty(baseMaterial2)) {
      return {
        material1: [],
        material2: [],
      };
    }

    let asmeResult = BaseMaterialRulesASME.calculate(
      this.getBaseMaterialGroupName(baseMaterial1),
      true,
      this.getBaseMaterialGroupName(baseMaterial2),
      this.allBaseMaterials,
      this.impactRequirements,
      this.weldTypeCode
    );
    return {
      material1: asmeResult.material1,
      material2: asmeResult.material2,
    };
  }

  branchAngle(/* testPiece */) {
    return this._emptyRange;
  }

  fillerMaterialRootCommercialDesignation(designation) {
    if (isEmpty(designation)) {
      return [];
    }
    if (this.impactRequirements && this.isOverlayHardFacing) {
      return [designation];
    }
    return [];
  }

  fillerMaterialFillCommercialDesignation(designation) {
    if (isEmpty(designation)) {
      return [];
    }
    if (this.impactRequirements && this.isOverlayHardFacing) {
      return [designation];
    }
    return [];
  }

  fillerMaterialCapCommercialDesignation(designation) {
    if (isEmpty(designation)) {
      return [];
    }
    if (this.impactRequirements && this.isOverlayHardFacing) {
      return [designation];
    }
    return [];
  }

  throatThickness(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    return this._toRange(0, null);
  }

  shielding(testPiece) {
    if (isEmpty(testPiece)) {
      return null;
    }
    return calculateShielding(testPiece);
  }

  baseMaterial1FilletWeldThickness() {
    if (this.isButtWeldOrPartialPenetrationWeld) {
      return this._toRange(0, null);
    } else {
      return this._toRange(
        this.baseMaterial1ThicknessApprovalRangeMinimum,
        this.baseMaterial1ThicknessApprovalRangeMaximum
      );
    }
  }

  baseMaterial2FilletWeldThickness() {
    if (this.isButtWeldOrPartialPenetrationWeld) {
      return this._toRange(0, null);
    } else {
      return this._toRange(
        this.baseMaterial2ThicknessApprovalRangeMinimum,
        this.baseMaterial2ThicknessApprovalRangeMaximum
      );
    }
  }

  pjpButtWeldBaseMaterial1Thickness() {
    return this._emptyRange;
  }

  pjpButtWeldBaseMaterial2Thickness() {
    return this._emptyRange;
  }

  productType(testPiece) {
    let result = {
      productType: [],
      restrictions: [],
    };
    if (testPiece === PRODUCT_TYPE.PIPE || testPiece === PRODUCT_TYPE.PLATE) {
      result.productType = [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
    }
    return result;
  }
}
