import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { get, getProperties, action, computed, set } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class InspectorsController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'inspector';

  @computed
  get gridColumns() {
    let nameLabel = this.getAttributeLabel('inspectorName');
    let stampLabel = this.getAttributeLabel('inspectorStamp');
    let idLabel = this.getAttributeLabel('inspectorId');
    let dateBirthLabel = this.getAttributeLabel('dateBirth');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'inspectorName',
        label: nameLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/inspector-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'inspectorStamp',
        label: stampLabel,
        sortable: false,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'inspectorId',
        label: idLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'dateBirth',
        label: dateBirthLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/person-age',
        breakpoints: ['laptop', 'desktop'],
      },
    ];
  }

  buildFilters() {
    let filters = getProperties(this, 'name', 'stamp');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });
    let examiningBody = get(this, 'examiningBody.id');
    if (!isEmpty(examiningBody)) {
      finalFilters.examiningBody = examiningBody;
    }
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllInspectors(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setName(name) {
    set(this, 'name', name);
    this.resetPage();
  }

  @action
  setStamp(stamp) {
    set(this, 'stamp', stamp);
    this.resetPage();
  }

  @action
  setExaminingBody(body) {
    set(this, 'examiningBody', body);
    this.resetPage();
  }

  @action
  clearFields() {
    this.resetPage();
    this.setProperties({
      name: null,
      stamp: null,
      examiningBody: null,
    });
    this.loadData.perform();
  }
}
