import Service from '@ember/service';

export default class WeldSearchParametersService extends Service {
  parameters = new Map();

  updateParameters(projectId, projectParameters) {
    this.parameters.set(projectId, projectParameters);
  }

  hasParameters(projectId) {
    return this.parameters.has(projectId);
  }

  deleteParameters(projectId) {
    this.parameters.delete(projectId);
  }

  getParametersForProject(projectId) {
    if (this.parameters.has(projectId)) {
      return this.parameters.get(projectId);
    }
    return null;
  }
}
