import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from '@ember/object';

export default class WeldingProcedureSpecificationPwpsToPqrRoute extends Route {
  @service('weldnote-data')
  data;

  @service session;

  @service
  router;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  model({ id }) {
    return this.data.loadAllDataWPS(id);
  }

  afterModel(model) {
    if (!get(model, 'isPreliminary')) {
      this.router.transitionTo('welding-procedure-specifications');
    }
  }
}
