import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';

@classic
export default class IeisArchivedRoute extends ListRoute {
  @service
  session;

  @service('weldnote-data')
  data;

  model(params) {
    return this.data.listAllIeis(
      {
        page: params.page,
      },
      {
        archived: 'AR',
      }
    );
  }
}
