// Copied from https://github.com/jasonkriss/ember-scroll-to (I only require the service)
import Service from '@ember/service';

import { computed } from '@ember/object';
import $ from 'jquery';

const DURATION = 750;
const EASING = 'swing';
const OFFSET = 0;

export default Service.extend({
  // ----- Static properties -----
  duration: DURATION,
  easing: EASING,
  offset: OFFSET,

  // ----- Computed properties -----
  scrollable: computed(() => {
    return $('html, body');
  }),

  // ----- Methods -----
  getJQueryElement(target) {
    let jQueryElement = $(target);

    if (!jQueryElement) {
      console.warn("element couldn't be found:", target);
      return;
    }

    return jQueryElement;
  },

  getVerticalCoord(target, offset = 0) {
    let jQueryElement = this.getJQueryElement(target);
    return jQueryElement.offset().top + offset;
  },

  scrollVertical(target, opts = {}) {
    this.scrollable.animate(
      {
        scrollTop: this.getVerticalCoord(target, opts.offset),
      },
      opts.duration || this.duration,
      opts.easing || this.easing,
      opts.complete
    );
  },
});
