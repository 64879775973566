export default {
  WELDING: {
    ORDER_NUM: 'orderNum',
    PASS_NUMBER: 'passNumber',
    WELDING_PROCESS: 'weldingProcess',
    FILLER_MATERIAL_TYPE: 'fillerMaterialType',
    FILLER_MATERIAL_DIAMETER: 'fillerMaterialDiameters',
    CURRENT_MINIMUM: 'currentMinimum',
    CURRENT_MAXIMUM: 'currentMaximum',
    VOLTAGE_MINIMUM: 'voltageMinimum',
    VOLTAGE_MAXIMUM: 'voltageMaximum',
    TYPE_CURRENT: 'typeCurrent',
    WIRE_FEED_SPEED_MINIMUM: 'wireFeedSpeedMinimum',
    WIRE_FEED_SPEED_MAXIMUM: 'wireFeedSpeedMaximum',
    TRAVEL_SPEED_MINIMUM: 'travelSpeedMinimum',
    TRAVEL_SPEED_MAXIMUM: 'travelSpeedMaximum',
    HEAT_INPUT_MINIMUM: 'heatInputMinimum',
    HEAT_INPUT_MAXIMUM: 'heatInputMaximum',
    ARC_TRANSFER_MODE: 'arcTransferMode',
    LAYER: 'layer',
  },
};
