import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { isEmpty } from '@ember/utils';
import { set, action } from '@ember/object';
import { t } from 'ember-intl';
import { task, timeout } from 'ember-concurrency';

@classic
export default class ThirtyDaysController extends Controller {
  @service
  intl;

  queryParams = ['page'];

  @service
  welderCertificateData;

  @alias('model.list')
  certificates;

  @t('miscellaneous-certificate-expiration.expire-30-days-title')
  certificateExpirationTitle;

  reloadDataTask = task(
    {
      drop: true,
    },
    async (opts = {}) => {
      let options = {};
      options.page = this.page;
      if (!isEmpty(opts.company)) {
        options.company = opts.company;
      }
      await timeout(400);
      let results = await this.welderCertificateData.getMiscellaneousCertificatesExpire30Days(
        options
      );
      set(this, 'model', results);
    }
  );

  @action
  postValidate() {
    this.welderCertificateData.clearMiscellaneousCache();
  }

  @action
  validateSingle(certificate) {
    if (!isEmpty(certificate)) {
      return certificate.revalidateCertificate();
    }
  }
}
