import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import Project from './project';
import WelderCertificate from './welder-certificate';

export default class ProjectWelderCertificate extends BaseModel {
  @belongsTo('project')
  declare project: AsyncBelongsTo<Project>;

  @belongsTo('welder-certificate')
  declare certificate: AsyncBelongsTo<WelderCertificate>;

  @attr('string')
  declare certificateNumber?: string;

  @attr('number')
  declare certificateId?: number;

  @attr('string')
  declare welder?: string;

  @attr('number')
  declare welderId?: number;

  @attr('string')
  declare referenceType?: string;

  async visualLabel() {
    let { certificate } = this;
    if (certificate) {
      let result = await certificate;
      return result.certificateNumber;
    }
    return '';
  }

  validations = {
    project: { presence: true },
    certificate: { presence: true },
  };

  metadata = {
    modelName: 'project-welder-certificate',
    project: { required: true },
    certificate: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-welder-certificate': ProjectWelderCertificate;
  }
}
