import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { getProperties, action, computed, set } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class GasCommercialDesignationsController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'gas-commercial-designation';

  commercialDesignation = '';

  gas = null;

  @computed
  get gridColumns() {
    let commercialDesignationLabel = this.getAttributeLabel('commercialDesignation');
    let gasLabel = this.getAttributeLabel('gas');
    let gasMixtureLabel = this.getAttributeLabel('gasMixture');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'commercialDesignation',
        label: commercialDesignationLabel,
        cellComponent: 'weldnote-grid/gas-commercial-designation-link',
      },
      {
        valuePath: 'gas.symbol',
        label: gasLabel,
        sortable: false,
      },
      {
        valuePath: 'gasMixture',
        label: gasMixtureLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
    ];
  }

  resetPage() {
    set(this, 'currentPage', 1);
  }

  buildFilters() {
    let filters = getProperties(this, 'commercialDesignation', 'gas');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllGasCommercialDesignations(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setCommercialDesignation(commercialDesignation) {
    set(this, 'commercialDesignation', commercialDesignation);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setGas(gas) {
    set(this, 'gas', gas);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  clearFields() {
    this.resetPage();
    this.setProperties({
      commercialDesignation: null,
      gas: null,
    });
    this.loadData.perform();
  }
}
