import { action } from '@ember/object';
import NewController from 'weldnote/controllers/_base/new';
import { service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import DS from 'ember-data';

export default class _NewController extends NewController {
  @service
  declare router: RouterService;

  model: DS.Model | null = null;

  @action
  instanceCreated() {
    if (this.model) {
      this.router.transitionTo('pqr', this.model, {
        queryParams: {
          isEditing: true,
        },
      });
    }
  }
}
