import { belongsTo, attr } from '@ember-data/model';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import NonDestructiveTesting from './non-destructive-testing';
import NdtReportCertificate from './ndt-report-certificate';
import TestStageName from './test-stage-name';
import TestLocation from './test-location';
import ProjectWeld from './project-weld';

export default class ProjectWeldInspection extends BaseModel {
  @belongsTo('project-weld', { async: false })
  declare weld: ProjectWeld;

  @belongsTo('non-destructive-testing', { async: false })
  declare test: NonDestructiveTesting;

  @attr('number')
  declare percentage?: number;

  @belongsTo('ndt-report-certificate', { async: false })
  declare report: NdtReportCertificate;

  @attr('string')
  declare testResult?: string;

  @attr('date')
  declare reportDate?: Date;

  @belongsTo('test-stage-name', { async: false })
  declare testStageName: TestStageName;

  @belongsTo('test-location', { async: false })
  declare testLocation: TestLocation;

  visualLabel(): string {
    return this.weld.weldId || '';
  }

  validations = {
    weld: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      }
    },
    test: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      }
    },
    percentage: {
      numericality: {
        allowBlank: false,
        lessThanOrEqualTo: 100,
        greaterThanOrEqualTo: 0,
        onlyInteger: true,
      },
    },

    report: {
      custom: {
        if(_key: string, value: any, model: any) {
          let test = get(model, 'test');
          return (
            !isEmpty(test) &&
            !isEmpty(get(test, 'id')) &&
            !isEmpty(value) &&
            !isEmpty(get(value, 'id'))
          );
        },

        validation(_key: string, value: any, model: any) {
          let test = get(model, 'test');
          if (!isEmpty(value)) {
            let testOfReport = get(value, 'typeOfTest');
            if (!isEmpty(testOfReport)) {
              return get(test, 'id') === get(testOfReport, 'id');
            }
          }
          return true;
        },

        message(_key: string, value: any, model: any) {
          let test = get(model, 'test');
          let testOfReport = get(value, 'typeOfTest');
          return model
            .translate('model-validations.weld-inspection.report-type-invalid', {
              testInspection: get(test, 'shortDesignation'),
              testReport: get(testOfReport, 'shortDesignation'),
            })
            .toString();
        },
      },
    },
  };

  metadata = {
    modelName: 'project-weld-inspection',
    weld: { required: true },
    test: { required: true },
    percentage: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-weld-inspection': ProjectWeldInspection;
  }
}
