export default {
  PROJECT: {
    DESIGNATION: 'designation',
    PROJECT_NAME: 'projectName',
    CUSTOMER: 'customer',
    SUPPLIER: 'supplier',
    CONSTRUCTION_STANDARD: 'constructionStandard',
    STANDARD: 'standard',
    WELDER_STANDARD: 'welderStandard',
    PROJECT_STATUS: 'projectStatus',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    DRAWINGS: 'drawings',
    WELDING_PROCEDURE_SPECIFICATIONS: 'weldingProcedureSpecifications',
    PQRS: 'pqrs',
    MAXIMUM_REPAIRS_PER_WELD: 'maximumRepairsPerWeld',
    SELECT_WELDERS_FROM_COMPANY_ONLY: 'selectWeldersFromCompanyOnly',
    SELECT_WPS_FROM_COMPANY_ONLY: 'selectWpsFromCompanyOnly',
    SELECT_WPS_FROM_PROJECT_LIBRARY_ONLY: 'selectWpsFromProjectLibraryOnly',
    COVER_PICTURE: 'coverPicture',
    ARCHIVAL_STATE: 'archivalState',
    TYPE_OF_WPS_INCLUSION: 'typeOfWpsInclusion',
  },
};
