import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Const from 'weldnote/utils/constants';

const { JOINT_TYPE_OPTIONS, WELD_TYPES } = Const;

export default function jointTypeFilteredOptions(allOptions, weldTypeProperty) {
  return computed(allOptions, weldTypeProperty, {
    get() {
      let selectedWeldType = get(this, weldTypeProperty);
      if (isEmpty(selectedWeldType)) {
        return get(this, allOptions);
      } else {
        let allItems = get(this, allOptions);
        let isFilletWeld = get(selectedWeldType, 'weldType') === WELD_TYPES.FILLET_WELD;
        // If we have a filler weld, don't show butt joints
        if (isFilletWeld) {
          return allItems.reject((item) => {
            return get(item, 'key') === JOINT_TYPE_OPTIONS.BUTT_JOINT;
          });
        }
        // Socket Joint Only appears in Fillet Welds
        if (!isFilletWeld) {
          return allItems.reject((item) => {
            return get(item, 'key') === JOINT_TYPE_OPTIONS.SOCKET_JOINT;
          });
        }
        return allItems;
      }
    },
  });
}
