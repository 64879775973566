import { service } from '@ember/service';
import EditRoute from 'weldnote/routes/_base/edit';

export default class FillerMaterialCertificateRoute extends EditRoute {
  @service
  store;

  modelName = 'filler-material-certificate';

  model({ id }) {
    return this.store.findRecord('filler-material-certificate', id, {
      include: 'filler-material,commercial-designation,certificate',
    });
  }
}
