import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { getProperties, set, action, computed } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class WeldingMapsController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service('instanceTracker')
  tracker;

  @service
  userSession;

  @service
  analytics;

  @service
  help;

  @service
  router;

  modelName = 'welding-map';

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  getGridColumnLabel(label) {
    return this.intl.t(`welding-map-list.grid-columns.${label}`);
  }

  @computed
  get gridColumns() {
    let designationLabel = this.getGridColumnLabel('designation');
    let pqrStandardLabel = this.getGridColumnLabel('pqr-standard');
    let certificateStandardLabel = this.getGridColumnLabel('certificate-standard');
    let wpsStandardLabel = this.getGridColumnLabel('wps-standard');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet', 'laptop'],
        sortable: false,
      },
      {
        valuePath: 'designation',
        label: designationLabel,
        cellComponent: 'weldnote-grid/qmatrix-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'pqrStandard.standard',
        label: pqrStandardLabel,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'certificateStandard.standard',
        label: certificateStandardLabel,
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'wpsStandard.standard',
        label: wpsStandardLabel,
        sortable: false,
        breakpoints: ['desktop'],
      },
    ];
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllQMatrix(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  buildFilters() {
    let { designation, pqrStandard, certificateStandard, wpsStandard } = getProperties(
      this,
      'designation',
      'pqrStandard',
      'certificateStandard',
      'wpsStandard'
    );
    let filters = {};
    if (!isEmpty(designation)) {
      filters.designation = designation;
    }
    if (!isEmpty(pqrStandard)) {
      filters.pqrStandard = pqrStandard.get('id');
    }
    if (!isEmpty(certificateStandard)) {
      filters.certificateStandard = certificateStandard.get('id');
    }
    if (!isEmpty(wpsStandard)) {
      filters.wpsStandard = wpsStandard.get('id');
    }
    return filters;
  }

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.loadData.perform();
    this.resetPage();
  }

  @action
  clearFields() {
    this.setProperties({
      designation: null,
      pqrStandard: null,
      certificateStandard: null,
      wpsStandard: null,
    });
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setDesignation(designation) {
    set(this, 'designation', designation);
    this.resetPage();
  }

  @action
  setPqrStandard(pqrStandard) {
    set(this, 'pqrStandard', pqrStandard);
    this.resetPage();
  }

  @action
  setCertificateStandard(certificateStandard) {
    set(this, 'certificateStandard', certificateStandard);
    this.resetPage();
  }

  @action
  setWpsStandard(wpsStandard) {
    set(this, 'wpsStandard', wpsStandard);
    this.resetPage();
  }

  @action
  createNew() {
    this.tracker.discardWeldingMap();
    this.router.transitionTo('welding-map.new');
  }

  @action
  displayHelpVideo(location) {
    set(this, 'showVideo', true);
    let { analytics, help } = this;
    analytics.trackEvent(help.convertVideoIdToAnalyticsEvent(help.qmatrixSearchVideo), {
      location,
    });
  }
}
