import Helper from '@ember/component/helper';

export function greaterThan(params /* , hash*/) {
  if (params[0] !== undefined && params[1] !== undefined) {
    if (params[0] > params[1]) {
      return true;
    }
  }
  return false;
}

export default Helper.helper(greaterThan);
