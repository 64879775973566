import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.hasWeldingDetailsError}}\n  <span class=\"help-block\" style=\"color:#a94442\">{{this.weldingDetailsInApprovalRange}}</span>\n  <ul>\n    {{#each this.approvalRange as |detail|}}\n      <li>\n        <span class=\"help-block\" style=\"color:#a94442\">\n          {{detail.label}}\n        </span>\n      </li>\n    {{/each}}\n  </ul>\n{{/if}}", {"contents":"{{#if this.hasWeldingDetailsError}}\n  <span class=\"help-block\" style=\"color:#a94442\">{{this.weldingDetailsInApprovalRange}}</span>\n  <ul>\n    {{#each this.approvalRange as |detail|}}\n      <li>\n        <span class=\"help-block\" style=\"color:#a94442\">\n          {{detail.label}}\n        </span>\n      </li>\n    {{/each}}\n  </ul>\n{{/if}}","moduleName":"weldnote/components/welding-details-chooser/wps-error.hbs","parseOptions":{"srcName":"weldnote/components/welding-details-chooser/wps-error.hbs"}});
import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { notEmpty, alias } from '@ember/object/computed';
import Component from '@ember/component';
import { isEmpty } from '@ember/utils';
import { get, computed } from '@ember/object';
import { notInCollectionById, serializeCollection } from 'weldnote/utils/collection-utils';

@classic
export default class WpsError extends Component {
  @service
  intl;

  @notEmpty('wps.errors.weldingDetails')
  hasWeldingDetailsError;

  @computed('wps.errors.weldingDetails')
  get weldingDetailsInApprovalRange() {
    if (!isEmpty(get(this, 'wps.errors.weldingDetails'))) {
      if (!isEmpty(get(this, 'wps.validator'))) {
        let { wps } = this;
        let notQualified = notInCollectionById(
          get(wps, 'validator.validations.weldingDetails'),
          get(this, 'wps.weldingDetails')
        );
        let notQualifiedSerialized = serializeCollection(notQualified, 'completeDesignation', '; ');
        let message = this.intl.t('model-validations.wps.pqr-validation', {
          value: notQualifiedSerialized,
          approvalRange: '',
        });
        return message;
      }
    }
    return '';
  }

  @alias('wps.validator.validations.weldingDetails')
  approvalRange;
}
