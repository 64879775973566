import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';
import {
  isStandardAWSD11,
  isStandardASME,
  isWelderStandardISO96061,
  isStandardISO,
} from 'weldnote/utils/standards';

// Check if the options only include the provided positions and not any more
export function optionsOnlyIncludePositions(options, positions) {
  if (!isEmpty(options) && !isEmpty(positions)) {
    let wrongPosition = false;
    options.forEach((position) => {
      let code = get(position, 'code');
      if (!positions.includes(code)) {
        wrongPosition = true;
      }
    });
    return !wrongPosition;
  }
  return false;
}

// Serialize positions from root, fill and cap properties of a model
export function serializePositions(model, rootProperty, fillProperty, capProperty) {
  let resultArray = [];
  let positionsRoot = get(model, rootProperty);
  if (!isEmpty(positionsRoot)) {
    positionsRoot.forEach((p) => {
      resultArray.push(get(p, 'code'));
    });
  }
  let positionsFill = get(model, fillProperty);
  if (!isEmpty(positionsFill)) {
    positionsFill.forEach((p) => {
      resultArray.push(get(p, 'code'));
    });
  }
  let positionsCap = get(model, capProperty);
  if (!isEmpty(positionsCap)) {
    positionsCap.forEach((p) => {
      resultArray.push(get(p, 'code'));
    });
  }
  return resultArray.uniq().join(', ');
}

export function allowedMultiplePositionsPQR(standard) {
  if (isStandardISO(standard) || isStandardASME(standard)) {
    return [];
  } else if (isStandardAWSD11(standard)) {
    return ['5G+2G'];
  } else {
    return [];
  }
}

export function allowedMultiplePositionsCertificates(standard) {
  if (isWelderStandardISO96061(standard)) {
    return [];
  } else if (isStandardASME(standard)) {
    return ['3G+4G', '2G+3G+4G', '3F+4F', '5G+2G'];
  } else if (isStandardAWSD11(standard)) {
    return ['5G+2G', '3G+4G', '3F+4F'];
  } else {
    return [];
  }
}
