import { service } from '@ember/service';
import EditRoute from 'weldnote/routes/_base/edit';

export default class NdtReportCertificateRoute extends EditRoute {
  @service
  store;

  modelName = 'ndt-report-certificate';

  listRoute = 'ndt-report-certificates';

  model({ id }) {
    return this.store.findRecord('ndt-report-certificate', id, {
      include: 'type-of-test,inspector,certificate',
    });
  }
}
