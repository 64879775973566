import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"w-column {{this.sizes}} {{@classes}}\" ...attributes>\n  {{yield}}\n</div>", {"contents":"<div class=\"w-column {{this.sizes}} {{@classes}}\" ...attributes>\n  {{yield}}\n</div>","moduleName":"weldnote/components/ui-column.hbs","parseOptions":{"srcName":"weldnote/components/ui-column.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class UiColumn extends Component {
  @tracked
  xsmall = null;

  @tracked
  small = null;

  @tracked
  medium = null;

  @tracked
  large = null;

  @tracked
  specific = null;

  get sizes() {
    let result = '';
    let { xsmall } = this.args;
    if (xsmall) {
      result += ` col-xs-${parseInt(12 / xsmall)}`;
    }
    let { small } = this.args;
    if (small) {
      result += ` col-sm-${parseInt(12 / small)}`;
    }
    let { medium } = this.args;
    if (medium) {
      result += ` col-md-${parseInt(12 / medium)}`;
    }
    let { large } = this.args;
    if (large) {
      result += ` col-lg-${parseInt(12 / large)}`;
    }
    let { specific } = this.args;
    if (specific) {
      result += ` col-${specific}`;
    }

    if (result.length === 0) {
      result = 'col-sm-6';
    }

    return result;
  }
}
