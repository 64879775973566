import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import {
  isSameMaterial,
  formatMaterialWithThickness,
} from 'weldnote/utils/base-material-formatter';

export default class FormatBaseMaterialsWithThickness extends Helper {
  @service
  userSession;

  compute(params, hash) {
    let { material1, material2, thickness1, thickness2 } = hash;
    let distanceUnits = this.userSession.distanceUnit;

    if (!isEmpty(material1) && !isEmpty(material2)) {
      if (isSameMaterial(material1, material2)) {
        return `${formatMaterialWithThickness(material1, thickness1, distanceUnits)}`;
      } else {
        let material1label = `${formatMaterialWithThickness(material1, thickness1, distanceUnits)}`;
        let material2label = `${formatMaterialWithThickness(material2, thickness2, distanceUnits)}`;
        return `${material1label}<br/>${material2label}`;
      }
    }

    if (!isEmpty(material1)) {
      return `${formatMaterialWithThickness(material1, thickness1, distanceUnits)}`;
    }
    if (!isEmpty(material2)) {
      return `${formatMaterialWithThickness(material2, thickness2, distanceUnits)}`;
    }
    return '';
  }
}
