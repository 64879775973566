import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import BaseMaterialGroup from 'weldnote/models/base-material-group';
import Constants from 'weldnote/utils/constants';

const { WELD_TYPES } = Constants;

interface BaseMaterialApprovalRange {
  material1: BaseMaterialGroup[] | null;
  material2: BaseMaterialGroup[] | null;
}

export default {
  isGroup(groupNumber: string, value: string) {
    let groupExpression = `${groupNumber} (`;
    if (!isEmpty(value) && value.startsWith(groupExpression)) {
      return true;
    }
    return false;
  },

  isSameGroup(material1: BaseMaterialGroup | null, material2: BaseMaterialGroup | null): boolean {
    if (material1 && material2) {
      return get(material1, 'groupNumber') === get(material2, 'groupNumber');
    }
    return false;
  },

  // Checks if a certain value is a group from the given list
  isGroups(groupNumberList: string[], value: string) {
    let result = false;
    groupNumberList.forEach((groupNumber) => {
      let groupExpression = `${groupNumber} (`;
      if (value.startsWith(groupExpression)) {
        result = true;
      }
    });
    return result;
  },

  // Gets a list of groups of the same group number
  groupAllSameGroup(groupNumber: string, groupList: BaseMaterialGroup[]) {
    let result: BaseMaterialGroup[] = [];
    groupList.forEach((item) => {
      if (this.isGroup(groupNumber, item.get('groupName'))) {
        result.pushObject(item);
      }
    });
    return result;
  },

  // Gets a subset of groups from the list
  groupAllSameGroups(groupNumberList: string[], groupList: BaseMaterialGroup[]) {
    let result: BaseMaterialGroup[] = [];
    groupNumberList.forEach((currentGroup) => {
      groupList.forEach((item) => {
        if (this.isGroup(currentGroup, item.get('groupName'))) {
          result.pushObject(item);
        }
      });
    });
    return result;
  },

  // Retrives a specific group from the list passed
  getGroupFromList(group: string, groupList: BaseMaterialGroup[]): BaseMaterialGroup[] {
    let result = null;
    groupList.forEach((current) => {
      if (group === current.get('groupName')) {
        result = current;
        return;
      }
    });
    if (result != null) {
      return [result];
    } else {
      return [];
    }
  },

  getFirstGroupOrNone(groups: BaseMaterialGroup[]): BaseMaterialGroup | null {
    if (groups && groups.length > 0) {
      return groups[0];
    }
    return null;
  },

  calculate(
    group1: string,
    isGroup1: boolean,
    group2: string,
    allGroups: BaseMaterialGroup[],
    impactRequirements: boolean,
    weldType: string
  ): BaseMaterialApprovalRange {
    let result: BaseMaterialApprovalRange = {
      material1: null,
      material2: null,
    };

    if (weldType === WELD_TYPES.OVERLAY_WELD) {
      let groups = [
        '1',
        '3',
        '4',
        '5A',
        '5B',
        '5C',
        '6',
        '7',
        '8',
        '9A',
        '9B',
        '9C',
        '10A',
        '10B',
        '10C',
        '10F',
        '10H',
        '10I',
        '10J',
        '10K',
        '11A',
        '11B',
        '11C',
        '15E',
      ];
      groups.forEach((g) => {
        if (this.isGroup(g, group1)) {
          result.material1 = this.groupAllSameGroup(g, allGroups);
        }
        if (this.isGroup(g, group2)) {
          result.material2 = this.groupAllSameGroup(g, allGroups);
        }

        if (isEmpty(result.material1) && !isEmpty(group1)) {
          result.material1 = this.getGroupFromList(group1, allGroups);
        }

        if (isEmpty(result.material2) && !isEmpty(group2)) {
          result.material2 = this.getGroupFromList(group2, allGroups);
        }
      });
    } else {
      if (!isEmpty(group1) && !isEmpty(group2)) {
        if (!impactRequirements) {
          if (this.isGroup('3', group1) && this.isGroup('3', group2)) {
            if (isGroup1) {
              result.material1 = this.groupAllSameGroup('3', allGroups);
              result.material2 = this.groupAllSameGroups(['3', '1'], allGroups);
            }
          } else if (this.isGroup('4', group1) && this.isGroup('4', group2)) {
            if (isGroup1) {
              result.material1 = this.groupAllSameGroup('4', allGroups);
              result.material2 = this.groupAllSameGroups(['4', '3', '1'], allGroups);
            }
          } else if (this.isGroup('5A', group1) && this.isGroup('5A', group2)) {
            if (isGroup1) {
              result.material1 = this.groupAllSameGroup('5A', allGroups);
              result.material2 = this.groupAllSameGroups(['5A', '4', '3', '1'], allGroups);
            }
          } else if (this.isGroup('4', group1) && this.isGroups(['3', '1'], group2)) {
            if (isGroup1) {
              result.material1 = this.groupAllSameGroup('4', allGroups);
              result.material2 = this.groupAllSameGroups(['3', '1'], allGroups);
            }
          } else if (this.isGroup('5A', group1) && this.isGroups(['4', '3', '1'], group2)) {
            if (isGroup1) {
              result.material1 = this.groupAllSameGroup('5A', allGroups);
              result.material2 = this.groupAllSameGroups(['4', '3', '1'], allGroups);
            }
          } else if (this.isGroup('4', group2) && this.isGroups(['3', '1'], group1)) {
            if (isGroup1) {
              result.material1 = this.groupAllSameGroups(['3', '1'], allGroups);
              result.material2 = this.groupAllSameGroup('4', allGroups);
            }
          } else {
            let groups = [
              '1',
              '3',
              '4',
              '5A',
              '5B',
              '5C',
              '6',
              '7',
              '8',
              '9A',
              '9B',
              '9C',
              '10A',
              '10B',
              '10C',
              '10F',
              '10H',
              '10I',
              '10J',
              '10K',
              '11A',
              '11B',
              '11C',
            ];
            groups.forEach((g) => {
              if (this.isGroup(g, group1)) {
                result.material1 = this.groupAllSameGroup(g, allGroups);
              }
              if (this.isGroup(g, group2)) {
                result.material2 = this.groupAllSameGroup(g, allGroups);
              }
            });
            if (isEmpty(result.material1)) {
              if (this.isGroup('15E', group1)) {
                result.material1 = this.groupAllSameGroups(['15E', '5B'], allGroups);
              } else {
                result.material1 = this.getGroupFromList(group1, allGroups);
              }
            }
            if (isEmpty(result.material2)) {
              if (this.isGroup('15E', group2)) {
                result.material2 = this.groupAllSameGroups(['15E', '5B'], allGroups);
              } else {
                result.material2 = this.getGroupFromList(group2, allGroups);
              }
            }
          }
        } else {
          // Impact Requirements = true
          let currentGroup1 = this.getGroupFromList(group1, allGroups);
          let currentGroup2 = this.getGroupFromList(group2, allGroups);
          if (this.isGroup('3', group1) && this.isGroup('3', group2)) {
            if (isGroup1) {
              result.material1 = currentGroup1.concat(currentGroup2);
              result.material2 = currentGroup1
                .concat(currentGroup2)
                .concat(this.groupAllSameGroup('1', allGroups));
            }
          } else if (this.isGroup('4', group1) && this.isGroup('4', group2)) {
            if (isGroup1) {
              result.material1 = currentGroup1.concat(currentGroup2);
              result.material2 = currentGroup1
                .concat(currentGroup2)
                .concat(this.groupAllSameGroups(['3', '1'], allGroups));
            }
          } else if (this.isGroup('5A', group1) && this.isGroup('5A', group2)) {
            if (isGroup1) {
              result.material1 = currentGroup1.concat(currentGroup2);
              result.material2 = currentGroup1
                .concat(currentGroup2)
                .concat(this.groupAllSameGroups(['4', '3', '1'], allGroups));
            }
          } else if (this.isGroup('5A', group1) && this.isGroups(['4', '3', '1'], group2)) {
            if (isGroup1) {
              result.material1 = currentGroup1;
              result.material2 = this.groupAllSameGroups(['4', '3', '1'], allGroups);
            }
          } else if (this.isGroup('5A', group2) && this.isGroups(['4', '3', '1'], group1)) {
            if (isGroup1) {
              result.material1 = this.groupAllSameGroups(['4', '3', '1'], allGroups);
              result.material2 = currentGroup2;
            }
          } else if (this.isGroup('4', group1) && this.isGroups(['3', '1'], group2)) {
            if (isGroup1) {
              result.material1 = currentGroup1;
              result.material2 = this.groupAllSameGroups(['3', '1'], allGroups);
            }
          } else if (this.isGroup('4', group2) && this.isGroups(['3', '1'], group1)) {
            if (isGroup1) {
              result.material1 = this.groupAllSameGroups(['3', '1'], allGroups);
              result.material2 = currentGroup2;
            }
          } else {
            if (
              this.isSameGroup(
                this.getFirstGroupOrNone(currentGroup1),
                this.getFirstGroupOrNone(currentGroup2)
              )
            ) {
              result.material1 = currentGroup1.concat(currentGroup2);
              result.material2 = currentGroup1.concat(currentGroup2);
            } else {
              result.material1 = currentGroup1;
              result.material2 = currentGroup2;
            }
          }
        }
      } else if (!isEmpty(group1)) {
        let currentGroup1 = this.getGroupFromList(group1, allGroups);
        if (impactRequirements) {
          result.material1 = currentGroup1;
        } else {
          let groups = [
            '1',
            '3',
            '4',
            '5A',
            '5B',
            '5C',
            '6',
            '7',
            '8',
            '9A',
            '9B',
            '9C',
            '10A',
            '10B',
            '10C',
            '10F',
            '10H',
            '10I',
            '10J',
            '10K',
            '11A',
            '11B',
            '11C',
          ];
          groups.forEach((g) => {
            if (this.isGroup(g, group1)) {
              result.material1 = this.groupAllSameGroup(g, allGroups);
              return;
            }
          });
          if (isEmpty(result.material1)) {
            if (this.isGroup('15E', group1)) {
              result.material1 = this.groupAllSameGroups(['15E', '5B'], allGroups);
            } else {
              result.material1 = currentGroup1;
            }
          }
          result.material2 = [];
        }
      }
    }
    return result;
  },
};
