import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.sortedContent as |group|}}\n  {{group.visualLabel}}{{#if (not-eq this.sortedContent.lastObject group)}}, {{/if}}\n{{/each}}", {"contents":"{{#each this.sortedContent as |group|}}\n  {{group.visualLabel}}{{#if (not-eq this.sortedContent.lastObject group)}}, {{/if}}\n{{/each}}","moduleName":"weldnote/components/sorted-base-material-group.hbs","parseOptions":{"srcName":"weldnote/components/sorted-base-material-group.hbs"}});
import classic from 'ember-classic-decorator';
import { sort } from '@ember/object/computed';
import Component from '@ember/component';

@classic
export default class SortedBaseMaterialGroup extends Component {
  @sort('content', function (a, b) {
    let sortProperty = 'groupOrder';
    let aProp = a.get(sortProperty);
    let bProp = b.get(sortProperty);
    if (aProp > bProp) {
      return -1;
    } else if (aProp < bProp) {
      return 1;
    }
    return 0;
  })
  sortedContent;
}
