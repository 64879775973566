import { computed, get } from '@ember/object';
import { belongsTo, attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import IndustryCode from './industry-code';

export default class WeldingPosition extends BaseModel {
  @attr('string')
  declare code: string;

  @belongsTo('industry-code', { async: false })
  declare industryCode?: IndustryCode;

  @attr('boolean')
  declare system: boolean;

  get visualLabel(): string {
    return this.code ?? '';
  }

  get industryCodeId() {
    let code = get(this, 'industryCode');
    if (code) {
      return code.id;
    }
    return '';
  }

  validations = {
    code: { presence: true },
    industryCode: { presence: true },
  };

  metadata = {
    modelName: 'welding-position',
    code: {
      required: true,
    },

    industryCode: {
      required: true,
    },
  };

  // Represents a Welding Position that has several positions
  // joined by a plus (e.g. 5G+2G), since we don't have multiple welding positions
  // in the Test Piece, this is the workaround
  @computed('code')
  get isMultiple(): boolean {
    if (this.code) {
      this.code.indexOf('+') >= 0;
    }
    return false;
  }

  @computed('code')
  get isPipeOnly(): boolean {
    let pipeOnly = ['5G', '6G', '6GR', 'PH', 'PJ', 'H-L045', 'J-L045', '5G+2G'];
    return pipeOnly.includes(this.code ?? '');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'welding-position': WeldingPosition;
  }
}
