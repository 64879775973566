import { isEmpty } from '@ember/utils';
import Constants from './constants';

const { ERROR_CODES } = Constants;

/**
 * @public
 * Set of functions to help with JSONAPI errors, namely getting error codes
 */
export function getErrorCode(error) {
  let { payload } = error;
  if (payload && payload.errors && payload.errors.length > 0) {
    let [firstError] = payload.errors;
    let { code } = firstError;
    return code;
  }
  let { errors } = error;
  if (errors && errors.length > 0) {
    let [firstError] = errors;
    let { code } = firstError;
    return code;
  }

  let { json } = error;
  if (json && json.errors) {
    let [firstError] = json.errors;
    let { code } = firstError;
    return code;
  }
}

export function getErrorStatus(error) {
  let { payload } = error;
  if (payload && payload.errors && payload.errors.length > 0) {
    let [firstError] = payload.errors;
    let { status } = firstError;
    return status;
  }
  let { errors } = error;
  if (errors && errors.length > 0) {
    let [firstError] = errors;
    let { status } = firstError;
    return status;
  }

  let { json } = error;
  if (json && json.errors) {
    let [firstError] = json.errors;
    let { status } = firstError;
    return status;
  }
}

export function getErrorDetail(error) {
  let { payload } = error;
  if (payload && payload.errors && payload.errors.length > 0) {
    let [firstError] = payload.errors;
    let { detail } = firstError;
    return detail;
  }
  let { errors } = error;
  if (errors && errors.length > 0) {
    let [firstError] = errors;
    let { detail } = firstError;
    return detail;
  }
}

export function isDuplicateError(error) {
  let code = getErrorCode(error);
  if (!isEmpty(code)) {
    return code === ERROR_CODES.DUPLICATED_INSTANCE;
  }
  return false;
}

export function isPermissionDenied(error) {
  let code = getErrorStatus(error);
  if (!isEmpty(code)) {
    return code === 403 || code === '403'; // HTTP Forbidden
  }
  return false;
}

export function isMissingUser(error) {
  let code = getErrorCode(error);
  if (!isEmpty(code)) {
    return code === ERROR_CODES.MISSING_USER;
  }
  return false;
}

export function isSessionExpired(error) {
  let code = getErrorCode(error);
  if (!isEmpty(code)) {
    return code === ERROR_CODES.SESSION_EXPIRED;
  }
  return false;
}

export function isWelderStandardNotSupported(error) {
  let code = getErrorCode(error);
  if (!isEmpty(code)) {
    return code === ERROR_CODES.WELDER_STANDARD_NOT_SUPPORTED;
  }
  return false;
}

export function isError(error, expected) {
  let code = getErrorCode(error);
  if (!isEmpty(code)) {
    return code === expected;
  }
  return false;
}

export function isForeignKeyError(error) {
  let code = getErrorCode(error);
  if (!isEmpty(code)) {
    return code === ERROR_CODES.FOREIGN_KEY;
  }
  return false;
}

export function isIntermediateWeldRestoreError(error) {
  let code = getErrorCode(error);
  if (!isEmpty(code)) {
    return code === ERROR_CODES.PROJECT_WELD_INTERMEDIATE_RESTORE;
  }
  return false;
}

export function isProjectWeldUsedByOthersError(error) {
  let code = getErrorCode(error);
  if (!isEmpty(code)) {
    return code === ERROR_CODES.PROJECT_WELD_USED_BY_OTHERS;
  }
  return false;
}
