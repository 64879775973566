import Helper from '@ember/component/helper';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';

export default class WpsFormatFillerMaterial extends Helper {
  @service
  intl;

  compute(params, hash) {
    let { pass, wps } = hash;
    let { layer } = pass;
    let fillerMaterial = null;
    if (layer === 'ROOT') {
      fillerMaterial = get(wps, 'fillerMaterialRoot');
    } else if (layer === 'FILL') {
      fillerMaterial = get(wps, 'fillerMaterialFill');
    } else if (layer === 'CAP') {
      fillerMaterial = get(wps, 'fillerMaterialCap');
    }
    if (fillerMaterial) {
      let { specification, classification } = fillerMaterial;
      if (!isEmpty(specification) && !isEmpty(classification)) {
        return `${specification.specification} - ${classification.classification}`;
      } else if (!isEmpty(specification)) {
        return specification.specification;
      } else if (!isEmpty(classification)) {
        return classification.classification;
      }
    } else {
      return this.intl.t(
        'components.model-welding-procedure-specification/add-new-linked-parameter.messages.no-filler-material'
      );
    }
  }
}
