import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import Constants from 'weldnote/utils/constants';
import Project from './project';
import NdtReportCertificate from './ndt-report-certificate';

const { PROJECT_REFERENCE_TYPE } = Constants;

export default class ProjectNdtReportCertificate extends BaseModel {
  @belongsTo('project')
  declare project: AsyncBelongsTo<Project>;

  @belongsTo('ndt-report-certificate')
  declare report: AsyncBelongsTo<NdtReportCertificate>;

  @attr('string')
  declare reportNumber?: string;

  @attr('string')
  declare pdfPath?: string;

  @attr('string')
  declare referenceType?: string;

  get visualLabel() {
    return this.reportNumber;
  }

  validations = {
    project: { presence: true },
    report: { presence: true },
  };

  metadata = {
    modelName: 'project-ndt-report-certificate',
    project: { required: true },
    report: { required: true },
  };

  get isManualReference(): boolean {
    return this.referenceType === PROJECT_REFERENCE_TYPE.MANUAL;
  }

  get isAutomaticReference(): boolean {
    return this.referenceType === PROJECT_REFERENCE_TYPE.REFERENCE;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-ndt-report-certificate': ProjectNdtReportCertificate;
  }
}
