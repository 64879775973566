import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias, or } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { A } from '@ember/array';
import Constants from 'weldnote/utils/constants';
import { task, waitForProperty } from 'ember-concurrency';

const { WELDER_CERTIFICATE_REVALIDATION_DEADLINES } = Constants;

@classic
export default class WelderCertificateDataService extends Service {
  @service
  session;

  @service
  userSession;

  @service
  ajax;

  @service
  intl;

  queryCertificateCount = task(async () => {
    await waitForProperty(this, 'userSession.isAccountLoaded', true);
    if (this.doesPlanAllowCertificates) {
      this.certificateInternallyToday.perform();
      this.certificateInternally30Days.perform();
      this.certificateInternallyExpired.perform();

      this.miscellaneousToday.perform();
      this.miscellaneous30Days.perform();
      this.miscellaneousExpired.perform();

      let result = await this.certificateInIsoCount.perform();
      if (!isEmpty(result) && parseInt(result.certificates, 10) > 0) {
        this.certificateExternallyToday.perform();
        this.certificateExternally30Days.perform();
        this.certificateExternallyExpired.perform();
      }
    }
  });

  certificateInternallyToday = task(async () => {
    let data = await this.getWelderCertificatesToInternallyValidateTodayCount();
    return data.count;
  });

  certificateInternally30Days = task(async () => {
    let data = await this.getWelderCertificatesToInternallyValidate30DaysCount();
    return data.count;
  });

  certificateInternallyExpired = task(async () => {
    let data = await this.getWelderCertificatesToInternallyValidateExpiredCount();
    return data.count;
  });

  certificateExternallyToday = task(async () => {
    let data = await this.getWelderCertificatesToExternallyValidateTodayCount();
    return data.count;
  });

  certificateExternally30Days = task(async () => {
    let data = await this.getWelderCertificatesToExternallyValidate30DaysCount();
    return data.count;
  });

  certificateExternallyExpired = task(async () => {
    let data = await this.getWelderCertificatesToExternallyValidateExpiredCount();
    return data.count;
  });

  miscellaneousToday = task(async () => {
    let data = await this.getMiscellaneousCertificatesExpireTodayCount();
    return data.count;
  });

  miscellaneous30Days = task(async () => {
    let data = await this.getMiscellaneousCertificatesExpire30DaysCount();
    return data.count;
  });

  miscellaneousExpired = task(async () => {
    let data = await this.getMiscellaneousCertificatesExpired();
    return data.count;
  });

  certificateInIsoCount = task(async () => {
    return await this.ajax.request('/grid/welder-certificate/isoCertificatesCount');
  });

  @computed('certificateInIsoCount.lastSuccessful.value')
  get hasCertificatesInIso() {
    let { lastSuccessful } = this.certificateInIsoCount;
    if (!isEmpty(lastSuccessful)) {
      let { value } = lastSuccessful;
      let certificateCount = parseInt(value.certificates, 10);
      if (!isNaN(certificateCount)) {
        return certificateCount > 0;
      }
    }
    return false;
  }

  @alias('certificateInternallyToday.isRunning')
  certificateInternallyTodayRunnning;

  @alias('certificateInternallyToday.lastSuccessful.value')
  certificateInternallyTodayCount;

  @alias('certificateInternally30Days.isRunning')
  certificateInternally30DaysRunnning;

  @alias('certificateInternally30Days.lastSuccessful.value')
  certificateInternally30DaysCount;

  @alias('certificateInternallyExpired.isRunning')
  certificateInternallyExpiredRunnning;

  @alias('certificateInternallyExpired.lastSuccessful.value')
  certificateInternallyExpiredCount;

  @alias('certificateExternallyToday.isRunning')
  certificateExternallyTodayRunnning;

  @alias('certificateExternallyToday.lastSuccessful.value')
  certificateExternallyTodayCount;

  @alias('certificateExternally30Days.isRunning')
  certificateExternally30DaysRunnning;

  @alias('certificateExternally30Days.lastSuccessful.value')
  certificateExternally30DaysCount;

  @alias('certificateExternallyExpired.isRunning')
  certificateExternallyExpiredRunnning;

  @alias('certificateExternallyExpired.lastSuccessful.value')
  certificateExternallyExpiredCount;

  @alias('miscellaneousToday.isRunning')
  miscellaneousTodayRunnning;

  @alias('miscellaneousToday.lastSuccessful.value')
  miscellaneousTodayCount;

  @alias('miscellaneous30Days.isRunning')
  miscellaneous30DaysRunnning;

  @alias('miscellaneous30Days.lastSuccessful.value')
  miscellaneous30DaysCount;

  @alias('miscellaneousExpired.isRunning')
  miscellaneousExpiredRunnning;

  @alias('miscellaneousExpired.lastSuccessful.value')
  miscellaneousExpiredCount;

  @or('userSession.isPlanQuality', 'userSession.isPlanCertificates')
  doesPlanAllowCertificates;

  @alias('certificateInternallyTodayCount')
  validateInternallyTodayCount;

  @alias('certificateInternally30DaysCount')
  validateInternally30DaysCount;

  @alias('certificateInternallyExpiredCount')
  validateInternallyExpiredCount;

  @alias('certificateExternallyTodayCount')
  validateExternallyTodayCount;

  @alias('certificateExternally30DaysCount')
  validateExternally30DaysCount;

  @alias('certificateExternallyExpiredCount')
  validateExternallyExpiredCount;

  @computed('intl.locale')
  get deadlineOptions() {
    let { intl } = this;
    return A([
      {
        key: WELDER_CERTIFICATE_REVALIDATION_DEADLINES.TODAY,
        label: intl.t('lov.welder-certificate-deadlines.today'),
        time: 1,
      },
      {
        key: WELDER_CERTIFICATE_REVALIDATION_DEADLINES.WEEK,
        label: intl.t('lov.welder-certificate-deadlines.7days'),
        time: 7,
      },
      {
        key: WELDER_CERTIFICATE_REVALIDATION_DEADLINES.MONTH,
        label: intl.t('lov.welder-certificate-deadlines.30days'),
        time: 30,
      },
      {
        key: WELDER_CERTIFICATE_REVALIDATION_DEADLINES.TWO_MONTH,
        label: intl.t('lov.welder-certificate-deadlines.60days'),
        time: 60,
      },
      {
        key: WELDER_CERTIFICATE_REVALIDATION_DEADLINES.THREE_MONTH,
        label: intl.t('lov.welder-certificate-deadlines.90days'),
        time: 90,
      },
      {
        key: WELDER_CERTIFICATE_REVALIDATION_DEADLINES.CUSTOM,
        label: intl.t('lov.welder-certificate-deadlines.custom'),
        time: null,
      },
    ]);
  }

  _requestData(url) {
    return this.ajax.request(url);
  }

  _getWelderCertificateData(type, options = {}) {
    let paging = '';
    let company = '';
    let url = `/api/grid/welder-certificate/${type}`;
    if (!isEmpty(options.page)) {
      paging = `?page=${options.page}`;
    }
    if (!isEmpty(options.company)) {
      company = `&filter[Company][id]=${options.company}`;
    }
    let finalUrl = `${url}${paging}${company}`;
    return this._requestData(finalUrl);
  }

  _getWelderCertificateDataCustom(type, options = {}) {
    let paging = '';
    let company = '';
    let url = `/api/grid/welder-certificate/${type}/custom/${options.deadlineTime}`;
    if (!isEmpty(options.page)) {
      paging = `?page=${options.page}`;
    }
    if (!isEmpty(options.company)) {
      company = `&filter[Company][id]=${options.company}`;
    }
    let finalUrl = `${url}${paging}${company}`;
    return this._requestData(finalUrl);
  }

  _getWelderCertificateDataCustomCount(type, deadlineTime) {
    let url = `/api/grid/welder-certificate/${type}/custom/${deadlineTime}/count`;
    return this._requestData(url);
  }

  _getMiscellaneousCertificateData(type, options = {}) {
    let paging = '';
    let company = '';
    let url = `/api/grid/miscellaneous-certificate/${type}`;
    if (!isEmpty(options.page)) {
      paging = `?page=${options.page}`;
    }
    if (!isEmpty(options.company)) {
      company = `&filter[Company][id]=${options.company}`;
    }
    let finalUrl = `${url}${paging}${company}`;
    return this._requestData(finalUrl);
  }

  // Gets certificates for internal validation for a custom period
  getWelderCertificatesToInternallyValidateCustom(options = {}) {
    return this._getWelderCertificateDataCustom('internal', options);
  }

  // Gets certificates for external validation for a custom period
  getWelderCertificatesToExternallyValidateCustom(options = {}) {
    return this._getWelderCertificateDataCustom('external', options);
  }

  getWelderCertificatesToInternallyValidateToday(options = {}) {
    options.deadlineTime = 1;
    return this.getWelderCertificatesToInternallyValidateCustom(options);
  }

  getWelderCertificatesToInternallyValidateTodayCount() {
    return this._getWelderCertificateDataCustomCount('internal', 1);
  }

  getWelderCertificatesToInternallyValidate30Days(options = {}) {
    options.deadlineTime = 30;
    return this.getWelderCertificatesToInternallyValidateCustom(options);
  }

  getWelderCertificatesToInternallyValidate30DaysCount() {
    return this._getWelderCertificateDataCustomCount('internal', 30);
  }

  getWelderCertificatesToInternallyValidateExpired(options = {}) {
    return this._getWelderCertificateData('internal/expired', options);
  }

  getWelderCertificatesToInternallyValidateExpiredCount() {
    return this._getWelderCertificateData('internal/expired?onlyCount=true');
  }

  getWelderCertificatesToExternallyValidateToday(options = {}) {
    options.deadlineTime = 1;
    return this.getWelderCertificatesToExternallyValidateCustom(options);
  }

  getWelderCertificatesToExternallyValidateTodayCount() {
    return this._getWelderCertificateDataCustomCount('external', 1);
  }

  getWelderCertificatesToExternallyValidate30Days(options = {}) {
    options.deadlineTime = 30;
    return this.getWelderCertificatesToExternallyValidateCustom(options);
  }

  getWelderCertificatesToExternallyValidate30DaysCount() {
    return this._getWelderCertificateDataCustomCount('external', 30);
  }

  getWelderCertificatesToExternallyValidateExpired(options) {
    return this._getWelderCertificateData('external/expired', options);
  }

  getWelderCertificatesToExternallyValidateExpiredCount() {
    return this._getWelderCertificateData('external/expired?onlyCount=true');
  }

  getWelderCertificatesArchived(options) {
    return this._getWelderCertificateData('archived', options);
  }

  getWelderCertificatesAll(options) {
    return this._getWelderCertificateData('all', options);
  }

  getMiscellaneousCertificatesExpired(options = {}) {
    return this._getMiscellaneousCertificateData('expired', options);
  }

  getMiscellaneousCertificatesExpiredCount(options = {}) {
    return this._getMiscellaneousCertificateData('expired?onlyCount=true', options);
  }

  getMiscellaneousCertificatesExpireToday(options = {}) {
    return this._getMiscellaneousCertificateData('expire/today', options);
  }

  getMiscellaneousCertificatesExpireTodayCount(options = {}) {
    return this._getMiscellaneousCertificateData('expire/today?onlyCount=true', options);
  }

  getMiscellaneousCertificatesExpire30Days(options = {}) {
    return this._getMiscellaneousCertificateData('expire/30days', options);
  }

  getMiscellaneousCertificatesExpire30DaysCount(options = {}) {
    return this._getMiscellaneousCertificateData('expire/30days?onlyCount=true', options);
  }

  getMiscellaneousCertificatesArchived(options = {}) {
    return this._getMiscellaneousCertificateData('archived', options);
  }

  clearCacheInternal() {
    this.certificateInternallyToday.perform();
    this.certificateInternally30Days.perform();
    this.certificateInternallyExpired.perform();
  }

  clearCacheExternal() {
    this.certificateExternallyToday.perform();
    this.certificateExternally30Days.perform();
    this.certificateExternallyExpired.perform();
  }

  clearCacheAll() {
    this.clearCacheInternal();
    this.clearCacheExternal();
  }

  clearMiscellaneousCache() {
    this.miscellaneousToday.perform();
    this.miscellaneous30Days.perform();
    this.miscellaneousExpired.perform();
  }
}
