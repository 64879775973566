import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { set, getProperties, action, computed, get } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class ProjectDocumentsController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'project-document';

  @computed
  get gridColumns() {
    let documentTitleLabel = this.getAttributeLabel('documentTitle');
    let documentNumberLabel = this.getAttributeLabel('documentNumber');
    let revisionLabel = this.getAttributeLabel('revision');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'documentTitle',
        label: documentTitleLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/project-document-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'documentNumber',
        label: documentNumberLabel,
        sortable: false,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'revision',
        label: revisionLabel,
        sortable: false,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
    ];
  }

  buildFilters() {
    return { anywhere: get(this, 'documentTitle') };
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllProjectDocuments(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  clearFields() {
    this.resetPage();
    this.setProperties({
      documentTitle: null,
    });
    this.loadData.perform();
  }
}
