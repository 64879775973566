import { isEmpty } from '@ember/utils';

export function fromMilimeterSecondToMilimeterMinute(
  valueInMilimeterSecond: string | number | undefined
): string {
  if (!isEmpty(valueInMilimeterSecond)) {
    let floatValue = parseFloat(`${valueInMilimeterSecond}`);
    if (!isNaN(floatValue)) {
      return `${parseFloat((floatValue * 60).toFixed(4))}`;
    }
  }
  return '';
}

export function fromMilimeterMinuteToMilimeterSecond(
  valueInMilimeterMinute: string | number
): string {
  if (!isEmpty(valueInMilimeterMinute)) {
    let floatValue = parseFloat(`${valueInMilimeterMinute}`);
    if (!isNaN(floatValue)) {
      return `${floatValue / 60}`;
    }
  }
  return '';
}

export function fromMilimeterSecondToMeterMinute(
  valueInMilimeterSecond: string | number | undefined
): string {
  if (valueInMilimeterSecond && !isEmpty(valueInMilimeterSecond)) {
    let floatValue = parseFloat(`${valueInMilimeterSecond}`);
    if (!isNaN(floatValue)) {
      return `${parseFloat((floatValue * 0.06).toFixed(4))}`;
    }
  }
  return '';
}

export function fromMeterMinuteToMilimeterSecond(valueInMeterMinute: string | number): string {
  if (!isEmpty(valueInMeterMinute)) {
    let floatValue = parseFloat(`${valueInMeterMinute}`);
    if (!isNaN(floatValue)) {
      return `${floatValue / 0.06}`;
    }
  }
  return '';
}
