import { action } from '@ember/object';
import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class WeldController extends Controller {
  @service
  userSession;

  @service
  router;

  get canEdit() {
    return this.userSession.canCreateProject && this.project.isActive;
  }

  @action
  goToList() {
    this.router.transitionTo('project.welds', {
      queryParams: {
        section: 'welds',
      },
    });
  }

  @action
  goToModel(id) {
    this.router.transitionTo('project.welds.weld', id, {
      queryParams: {
        section: 'welds',
      },
    });
  }
}
