import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';

@classic
export default class WeldingBooksRoute extends ListRoute {
  @service('weldnote-data')
  data;

  @service('helpdesk-widget')
  helpdesk;

  model() {
    return this.data.listAllWeldingBooks();
  }

  setupController(controller, model) {
    controller.set('model', model.list);
    controller.set('totalCount', model.count);
  }

  activate() {
    this.helpdesk.setSearchItems('Welding Books');
  }
}
