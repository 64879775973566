import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiPanel\n  @title={{this.title}}\n  @class=\"m-t-lg\"\n  @titleSmall={{t \"edit-view.required-fields\"}}\n  @collapsible={{this.collapsible}}\n>\n  {{yield}}\n</UiPanel>", {"contents":"<UiPanel\n  @title={{this.title}}\n  @class=\"m-t-lg\"\n  @titleSmall={{t \"edit-view.required-fields\"}}\n  @collapsible={{this.collapsible}}\n>\n  {{yield}}\n</UiPanel>","moduleName":"weldnote/components/w-model-edit-panel.hbs","parseOptions":{"srcName":"weldnote/components/w-model-edit-panel.hbs"}});
import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Component from '@ember/component';
import { t } from 'ember-intl';

@classic
export default class WModelEditPanel extends Component {
  @service
  intl;

  collapsible = false;

  @t('edit-view.main-panel')
  title;
}
