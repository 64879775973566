import Helper from '@ember/component/helper';

export function percentageFormatter(params, hash) {
  let { total, count, decimalPlaces = 1 } = hash;
  let totalInt = parseInt(total, 10);
  let countInt = parseInt(count, 10);

  if (!isNaN(totalInt) && !isNaN(countInt)) {
    if (totalInt > 0) {
      return `${((countInt / totalInt) * 100).toFixed(decimalPlaces)}%`;
    }
  }
  return `${parseInt(0).toFixed(decimalPlaces)}%`;
}

export default Helper.helper(percentageFormatter);
