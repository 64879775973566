import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

import { isStandardISO, isStandardASME, isStandardAWS } from 'weldnote/utils/standards';

import Constants from 'weldnote/utils/constants';
import WeldingPosition from 'weldnote/models/welding-position';
import { WeldingStandard } from 'weldnote/config/types/welding-standard';
import WeldType from 'weldnote/models/weld-type';

const { WELD_TYPES, WELDING_POSITION_DIRECTION_OPTIONS, PRODUCT_TYPE } = Constants;

export default class WeldingPositionApprovalRange {
  private allPositions: WeldingPosition[];

  private parameters: any;

  constructor(
    allPositions: WeldingPosition[],
    parameters: {
      standard: WeldingStandard;
      weldType: WeldType;
      impactRequirements: boolean;
      productType: string;
    }
  ) {
    this.parameters = parameters;
    let { standard } = parameters;
    if (standard) {
      let codeId = standard.industryCodeId;
      this.allPositions = allPositions.filter((item) => {
        let industryCode = get(item, 'industryCode');
        if (industryCode) {
          let positionCodeId = get(industryCode, 'id');
          return positionCodeId === codeId;
        }
        return false;
      });
    } else {
      this.allPositions = allPositions;
    }
  }

  _filterPositions(positions: string[]): WeldingPosition[] {
    let result: WeldingPosition[] = [];
    positions.forEach((position: string) => {
      let potentialMatch = this.allPositions.findBy('code', position);
      if (potentialMatch) {
        result.pushObject(potentialMatch);
      }
    });
    return result;
  }

  _calculateISOPositions(weldingPosition: string, weldType: string): WeldingPosition[] {
    if (weldType === WELD_TYPES.OVERLAY_WELD) {
      if (weldingPosition === 'PC') {
        let resultingPositions = ['PA', 'PB', 'PC'];
        return this.allPositions.filter((item) => {
          let currentPositionCode = item.get('code');
          if (currentPositionCode) {
            return resultingPositions.includes(currentPositionCode);
          } else {
            return false;
          }
        });
      } else {
        return this.allPositions.filter((item) => {
          return item.get('code') === weldingPosition;
        });
      }
    } else {
      let ascendantPositions = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'PH', 'H-L045', 'PF+PC'];
      let result = ascendantPositions.includes(weldingPosition);
      if (result) {
        return this.allPositions.filter((item) => {
          let currentPositionCode = item.get('code');
          if (currentPositionCode) {
            return ascendantPositions.includes(currentPositionCode);
          } else {
            return false;
          }
        });
      }
      let descendentPositions = ['PG', 'PJ', 'J-L045'];
      result = descendentPositions.includes(weldingPosition);
      if (result) {
        return this.allPositions.filter((item) => {
          let currentPositionCode = item.get('code');
          if (currentPositionCode) {
            return descendentPositions.includes(currentPositionCode);
          } else {
            return false;
          }
        });
      }
    }
    return [];
  }

  _calculateAWSPositions(weldingPosition: string, productType: string) {
    if (productType === PRODUCT_TYPE.PLATE) {
      if (weldingPosition === '1G') {
        return this._filterPositions(['1G', '1F']);
      } else if (weldingPosition === '2G') {
        return this._filterPositions(['1G', '2G', '1F', '2F']);
      } else if (weldingPosition === '3G') {
        return this._filterPositions(['3G', '3F']);
      } else if (weldingPosition === '4G') {
        return this._filterPositions(['4G', '4F']);
      } else if (weldingPosition === '2F') {
        return this._filterPositions(['2F', '1F']);
      } else {
        return this._filterPositions([weldingPosition]);
      }
    } else if (productType === PRODUCT_TYPE.PIPE) {
      if (weldingPosition === '1G') {
        return this._filterPositions(['1G', '1F']);
      } else if (weldingPosition === '2G') {
        return this._filterPositions(['1G', '2G', '1F', '2F']);
      } else if (weldingPosition === '5G') {
        return this._filterPositions(['1G', '3G', '4G', '5G', '1F', '3F', '4F', '5F']);
      } else if (weldingPosition === '5G+2G') {
        return this._filterPositions([
          '1G',
          '2G',
          '3G',
          '4G',
          '5G',
          '6G',
          '1F',
          '2F',
          '3F',
          '4F',
          '5F',
        ]);
      } else if (weldingPosition === '6G') {
        return this._filterPositions([
          '1G',
          '2G',
          '3G',
          '4G',
          '5G',
          '6G',
          '1F',
          '2F',
          '3F',
          '4F',
          '5F',
        ]);
      } else if (weldingPosition === '6GR') {
        return this._filterPositions([
          '1G',
          '2G',
          '3G',
          '4G',
          '5G',
          '6G',
          '6GR',
          '1F',
          '2F',
          '3F',
          '4F',
          '5F',
        ]);
      } else if (weldingPosition === '2F') {
        return this._filterPositions(['1F', '2F']);
      } else if (weldingPosition === '4F') {
        return this._filterPositions(['1F', '2F', '4F']);
      } else if (weldingPosition === '5F') {
        return this._filterPositions(['1F', '2F', '3F', '4F', '5F']);
      } else {
        return this._filterPositions([weldingPosition]);
      }
    }
    return [];
  }

  _calculateASMEPositions(
    weldingPosition: string,
    testPieceDirection: string,
    weldType: string,
    impactRequirements: boolean
  ) {
    if (weldType === WELD_TYPES.OVERLAY_WELD) {
      let positions2 = ['3G', '5G', '6G', '6GR'];
      if (
        positions2.includes(weldingPosition) &&
        testPieceDirection === WELDING_POSITION_DIRECTION_OPTIONS.UPHILL
      ) {
        return this._filterPositions([
          '1G',
          '2G',
          '3G',
          '4G',
          '5G',
          '6G',
          '6GR',
          '1F',
          '2F',
          '3F',
          '4F',
          '5F',
        ]);
      } else if (['1F', '1G'].includes(weldingPosition)) {
        return this._filterPositions(['1G', '1F']);
      } else if (['2F', '2G'].includes(weldingPosition)) {
        return this._filterPositions(['1G', '1F', '2G', '2F']);
      } else if (['3F', '3G'].includes(weldingPosition)) {
        return this._filterPositions(['1G', '1F', '3G', '3F']);
      } else if (['4F', '4G'].includes(weldingPosition)) {
        return this._filterPositions(['1G', '1F', '4G', '4F']);
      } else if (['5F', '5G'].includes(weldingPosition)) {
        return this._filterPositions(['1G', '2G', '3G', '4G', '5G', '1F', '2F', '3F', '4F', '5F']);
      } else if (['6G'].includes(weldingPosition)) {
        return this._filterPositions([
          '1G',
          '2G',
          '3G',
          '4G',
          '5G',
          '6G',
          '1F',
          '2F',
          '3F',
          '4F',
          '5F',
        ]);
      } else if (['6GR'].includes(weldingPosition)) {
        return this._filterPositions([
          '1G',
          '2G',
          '3G',
          '4G',
          '5G',
          '6G',
          '6GR',
          '1F',
          '2F',
          '3F',
          '4F',
          '5F',
        ]);
      }
    } else {
      if (!impactRequirements) {
        let positionsG = ['1G', '2G', '3G', '4G', '5G', '6G', '6GR'];
        let positionsF = ['1F', '2F', '3F', '4F', '5F'];
        if (positionsG.includes(weldingPosition)) {
          return this._filterPositions([
            '1G',
            '2G',
            '3G',
            '4G',
            '5G',
            '6G',
            '6GR',
            '1F',
            '2F',
            '3F',
            '4F',
            '5F',
          ]);
        } else if (positionsF.includes(weldingPosition)) {
          return this._filterPositions(['1F', '2F', '3F', '4F', '5F']);
        }
      } else {
        let positions1 = ['1G', '2G', '4G'];
        let positions2 = ['3G', '5G', '6G', '6GR'];
        let positions3 = ['1F', '2F', '4F'];
        let positions4 = ['3F', '5F'];
        if (positions1.includes(weldingPosition)) {
          return this._filterPositions(['1G', '2G', '4G', '1F', '2F', '4F']);
        } else if (positions2.includes(weldingPosition)) {
          return this._filterPositions([
            '1G',
            '2G',
            '3G',
            '4G',
            '5G',
            '6G',
            '6GR',
            '1F',
            '2F',
            '3F',
            '4F',
            '5F',
          ]);
        } else if (positions3.includes(weldingPosition)) {
          return this._filterPositions(['1F', '2F', '4F']);
        } else if (positions4.includes(weldingPosition)) {
          return this._filterPositions(['1F', '2F', '3F', '4F', '5F']);
        }
      }
    }
    return [];
  }

  calculateWeldingPositions(testPiece: WeldingPosition, testPieceDirection: string) {
    let { standard, weldType, impactRequirements, productType } = this.parameters;

    if (isEmpty(testPiece) || !standard) {
      return [];
    }
    let weldingPosition = get(testPiece, 'code');
    if (!weldingPosition) {
      return [];
    }
    if (isEmpty(weldingPosition)) {
      return [];
    }

    if (isStandardISO(standard)) {
      return this._calculateISOPositions(weldingPosition, weldType);
    } else if (isStandardASME(standard)) {
      return this._calculateASMEPositions(
        weldingPosition,
        testPieceDirection,
        weldType,
        impactRequirements
      );
    } else if (isStandardAWS(standard)) {
      return this._calculateAWSPositions(weldingPosition, productType);
    }

    if (testPiece) {
      return this.allPositions.filter((item) => {
        return item.get('code') === weldingPosition;
      });
    } else {
      return [];
    }
  }
}
