import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import ProcessMecanization, { ProcessMecanizationType } from 'weldnote/models/process-mecanization';

function isMecanization(mecanization: ProcessMecanization, name: ProcessMecanizationType) {
  return get(mecanization, 'name') === name;
}

export function isManualMecanization(mecanization: ProcessMecanization): boolean {
  return isMecanization(mecanization, ProcessMecanizationType.MANUAL);
}

export function isAutomaticMecanization(mecanization: ProcessMecanization): boolean {
  return isMecanization(mecanization, ProcessMecanizationType.AUTOMATIC);
}

export function isMechanizedMecanization(mecanization: ProcessMecanization): boolean {
  return isMecanization(mecanization, ProcessMecanizationType.MECHANIZED);
}

export function filterMecanizations(
  allMecanizations: ProcessMecanization[],
  mecanizationNameList: string[]
): ProcessMecanization[] {
  let result: ProcessMecanization[] = [];
  for (let mecanizationName of mecanizationNameList) {
    let mecanization = allMecanizations.findBy('name', mecanizationName);
    if (mecanization && !isEmpty(mecanization)) {
      result.pushObject(mecanization);
    }
  }
  return result;
}
