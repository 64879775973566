import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class SettingsRoute extends Route {
  @service('helpdesk-widget')
  helpdesk;

  @service session;

  activate() {
    this.helpdesk.setSearchItems('Settings');
  }

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
  }
}
