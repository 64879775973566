import Helper, { helper } from '@ember/component/helper';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';

export default Helper.extend({
  intl: service(),

  compute(params, hash) {
    let { min, max, unit = '' } = hash;
    let { intl } = this;
    if (isEmpty(max) && !isEmpty(min)) {
      return intl
        .t('helpers.help-text-value-range.more-than', {
          value: min,
          unit,
        })
        .toString();
    } else if (!isEmpty(max) && !isEmpty(min)) {
      if (max === min) {
        return intl
          .t('helpers.help-text-value-range.exactly-equal', {
            value: min,
            unit,
          })
          .toString();
      } else {
        return intl
          .t('helpers.help-text-value-range.between', {
            min,
            max,
            unit,
          })
          .toString();
      }
    } else if (isEmpty(min) && !isEmpty(max)) {
      return intl
        .t('helpers.help-text-value-range.less-than', {
          value: max,
          unit,
        })
        .toString();
    } else {
      return '';
    }
  },
});
