import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class ComponentsController extends Controller {
  @service
  userSession;

  get canEdit() {
    return this.userSession.canCreateProject && this.project.isActive;
  }
}
