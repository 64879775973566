import Service from '@ember/service';
import { CrossStorageClient } from 'cross-storage';
import ENV from 'weldnote/config/environment';

const storage = new CrossStorageClient(ENV.msalWeldCloud.sharedHub, {});

export default class SharedStorageService extends Service {
  async getValue(key: string) {
    return storage
      .onConnect()
      .then(() => {
        return storage.get(key);
      })
      .catch((reason) => {
        console.error(
          "Couldn't connect to Shared Storage to GET data, Single Sign On will not work."
        );
        // NOTE: this is the only method that might throw an exception
        throw reason;
      });
  }

  async setValue(key: string, value: string) {
    return storage
      .onConnect()
      .then(() => {
        return storage.set(key, value);
      })
      .catch(() => {
        console.error(
          "Couldn't connect to Shared Storage to SET data, Single Sign On will not work."
        );
        return null;
      });
  }

  async clear() {
    return storage
      .onConnect()
      .then(() => {
        return storage.clear();
      })
      .catch(() => {
        console.error(
          "Couldn't connect to Shared Storage to CLEAR data, Single Sign On will not work."
        );
        return null;
      });
  }
}
