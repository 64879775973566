import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';

export default class DestructiveTestingsRoute extends ListRoute {
  @service
  store;

  model() {
    return this.store.findAll('destructive-testing');
  }
}
