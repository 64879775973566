/*

Tailwind - The Utility-First CSS Framework

A project by Adam Wathan (@adamwathan), Jonathan Reinink (@reinink),
David Hemphill (@davidhemphill) and Steve Schoger (@steveschoger).

Welcome to the Tailwind config file. This is where you can customize
Tailwind specifically for your project. Don't be intimidated by the
length of this file. It's really just a big JavaScript object and
we've done our very best to explain each section.

View the full documentation at https://tailwindcss.com.


|-------------------------------------------------------------------------------
| The default config
|-------------------------------------------------------------------------------
|
| This variable contains the default Tailwind config. You don't have
| to use it, but it can sometimes be helpful to have available. For
| example, you may choose to merge your custom configuration
| values with some of the Tailwind defaults.
|
*/

// let defaultConfig = require('tailwindcss/defaultConfig')

/*
|-------------------------------------------------------------------------------
| Colors                                    https://tailwindcss.com/docs/colors
|-------------------------------------------------------------------------------
|
| Here you can specify the colors used in your project. To get you started,
| we've provided a generous palette of great looking colors that are perfect
| for prototyping, but don't hesitate to change them for your project. You
| own these colors, nothing will break if you change everything about them.
|
| We've used literal color names ("red", "blue", etc.) for the default
| palette, but if you'd rather use functional names like "primary" and
| "secondary", or even a numeric scale like "100" and "200", go for it.
|
*/

module.exports = {
  theme: {
    colors: {
      transparent: 'transparent',
      black: '#22292f',
      'real-black': '#000',
      'gray-800': '#3d4852',
      'gray-700': '#606f7b',
      'gray-600': '#8795a1',
      gray: '#b8c2cc',
      'gray-400': '#dae1e7',
      'gray-200': '#f1f5f8',
      'gray-100': '#f8fafc',

      white: '#ffffff',
      'off-white': '#f7fafc',
      'grey-weldnote': '#dae1e7',

      'red-900': '#3b0d0c',
      'red-800': '#621b18',
      'red-600': '#cc1f1a',
      red: '#e3342f',
      'red-400': '#ef5753',
      'red-200': '#f9acaa',
      'red-100': '#fcebea',

      'yellow-900': '#453411',
      'yellow-800': '#a77f2e',
      'yellow-600': '#f2d024',
      yellow: '#ffed4a',
      'yellow-400': '#fff382',
      'yellow-200': '#fff9c2',
      'yellow-100': '#fcfbeb',

      'green-900': '#0f2f21',
      'green-800': '#1a4731',
      'green-600': '#1f9d55',
      green: '#38c172',
      'green-400': '#51d88a',
      'green-200': '#a2f5bf',
      'green-100': '#e3fcec',

      'blue-modal': '#2e4050',
      'blue-900': '#12283a',
      'blue-800': '#1c3d5a',
      'blue-600': '#2779bd',
      blue: '#3498db',
      'blue-400': '#6cb2eb',
      'blue-200': '#bcdefa',
      'blue-100': '#eff8ff',

      'purple-900': '#21183c',
      'purple-800': '#382b5f',
      'purple-600': '#794acf',
      purple: '#9561e2',
      'purple-400': '#a779e9',
      'purple-200': '#d6bbfc',
      'purple-100': '#f3ebff',

      'orange-900': '#462a16',
      'orange-800': '#613b1f',
      'orange-600': '#de751f',
      orange: '#f6993f',
      'orange-400': '#faad63',
      'orange-200': '#fcd9b6',
      'orange-100': '#fff5eb',

      // TODO UI: old UI colors that we might want to review and remove them
      'esab-gray': '#cecfd0',
      'esab-orange': '#da7129',
      'esab-darkgrey': '#373338',
      'esab-background': '#f0f0f0',

      // New UI colors for 2021 design
      'esab-icon-blue': '#0B7DA8',
      'esab-sidebar-blue': '#c7d0d8',
      'esab-sidebar-blue-active': '#174167',
      'esab-sidebar-blue-arrow': '#164167',
      'esab-black': '#26292A',
      'esab-primary-blue': '#1A61A1',
      'esab-primary-blue-light': '#347fc3',
      'esab-secondary-blue': '#6ca1cd', // this one is not from the designs
      'esab-card-blue': '#2961A1',
      'esab-card-secondary-blue': '#6491EB',
      'esab-red': '#E23900',
      'esab-red-highlight': '#ed6031'
    },
    screens: {
      sm: '576px',
      md: '768px',
      lg: '992px',
      xl: '1200px',
    },
    fontFamily: {
      sans: [
        'system-ui',
        'BlinkMacSystemFont',
        '-apple-system',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif',
      ],
      serif: [
        'Constantia',
        'Lucida Bright',
        'Lucidabright',
        'Lucida Serif',
        'Lucida',
        'DejaVu Serif',
        'Bitstream Vera Serif',
        'Liberation Serif',
        'Georgia',
        'serif',
      ],
      mono: ['Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', 'monospace'],
      museo: ['Museo Sans', 'Roboto', 'sans-serif'],
    },
    fontSize: {
      // xs: '.75rem', // 12px
      sm: '.875rem', // 14px
      base: '1rem', // 16px
      lg: '1.125rem', // 18px
      xl: '1.25rem', // 20px
      '2xl': '1.5rem', // 24px
      '3xl': '1.875rem', // 30px
      '4xl': '2.25rem', // 36px
      '5xl': '3rem', // 48px
      '6xl': '4rem', // 64px
      '7xl': '5rem',
    },
    fontWeight: {
      hairline: 100,
      thin: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900,
    },
    lineHeight: {
      none: 1,
      tight: 1.25,
      normal: 1.5,
      loose: 2,
      10: '2.7rem !important',
    },
    letterSpacing: {
      tight: '-0.05em',
      normal: '0',
      wide: '0.05em',
    },
    textColor: (theme) => theme('colors'),
    backgroundColor: (theme) => theme('colors'),
    backgroundSize: {
      auto: 'auto',
      cover: 'cover',
      contain: 'contain',
    },
    borderWidth: {
      default: '1px',
      0: '0',
      2: '2px',
      4: '4px',
      8: '8px',
    },
    borderColor: (theme) => ({
      default: theme('colors.gray-400'),
      ...theme('colors'),
    }),
    borderRadius: {
      none: '0',
      sm: '.125rem',
      default: '.25rem',
      lg: '.5rem',
      full: '9999px',
    },
    width: {
      auto: 'auto',
      px: '1px',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      24: '6rem',
      32: '8rem',
      48: '12rem',
      64: '16rem',
      128: '32rem',
      160: '40rem',
      '1/2': '50%',
      '1/3': '33.33333%',
      '2/3': '66.66667%',
      '1/4': '25%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.66667%',
      '5/6': '83.33333%',
      full: '100%',
      screen: '100vw',
    },
    height: {
      auto: 'auto',
      px: '1px',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      24: '6rem',
      32: '8rem',
      48: '12rem',
      64: '16rem',
      full: '100%',
      screen: '100vh',
    },
    minWidth: {
      0: '0',
      full: '100%',
    },
    minHeight: {
      0: '0',
      full: '100%',
    },
    maxWidth: {
      xs: '20rem',
      sm: '30rem',
      md: '40rem',
      lg: '50rem',
      xl: '60rem',
      '2xl': '70rem',
      '3xl': '80rem',
      '4xl': '90rem',
      '5xl': '100rem',
      full: '100%',
    },
    maxHeight: {
      full: '100%',
      screen: '100vh',
    },
    padding: {
      px: '1px',
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      32: '8rem',
    },
    margin: {
      auto: 'auto',
      px: '1px',
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      32: '8rem',
      // Negative
      '-px': '-1px',
      '-0': '0',
      '-1': '-0.25rem',
      '-2': '-0.5rem',
      '-3': '-0.75rem',
      '-4': '-1rem',
      '-5': '-1.25rem',
      '-6': '-1.5rem',
      '-8': '-2rem',
      '-10': '-2.5rem',
      '-12': '-3rem',
      '-16': '-4rem',
      '-20': '-5rem',
      '-24': '-6rem',
      '-32': '-8rem',
    },
    boxShadow: {
      default: '0 2px 4px 0 rgba(0,0,0,0.10)',
      md: '0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)',
      lg: '0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)',
      inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
      outline: '0 0 0 3px rgba(52,144,220,0.5)',
      none: 'none',
    },
    zIndex: {
      auto: 'auto',
      0: 0,
      10: 10,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
    },
    opacity: {
      0: '0',
      10: '.1',
      20: '.20',
      25: '.25',
      50: '.5',
      75: '.75',
      100: '1',
    },
    fill: {
      current: 'currentColor',
    },
    stroke: {
      current: 'currentColor',
    },
  },

  variants: {
    appearance: [],
    backgroundAttachment: [],
    backgroundColor: ['hover', 'group-hover'],
    backgroundPosition: [],
    backgroundRepeat: [],
    backgroundSize: [],
    borderCollapse: [],
    borderColor: ['focus'],
    borderRadius: ['hover'],
    borderStyle: [],
    borderWidth: [],
    cursor: ['hover'],
    display: [],
    flexDirection: [],
    flexWrap: [],
    alignItems: [],
    alignSelf: [],
    justifyContent: [],
    alignContent: [],
    flex: [],
    flexGrow: [],
    flexShrink: [],
    float: [],
    fontFamily: [],
    fontWeight: [],
    height: [],
    lineHeight: [],
    listStylePosition: [],
    listStyleType: [],
    margin: ['responsive'],
    maxHeight: [],
    maxWidth: [],
    minHeight: [],
    minWidth: [],
    negativeMargin: [],
    opacity: [],
    outline: [],
    overflow: [],
    padding: [],
    pointerEvents: [],
    position: [],
    inset: [],
    resize: [],
    boxShadow: ['hover'],
    fill: [],
    stroke: [],
    textAlign: [],
    textColor: ['hover', 'group-hover', 'focus'],
    fontSize: [],
    fontStyle: ['hover'],
    fontSmoothing: ['hover'],
    textDecoration: ['hover'],
    textTransform: ['hover'],
    letterSpacing: [],
    userSelect: [],
    verticalAlign: [],
    visibility: [],
    whitespace: [],
    wordBreak: [],
    width: ['responsive'],
    zIndex: [],
  },

  corePlugins: {
    container: false,
  },

  /*
  |-----------------------------------------------------------------------------
  | Plugins                                https://tailwindcss.com/docs/plugins
  |-----------------------------------------------------------------------------
  |
  | Here is where you can register any plugins you'd like to use in your
  | project. Tailwind's built-in `container` plugin is enabled by default to
  | give you a Bootstrap-style responsive container component out of the box.
  |
  | Be sure to view the complete plugin documentation to learn more about how
  | the plugin system works.
  |
  */

  plugins: [],
};
