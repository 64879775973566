import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';

export default class GasSpecificationsRoute extends ListRoute {
  @service
  store;

  model() {
    return this.store.findAll('gas-specification');
  }
}
