import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { set, get } from '@ember/object';
import InstanceTrackerService from 'weldnote/services/instance-tracker';
import DS from 'ember-data';
import UserSessionService from 'weldnote/services/user-session';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { tracked } from '@glimmer/tracking';
import NewController from 'weldnote/controllers/_base/new';

export default class NewRoute extends Route {
  @service('instanceTracker')
  declare tracker: InstanceTrackerService;

  @service
  declare store: DS.Store

  @service
  declare userSession: UserSessionService;

  @service
  declare router: RouterService;

  @service session: any;

  modelName = 'welding-book';

  @tracked
  currentModel: DS.Model | null = null;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);
    this.session.requireAuthentication(transition, 'login');
    if (!this.userSession.canCreateWeldingBook) {
      this.router.transitionTo('welding-books');
    }
  }

  model() {
    return this.store.createRecord('welding-book');
  }

  setupController(controller: NewController, model: DS.Model, transition: Transition) {
    super.setupController(controller, model, transition);
    this.currentModel = model;
    controller.modelName = this.modelName;
  }

  /**
   * @public
   * When we're leaving the route with an unsaved instance, destroy the instance.
   */
  deactivate(transition: Transition) {
    super.deactivate(transition);
    let model = this.currentModel;
    
    if (model && get(model, 'isNew') && !model.isSaving) {
      this.store.unloadRecord(model);
      set(this, 'currentModel', null);
    }
  }
}
