import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { set, computed, action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';

const {
  HTTP: { DEBOUNCE },
  ARCHIVAL_STATE,
} = Constants;

export default class WeldersArchivedController extends Controller {
  @service
  intl;

  @service
  userSession;

  @service('weldnote-data')
  data;

  queryParams = ['page'];

  @service('weldnote-data')
  data;

  @tracked
  welders = [];

  @tracked
  welderCount = 0;

  @tracked
  pageNumber = 1;

  @tracked
  name = '';

  @computed
  get gridColumns() {
    let { intl, userSession } = this;
    let nameLabel = this.intl.t('model.welder.welderName.label');
    let stampLabel = this.intl.t('model.welder.welderStamp.label');
    let dateBirthLabel = this.intl.t('model.welder.dateBirth.label');
    let idLabel = this.intl.t('model.welder.welderId.label');
    let weldsPerformedLabel = intl.t('generic.welding.welds-performed');
    let weldsRepairedLabel = intl.t('generic.welding.welds-repaired');

    let columns = [
      {
        width: '40px',
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
        sortable: false,
      },
      {
        valuePath: 'welderName',
        label: nameLabel,
        cellComponent: 'weldnote-grid/welder-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'welderStamp',
        label: stampLabel,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'dateBirth',
        label: dateBirthLabel,
        cellComponent: 'weldnote-grid/person-age',
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
    ];

    if (userSession.isPlanQuality) {
      columns.pushObject({
        valuePath: 'totalWelds',
        label: weldsPerformedLabel,
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      });
      columns.pushObject({
        valuePath: 'repairRate',
        label: weldsRepairedLabel,
        cellComponent: 'weldnote-grid/repair-rate',
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      });
    } else {
      columns.pushObject({
        valuePath: 'welderId',
        cellComponent: 'weldnote-grid/welder-id',
        label: idLabel,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      });
    }

    return columns;
  }

  buildFilters() {
    let finalFilters = {};
    finalFilters.archived = ARCHIVAL_STATE.ARCHIVED;

    let { name } = this;
    if (!isEmpty(name)) {
      finalFilters.name = name;
    }
    finalFilters.personType = 'ALL';
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async (pageNumber = 1) => {
      let options = {};
      options.page = pageNumber;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllWelders(options, this.buildFilters());
      this.welders = data;
      this.welderCount = data.meta.records;
    }
  );

  resetPage() {
    this.pageNumber = 1;
  }

  @action
  search(pageNumber) {
    this.pageNumber = pageNumber;
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setName(newName) {
    this.name = newName;
  }

  @action
  clearFields() {
    this.name = '';
    this.search(1);
  }
}
