import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { oneWay, and } from '@ember/object/computed';
import Component from '@ember/component';
import { later } from '@ember/runloop';
import { set, get, action, computed } from '@ember/object';
import { isPermissionDenied, isForeignKeyError } from 'weldnote/utils/json-api-errors';
import EVENTS from 'weldnote/utils/analytics-events';
import { isEmpty } from '@ember/utils';

@classic
export default class BaseEditWps extends Component {
  @service
  intl;

  @service
  store;

  @service
  analytics;

  @service
  alertMessage;

  @service
  loading;

  showLoading = true;

  @service
  userSession;

  /* Whether the Model is being edited or not*/
  isUserEditing = false;

  title = '';

  createAction = null;

  isComponentSaving = false;

  @and('wps.isSaving', 'isComponentSaving')
  isModelSaving;

  @computed('wps.{isNew,visualLabel}')
  get titleValue() {
    let { model: wps, intl } = this;
    if (get(wps, 'isNew')) {
      let modelLabel = intl
        .t(`model.${get(this, 'model.metadata.modelName')}.model-name`)
        .toString();
      return intl.t('edit-view.new-item', { name: modelLabel });
    }
    return get(this, 'wps.wpsNumber');
  }

  @oneWay('model.hasDirtyAttributes')
  isDirty;

  @computed('isDirty')
  get subtitle() {
    let isDirty = get(this, 'isDirty');
    let changedAttributes = get(this, 'model').changedAttributes();
    // Something's odd, changedAttributes returns empty, but hasDirtyAttributes returns true?
    if (isDirty && Object.keys(changedAttributes).length > 0) {
      return '*';
    } else {
      return '';
    }
  }

  getActionLabel(title) {
    return this.intl.t(title, { name: get(this, 'model.visualLabel') });
  }

  getModelLabel(label) {
    return this.intl.t(`model.${get(this, 'model.metadata.modelName')}.${label}`);
  }

  init() {
    super.init(...arguments);
    set(this, 'title', get(this, 'titleValue'));
  }

  _markSaving() {
    set(this, 'isComponentSaving', true);
  }

  _finishSaving() {
    set(this, 'isComponentSaving', false);
  }

  _showSaveSuccessMessage() {
    let translation = this.getActionLabel('edit-view.actions.saved');
    get(this, 'alertMessage').success(translation.toString());
  }

  _updateTitle() {
    let newTitle = get(this, 'titleValue');
    set(this, 'title', newTitle);
  }

  _stopEditing() {
    set(this, 'isUserEditing', false);
  }

  postCancelEditModel() {
    /* Hook to override */
  }

  // The breadcrumb for a given model
  @computed('model')
  get breadcrumbs() {
    return [
      {
        route: get(this, 'listRoute'),
        label: this.getModelLabel('model-plural'),
      },
      {
        label: get(this, 'titleValue'),
      },
    ];
  }

  pushValidationErrorsToAnalytics() {
    let { model: wps, analytics } = this;
    let errors = [];
    get(wps, 'errors').forEach((error) => {
      errors.pushObject(error);
    });
    let parameters = get(wps, 'weldingParameters');
    if (parameters) {
      parameters.forEach((parameter) => {
        let errorParameter = get(parameter, 'errors.content');
        if (!isEmpty(errorParameter)) {
          errors.pushObject({
            pass: get(parameter, 'passNumber'),
            error: errorParameter,
          });
        }
      });
      analytics.trackEvent(EVENTS.VALIDATION_FAILED, {
        id: get(wps, 'id'),
        type: 'welding-procedure-specification',
        errors,
      });
    }
  }

  @action
  deleteModel() {
    let self = this;
    let { model } = this;
    let deleteInfo = {
      id: model.get('id'),
      type: self.get('model.metadata.modelName'),
    };
    let translation = self.getActionLabel('edit-view.actions.deleted');
    get(this, 'loading').block();
    model.deleteRecord();
    model
      .save()
      .then(() => {
        self.get('alertMessage').success(translation);
        if (self.postDelete) {
          self.postDelete(deleteInfo);
        }
        self.deleteInstance();
      })
      .catch((reason) => {
        model.rollbackAttributes();
        if (isPermissionDenied(reason)) {
          get(this, 'alertMessage').error(this.intl.t('edit-view.actions.permission-denied'));
        } else {
          if (isForeignKeyError(reason)) {
            let foreignKeyMessage = this.intl
              .t('edit-view.actions.foreign-key-error', { name: get(this, 'model.visualLabel') })
              .toString();
            get(this, 'alertMessage').error(foreignKeyMessage);
            set(this, 'isShowingModal', false);
          } else {
            get(this, 'alertMessage').error(
              this.intl
                .t('edit-view.actions.generic-delete-error', {
                  name: this.get('model.visualLabel'),
                })
                .toString()
            );
          }
        }
      })
      .finally(() => {
        this.loading.unblock();
        this.toggleProperty('isShowingModal');
      });
  }

  @action
  saveModel(stopEditing = false) {
    let { model } = this;
    let self = this;
    let modelIsNew = model.get('isNew');
    if (this.beforeSave) {
      this.beforeSave();
    }

    let modelValid = model.validate();
    let session = get(this, 'userSession');
    if (modelValid || session.hasFeature('save-invalid-wps')) {
      this.loading.block();
      self._markSaving();
      model
        .save()
        .then((model) => {
          if (modelValid) {
            self._showSaveSuccessMessage();
          } else {
            get(this, 'alertMessage').info(
              'WPS was saved, but with errors please correct them in order to keep things tidy',
              'WPS has errors'
            );
          }
          if (stopEditing) {
            this._stopEditing();
          }
          self._updateTitle();
          if (this._postSave) {
            this._postSave(model, { isNew: modelIsNew });
          }
          if (modelIsNew) {
            get(this, 'instanceCreated')();
          }
        })
        .catch((reason) => {
          if (isPermissionDenied(reason)) {
            get(this, 'alertMessage').error(this.intl.t('edit-view.actions.permission-denied'));
          } else {
            if (reason && reason.errors) {
              if (reason.errors[0].code === '23000') {
                let duplicateMessage = this.intl.t('edit-view.actions.unique-violation').toString();
                get(this, 'alertMessage').error(duplicateMessage);
              } else {
                get(this, 'alertMessage').error(
                  this.intl
                    .t('edit-view.actions.generic-error', {
                      name: get(this, 'model.visualLabel'),
                    })
                    .toString()
                );
              }
            } else {
              get(this, 'alertMessage').error(
                this.intl.t('edit-view.actions.error', { name: get(this, 'titleValue') }).toString()
              );
            }
          }
        })
        .finally(() => {
          self._finishSaving();
          this.loading.unblock();
        });
    } else {
      if (this.validationFailed) {
        this.validationFailed(this.model);
        this.pushValidationErrorsToAnalytics();
      }
      this.alertMessage.error(this.intl.t('edit-view.actions.validation-failed'));
    }
  }

  @action
  editModel() {
    if (get(this, 'showLoading')) {
      get(this, 'loading').block();
    }
    if (this.beforeEditModel) {
      this.beforeEditModel();
    }
    set(this, 'isUserEditing', true);
    if (get(this, 'showLoading')) {
      later(
        this,
        () => {
          get(this, 'loading').unblock();
        },
        600
      );
    }
  }

  @action
  confirmDeleteModel() {
    this.toggleProperty('isShowingModal');
  }

  @action
  toggleModal() {
    this.toggleProperty('isShowingModal');
  }

  @action
  cancelEditModel() {
    if (get(this, 'showLoading')) {
      get(this, 'loading').block();
    }
    let model = get(this, 'model');
    model.rollbackAttributes();
    set(this, 'isUserEditing', false);
    this.postCancelEditModel();
    if (get(this, 'showLoading')) {
      later(
        this,
        () => {
          get(this, 'loading').unblock();
        },
        1000
      );
    }
  }
}
