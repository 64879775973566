import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class WeldSketchDesignRoute extends Route {
  @service
  intl;

  @service session;

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('finishedDrawing', true);
  }

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  @action
  willTransition(transition) {
    if (
      !this.controller.finishedDrawing &&
      !confirm(this.intl.t('routes.weld-sketch-design.messages.abort-progress'))
    ) {
      transition.abort();
    } else {
      // Bubble the `willTransition` action so that
      // parent routes can decide whether or not to abort.
      return true;
    }
  }
}
