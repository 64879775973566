import Component from '@glimmer/component';
import { get, set, action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class BaseComponent extends Component {
  @service
  intl;

  get modelName() {
    return this.args.model.metadata.modelName;
  }

  get isRequired() {
    let { model, attribute } = this.args;
    return (
      get(model, `metadata.${attribute}.required`) ||
      get(model, `validations.${this.args.attribute}.presence`)
    );
  }

  get label() {
    let labelTranslationKey = `model.${this.modelName}.${this.args.attribute}.label`;
    if (!isEmpty(this.args.labelParams)) {
      return this.intl.t(labelTranslationKey, this.args.labelParams);
    } else {
      return this.intl.t(labelTranslationKey);
    }
  }

  get placeholder() {
    return this.intl.t(`model.${this.modelName}.${this.args.attribute}.placeholder`);
  }

  get help() {
    return this.intl.t(`model.${this.modelName}.${this.args.attribute}.help`);
  }

  get hasError() {
    let errors = get(this.args, `model.errors.${this.args.attribute}`);
    return errors && errors.length > 0;
  }

  get errors() {
    return get(this.args, `model.errors.${this.args.attribute}`);
  }

  updateValue(value) {
    set(this.args.model, this.args.attribute, value);
  }

  @action
  onUpdate(value) {
    if (this.args.onUpdate) {
      this.args.onUpdate(value);
    } else {
      this.updateValue(value);
    }
  }
}
