import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

@classic
export default class NewFromPqrRoute extends Route {
  @service
  userSession;

  @service('weldnote-data')
  data;

  @service session;

  @service
  router;

  beforeModel(transition) {
    super.beforeModel(...arguments);
    this.session.requireAuthentication(transition, 'login');
    if (this.userSession.isAccountLocked) {
      this.router.transitionTo('index');
    }
  }

  // If a pqr id is sent from the url use that
  model(params) {
    let { pqr: pqrId } = params;
    if (!isEmpty(pqrId)) {
      return this.data.loadAllDataPQR(pqrId);
    } else {
      return {};
    }
  }
}
