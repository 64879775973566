import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import BaseMaterial from './base-material';
import FileUpload from './file-upload';

export default class BaseMaterialCertificate extends BaseModel {
  @belongsTo('base-material')
  declare baseMaterial: AsyncBelongsTo<BaseMaterial>;

  @attr('string')
  declare baseMaterialLabel?: string;

  @attr('number')
  declare thickness?: number;

  @attr('number')
  declare diameter?: number;

  @attr('string')
  declare productType?: string;

  @attr('string')
  declare heatNumber?: string;

  @belongsTo('file-upload')
  declare certificate: AsyncBelongsTo<FileUpload>;

  @attr('string')
  declare certificatePath?: string;

  @attr('string')
  declare productForm?: string;

  get visualLabel(): string {
    return this.heatNumber || '';
  }

  validations = {
    thickness: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    diameter: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    productType: { presence: true },
    heatNumber: { presence: true },
    baseMaterial: { presence: true },
  };

  metadata = {
    modelName: 'base-material-certificate',
    listRoute: 'base-material-certificates',
    editRoute: 'base-material-certificate',
    heatNumber: { required: true },
    baseMaterial: { required: true },
    productType: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'base-material-certificate': BaseMaterialCertificate;
  }
}
