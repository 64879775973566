import { service } from '@ember/service';
import { attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { memberAction } from 'ember-api-actions';
import { hash } from 'rsvp';
import BaseModel from 'weldnote/models/base-model';
import Constants from 'weldnote/utils/constants';
import { isFilletWeld, isPartialJointPenetrationButtWeld } from 'weldnote/utils/rules/weld-type';
import { isBothSides } from 'weldnote/utils/rules/welding-detail';
import WPSRules from 'weldnote/utils/welding-procedure-specification-rules';
import WeldType from './weld-type';
import WeldingProcess from './welding-process';
import PqrStandard from './pqr-standard';
import PQRModel from './pqr';
import Company from './company';
import BaseMaterial from './base-material';
import WeldingPosition from './welding-position';
import FillerMaterial from './filler-material';
import FillerMaterialCommercialDesignation from './filler-material-commercial-designation';
import Gas from './gas';
import GasCommercialDesignation from './gas-commercial-designation';
import Flux from './flux';
import FluxCommercialDesignation from './flux-commercial-designation';
import PostWeldTreatmentMethod from './post-weld-treatment-method';
import Staff from './staff';
import FileUpload from './file-upload';
import GrooveDesign from './groove-design';
import WeldLayerConfiguration from './weld-layer-configuration';
import WeldingDetail from './welding-detail';
import PreparationMethod from './preparation-method';
import ProcessMecanization from './process-mecanization';
import ArcTransferMode from './arc-transfer-mode';
import TypeCurrentPolarity from './type-current-polarity';
import CertificationSpecialRequirement from './certification-special-requirement';
import ConstructionStandard from './construction-standard';
import ProjectClient from './project-client';
import Project from './project';
import NullWpsValidation from 'weldnote/src-weldnote/types/wps/null-wps-validation';
import WpsWeldingParameter from './wps-welding-parameter';
import WpsValidationType from 'weldnote/src-weldnote/types/wps/wps-validation';

const { WELDING_PROCEDURE_SPECIFICATION } = WPSRules;
const WPS = WELDING_PROCEDURE_SPECIFICATION;

const { PRODUCT_TYPE, WELD_TYPES, WPS_TYPE, ARCHIVAL_STATE } = Constants;

class WPSModel extends BaseModel {
  @service
  declare userSession: any;

  @attr('string')
  declare wpsNumber?: string;

  @attr('string')
  declare guid?: string;

  @belongsTo('company', { async: false })
  declare company: Company;

  @belongsTo('pqr')
  declare mainPqr: PQRModel;

  @belongsTo('pqr-standard', { async: false })
  declare standard: PqrStandard;

  @attr('string')
  declare productType?: string;

  @belongsTo('weld-type', { async: false })
  declare weldType: WeldType;

  @belongsTo('welding-process', { async: false })
  declare weldingProcessRoot: WeldingProcess;

  @belongsTo('welding-process', { async: false })
  declare weldingProcessFill: WeldingProcess;

  @belongsTo('base-material', { async: false })
  declare baseMaterial1: BaseMaterial;

  @belongsTo('base-material', { async: false })
  declare baseMaterial2: BaseMaterial;

  @attr('decimal-value')
  declare thicknessMinimum?: number;

  @attr('decimal-value')
  declare thicknessMaximum?: number;

  @attr('decimal-value')
  declare diameterMinimum?: number;

  @attr('decimal-value')
  declare diameterMaximum?: number;

  @hasMany('welding-position', { async: false })
  declare weldingPositionsRoot: SyncHasMany<WeldingPosition>;

  @hasMany('welding-position', { async: false })
  declare weldingPositionsFill: SyncHasMany<WeldingPosition>;

  @belongsTo('filler-material', { async: false })
  declare fillerMaterialRoot: FillerMaterial;

  @belongsTo('filler-material-commercial-designation', {
    async: false,
  })
  declare fillerMaterialRootCommercialDesignation: FillerMaterialCommercialDesignation;

  @belongsTo('filler-material', { async: false })
  declare fillerMaterialFill: FillerMaterial;

  @belongsTo('filler-material-commercial-designation', {
    async: false,
  })
  declare fillerMaterialFillCommercialDesignation: FillerMaterialCommercialDesignation;

  @attr('string')
  declare shieldingRoot?: string;

  @belongsTo('gas', { async: false })
  declare shieldingRootGas: Gas;

  @belongsTo('gas-commercial-designation', { async: false })
  declare shieldingRootGasCommercialDesignation: GasCommercialDesignation;

  @attr('string')
  declare shieldingRootGasMixture?: string;

  @belongsTo('flux', { async: false })
  declare shieldingRootFlux: Flux;

  @belongsTo('flux-commercial-designation', {
    async: false,
  })
  declare shieldingRootFluxCommercialDesignation: FluxCommercialDesignation;

  @attr('string')
  declare flowRateRoot?: string;

  @attr('string')
  declare shieldingFill?: string;

  @belongsTo('gas', { async: false })
  declare shieldingFillGas: Gas;

  @belongsTo('gas-commercial-designation', { async: false })
  declare shieldingFillGasCommercialDesignation: GasCommercialDesignation;

  @attr('string')
  declare shieldingFillGasMixture?: string;

  @belongsTo('flux', { async: false })
  declare shieldingFillFlux: Flux;

  @belongsTo('flux-commercial-designation', {
    async: false,
  })
  declare shieldingFillFluxCommercialDesignation: FluxCommercialDesignation;

  @attr('string')
  declare flowRateFill?: string;

  @attr('string')
  declare backing?: string;

  @belongsTo('gas', { async: false })
  declare backingGas: Gas;

  @belongsTo('gas-commercial-designation', { async: false })
  declare backingGasCommercialDesignation: GasCommercialDesignation;

  @attr('string')
  declare backingGasMixture?: string;

  @belongsTo('flux', { async: false })
  declare backingFlux: Flux;

  @belongsTo('flux-commercial-designation', { async: false })
  declare backingFluxCommercialDesignation: FluxCommercialDesignation;

  @attr('string')
  declare backingFlowRateRoot?: string;

  @attr('string')
  declare backingFlowRateFill?: string;

  @attr('decimal-value')
  declare rootFaceMinimum?: number;

  @attr('decimal-value')
  declare rootFaceMaximum?: number;

  @attr('decimal-value')
  declare rootGapMinimum?: number;

  @attr('decimal-value')
  declare rootGapMaximum?: number;

  @attr('string')
  declare electrodeType?: string;

  @attr('number')
  declare numberOfElectrodes?: number;

  @attr('string')
  declare pulsedWeldingDetails?: string;

  @attr('string')
  declare weaving?: string;

  @attr('string')
  declare oscillation?: string;

  @attr('string')
  declare contactTubeDistance?: string;

  @attr('string')
  declare backGaugingDetails?: string;

  @attr('decimal-value')
  declare heatInputMinimum?: number;

  @attr('decimal-value')
  declare heatInputMaximum?: number;

  @attr('decimal-value')
  declare postWeldHeatTreatmentTimeMinimum?: number;

  @attr('decimal-value')
  declare postWeldHeatTreatmentTimeMaximum?: number;

  @belongsTo('post-weld-treatment-method', { async: false })
  declare postWeldHeatTreatmentMethod: PostWeldTreatmentMethod;

  @attr('number')
  declare postWeldHeatTreatmentHeatingRate?: number;

  @attr('number')
  declare postWeldHeatTreatmentCoolingRate?: number;

  @attr('string', { defaultValue: '0' })
  declare revision: string;

  @belongsTo('staff', { async: false })
  declare writtenBy: Staff;

  @attr('date')
  declare writtenByDate?: Date;

  @belongsTo('staff', { async: false })
  declare verifiedBy: Staff;

  @attr('date')
  declare verifiedByDate?: Date;

  @belongsTo('staff', { async: false })
  declare approvedBy: Staff;

  @attr('date')
  declare approvedByDate?: Date;

  @attr('string')
  declare status?: string;

  @hasMany('wps-welding-parameter', { async: false })
  declare weldingParameters: SyncHasMany<WpsWeldingParameter>;

  @attr()
  declare weldingParametersRaw?: string;

  @belongsTo('file-upload', { async: false })
  declare wpsDocument: FileUpload;

  @hasMany('groove-design', { async: false })
  declare grooveDesigns: SyncHasMany<GrooveDesign>;

  @hasMany('weld-layer-configuration', { async: false })
  declare weldLayerConfigurations: SyncHasMany<WeldLayerConfiguration>;

  @hasMany('welding-detail', { async: false })
  declare weldingDetails: SyncHasMany<WeldingDetail>;

  @attr('number')
  declare branchAngleMinimum?: number;

  @attr('number')
  declare branchAngleMaximum?: number;

  @attr('decimal-value')
  declare grooveAngleMinimum?: number;

  @attr('decimal-value')
  declare grooveAngleMaximum?: number;

  @attr('number')
  declare postWeldHeatTreatmentTemperatureMinimum?: number;

  @attr('number')
  declare postWeldHeatTreatmentTemperatureMaximum?: number;

  @attr('decimal-value')
  declare throatThicknessMinimum?: number;

  @attr('decimal-value')
  declare throatThicknessMaximum?: number;

  @attr('decimal-value')
  declare impactTestTemperature?: number;

  @attr('boolean')
  declare hardnessTestRequired?: boolean;

  @attr('number')
  declare preheatTemperatureMinimum?: number;

  @attr('number')
  declare preheatTemperatureMaximum?: number;

  @attr('number')
  declare interpassTemperatureMinimum?: number;

  @attr('number')
  declare interpassTemperatureMaximum?: number;

  @attr('number')
  declare postHeatTemperatureMinimum?: number;

  @attr('number')
  declare postHeatTemperatureMaximum?: number;

  @hasMany('pqr', { async: false })
  declare secondaryPqrs: SyncHasMany<PQRModel>;

  @attr('string')
  declare notes?: string;

  @hasMany('preparation-method', { async: false })
  declare preparationMethods: SyncHasMany<PreparationMethod>;

  @attr('number')
  declare torchAngleMinimum?: number;

  @attr('number')
  declare torchAngleMaximum?: number;

  @attr('string')
  declare revisionStatus?: string;

  // The inverse null relationship is very important. Without it, when loading a WPS
  // in the edit screen if that WPS (A) has a master WPS (B) which in turn does not have
  // a master WPS, loading A, correctly loads B, but when loading B (with null as master wps)
  // the reference to the B WPS on A is removed
  @belongsTo('welding-procedure-specification', { inverse: null, async: false })
  declare masterWps: WPSModel;

  @attr('decimal-value')
  declare gasNozzleDiameterRoot?: number;

  @attr('decimal-value')
  declare gasNozzleDiameterFill?: number;

  @belongsTo('process-mecanization', { async: false })
  declare processMecanizationRoot: ProcessMecanization;

  @belongsTo('process-mecanization', { async: false })
  declare processMecanizationFill: ProcessMecanization;

  @belongsTo('arc-transfer-mode', { async: false })
  declare arcTransferModeRoot: ArcTransferMode;

  @belongsTo('arc-transfer-mode', { async: false })
  declare arcTransferModeFill: ArcTransferMode;

  @belongsTo('arc-transfer-mode', { async: false })
  declare arcTransferModeCap: ArcTransferMode;

  @belongsTo('welding-process', { async: false })
  declare weldingProcessCap: WeldingProcess;

  @attr('number')
  declare gasNozzleDiameterCap?: number;

  @belongsTo('process-mecanization', { async: false })
  declare processMecanizationCap: ProcessMecanization;

  @belongsTo('type-current-polarity', { async: false })
  declare typeCurrentRoot: TypeCurrentPolarity;

  @belongsTo('type-current-polarity', { async: false })
  declare typeCurrentFill: TypeCurrentPolarity;

  @belongsTo('type-current-polarity', { async: false })
  declare typeCurrentCap: TypeCurrentPolarity;

  @attr('number')
  declare numberOfElectrodesFill?: number;

  @attr('number')
  declare numberOfElectrodesCap?: number;

  @hasMany('welding-position', { async: false })
  declare weldingPositionsCap: SyncHasMany<WeldingPosition>;

  @attr('string')
  declare shieldingCap?: string;

  @belongsTo('gas', { async: false })
  declare shieldingCapGas: Gas;

  @belongsTo('gas-commercial-designation', { async: false })
  declare shieldingCapGasCommercialDesignation: GasCommercialDesignation;

  @attr('string')
  declare shieldingCapGasMixture?: string;

  @belongsTo('flux', { async: false })
  declare shieldingCapFlux: Flux;

  @belongsTo('flux-commercial-designation', { async: false })
  declare shieldingCapFluxCommercialDesignation: FluxCommercialDesignation;

  @attr('string')
  declare flowRateCap?: string;

  @attr('string')
  declare backingFlowRateCap?: string;

  @belongsTo('filler-material', { async: false })
  declare fillerMaterialCap: FillerMaterial;

  @belongsTo('filler-material-commercial-designation', {
    async: false,
  })
  declare fillerMaterialCapCommercialDesignation: FillerMaterialCommercialDesignation;

  @attr('decimal-value')
  declare baseMaterial1ThicknessMinimum?: number;

  @attr('decimal-value')
  declare baseMaterial1ThicknessMaximum?: number;

  @attr('number')
  declare baseMaterial1Diameter?: number;

  @attr('decimal-value')
  declare baseMaterial2ThicknessMinimum?: number;

  @attr('decimal-value')
  declare baseMaterial2ThicknessMaximum?: number;

  @attr('number')
  declare baseMaterial2Diameter?: number;

  @attr('string')
  declare weldingPositionRootDirection?: string;

  @attr('string')
  declare weldingPositionFillDirection?: string;

  @attr('string')
  declare weldingPositionCapDirection?: string;

  @hasMany('certification-special-requirement', { async: false })
  declare specialRequirements: SyncHasMany<CertificationSpecialRequirement>;

  @attr('string')
  declare overlayType?: string;

  @attr('number')
  declare postHeatTimeMinimum?: number;

  @attr('number')
  declare postHeatTimeMaximum?: number;

  @attr('string')
  declare jointType?: string;

  @attr('string')
  declare electrodeTypeFill?: string;

  @attr('string')
  declare electrodeTypeCap?: string;

  @attr('string')
  declare contactTubeDistanceFill?: string;

  @attr('string')
  declare contactTubeDistanceCap?: string;

  @attr('string')
  declare weavingFill?: string;

  @attr('string')
  declare weavingCap?: string;

  @attr('string')
  declare tungstenDiameterRoot?: string;

  @attr('string')
  declare tungstenDiameterFill?: string;

  @attr('string')
  declare tungstenDiameterCap?: string;

  @belongsTo('pqr', { async: false })
  declare pqrFill: PQRModel;

  @belongsTo('pqr', { async: false })
  declare pqrCap: PQRModel;

  @attr('string')
  declare pqrRootLayer?: string;

  @attr('string')
  declare pqrFillLayer?: string;

  @attr('string')
  declare pqrCapLayer?: string;

  @attr('decimal-value')
  declare thicknessRootMinimum?: number;

  @attr('decimal-value')
  declare thicknessRootMaximum?: number;

  @attr('decimal-value')
  declare thicknessFillMinimum?: number;

  @attr('decimal-value')
  declare thicknessFillMaximum?: number;

  @attr('decimal-value')
  declare thicknessCapMinimum?: number;

  @attr('decimal-value')
  declare thicknessCapMaximum?: number;

  @belongsTo('construction-standard', { async: false })
  declare constructionStandard: ConstructionStandard;

  @belongsTo('project-client', { async: false })
  declare projectClient: ProjectClient;

  // Seems to be the same issue with the MasterWPS, if I don't have the inverse set
  // to null, EmberData is removing the WPS from the screen
  @belongsTo('project', { inverse: null, async: false })
  declare project: Project;

  @attr('number')
  declare overlayNumberOfLayers?: number;

  @attr('string')
  declare revisionNotes?: string;

  @attr('string')
  declare wpsType?: string;

  @attr('string')
  declare fillerMaterialElectrodeFormRoot?: string;

  @attr('string')
  declare fillerMaterialElectrodeFormFill?: string;

  @attr('string')
  declare fillerMaterialElectrodeFormCap?: string;

  @attr('string')
  declare fillerMaterialGuideRoot?: string;

  @attr('string')
  declare fillerMaterialGuideFill?: string;

  @attr('string')
  declare fillerMaterialGuideCap?: string;

  @attr('string')
  declare oscillationWidthRoot?: string;

  @attr('string')
  declare oscillationWidthFill?: string;

  @attr('string')
  declare oscillationWidthCap?: string;

  @attr('string')
  declare oscillationFrequencyRoot?: string;

  @attr('string')
  declare oscillationFrequencyFill?: string;

  @attr('string')
  declare oscillationFrequencyCap?: string;

  @attr('string')
  declare oscillationDwellTimeRoot?: string;

  @attr('string')
  declare oscillationDwellTimeFill?: string;

  @attr('string')
  declare oscillationDwellTimeCap?: string;

  @attr('boolean')
  declare supplementalDeviceRoot?: boolean;

  @attr('boolean')
  declare supplementalDeviceFill?: boolean;

  @attr('boolean')
  declare supplementalDeviceCap?: boolean;

  @belongsTo('pqr', { async: false })
  declare multiplePositionPqrRoot: PQRModel;

  @belongsTo('pqr', { async: false })
  declare multiplePositionPqrFill: PQRModel;

  @belongsTo('pqr', { async: false })
  declare multiplePositionPqrCap: PQRModel;

  @attr('string')
  declare archivalState?: string;

  @attr()
  declare fillerMaterialRootDiameters: string;

  @attr()
  declare fillerMaterialFillDiameters: string;

  @attr()
  declare fillerMaterialCapDiameters: string;

  @attr('number')
  declare totalWelds?: number;

  @attr('number')
  declare goodWelds?: number;

  @attr('number')
  declare repairedWelds?: number;

  @attr('number')
  declare repairRate?: number;

  @attr('number')
  declare flowRateProcess1Minimum?: number;

  @attr('number')
  declare flowRateProcess1Maximum?: number;

  @attr('number')
  declare flowRateProcess2Minimum?: number;

  @attr('number')
  declare flowRateProcess2Maximum?: number;

  @attr('number')
  declare flowRateProcess3Minimum?: number;

  @attr('number')
  declare flowRateProcess3Maximum?: number;

  @attr({
    defaultValue() {
      return {};
    },
  })
  declare additionalData: string;

  // Validator must be created before validations occur
  validator: WpsValidationType = new NullWpsValidation();

  @computed('wpsNumber', 'revision')
  get visualLabel() {
    return `${this.wpsNumber} - ${this.revision}`;
  }

  validations = {
    wpsNumber: { presence: true },
    standard: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    thicknessMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value) && model.validateDimensions();
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isThicknessTotalMinimumValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.thicknessTotalMinimumInvalidMessage;
        },
      },
    },

    thicknessMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value) && model.validateDimensions();
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isThicknessTotalMaximumValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.thicknessTotalMaximumInvalidMessage;
        },
      },
    },

    diameterMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (get(model, 'isPlate')) {
              return true;
            }
            if (
              model.shouldValidateSingleValue(value) &&
              model.validateDimensions() &&
              get(model, 'isPipe')
            ) {
              return model.validator.isDiameterValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.diameterInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (get(model, 'isPlate')) {
              return true;
            }
            if (model.shouldValidateSingleValue(value) && model.validateDimensions()) {
              return model.validator.isDiameterValidForPipe(value);
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.diameterForPipeInvalidMessage;
          },
        },
      ],
    },

    diameterMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          if (get(model, 'isPlate')) {
            return false;
          }
          return (
            model.shouldValidateSingleValue(value) &&
            model.validateDimensions() &&
            get(model, 'isPipe')
          );
        },

        validation(_key: string, value: any, model: WPSModel) {
          return model.validator.isDiameterValid(value);
        },

        message(_key: string, value: any, model: WPSModel): string {
          return model.validator.diameterInvalidMessage(value);
        },
      },
    },

    fillerMaterialRootDiameters: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isFillerMaterialDiameterRootValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.fillerMaterialDiameterRootInvalidMessage;
        },
      },
    },

    fillerMaterialFillDiameters: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isFillerMaterialDiameterFillValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.fillerMaterialDiameterFillInvalidMessage;
        },
      },
    },

    fillerMaterialCapDiameters: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isFillerMaterialDiameterCapValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.fillerMaterialDiameterCapInvalidMessage;
        },
      },
    },

    rootFaceMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    rootFaceMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    rootGapMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    rootGapMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    numberOfElectrodes: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 20,
        onlyInteger: true,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isNumberElectrodesRootValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.numberOfElectrodesRootInvalidMessage;
        },
      },
    },

    heatInputMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99999.99,
      },
    },

    heatInputMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99999.99,
      },
    },

    postWeldHeatTreatmentTimeMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 999.99,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isPwhtTimeValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.pwthTimeInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(model.validator)) {
              return model.validator.isPWHTTimeMinimumPresenceValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.pWHTTimeMinimumPresenceInvalidMessage(value);
          },
        },
      ],
    },

    postWeldHeatTreatmentTimeMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 999.99,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isPwhtTimeValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.pwthTimeInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(model.validator)) {
              return model.validator.isPWHTTimeMaximumPresenceValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.pWHTTimeMaximumPresenceInvalidMessage(value);
          },
        },
      ],
    },

    postWeldHeatTreatmentHeatingRate: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },

      custom: {
        validation(_key: string, value: any, model: WPSModel) {
          let userSession = get(model, 'userSession');
          if (!userSession.hasFeature('ge-requirements')) {
            return true;
          }
          let pwhtTemperatureMin = model.postWeldHeatTreatmentTemperatureMinimum;
          let pwhtTemperatureMax = model.postWeldHeatTreatmentTemperatureMaximum;
          let hasPwht = !isEmpty(pwhtTemperatureMin) || !isEmpty(pwhtTemperatureMax);
          if (hasPwht && isEmpty(value)) {
            return false;
          }
          return true;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.translate('model-validations.wps.heating-rate-required');
        },
      },
    },

    postWeldHeatTreatmentCoolingRate: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },

      custom: {
        validation(_key: string, value: any, model: WPSModel) {
          let userSession = get(model, 'userSession');
          if (!userSession.hasFeature('ge-requirements')) {
            return true;
          }
          let pwhtTemperatureMin = model.postWeldHeatTreatmentTemperatureMinimum;
          let pwhtTemperatureMax = model.postWeldHeatTreatmentTemperatureMaximum;
          let hasPwht = !isEmpty(pwhtTemperatureMin) || !isEmpty(pwhtTemperatureMax);
          if (hasPwht && isEmpty(value)) {
            return false;
          }
          return true;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.translate('model-validations.wps.cooling-rate-required');
        },
      },
    },

    branchAngleMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 90,
        onlyInteger: true,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, value: any, model: WPSModel) {
          return model.validator.isBranchAngleValid(value);
        },

        message(_key: string, value: any, model: WPSModel): string {
          return model.validator.branchAngleInvalidMessage(value);
        },
      },
    },

    branchAngleMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 90,
        onlyInteger: true,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, value: any, model: WPSModel) {
          return model.validator.isBranchAngleValid(value);
        },

        message(_key: string, value: any, model: WPSModel): string {
          return model.validator.branchAngleInvalidMessage(value);
        },
      },
    },

    grooveAngleMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 360,
      },
    },

    grooveAngleMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 360,
      },
    },

    postWeldHeatTreatmentTemperatureMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isPwhtTemperatureValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.pwthTemperatureInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(model.validator)) {
              return model.validator.isPWHTMinimumPresenceValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.pWHTMinimumPresenceInvalidMessage(value);
          },
        },
      ],
    },

    postWeldHeatTreatmentTemperatureMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isPwhtTemperatureValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.pwthTemperatureInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(model.validator)) {
              return model.validator.isPWHTMaximumPresenceValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.pWHTMaximumPresenceInvalidMessage(value);
          },
        },
      ],
    },

    throatThicknessMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    throatThicknessMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    preheatTemperatureMinimum: {
      numericality: {
        allowBlank: true,
        lessThanOrEqualTo: 9999,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isPreheatTemperatureValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.preheatTemperatureInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(model.validator)) {
              return model.validator.isPreheatMinimumPresenceValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.preheatMinimumPresenceInvalidMessage(value);
          },
        },
      ],
    },

    preheatTemperatureMaximum: {
      numericality: {
        allowBlank: true,
        lessThanOrEqualTo: 9999,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isPreheatTemperatureValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.preheatTemperatureInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(model.validator)) {
              return model.validator.isPreheatMaximumPresenceValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.preheatMaximumPresenceInvalidMessage(value);
          },
        },
      ],
    },

    interpassTemperatureMinimum: {
      numericality: {
        allowBlank: true,
        lessThanOrEqualTo: 9999,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isInterpassTemperatureValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.interpassTemperatureInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(model.validator)) {
              return model.validator.isInterpassTemperatureMinimumPresenceValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.interpassTemperatureMinimumPresenceInvalidMessage(value);
          },
        },
      ],
    },

    interpassTemperatureMaximum: {
      numericality: {
        allowBlank: true,
        lessThanOrEqualTo: 9999,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isInterpassTemperatureValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.interpassTemperatureInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(model.validator)) {
              return model.validator.isInterpassTemperatureMaximumPresenceValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.interpassTemperatureMaximumPresenceInvalidMessage(value);
          },
        },
      ],
    },

    postHeatTemperatureMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isPostHeatTemperatureValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.postHeatTemperatureInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(model.validator)) {
              return model.validator.isPostHeatMinimumPresenceValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.postHeatMinimumPresenceInvalidMessage(value);
          },
        },
      ],
    },

    postHeatTemperatureMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isPostHeatTemperatureValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.postHeatTemperatureInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(model.validator)) {
              return model.validator.isPostHeatMaximumPresenceValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.postHeatMaximumPresenceInvalidMessage(value);
          },
        },
      ],
    },

    notes: {
      length: {
        maximum: 5000,
      },
    },

    torchAngleMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 180,
        onlyInteger: true,
      },
    },

    torchAngleMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 180,
        onlyInteger: true,
      },
    },

    revision: {
      presence: {
        message(_key: string, _value: any, model: WPSModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    gasNozzleDiameterRoot: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isGasNozzleDiameterRootValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.gasNozzleDiameterRootInvalidMessage;
        },
      },
    },

    gasNozzleDiameterFill: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isGasNozzleDiameterFillValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.gasNozzleDiameterFillInvalidMessage;
        },
      },
    },

    gasNozzleDiameterCap: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isGasNozzleDiameterCapValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.gasNozzleDiameterCapInvalidMessage;
        },
      },
    },

    numberOfElectrodesFill: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 20,
        onlyInteger: true,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isNumberElectrodesFillValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.numberOfElectrodesFillInvalidMessage;
        },
      },
    },

    numberOfElectrodesCap: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 20,
        onlyInteger: true,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isNumberElectrodesCapValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.numberOfElectrodesCapInvalidMessage;
        },
      },
    },

    baseMaterial1ThicknessMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, value: any, model: WPSModel) {
          let bm1 = parseFloat(value);
          if (!isNaN(bm1)) {
            return model.validator.isBaseMaterial1ThicknessValid(bm1);
          }
          return true;
        },

        message(_key: string, value: any, model: WPSModel): string {
          return model.validator.baseMaterial1ThicknessInvalidMessage(value);
        },
      },
    },

    baseMaterial1ThicknessMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, value: any, model: WPSModel) {
          let bm1 = parseFloat(value);
          if (!isNaN(bm1)) {
            return model.validator.isBaseMaterial1ThicknessValid(bm1);
          }
          return true;
        },

        message(_key: string, value: any, model: WPSModel): string {
          return model.validator.baseMaterial1ThicknessInvalidMessage(value);
        },
      },
    },

    baseMaterial1Diameter: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          if (get(model, 'isPlate')) {
            return false;
          }
          if (model.shouldValidateSingleValue(value)) {
            let bm1 = parseFloat(value);
            let bm2 = parseFloat(`${model.baseMaterial2Diameter}`);
            if (!isNaN(bm1) && !isNaN(bm2)) {
              return bm1 <= bm2;
            }
          }
          return false;
        },

        validation(_key: string, value: any, model: WPSModel) {
          let bm1 = parseFloat(value);
          if (!isNaN(bm1)) {
            return model.validator.isDiameterValid(value);
          }
          return true;
        },

        message(_key: string, value: any, model: WPSModel): string {
          return model.validator.diameterInvalidMessage(value);
        },
      },
    },

    baseMaterial2ThicknessMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, value: any, model: WPSModel) {
          let bm2 = parseFloat(value);
          if (!isNaN(bm2)) {
            return model.validator.isBaseMaterial2ThicknessValid(bm2);
          }
          return true;
        },

        message(_key: string, value: any, model: WPSModel): string {
          return model.validator.baseMaterial2ThicknessInvalidMessage(value);
        },
      },
    },

    baseMaterial2ThicknessMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, value: any, model: WPSModel) {
          let bm2 = parseFloat(value);
          if (!isNaN(bm2)) {
            return model.validator.isBaseMaterial2ThicknessValid(bm2);
          }
          return true;
        },

        message(_key: string, value: any, model: WPSModel): string {
          return model.validator.baseMaterial2ThicknessInvalidMessage(value);
        },
      },
    },

    baseMaterial2Diameter: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          if (model.isPlate) {
            return false;
          }
          if (model.shouldValidateSingleValue(value)) {
            let bm2 = parseFloat(value);
            let bm1 = parseFloat(`${model.baseMaterial1Diameter}`);
            if (!isNaN(bm1) && !isNaN(bm2)) {
              return bm2 <= bm1;
            }
          }
          return false;
        },

        validation(_key: string, value: any, model: WPSModel) {
          let bm1 = parseFloat(value);
          if (!isNaN(bm1)) {
            return model.validator.isDiameterValid(value);
          }
          return true;
        },

        message(_key: string, value: any, model: WPSModel): string {
          return model.validator.diameterInvalidMessage(value);
        },
      },
    },

    postHeatTimeMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isPostHeatTimeValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.postHeatTimeInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(model.validator)) {
              return model.validator.isPostHeatTimeMinimumPresenceValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.postHeatTimeMinimumPresenceInvalidMessage(value);
          },
        },
      ],
    },

    postHeatTimeMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isPostHeatTimeValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.postHeatTimeInvalidMessage(value);
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(model.validator)) {
              return model.validator.isPostHeatTimeMaximumPresenceValid(value);
            }
            return true;
          },

          message(_key: string, value: any, model: WPSModel): string {
            return model.validator.postHeatTimeMaximumPresenceInvalidMessage(value);
          },
        },
      ],
    },

    weldingProcessRoot: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isWeldingProcessRootValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.weldingProcessRootInvalidMessage;
        },
      },
    },

    weldingProcessFill: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isWeldingProcessFillValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.weldingProcessFillInvalidMessage;
        },
      },
    },

    weldingProcessCap: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isWeldingProcessCapValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.weldingProcessCapInvalidMessage;
        },
      },
    },

    weldingDetails: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateCollectionValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isWeldingDetailsValid;
        },

        message(_key: string, _value: any, model: WPSModel) {
          return model.validator.weldingDetailsInvalidMessage;
        },
      },
    },

    processMecanizationRoot: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isProcessMecanizationRootValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.processMecanizationRootInvalidMessage;
        },
      },
    },

    processMecanizationFill: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isProcessMecanizationFillValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.processMecanizationFillInvalidMessage;
        },
      },
    },

    processMecanizationCap: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isProcessMecanizationCapValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.processMecanizationCapInvalidMessage;
        },
      },
    },

    typeCurrentRoot: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isTypeCurrentRootValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.typeCurrentRootInvalidMessage;
        },
      },
    },

    typeCurrentFill: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isTypeCurrentFillValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.typeCurrentFillInvalidMessage;
        },
      },
    },

    typeCurrentCap: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isTypeCurrentCapValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.typeCurrentCapInvalidMessage;
        },
      },
    },

    arcTransferModeRoot: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isArcTransferModeRootValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.arcTransferModeRootInvalidMessage;
        },
      },
    },

    arcTransferModeFill: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isArcTransferModeFillValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.arcTransferModeFillInvalidMessage;
        },
      },
    },

    arcTransferModeCap: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isArcTransferModeCapValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.arcTransferModeCapInvalidMessage;
        },
      },
    },

    weldType: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isWeldTypeValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.weldTypeInvalidMessage;
        },
      },
    },

    baseMaterial1: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isBaseMaterial1Valid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.baseMaterial1InvalidMessage;
        },
      },
    },

    baseMaterial2: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isBaseMaterial2Valid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.baseMaterial2InvalidMessage;
        },
      },
    },

    overlayType: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isOverlayTypeValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.overlayTypeInvalidMessage;
        },
      },
    },

    productType: {
      custom: {
        validation(_key: string, wpsProductType: any, model: WPSModel) {
          if (model.shouldValidateSingleValue(wpsProductType)) {
            return model.validator.isProductTypeValid;
          }
          return true;
        },

        message(_key: string, _wpsProductType: any, model: WPSModel) {
          return model.validator.productTypeInvalidMessage;
        },
      },
    },

    fillerMaterialRoot: {
      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateRelationValue(value)) {
              return model.validator.isFillerMaterialRootValid;
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.fillerMaterialRootInvalidMessage;
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateRelationValue(value)) {
              return model.validator.isFillerMaterialRootAWSValid;
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.fillerMaterialRootAWSInvalidMessage;
          },
        },
      ],
    },

    fillerMaterialFill: {
      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateRelationValue(value)) {
              return model.validator.isFillerMaterialFillValid;
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.fillerMaterialFillInvalidMessage;
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateRelationValue(value)) {
              return model.validator.isFillerMaterialFillAWSValid;
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.fillerMaterialFillAWSInvalidMessage;
          },
        },
      ],
    },

    fillerMaterialCap: {
      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateRelationValue(value)) {
              return model.validator.isFillerMaterialCapValid;
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.fillerMaterialCapInvalidMessage;
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateRelationValue(value)) {
              return model.validator.isFillerMaterialCapAWSValid;
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.fillerMaterialCapAWSInvalidMessage;
          },
        },
      ],
    },

    fillerMaterialRootCommercialDesignation: {
      custom: {
        if(_key: string, value: any, wps: WPSModel) {
          return wps.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, wps: WPSModel) {
          return wps.validator.isFillerMaterialRootCommercialDesignationValid;
        },

        message(_key: string, _value: any, wps: WPSModel) {
          return wps.validator.fillerMaterialRootCommercialDesignationInvalidMessage;
        },
      },
    },

    fillerMaterialFillCommercialDesignation: {
      custom: {
        if(_key: string, value: any, wps: WPSModel) {
          return wps.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isFillerMaterialFillCommercialDesignationValid;
        },

        message(_key: string, _value: any, wps: WPSModel) {
          return wps.validator.fillerMaterialFillCommercialDesignationInvalidMessage;
        },
      },
    },

    fillerMaterialCapCommercialDesignation: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isFillerMaterialCapCommercialDesignationValid;
        },

        message(_key: string, _value: any, wps: WPSModel) {
          return wps.validator.fillerMaterialCapCommercialDesignationInvalidMessage;
        },
      },
    },

    shieldingRoot: {
      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isShieldingRootValid;
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.shieldingRootInvalidMessage;
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isShieldingRootValidForProcess;
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.shieldingRootInvalidMessageForProcess;
          },
        },
      ],
    },

    shieldingFill: {
      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isShieldingFillValid;
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.shieldingFillInvalidMessage;
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isShieldingFillValidForProcess;
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.shieldingFillInvalidMessageForProcess;
          },
        },
      ],
    },

    shieldingCap: {
      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isShieldingCapValid;
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.shieldingCapInvalidMessage;
          },
        },
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (model.shouldValidateSingleValue(value)) {
              return model.validator.isShieldingCapValidForProcess;
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel): string {
            return model.validator.shieldingCapInvalidMessageForProcess;
          },
        },
      ],
    },

    backing: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isBackingValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.backingInvalidMessage;
        },
      },
    },

    shieldingRootGas: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isShieldingRootGasValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.shieldingRootGasInvalidMessage;
        },
      },
    },

    shieldingFillGas: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isShieldingFillGasValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.shieldingFillGasInvalidMessage;
        },
      },
    },

    shieldingCapGas: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isShieldingCapGasValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.shieldingCapGasInvalidMessage;
        },
      },
    },

    backingGas: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isBackingGasValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.backingGasInvalidMessage;
        },
      },
    },

    shieldingRootFlux: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isShieldingRootFluxValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.shieldingRootFluxInvalidMessage;
        },
      },
    },

    shieldingFillFlux: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isShieldingFillFluxValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.shieldingFillFluxInvalidMessage;
        },
      },
    },

    shieldingCapFlux: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isShieldingCapFluxValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.shieldingCapFluxInvalidMessage;
        },
      },
    },

    backingFlux: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isBackingFluxValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.backingFluxInvalidMessage;
        },
      },
    },

    shieldingRootFluxCommercialDesignation: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isShieldingRootFluxCommercialDesignationValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.shieldingRootFluxCommercialDesignationInvalidMessage;
        },
      },
    },

    shieldingFillFluxCommercialDesignation: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isShieldingFillFluxCommercialDesignationValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.shieldingFillFluxCommercialDesignationInvalidMessage;
        },
      },
    },

    shieldingCapFluxCommercialDesignation: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isShieldingCapFluxCommercialDesignationValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.shieldingCapFluxCommercialDesignationInvalidMessage;
        },
      },
    },

    backingFluxCommercialDesignation: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateRelationValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isBackingFluxCommercialDesignationValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.backingFluxCommercialDesignationInvalidMessage;
        },
      },
    },

    weldingParameters: {
      relations: ['hasMany'],
    },

    weldingPositionRootDirection: {
      custom: {
        if(_key: string, _value: any, model: WPSModel) {
          return get(model, 'isLinkedToPQR') || get(model, 'isPrequalified');
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isWeldingPositionRootDirectionValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.weldingPositionRootDirectionInvalidMessage;
        },
      },
    },

    weldingPositionFillDirection: {
      custom: {
        if(_key: string, _value: any, model: WPSModel) {
          return get(model, 'isLinkedToPQR') || get(model, 'isPrequalified');
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isWeldingPositionFillDirectionValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.weldingPositionFillDirectionInvalidMessage;
        },
      },
    },

    weldingPositionCapDirection: {
      custom: {
        if(_key: string, _value: any, model: WPSModel) {
          return get(model, 'isLinkedToPQR') || get(model, 'isPrequalified');
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isWeldingPositionCapDirectionValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.weldingPositionCapDirectionInvalidMessage;
        },
      },
    },

    weldingPositionsRoot: {
      custom: {
        validation(_key: string, value: any, model: WPSModel) {
          if (model.shouldValidateCollectionValue(value)) {
            return model.validator.isWeldingPositionRootValid;
          }
          return true;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.weldingPositionRootInvalidMessage;
        },
      },
    },

    weldingPositionsFill: {
      custom: {
        validation(_key: string, value: any, model: WPSModel) {
          if (model.shouldValidateCollectionValue(value)) {
            return model.validator.isWeldingPositionFillValid;
          }
          return true;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.weldingPositionFillInvalidMessage;
        },
      },
    },

    weldingPositionsCap: {
      custom: {
        validation(_key: string, value: any, model: WPSModel) {
          if (model.shouldValidateCollectionValue(value)) {
            return model.validator.isWeldingPositionCapValid;
          }
          return true;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.weldingPositionCapInvalidMessage;
        },
      },
    },

    thicknessRootMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isThicknessRootMinimumValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.thicknessRootMinimumInvalidMessage;
        },
      },
    },

    thicknessRootMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isThicknessRootMaximumValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.thicknessRootMaximumInvalidMessage;
        },
      },
    },

    thicknessFillMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isThicknessFillMinimumValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.thicknessFillMinimumInvalidMessage;
        },
      },
    },

    thicknessFillMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isThicknessFillMaximumValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.thicknessFillMaximumInvalidMessage;
        },
      },
    },

    thicknessCapMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isThicknessCapMinimumValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.thicknessCapMinimumInvalidMessage;
        },
      },
    },

    thicknessCapMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isThicknessCapMaximumValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.thicknessCapMaximumInvalidMessage;
        },
      },
    },

    hardnessTestRequired: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isHardnessRequirementsValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.hardnessRequirementsInvalidMessage;
        },
      },
    },

    impactTestTemperature: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isImpactTestTemperatureValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.impactTestTemperatureInvalidMessage;
        },
      },
    },

    overlayNumberOfLayers: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        onlyInteger: true,
      },

      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isOverlayNumberOfLayerValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.overlayNumberOfLayerInvalidMessage;
        },
      },
    },

    fillerMaterialElectrodeFormRoot: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isFillerMaterialElectrodeFormRootValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.fillerMaterialElectrodeFormRootInvalidMessage;
        },
      },
    },

    fillerMaterialElectrodeFormFill: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isFillerMaterialElectrodeFormFillValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.fillerMaterialElectrodeFormFillInvalidMessage;
        },
      },
    },

    fillerMaterialElectrodeFormCap: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isFillerMaterialElectrodeFormCapValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.fillerMaterialElectrodeFormCapInvalidMessage;
        },
      },
    },

    fillerMaterialGuideRoot: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isFillerMaterialGuideRootValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.fillerMaterialGuideRootInvalidMessage;
        },
      },
    },

    fillerMaterialGuideFill: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isFillerMaterialGuideFillValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.fillerMaterialGuideFillInvalidMessage;
        },
      },
    },

    fillerMaterialGuideCap: {
      custom: {
        if(_key: string, value: any, model: WPSModel) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, _value: any, model: WPSModel) {
          return model.validator.isFillerMaterialGuideCapValid;
        },

        message(_key: string, _value: any, model: WPSModel): string {
          return model.validator.fillerMaterialGuideCapInvalidMessage;
        },
      },
    },

    flowRateProcess1Minimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
      custom: [
        {
          validation(_key: string, value: any, model: PQRModel) {
            if (!isEmpty(value)) {
              return model.validateMinimum('flowRateProcess1Maximum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: PQRModel) {
            return model.getBelowMaximumMessage(get(model, 'flowRateProcess1Maximum'), model.flowUnit);
          },
        },
      ]
    },
    flowRateProcess1Maximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
      custom: [
        {
          validation(_key: string, value: any, model: PQRModel) {
            if (!isEmpty(value)) {
              return model.validateMaximum('flowRateProcess1Minimum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: PQRModel) {
            return model.getAboveMinimumMessage(get(model, 'flowRateProcess1Minimum'), model.flowUnit);
          },
        },
      ]
    },
    flowRateProcess2Minimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(value)) {
              return model.validateMinimum('flowRateProcess2Maximum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel) {
            return model.getBelowMaximumMessage(get(model, 'flowRateProcess2Maximum'), model.flowUnit);
          },
        },
      ]
    },
    flowRateProcess2Maximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(value)) {
              return model.validateMaximum('flowRateProcess2Minimum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel) {
            return model.getAboveMinimumMessage(get(model, 'flowRateProcess2Minimum'), model.flowUnit);
          },
        },
      ]
    },
    flowRateProcess3Minimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(value)) {
              return model.validateMinimum('flowRateProcess3Maximum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel) {
            return model.getBelowMaximumMessage(get(model, 'flowRateProcess3Maximum'), model.flowUnit);
          },
        },
      ]
    },
    flowRateProcess3Maximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
      custom: [
        {
          validation(_key: string, value: any, model: WPSModel) {
            if (!isEmpty(value)) {
              return model.validateMaximum('flowRateProcess3Minimum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: WPSModel) {
            return model.getAboveMinimumMessage(get(model, 'flowRateProcess3Minimum'), model.flowUnit);
          },
        },
      ]
    }
  };

  validateMinimum(propertyMaximumValue: any, value: string) {
    let maximum = parseFloat(get(this, propertyMaximumValue));
    if (!isEmpty(maximum) && !isNaN(maximum)) {
      let minimum = parseFloat(value);
      return minimum <= maximum;
    }
    return true;
  }

  validateMaximum(propertyMinimumValue: any, value: string) {
    let minimum = parseFloat(get(this, propertyMinimumValue));
    if (!isEmpty(minimum) && !isNaN(minimum)) {
      let maximum = parseFloat(value);
      return maximum >= minimum;
    }
    return true;
  }

  getBelowMaximumMessage(approvalRange: string | number | undefined, unit = '') {
    return this.translate('model-validations.wps-parameter.must-be-below-maximum', {
      approvalRange,
      unit,
    });
  }

  getAboveMinimumMessage(approvalRange: string | number | undefined, unit: string) {
    return this.translate('model-validations.wps-parameter.must-be-above-minimum', {
      approvalRange,
      unit,
    });
  }

  // Whether the WPS is locked to the PQR values (currently is always locked, in the future will be a property)
  isLocked = false;

  // Whether a value from the WPS should be validated againts the PQR
  shouldValidateRelationValue(value: BaseModel) {
    return (
      !isEmpty(value) &&
      !isEmpty(get(value, 'id')) &&
      (this.isLinkedToPQR || this.isPrequalified) &&
      !isEmpty(this.validator)
    );
  }

  shouldValidateCollectionValue(value: []) {
    return (
      !isEmpty(value) &&
      get(value, 'length') > 0 &&
      (this.isLinkedToPQR || this.isPrequalified) &&
      !isEmpty(this.validator)
    );
  }

  shouldValidateSingleValue(value: any) {
    return (
      !isEmpty(value) && (this.isLinkedToPQR || this.isPrequalified) && !isEmpty(this.validator)
    );
  }

  @computed(`${WPS.MAIN_PQR}.id`, `${WPS.PQR_FILL}.id`, `${WPS.PQR_CAP}.id`)
  get isLinkedToPQR() {
    return !isEmpty(this.mainPqrId) || !isEmpty(this.pqrFillId) || !isEmpty(this.pqrCapId);
  }

  getPQR() {
    // Assumes the PQR was previouly loaded, otherwise need to resolve the promise
    return this.store.peekRecord('pqr', this.mainPqrId);
  }

  fetchAllRelations() {
    return hash({
      pqrs: get(this, 'secondaryPqrs'),
      positionsRoot: get(this, 'weldingPositionsRoot'),
      positionsFill: get(this, 'weldingPositionsFill'),
      positionsCap: get(this, 'weldingPositionsCap'),
      grooveDesigns: get(this, 'grooveDesigns'),
      weldLayers: get(this, 'weldLayerConfigurations'),
      weldingDetails: get(this, 'weldingDetails'),
      parameters: get(this, 'weldingParameters'),
      preparationMethods: get(this, 'preparationMethods'),
      specialRequirements: get(this, 'specialRequirements'),
    });
  }

  get isActiveRevision() {
    return this.revisionStatus === 'ACT';
  }

  getIdOfRelation(relation: BaseModel): string {
    if (relation) {
      return get(relation, 'id');
    }
    return '';
  }

  getBelongsToId(relationName: keyof WPSModel): string {
    if (relationName) {
      let belongsTo = get(this, relationName);
      if (belongsTo && belongsTo instanceof BaseModel) {
        return get(belongsTo, 'id');
      }
    }
    return '';
  }

  get baseMaterial1Id() {
    return this.getIdOfRelation(this.baseMaterial1);
  }

  get baseMaterial2Id() {
    return this.getIdOfRelation(this.baseMaterial2);
  }

  get mainPqrId() {
    return this.getIdOfRelation(this.mainPqr);
  }

  get pqrFillId() {
    return this.getIdOfRelation(this.pqrFill);
  }

  get pqrCapId() {
    return this.getIdOfRelation(this.pqrCap);
  }

  @computed('pqrFill', 'pqrCap')
  get isAdvancedWPS() {
    let hasFill = !isEmpty(this.pqrFillId);
    let hasCap = !isEmpty(this.pqrCapId);
    return hasFill || hasCap;
  }

  @computed('mainPqr', 'multiplePositionPqrRoot')
  get isPcPlusPfPosition(): boolean {
    return !isEmpty(this.mainPqrId) && !isEmpty(this.getBelongsToId('multiplePositionPqrRoot'));
  }

  isFilletWeld() {
    return isFilletWeld(get(this, 'weldType'));
  }

  isPartialPenetrationButtWeld() {
    return isPartialJointPenetrationButtWeld(this.weldType);
  }

  validateDimensions() {
    let isFilletWeld = this.isFilletWeld();
    let isDifferentMaterials = false;

    let hasBothMaterials = !isEmpty(this.baseMaterial1Id) && !isEmpty(this.baseMaterial2Id);
    if (this.baseMaterial1Id !== this.baseMaterial2Id && hasBothMaterials) {
      isDifferentMaterials = true;
    }
    let hasBothDiameter =
      !isEmpty(this.baseMaterial1Diameter) && !isEmpty(this.baseMaterial2Diameter);
    if (this.baseMaterial1Diameter !== this.baseMaterial2Diameter && hasBothDiameter) {
      isDifferentMaterials = true;
    }

    let hasBothThickness =
      !isEmpty(this.baseMaterial1ThicknessMinimum) && !isEmpty(this.baseMaterial2ThicknessMinimum);
    if (
      this.baseMaterial1ThicknessMinimum !== this.baseMaterial2ThicknessMinimum &&
      hasBothThickness
    ) {
      isDifferentMaterials = true;
    }

    if (isFilletWeld && isDifferentMaterials) {
      return false;
    }

    return true;
  }

  hasImpactRequirements() {
    return !isEmpty(get(this, 'impactTestTemperature'));
  }

  isBothSides() {
    return isBothSides(this.weldingDetails);
  }

  get isPipe() {
    return this.productType === PRODUCT_TYPE.PIPE;
  }

  get isPlate() {
    return this.productType === PRODUCT_TYPE.PLATE;
  }

  get isMultiPQR(): boolean {
    return this.secondaryPqrs.length > 0;
  }

  get flowUnit(): string {
    return this.userSession.flowUnit;
  }

  get isOverlayWeld() {
    let weldType = get(this, 'weldType');
    if (weldType) {
      let weldTypeType = get(weldType, 'weldType');
      return weldTypeType === WELD_TYPES.OVERLAY_WELD;
    }
    return false;
  }

  get isPrimary(): boolean {
    return this.wpsType === WPS_TYPE.PRIMARY;
  }

  get isProject() {
    return this.wpsType === WPS_TYPE.PROJECT;
  }

  get isPreliminary(): boolean {
    return this.wpsType === WPS_TYPE.PRELIMINARY;
  }

  get isPrequalified(): boolean {
    return this.wpsType === WPS_TYPE.PREQUALIFIED;
  }

  get isArchived(): boolean {
    return this.archivalState === ARCHIVAL_STATE.ARCHIVED;
  }

  get isActive(): boolean {
    return this.archivalState === ARCHIVAL_STATE.ACTIVE;
  }

  // The multiple position WPS is a PC+PF WPS supported by two PQRs, the PC PQR is stored in the MainPqr field
  // and the PF PQR is stored in the multiplePositionPqrRoot field
  @computed('multiplePositionPqrRoot.id', 'mainPqr.id')
  get isMultiplePositionWps() {
    return !isEmpty(this.getBelongsToId('multiplePositionPqrRoot')) && !isEmpty(this.mainPqrId);
  }

  @computed('verifiedBy')
  get isVerified(): boolean {
    let verifiedBy = get(this, 'verifiedBy');
    if (verifiedBy && !isEmpty(get(verifiedBy, 'id'))) {
      return true;
    }
    return false;
  }

  @computed('approvedBy')
  get isApproved() {
    let approvedBy = get(this, 'approvedBy');
    if (approvedBy && !isEmpty(get(approvedBy, 'id'))) {
      return true;
    }
    return false;
  }

  metadata = {
    modelName: 'welding-procedure-specification',
    wpsNumber: { required: true },
    standard: { required: true },
    revision: { required: true },
    weldingProcessRoot: { layer: 'process1' },
    weldingProcessFill: { layer: 'process2' },
    weldingProcessCap: { layer: 'process3' },
    weldingPositionsRoot: { layer: 'process1' },
    weldingPositionsFill: { layer: 'process2' },
    weldingPositionsCap: { layer: 'process3' },
    fillerMaterialRoot: { layer: 'process1' },
    baseMaterial1ThicknessMinimum: { unit: 'distance' },
    baseMaterial1ThicknessMaximum: { unit: 'distance' },
    baseMaterial2ThicknessMinimum: { unit: 'distance' },
    baseMaterial2ThicknessMaximum: { unit: 'distance' },
    thicknessRootMinimum: { unit: 'distance' },
    thicknessRootMaximum: { unit: 'distance' },
    thicknessFillMinimum: { unit: 'distance' },
    thicknessFillMaximum: { unit: 'distance' },
    thicknessCapMinimum: { unit: 'distance' },
    thicknessCapMaximum: { unit: 'distance' },
    thicknessMinimum: { unit: 'distance' },
    thicknessMaximum: { unit: 'distance' },
    diameterMinimum: { unit: 'distance' },
    diameterMaximum: { unit: 'distance' },
    rootFaceMinimum: { unit: 'distance' },
    rootFaceMaximum: { unit: 'distance' },
    rootGapMinimum: { unit: 'distance' },
    preheatTemperatureMinimum: { unit: 'temperature' },
    preheatTemperatureMaximum: { unit: 'temperature' },
    postHeatTemperatureMinimum: { unit: 'temperature' },
    postHeatTemperatureMaximum: { unit: 'temperature' },
    postWeldHeatTreatmentTemperatureMinimum: { unit: 'temperature' },
    postWeldHeatTreatmentTemperatureMaximum: { unit: 'temperature' },
    interpassTemperatureMinimum: { unit: 'temperature' },
    interpassTemperatureMaximum: { unit: 'temperature' },
    fillerMaterialRootCommercialDesignation: { layer: 'process1' },
    fillerMaterialFill: { layer: 'process2' },
    fillerMaterialFillCommercialDesignation: { layer: 'process2' },
    fillerMaterialCap: { layer: 'process3' },
    fillerMaterialCapCommercialDesignation: { layer: 'process3' },
    shieldingRoot: { layer: 'process1' },
    shieldingRootGas: { layer: 'process1' },
    shieldingRootGasCommercialDesignation: { layer: 'process1' },
    shieldingRootGasMixture: { layer: 'process1' },
    shieldingRootFlux: { layer: 'process1' },
    shieldingRootFluxCommercialDesignation: { layer: 'process1' },
    flowRateRoot: { layer: 'process1' },
    backingFlowRateRoot: { layer: 'process1' },
    shieldingFill: { layer: 'process2' },
    shieldingFillGas: { layer: 'process2' },
    shieldingFillGasCommercialDesignation: { layer: 'process2' },
    shieldingFillGasMixture: { layer: 'process2' },
    shieldingFillFlux: { layer: 'process2' },
    shieldingFillFluxCommercialDesignation: { layer: 'process2' },
    flowRateFill: { layer: 'process2' },
    backingFlowRateFill: { layer: 'process2' },
    shieldingCap: { layer: 'process3' },
    shieldingCapGas: { layer: 'process3' },
    shieldingCapGasCommercialDesignation: { layer: 'process3' },
    shieldingCapGasMixture: { layer: 'process3' },
    shieldingCapFlux: { layer: 'process3' },
    shieldingCapFluxCommercialDesignation: { layer: 'process3' },
    flowRateCap: { layer: 'process3' },
    backingFlowRateCap: { layer: 'process3' },
    electrodeType: { layer: 'process1' },
    electrodeTypeFill: { layer: 'process2' },
    electrodeTypeCap: { layer: 'process3' },
    numberOfElectrodes: { layer: 'process1' },
    numberOfElectrodesFill: { layer: 'process2' },
    numberOfElectrodesCap: { layer: 'process3' },
    oscillation: { layer: 'process1' },
    weaving: { layer: 'process1' },
    weavingFill: { layer: 'process2' },
    weavingCap: { layer: 'process3' },
    contactTubeDistance: { layer: 'process1' },
    contactTubeDistanceFill: { layer: 'process2' },
    contactTubeDistanceCap: { layer: 'process3' },
    gasNozzleDiameterRoot: { layer: 'process1' },
    gasNozzleDiameterFill: { layer: 'process2' },
    gasNozzleDiameterCap: { layer: 'process3' },
    processMecanizationRoot: { layer: 'process1' },
    processMecanizationFill: { layer: 'process2' },
    processMecanizationCap: { layer: 'process3' },
    arcTransferModeRoot: { layer: 'process1' },
    arcTransferModeFill: { layer: 'process2' },
    arcTransferModeCap: { layer: 'process1' },
    typeCurrentRoot: { layer: 'process1' },
    typeCurrentFill: { layer: 'process2' },
    typeCurrentCap: { layer: 'process3' },
    weldingPositionRootDirection: { layer: 'process1' },
    weldingPositionFillDirection: { layer: 'process2' },
    weldingPositionCapDirection: { layer: 'process3' },
    tungstenDiameterRoot: { layer: 'process1' },
    tungstenDiameterFill: { layer: 'process2' },
    tungstenDiameterCap: { layer: 'process3' },
    fillerMaterialElectrodeFormRoot: { layer: 'process1' },
    fillerMaterialElectrodeFormFill: { layer: 'process2' },
    fillerMaterialElectrodeFormCap: { layer: 'process3' },
    fillerMaterialGuideRoot: { layer: 'process1' },
    fillerMaterialGuideFill: { layer: 'process2' },
    fillerMaterialGuideCap: { layer: 'process3' },
    oscillationWidthRoot: { layer: 'process1' },
    oscillationWidthFill: { layer: 'process2' },
    oscillationWidthCap: { layer: 'process3' },
    oscillationFrequencyRoot: { layer: 'process1' },
    oscillationFrequencyFill: { layer: 'process2' },
    oscillationFrequencyCap: { layer: 'process3' },
    oscillationDwellTimeRoot: { layer: 'process1' },
    oscillationDwellTimeFill: { layer: 'process2' },
    oscillationDwellTimeCap: { layer: 'process3' },
    supplementalDeviceRoot: { layer: 'process1' },
    supplementalDeviceFill: { layer: 'process2' },
    supplementalDeviceCap: { layer: 'process3' },
    fillerMaterialRootDiameters: { layer: 'process1', unit: 'distance' },
    fillerMaterialFillDiameters: { layer: 'process2', unit: 'distance' },
    fillerMaterialCapDiameters: { layer: 'process3', unit: 'distance' },
    flowRateProcess1Minimum: { layer: 'process1', unit: 'flow' },
    flowRateProcess1Maximum: { layer: 'process1', unit: 'flow' },
    flowRateProcess2Minimum: { layer: 'process2', unit: 'flow' },
    flowRateProcess2Maximum: { layer: 'process2', unit: 'flow' },
    flowRateProcess3Minimum: { layer: 'process3', unit: 'flow' },
    flowRateProcess3Maximum: { layer: 'process3', unit: 'flow' },
  };

  fetchRevisions = memberAction({ path: 'fetchRevisions', type: 'GET' });

  fetchProjectWps = memberAction({ path: 'fetchProjectWps', type: 'GET' });

  fetchProjectsOfWps = memberAction({ path: 'fetchProjectsOfWps', type: 'GET' });

  fetchWeldsOfWps = memberAction({ path: 'fetchWeldsOfWps', type: 'GET' });

  updateRevisionId = memberAction({ path: 'updateRevisionId', type: 'PUT' });

  validationRange = memberAction({ path: 'validationRange', type: 'GET' });

  generateExportPdf = memberAction({ path: 'exportPDF', type: 'POST' });

  createRevision = memberAction({ path: 'createRevision', type: 'PUT' });

  createForProject = memberAction({ path: 'createForProject', type: 'PUT' });

  addWpsToProject = memberAction({ path: 'addSpecificationToProject', type: 'PUT' });

  archive = memberAction({ path: 'archiveWPS', type: 'PUT' });

  unarchive = memberAction({ path: 'unarchiveWPS', type: 'PUT' });

  createPQR = memberAction({ path: 'createPQR', type: 'PUT' });

  createCertificate = memberAction({ path: 'createCertificate', type: 'PUT' });

  createPrequalifiedWPS = memberAction({ path: 'createPrequalifiedWPS', type: 'PUT' });

  verifyWps = memberAction({ path: 'verify', type: 'PUT' });

  approveWps = memberAction({ path: 'approve', type: 'PUT' });

  revokeWps = memberAction({ path: 'revoke', type: 'PUT' });

  duplicateWps = memberAction({ path: 'duplicate', type: 'PUT' });

  deleteRevision = memberAction({ path: 'deleteRevision', type: 'PUT' });
}

export default WPSModel;
