export default {
  WELDING_BOOK: {
    DESIGNATION: 'designation',
    REVISION: 'revision',
    REVISION_STATUS: 'revisionStatus',
    CUSTOMER: 'customer',
    SUPPLIER: 'supplier',
    STANDARD: 'standard',
    WELDER_STANDARD: 'welderStandard',
    REVISION_DATE: 'revisionDate',
    WRITTEN_BY: 'writtenBy',
    VERIFIED_BY: 'verifiedBy',
    APPROVED_BY: 'approvedBy',
    WRITTEN_BY_DATE: 'writtenByDate',
    VERIFIED_BY_DATE: 'verifiedByDate',
    APPROVED_BY_DATE: 'approvedByDate',
    STATUS: 'status',
    NOTES: 'notes',
    INCLUDE_PQRS: 'includePqrs',
    WPS: 'wps',
    PQRS: 'pqrs',
    WELDERS: 'welders',
    BOOK_DRAWINGS: 'bookDrawings',
    COMPONENT_DRAWINGS: 'componentDrawings',
    WELDS: 'welds',
    WELDING_PROJECT: 'weldingProject',
    COMPONENT: 'component',
    TYPE_OF_WPS_INCLUSION: 'typeOfWpsInclusion',
  },
};
