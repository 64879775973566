import ListRoute from 'weldnote/routes/_base/list';
import { service } from '@ember/service';

export default class TestStageNamesRoute extends ListRoute {
  @service('weldnote-data')
  data;

  model() {
    return this.data.listAllTestStageNames();
  }
}
