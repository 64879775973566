import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { get } from '@ember/object';
import ListRoute from 'weldnote/routes/_base/list';
import Constants from 'weldnote/utils/constants';

const { ARCHIVAL_STATE } = Constants;

@classic
export default class WeldingProcedureSpecificationsArchivedRoute extends ListRoute {
  @service('weldnote-data')
  data;

  model() {
    return this.data.listAllWeldingProcedureSpecifications(
      {},
      {
        archivalState: ARCHIVAL_STATE.ARCHIVED,
      }
    );
  }
}
