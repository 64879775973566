import { computed, get } from '@ember/object';
import { hasMany, belongsTo, attr, AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';
import BaseModel from 'weldnote/models/base-model';
import { collectionAction } from 'ember-api-actions';
import Company from './company';
import PipingPiece from './piping-piece';
import PlatePiece from './plate-piece';
import PqrStandard from './pqr-standard';
import ExaminingBody from './examining-body';
import WeldType from './weld-type';
import WeldingProcess from './welding-process';
import WeldingDetail from './welding-detail';
import BaseMaterialGroup from './base-material-group';
import FillerMaterial from './filler-material';
import FillerMaterialGroup from './filler-material-group';
import WelderStandard from './welder-standard';
import WeldingPosition from './welding-position';
import Welder from './welder';
import CertificationSpecialRequirement from './certification-special-requirement';

const STRING_CAMELIZE_REGEXP_1 = /(\-|\_|\.|\s)+(.)?/g;
const STRING_CAMELIZE_REGEXP_2 = /(^|\/)([A-Z])/g;

export default class WeldingMap extends BaseModel {
  @attr('string')
  declare designation?: string;

  @belongsTo('company')
  declare company: AsyncBelongsTo<Company>;

  @attr('date')
  declare mapDate?: Date;

  @hasMany('piping-piece')
  declare pipingPieces: AsyncHasMany<PipingPiece>;

  @hasMany('plate-piece')
  declare platePieces: AsyncHasMany<PlatePiece>;

  @belongsTo('pqr-standard')
  declare pqrStandard: AsyncBelongsTo<PqrStandard>;

  @belongsTo('examining-body')
  declare pqrExaminingBody: AsyncBelongsTo<ExaminingBody>;

  @belongsTo('weld-type')
  declare pqrWeldType: AsyncBelongsTo<WeldType>;

  @belongsTo('welding-process')
  declare pqrWeldingProcessRoot: AsyncBelongsTo<WeldingProcess>;

  @belongsTo('welding-process')
  declare pqrWeldingProcessFill: AsyncBelongsTo<WeldingProcess>;

  @hasMany('welding-detail')
  declare pqrWeldingDetails: AsyncBelongsTo<WeldingDetail>;

  @belongsTo('base-material-group')
  declare pqrBaseMaterial1Group: AsyncBelongsTo<BaseMaterialGroup>;

  @belongsTo('base-material-group')
  declare pqrBaseMaterial2Group: AsyncBelongsTo<BaseMaterialGroup>;

  @belongsTo('filler-material')
  declare pqrFillerMaterialRoot: AsyncBelongsTo<FillerMaterial>;

  @belongsTo('filler-material')
  declare pqrFillerMaterialFill: AsyncBelongsTo<FillerMaterial>;

  @belongsTo('filler-material-group')
  declare pqrFillerMaterialGroupRoot: AsyncBelongsTo<FillerMaterialGroup>;

  @belongsTo('filler-material-group')
  declare pqrFillerMaterialGroupFill: AsyncBelongsTo<FillerMaterialGroup>;

  @attr('decimal-value')
  declare pqrHeatInput?: number;

  @attr('number')
  declare pqrPreheatTemperature?: number;

  @attr('number')
  declare pqrPostWeldHeatTreatmentTemperature?: number;

  @attr('decimal-value')
  declare pqrImpactTestTemperature?: number;

  @attr('boolean')
  declare pqrHardnessTestRequired?: boolean;

  @belongsTo('welder-standard')
  declare certificateStandard: AsyncBelongsTo<WelderStandard>;

  @belongsTo('examining-body')
  declare certificateExaminingBody: AsyncBelongsTo<ExaminingBody>;

  @belongsTo('weld-type')
  declare certificateWeldType: AsyncBelongsTo<WeldType>;

  @belongsTo('welding-process')
  declare certificateWeldingProcessRoot: AsyncBelongsTo<WeldingProcess>;

  @belongsTo('welding-process')
  declare certificateWeldingProcessFill: AsyncBelongsTo<WeldingProcess>;

  @hasMany('welding-detail')
  declare certificateWeldingDetails: AsyncBelongsTo<WeldingDetail>;

  @belongsTo('base-material-group')
  declare certificateBaseMaterial1Group: AsyncBelongsTo<BaseMaterialGroup>;

  @belongsTo('base-material-group')
  declare certificateBaseMaterial2Group: AsyncBelongsTo<BaseMaterialGroup>;

  @belongsTo('filler-material-group')
  declare certificateFillerMaterialGroupRoot: AsyncBelongsTo<FillerMaterialGroup>;

  @belongsTo('filler-material-group')
  declare certificateFillerMaterialGroupFill: AsyncBelongsTo<FillerMaterialGroup>;

  @belongsTo('filler-material-group')
  declare certificateFillerMaterialGroupCap: AsyncBelongsTo<FillerMaterialGroup>;

  @belongsTo('welding-position')
  declare certificateWeldingPositionRoot: AsyncBelongsTo<WeldingPosition>;

  @belongsTo('welding-position')
  declare certificateWeldingPositionFill: AsyncBelongsTo<WeldingPosition>;

  @hasMany('welder')
  declare certificateWelders: AsyncHasMany<Welder>;

  @belongsTo('pqr-standard')
  declare wpsStandard: AsyncBelongsTo<PqrStandard>;

  @belongsTo('weld-type')
  declare wpsWeldType: AsyncBelongsTo<WeldType>;

  @belongsTo('welding-process')
  declare wpsWeldingProcessRoot: AsyncBelongsTo<WeldingProcess>;

  @belongsTo('welding-process')
  declare wpsWeldingProcessFill: AsyncBelongsTo<WeldingProcess>;

  @hasMany('welding-detail')
  declare wpsWeldingDetails: AsyncBelongsTo<WeldingDetail>;

  @belongsTo('base-material-group')
  declare wpsBaseMaterial1Group: AsyncBelongsTo<BaseMaterialGroup>;

  @belongsTo('base-material-group')
  declare wpsBaseMaterial2Group: AsyncBelongsTo<BaseMaterialGroup>;

  @belongsTo('filler-material')
  declare wpsFillerMaterialRoot: AsyncBelongsTo<FillerMaterial>;

  @belongsTo('filler-material')
  declare wpsFillerMaterialFill: AsyncBelongsTo<FillerMaterial>;

  @belongsTo('filler-material-group')
  declare wpsFillerMaterialGroupRoot: AsyncBelongsTo<FillerMaterialGroup>;

  @belongsTo('filler-material-group')
  declare wpsFillerMaterialGroupFill: AsyncBelongsTo<FillerMaterialGroup>;

  @attr('decimal-value')
  declare wpsHeatInput?: number;

  @attr('number')
  declare wpsPreheatTemperature?: number;

  @attr('number')
  declare wpsPostWeldHeatTreatmentTemperature?: number;

  @hasMany('welding-procedure-specification')
  declare wpsWeldingProcedureSpecifications: AsyncHasMany<any>;

  @belongsTo('welding-process')
  declare pqrWeldingProcessCap: AsyncBelongsTo<WeldingProcess>;

  @belongsTo('filler-material')
  declare pqrFillerMaterialCap: AsyncBelongsTo<FillerMaterial>;

  @belongsTo('welding-process')
  declare certificateWeldingProcessCap: AsyncBelongsTo<WeldingProcess>;

  @belongsTo('welding-position')
  declare certificateWeldingPositionCap: AsyncBelongsTo<WeldingPosition>;

  @belongsTo('welding-process')
  declare wpsWeldingProcessCap: AsyncBelongsTo<WeldingProcess>;

  @belongsTo('filler-material')
  declare wpsFillerMaterialCap: AsyncBelongsTo<FillerMaterial>;

  @attr('boolean')
  declare pqrSearchFillerMaterialRootGroup?: boolean;

  @attr('boolean')
  declare pqrSearchFillerMaterialFillGroup?: boolean;

  @attr('boolean')
  declare pqrSearchFillerMaterialCapGroup?: boolean;

  @attr('boolean')
  declare wpsSearchFillerMaterialRootGroup?: boolean;

  @attr('boolean')
  declare wpsSearchFillerMaterialFillGroup?: boolean;

  @attr('boolean')
  declare wpsSearchFillerMaterialCapGroup?: boolean;

  @attr('number')
  declare pqrThroatThickness?: number;

  @attr('number')
  declare wpsThroatThickness?: number;

  @hasMany('certification-special-requirement')
  declare pqrSpecialRequirements: AsyncHasMany<CertificationSpecialRequirement>;

  @hasMany('certification-special-requirement')
  declare wpsSpecialRequirements: AsyncHasMany<CertificationSpecialRequirement>;

  @hasMany('certification-special-requirement')
  declare certificateSpecialRequirements: AsyncHasMany<CertificationSpecialRequirement>;

  @attr('string')
  declare pqrOverlayType?: string;

  @attr('string')
  declare certificateOverlayType?: string;

  @attr('string')
  declare wpsOverlayType?: string;

  @attr('boolean')
  declare certificateSearchExpired?: boolean;

  @belongsTo('welding-position')
  declare pqrWeldingPositionRoot: AsyncBelongsTo<WeldingPosition>;

  @belongsTo('welding-position')
  declare pqrWeldingPositionFill: AsyncBelongsTo<WeldingPosition>;

  @belongsTo('welding-position')
  declare pqrWeldingPositionCap: AsyncBelongsTo<WeldingPosition>;

  @attr('string')
  declare pqrPostWeldHeatTreatmentPresence?: string;

  @attr('string')
  declare wpsPostWeldHeatTreatmentPresence?: string;

  @attr('boolean')
  declare pqrSearchByWeldedThickness?: boolean;

  @attr('boolean')
  declare wpsSearchByWeldedThickness?: boolean;

  get visualLabel() {
    return this.designation;
  }

  validations = {
    designation: {
      presence: {
        message(_key: string, _value: any, model: any) {
          return model.translate('welding-map-new.errors.designation.blank');
        },
      },
    },

    pqrHeatInput: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99999.99,
      },
    },

    pqrPreheatTemperature: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
        onlyInteger: true,
      },
    },

    pqrPostWeldHeatTreatmentTemperature: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
        onlyInteger: true,
      },
    },

    pqrImpactTestTemperature: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: -273.0,
        lessThanOrEqualTo: 9999.9,
      },
    },

    wpsHeatInput: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99999.99,
      },
    },

    wpsPreheatTemperature: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
        onlyInteger: true,
      },
    },

    wpsPostWeldHeatTreatmentTemperature: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
        onlyInteger: true,
      },
    },

    pqrThroatThickness: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    wpsThroatThickness: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },
  };

  retrieveAllRelations() {
    return RSVP.hash({
      pieces: this.pipingPieces,
      plates: this.platePieces,
      wps: this.wpsWeldingProcedureSpecifications,
      welders: this.certificateWelders,
      pqrDetails: this.pqrWeldingDetails,
      certificateDetails: this.certificateWeldingDetails,
      wpsDetails: this.wpsWeldingDetails,
    });
  }

  _convertToJSON(relations: any) {
    let weldingMapJson: any = this.serialize();
    let json: any = weldingMapJson?.data.attributes;
    if (!isEmpty(relations.pieces)) {
      let piecesArray = relations.pieces.map((piece: PipingPiece) => {
        let result: any = piece.serialize();
        return result?.data?.attributes;
      });
      json.pipingPieces = piecesArray;
    }
    if (!isEmpty(relations.plates)) {
      let platesArray = relations.plates.map((plate: PlatePiece) => {
        let result: any = plate.serialize();
        return result?.data?.attributes;
      });
      json.platePieces = platesArray;
    }
    return json;
  }

  camelize(key: string) {
    return key
    .replace(STRING_CAMELIZE_REGEXP_1, (_match, _separator, chr) =>
      chr ? chr.toUpperCase() : ''
    )
    .replace(STRING_CAMELIZE_REGEXP_2, (match /*, separator, chr */) =>
      match.toLowerCase()
    )
  }


  convertToJSON(): any {
    const json: any = {};
    WeldingMap.eachAttribute((key: keyof this) => {
      const camelCaseKey = this.camelize(key.toString());
      json[camelCaseKey] = get(this, key);
    });
    
    // This complicated pieces basically does the following.
    WeldingMap.eachRelationship((key: keyof this, meta) => {
      //const camelCaseKey = this.camelize(key.toString());
      let keyName: string = key.toString();
      if (keyName === 'pipingPieces') {
        let resultPiece: any = this.get('pipingPieces');
        let pipingPieces: any[] = [];
        if (resultPiece && resultPiece.length > 0) {
          resultPiece.forEach((currentPiece: PipingPiece) => {
            let pieceJson: any = {};
            PipingPiece.eachAttribute((keyPiece: keyof PipingPiece) => {
              // const pipeKeyCamel: string = this.camelize(keyPiece.toString());
              pieceJson[keyPiece.toString()] = get(currentPiece, keyPiece);
            })
            pipingPieces.push(pieceJson);
          });
        }
        json['pipingPieces'] = pipingPieces;
      } else if (keyName === 'platePieces') {
        let resultPlate: any = this.get('platePieces');
        if (resultPlate && resultPlate.length > 0) {
          let resultPlates: any = this.get('platePieces');
          let platePieces: any[] = [];
          if (resultPlates && resultPlates.length > 0) {
            resultPlates.forEach((currentPiece: PlatePiece) => {
              let plateJson: any = {};
              PlatePiece.eachAttribute((keyPiece: keyof PlatePiece) => {
                //const plateKeyCamel: string = this.camelize(keyPiece.toString());
                plateJson[keyPiece.toString()] = get(currentPiece, keyPiece);
              })
              platePieces.push(plateJson);
            });
          }
          json['platePieces'] = platePieces;
        } 
      } else if (meta.kind === 'belongsTo') {
          let value: any = this.get(key);
          if (value && get(value, 'id')) {
            json[key] = get(value, 'id');
          }
        } else if (meta.kind === 'hasMany') {
          let result: any = this.get(key);
          if (result && result.length) {
            json[key] = result.mapBy('id');
          } else {
            json[key] = [];
          }
        }
      
    });
  console.log(json)
  return json;
}

  @computed('pipingPieces.[]', 'platePieces.[]')
  get noPieces() {
    return this.get('pipingPieces').length === 0 && this.get('platePieces').length === 0;
  }

  metadata = {
    modelName: 'welding-map',
    designation: { required: true },
  };

  generatePQRMap = collectionAction({ path: 'generatePQRMap' });

  generateWPSMap = collectionAction({ path: 'generateWPSMap' });

  generateCertificateMap = collectionAction({ path: 'generateCertificateMap' });

  generateWelderMap = collectionAction({ path: 'generateWelderMap' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'welding-map': WeldingMap;
  }
}
