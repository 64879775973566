import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label title=\"{{@label}}\" class=\"control-label\" for=\"{{@componentId}}\" data-test-id={{@data-id}}>\n  <span data-test-label>{{@label}}{{#if @isRequired}} *{{/if}}</span>\n  {{#if this.hasTooltip}}\n    <span data-test-tooltip>(?)<WTooltip @text={{@tooltip}} /></span>\n  {{/if}}\n</label>", {"contents":"<label title=\"{{@label}}\" class=\"control-label\" for=\"{{@componentId}}\" data-test-id={{@data-id}}>\n  <span data-test-label>{{@label}}{{#if @isRequired}} *{{/if}}</span>\n  {{#if this.hasTooltip}}\n    <span data-test-tooltip>(?)<WTooltip @text={{@tooltip}} /></span>\n  {{/if}}\n</label>","moduleName":"weldnote/components/ui-form/label.hbs","parseOptions":{"srcName":"weldnote/components/ui-form/label.hbs"}});
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';

export default class Label extends Component {
  get hasTooltip() {
    return !isEmpty(this.args.tooltip);
  }
}
