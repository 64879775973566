import { service } from '@ember/service';
import Model from '@ember-data/model';
import IntlService from 'ember-intl/services/intl';
import { modelValidator } from 'ember-model-validator';

@modelValidator
export default class BaseModel extends Model {
  @service
  declare intl: IntlService;

  translate(what: string, options = {}) {
    return this.intl.t(what, options);
  }

  declare validate: (options?: any) => boolean;

  metadata: any;
}
