import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"{{this.type}}\">\n  <div class=\"{{this.cssClasses}}\">\n    {{svg-jar \"weld-cloud-loader\" class=\"weld-cloud-loader\"}}\n    {{yield}}\n  </div>\n</div>", {"contents":"<div class=\"{{this.type}}\">\n  <div class=\"{{this.cssClasses}}\">\n    {{svg-jar \"weld-cloud-loader\" class=\"weld-cloud-loader\"}}\n    {{yield}}\n  </div>\n</div>","moduleName":"weldnote/components/loading-spinner.hbs","parseOptions":{"srcName":"weldnote/components/loading-spinner.hbs"}});
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';

export default class LoadingSpinner extends Component {
  get type() {
    if (isEmpty(this.args.style)) {
      return 'weldnote-loading';
    }
    return this.args.style;
  }

  get cssClasses() {
    if (isEmpty(this.args.cssClasses)) {
      return 'w-24 mx-auto my-32';
    }
    return this.args.cssClasses;
  }
}
