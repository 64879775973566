import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span data-id={{@data-id}} data-test-id={{@data-id}}>\n  {{#if @isEditing}}\n    <Pikaday::PikadayInput\n      @value={{readonly @value}}\n      @disabled={{@disabled}}\n      class=\"form-control pikaday-input\"\n      @placeholder={{@placeholder}}\n      @useUTC={{true}}\n      @format={{this.dateFormat}}\n      @yearRange={{@yearRange}}\n      @maxDate={{@maxDate}}\n      @onSelection={{@onUpdate}}\n      @data-test-pikaday={{true}}\n      @options={{hash toString=this.formatDate}}\n    />\n  {{else}}\n    <p class=\"form-control-static\">{{this.displayValue}}</p>\n  {{/if}}\n</span>", {"contents":"<span data-id={{@data-id}} data-test-id={{@data-id}}>\n  {{#if @isEditing}}\n    <Pikaday::PikadayInput\n      @value={{readonly @value}}\n      @disabled={{@disabled}}\n      class=\"form-control pikaday-input\"\n      @placeholder={{@placeholder}}\n      @useUTC={{true}}\n      @format={{this.dateFormat}}\n      @yearRange={{@yearRange}}\n      @maxDate={{@maxDate}}\n      @onSelection={{@onUpdate}}\n      @data-test-pikaday={{true}}\n      @options={{hash toString=this.formatDate}}\n    />\n  {{else}}\n    <p class=\"form-control-static\">{{this.displayValue}}</p>\n  {{/if}}\n</span>","moduleName":"weldnote/components/ui-form/input/date.hbs","parseOptions":{"srcName":"weldnote/components/ui-form/input/date.hbs"}});
import { action, computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import moment from 'moment';

export default class DateClass extends Component {
  @service
  userSession;

  get dateFormat() {
    return this.userSession.dateFormat;
  }

  yearRange = null;

  get displayValue() {
    let { value } = this.args;
    let { dateFormat } = this;
    if (!isEmpty(value)) {
      return moment(value).format(dateFormat);
    }
    return null;
  }

  @action
  formatDate(date, format) {
    // Show the date in the users timezone (as it was stored in UTC)
    let result = moment(date).format(format);
    return result;
  }
}
