import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"{{if (greater-than (get @errors.length) 0) 'has-error'}}\"\n  id={{concat @inputId \"_container\"}}\n  data-test-id={{@data-id}}\n>\n  <label class=\"control-label\" for=\"{{@inputId}}\" id={{concat @inputId \"_label\"}}>\n    {{@label}}\n    {{#if @required}}*{{/if}}\n    {{#if this.hasTooltip}}\n      <span>(?)<WTooltip @text={{@tooltipText}} /></span>\n    {{/if}}\n  </label>\n  <div class=\"text-3xl mt-1\" id={{concat @inputId \"_checkbox\"}}>\n    <input\n      type=\"checkbox\"\n      checked={{this.isChecked}}\n      {{on \"change\" (pick \"target.checked\" @onUpdateValue)}}\n      disabled={{@disabled}}\n      id={{@inputId}}\n    />\n  </div>\n  {{#each @errors as |error|}}\n    <span class=\"help-block\">{{error.message}}</span>\n  {{/each}}\n  {{#if this.hasHelp}}\n    <span class=\"help-block\">{{@helpText}}</span>\n  {{/if}}\n</div>", {"contents":"<div\n  class=\"{{if (greater-than (get @errors.length) 0) 'has-error'}}\"\n  id={{concat @inputId \"_container\"}}\n  data-test-id={{@data-id}}\n>\n  <label class=\"control-label\" for=\"{{@inputId}}\" id={{concat @inputId \"_label\"}}>\n    {{@label}}\n    {{#if @required}}*{{/if}}\n    {{#if this.hasTooltip}}\n      <span>(?)<WTooltip @text={{@tooltipText}} /></span>\n    {{/if}}\n  </label>\n  <div class=\"text-3xl mt-1\" id={{concat @inputId \"_checkbox\"}}>\n    <input\n      type=\"checkbox\"\n      checked={{this.isChecked}}\n      {{on \"change\" (pick \"target.checked\" @onUpdateValue)}}\n      disabled={{@disabled}}\n      id={{@inputId}}\n    />\n  </div>\n  {{#each @errors as |error|}}\n    <span class=\"help-block\">{{error.message}}</span>\n  {{/each}}\n  {{#if this.hasHelp}}\n    <span class=\"help-block\">{{@helpText}}</span>\n  {{/if}}\n</div>","moduleName":"weldnote/components/generic-boolean.hbs","parseOptions":{"srcName":"weldnote/components/generic-boolean.hbs"}});
import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import Component from '@ember/component';

@classic
export default class GenericBoolean extends Component {
  @notEmpty('tooltipText')
  hasTooltip;

  @notEmpty('helpText')
  hasHelp;

  @computed('value')
  get isChecked() {
    if (this.value === true) {
      return true;
    }
    return false;
  }
}
