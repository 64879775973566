export default {
  INSPECTOR: {
    INSPECTOR_NAME: 'inspectorName',
    INSPECTOR_STAMP: 'inspectorStamp',
    INSPECTOR_ID: 'inspectorId',
    INSPECTOR_TYPE: 'inspectorType',
    DATE_BIRTH: 'dateBirth',
    EXAMINING_BODY: 'examiningBody',
  },
};
