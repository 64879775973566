import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { set, action } from '@ember/object';

@classic
export default class WeldSketchDesignController extends Controller {
  finishedDrawing = true;

  showHelp = false;

  @action
  startDrawing() {
    set(this, 'finishedDrawing', false);
  }

  @action
  finishDrawing() {
    set(this, 'finishedDrawing', true);
  }

  @action
  doShowHelp() {
    this.toggleProperty('showHelp');
  }
}
