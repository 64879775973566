import { alias } from '@ember/object/computed';
import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class TypeCurrentPolarity extends BaseModel {
  @attr('string')
  declare typeCurrent: string;

  @attr('boolean')
  declare system: boolean;

  @alias('typeCurrent')
  get visualLabel(): string {
    return this.typeCurrent || '';
  }

  validations = {
    typeCurrent: {
      presence: {
        message(_key: string, _value: string, model: TypeCurrentPolarity): string {
          return model.intl.t('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'type-current-polarity',
    typeCurrent: {
      required: true,
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'type-current-polarity': TypeCurrentPolarity;
  }
}
