import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield}}", {"contents":"{{yield}}","moduleName":"weldnote/components/inspinia-config.hbs","parseOptions":{"srcName":"weldnote/components/inspinia-config.hbs"}});
import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Component from '@ember/component';
import $ from 'jquery';
import { debounce } from '@ember/runloop';

@classic
export default class InspiniaConfig extends Component {
  @service
  intl;

  @service('instanceTracker')
  tracker;

  smoothlyMenu() {
    let jquery = $;
    if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
      // Hide menu in order to smoothly turn on when maximize menu
      $('#side-menu').hide();
      // For smoothly turn on menu
      setTimeout(function () {
        jquery('#side-menu').fadeIn(300);
      }, 100);
      // Align collapse button to the width of the sidebar
      if($('#sidebar-collapse-button')) {
        $('#sidebar-collapse-button').css("left", "236px");
        $('#sidebar-collapse-button').removeClass("collapsed")
      }
    } else if ($('body').hasClass('fixed-sidebar')) {
      $('#side-menu').hide();
      setTimeout(function () {
        jquery('#side-menu').fadeIn(300);
      }, 300);
      // Left align collapse button
      if($('#sidebar-collapse-button')) {
        $('#sidebar-collapse-button').css("left", "5px");
        $('#sidebar-collapse-button').addClass("collapsed")
      }
    } else {
      // Remove all inline style from jquery fadeIn function to reset menu state
      $('#side-menu').removeAttr('style');
    }
  }

  applySizeClass() {
    if ($(document).width() < 769) {
      $('body').removeClass('body-small');
    } else {
      $('body').addClass('body-small');
    }
  }

  // Full height of sidebar
  fixHeight() {
    let heightWithoutNavbar = $('#wrapper').height() - 61;
    $('.sidebard-panel').css('min-height', `${heightWithoutNavbar}px`);

    let navbarHeigh = $('nav.navbar-default').height();
    let wrapperHeigh = $('#page-wrapper').height();

    if (navbarHeigh > wrapperHeigh) {
      $('#page-wrapper').css('min-height', `${navbarHeigh}px`);
    }

    let windowHeight = $(window).height();
    if (navbarHeigh < wrapperHeigh) {
      $('#page-wrapper').css('min-height', `${windowHeight}px`);
    }

    if ($('body').hasClass('fixed-nav')) {
      let adjustedHeight = windowHeight - 60;
      $('#page-wrapper').css('min-height', `${adjustedHeight}px`);
    }
  }

  checkIfFixHeightIsNeeded() {
    if (!$('body').hasClass('body-small')) {
      this.fixHeight();
    }
  }

  didInsertElement() {
    let self = this;
    self.fixHeight();

    $('.navbar-minimalize').click(function (e) {
      $('body').toggleClass('body-small');
      self.smoothlyMenu();
      e.preventDefault();
    });

    if ($('body').hasClass('fixed-sidebar')) {
      $('.sidebar-collapse').slimScroll({
        height: '100%',
        railOpacity: 0.9,
      });
    }

    $(window).bind('load', function () {
      debounce(self, 'applySizeClass', 1);
    });

    $(window).bind('load resize', function () {
      // Use debounce to prevent to many runs of this
      debounce(self, 'checkIfFixHeightIsNeeded', 500);
    });

    $(window).on('beforeunload', function () {
      if (self.get('tracker.hasUnsavedElements')) {
        let result = self.get('intl').t('components.inspinia-config.unsaved-work').toString();
        return result;
      }
    });
  }
}
