import { action } from '@ember/object';
import Controller from '@ember/controller';
import { service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import DS from 'ember-data';
import { tracked } from '@glimmer/tracking';

export default class NewController extends Controller {
  declare modelName: string;

  @tracked
  model: DS.Model | null = null;

  @service
  declare router: RouterService;

  @action
  instanceCreated() {
    if (this.model) {
      this.router.transitionTo(this.modelName, this.model);
    }
  }
}
