import WpsValidation from 'weldnote/utils/validation/wps-from-pqr';
import Rules from 'weldnote/utils/welding-procedure-specification-rules';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Constants from 'weldnote/utils/constants';

const { WELDING_PROCEDURE_SPECIFICATION } = Rules;

const { WELDING_POSITION_DIRECTION_OPTIONS } = Constants;

export default class AwsB21Validator extends WpsValidation {
  constructor(validations, wps, intl, units) {
    super(validations, wps, intl, units);
  }

  get isFillerMaterialRootValid() {
    let fillerMaterial = this._wpsFillerMaterialRoot;
    if (!isEmpty(fillerMaterial)) {
      if (this._hasImpactRequirements()) {
        let pqrMaterialId = get(this.validations, 'fillerMaterialRoot.value');
        let fillerMaterialId = get(fillerMaterial, 'id');
        if (!isEmpty(pqrMaterialId)) {
          return pqrMaterialId === fillerMaterialId;
        }
      } else {
        let pqrMaterialGroupId = get(this.validations, 'fillerMaterialRootGroup.value');
        if (!isEmpty(pqrMaterialGroupId)) {
          let groupId = get(fillerMaterial, 'group.id');
          return pqrMaterialGroupId === groupId;
        }
      }
    }
    return true;
  }

  get fillerMaterialRootInvalidMessage() {
    let fillerMaterial = this._wpsFillerMaterialRoot;
    if (!isEmpty(fillerMaterial)) {
      if (this._hasImpactRequirements()) {
        let { value: pqrMaterialId, label } = get(this.validations, 'fillerMaterialRoot');
        if (!isEmpty(pqrMaterialId) && pqrMaterialId !== get(fillerMaterial, 'id')) {
          return this.intl
            .t('model-validations.wps.aws-b2-1.filler-material.same-material', {
              fillerMaterial: label,
            })
            .toString();
        }
      } else {
        let { value: pqrMaterialGroupId, label } = get(this.validations, 'fillerMaterialRootGroup');
        if (
          !isEmpty(pqrMaterialGroupId) &&
          pqrMaterialGroupId !== get(fillerMaterial, 'group.id')
        ) {
          return this.intl
            .t('model-validations.wps.aws-b2-1.filler-material.same-group', {
              group: label,
            })
            .toString();
        }
      }
    }
    return '';
  }

  get isFillerMaterialFillValid() {
    let fillerMaterial = this._wpsFillerMaterialFill;
    if (!isEmpty(fillerMaterial)) {
      if (this._hasImpactRequirements()) {
        let pqrMaterialId = get(this.validations, 'fillerMaterialFill.value');
        let fillerMaterialId = get(fillerMaterial, 'id');
        if (!isEmpty(pqrMaterialId)) {
          return pqrMaterialId === fillerMaterialId;
        }
      } else {
        let pqrMaterialGroupId = get(this.validations, 'fillerMaterialFillGroup.value');
        if (!isEmpty(pqrMaterialGroupId)) {
          let groupId = get(fillerMaterial, 'group.id');
          return pqrMaterialGroupId === groupId;
        }
      }
    }
    return true;
  }

  get fillerMaterialFillInvalidMessage() {
    let fillerMaterial = this._wpsFillerMaterialFill;
    if (!isEmpty(fillerMaterial)) {
      if (this._hasImpactRequirements()) {
        let { value: pqrMaterialId, label } = get(this.validations, 'fillerMaterialFill');
        if (!isEmpty(pqrMaterialId) && pqrMaterialId !== get(fillerMaterial, 'id')) {
          return this.intl
            .t('model-validations.wps.aws-b2-1.filler-material.same-material', {
              fillerMaterial: label,
            })
            .toString();
        }
      } else {
        let { value: pqrMaterialGroupId, label } = get(this.validations, 'fillerMaterialFillGroup');
        if (
          !isEmpty(pqrMaterialGroupId) &&
          pqrMaterialGroupId !== get(fillerMaterial, 'group.id')
        ) {
          return this.intl
            .t('model-validations.wps.aws-b2-1.filler-material.same-group', {
              group: label,
            })
            .toString();
        }
      }
    }
    return '';
  }

  get isFillerMaterialCapValid() {
    let fillerMaterial = this._wpsFillerMaterialCap;
    if (!isEmpty(fillerMaterial)) {
      if (this._hasImpactRequirements()) {
        let pqrMaterialId = get(this.validations, 'fillerMaterialCap.value');
        let fillerMaterialId = get(fillerMaterial, 'id');
        if (!isEmpty(pqrMaterialId)) {
          return pqrMaterialId === fillerMaterialId;
        }
      } else {
        let pqrMaterialGroupId = get(this.validations, 'fillerMaterialCapGroup.value');
        if (!isEmpty(pqrMaterialGroupId)) {
          let groupId = get(fillerMaterial, 'group.id');
          return pqrMaterialGroupId === groupId;
        }
      }
    }
    return true;
  }

  get fillerMaterialCapInvalidMessage() {
    let fillerMaterial = this._wpsFillerMaterialCap;
    if (!isEmpty(fillerMaterial)) {
      if (this._hasImpactRequirements()) {
        let { value: pqrMaterialId, label } = get(this.validations, 'fillerMaterialCap');
        if (!isEmpty(pqrMaterialId) && pqrMaterialId !== get(fillerMaterial, 'id')) {
          return this.intl
            .t('model-validations.wps.aws-b2-1.filler-material.same-material', {
              fillerMaterial: label,
            })
            .toString();
        }
      } else {
        let { value: pqrMaterialGroupId, label } = get(this.validations, 'fillerMaterialCapGroup');
        if (
          !isEmpty(pqrMaterialGroupId) &&
          pqrMaterialGroupId !== get(fillerMaterial, 'group.id')
        ) {
          return this.intl
            .t('model-validations.wps.aws-b2-1.filler-material.same-group', {
              group: label,
            })
            .toString();
        }
      }
    }
    return '';
  }

  get isFillerMaterialDiameterRootValid() {
    if (this.isOverlayWeld) {
      let pqrDiametersSerialized = get(this.validations, 'fillerMaterialRootDiameter');
      let wpsDiameters = get(this.wps, 'fillerMaterialRootDiameters');
      if (!isEmpty(pqrDiametersSerialized) && !isEmpty(wpsDiameters)) {
        let pqrDiameters = pqrDiametersSerialized.split(',');
        if (wpsDiameters.length !== pqrDiameters.length) {
          return false;
        }

        for (let i = 0; i < pqrDiameters.length; i++) {
          let pqrDiameter = pqrDiameters[i];
          if (wpsDiameters.indexOf(pqrDiameter) < 0) {
            return false;
          }
        }
      }
    }
    return true;
  }

  get fillerMaterialDiameterRootInvalidMessage() {
    if (this.isOverlayWeld) {
      return this.intl
        .t('model-validations.wps.aws-b2-1.filler-material-diameter.overlay-same', {
          values: get(this.validations, 'fillerMaterialRootDiameter'),
        })
        .toString();
    }
    return '';
  }

  get isWeldingPositionRootDirectionValid() {
    let pqrDirection = get(this.validations, 'weldingPositionRootDirection');
    let wpsDirection = get(this.wps, 'weldingPositionRootDirection');

    if (!isEmpty(pqrDirection)) {
      if (pqrDirection === WELDING_POSITION_DIRECTION_OPTIONS.UPHILL) {
        return true;
      } else if (pqrDirection === WELDING_POSITION_DIRECTION_OPTIONS.DOWNHILL) {
        return (
          wpsDirection === WELDING_POSITION_DIRECTION_OPTIONS.DOWNHILL || isEmpty(wpsDirection)
        );
      }
    } else {
      return wpsDirection === WELDING_POSITION_DIRECTION_OPTIONS.DOWNHILL || isEmpty(wpsDirection);
    }
    return true;
  }

  get weldingPositionRootDirectionInvalidMessage() {
    let pqrDirection = get(this.validations, 'weldingPositionRootDirection');
    let wpsDirection = get(this.wps, 'weldingPositionRootDirection');

    if (!isEmpty(pqrDirection)) {
      if (pqrDirection === WELDING_POSITION_DIRECTION_OPTIONS.UPHILL) {
        return true;
      } else if (pqrDirection === WELDING_POSITION_DIRECTION_OPTIONS.DOWNHILL) {
        return this.intl
          .t('model-validations.wps.aws-b2-1.welding-position-direction.down-hill-or-nothing')
          .toString();
      }
    } else {
      return this.intl
        .t('model-validations.wps.aws-b2-1.welding-position-direction.down-hill-or-nothing')
        .toString();
    }
    return '';
  }

  get isWeldingPositionFillDirectionValid() {
    let pqrDirection = get(this.validations, 'weldingPositionFillDirection');
    let wpsDirection = get(this.wps, 'weldingPositionFillDirection');

    if (!isEmpty(pqrDirection)) {
      if (pqrDirection === WELDING_POSITION_DIRECTION_OPTIONS.UPHILL) {
        return true;
      } else if (pqrDirection === WELDING_POSITION_DIRECTION_OPTIONS.DOWNHILL) {
        return (
          wpsDirection === WELDING_POSITION_DIRECTION_OPTIONS.DOWNHILL || isEmpty(wpsDirection)
        );
      }
    } else {
      return wpsDirection === WELDING_POSITION_DIRECTION_OPTIONS.DOWNHILL || isEmpty(wpsDirection);
    }
    return true;
  }

  get weldingPositionFillDirectionInvalidMessage() {
    let pqrDirection = get(this.validations, 'weldingPositionFillDirection');
    let wpsDirection = get(this.wps, 'weldingPositionFillDirection');

    if (!isEmpty(pqrDirection)) {
      if (pqrDirection === WELDING_POSITION_DIRECTION_OPTIONS.UPHILL) {
        return true;
      } else if (pqrDirection === WELDING_POSITION_DIRECTION_OPTIONS.DOWNHILL) {
        return this.intl
          .t('model-validations.wps.aws-b2-1.welding-position-direction.down-hill-or-nothing')
          .toString();
      }
    } else {
      return this.intl
        .t('model-validations.wps.aws-b2-1.welding-position-direction.down-hill-or-nothing')
        .toString();
    }
    return '';
  }

  get isWeldingPositionCapDirectionValid() {
    let pqrDirection = get(this.validations, 'weldingPositionCapDirection');
    let wpsDirection = get(this.wps, 'weldingPositionCapDirection');

    if (!isEmpty(pqrDirection)) {
      if (pqrDirection === WELDING_POSITION_DIRECTION_OPTIONS.UPHILL) {
        return '';
      } else if (pqrDirection === WELDING_POSITION_DIRECTION_OPTIONS.DOWNHILL) {
        return (
          wpsDirection === WELDING_POSITION_DIRECTION_OPTIONS.DOWNHILL || isEmpty(wpsDirection)
        );
      }
    } else {
      return wpsDirection === WELDING_POSITION_DIRECTION_OPTIONS.DOWNHILL || isEmpty(wpsDirection);
    }
    return true;
  }

  get weldingPositionCapDirectionInvalidMessage() {
    let pqrDirection = get(this.validations, 'weldingPositionCapDirection');

    if (!isEmpty(pqrDirection)) {
      if (pqrDirection === WELDING_POSITION_DIRECTION_OPTIONS.UPHILL) {
        return '';
      } else if (pqrDirection === WELDING_POSITION_DIRECTION_OPTIONS.DOWNHILL) {
        return this.intl
          .t('model-validations.wps.aws-b2-1.welding-position-direction.down-hill-or-nothing')
          .toString();
      }
    } else {
      return this.intl
        .t('model-validations.wps.aws-b2-1.welding-position-direction.down-hill-or-nothing')
        .toString();
    }
    return '';
  }

  get isOverlayNumberOfLayerValid() {
    let pqrLayers = parseInt(get(this.validations, 'overlayNumberOfLayers'));
    let wpsLayers = parseInt(get(this.wps, 'overlayNumberOfLayers'));

    if (!isNaN(pqrLayers) && !isNaN(wpsLayers)) {
      if (pqrLayers === 1) {
        return wpsLayers >= 1;
      }
      if (pqrLayers >= 2) {
        return wpsLayers >= 2;
      }
    }
    return true;
  }

  get overlayNumberOfLayerInvalidMessage() {
    let pqrLayers = parseInt(get(this.validations, 'overlayNumberOfLayers'));
    let wpsLayers = parseInt(get(this.wps, 'overlayNumberOfLayers'));

    if (!isNaN(pqrLayers) && !isNaN(wpsLayers)) {
      return this.intl.t('model-validations.wps.aws-b2-1.overlay-number-layers.must-have', {
        layers: pqrLayers,
      });
    }
    return true;
  }
}
