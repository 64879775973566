import { service } from '@ember/service';
import { attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { assert } from '@ember/debug';
import { get, computed, set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { memberAction } from 'ember-api-actions';
import moment from 'moment';
import { hash } from 'rsvp';
import BaseModel from 'weldnote/models/base-model';
import { calculateShielding } from 'weldnote/utils/certification-rules';
import Constants from 'weldnote/utils/constants';
import PQRRules from 'weldnote/utils/pqr-rules';
import {
  isCompleteJointPenetrationButtWeld,
  isFilletWeld,
  isPartialPenetrationButtWeldASME,
} from 'weldnote/utils/rules/weld-type';
import { isBothSides, isMultiLayer, isSingleLayer } from 'weldnote/utils/rules/welding-detail';
import { isESW } from 'weldnote/utils/rules/welding-process';
import { isStandardAWS } from 'weldnote/utils/standards';
import { validateFillerMaterialAWS } from 'weldnote/utils/validation/filler-material';
import { thicknessValidation } from 'weldnote/utils/validation/thickness';
import { weldingDetailValidation } from 'weldnote/utils/validation/welding-details';
import PqrStandard from './pqr-standard';
import ConstructionStandard from './construction-standard';
import ExaminingBody from './examining-body';
import WeldType from './weld-type';
import WeldingProcess from './welding-process';
import WeldingDetail from './welding-detail';
import WeldingPosition from './welding-position';
import ArcTransferMode from './arc-transfer-mode';
import TypeCurrentPolarity from './type-current-polarity';
import BaseMaterial from './base-material';
import BaseMaterialGroup from './base-material-group';
import PreparationMethod from './preparation-method';
import FillerMaterial from './filler-material';
import FillerMaterialCommercialDesignation from './filler-material-commercial-designation';
import Gas from './gas';
import GasCommercialDesignation from './gas-commercial-designation';
import Flux from './flux';
import FluxCommercialDesignation from './flux-commercial-designation';
import PostWeldTreatmentMethod from './post-weld-treatment-method';
import TensileTestLine from './tensile-test-line';
import BendTestLine from './bend-test-line';
import ImpactTestLine from './impact-test-line';
import HardnessTestLine from './hardness-test-line';
import FileUpload from './file-upload';
import GrooveDesign from './groove-design';
import WeldLayerConfiguration from './weld-layer-configuration';
import ProcessMecanization from './process-mecanization';
import CertificationSpecialRequirement from './certification-special-requirement';
import Welder from './welder';
import Staff from './staff';
import PqrWeldingParameter from './pqr-welding-parameter';
import IndustryCode from './industry-code';

const { PQR } = PQRRules;

const { LAYER_OPTIONS, UNIT_SYSTEM, UNITS, PRODUCT_TYPE, OVERLAY_TYPE_OPTIONS, ARCHIVAL_STATE } =
  Constants;

class PQRModel extends BaseModel {
  @service
  userSession: any;

  @attr('string')
  declare certificateNumber?: string;

  @belongsTo('company')
  declare company: string;

  @attr('string')
  declare address?: string;

  @belongsTo('pqr-standard', { async: false })
  declare standard: PqrStandard;

  @belongsTo('construction-standard', { async: false })
  declare constructionStandard: ConstructionStandard;

  @belongsTo('examining-body', { async: false })
  declare examiningBody: ExaminingBody;

  @attr('date')
  declare emissionDate?: string;

  // Weld Type
  @belongsTo('weld-type', { async: false })
  declare weldTypeTestPiece?: WeldType | null;

  @hasMany('weld-type', { async: false })
  declare weldTypeApprovalRange: SyncHasMany<WeldType>;

  // Welding Process
  @belongsTo('welding-process', { async: false })
  declare weldingProcessRootTestPiece: WeldingProcess;

  @belongsTo('welding-process', { async: false })
  declare weldingProcessFillTestPiece: WeldingProcess;

  @hasMany('welding-process', { async: false })
  declare weldingProcessRootApprovalRange: SyncHasMany<WeldingProcess>;

  @hasMany('welding-process', { async: false })
  declare weldingProcessFillApprovalRange: SyncHasMany<WeldingProcess>;

  // Welding Details
  @hasMany('welding-detail', { async: false })
  declare weldingDetailsTestPiece: SyncHasMany<WeldingDetail>;

  @hasMany('welding-detail', { async: false })
  declare weldingDetailsApprovalRange: SyncHasMany<WeldingDetail>;

  // Welding Position
  @belongsTo('welding-position', { async: false })
  declare weldingPositionRootTestPiece: WeldingPosition;

  @belongsTo('welding-position', { async: false })
  declare weldingPositionFillTestPiece: WeldingPosition;

  @hasMany('welding-position', { async: false })
  declare weldingPositionRootApprovalRange: SyncHasMany<WeldingPosition>;

  @hasMany('welding-position', { async: false })
  declare weldingPositionFillApprovalRange: SyncHasMany<WeldingPosition>;

  // Arc Transfer Mode
  @belongsTo('arc-transfer-mode', { async: false })
  declare arcTransferModeRootTestPiece: ArcTransferMode;

  @belongsTo('arc-transfer-mode', { async: false })
  declare arcTransferModeFillTestPiece: ArcTransferMode;

  @hasMany('arc-transfer-mode', { async: false })
  declare arcTransferModeRootApprovalRange: SyncHasMany<ArcTransferMode>;

  @hasMany('arc-transfer-mode', { async: false })
  declare arcTransferModeFillApprovalRange: SyncHasMany<ArcTransferMode>;

  @belongsTo('type-current-polarity', { async: false })
  declare typeCurrentRootTestPiece: TypeCurrentPolarity;

  @belongsTo('type-current-polarity', { async: false })
  declare typeCurrentFillTestPiece: TypeCurrentPolarity;

  @hasMany('type-current-polarity', { async: false })
  declare typeCurrentRootApprovalRange: SyncHasMany<TypeCurrentPolarity>;

  @hasMany('type-current-polarity', { async: false })
  declare typeCurrentFillApprovalRange: SyncHasMany<TypeCurrentPolarity>;

  // Base Material
  @belongsTo('base-material', { async: false })
  declare baseMaterial1TestPiece: BaseMaterial;

  @belongsTo('base-material', { async: false })
  declare baseMaterial2TestPiece: BaseMaterial;

  @hasMany('base-material-group', { async: false })
  declare baseMaterial1ApprovalRange: SyncHasMany<BaseMaterialGroup>;

  @hasMany('base-material-group', { async: false })
  declare baseMaterial2ApprovalRange: SyncHasMany<BaseMaterialGroup>;

  // Product Type
  @attr('string')
  declare productTypeTestPiece?: string;

  // Welding Thickness
  @attr('decimal-value')
  declare thicknessRootTestPiece?: number;

  @attr('string')
  declare thicknessRootLayerTestPiece?: string;

  @attr('decimal-value')
  declare thicknessFillTestPiece?: number;

  @attr('string')
  declare thicknessFillLayerTestPiece?: string;

  @attr('decimal-value')
  declare thicknessTotalTestPiece?: number;

  @attr('decimal-value')
  declare thicknessRootMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare thicknessRootMaximumApprovalRange?: number;

  @attr('decimal-value')
  declare thicknessFillMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare thicknessFillMaximumApprovalRange?: number;

  @attr('decimal-value')
  declare thicknessTotalMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare thicknessTotalMaximumApprovalRange?: number;

  // Throat Thickness
  @attr('decimal-value')
  declare throatThicknessTestPiece?: number;

  @attr('decimal-value')
  declare throatThicknessMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare throatThicknessMaximumApprovalRange?: number;

  // Fillet Weld Base Material Thickness
  @attr('decimal-value')
  declare filletWeldBaseMaterial1ThicknessTestPiece?: number;

  @attr('decimal-value')
  declare filletWeldBaseMaterial1ThicknessMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare filletWeldBaseMaterial1ThicknessMaximumApprovalRange?: number;

  @attr('decimal-value')
  declare filletWeldBaseMaterial2ThicknessTestPiece?: number;

  @attr('decimal-value')
  declare filletWeldBaseMaterial2ThicknessMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare filletWeldBaseMaterial2ThicknessMaximumApprovalRange?: number;

  // PJP Butt Weld Base Material Thickness
  @attr('decimal-value')
  declare pjpButtWeldBaseMaterial1ThicknessMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare pjpButtWeldBaseMaterial1ThicknessMaximumApprovalRange?: number;

  @attr('decimal-value')
  declare pjpButtWeldBaseMaterial2ThicknessMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare pjpButtWeldBaseMaterial2ThicknessMaximumApprovalRange?: number;

  // Diameter
  @attr('decimal-value')
  declare diameterTestPiece?: number;

  @attr('decimal-value')
  declare diameterMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare diameterMaximumApprovalRange?: number;

  // Branch Angle
  @attr('number')
  declare branchAngleTestPiece?: number;

  @attr('number')
  declare branchAngleMinimumApprovalRange?: number;

  @attr('number')
  declare branchAngleMaximumApprovalRange?: number;

  // Preparation Methods
  @hasMany('preparation-method', { async: false })
  declare preparationMethodsTestPiece?: SyncHasMany<PreparationMethod>;

  // Filler Materials
  @belongsTo('filler-material', { async: false })
  declare fillerMaterialRootTestPiece: FillerMaterial;

  // Root is currently modeled as a syncronous relations, if this changes look for references
  @belongsTo('filler-material-commercial-designation', { async: false })
  declare fillerMaterialRootCommercialDesignationTestPiece: FillerMaterialCommercialDesignation;

  @hasMany('filler-material-commercial-designation', { async: false })
  declare fillerMaterialRootCommercialDesignationApprovalRange: FillerMaterialCommercialDesignation;

  @belongsTo('filler-material', { async: false })
  declare fillerMaterialFillTestPiece: FillerMaterial;

  @belongsTo('filler-material-commercial-designation', { async: false })
  declare fillerMaterialFillCommercialDesignationTestPiece: FillerMaterialCommercialDesignation;

  @hasMany('filler-material-commercial-designation', { async: false })
  declare fillerMaterialFillCommercialDesignationApprovalRange: SyncHasMany<FillerMaterialCommercialDesignation>;

  @attr()
  declare fillerMaterialRootDiameters?: string;

  @attr()
  declare fillerMaterialFillDiameters?: string;

  @attr()
  declare fillerMaterialCapDiameters?: string;

  @attr({
    defaultValue() {
      return {};
    },
  })
  declare additionalData?: string;

  @attr('string')
  declare shieldingRoot?: string;

  @belongsTo('gas', { async: false })
  declare shieldingRootGasTestPiece: Gas;

  @hasMany('gas', { async: false })
  declare shieldingRootGasApprovalRange: SyncHasMany<Gas>;

  @belongsTo('gas-commercial-designation', {
    async: false,
  })
  declare shieldingRootGasCommercialDesignationTestPiece: GasCommercialDesignation;

  @attr('string')
  declare shieldingRootGasMixture?: string;

  @hasMany('gas-commercial-designation', {
    async: false,
  })
  declare shieldingRootGasCommercialDesignationApprovalRange: SyncHasMany<GasCommercialDesignation>;

  @belongsTo('flux', { async: false })
  declare shieldingRootFluxTestPiece: Flux;

  @hasMany('flux', { async: false })
  declare shieldingRootFluxApprovalRange: SyncHasMany<Flux>;

  @belongsTo('flux-commercial-designation', {
    async: false,
  })
  declare shieldingRootFluxCommercialDesignationTestPiece: FluxCommercialDesignation;

  @hasMany('flux-commercial-designation', {
    async: false,
  })
  declare shieldingRootFluxCommercialDesignationApprovalRange: SyncHasMany<FluxCommercialDesignation>;

  @attr('string')
  declare shieldingFill?: string;

  @belongsTo('gas', { async: false })
  declare shieldingFillGasTestPiece: Gas;

  @hasMany('gas', { async: false })
  declare shieldingFillGasApprovalRange: SyncHasMany<Gas>;

  @belongsTo('gas-commercial-designation', {
    async: false,
  })
  declare shieldingFillGasCommercialDesignationTestPiece: GasCommercialDesignation;

  @attr('string')
  declare shieldingFillGasMixture?: string;

  @hasMany('gas-commercial-designation', {
    async: false,
  })
  declare shieldingFillGasCommercialDesignationApprovalRange: SyncHasMany<GasCommercialDesignation>;

  @belongsTo('flux', { async: false })
  declare shieldingFillFluxTestPiece: Flux;

  @hasMany('flux', { async: false })
  declare shieldingFillFluxApprovalRange: SyncHasMany<Flux>;

  @belongsTo('flux-commercial-designation', {
    async: false,
  })
  declare shieldingFillFluxCommercialDesignationTestPiece: FluxCommercialDesignation;

  @hasMany('flux-commercial-designation', {
    async: false,
  })
  declare shieldingFillFluxCommercialDesignationApprovalRange: SyncHasMany<FluxCommercialDesignation>;

  @attr('string')
  declare backingTestPiece?: string;

  @belongsTo('gas', { async: false })
  declare backingGasTestPiece: Gas;

  @hasMany('gas', { async: false })
  declare backingGasApprovalRange: SyncHasMany<Gas>;

  @belongsTo('gas-commercial-designation', {
    async: false,
  })
  declare backingGasCommercialDesignationTestPiece: SyncHasMany<GasCommercialDesignation>;

  @attr('string')
  declare backingGasMixture?: string;

  @hasMany('gas-commercial-designation', {
    async: false,
  })
  declare backingGasCommercialDesignationApprovalRange: SyncHasMany<GasCommercialDesignation>;

  @belongsTo('flux', { async: false })
  declare backingFluxTestPiece: Flux;

  @hasMany('flux', { async: false })
  declare backingFluxApprovalRange: SyncHasMany<Flux>;

  @belongsTo('flux-commercial-designation', {
    async: false,
  })
  declare backingFluxCommercialDesignationTestPiece: FluxCommercialDesignation;

  @hasMany('flux-commercial-designation', {
    async: false,
  })
  declare backingFluxCommercialDesignationApprovalRange: SyncHasMany<FluxCommercialDesignation>;

  @attr('string')
  declare backingFlowRateRoot?: string;

  @attr('string')
  declare backingFlowRateFill?: string;

  @attr('string')
  declare flowRateRoot?: string;

  @attr('string')
  declare flowRateFill?: string;

  // Additional Details
  @attr('string')
  declare electrodeType?: string;

  @attr('number')
  declare numberOfElectrodes?: number;

  @attr('string')
  declare pulsedWeldingDetails?: string;

  @attr('string')
  declare weaving?: string;

  @attr('string')
  declare oscillation?: string;

  @attr('string')
  declare contactTubeDistance?: string;

  @attr('string')
  declare backGaugingDetails?: string;

  // Thermal Parameters
  @attr('number')
  declare preheatTemperatureTestPiece?: number;

  @attr('number')
  declare interpassTemperatureTestPiece?: number;

  @attr('number')
  declare postHeatTemperatureTestPiece?: number;

  @attr('decimal-value')
  declare postWeldHeatTreatmentTimeTestPiece?: number;

  @attr('decimal-value')
  declare postWeldHeatTreatmentTimeMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare postWeldHeatTreatmentTimeMaximumApprovalRange?: number;

  @attr('number')
  declare postWeldHeatTreatmentTemperatureTestPiece?: number;

  @belongsTo('post-weld-treatment-method', { async: false })
  declare postWeldHeatTreatmentMethodTestPiece: PostWeldTreatmentMethod;

  @attr('number')
  declare postWeldHeatTreatmentHeatingRateTestPiece?: number;

  @attr('number')
  declare postWeldHeatTreatmentCoolingRateTestPiece?: number;

  @attr('decimal-value')
  declare heatInputMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare heatInputMaximumApprovalRange?: number;

  @attr('number')
  declare preheatTemperatureMinimumApprovalRange?: number;

  @attr('number')
  declare preheatTemperatureMaximumApprovalRange?: number;

  @attr('number')
  declare interpassTemperatureMinimumApprovalRange?: number;

  @attr('number')
  declare interpassTemperatureMaximumApprovalRange?: number;

  @attr('number')
  declare postHeatTemperatureMinimumApprovalRange?: number;

  @attr('number')
  declare postHeatTemperatureMaximumApprovalRange?: number;

  @attr('number')
  declare postWeldHeatTreatmentTemperatureMinimumApprovalRange?: number;

  @attr('number')
  declare postWeldHeatTreatmentTemperatureMaximumApprovalRange?: number;

  @hasMany('pqr-welding-parameter', { async: false })
  declare weldingParameters: SyncHasMany<PqrWeldingParameter>;

  get visualLabel() {
    return this.certificateNumber;
  }

  @attr('string')
  declare tensileTestName?: string;

  @attr('number')
  declare tensileTestTemperature?: number;

  @hasMany('tensile-test-line', { async: false })
  declare tensileTestLines: SyncHasMany<TensileTestLine>;

  @attr('string')
  declare bendTestReport?: string;

  @attr('number')
  declare bendTestMandrillDiameter?: number;

  @hasMany('bend-test-line', { async: false })
  declare bendTestLines: SyncHasMany<BendTestLine>;

  @attr('string')
  declare macroscopicExaminationTestReport?: string;

  @attr('string')
  declare macroscopicExaminationObservations?: string;

  @attr('string')
  declare impactTestReport?: string;

  @attr('boolean')
  declare impactTestRequired?: boolean;

  @attr('string')
  declare impactTestType?: string;

  @attr('string')
  declare impactTestDimensions?: string;

  @attr('number')
  declare impactTestTemperature?: number;

  @hasMany('impact-test-line', { async: false })
  declare impactTestLines: SyncHasMany<ImpactTestLine>;

  @attr('string')
  declare hardnessTestReport?: string;

  @attr('boolean')
  declare hardnessTestRequired?: boolean;

  @attr('string')
  declare hardnessTestType?: string;

  @hasMany('hardness-test-line', { async: false })
  declare hardnessTestLines: SyncHasMany<HardnessTestLine>;

  @attr('string')
  declare nonDestructiveVisualTesting?: string;

  @attr('string')
  declare nonDestructiveVisualTestingResult?: string;

  @attr('string')
  declare nonDestructivePenetrantTesting?: string;

  @attr('string')
  declare nonDestructivePenetrantTestingResult?: string;

  @attr('string')
  declare nonDestructiveMagneticTesting?: string;

  @attr('string')
  declare nonDestructiveMagneticTestingResult?: string;

  @attr('string')
  declare nonDestructiveUltrasonicTesting?: string;

  @attr('string')
  declare nonDestructiveUltrasonicTestingResult?: string;

  @attr('string')
  declare nonDestructiveRadiographicTesting?: string;

  @attr('string')
  declare nonDestructiveRadiographicTestingResult?: string;

  @attr('decimal-value')
  declare grooveAngleMinimum?: number;

  @attr('decimal-value')
  declare grooveAngleMaximum?: number;

  @attr('decimal-value')
  declare rootFaceMinimum?: number;

  @attr('decimal-value')
  declare rootFaceMaximum?: number;

  @attr('decimal-value')
  declare rootGapMinimum?: number;

  @attr('decimal-value')
  declare rootGapMaximum?: number;

  @belongsTo('file-upload', { async: false })
  declare pqrDocument: FileUpload;

  @hasMany('groove-design', { async: false })
  declare grooveDesigns: SyncHasMany<GrooveDesign>;

  @hasMany('weld-layer-configuration', { async: false })
  declare weldLayerConfigurations: SyncHasMany<WeldLayerConfiguration>;

  @attr('number')
  declare torchAngleMinimum?: number;

  @attr('number')
  declare torchAngleMaximum?: number;

  @attr('decimal-value')
  declare gasNozzleDiameterRoot?: number;

  @attr('decimal-value')
  declare gasNozzleDiameterFill?: number;

  @belongsTo('process-mecanization', { async: false })
  declare processMecanizationRootTestPiece: ProcessMecanization;

  @belongsTo('process-mecanization', { async: false })
  declare processMecanizationFillTestPiece: ProcessMecanization;

  @hasMany('process-mecanization', { async: false })
  declare processMecanizationRootApprovalRange: SyncHasMany<ProcessMecanization>;

  @hasMany('process-mecanization', { async: false })
  declare processMecanizationFillApprovalRange: SyncHasMany<ProcessMecanization>;

  @belongsTo('welding-process', { async: false })
  declare weldingProcessCapTestPiece: WeldingProcess;

  @hasMany('welding-process', { async: false })
  declare weldingProcessCapApprovalRange: SyncHasMany<WeldingProcess>;

  @belongsTo('arc-transfer-mode', { async: false })
  declare arcTransferModeCapTestPiece: ArcTransferMode;

  @hasMany('arc-transfer-mode', { async: false })
  declare arcTransferModeCapApprovalRange: SyncHasMany<ArcTransferMode>;

  @belongsTo('process-mecanization', { async: false })
  declare processMecanizationCapTestPiece: ProcessMecanization;

  @hasMany('process-mecanization', { async: false })
  declare processMecanizationCapApprovalRange: SyncHasMany<ProcessMecanization>;

  @attr('number')
  declare gasNozzleDiameterCap?: number;

  @belongsTo('welding-position', { async: false })
  declare weldingPositionCapTestPiece: WeldingPosition;

  @hasMany('welding-position', { async: false })
  declare weldingPositionCapApprovalRange: SyncHasMany<WeldingPosition>;

  @belongsTo('type-current-polarity', { async: false })
  declare typeCurrentCapTestPiece: TypeCurrentPolarity;

  @hasMany('type-current-polarity', { async: false })
  declare typeCurrentCapApprovalRange: SyncHasMany<TypeCurrentPolarity>;

  @attr('decimal-value')
  declare thicknessCapTestPiece?: number;

  @attr('string')
  declare thicknessCapLayerTestPiece?: string;

  @attr('decimal-value')
  declare thicknessCapMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare thicknessCapMaximumApprovalRange?: number;

  @attr('string')
  declare shieldingCap?: string;

  @belongsTo('gas', { async: false })
  declare shieldingCapGasTestPiece: Gas;

  @hasMany('gas', { async: false })
  declare shieldingCapGasApprovalRange: SyncHasMany<Gas>;

  @belongsTo('gas-commercial-designation', {
    async: false,
  })
  declare shieldingCapGasCommercialDesignationTestPiece: GasCommercialDesignation;

  @hasMany('gas-commercial-designation', {
    async: false,
  })
  declare shieldingCapGasCommercialDesignationApprovalRange: SyncHasMany<GasCommercialDesignation>;

  @attr('string')
  declare shieldingCapGasMixture?: string;

  @belongsTo('flux', { async: false })
  declare shieldingCapFluxTestPiece: Flux;

  @hasMany('flux', { async: false })
  declare shieldingCapFluxApprovalRange: SyncHasMany<Flux>;

  @belongsTo('flux-commercial-designation', {
    async: false,
  })
  declare shieldingCapFluxCommercialDesignationTestPiece: FluxCommercialDesignation;

  @hasMany('flux-commercial-designation', {
    async: false,
  })
  declare shieldingCapFluxCommercialDesignationApprovalRange: SyncHasMany<FluxCommercialDesignation>;

  @attr('string')
  declare flowRateCap?: string;

  @attr('string')
  declare backingFlowRateCap?: string;

  @attr('number')
  declare numberOfElectrodesFill?: number;

  @attr('number')
  declare numberOfElectrodesCap?: number;

  @belongsTo('filler-material', { async: false })
  declare fillerMaterialCapTestPiece: FillerMaterial;

  @belongsTo('filler-material-commercial-designation', { async: false })
  declare fillerMaterialCapCommercialDesignationTestPiece: FillerMaterialCommercialDesignation;

  @hasMany('filler-material-commercial-designation', { async: false })
  declare fillerMaterialCapCommercialDesignationApprovalRange: SyncHasMany<FillerMaterialCommercialDesignation>;

  @attr('decimal-value')
  declare baseMaterial1Thickness?: number;

  @attr('decimal-value')
  declare baseMaterial1ThicknessMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare baseMaterial1ThicknessMaximumApprovalRange?: number;

  @attr('number')
  declare baseMaterial1Diameter?: number;

  @attr('decimal-value')
  declare baseMaterial2Thickness?: number;

  @attr('decimal-value')
  declare baseMaterial2ThicknessMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare baseMaterial2ThicknessMaximumApprovalRange?: number;

  @attr('number')
  declare baseMaterial2Diameter?: number;

  @attr('string')
  declare weldingPositionRootDirection?: string;

  @attr('string')
  declare weldingPositionFillDirection?: string;

  @attr('string')
  declare weldingPositionCapDirection?: string;

  @hasMany('certification-special-requirement', { async: false })
  declare specialRequirements: SyncHasMany<CertificationSpecialRequirement>;

  @attr('string')
  declare overlayType?: string;

  @attr('number')
  declare postHeatTimeTestPiece?: number;

  @attr('number')
  declare postHeatTimeMinimumApprovalRange?: number;

  @attr('number')
  declare postHeatTimeMaximumApprovalRange?: number;

  @attr('string')
  declare pqrDocumentPath?: string;

  @attr('string')
  declare jointType?: string;

  @attr('string')
  declare electrodeTypeFill?: string;

  @attr('string')
  declare electrodeTypeCap?: string;

  @attr('string')
  declare contactTubeDistanceFill?: string;

  @attr('string')
  declare contactTubeDistanceCap?: string;

  @attr('string')
  declare weavingFill?: string;

  @attr('string')
  declare weavingCap?: string;

  @attr('string')
  declare tungstenDiameterRoot?: string;

  @attr('string')
  declare tungstenDiameterFill?: string;

  @attr('string')
  declare tungstenDiameterCap?: string;

  @attr('string')
  declare chemicalReportName?: string;

  @attr('decimal-value')
  declare chemicalCarbonContent?: number;

  @attr('decimal-value')
  declare chemicalManganeseContent?: number;

  @attr('decimal-value')
  declare chemicalSiliconContent?: number;

  @attr('decimal-value')
  declare chemicalChromiumContent?: number;

  @attr('decimal-value')
  declare chemicalNickelContent?: number;

  @attr('decimal-value')
  declare chemicalMolybdenumContent?: number;

  @attr('decimal-value')
  declare chemicalCopperContent?: number;

  @attr('decimal-value')
  declare chemicalPhosphorusContent?: number;

  @attr('decimal-value')
  declare chemicalSulphurContent?: number;

  @attr('decimal-value')
  declare chemicalAluminumContent?: number;

  @attr('decimal-value')
  declare chemicalTitaniumContent?: number;

  @attr('number')
  declare overlayNumberOfLayers?: number;

  @attr('string')
  declare fillerMaterialElectrodeFormRoot?: string;

  @attr('string')
  declare fillerMaterialElectrodeFormFill?: string;

  @attr('string')
  declare fillerMaterialElectrodeFormCap?: string;

  @attr('string')
  declare fillerMaterialGuideRoot?: string;

  @attr('string')
  declare fillerMaterialGuideFill?: string;

  @attr('string')
  declare fillerMaterialGuideCap?: string;

  @attr('string')
  declare oscillationWidthRoot?: string;

  @attr('string')
  declare oscillationWidthFill?: string;

  @attr('string')
  declare oscillationWidthCap?: string;

  @attr('string')
  declare oscillationFrequencyRoot?: string;

  @attr('string')
  declare oscillationFrequencyFill?: string;

  @attr('string')
  declare oscillationFrequencyCap?: string;

  @attr('string')
  declare oscillationDwellTimeRoot?: string;

  @attr('string')
  declare oscillationDwellTimeFill?: string;

  @attr('string')
  declare oscillationDwellTimeCap?: string;

  @attr('boolean')
  declare supplementalDeviceRoot?: boolean;

  @attr('boolean')
  declare supplementalDeviceFill?: boolean;

  @attr('boolean')
  declare supplementalDeviceCap?: boolean;

  @belongsTo('file-upload', { async: false })
  declare baseMaterial1TestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare baseMaterial2TestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare fillerMaterialRootTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare fillerMaterialFillTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare fillerMaterialCapTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare visualTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare penetrantTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare magneticTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare ultrasonicTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare radiographicTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare tensileTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare bendTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare macroscopicTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare impactTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare hardnessTestDocument: FileUpload;

  @belongsTo('file-upload', { async: false })
  declare chemicalAnalysisTestDocument: FileUpload;

  @attr('string')
  declare baseMaterial1TestDocumentPath?: string;

  @attr('string')
  declare baseMaterial2TestDocumentPath?: string;

  @attr('string')
  declare fillerMaterialRootTestDocumentPath?: string;

  @attr('string')
  declare fillerMaterialFillTestDocumentPath?: string;

  @attr('string')
  declare fillerMaterialCapTestDocumentPath?: string;

  @attr('string')
  declare visualTestDocumentPath?: string;

  @attr('string')
  declare penetrantTestDocumentPath?: string;

  @attr('string')
  declare magneticTestDocumentPath?: string;

  @attr('string')
  declare ultrasonicTestDocumentPath?: string;

  @attr('string')
  declare radiographicTestDocumentPath?: string;

  @attr('string')
  declare tensileTestDocumentPath?: string;

  @attr('string')
  declare bendTestDocumentPath?: string;

  @attr('string')
  declare macroscopicTestDocumentPath?: string;

  @attr('string')
  declare impactTestDocumentPath?: string;

  @attr('string')
  declare hardnessTestDocumentPath?: string;

  @attr('string')
  declare chemicalAnalysisTestDocumentPath?: string;

  @belongsTo('file-upload', { async: false })
  declare pqrReportDocument: FileUpload;

  @attr('string')
  declare pqrReportDocumentPath?: string;

  @attr('boolean')
  declare pqrReportOutdated?: boolean;

  @attr('string')
  declare notes?: string;

  @attr('string')
  declare testNotes?: string;

  @attr('string')
  declare archivalState?: string;

  @hasMany('welder', { async: false })
  declare welders: SyncHasMany<Welder>;

  @attr('string')
  declare testConductedBy?: string;

  @belongsTo('welding-procedure-specification', { inverse: null, async: false })
  declare wps: any;

  @belongsTo('staff', { async: false })
  declare writtenBy: Staff;

  @attr('number')
  declare flowRateProcess1Minimum?: number;

  @attr('number')
  declare flowRateProcess1Maximum?: number;

  @attr('number')
  declare flowRateProcess2Minimum?: number;

  @attr('number')
  declare flowRateProcess2Maximum?: number;

  @attr('number')
  declare flowRateProcess3Minimum?: number;

  @attr('number')
  declare flowRateProcess3Maximum?: number;

  hasStandard() {
    return !isEmpty(this.standardId);
  }

  clearStandardDependentFields() {
    set(this, 'weldTypeTestPiece', null);
  }

  get hasAttachement() {
    return !isEmpty(this.pqrDocumentPath);
  }

  get standardId() {
    let { standard } = this;
    if (standard) {
      return get(standard, 'id');
    }
    return '';
  }

  get flowUnit(): string {
    return this.userSession.flowUnit;
  }

  validations = {
    certificateNumber: {
      presence: {
        message(_key: string, _value: any, model: PQRModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    standard: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    emissionDate: {
      custom: {
        validation(_key: string, value: any) {
          if (value) {
            return moment().isAfter(value);
          }
          return true;
        },

        message(_key: string, _value: any, model: PQRModel) {
          return model.translate('model-validations.pqr.emission-date-future');
        },
      },
    },

    thicknessRootTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: [
        {
          validation(_key: string, value: any, model: PQRModel) {
            if (
              !isEmpty(value) &&
              model.get('thicknessRootLayerTestPiece') === LAYER_OPTIONS.SINGLE_LAYER
            ) {
              return value <= 100;
            }
            return true;
          },

          message(_key: string, _value: any, model: PQRModel) {
            return model
              .translate('model-validations.pqr.single-layer-thickness-above-treshold')
              .toString();
          },
        },
        thicknessValidation(
          PQR.THICKNESS_ROOT_TESTPIECE,
          PQR.THICKNESS_FILL_TESTPIECE,
          PQR.THICKNESS_CAP_TEST_PIECE,
          PQR.THICKNESS_TOTAL_TESTPIECE
        ),
      ],
    },

    thicknessFillTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: [
        {
          validation(_key: string, value: any, model: PQRModel) {
            if (
              !isEmpty(value) &&
              model.get('thicknessFillLayerTestPiece') === LAYER_OPTIONS.SINGLE_LAYER
            ) {
              return value <= 100;
            }
            return true;
          },

          message(_key: string, _value: any, model: PQRModel) {
            return model
              .translate('model-validations.pqr.single-layer-thickness-above-treshold')
              .toString();
          },
        },
        thicknessValidation(
          PQR.THICKNESS_ROOT_TESTPIECE,
          PQR.THICKNESS_FILL_TESTPIECE,
          PQR.THICKNESS_CAP_TEST_PIECE,
          PQR.THICKNESS_TOTAL_TESTPIECE
        ),
      ],
    },

    thicknessCapTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: [
        {
          validation(_key: string, value: any, model: PQRModel) {
            if (
              !isEmpty(value) &&
              model.get('thicknessCapLayerTestPiece') === LAYER_OPTIONS.SINGLE_LAYER
            ) {
              return value <= 100;
            }
            return true;
          },

          message(_key: string, _value: any, model: PQRModel) {
            return model
              .translate('model-validations.pqr.single-layer-thickness-above-treshold')
              .toString();
          },
        },
        thicknessValidation(
          PQR.THICKNESS_ROOT_TESTPIECE,
          PQR.THICKNESS_FILL_TESTPIECE,
          PQR.THICKNESS_CAP_TEST_PIECE,
          PQR.THICKNESS_TOTAL_TESTPIECE
        ),
      ],
    },

    thicknessTotalTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: {
        if(_key: string, _value: any, model: PQRModel) {
          return model.isSingleLayer() || isStandardAWS(get(model, 'standard'));
        },

        validation(_key: string, value: any, model: PQRModel) {
          if (!isEmpty(value)) {
            let standard = get(model, 'standard');
            if (isStandardAWS(standard)) {
              let unitSystem = get(model.userSession, 'unitSystem');
              if (unitSystem === UNIT_SYSTEM.METRIC) {
                return value >= 3 || isEmpty(value);
              } else if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
                return value >= 0.125 || isEmpty(value);
              }
              return true;
            } else {
              return value <= 100;
            }
          }
          return true;
        },

        message(_key: string, _value: any, model: PQRModel) {
          let standard = get(model, 'standard');
          if (!isStandardAWS(standard)) {
            return model
              .translate('model-validations.pqr.single-layer-thickness-above-treshold')
              .toString();
          } else {
            let unitSystem = get(model.userSession, 'unitSystem');
            let options: any = {};
            if (unitSystem === UNIT_SYSTEM.METRIC) {
              options.value = 3;
              options.unit = model.translate(`lov.units.${UNITS.MILLIMETER}`);
            } else {
              options.value = 0.125;
              options.unit = model.translate(`lov.units.${UNITS.INCH}`);
            }
            return model.translate('model-validations.pqr.aws-thickness', options);
          }
        },
      },
    },

    diameterTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    fillerMaterialRootDiameters: {
      custom: {
        if(_key: string, value: any) {
          return !isEmpty(value);
        },

        validation(_key: string, value: any /* , model */) {
          for (let i = 0; i < 5; i++) {
            if (!isEmpty(value[i]) && isNaN(parseFloat(value[i]))) {
              return false;
            }
          }
          return true;
        },

        message(_key: string, value: any /* , model */) {
          let invalidResults: string[] = [];
          for (let i = 0; i < 5; i++) {
            if (!isEmpty(value[i]) && isNaN(parseFloat(value[i]))) {
              invalidResults.pushObject(value[i]);
            }
          }
          return `Filler Material Diameter is invalid, values "${invalidResults.join(
            ','
          )}" must be fixed`;
        },
      },
    },

    fillerMaterialFillDiameters: {
      custom: {
        if(_key: string, value: any) {
          return !isEmpty(value);
        },

        validation(_key: string, value: any /* , model */) {
          for (let i = 0; i < 5; i++) {
            if (!isEmpty(value[i]) && isNaN(parseFloat(value[i]))) {
              return false;
            }
          }
          return true;
        },

        message(_key: string, value: any /* , model */) {
          let invalidResults: string[] = [];
          for (let i = 0; i < 5; i++) {
            if (!isEmpty(value[i]) && isNaN(parseFloat(value[i]))) {
              invalidResults.pushObject(value[i]);
            }
          }
          return `Filler Material Diameter is invalid, values "${invalidResults.join(
            ','
          )}" must be fixed`;
        },
      },
    },

    fillerMaterialCapDiameters: {
      custom: {
        if(_key: string, value: any) {
          return !isEmpty(value);
        },

        validation(_key: string, value: any /* , model */) {
          for (let i = 0; i < 5; i++) {
            if (!isEmpty(value[i]) && isNaN(parseFloat(value[i]))) {
              return false;
            }
          }
          return true;
        },

        message(_key: string, value: any /* , model */) {
          let invalidResults: string[] = [];
          for (let i = 0; i < 5; i++) {
            if (!isEmpty(value[i]) && isNaN(parseFloat(value[i]))) {
              invalidResults.pushObject(value[i]);
            }
          }
          return `Filler Material Diameter is invalid, values "${invalidResults.join(
            ','
          )}" must be fixed`;
        },
      },
    },

    shieldingRoot: {
      custom: {
        if(/* key, value*/) {
          return false;
          // return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PQRModel) {
          let weldingProcessRoot = get(model, 'weldingProcessRootTestPiece');
          let weldingProcessCode = get(weldingProcessRoot, 'shortDesignation');
          let correctShielding = calculateShielding(weldingProcessCode);
          if (!isEmpty(correctShielding)) {
            return correctShielding === value;
          }
          return true;
        },

        message(_key: string, _value: any, model: PQRModel) {
          let weldingProcessRoot = get(model, 'weldingProcessRootTestPiece');
          let process = get(weldingProcessRoot, 'shortDesignation');
          let correctShielding = calculateShielding(process);
          let shielding = model.translate(`lov.shielding.${correctShielding}`);
          return model
            .translate('model-validations.shielding.root', {
              process,
              shielding,
            })
            .toString();
        },
      },
    },

    shieldingFill: {
      custom: {
        if(/* key, value, model*/) {
          return false;
          // return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PQRModel) {
          let weldingProcessFill = get(model, 'weldingProcessFillTestPiece');
          let weldingProcessCode = get(weldingProcessFill, 'shortDesignation');
          let correctShielding = calculateShielding(weldingProcessCode);
          if (!isEmpty(correctShielding)) {
            return correctShielding === value;
          }
          return true;
        },

        message(_key: string, _value: any, model: PQRModel) {
          let weldingProcessFill = get(model, 'weldingProcessFillTestPiece');
          let process = get(weldingProcessFill, 'shortDesignation');
          let correctShielding = calculateShielding(process);
          let shielding = model.translate(`lov.shielding.${correctShielding}`);
          return model
            .translate('model-validations.shielding.fill', {
              process,
              shielding,
            })
            .toString();
        },
      },
    },

    shieldingCap: {
      custom: {
        if(/* key, value, model*/) {
          return false;
          // return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PQRModel) {
          let weldingProcessCap = get(model, 'weldingProcessCapTestPiece');
          let weldingProcessCode = get(weldingProcessCap, 'shortDesignation');
          let correctShielding = calculateShielding(weldingProcessCode);
          if (!isEmpty(correctShielding)) {
            return correctShielding === value;
          }
          return true;
        },

        message(_key: string, _value: any, model: PQRModel) {
          let weldingProcessCap = get(model, 'weldingProcessCapTestPiece');
          let process = get(weldingProcessCap, 'shortDesignation');
          let correctShielding = calculateShielding(process);
          let shielding = model.translate(`lov.shielding.${correctShielding}`);
          return model
            .translate('model-validations.shielding.cap', {
              process,
              shielding,
            })
            .toString();
        },
      },
    },

    numberOfElectrodes: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 20,
      },

      custom: {
        if(_key: string, _value: any, model: PQRModel) {
          return isESW(get(model, 'weldingProcessRootTestPiece'));
        },

        validation(_key: string, value: any) {
          return !isEmpty(value);
        },

        message(_key: string, _value: any, model: PQRModel) {
          return model.translate('model-validations.pqr.esw-number-electrodes-required');
        },
      },
    },

    preheatTemperatureTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },
    },

    interpassTemperatureTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },
    },

    postHeatTemperatureTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },
    },

    postWeldHeatTreatmentTimeTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 999.99,
      },
    },

    postWeldHeatTreatmentTimeMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 999.99,
      },
    },

    postWeldHeatTreatmentTimeMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 999.99,
      },
    },

    postWeldHeatTreatmentTemperatureTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },
    },

    postWeldHeatTreatmentHeatingRateTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },

      custom: {
        validation(_key: string, value: any, model: PQRModel) {
          let pwhtTemperature = get(model, 'postWeldHeatTreatmentTemperatureTestPiece');
          if (!isEmpty(pwhtTemperature) && isEmpty(value)) {
            return false;
          }
          return true;
        },

        message(_key: string, _value: any, model: PQRModel) {
          return model.translate('model-validations.pqr.heating-rate-required');
        },
      },
    },

    postWeldHeatTreatmentCoolingRateTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },

      custom: {
        validation(_key: string, value: any, model: PQRModel) {
          let pwhtTemperature = get(model, 'postWeldHeatTreatmentTemperatureTestPiece');
          if (!isEmpty(pwhtTemperature) && isEmpty(value)) {
            return false;
          }
          return true;
        },

        message(_key: string, _value: any, model: PQRModel) {
          return model.translate('model-validations.pqr.cooling-rate-required');
        },
      },
    },

    heatInputMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },
    },

    heatInputMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },
    },

    preheatTemperatureMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },
    },

    preheatTemperatureMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },
    },

    interpassTemperatureMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        lessThanOrEqualTo: 9999,
      },
    },

    interpassTemperatureMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        lessThanOrEqualTo: 9999,
      },
    },

    postHeatTemperatureMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },
    },

    postHeatTemperatureMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },
    },

    postWeldHeatTreatmentTemperatureMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },
    },

    postWeldHeatTreatmentTemperatureMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },
    },

    tensileTestTemperature: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: -273,
        lessThanOrEqualTo: 9999,
        onlyInteger: true,
      },
    },

    grooveAngleMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
      },
    },

    grooveAngleMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 360,
      },
    },

    rootFaceMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
      },
    },

    rootFaceMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
      },
    },

    rootGapMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
      },
    },

    rootGapMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
      },
    },

    branchAngleTestPiece: {
      numericality: {
        onlyInteger: true,
        allowBlank: true,
        greaterThan: 0,
        lessThanOrEqualTo: 90,
      },
    },

    torchAngleMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 180,
        onlyInteger: true,
      },
    },

    torchAngleMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 180,
        onlyInteger: true,
      },
    },

    gasNozzleDiameterRoot: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    gasNozzleDiameterFill: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    gasNozzleDiameterCap: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    thicknessCapMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    thicknessCapMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    numberOfElectrodesFill: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 20,
        onlyInteger: true,
      },

      custom: {
        if(_key: string, _value: any, model: PQRModel) {
          return isESW(get(model, 'weldingProcessFillTestPiece'));
        },

        validation(_key: string, value: any) {
          return !isEmpty(value);
        },

        message(_key: string, _value: any, model: PQRModel) {
          return model.translate('model-validations.pqr.esw-number-electrodes-required');
        },
      },
    },

    numberOfElectrodesCap: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 20,
        onlyInteger: true,
      },

      custom: {
        if(_key: string, _value: any, model: PQRModel) {
          return isESW(get(model, 'weldingProcessCapTestPiece'));
        },

        validation(_key: string, value: any) {
          return !isEmpty(value);
        },

        message(_key: string, _value: any, model: PQRModel) {
          return model.translate('model-validations.pqr.esw-number-electrodes-required');
        },
      },
    },

    baseMaterial1Thickness: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    baseMaterial1Diameter: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    baseMaterial2Thickness: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    baseMaterial2Diameter: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    postHeatTimeTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    postHeatTimeMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    postHeatTimeMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    weldingDetailsTestPiece: {
      custom: weldingDetailValidation,
    },

    fillerMaterialRootTestPiece: {
      custom: validateFillerMaterialAWS(PQR.WELDING_PROCESS_ROOT_TESTPIECE),
    },

    fillerMaterialRootCommercialDesignationTestPiece: {
      custom: validateFillerMaterialAWS(PQR.WELDING_PROCESS_ROOT_TESTPIECE),
    },

    fillerMaterialFillTestPiece: {
      custom: validateFillerMaterialAWS(PQR.WELDING_PROCESS_FILL_TESTPIECE),
    },

    fillerMaterialFillCommercialDesignationTestPiece: {
      custom: validateFillerMaterialAWS(PQR.WELDING_PROCESS_FILL_TESTPIECE),
    },

    fillerMaterialCapTestPiece: {
      custom: validateFillerMaterialAWS(PQR.WELDING_PROCESS_CAP_TEST_PIECE),
    },

    fillerMaterialCapCommercialDesignationTestPiece: {
      custom: validateFillerMaterialAWS(PQR.WELDING_PROCESS_CAP_TEST_PIECE),
    },

    grooveDesigns: {
      custom: {
        if(_key: string, _value: any, model: PQRModel) {
          let weldLayers = get(model, 'weldLayerConfigurations');
          let grooveDesigns = get(model, 'grooveDesigns');
          return weldLayers.get('length') > 0 && grooveDesigns.get('length') > 0;
        },

        validation(_key: string, _value: any, model: PQRModel) {
          let weldLayer = get(model, 'weldLayerConfigurations').firstObject;
          let grooveDesign = get(model, 'grooveDesigns').firstObject;
          if (weldLayer && !isEmpty(weldLayer) && grooveDesign && !isEmpty(grooveDesign)) {
            let grooveType = get(grooveDesign, 'grooveType');
            let weldLayerGrooveType = get(weldLayer, 'grooveType');
            return grooveType === weldLayerGrooveType;
          }
          return true;
        },

        message(_key: string, _value: any, model: PQRModel) {
          return model.translate('model-validations.pqr.groove-design-groove-type');
        },
      },
    },

    weldLayerConfigurations: {
      custom: {
        if(_key: string, _value: any, model: PQRModel) {
          let weldLayers: SyncHasMany<WeldLayerConfiguration> = get(model, 'weldLayerConfigurations');
          let grooveDesigns = get(model, 'grooveDesigns');
          return weldLayers.get('length') > 0 && grooveDesigns.get('length') > 0;
        },

        validation(_key: string, _value: any, model: PQRModel) {
          let weldLayer = get(model, 'weldLayerConfigurations').firstObject;
          let grooveDesign = get(model, 'grooveDesigns').firstObject;
          if (weldLayer && !isEmpty(weldLayer) && grooveDesign && !isEmpty(grooveDesign)) {
            let grooveType = get(grooveDesign, 'grooveType');
            let weldLayerGrooveType = get(weldLayer, 'grooveType');
            return grooveType === weldLayerGrooveType;
          }
          return true;
        },

        message(_key: string, _value: any, model: PQRModel) {
          return model.translate('model-validations.pqr.weld-layer-configuration-groove-type');
        },
      },
    },

    chemicalCarbonContent: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99.9999,
      },
    },

    chemicalManganeseContent: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99.9999,
      },
    },

    chemicalSiliconContent: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99.9999,
      },
    },

    chemicalChromiumContent: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99.9999,
      },
    },

    chemicalNickelContent: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99.9999,
      },
    },

    chemicalMolybdenumContent: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99.9999,
      },
    },

    chemicalCopperContent: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99.9999,
      },
    },

    chemicalPhosphorusContent: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99.9999,
      },
    },

    chemicalSulphurContent: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99.9999,
      },
    },

    chemicalAluminumContent: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99.9999,
      },
    },

    chemicalTitaniumContent: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99.9999,
      },
    },

    overlayNumberOfLayers: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        onlyInteger: true,
      },
    },

    impactTestType: {
      length: {
        maximum: 40,
      },
    },

    impactTestDimensions: {
      length: {
        maximum: 30,
      },
    },

    shieldingCapGasMixture: {
      length: {
        maximum: 100,
      },
    },

    chemicalReportName: {
      length: {
        maximum: 300,
      },
    },

    flowRateProcess1Minimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
      custom: [
        {
          validation(_key: string, value: any, model: PQRModel) {
            if (!isEmpty(value)) {
              return model.validateMinimum('flowRateProcess1Maximum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: PQRModel) {
            return model.getBelowMaximumMessage(get(model, 'flowRateProcess1Maximum'), model.flowUnit);
          },
        },
      ]
    },
    flowRateProcess1Maximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
      custom: [
        {
          validation(_key: string, value: any, model: PQRModel) {
            if (!isEmpty(value)) {
              return model.validateMaximum('flowRateProcess1Minimum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: PQRModel) {
            return model.getAboveMinimumMessage(get(model, 'flowRateProcess1Minimum'), model.flowUnit);
          },
        },
      ]
    },
    flowRateProcess2Minimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
      custom: [
        {
          validation(_key: string, value: any, model: PQRModel) {
            if (!isEmpty(value)) {
              return model.validateMinimum('flowRateProcess2Maximum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: PQRModel) {
            return model.getBelowMaximumMessage(get(model, 'flowRateProcess2Maximum'), model.flowUnit);
          },
        },
      ]
    },
    flowRateProcess2Maximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
      custom: [
        {
          validation(_key: string, value: any, model: PQRModel) {
            if (!isEmpty(value)) {
              return model.validateMaximum('flowRateProcess2Minimum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: PQRModel) {
            return model.getAboveMinimumMessage(get(model, 'flowRateProcess2Minimum'), model.flowUnit);
          },
        },
      ]
    },
    flowRateProcess3Minimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
      custom: [
        {
          validation(_key: string, value: any, model: PQRModel) {
            if (!isEmpty(value)) {
              return model.validateMinimum('flowRateProcess3Maximum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: PQRModel) {
            return model.getBelowMaximumMessage(get(model, 'flowRateProcess3Maximum'), model.flowUnit);
          },
        },
      ]
    },
    flowRateProcess3Maximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
      custom: [
        {
          validation(_key: string, value: any, model: PQRModel) {
            if (!isEmpty(value)) {
              return model.validateMaximum('flowRateProcess3Minimum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: PQRModel) {
            return model.getAboveMinimumMessage(get(model, 'flowRateProcess3Minimum'), model.flowUnit);
          },
        },
      ]
    }
  };

  get industryCode(): IndustryCode {
    return get(this.standard, 'code');
  }

  // @ts-expect-error
  validate(): boolean {
    let result: any = super.validate();
    let tensilelineInvalid = false;
    let bendLineInvalid = false;
    let impactTestLineInvalid = false;
    let tensileLines = this.tensileTestLines;
    if (tensileLines) {
      tensileLines.forEach((line) => {
        if (!line.validate()) {
          tensilelineInvalid = true;
        }
      });
    }
    let { bendTestLines } = this;
    if (bendTestLines) {
      bendTestLines.forEach((line) => {
        if (!line.validate()) {
          bendLineInvalid = true;
        }
      });
    }
    let { impactTestLines } = this;
    if (impactTestLines) {
      impactTestLines.forEach((line) => {
        if (!line.validate()) {
          impactTestLineInvalid = true;
        }
      });
    }

    let passesInvalid = false;
    let passes = this.weldingParameters;
    if (passes) {
      passes.forEach((line) => {
        if (!line.validate()) {
          passesInvalid = true;
        }
      });
    }
    return (
      result && !tensilelineInvalid && !bendLineInvalid && !impactTestLineInvalid && !passesInvalid
    );
  }

  metadata = {
    modelName: 'pqr',
    certificateNumber: {
      required: true,
    },

    standard: {
      required: true,
    },
  };

  fetchAllRelations() {
    return hash({
      weldingPositionsRoot: this.get('weldingPositionRootApprovalRange'),
      weldingPositionsFill: this.get('weldingPositionFillApprovalRange'),
      weldingPositionsCap: this.get('weldingPositionCapApprovalRange'),
      weldingParameters: this.get('weldingParameters'),
      weldingDetails: this.get('weldingDetailsTestPiece'),
      grooveDesigns: this.get('grooveDesigns'),
      weldLayerConfigurations: this.get('weldLayerConfigurations'),
      specialRequirements: this.get('specialRequirements'),
      preparationMethods: this.get('preparationMethodsTestPiece'),
    });
  }

  fetchAllRelationsForWPSValidation() {
    return hash({
      weldingProcessRoot: this.get('weldingProcessRootApprovalRange'),
      weldingProcessFill: this.get('weldingProcessFillApprovalRange'),
      weldingProcessCap: this.get('weldingProcessCapApprovalRange'),
      weldingParameters: this.get('weldingParameters'),
      weldingDetails: this.get('weldingDetailsApprovalRange'),
      weldType: this.get('weldTypeApprovalRange'),
      processMecanizationRootApprovalRange: this.get('processMecanizationRootApprovalRange'),
      processMecanizationFillApprovalRange: this.get('processMecanizationFillApprovalRange'),
      processMecanizationCapApprovalRange: this.get('processMecanizationCapApprovalRange'),
      typeCurrentRootApprovalRange: this.get('typeCurrentRootApprovalRange'),
      typeCurrentFillApprovalRange: this.get('typeCurrentFillApprovalRange'),
      typeCurrentCapApprovalRange: this.get('typeCurrentCapApprovalRange'),
      baseMaterial1: this.get('baseMaterial1ApprovalRange'),
      baseMaterial2: this.get('baseMaterial2ApprovalRange'),
      fillerMaterialRootCommercialDesignationApprovalRange: this.get(
        'fillerMaterialRootCommercialDesignationApprovalRange'
      ),

      fillerMaterialFillCommercialDesignationApprovalRange: this.get(
        'fillerMaterialFillCommercialDesignationApprovalRange'
      ),

      fillerMaterialCapCommercialDesignationApprovalRange: this.get(
        'fillerMaterialCapCommercialDesignationApprovalRange'
      ),

      shieldingRootGasApprovalRange: this.get('shieldingRootGasApprovalRange'),
      shieldingRootGasCommercialDesignationApprovalRange: this.get(
        'shieldingRootGasCommercialDesignationApprovalRange'
      ),

      shieldingRootFluxApprovalRange: this.get('shieldingRootFluxApprovalRange'),
      shieldingRootFluxCommercialDesignationApprovalRange: this.get(
        'shieldingRootFluxCommercialDesignationApprovalRange'
      ),

      shieldingFillGasApprovalRange: this.get('shieldingFillGasApprovalRange'),
      shieldingFillGasCommercialDesignationApprovalRange: this.get(
        'shieldingFillGasCommercialDesignationApprovalRange'
      ),

      shieldingFillFluxApprovalRange: this.get('shieldingFillFluxApprovalRange'),
      shieldingFillFluxCommercialDesignationApprovalRange: this.get(
        'shieldingFillFluxCommercialDesignationApprovalRange'
      ),

      shieldingCapGasApprovalRange: this.get('shieldingCapGasApprovalRange'),
      shieldingCapGasCommercialDesignationApprovalRange: this.get(
        'shieldingCapGasCommercialDesignationApprovalRange'
      ),

      shieldingCapFluxApprovalRange: this.get('shieldingCapFluxApprovalRange'),
      shieldingCapFluxCommercialDesignationApprovalRange: this.get(
        'shieldingCapFluxCommercialDesignationApprovalRange'
      ),

      backingGasApprovalRange: this.get('backingGasApprovalRange'),
      backingGasCommercialDesignationApprovalRange: this.get(
        'backingGasCommercialDesignationApprovalRange'
      ),

      backingFluxApprovalRange: this.get('backingFluxApprovalRange'),
      backingFluxCommercialDesignationApprovalRange: this.get(
        'backingFluxCommercialDesignationApprovalRange'
      ),
    });
  }

  clearCollection(collection: any) {
    assert(`Must pass a valid collection name - ${collection}`, !isEmpty(collection));
    get(this, collection).clear();
  }

  isButtWeld() {
    let weldType = this.weldTypeTestPiece;
    if (weldType && !isEmpty(weldType)) {
      return weldType.isButtWeld;
    }
    return false;
  }

  get weldType() {
    return get(this, 'weldTypeTestPiece');
  }

  isFilletWeld() {
    return isFilletWeld(this.weldType);
  }

  isCompletePenetrationButtWeld() {
    return isCompleteJointPenetrationButtWeld(this.weldType);
  }

  isPartialPenetrationButtWeld() {
    return isPartialPenetrationButtWeldASME(this.weldType);
  }

  hasImpactRequirements() {
    let temperature = this.impactTestTemperature;
    let required = this.impactTestRequired;
    return (!isEmpty(temperature) && !isNaN(parseFloat(`${temperature}`))) || required;
  }

  isOverlayWeldHardFacing() {
    let { overlayType } = this;
    if (!isEmpty(overlayType) && overlayType === OVERLAY_TYPE_OPTIONS.HARD_FACING) {
      return true;
    }
    return false;
  }

  get weldingDetails() {
    return get(this, 'weldingDetailsTestPiece');
  }

  isSingleLayer() {
    return isSingleLayer(this.weldingDetails);
  }

  isMultiLayer() {
    return isMultiLayer(this.weldingDetails);
  }

  getLayerType() {
    if (this.isSingleLayer()) {
      return LAYER_OPTIONS.SINGLE_LAYER;
    } else if (this.isMultiLayer()) {
      return LAYER_OPTIONS.MULTI_LAYER;
    }
    return null;
  }

  validateMinimum(propertyMaximumValue: any, value: string) {
    let maximum = parseFloat(get(this, propertyMaximumValue));
    if (!isEmpty(maximum) && !isNaN(maximum)) {
      let minimum = parseFloat(value);
      return minimum <= maximum;
    }
    return true;
  }

  validateMaximum(propertyMinimumValue: any, value: string) {
    let minimum = parseFloat(get(this, propertyMinimumValue));
    if (!isEmpty(minimum) && !isNaN(minimum)) {
      let maximum = parseFloat(value);
      return maximum >= minimum;
    }
    return true;
  }

  getBelowMaximumMessage(approvalRange: string | number | undefined, unit = '') {
    return this.translate('model-validations.wps-parameter.must-be-below-maximum', {
      approvalRange,
      unit,
    });
  }

  getAboveMinimumMessage(approvalRange: string | number | undefined, unit: string) {
    return this.translate('model-validations.wps-parameter.must-be-above-minimum', {
      approvalRange,
      unit,
    });
  }

  @computed('weldingDetailsTestPiece.[]')
  get isDetailBothSides() {
    return isBothSides(this.weldingDetails);
  }

  get isOverlayWeld() {
    let weldType = get(this, 'weldTypeTestPiece');
    if (weldType && !isEmpty(weldType)) {
      return weldType.isOverlayWeld;
    }
    return false;
  }

  get isPipe() {
    return this.productTypeTestPiece === PRODUCT_TYPE.PIPE;
  }

  get isPlate() {
    return this.productTypeTestPiece === PRODUCT_TYPE.PLATE;
  }

  get isArchived() {
    return this.archivalState === ARCHIVAL_STATE.ARCHIVED;
  }

  get isActive() {
    return this.archivalState === ARCHIVAL_STATE.ACTIVE;
  }

  createReport = memberAction({ path: 'createReport', type: 'PUT' });

  archive = memberAction({ path: 'archivePQR', type: 'PUT' });

  unarchive = memberAction({ path: 'unarchivePQR', type: 'PUT' });

  duplicate = memberAction({ path: 'duplicatePQR', type: 'PUT' });
}

export default PQRModel;
