import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

export function notInCollectionById(collection, values) {
  let notInCollection = [];
  if (!isEmpty(collection)) {
    values.forEach((v) => {
      let result = collection.findBy('id', get(v, 'id'));
      if (isEmpty(result)) {
        notInCollection.pushObject(v);
      }
    });
  }
  return notInCollection;
}

export function serializeCollection(collection, attribute, join = ', ') {
  if (!isEmpty(collection)) {
    return collection
      .map((item) => {
        return `${get(item, attribute)}`;
      })
      .join(join);
  }
  return '';
}

export function valueInCollectionById(collection, idValue) {
  if (!isEmpty(collection) && !isEmpty(idValue)) {
    let result = collection.findBy('id', idValue);
    return !isEmpty(result);
  }
  // When the collection is empty we accept any value
  return true;
}

export function collectionInCollectionById(collection, values) {
  let inApprovalRange = true;
  if (!isEmpty(collection)) {
    values.forEach((v) => {
      let result = collection.findBy('id', get(v, 'id'));
      if (isEmpty(result)) {
        inApprovalRange = false;
      }
    });
  }
  return inApprovalRange;
}
