import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class PlatePiece extends BaseModel {
  @attr('decimal-value')
  declare thickness?: number;

  @attr('decimal-value')
  declare thicknessInches?: number;

  @attr('boolean')
  declare system?: boolean;

  get visualLabel() {
    return this.thickness;
  }

  validations = {
    thickness: {
      numericality: {
        allowBlank: false,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    thicknessInches: {
      numericality: {
        allowBlank: false,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },
  };

  metadata = {
    modelName: 'plate-piece',
    thickness: { required: true },
    thicknessInches: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'plate-piece': PlatePiece;
  }
}
