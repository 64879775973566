import { action } from '@ember/object';
import Controller from '@ember/controller';
import { service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import WelderCertificate from 'weldnote/models/welder-certificate';

export default class WelderCertificateViewController extends Controller {
  @service
  declare router: RouterService;

  @action
  editCertificate(certificate: string) {
    this.router.transitionTo('welder-certificate', certificate);
  }

  @action
  goToNewCertificate(result: WelderCertificate) {
    this.router.transitionTo('welder-certificate', result.id);
  }

  @action
  deleteInstance() {
    this.router.transitionTo('welder-certificates');
  }
}
