import { attr, belongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import IndustryCode from './industry-code';
import Constants from 'weldnote/utils/constants';

const { ARC_TRANSFER_MODES } = Constants;

export default class ArcTransferMode extends BaseModel {
  @attr('string')
  declare transferMode: string;

  @belongsTo('industry-code', { async: false })
  declare industryCode: IndustryCode;

  @attr('boolean')
  declare system: boolean;

  get isShortArc(): boolean {
    return this.transferMode === ARC_TRANSFER_MODES.SHORT_ARC;
  }

  get isGlobular(): boolean {
    return this.transferMode === ARC_TRANSFER_MODES.GLOBULAR;
  }

  get isSpray(): boolean {
    return this.transferMode === ARC_TRANSFER_MODES.SPRAY;
  }

  get isPulsedSpray(): boolean {
    return this.transferMode === ARC_TRANSFER_MODES.PULSED_SPRAY;
  }

  get visualLabel(): string {
    return this.transferMode || '';
  }

  validations = {
    transferMode: {
      presence: {
        message(_key: string, _value: string, model: ArcTransferMode): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    industryCode: {
      presence: {
        message(_key: string, _value: string, model: ArcTransferMode): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'arc-transfer-mode',
    transferMode: {
      required: true,
    },

    industryCode: {
      required: true,
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'arc-transfer-mode': ArcTransferMode;
  }
}
