import { service } from '@ember/service';
import { debounce } from '@ember/runloop';
import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';
import { set, get, action, computed } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list';
import CertificateRules from 'weldnote/utils/welder-certificate-rules';
import { convertDecimals } from 'weldnote/utils/decimal-utils';
import Constants from 'weldnote/utils/constants';
import filterFromCollectionByKey from 'weldnote/macros/find-from-collection-by-key';
import Env from 'weldnote/config/environment';
import { task, timeout } from 'ember-concurrency';
import { toNumber, isFraction } from 'weldnote/utils/unit-system/units';
import { isWelderStandardISO96061 } from 'weldnote/utils/standards';
import { tracked } from '@glimmer/tracking';
import WelderStandard from 'weldnote/models/welder-standard';
import WeldType from 'weldnote/models/weld-type';
import InstanceTrackerService from 'weldnote/services/instance-tracker';
import { AjaxServiceClass } from 'ember-ajax/services/ajax';
import UserSessionService from 'weldnote/services/user-session';
import WeldcloudData from 'weldnote/services/weldcloud-data';
import WeldingProcess from 'weldnote/models/welding-process';
import WeldingPosition from 'weldnote/models/welding-position';
import ConstructionStandard from 'weldnote/models/construction-standard';
import WeldingDetail from 'weldnote/models/welding-detail';
import ExaminingBody from 'weldnote/models/examining-body';
import Company from 'weldnote/models/company';
import BaseMaterialGroup from 'weldnote/models/base-material-group';
import FillerMaterial from 'weldnote/models/filler-material';
import Welder from 'weldnote/models/welder';
import CertificationSpecialRequirement from 'weldnote/models/certification-special-requirement';
import FillerMaterialGroup from 'weldnote/models/filler-material-group';
import ProcessMecanization from 'weldnote/models/process-mecanization';
import FillerMaterialSpecification from 'weldnote/models/filler-material-specification';
import FillerMaterialClassification from 'weldnote/models/filler-material-classification';
import BaseModel from 'weldnote/models/base-model';
import AnalyticsService from 'weldnote/services/analytics';
import HelpService from 'weldnote/services/help';
import WindowService from 'weldnote/services/window-service';
import { GridColumn } from 'gridcolumn';
import DS from 'ember-data';

const { WELDER_CERTIFICATE } = CertificateRules;

const { WELD_TYPES, UNIT_SYSTEM } = Constants;

export default class WelderCertificatesController extends ListController {
  @service
  declare weldnoteData: any;

  get data() {
    return this.weldnoteData;
  }

  @service
  declare scroller: any;

  declare instanceTracker: InstanceTrackerService;

  get tracker() {
    return this.instanceTracker;
  }

  @service
  declare userSession: UserSessionService;

  @service
  declare session: any;

  @service
  declare ajax: AjaxServiceClass;

  @service
  declare help: HelpService;

  @service
  declare analytics: AnalyticsService;

  @service
  declare store: DS.Store;

  @service('weldcloud-data')
  declare weldCloudData: WeldcloudData; // FIXME?

  @service
  declare windowService: WindowService;

  @tracked
  showAdvancedParameters = false;

  @tracked
  showSearchParameters = false;

  get standardOptions() {
    return this.data.welderStandardOptions;
  }

  get weldingDetailOptions() {
    return this.data.weldingDetailOptions;
  }

  get weldTypeOptionsAll() {
    return this.data.weldTypeOptions;
  }

  get weldingProcessesAll() {
    return this.data.weldingProcessOptions;
  }

  get weldingPositionsAll() {
    return this.data.weldingPositionOptions;
  }

  get examiningBodyOptions() {
    return this.data.examiningBodyOptions;
  }

  get companyOptions() {
    return this.data.companyOptions;
  }

  get specialRequirementOptions() {
    return this.data.specialRequirementOptions;
  }

  @computed('selectedStandard')
  get currentIndustryCode() {
    if (this.selectedStandard) {
      return get(this.selectedStandard, 'code');
    }
    return null;
  }

  get distanceUnit() {
    return this.userSession.distanceUnit;
  }

  get decimalSeparator() {
    return this.userSession.decimalSeparator;
  }

  @filterFromCollectionByKey('weldTypeOptionsAll', 'industryCode.id', 'currentIndustryCode.id')
  declare weldTypeOptions: any;

  @filterFromCollectionByKey('weldingProcessesAll', 'industryCode.id', 'currentIndustryCode.id')
  declare weldingProcessOptions: WeldingProcess[];

  @filterFromCollectionByKey('weldingPositionsAll', 'industryCode.id', 'currentIndustryCode.id')
  declare weldingPositionOptions: WeldingPosition[];

  get fieldsDisabled() {
    return isEmpty(this.selectedStandard);
  }

  get isStandardSelected() {
    return this.selectedStandard && !isEmpty(this.selectedStandard);
  }

  @tracked
  selectedStandard: WelderStandard | null = null;

  @tracked
  selectedWeldType: WeldType | null = null;

  @tracked
  selectedConstructionStandard: ConstructionStandard | null = null;

  @tracked selectedDetails: WeldingDetail[] = [];

  @tracked
  selectedExaminingBody: ExaminingBody | null = null;

  @tracked
  selectedCompanies: Company[] = [];

  @tracked
  selectedProcessRoot: WeldingProcess | null = null;

  @tracked
  selectedProcessFill: WeldingProcess | null = null;

  @tracked
  selectedProcessCap: WeldingProcess | null = null;

  @tracked
  diameter: string = '';

  @tracked
  diameterDisplay: string = '';

  @tracked
  thickness: string = '';

  @tracked
  thicknessDisplay: string = '';

  @tracked
  selectedProductType: string | null = null;

  @tracked
  selectedWeldingPositionRoot: WeldingPosition | null = null;

  @tracked
  selectedWeldingPositionFill: WeldingPosition | null = null;

  @tracked
  selectedWeldingPositionCap: WeldingPosition | null = null;

  @tracked
  thicknessRoot: string = '';

  @tracked
  thicknessRootDisplay: string = '';

  @tracked
  thicknessFill: string = '';

  @tracked
  thicknessFillDisplay: string = '';

  @tracked
  thicknessCap: string = '';

  @tracked
  thicknessCapDisplay: string = '';

  @tracked
  selectedBaseMaterial1Group: BaseMaterialGroup | null = null;

  @tracked
  selectedBaseMaterial2Group: BaseMaterialGroup | null = null;

  @tracked
  selectedFillerMaterialRoot: FillerMaterial | null = null;

  @tracked
  selectedFillerMaterialFill: FillerMaterial | null = null;

  @tracked
  selectedFillerMaterialRootSpecification: FillerMaterialSpecification | null = null;

  @tracked
  selectedFillerMaterialRootClassification: FillerMaterialClassification | null = null;

  @tracked
  selectedFillerMaterialFillSpecification: FillerMaterialSpecification | null = null;

  @tracked
  selectedFillerMaterialFillClassification: FillerMaterialClassification | null = null;

  @tracked selectedWelders: Welder[] = [];

  @tracked
  selectedShowExpired: boolean = false;

  @tracked
  selectedShowArchived: boolean = false;

  @tracked selectedSpecialRequirements: CertificationSpecialRequirement[] = [];

  @tracked
  selectedOverlayType: string | null = null;

  @tracked
  certificateNumber: string = '';

  @tracked
  selectedFillerMaterialRootGroup: FillerMaterialGroup | null = null;

  @tracked
  selectedFillerMaterialFillGroup: FillerMaterialGroup | null = null;

  @tracked
  selectedFillerMaterialCapGroup: FillerMaterialGroup | null = null;

  @tracked
  selectedProcessMecanizationRoot: ProcessMecanization | null = null;

  @tracked
  selectedProcessMecanizationFill: ProcessMecanization | null = null;

  @tracked
  selectedProcessMecanizationCap: ProcessMecanization | null = null;

  get isISO96061() {
    let { selectedStandard } = this;
    if (selectedStandard && !isEmpty(selectedStandard)) {
      return isWelderStandardISO96061(selectedStandard);
    }
    return true;
  }

  get productTypeOptions() {
    if (this.selectedStandard && !isEmpty(this.selectedStandard)) {
      if (isWelderStandardISO96061(this.selectedStandard)) {
        return this.data.productTypeOptionsCertificatesIso96061Options;
      }
    }
    return this.data.productTypeOptions;
  }

  get isOverlayWeld() {
    return this.selectedWeldType && this.selectedWeldType.isOverlayWeld;
  }

  get overlayTypeOptions() {
    return this.data.overlayTypeOptions;
  }

  get processMecanizationOptions() {
    return this.data.processMecanizationOptions;
  }

  public modelName = 'welder-certificate';

  getGridColumnLabel(label: string, options = {}) {
    return this.intl.t(`certificate-list.grid-columns.${label}`, options);
  }

  get gridColumns(): GridColumn[] {
    let certificateNumberLabel = this.getGridColumnLabel('certificate-number');
    let stampLabel = this.getGridColumnLabel('stamp');
    let weldTypeLabel = this.getGridColumnLabel('weld-type');
    let weldingProcessesLabel = this.getGridColumnLabel('welding-processes');
    let weldingPositionsLabel = this.getGridColumnLabel('welding-positions');
    let diameterLabel = this.getGridColumnLabel('diameter', { unit: get(this, 'distanceUnit') });
    let weldingDetailsLabel = this.getGridColumnLabel('welding-details');
    let totalThicknessLabel = this.getGridColumnLabel('total-thickness', {
      unit: get(this, 'distanceUnit'),
    });
    let baseMaterialsLabel = this.getGridColumnLabel('base-materials');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet', 'laptop'],
      },
      {
        valuePath: 'certificateNumber',
        label: certificateNumberLabel,
        width: '250px',
        cellComponent: 'weldnote-grid/welder-certificate-link',
        sortable: false,
      },
      {
        valuePath: 'welderStamp',
        label: stampLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/welder-in-certificate',
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'weldType',
        label: weldTypeLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/weld-type',
        breakpoints: ['tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'weldingProcesses',
        label: weldingProcessesLabel,
        cellComponent: 'weldnote-grid/welding-processes',
        sortable: false,
        breakpoints: ['tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'weldingPositions',
        label: weldingPositionsLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'weldingDetails',
        label: weldingDetailsLabel,
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'baseMaterials',
        label: baseMaterialsLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'diameter',
        align: 'right',
        width: '150px',
        label: diameterLabel,
        breakpoints: ['desktop'],
        sortable: false,
      },
      {
        valuePath: 'totalThickness',
        align: 'right',
        width: '200px',
        label: totalThicknessLabel,
        breakpoints: ['desktop'],
        sortable: false,
      },
    ];
  }

  clearProperties() {
    this.setProperties({
      selectedStandard: null,
      selectedConstructionStandard: null,
      selectedDetails: [],
      selectedExaminingBody: null,
      selectedCompanies: null,
      selectedWeldType: null,
      selectedProcessRoot: null,
      selectedProcessFill: null,
      selectedProcessCap: null,
      diameter: null,
      diameterDisplay: null,
      thickness: null,
      thicknessDisplay: null,
      selectedProductType: null,
      selectedWeldingPositionRoot: null,
      selectedWeldingPositionFill: null,
      selectedWeldingPositionCap: null,
      thicknessRoot: null,
      thicknessRootDisplay: null,
      thicknessFill: null,
      thicknessFillDisplay: null,
      thicknessCap: null,
      thicknessCapDisplay: null,
      selectedBaseMaterial1Group: null,
      selectedBaseMaterial2Group: null,
      selectedFillerMaterialRoot: null,
      selectedFillerMaterialFill: null,
      selectedFillerMaterialRootSpecification: null,
      selectedFillerMaterialRootClassification: null,
      selectedFillerMaterialFillSpecification: null,
      selectedFillerMaterialFillClassification: null,
      selectedWelders: [],
      selectedShowExpired: null,
      selectedShowArchived: null,
      selectedSpecialRequirements: [],
      selectedOverlayType: null,
      certificateNumber: null,
      selectedFillerMaterialRootGroup: null,
      selectedFillerMaterialFillGroup: null,
      selectedFillerMaterialCapGroup: null,
      selectedProcessMecanizationRoot: null,
      selectedProcessMecanizationFill: null,
      selectedProcessMecanizationCap: null,
    });
    this.resetPage();
    this.loadData.perform();
  }

  @tracked
  currentPage = 1;

  @tracked
  totalCount = 0;

  resetPage() {
    set(this, 'currentPage', 1);
  }

  searchCertificates(pageNumber: number) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform({
      userSearch: true,
    });
  }

  loadData = task(
    {
      restartable: true,
    },
    async (otherOptions = {}) => {
      let options: any = {};

      if (otherOptions.userSearch) {
        options.userSearch = true;
      }

      options.page = get(this, 'currentPage');

      await timeout(600);
      let data = await this.data.listAllWelderCertificates(options, this.buildFilters());
      set(this, 'model', data.list);
      set(this, 'totalCount', data.count);
    }
  );

  buildSingleRelationFilter(
    controllerProperty: BaseModel | null,
    apiProperty: string,
    filters: any
  ) {
    if (controllerProperty) {
      let result = get(controllerProperty, 'id');
      filters[apiProperty] = result;
    }
  }

  buildMultiRelationFilter(controllerProperty: BaseModel[], apiProperty: string, filters: any) {
    if (controllerProperty) {
      let result = controllerProperty.map((m) => m.get('id')).join(',');
      filters[apiProperty] = result;
    }
  }

  buildSingleValueFilter(controllerProperty: any | null, apiProperty: string, filters: any) {
    if (controllerProperty) {
      let result = get(this, controllerProperty);
      if (result) {
        filters[apiProperty] = result;
      }
    }
  }

  buildDecimalFilter(controllerProperty: any, apiProperty: string, filters: any) {
    if (!isEmpty(this.get(controllerProperty))) {
      let decimalValue = this.get(controllerProperty);
      let convertedValue = convertDecimals(decimalValue, this.userSession.decimalSeparator);
      filters[apiProperty] = convertedValue;
    }
  }

  buildFilters() {
    let filters: any = {};

    this.buildSingleRelationFilter(this.selectedStandard, WELDER_CERTIFICATE.STANDARD, filters);
    this.buildSingleRelationFilter(
      this.selectedConstructionStandard,
      WELDER_CERTIFICATE.CONSTRUCTION_STANDARD,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedExaminingBody,
      WELDER_CERTIFICATE.EXAMINING_BODY,
      filters
    );
    this.buildMultiRelationFilter(this.selectedCompanies, WELDER_CERTIFICATE.COMPANY, filters);
    this.buildSingleRelationFilter(
      this.selectedWeldType,
      WELDER_CERTIFICATE.WELD_TYPE_TEST_PIECE,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedProcessRoot,
      WELDER_CERTIFICATE.WELDING_PROCESS_ROOT_TEST_PIECE,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedProcessFill,
      WELDER_CERTIFICATE.WELDING_PROCESS_FILL_TEST_PIECE,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedProcessCap,
      WELDER_CERTIFICATE.WELDING_PROCESS_CAP_TEST_PIECE,
      filters
    );
    this.buildDecimalFilter('diameter', WELDER_CERTIFICATE.DIAMETER_TEST_PIECE, filters);
    this.buildDecimalFilter('thickness', WELDER_CERTIFICATE.THICKNESS_TOTAL_TEST_PIECE, filters);
    this.buildSingleRelationFilter(
      this.selectedWeldingPositionRoot,
      WELDER_CERTIFICATE.WELDING_POSITION_ROOT_TEST_PIECE,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedWeldingPositionFill,
      WELDER_CERTIFICATE.WELDING_POSITION_FILL_TEST_PIECE,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedWeldingPositionCap,
      WELDER_CERTIFICATE.WELDING_POSITION_CAP_TEST_PIECE,
      filters
    );
    this.buildSingleValueFilter(
      'selectedProductType.key',
      WELDER_CERTIFICATE.PRODUCT_TYPE_TEST_PIECE,
      filters
    );
    this.buildSingleValueFilter(
      this.selectedBaseMaterial1Group,
      WELDER_CERTIFICATE.BASE_MATERIAL1_TEST_PIECE,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedBaseMaterial2Group,
      WELDER_CERTIFICATE.BASE_MATERIAL2_TEST_PIECE,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedFillerMaterialRoot,
      WELDER_CERTIFICATE.FILLER_MATERIAL_ROOT_TEST_PIECE,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedFillerMaterialFill,
      WELDER_CERTIFICATE.FILLER_MATERIAL_FILL_TEST_PIECE,
      filters
    );
    this.buildSingleValueFilter(
      'selectedOverlayType.key',
      WELDER_CERTIFICATE.OVERLAY_TYPE,
      filters
    );
    this.buildSingleValueFilter(
      'certificateNumber',
      WELDER_CERTIFICATE.CERTIFICATE_NUMBER,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedFillerMaterialRootGroup,
      WELDER_CERTIFICATE.FILLER_MATERIAL_ROOT_APPROVAL_RANGE,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedFillerMaterialFillGroup,
      WELDER_CERTIFICATE.FILLER_MATERIAL_FILL_APPROVAL_RANGE,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedFillerMaterialCapGroup,
      WELDER_CERTIFICATE.FILLER_MATERIAL_CAP_APPROVAL_RANGE,
      filters
    );

    this.buildSingleRelationFilter(
      this.selectedProcessMecanizationRoot,
      WELDER_CERTIFICATE.PROCESS_MECANIZATION_ROOT_TEST_PIECE,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedProcessMecanizationFill,
      WELDER_CERTIFICATE.PROCESS_MECANIZATION_FILL_TEST_PIECE,
      filters
    );
    this.buildSingleRelationFilter(
      this.selectedProcessMecanizationCap,
      WELDER_CERTIFICATE.PROCESS_MECANIZATION_CAP_TEST_PIECE,
      filters
    );

    if (this.selectedShowArchived && this.selectedShowExpired) {
      filters._all = '_all';
    } else if (this.selectedShowExpired) {
      filters._activeExpired = '_activeExpired';
    } else if (this.selectedShowArchived) {
      filters._activeArchived = '_activeArchived';
    }

    this.buildDecimalFilter('thicknessRoot', WELDER_CERTIFICATE.THICKNESS_ROOT_TEST_PIECE, filters);
    this.buildDecimalFilter('thicknessFill', WELDER_CERTIFICATE.THICKNESS_FILL_TEST_PIECE, filters);
    this.buildDecimalFilter('thicknessCap', WELDER_CERTIFICATE.THICKNESS_CAP_TEST_PIECE, filters);

    if (!isEmpty(this.selectedDetails)) {
      let detailsSerialized = this.selectedDetails
        .map((item) => {
          return `${item.get('id')}`;
        })
        .join(',');
      filters[WELDER_CERTIFICATE.WELDING_DETAIL_TEST_PIECE] = detailsSerialized;
    }

    if (!isEmpty(this.selectedCompanies)) {
      let companiesSerialized = this.selectedCompanies
        .map((item) => {
          return `${item.get('id')}`;
        })
        .join(',');
      filters[WELDER_CERTIFICATE.COMPANY] = companiesSerialized;
    }

    if (!isEmpty(this.selectedWelders)) {
      let weldersSerialized = this.selectedWelders
        .map((item) => {
          return `${item.get('id')}`;
        })
        .join(',');
      filters[WELDER_CERTIFICATE.WELDER] = weldersSerialized;
    }

    if (!isEmpty(this.selectedSpecialRequirements)) {
      let requirementsSerialized = this.selectedSpecialRequirements
        .map((item) => {
          return `${item.get('id')}`;
        })
        .join(',');
      filters[WELDER_CERTIFICATE.SPECIAL_REQUIREMENTS] = requirementsSerialized;
    }

    return filters;
  }

  _searchWelders(term: string, resolve: any, reject: any) {
    if (isEmpty(term)) {
      return resolve([]);
    }
    this.weldnoteData.searchWelder(term).then(
      (data: any) => resolve(data),
      (err: any) => reject(err)
    );
  }

  scrollToSearchResults() {
    this.scroller.scrollVertical('#certificate-search-grid');
  }

  @action
  updateStandard(selectedStandard: WelderStandard) {
    this.clearProperties();
    set(this, 'selectedStandard', selectedStandard);
  }

  get currentIndustryCodeId() {
    if (this.currentIndustryCode) {
      return get(this.currentIndustryCode, 'id');
    }
    return null;
  }

  @action
  searchBaseMaterialGroup(term: string) {
    return this.data.findBaseMaterialGroup(term, this.currentIndustryCodeId);
  }

  @action
  searchFillerMaterialGroup(term: string) {
    return this.data.searchFillerMaterialGroupByCode(term, this.currentIndustryCodeId);
  }

  @action
  searchWelders(term: string) {
    return new RSVP.Promise((resolve, reject) => {
      debounce(this, this._searchWelders, term, resolve, reject, 500);
    });
  }

  @action
  clearSearch() {
    this.clearProperties();
  }

  @action
  search(pageNumber: number) {
    set(this, 'currentPage', pageNumber);
    this.searchCertificates(pageNumber);
    this.scrollToSearchResults();
  }

  @action
  createNew() {
    this.router.transitionTo('welder-certificate.new');
  }

  @action
  setSelectedWeldType(type: WeldType) {
    if (isEmpty(type)) {
      set(this, 'selectedOverlayType', null);
      set(this, 'selectedWeldType', null);
    } else {
      set(this, 'selectedWeldType', type);
      if (type.get('weldType') !== WELD_TYPES.OVERLAY_WELD) {
        set(this, 'selectedOverlayType', null);
      }
    }
  }

  @action
  download() {
    let filters = this.buildFilters();
    filters.exportType = 'certificate';
    this.ajax
      .request('/export/get-token', {
        method: 'POST',
        data: JSON.stringify(filters),
        dataType: 'json',
      })
      .then((data) => {
        let namespace = this.store.adapterFor('application').get('namespace');
        let host = `${location.protocol}//${location.hostname}/${namespace}`;
        if (!isEmpty(Env.apiHost)) {
          host = Env.apiHost;
        }
        this.windowService.downloadFile(`${host}/export/download?token=${data}`);
      });
  }

  @action
  setCertificateNumber(number: string) {
    set(this, 'certificateNumber', number);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setThickness(value: string) {
    set(this, 'thicknessDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        this.thickness = `${toNumber(value, this.decimalSeparator)}`;
      } else {
        this.thickness = value;
      }
    } else {
      this.thickness = value;
    }
  }

  @action
  setDiameter(value: string) {
    set(this, 'diameterDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        this.diameter = `${toNumber(value, this.decimalSeparator)}`;
      } else {
        this.diameter = value;
      }
    } else {
      this.diameter = value;
    }
  }

  @action
  setThicknessRoot(value: string) {
    set(this, 'thicknessRootDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        this.thicknessRoot = `${toNumber(value, this.decimalSeparator)}`;
      } else {
        this.thicknessRoot = value;
      }
    } else {
      this.thicknessRoot = value;
    }
  }

  @action
  setThicknessFill(value: string) {
    set(this, 'thicknessFillDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        this.thicknessFill = `${toNumber(value, this.decimalSeparator)}`;
      } else {
        this.thicknessFill = value;
      }
    } else {
      this.thicknessFill = value;
    }
  }

  @action
  setThicknessCap(value: string) {
    set(this, 'thicknessCapDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        this.thicknessCap = `${toNumber(value, this.decimalSeparator)}`;
      } else {
        this.thicknessCap = value;
      }
    } else {
      this.thicknessCap = value;
    }
  }

  @tracked
  showVideo = false;

  @action
  displayHelpVideo() {
    set(this, 'showVideo', true);
    let { analytics, help } = this;
    analytics.trackEvent(help.convertVideoIdToAnalyticsEvent(help.wpqVideo), {
      location: 'welder-certificate-list',
    });
  }

  @action
  setShowExpired(value: boolean) {
    set(this, 'selectedShowExpired', value);
    this.searchCertificates(1);
    this.scrollToSearchResults();
  }

  @action
  setShowArchived(value: boolean) {
    set(this, 'selectedShowArchived', value);
    this.searchCertificates(1);
    this.scrollToSearchResults();
  }
}
