import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { set, get, computed } from '@ember/object';
import { task } from 'ember-concurrency';

@classic
export default class ArchivedController extends Controller {
  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  queryParams = ['page'];

  page = 1;

  @service
  welderCertificateData;

  @alias('model.list')
  certificates;

  @alias('model.count')
  certificateCount;

  loadData = task(async (pageNumber = 1) => {
    let results = await this.welderCertificateData.getWelderCertificatesArchived({
      page: pageNumber,
    });
    set(this, 'model', results);
  });

  @computed
  get gridColumns() {
    let { intl } = this;

    let certificateNumberLabel = intl.t('certificate-list.grid-columns.certificate-number');
    let stampLabel = intl.t('certificate-list.grid-columns.stamp');
    let weldTypeLabel = intl.t('certificate-list.grid-columns.weld-type');
    let weldingProcessesLabel = intl.t('certificate-list.grid-columns.welding-processes');
    let weldingPositionsLabel = intl.t('certificate-list.grid-columns.welding-positions');
    let diameterLabel = intl.t('certificate-list.grid-columns.diameter', {
      unit: this.userSession.distanceUnit,
    });
    let weldingDetailsLabel = intl.t('certificate-list.grid-columns.welding-details');
    let totalThicknessLabel = intl.t('certificate-list.grid-columns.total-thickness', {
      unit: this.userSession.distanceUnit,
    });
    let baseMaterialsLabel = intl.t('certificate-list.grid-columns.base-materials');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet', 'laptop'],
      },
      {
        valuePath: 'certificateNumber',
        label: certificateNumberLabel,
        width: '250px',
        cellComponent: 'weldnote-grid/welder-certificate-link-archive',
        sortable: false,
      },
      {
        valuePath: 'welderStamp',
        label: stampLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/welder-in-certificate',
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'weldType',
        label: weldTypeLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/weld-type',
        breakpoints: ['tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'weldingProcesses',
        label: weldingProcessesLabel,
        cellComponent: 'weldnote-grid/welding-processes',
        sortable: false,
        breakpoints: ['tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'weldingPositions',
        label: weldingPositionsLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'weldingDetails',
        label: weldingDetailsLabel,
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'baseMaterials',
        label: baseMaterialsLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'diameter',
        align: 'right',
        width: '150px',
        label: diameterLabel,
        breakpoints: ['desktop'],
        sortable: false,
      },
      {
        valuePath: 'totalThickness',
        align: 'right',
        width: '200px',
        label: totalThicknessLabel,
        breakpoints: ['desktop'],
        sortable: false,
      },
    ];
  }
}
