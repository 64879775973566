import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from '@ember/object';

export default class AccountAdminRoute extends Route {
  @service
  session;

  @service
  userSession;

  @service
  router;

  @service('weldnote-data')
  data;

  beforeModel(transition) {
    super.beforeModel(...arguments);
    this.session.requireAuthentication(transition, 'login');
    let session = this.userSession;
    if (!get(session, 'isAccountAdmin')) {
      this.router.transitionTo('index');
    }
  }

  model() {
    return this.data.getAccounts();
  }
}
