import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';
import Constants from 'weldnote/utils/constants';
import WeldType from 'weldnote/models/weld-type';

const { WELD_TYPES } = Constants;

function is(weldType: WeldType | null | undefined, toCheck: string): boolean {
  if (weldType && !isEmpty(weldType) && !isEmpty(get(weldType, 'weldType'))) {
    return get(weldType, 'weldType') === toCheck;
  }
  return false;
}

export function isFilletWeld(weldType: WeldType | null | undefined): boolean {
  return is(weldType, WELD_TYPES.FILLET_WELD);
}

export function isButtWeld(weldType: WeldType): boolean {
  return is(weldType, WELD_TYPES.BUTT_WELD);
}

export function isATypeOfButtWeld(weldType: WeldType): boolean {
  return (
    isButtWeld(weldType) ||
    isPartialPenetrationButtWeld(weldType) ||
    isCompleteJointPenetrationButtWeld(weldType) ||
    isPartialJointPenetrationButtWeld(weldType)
  );
}

export function isPartialJointPenetrationButtWeld(weldType: WeldType): boolean {
  return is(weldType, WELD_TYPES.PARTIAL_JOINT_PENETRATION_BUTT_WELD);
}

export function isCompleteJointPenetrationButtWeld(weldType: WeldType | null | undefined): boolean {
  return is(weldType, WELD_TYPES.COMPLETE_JOINT_PENETRATION_BUTT_WELD);
}

export function isBranchConnection(weldType: WeldType): boolean {
  return is(weldType, WELD_TYPES.BRANCH_CONNECTION);
}

export function isOverlayWeld(weldType: WeldType): boolean {
  return is(weldType, WELD_TYPES.OVERLAY_WELD);
}

export function isPartialPenetrationButtWeldASME(weldType: WeldType | null | undefined): boolean {
  return is(weldType, WELD_TYPES.PARTIAL_PENETRATION_BUTT_WELD);
}

export function isPartialPenetrationButtWeldISO(weldType: WeldType): boolean {
  return is(weldType, WELD_TYPES.PARTIAL_PENETRATION_BUTT_WELD);
}

export function isPartialPenetrationButtWeld(weldType: WeldType): boolean {
  return is(weldType, WELD_TYPES.PARTIAL_PENETRATION_BUTT_WELD);
}
