import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ProjectWeld extends Route {
  @service session;

  @service
  store;

  @service
  router;

  model(params) {
    return this.store.findRecord('project-weld', params.weld_id, { reload: true });
  }

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  afterModel(model) {
    this.router.transitionTo('project.welds.weld', get(model, 'project.id'), model.id, {
      queryParams: { section: 'welds' },
    });
  }
}
