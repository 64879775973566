export default {
  WELD_LAYER_CONFIGURATION: {
    DESCRIPTION: 'description',
    LONG_DESCRIPTION: 'longDescription',
    UPLOAD: 'upload',
    WELDING_DETAILS: 'weldingDetails',
    WELD_TYPES: 'weldTypes',
    GROOVE_TYPE: 'grooveType',
    JOINT_TYPE: 'jointType',
  },
};
