import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { get } from '@ember/object';
import ListRoute from 'weldnote/routes/_base/list';

@classic
export default class ExpiredRoute extends ListRoute {
  @service
  session;

  @service
  welderCertificateData;

  model(params) {
    let options = { page: params.page };
    return this.welderCertificateData.getMiscellaneousCertificatesExpired(options);
  }
}
