import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { assert, warn } from '@ember/debug';
import { get } from '@ember/object';

export default class EditRoute extends Route {
  @service
  userSession;

  @service session;

  @service
  store;

  @service
  router;

  /* Name of the model type used in this edit route, e.g. 'welding-process' */
  modelName = '';

  /* Path to the list route of the model type used in this route, e.g. 'weld-types' */
  listRoute = '';

  beforeModel(transition) {
    super.beforeModel(...arguments);
    this.session.requireAuthentication(transition, 'login');
    if (this.userSession.isAccountLocked) {
      this.router.transitionTo('index');
    }
  }

  model({ id }) {
    return this.store.findRecord(this.modelName, id);
  }

  init() {
    super.init(...arguments);
    assert('Route must have a modelName declared', !isEmpty(this.modelName));
    warn(
      `Route has a 'listRoute' field, you should convert it to base-edit-glimmer and remove this field (if it is already using base-edit-glimmer, you can safely remove this field). Instructions for the migration: https://bitbucket.org/weldnote/weldnote-wiki/wiki/Upgrade%20a%20base-edit%20component%20to%20be%20a%20glimmer%20component`,
      isEmpty(this.listRoute),
      {
        id: 'ember-debug.base-edit-without-list-route',
      }
    );
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('modelName', model.metadata.modelName);
    controller.set('listRoute', this.listRoute);
  }
}
