import { get } from '@ember/object';
import { Optional } from 'typescript-optional';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import FillerMaterial from 'weldnote/models/filler-material';
import ProcessMecanization from 'weldnote/models/process-mecanization';
import TypeCurrentPolarity from 'weldnote/models/type-current-polarity';
import WelderCertificate from 'weldnote/models/welder-certificate';
import WeldingPosition from 'weldnote/models/welding-position';
import WeldingProcess from 'weldnote/models/welding-process';

export enum AutomaticJointTracking {
  WITH = 'WITH',
  WITHOUT = 'WITHOUT',
}

export enum AutomaticArcVoltageControl {
  WITH = 'WITH',
  WITHOUT = 'WITHOUT',
}

export enum VisualControl {
  DIRECT = 'DIRECT',
  REMOTE = 'REMOTE',
}

export enum WeldingOperatorSpecificWeldingDetails {
  ARC = 'ARC',
  JOINT = 'JOINT',
  NONE = 'NONE',
}

export enum Process {
  PROCESS1,
  PROCESS2,
  PROCESS3,
}

/**
 *
 */
export interface TestPieceValuesSingleProcess {
  weldingProcess: Optional<WeldingProcess>;
  weldingPosition: Optional<WeldingPosition>;
  processMecanization: Optional<ProcessMecanization>;
  transferMode: Optional<ArcTransferMode>;
  fillerMaterial: Optional<FillerMaterial>;
  typeCurrent: Optional<TypeCurrentPolarity>;
}

export class WelderCertificateTestPieceValues implements TestPieceValuesSingleProcess {
  certificate: WelderCertificate;

  process: Process;

  constructor(certificate: WelderCertificate, process: Process) {
    this.certificate = certificate;
    this.process = process;
  }

  get weldingProcess(): Optional<WeldingProcess> {
    if (this.process === Process.PROCESS1) {
      return Optional.ofNullable(get(this.certificate, 'weldingProcessRootTestPiece'));
    } else if (this.process === Process.PROCESS2) {
      return Optional.ofNullable(get(this.certificate, 'weldingProcessFillTestPiece'));
    } else if (this.process === Process.PROCESS3) {
      return Optional.ofNullable(get(this.certificate, 'weldingProcessCapTestPiece'));
    }
    return Optional.empty();
  }

  get weldingPosition(): Optional<WeldingPosition> {
    if (this.process === Process.PROCESS1) {
      return Optional.ofNullable(get(this.certificate, 'weldingPositionRootTestPiece'));
    } else if (this.process === Process.PROCESS2) {
      return Optional.ofNullable(get(this.certificate, 'weldingPositionFillTestPiece'));
    } else if (this.process === Process.PROCESS3) {
      return Optional.ofNullable(get(this.certificate, 'weldingPositionCapTestPiece'));
    }
    return Optional.empty();
  }

  get processMecanization(): Optional<ProcessMecanization> {
    if (this.process === Process.PROCESS1) {
      return Optional.ofNullable(get(this.certificate, 'processMecanizationRootTestPiece'));
    } else if (this.process === Process.PROCESS2) {
      return Optional.ofNullable(get(this.certificate, 'processMecanizationFillTestPiece'));
    } else if (this.process === Process.PROCESS3) {
      return Optional.ofNullable(get(this.certificate, 'processMecanizationCapTestPiece'));
    }
    return Optional.empty();
  }

  get transferMode(): Optional<ArcTransferMode> {
    if (this.process === Process.PROCESS1) {
      return Optional.ofNullable(get(this.certificate, 'arcTransferModeRootTestPiece'));
    } else if (this.process === Process.PROCESS2) {
      return Optional.ofNullable(get(this.certificate, 'arcTransferModeFillTestPiece'));
    } else if (this.process === Process.PROCESS3) {
      return Optional.ofNullable(get(this.certificate, 'arcTransferModeCapTestPiece'));
    }
    return Optional.empty();
  }

  get fillerMaterial(): Optional<FillerMaterial> {
    if (this.process === Process.PROCESS1) {
      return Optional.ofNullable(get(this.certificate, 'fillerMaterialRootTestPiece'));
    } else if (this.process === Process.PROCESS2) {
      return Optional.ofNullable(get(this.certificate, 'fillerMaterialFillTestPiece'));
    } else if (this.process === Process.PROCESS3) {
      return Optional.ofNullable(get(this.certificate, 'fillerMaterialCapTestPiece'));
    }
    return Optional.empty();
  }

  get typeCurrent(): Optional<TypeCurrentPolarity> {
    if (this.process === Process.PROCESS1) {
      return Optional.ofNullable(get(this.certificate, 'typeCurrentRootTestPiece'));
    } else if (this.process === Process.PROCESS2) {
      return Optional.ofNullable(get(this.certificate, 'typeCurrentFillTestPiece'));
    } else if (this.process === Process.PROCESS3) {
      return Optional.ofNullable(get(this.certificate, 'typeCurrentCapTestPiece'));
    }
    return Optional.empty();
  }
}

export class NullTestPieceValues implements TestPieceValuesSingleProcess {
  get weldingProcess(): Optional<WeldingProcess> {
    return Optional.empty();
  }

  get weldingPosition(): Optional<WeldingPosition> {
    return Optional.empty();
  }

  get processMecanization(): Optional<ProcessMecanization> {
    return Optional.empty();
  }

  get transferMode(): Optional<ArcTransferMode> {
    return Optional.empty();
  }

  get fillerMaterial(): Optional<FillerMaterial> {
    return Optional.empty();
  }

  get typeCurrent(): Optional<TypeCurrentPolarity> {
    return Optional.empty();
  }
}

export class WelderOperatorTypes {
  static jointTrackingFromString(value: string): Optional<AutomaticJointTracking> {
    if (value) {
      if (value.toUpperCase() === 'WITH') {
        return Optional.of(AutomaticJointTracking.WITH);
      } else if (value.toUpperCase() === 'WITHOUT') {
        return Optional.of(AutomaticJointTracking.WITHOUT);
      }
    }
    return Optional.empty();
  }

  static arcVoltageControlFromString(value: string): Optional<AutomaticArcVoltageControl> {
    if (value) {
      if (value.toUpperCase() === 'WITH') {
        return Optional.of(AutomaticArcVoltageControl.WITH);
      } else if (value.toUpperCase() === 'WITHOUT') {
        return Optional.of(AutomaticArcVoltageControl.WITHOUT);
      }
    }
    return Optional.empty();
  }

  static visualControlFromString(value: string): Optional<VisualControl> {
    if (value) {
      if (value.toUpperCase() === 'DIRECT') {
        return Optional.of(VisualControl.DIRECT);
      } else if (value.toUpperCase() === 'REMOTE') {
        return Optional.of(VisualControl.REMOTE);
      }
    }
    return Optional.empty();
  }

  static processFromString(value: string): Optional<Process> {
    if (value) {
      if (value === 'process1') {
        return Optional.of(Process.PROCESS1);
      } else if (value === 'process2') {
        return Optional.of(Process.PROCESS2);
      } else if (value === 'process3') {
        return Optional.of(Process.PROCESS3);
      }
    }
    return Optional.empty();
  }

  static weldingOperatorDetailFromString(
    value: string
  ): Optional<WeldingOperatorSpecificWeldingDetails> {
    if (value) {
      if (value.toUpperCase() === 'ARC') {
        return Optional.of(WeldingOperatorSpecificWeldingDetails.ARC);
      } else if (value.toUpperCase() === 'JOINT') {
        return Optional.of(WeldingOperatorSpecificWeldingDetails.JOINT);
      } else if (value.toUpperCase() === 'NONE') {
        return Optional.of(WeldingOperatorSpecificWeldingDetails.NONE);
      }
    }
    return Optional.empty();
  }
}
