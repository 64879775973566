import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get, action, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

@classic
export default class IndexController extends Controller {
  @service
  userSession;

  @service('weldnote-data')
  data;

  @service
  session;

  @service
  router;

  emptyCards = null;

  @computed(
    'userSession.{canCreatePQR,canCreateWPS,canCreateCertificate,canCreateWeldingBook,canCreateWelder}'
  )
  get canSeeMenuToCreate() {
    let { userSession } = this;
    let {
      canCreatePQR,
      canCreateWPS,
      canCreateCertificate,
      canCreateWeldingBook,
      canCreateWelder,
      canCreateQMatrix,
    } = userSession;
    return (
      canCreatePQR ||
      canCreateWPS ||
      canCreateCertificate ||
      canCreateWeldingBook ||
      canCreateWelder ||
      canCreateQMatrix
    );
  }

  init() {
    super.init(...arguments);
    // This is just to have an array of five
    this.set('emptyCards', Array(5).fill(''));
  }

  @service
  help;

  @computed('userSession.isPlanQuality')
  get columnSize() {
    if (get(this, 'userSession.isPlanQuality')) {
      return 3;
    }
    return 2;
  }

  @alias('model.internalCertificates.lastSuccessful.value')
  internalCertificateList;

  @alias('model.externalCertificates.lastSuccessful.value')
  externalCertificateList;

  @alias('model.miscellaneousCertificates.lastSuccessful.value')
  miscellaneousCertificateList;

  @alias('model.internalCertificates.isRunning')
  isLoadingInternalCertificateList;

  @alias('model.externalCertificates.isRunning')
  isLoadingExternalCertificateList;

  @alias('model.miscellaneousCertificates.isRunning')
  isLoadingMiscellaneousCertificateList;

  showInternalRevalidations = true;

  showExternalRevalidations = false;

  showMiscellaneousRevalidations = false;

  @computed('model.latestDocuments.lastSuccessful.value')
  get latestDocumentRow() {
    let lastSuccessful = this.model?.latestDocuments?.lastSuccessful;
    if (!isEmpty(lastSuccessful)) {
      let { value } = lastSuccessful;
      if (!isEmpty(value)) {
        let result = [];
        let { pqrs, certificates, wps } = value;
        let pqrsWithType = pqrs.map((pqr) => {
          pqr.type = 'pqr';
          return pqr;
        });
        let certificateWithType = certificates.map((certificate) => {
          certificate.type = 'welder-certificate';
          return certificate;
        });
        let wpsWithType = wps.map((wps) => {
          wps.type = 'wps';
          return wps;
        });
        result.push(...pqrsWithType);
        result.push(...certificateWithType);
        result.push(...wpsWithType);
        result.sort((document1, document2) => {
          if (document1.modifiedOn < document2.modifiedOn) {
            return 1;
          }
          if (document1.modifiedOn > document2.modifiedOn) {
            return -1;
          }
          return 0;
        });

        return result.slice(0, 5);
      }
    }
    return [];
  }

  @computed('userSession.account', 'model.loaded')
  get accountDataLoaded() {
    if (this.userSession.account) {
      if (this.model.loaded) {
        return true;
      } else {
        this.router.refresh();
        return true;
      }
    }
    return false;
  }

  @action
  setInternalRevalidations() {
    this.set('showInternalRevalidations', true);
    this.set('showExternalRevalidations', false);
    this.set('showMiscellaneousRevalidations', false);
    this.model.internalCertificates.perform();
  }

  @action
  setExternalRevalidations() {
    this.set('showExternalRevalidations', true);
    this.set('showInternalRevalidations', false);
    this.set('showMiscellaneousRevalidations', false);
    this.model.externalCertificates.perform();
  }

  @action
  setMiscellaneousRevalidations() {
    this.set('showExternalRevalidations', false);
    this.set('showInternalRevalidations', false);
    this.set('showMiscellaneousRevalidations', true);
    this.model.miscellaneousCertificates.perform();
  }
}
