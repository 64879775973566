import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { get, set } from '@ember/object';
import ListRoute from 'weldnote/routes/_base/list';

@classic
export default class MiscellaneousCertificatesRoute extends ListRoute {
  @service('weldnote-data')
  data;

  @service('helpdesk-widget')
  helpdesk;

  model() {
    return this.data.listAllMiscellaneousCertificates();
  }

  setupController(controller, model) {
    set(controller, 'model', model);
    set(controller, 'totalCount', model.meta.records);
  }

  activate() {
    this.helpdesk.setSearchItems('Miscellaneous');
  }
}
