import EditRoute from 'weldnote/routes/_base/edit';
import { service } from '@ember/service';

export default class WeldLayerConfigurationRoute extends EditRoute {
  @service
  store;

  modelName = 'weld-layer-configuration';

  listRoute = 'weld-layer-configurations';

  model({ id }) {
    return this.store.findRecord('weld-layer-configuration', id, {
      include: 'welding-details,weld-types,rendered-image,upload',
    });
  }
}
