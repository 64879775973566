import { helper } from '@ember/component/helper';

export function formatPercentage(params, { value, total }) {
  let totalNum = parseInt(total);
  let valueNum = parseInt(value);
  if (!isNaN(totalNum) && !isNaN(valueNum)) {
    if (totalNum > 0) {
      return `${((valueNum / totalNum).toFixed(2) * 100).toFixed(1)}%`;
    } else {
      return '0%';
    }
  }
  return '';
}

export default helper(formatPercentage);
