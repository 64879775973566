import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import EditRoute from 'weldnote/routes/_base/edit';

@classic
export default class WelderCertificateViewRoute extends EditRoute {
  @service('weldnote-data')
  data;

  modelName = 'welder-certificate';

  listRoute = 'welder-certificates';

  model({ id }) {
    return this.data.loadAllDataWPQ(id);
  }
}
