import { attr, belongsTo } from '@ember-data/model';
import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import IndustryCode from './industry-code';

export default class FillerMaterialGroup extends BaseModel {
  @attr('string')
  declare groupName?: string;

  @attr('string')
  declare description?: string;

  @belongsTo('industry-code')
  declare code: IndustryCode;

  @attr('boolean')
  declare system?: boolean;

  @attr('string')
  declare groupNumber?: string;

  @attr('string')
  declare subgroupNumber?: string;

  @computed('groupName', 'groupNumber', 'subgroupNumber', 'code')
  get visualLabel(): string {
    let { groupName, groupNumber, subgroupNumber, code: industryCode } = this;
    if (!isEmpty(groupNumber)) {
      let code = get(industryCode, 'code');
      if (code === 'ISO') {
        if (!isEmpty(subgroupNumber)) {
          return `${groupNumber}.${subgroupNumber}`;
        }
        return groupNumber || '';
      } else if (code === 'ASME') {
        if (!isEmpty(subgroupNumber)) {
          return `F${groupNumber} A${subgroupNumber}`;
        }
        return `F${groupNumber}`;
      } else if (code === 'AWS') {
        return groupNumber || '';
      }
    }
    return groupName || '';
  }

  validations = {
    groupName: { presence: true },
    code: {
      presence: {
        message(_key: string, _value: string, model: FillerMaterialGroup): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'filler-material-group',
    groupName: { required: true },
    code: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'filler-material-group': FillerMaterialGroup;
  }
}
