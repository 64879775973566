import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import { calculateShielding } from 'weldnote/utils/certification-rules';
import Constants from 'weldnote/utils/constants';
import {
  isButtWeld,
  isFilletWeld,
  isPartialPenetrationButtWeldISO,
  isBranchConnection,
  isOverlayWeld,
} from 'weldnote/utils/rules/weld-type';
import {
  convertInchesToMilimeters,
  convertMilimetersToInches,
} from 'weldnote/utils/unit-system/units';
import { formatRangeWith3Decimals } from 'weldnote/utils/unit-system/format';
import WelderCertificate from 'weldnote/models/welder-certificate';
import WeldType from 'weldnote/models/weld-type';
import BaseMaterialGroup from 'weldnote/models/base-material-group';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import FillerMaterialGroup from 'weldnote/models/filler-material-group';
import WeldingDetail from 'weldnote/models/welding-detail';
import WeldingPosition from 'weldnote/models/welding-position';
import WeldingProcess from 'weldnote/models/welding-process';
import ElectrodeType from 'weldnote/models/electrode-type';
import IndustryCode from 'weldnote/models/industry-code';
import FillerMaterial from 'weldnote/models/filler-material';
import BaseMaterial from 'weldnote/models/base-material';
import {
  WelderCertificateApprovalRange,
  NumericalRange,
} from 'weldnote/src-weldnote/wopq/approval-ranges/wopq-range-interface';
import ProcessMecanization from 'weldnote/models/process-mecanization';
import {
  AutomaticJointTracking,
  TestPieceValuesSingleProcess,
  AutomaticArcVoltageControl,
  VisualControl,
  WeldingOperatorSpecificWeldingDetails,
} from 'weldnote/src-weldnote/wopq/types';

const { ARC_TRANSFER_MODES, WELDING_DETAILS, UNIT_SYSTEM, PRODUCT_TYPE } = Constants;

export default class Iso96061ApprovalRanges implements WelderCertificateApprovalRange {
  private certificate: WelderCertificate;

  private _weldTypes: WeldType[];

  private _baseMaterials: BaseMaterialGroup[];

  private allWeldingDetails: WeldingDetail[];

  private _fillerMaterials: FillerMaterialGroup[];

  private _weldingProcesses: WeldingProcess[];

  private _weldingPositions: WeldingPosition[];

  private _transferModes: ArcTransferMode[];

  private allElectrodeTypes: ElectrodeType[];

  private unitSystem: string;

  constructor(certificate: WelderCertificate, data: any, unitSystem: string) {
    this.certificate = certificate;
    this._weldTypes = data.allWeldTypes;
    this._baseMaterials = data.allBaseMaterials;
    this.allWeldingDetails = data.allWeldingDetails;
    this._fillerMaterials = data.allFillerMaterials;
    this._weldingProcesses = data.allWeldingProcesses;
    this._weldingPositions = data.allWeldingPositions;
    this._transferModes = data.allTransferModes;
    this.allElectrodeTypes = data.allElectrodes;
    this.unitSystem = unitSystem;
    if (unitSystem === null) {
      throw new Error('Unit System is required for AWS B2.1 Approval Ranges');
    }
  }

  automaticJointTracking(
    _testPiece: AutomaticJointTracking | null,
    _process: TestPieceValuesSingleProcess
  ): AutomaticJointTracking[] {
    return [];
  }

  automaticVoltageControl(
    _testPiece: AutomaticArcVoltageControl | null,
    _process: TestPieceValuesSingleProcess
  ): AutomaticArcVoltageControl[] {
    return [];
  }

  visualControl(
    _testPiece: VisualControl | null,
    _process: TestPieceValuesSingleProcess
  ): VisualControl[] {
    return [];
  }

  electrodeType(
    electrode: ElectrodeType,
    _processValues: TestPieceValuesSingleProcess
  ): ElectrodeType[] {
    return this.electrodeTypeRoot(electrode);
  }

  processMecanization(_testPiece: ProcessMecanization): ProcessMecanization[] {
    return [];
  }

  getIndustryCode(code: IndustryCode | undefined): string {
    if (code) {
      return get(code, 'id');
    }
    return '';
  }

  get industryCodeId() {
    let { standard } = this;
    let code = get(standard, 'code');
    if (code) {
      return get(code, 'id');
    }
    return '';
  }

  get allWeldingProcesses() {
    return this._weldingProcesses.filter(
      (process) => this.getIndustryCode(get(process, 'industryCode')) === this.industryCodeId
    );
  }

  get allWeldingPositions() {
    return this._weldingPositions.filter(
      (position) => this.getIndustryCode(get(position, 'industryCode')) === this.industryCodeId
    );
  }

  get allTransferModes() {
    return this._transferModes.filter(
      (transferMode) =>
        this.getIndustryCode(get(transferMode, 'industryCode')) === this.industryCodeId
    );
  }

  get allWeldTypes() {
    return this._weldTypes.filter(
      (weldType) => this.getIndustryCode(get(weldType, 'industryCode')) === this.industryCodeId
    );
  }

  get allBaseMaterials() {
    return this._baseMaterials.filter(
      (baseMaterial) => this.getIndustryCode(get(baseMaterial, 'code')) === this.industryCodeId
    );
  }

  get allFillerMaterials() {
    return this._fillerMaterials.filter(
      (fillerMaterial) => this.getIndustryCode(get(fillerMaterial, 'code')) === this.industryCodeId
    );
  }

  get standard() {
    return this.certificate.get('standard');
  }

  get productType() {
    return this.certificate.get('productTypeTestPiece');
  }

  get isPlate() {
    let { productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PLATE;
    }
    return false;
  }

  get isPipePlate() {
    let { productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PIPE_PLATE;
    }
    return false;
  }

  get requalificationPeriod() {
    return this.certificate.get('requalificationPeriod');
  }

  get weldTypeTestPiece() {
    return this.certificate.get('weldTypeTestPiece');
  }

  get transferModeRoot() {
    return this.certificate.get('arcTransferModeRootTestPiece');
  }

  get transferModeFill() {
    return this.certificate.get('arcTransferModeFillTestPiece');
  }

  get transferModeCap() {
    return this.certificate.get('arcTransferModeCapTestPiece');
  }

  get isMetric() {
    return this.unitSystem === UNIT_SYSTEM.METRIC;
  }

  get isSupplementaryFilletWeldTestRequired() {
    let required = this.certificate.get('supplementaryFilletWeldTestRequired');
    return !isEmpty(required) && required;
  }

  get isImperial() {
    return this.unitSystem === UNIT_SYSTEM.IMPERIAL;
  }

  get isFilletWeld() {
    return isFilletWeld(this.weldTypeTestPiece);
  }

  get isButtWeld() {
    return isButtWeld(this.weldTypeTestPiece);
  }

  get isPartialPenetrationButtWeld() {
    return isPartialPenetrationButtWeldISO(this.weldTypeTestPiece);
  }

  get isBranchConnection() {
    return isBranchConnection(this.weldTypeTestPiece);
  }

  get isOverlayWeld() {
    return isOverlayWeld(this.weldTypeTestPiece);
  }

  get isPipe() {
    let { productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PIPE;
    }
    return false;
  }

  get _emptyRange() {
    return {
      min: null,
      max: null,
    };
  }

  get weldingProcessRootTestPiece() {
    return get(this.certificate, 'weldingProcessRootTestPiece');
  }

  get weldingProcessFillTestPiece() {
    return get(this.certificate, 'weldingProcessFillTestPiece');
  }

  get weldingProcessCapTestPiece() {
    return get(this.certificate, 'weldingProcessCapTestPiece');
  }

  electrodeTypeRoot(electrode: ElectrodeType) {
    return this._electrodeType(electrode, this.weldingProcessRootTestPiece);
  }

  electrodeTypeFill(electrode: ElectrodeType) {
    return this._electrodeType(electrode, this.weldingProcessFillTestPiece);
  }

  electrodeTypeCap(electrode: ElectrodeType) {
    return this._electrodeType(electrode, this.weldingProcessCapTestPiece);
  }

  filterElectrodeTypes(electrodeCodes: string[]) {
    let results: ElectrodeType[] = [];
    electrodeCodes.forEach((designation) => {
      let candidate = this.allElectrodeTypes.findBy('shortDesignation', designation);
      if (candidate) {
        results.pushObject(candidate);
      }
    });
    return results;
  }

  _electrodeType(electrode: ElectrodeType, weldingProcess: WeldingProcess) {
    if (isEmpty(electrode)) {
      return [];
    }

    let weldingProcessName = '';
    if (!isEmpty(weldingProcess)) {
      weldingProcessName = weldingProcess.shortDesignation;
    }
    let electrodeCode = electrode.shortDesignation;
    if (weldingProcessName === '111') {
      if (
        electrodeCode === 'A' ||
        electrodeCode === 'RA' ||
        electrodeCode === 'RB' ||
        electrodeCode === 'RC' ||
        electrodeCode === 'RR' ||
        electrodeCode === 'R'
      ) {
        return this.filterElectrodeTypes(['A', 'RA', 'RB', 'RC', 'RR', 'R']);
      } else if (electrodeCode === 'B') {
        return this.filterElectrodeTypes(['A', 'RA', 'RB', 'RC', 'RR', 'R', 'B']);
      } else if (electrodeCode === 'C') {
        return this.filterElectrodeTypes(['C']);
      }
    } else if (weldingProcessName === '135' || weldingProcessName === '138') {
      if (electrodeCode === 'SW' || electrodeCode === 'MC') {
        return this.filterElectrodeTypes(['SW', 'MC']);
      }
    } else if (weldingProcessName === '136') {
      if (electrodeCode === 'FC (B)') {
        return this.filterElectrodeTypes([
          'FC (B)',
          'FC (R)',
          'FC (P)',
          'FC (V)',
          'FC (W)',
          'FC (Y)',
          'FC (Z)',
        ]);
      } else if (
        electrodeCode === 'FC (R)' ||
        electrodeCode === 'FC (P)' ||
        electrodeCode === 'FC (V)' ||
        electrodeCode === 'FC (W)' ||
        electrodeCode === 'FC (Y)' ||
        electrodeCode === 'FC (Z)'
      ) {
        return this.filterElectrodeTypes([
          'FC (R)',
          'FC (P)',
          'FC (V)',
          'FC (W)',
          'FC (Y)',
          'FC (Z)',
        ]);
      }
    } else {
      return this.filterElectrodeTypes([electrodeCode]);
    }

    return [electrode];
  }

  thickness(testPiece: number) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    let thickness: number | null = parseFloat(`${testPiece}`);
    if (isNaN(thickness)) {
      return this._emptyRange;
    }
    let result: NumericalRange = this._emptyRange;

    if (this.isImperial) {
      thickness = convertInchesToMilimeters(thickness);
    }

    if (!thickness) {
      return this._emptyRange;
    }

    if (this.isButtWeld || this.isOverlayWeld || this.isPartialPenetrationButtWeld) {
      if (thickness < 3) {
        result.min = thickness;
        result.max = Math.max(3, 2 * thickness);
      } else if (thickness < 12 && thickness >= 3) {
        result.min = 3;
        result.max = 2 * thickness;
      } else {
        result.min = 3;
        result.max = null;
      }
    } else if (this.isFilletWeld) {
      if (thickness < 3) {
        result.min = thickness;
        result.max = Math.max(3, 2 * thickness);
      } else {
        result.min = 3;
        result.max = null;
      }
    }

    if (this.isImperial) {
      result.min = convertMilimetersToInches(result.min);
      result.max = convertMilimetersToInches(result.max);
      result = formatRangeWith3Decimals(result);
    }

    return result;
  }

  weldType(testPiece: WeldType) {
    if (isEmpty(testPiece)) {
      return [];
    }

    let results: WeldType[] = [];
    results.pushObject(testPiece);
    if (isButtWeld(testPiece) || isOverlayWeld(testPiece)) {
      if (this.productType === PRODUCT_TYPE.PIPE) {
        let [branchConnection] = this.allWeldTypes.filter((e) => isBranchConnection(e));
        results.pushObject(branchConnection);
      }
      if (this.isSupplementaryFilletWeldTestRequired) {
        let [filletWeld] = this.allWeldTypes.filter((e) => isFilletWeld(e));
        results.pushObject(filletWeld);
      }
      if (isButtWeld(testPiece)) {
        let [partialPenetration] = this.allWeldTypes.filter((e) =>
          isPartialPenetrationButtWeldISO(e)
        );
        results.pushObject(partialPenetration);
      }
    }
    if (isPartialPenetrationButtWeldISO(testPiece) && this.isSupplementaryFilletWeldTestRequired) {
      let [filletWeld] = this.allWeldTypes.filter((e) => isFilletWeld(e));
      results.pushObject(filletWeld);
    }

    return results;
  }

  transferMode(testPiece: ArcTransferMode) {
    if (isEmpty(testPiece)) {
      return [];
    }

    let arcTransferMode = get(testPiece, 'transferMode');

    if (arcTransferMode === ARC_TRANSFER_MODES.SHORT_ARC) {
      return this.allTransferModes.filter((e) => {
        let currentMode = get(e, 'transferMode');
        return (
          currentMode === ARC_TRANSFER_MODES.PULSED_SPRAY ||
          currentMode === ARC_TRANSFER_MODES.GLOBULAR ||
          currentMode === ARC_TRANSFER_MODES.SPRAY ||
          currentMode === ARC_TRANSFER_MODES.SHORT_ARC
        );
      });
    }

    return [testPiece];
  }

  findWeldingDetails(wanted: string[]) {
    let results: WeldingDetail[] = [];
    wanted.forEach((position) => {
      let candidate = this.allWeldingDetails.findBy('shortDesignation', position);
      if (candidate && !isEmpty(candidate)) {
        results.pushObject(candidate);
      }
    });
    return results;
  }

  weldingDetails(testPiece: WeldingDetail[]) {
    if (isEmpty(testPiece)) {
      return [];
    }
    let results: WeldingDetail[] = [];
    let finalResults: WeldingDetail[] = [];

    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_NO_BACKING)) {
      results.pushObjects(
        this.findWeldingDetails([
          WELDING_DETAILS.SINGLE_SIDE_NO_BACKING,
          WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
          WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING,
          WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING,
          WELDING_DETAILS.BOTH_SIDES,
        ])
      );
    }
    if (
      testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING) ||
      testPiece.findBy('shortDesignation', WELDING_DETAILS.BOTH_SIDES)
    ) {
      results.pushObjects(
        this.findWeldingDetails([
          WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
          WELDING_DETAILS.BOTH_SIDES,
        ])
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING)) {
      results.pushObjects(
        this.findWeldingDetails([
          WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
          WELDING_DETAILS.BOTH_SIDES,
          WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING,
        ])
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING)) {
      results.pushObjects(
        this.findWeldingDetails([
          WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
          WELDING_DETAILS.BOTH_SIDES,
          WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING,
        ])
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.CONSUMABLE_INSERT)) {
      results.pushObjects(
        this.findWeldingDetails([
          WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
          WELDING_DETAILS.BOTH_SIDES,
          WELDING_DETAILS.CONSUMABLE_INSERT,
        ])
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.MULTI_LAYER)) {
      results.pushObjects(
        this.findWeldingDetails([WELDING_DETAILS.SINGLE_LAYER, WELDING_DETAILS.MULTI_LAYER])
      );
    }

    testPiece.forEach((detail) => {
      results.pushObject(detail);
    });
    results.forEach((detail) => {
      if (!finalResults.findBy('shortDesignation', detail.get('shortDesignation'))) {
        finalResults.pushObject(detail);
      }
    });
    return finalResults;
  }

  baseMaterial(testPiece: BaseMaterial) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [];
  }

  _filterProcessByName(processes: string[]) {
    let result: WeldingProcess[] = [];
    processes.forEach((processName) => {
      let process = this.allWeldingProcesses.findBy('shortDesignation', processName);
      if (process) {
        result.pushObject(process);
      }
    });
    return result;
  }

  _calculateWeldingProcess(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    let result: WeldingProcess[] = [];
    let processName = process.shortDesignation;
    if ('135' === processName || '138' === processName) {
      result = this._filterProcessByName(['135', '138']);
    }
    if ('121' === processName || '125' === processName) {
      result = this._filterProcessByName(['121', '125']);
    }
    if ('141' === processName || '143' === processName || '145' === processName) {
      result = this._filterProcessByName(['141', '142', '143', '145']);
    }

    if (isEmpty(result)) {
      result = [process];
    }
    return result;
  }

  weldingProcessRoot(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    return this._calculateWeldingProcess(process);
  }

  weldingProcessFill(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    return this._calculateWeldingProcess(process);
  }

  weldingProcessCap(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    return this._calculateWeldingProcess(process);
  }

  shielding(process: WeldingProcess) {
    if (isEmpty(process)) {
      return null;
    }
    return calculateShielding(process.shortDesignation);
  }

  diameter(testPiece: number) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    let results: NumericalRange = this._emptyRange;

    let diameter: number | null = parseFloat(`${testPiece}`);
    if (!diameter) {
      return this._emptyRange;
    }

    if (diameter < 0) {
      return results;
    }
    if (this.isImperial) {
      diameter = convertInchesToMilimeters(diameter);
    }
    if (!diameter) {
      return this._emptyRange;
    }

    if (diameter <= 25) {
      results.min = diameter;
      results.max = 2 * diameter;
    } else {
      results.min = Math.max(25, 0.5 * diameter);
      results.max = null;
    }
    if (this.isImperial) {
      results.min = convertMilimetersToInches(results.min);
      results.max = convertMilimetersToInches(results.max);
      results = formatRangeWith3Decimals(results);
    }
    return results;
  }

  filterPositions(wanted: string[]) {
    let results: WeldingPosition[] = [];
    wanted.forEach((position) => {
      let candidate = this.allWeldingPositions.findBy('code', position);
      if (candidate && !isEmpty(candidate)) {
        results.pushObject(candidate);
      }
    });
    return results;
  }

  weldingPosition(testPiece: WeldingPosition) {
    if (isEmpty(testPiece)) {
      return [];
    }

    let results: WeldingPosition[] = [];
    let weldingPosition = get(testPiece, 'code');

    if (this.isFilletWeld) {
      if (weldingPosition === 'PA') {
        return this.filterPositions(['PA']);
      } else if (weldingPosition === 'PB') {
        return this.filterPositions(['PA', 'PB']);
      } else if (weldingPosition === 'PC') {
        return this.filterPositions(['PA', 'PB', 'PC']);
      } else if (weldingPosition === 'PD' || weldingPosition === 'PE') {
        return this.filterPositions(['PA', 'PB', 'PC', 'PD', 'PE']);
      } else if (weldingPosition === 'PF') {
        return this.filterPositions(['PA', 'PB', 'PF']);
      } else if (weldingPosition === 'PH') {
        return this.filterPositions(['PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'PH']);
      } else if (weldingPosition === 'PG') {
        return this.filterPositions(['PG']);
      } else if (weldingPosition === 'PJ') {
        return this.filterPositions(['PA', 'PB', 'PD', 'PE', 'PG', 'PJ']);
      }
    } else if (this.isButtWeld || this.isOverlayWeld || this.isPartialPenetrationButtWeld) {
      if (weldingPosition === 'PA') {
        results = this.filterPositions(['PA']);
      } else if (weldingPosition === 'PC') {
        results = this.filterPositions(['PA', 'PC']);
        if (this.isSupplementaryFilletWeldTestRequired) {
          results.pushObjects(this.filterPositions(['PB']));
        }
      } else if (weldingPosition === 'PE') {
        results = this.filterPositions(['PA', 'PC', 'PE']);
        if (this.isSupplementaryFilletWeldTestRequired) {
          results.pushObjects(this.filterPositions(['PB', 'PD']));
        }
      } else if (weldingPosition === 'PF') {
        results = this.filterPositions(['PA', 'PF']);
        if (this.isSupplementaryFilletWeldTestRequired) {
          results.pushObjects(this.filterPositions(['PB']));
        }
      } else if (weldingPosition === 'PH') {
        results = this.filterPositions(['PA', 'PF', 'PE', 'PH']);
        if (this.isSupplementaryFilletWeldTestRequired) {
          results.pushObjects(this.filterPositions(['PB', 'PD']));
        }
      } else if (weldingPosition === 'PG') {
        return this.filterPositions(['PG']);
      } else if (weldingPosition === 'PJ') {
        results = this.filterPositions(['PA', 'PE', 'PG', 'PJ']);
        if (this.isSupplementaryFilletWeldTestRequired) {
          results.pushObjects(this.filterPositions(['PB', 'PD']));
        }
      } else if (weldingPosition === 'PF+PC') {
        results = this.filterPositions(['PA', 'PC', 'PE', 'PF']);
        if (this.isSupplementaryFilletWeldTestRequired) {
          results.pushObjects(this.filterPositions(['PB', 'PD']));
        }
      } else if (weldingPosition === 'H-L045' || weldingPosition === 'PH+PC') {
        results = this.filterPositions(['PA', 'PC', 'PE', 'PF', 'PH', 'H-L045']);
        if (this.isSupplementaryFilletWeldTestRequired) {
          results.pushObjects(this.filterPositions(['PB', 'PD']));
        }
      } else if (weldingPosition === 'J-L045' || weldingPosition === 'PJ+PC') {
        results = this.filterPositions(['PA', 'PC', 'PE', 'PG', 'PJ', 'J-L045']);
        if (this.isSupplementaryFilletWeldTestRequired) {
          results.pushObjects(this.filterPositions(['PB', 'PD']));
        }
      } else {
        results = this.filterPositions([weldingPosition]);
      }
    } else {
      results = this.filterPositions([weldingPosition]);
    }
    return results.sortBy('code');
  }

  _filterFillerMaterialGroups(groupToSelect: string[]) {
    let results: FillerMaterialGroup[] = [];
    groupToSelect.forEach((groupCode) => {
      let candidate = this.allFillerMaterials.findBy('groupName', groupCode);
      if (candidate) {
        results.pushObject(candidate);
      }
    });
    return results;
  }

  fillerMaterial(testPiece: FillerMaterial) {
    if (isEmpty(testPiece)) {
      return [];
    }
    let materialGroup = get(testPiece, 'group');
    let groupCode = get(materialGroup, 'groupName');
    if (groupCode === 'FM1' || groupCode === 'FM2') {
      return this._filterFillerMaterialGroups(['FM1', 'FM2']);
    } else if (groupCode === 'FM3') {
      return this._filterFillerMaterialGroups(['FM1', 'FM2', 'FM3']);
    } else if (groupCode === 'FM4') {
      return this._filterFillerMaterialGroups(['FM1', 'FM2', 'FM3', 'FM4']);
    } else if (groupCode === 'FM5') {
      return this._filterFillerMaterialGroups(['FM5']);
    } else if (groupCode === 'FM6') {
      return this._filterFillerMaterialGroups(['FM5', 'FM6']);
    }
    return [];
  }

  internalDeadline(previousDate: Date) {
    if (isEmpty(previousDate)) {
      return null;
    }

    let newDate = moment(previousDate).add(6, 'M');
    return newDate.toDate();
  }

  externalDeadline(previousDate: Date) {
    if (isEmpty(previousDate)) {
      return null;
    }

    let requalificationPeriod = this.certificate.get('requalificationPeriod');
    if (!isNaN(parseInt(`${requalificationPeriod}`))) {
      let newDate = moment(previousDate).add(parseInt(`${requalificationPeriod}`), 'y');
      return newDate.toDate();
    }

    return null;
  }

  weldingDetailsForOperator(
    _testPiece: WeldingOperatorSpecificWeldingDetails,
    _process: TestPieceValuesSingleProcess
  ): WeldingOperatorSpecificWeldingDetails[] {
    throw new Error('Method not implemented.');
  }
}
