import classic from 'ember-classic-decorator';
import { inject } from '@ember/service';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import EditRoute from 'weldnote/routes/_base/edit';
import WPSRules from 'weldnote/utils/welding-procedure-specification-rules';

const { WELDING_PROCEDURE_SPECIFICATION } = WPSRules;

@classic
export default class WeldingProcedureSpecificationViewRoute extends EditRoute {
  @inject('weldnote-data')
  data;

  modelName = 'welding-procedure-specification';

  listRoute = 'welding-procedure-specifications';

  model({ id }) {
    return this.data.loadAllDataWPS(id);
  }

  afterModel(wps) {
    return hash({
      root: get(wps, WELDING_PROCEDURE_SPECIFICATION.MAIN_PQR),
      fill: get(wps, WELDING_PROCEDURE_SPECIFICATION.PQR_FILL),
      cap: get(wps, WELDING_PROCEDURE_SPECIFICATION.PQR_CAP),
      secondary: get(wps, WELDING_PROCEDURE_SPECIFICATION.SECONDARY_PQRS),
    });
  }
}
