import { attr, belongsTo } from '@ember-data/model';
import { get } from '@ember/object';
import BaseModel from 'weldnote/models/base-model';
import IndustryCode from './industry-code';
import { WeldingStandard } from '../config/types/welding-standard';

export default class WelderStandard extends BaseModel implements WeldingStandard {
  @attr('string')
  declare standard: string;

  @belongsTo('industry-code', { async: false })
  declare code: IndustryCode;

  @attr('boolean')
  declare system: boolean;

  get visualLabel() {
    return this.standard;
  }

  get industryCodeId(): string {
    let code = get(this, 'code');
    if (code) {
      return get(code, 'id');
    }
    return '';
  }

  validations = {
    standard: {
      presence: {
        presence: {
          message(_key: string, _value: string, model: WelderStandard): string {
            return model.intl.t('generic.error.input-value');
          },
        },
      },
    },

    code: {
      presence: {
        presence: {
          message(_key: string, _value: string, model: WelderStandard): string {
            return model.intl.t('generic.error.input-value');
          },
        },
      },
    },
  };

  metadata = {
    modelName: 'welder-standard',
    standard: { required: true },
    code: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'welder-standard': WelderStandard;
  }
}
