import Component from '@glimmer/component';
import WelderCertificate from 'weldnote/models/welder-certificate';
import WopqApprovalRange from 'weldnote/src-weldnote/wopq/approval-ranges/wopq-approval-range';
import {
  WelderOperatorTypes,
  WelderCertificateTestPieceValues,
  VisualControl,
} from 'weldnote/src-weldnote/wopq/types';
import { service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface ModelWelderCertificateVisualControlApprovalRangeArgs {
  visualControl: VisualControl | null;
  certificate: WelderCertificate;
  approvalRange: WopqApprovalRange;
  process: string;
}

export default class ModelWelderCertificateVisualControlApprovalRange extends Component<ModelWelderCertificateVisualControlApprovalRangeArgs> {
  @service
  declare intl: IntlService;

  constructor(owner: unknown, args: ModelWelderCertificateVisualControlApprovalRangeArgs) {
    super(owner, args);
  }

  get value(): string {
    if (this.args.visualControl) {
      let result = '';
      let visualControl = WelderOperatorTypes.visualControlFromString(this.args.visualControl);
      if (visualControl.isPresent()) {
        let visualValue = visualControl.get();
        let possibleProcess = WelderOperatorTypes.processFromString(this.args.process);
        let process = null;
        if (possibleProcess.isEmpty()) {
          return result;
        } else {
          process = possibleProcess.get();
        }
        let testPieces = new WelderCertificateTestPieceValues(this.args.certificate, process);
        let approvalRange = this.args.approvalRange.visualControl(visualValue, testPieces);
        result = approvalRange
          .map((t) => {
            if (t === VisualControl.DIRECT) {
              return this.intl.t('lov.visual-control.direct');
            } else if (t === VisualControl.REMOTE) {
              return this.intl.t('lov.visual-control.remote');
            }
            return '';
          })
          .join('+');
      }
      return result;
    } else {
      return '';
    }
  }
}
