import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';
import { isKnownFractionString, toFraction } from 'weldnote/utils/unit-system/units';
import Constants from 'weldnote/utils/constants';
import { htmlSafe } from '@ember/template';

const { UNIT_SYSTEM } = Constants;

export default Helper.extend({
  userSession: service(),

  compute(params, hash) {
    let { convertToFraction } = hash;
    let separator = get(this, 'userSession.decimalSeparator');
    let unitSystem = get(this, 'userSession.unitSystem');
    let [value] = params;
    if (isEmpty(value)) {
      return '';
    }

    if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (convertToFraction === true) {
        let fraction = toFraction(value);
        if (isKnownFractionString(fraction)) {
          return htmlSafe(toFraction(value, 'display'));
        }
      }
    }

    if (separator !== '.') {
      return `${value}`.replace('.', ',');
    }
    return value;
  },
});
