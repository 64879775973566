import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { getProperties, action, computed, set } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class FillerMaterialsController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  modelName = 'filler-material';

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  @computed
  get gridColumns() {
    let specificationLabel = this.getAttributeLabel('specification');
    let groupLabel = this.getAttributeLabel('group');
    let classificationLabel = this.getAttributeLabel('classification');
    let codeLabel = this.getAttributeLabel('code');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet', 'laptop'],
      },
      {
        valuePath: 'specification.specification',
        label: specificationLabel,
        cellComponent: 'weldnote-grid/filler-material-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'classification.classification',
        label: classificationLabel,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'group.groupName',
        label: groupLabel,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'code.code',
        label: codeLabel,
        sortable: false,
        breakpoints: ['desktop'],
      },
    ];
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};
      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllFillerMaterials(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  buildFilters() {
    let { specification, classification, group, code } = getProperties(
      this,
      'specification',
      'classification',
      'group',
      'code'
    );
    let filters = {};
    if (!isEmpty(specification)) {
      filters.specification = specification;
    }
    if (!isEmpty(classification)) {
      filters.classification = classification;
    }
    if (!isEmpty(group)) {
      filters.group = group;
    }
    if (!isEmpty(code)) {
      filters.code = code.get('id');
    }
    return filters;
  }

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.loadData.perform();
    this.resetPage();
  }

  @action
  clearFields() {
    this.setProperties({
      specification: null,
      classification: null,
      group: null,
      code: null,
    });
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setSpecification(spec) {
    set(this, 'specification', spec);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setClassification(classification) {
    set(this, 'classification', classification);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setGroup(group) {
    set(this, 'group', group);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setCode(code) {
    set(this, 'code', code);
    this.resetPage();
    this.loadData.perform();
  }
}
