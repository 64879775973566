import { action } from '@ember/object';
import Controller from '@ember/controller';
import { service } from '@ember/service';
import RouterService from '@ember/routing/router-service';

export default class PqrViewController extends Controller {
  @service
  declare router: RouterService;

  @action
  editPqr(pqr: string) {
    this.router.transitionTo('pqr', pqr);
  }
}
