import { get } from '@ember/object';
import { A } from '@ember/array';
import { isEmpty } from '@ember/utils';
import {
  isFilletWeld,
  isButtWeld,
  isOverlayWeld,
  isPartialPenetrationButtWeld,
  isBranchConnection,
  isATypeOfButtWeld,
} from 'weldnote/utils/rules/weld-type';
import { isSingleLayer, isMultiLayer } from 'weldnote/utils/rules/welding-detail';
import { calculateShielding } from 'weldnote/utils/certification-rules';
import {
  formatRangeWith2Decimals,
  formatRangeWith3Decimals,
} from 'weldnote/utils/unit-system/format';
import BaseMaterialRulesISO from 'weldnote/utils/pqr-base-material-rules-iso';
import {
  convertMilimetersToInches,
  convertInchesToMilimeters,
} from 'weldnote/utils/unit-system/units';

import Constants from 'weldnote/utils/constants';

const {
  PRODUCT_TYPE,
  UNIT_SYSTEM,
  WELDING_DETAILS,
  ARC_TRANSFER_MODES,
  WELD_TYPES,
  OVERLAY_TYPE_OPTIONS,
} = Constants;

export default class Iso156141ApprovalRanges {
  constructor(
    pqr,
    {
      allWeldTypes,
      allBaseMaterials,
      allWeldingDetails,
      allProcessMecanizations,
      allTypeCurrentPolarity,
      allWeldingPositions,
      allTransferModes,
      unitSystem = UNIT_SYSTEM.METRIC,
    }
  ) {
    this.pqr = pqr;
    let industryCodeId = get(this.standard, 'code.id');

    this.allWeldTypes = allWeldTypes.filter((weldType) => {
      return get(weldType, 'industryCode.id') === industryCodeId;
    });
    this._baseMaterials = allBaseMaterials;
    this.allWeldingDetails = allWeldingDetails;
    this.allProcessMecanizations = allProcessMecanizations;
    this.allTypeCurrentPolarity = allTypeCurrentPolarity;
    this._weldingPositions = allWeldingPositions;
    this._transferModes = allTransferModes;
    this.unitSystem = unitSystem;
    if (unitSystem === null) {
      throw new Error('Unit System is required for ISO 15614-1 Approval Ranges');
    }
  }

  get allWeldingPositions() {
    return this._weldingPositions.filter(
      (position) => get(position, 'industryCode.id') === this.industryCodeId
    );
  }

  get allBaseMaterials() {
    return this._baseMaterials.filter(
      (baseMaterial) => get(baseMaterial, 'code.id') === this.industryCodeId
    );
  }

  get allTransferModes() {
    return this._transferModes.filter(
      (transferMode) => get(transferMode, 'industryCode.id') === this.industryCodeId
    );
  }

  get industryCodeId() {
    return get(this.standard, 'code.id');
  }

  get standard() {
    return this.pqr.get('standard');
  }

  get productTypeTestPiece() {
    return this.pqr.get('productTypeTestPiece');
  }

  get weldTypeTestPiece() {
    return this.pqr.get('weldTypeTestPiece');
  }

  get weldTypeCode() {
    return this.pqr.get('weldTypeTestPiece.weldType');
  }

  get baseMaterial1ThicknessValue() {
    return this.pqr.get('baseMaterial1Thickness');
  }

  get baseMaterial1() {
    return this.pqr.get('baseMaterial1TestPiece');
  }

  get baseMaterial2ThicknessValue() {
    return this.pqr.get('baseMaterial2Thickness');
  }

  get baseMaterial2() {
    return this.pqr.get('baseMaterial2TestPiece');
  }

  get weldingDetailsValues() {
    return this.pqr.get('weldingDetailsTestPiece');
  }

  get weldingProcessRoot() {
    return this.pqr.get('weldingProcessRootTestPiece');
  }

  get weldingProcessFill() {
    return this.pqr.get('weldingProcessFillTestPiece');
  }

  get weldingProcessCap() {
    return this.pqr.get('weldingProcessCapTestPiece');
  }

  get transferModeRoot() {
    return this.pqr.get('arcTransferModeRootTestPiece');
  }

  get transferModeFill() {
    return this.pqr.get('arcTransferModeFillTestPiece');
  }

  get transferModeCap() {
    return this.pqr.get('arcTransferModeCapTestPiece');
  }

  get impactTemperature() {
    return this.pqr.get('impactTestTemperature');
  }

  get impactRequired() {
    return this.pqr.get('impactTestRequired');
  }

  get impactTestReport() {
    return this.pqr.get('impactTestReport');
  }

  get interpassMinimum() {
    return this.pqr.get('interpassTemperatureMinimumApprovalRange');
  }

  get thicknessTotal() {
    return this.pqr.get('thicknessTotalTestPiece');
  }

  get isPipe() {
    let { productTypeTestPiece: productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PIPE;
    }
    return false;
  }

  get isPlate() {
    let { productTypeTestPiece: productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PLATE;
    }
    return false;
  }

  filterWeldTypes(allTypes, listToFilter, industryCode) {
    let result = A();
    allTypes
      .filter((item) => {
        return get(item, 'industryCode.id') === industryCode;
      })
      .forEach((item) => {
        let name = get(item, 'weldType');
        if (listToFilter.indexOf(name) > -1) {
          result.pushObject(item);
        }
      });
    return result;
  }

  weldType(testPiece) {
    let result = A();
    let { industryCodeId } = this;

    if (isEmpty(testPiece)) {
      return [];
    }

    let curryedFilterTypes = (allWeldTypes, industryCode) => (listToFilter) =>
      this.filterWeldTypes(allWeldTypes, listToFilter, industryCode);
    let filterCurrentWeldTypes = curryedFilterTypes(this.allWeldTypes, industryCodeId);

    if (isBranchConnection(testPiece) || isFilletWeld(testPiece)) {
      result.pushObject(testPiece);
    } else if (isButtWeld(testPiece)) {
      result = filterCurrentWeldTypes([
        WELD_TYPES.FILLET_WELD,
        WELD_TYPES.BUTT_WELD,
        WELD_TYPES.PARTIAL_PENETRATION_BUTT_WELD,
      ]);
      if (this.isPipe) {
        // Add a Branch Connection
        let [branchConnection] = filterCurrentWeldTypes([WELD_TYPES.BRANCH_CONNECTION]);
        result.pushObject(branchConnection);
      }
    } else if (isPartialPenetrationButtWeld(testPiece)) {
      result = filterCurrentWeldTypes([
        WELD_TYPES.FILLET_WELD,
        WELD_TYPES.PARTIAL_PENETRATION_BUTT_WELD,
      ]);
    }

    // If no other rules is found, test piece qualifies itself
    if (isEmpty(result) && !isEmpty(testPiece)) {
      result.pushObject(testPiece);
    }

    return result;
  }

  weldingProcess(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  _findWeldingDetail(detail) {
    return this.allWeldingDetails.findBy('shortDesignation', detail);
  }

  _findListWeldingDetails(details = []) {
    let results = [];
    details.forEach((d) => {
      let detail = this._findWeldingDetail(d);
      if (detail) {
        results.push(detail);
      }
    });
    return results;
  }

  _isWeldingDetail(testPiece, detail) {
    return testPiece.findBy('shortDesignation', detail);
  }

  weldingDetails(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
    let resultDetailNames = A();
    let result = A();
    let { weldTypeCode: weldType, overlayType } = this;

    if (weldType === WELD_TYPES.OVERLAY_WELD) {
      if (overlayType === OVERLAY_TYPE_OPTIONS.CORROSION_RESISTANT) {
        testPiece.forEach((detail) => {
          let designation = detail.get('shortDesignation');
          if (designation === WELDING_DETAILS.SINGLE_LAYER) {
            [WELDING_DETAILS.SINGLE_LAYER, WELDING_DETAILS.MULTI_LAYER].forEach((currentDetail) => {
              resultDetailNames.pushObject(currentDetail);
            });
          } else {
            resultDetailNames.pushObject(designation);
          }
        });
      } else {
        testPiece.forEach((detail) => {
          let designation = detail.get('shortDesignation');
          resultDetailNames.pushObject(designation);
        });
      }
    } else {
      testPiece.forEach((detail) => {
        let designation = detail.get('shortDesignation');
        if (designation === WELDING_DETAILS.SINGLE_SIDE_NO_BACKING) {
          [
            WELDING_DETAILS.SINGLE_SIDE_NO_BACKING,
            WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING,
            WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
            WELDING_DETAILS.BOTH_SIDES,
          ].forEach((currentDetail) => {
            resultDetailNames.pushObject(currentDetail);
          });
          resultDetailNames.concat();
        } else if (designation === WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING) {
          [WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING, WELDING_DETAILS.BOTH_SIDES].forEach(
            (currentDetail) => {
              resultDetailNames.pushObject(currentDetail);
            }
          );
        } else if (designation === WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING) {
          [
            WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING,
            WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
            WELDING_DETAILS.BOTH_SIDES,
          ].forEach((currentDetail) => {
            resultDetailNames.pushObject(currentDetail);
          });
        } else if (designation === WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING) {
          [
            WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING,
            WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
            WELDING_DETAILS.BOTH_SIDES,
          ].forEach((currentDetail) => {
            resultDetailNames.pushObject(currentDetail);
          });
        } else if (designation === WELDING_DETAILS.BOTH_SIDES) {
          [WELDING_DETAILS.BOTH_SIDES, WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING].forEach(
            (currentDetail) => {
              resultDetailNames.pushObject(currentDetail);
            }
          );
        } else {
          resultDetailNames.pushObject(designation);
        }
      });
    }

    resultDetailNames.uniq().forEach((name) => {
      let position = this.allWeldingDetails.findBy('shortDesignation', name);
      if (position) {
        result.pushObject(position);
      }
    });
    return result;
  }

  processMecanization(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  transferMode(transferMode, weldingProcess) {
    if (isEmpty(transferMode) || isEmpty(weldingProcess)) {
      return [];
    }
    let weldingProcessName = get(weldingProcess, 'shortDesignation');
    let weldingProcesses = ['114', '131', '135', '136', '137', '138'];
    if (!isEmpty(weldingProcessName) && weldingProcesses.indexOf(weldingProcessName) > -1) {
      let transferModeName = transferMode.get('transferMode');
      if (
        transferModeName === ARC_TRANSFER_MODES.SPRAY ||
        transferModeName === ARC_TRANSFER_MODES.GLOBULAR
      ) {
        return this.allTransferModes.filter((mode) => {
          if (
            mode.get('transferMode') === ARC_TRANSFER_MODES.SPRAY ||
            mode.get('transferMode') === ARC_TRANSFER_MODES.GLOBULAR
          ) {
            return true;
          }
        });
      } else {
        return [transferMode];
      }
    } else {
      return [];
    }
  }

  filterTypeCurrent(allCurrents, toFilter) {
    let result = [];
    toFilter.forEach((type) => {
      allCurrents.forEach((current) => {
        if (current.get('typeCurrent') === type) {
          result.pushObject(current);
          return;
        }
      });
    });
    return result;
  }

  typeCurrentAndPolarity(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  diameter(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    let result = {
      min: null,
      max: null,
    };

    let diameter = parseFloat(testPiece, 10);

    if (isNaN(diameter)) {
      return this._emptyRange;
    }

    if (this.isPipe) {
      if (this.isOverlayWeld) {
        result.min = 0.75 * diameter;
        result.max = null;
      } else {
        result.min = 0.5 * diameter;
        result.max = null;
      }
    } else {
      if (this.isOverlayWeld) {
        result.min = 150;
        result.max = null;
      }
    }
    return this.fixDecimals(result);
  }

  weldedThickness(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    return this._calculateWeldingThicknessRange(testPiece);
  }

  _calculateWeldingThicknessRange(testPiece) {
    let thickness = parseFloat(testPiece);
    if (isNaN(thickness)) {
      return this._emptyRange;
    }

    let { smallestBaseMaterialThickness, impactRequirements, weldTypeCode: weldType } = this;
    let result = this._emptyRange;
    result.min = 0;
    if (this.isImperialSystem) {
      thickness = convertInchesToMilimeters(thickness);
      smallestBaseMaterialThickness = convertInchesToMilimeters(smallestBaseMaterialThickness);
    }
    if (
      weldType === WELD_TYPES.BUTT_WELD ||
      weldType === WELD_TYPES.BRANCH_CONNECTION ||
      weldType === WELD_TYPES.PARTIAL_PENETRATION_BUTT_WELD
    ) {
      if (thickness > 0 && thickness <= 3) {
        result.max = 2 * thickness;
      } else if (thickness > 3 && thickness <= 12) {
        if (impactRequirements && isEmpty(this.impactTestReport)) {
          result.max = 12;
        } else if (impactRequirements && !isEmpty(this.impactTestReport)) {
          result.max = 2 * thickness;
        } else if (!impactRequirements) {
          result.max = 2 * thickness;
        }
      } else if (thickness > 12 && thickness <= 20) {
        result.max = 2 * thickness;
      } else if (thickness > 20) {
        if (smallestBaseMaterialThickness >= 20 && smallestBaseMaterialThickness < 40) {
          result.max = 2 * smallestBaseMaterialThickness;
        } else if (smallestBaseMaterialThickness >= 40 && smallestBaseMaterialThickness < 100) {
          result.max = 200;
        } else if (smallestBaseMaterialThickness >= 100 && smallestBaseMaterialThickness < 150) {
          result.max = 300;
        } else if (smallestBaseMaterialThickness >= 150) {
          result.max = 1.33 * smallestBaseMaterialThickness;
        }
      }
    } else {
      if (this.isSingleLayer) {
        result.min = 0.75 * thickness;
        result.max = 1.5 * thickness;
      } else if (this.isMultiLayer) {
        result.min = 0;
        result.max = null;
      }
    }
    if (this.isImperialSystem) {
      result.min = convertMilimetersToInches(result.min);
      result.max = convertMilimetersToInches(result.max);
      result = formatRangeWith3Decimals(result);
      return result;
    }

    return this.fixDecimals(result);
  }

  _thicknessButtWeldBranchConnectionSingleLayer(thickness) {
    let result = this._emptyRange;
    if (isNaN(thickness)) {
      return result;
    }
    if (thickness <= 3) {
      result.min = thickness * 0.5;
      result.max = thickness * 2;
    } else if (thickness > 3 && thickness <= 12) {
      result.min = Math.max(3, 0.5 * thickness);
      if (this.impactRequirements && isEmpty(this.impactTestReport)) {
        if (1.3 * thickness >= 12) {
          result.max = 12;
        } else {
          result.max = 1.3 * thickness;
        }
      } else {
        result.max = 1.3 * thickness;
      }
    } else if (thickness > 12 && thickness <= 100) {
      result.min = 0.5 * thickness;
      result.max = 1.1 * thickness;
    } else {
      result.min = null;
      result.max = null;
    }
    return result;
  }

  _thicknessButtWeldBranchConnectionMultiLayer(thickness) {
    let result = this._emptyRange;
    if (isNaN(thickness)) {
      return result;
    }
    if (thickness <= 3) {
      result.min = 0.5 * thickness;
      result.max = 2 * thickness;
    } else if (thickness > 3 && thickness <= 12) {
      result.min = 3;
      if (this.impactRequirements && isEmpty(this.impactTestReport)) {
        if (2 * thickness >= 12) {
          result.max = 12;
        } else {
          result.max = 2 * thickness;
        }
      } else {
        result.max = 2 * thickness;
      }
    } else if (thickness > 12 && thickness <= 100) {
      result.min = 0.5 * thickness;
      result.max = 2 * thickness;
    } else {
      result.min = 50;
      result.max = 2 * thickness;
    }
    return result;
  }

  _thicknessFilletWeld(thickness) {
    let result = this._emptyRange;
    if (thickness <= 3) {
      result.min = 0.7 * thickness;
      result.max = 2 * thickness;
    } else if (thickness > 3 && thickness < 30) {
      result.min = 3;
      result.max = thickness * 2;
    } else if (thickness >= 30) {
      result.min = 5;
      result.max = null;
    }
    return result;
  }

  _thicknessOverlayWeld(thickness) {
    let result = this._emptyRange;
    if (isNaN(thickness)) {
      return result;
    }
    if (thickness < 25) {
      result.min = 0.8 * thickness;
      result.max = 1.5 * thickness;
    } else {
      result.min = 25;
      result.max = null;
    }
    return result;
  }

  baseMaterialThickness(thickness1, thickness2) {
    if (isEmpty(thickness1)) {
      return {
        material1: this._emptyRange,
        material2: this._emptyRange,
      };
    }

    let { weldTypeCode: weldType } = this;

    let thicknessesDifferent = false;
    let thickness1Numeric = parseFloat(thickness1);
    let thickness2Numeric = parseFloat(thickness2);

    let resultThickness1 = {
      min: 0,
      max: null,
    };
    let resultThickness2 = {
      min: 0,
      max: null,
    };
    if (
      !isEmpty(thickness1) &&
      !isEmpty(thickness2) &&
      !isNaN(thickness1Numeric) &&
      !isNaN(thickness2Numeric) &&
      thickness1Numeric !== thickness2Numeric
    ) {
      thicknessesDifferent = Math.abs(thickness1 - thickness2) > Number.EPSILON;
    }

    if (this.isImperialSystem) {
      thickness1 = convertInchesToMilimeters(thickness1);
      thickness2 = convertInchesToMilimeters(thickness2);
    }

    if (
      weldType === WELD_TYPES.BUTT_WELD ||
      weldType === WELD_TYPES.BRANCH_CONNECTION ||
      weldType === WELD_TYPES.PARTIAL_PENETRATION_BUTT_WELD
    ) {
      if (this.isSingleLayer) {
        resultThickness1 = this._thicknessButtWeldBranchConnectionSingleLayer(thickness1);
        resultThickness2 = this._thicknessButtWeldBranchConnectionSingleLayer(thickness2);
      } else if (this.isMultiLayer) {
        resultThickness1 = this._thicknessButtWeldBranchConnectionMultiLayer(thickness1);
        resultThickness2 = this._thicknessButtWeldBranchConnectionMultiLayer(thickness2);
      }
    } else if (weldType === WELD_TYPES.FILLET_WELD) {
      resultThickness1 = this._thicknessFilletWeld(thickness1);
      resultThickness2 = this._thicknessFilletWeld(thickness2);
    } else if (weldType === WELD_TYPES.OVERLAY_WELD) {
      resultThickness1 = this._thicknessOverlayWeld(thickness1);
      resultThickness2 = this._thicknessOverlayWeld(thickness2);
    }

    if (thicknessesDifferent) {
      if (thickness1Numeric >= 30 && thickness2Numeric >= 30) {
        if (thickness1Numeric > thickness2Numeric) {
          resultThickness1.max = null;
        } else if (thickness2Numeric > thickness1Numeric) {
          resultThickness2.max = null;
        }
      }
    }

    if (this.isImperialSystem) {
      resultThickness1.min = convertMilimetersToInches(resultThickness1.min);
      resultThickness1.max = convertMilimetersToInches(resultThickness1.max);
      resultThickness2.min = convertMilimetersToInches(resultThickness2.min);
      resultThickness2.max = convertMilimetersToInches(resultThickness2.max);
      resultThickness1 = formatRangeWith3Decimals(resultThickness1);
      resultThickness2 = formatRangeWith3Decimals(resultThickness2);
      return {
        material1: resultThickness1,
        material2: resultThickness2,
      };
    }

    return {
      material1: this.fixDecimals(resultThickness1),
      material2: this.fixDecimals(resultThickness2),
    };
  }

  _findWeldingPosition(code = '') {
    return this.allWeldingPositions
      .filter((p) => {
        return get(p, 'industryCode.id') === get(this.standard, 'code.id');
      })
      .findBy('code', code);
  }

  weldingPosition(testPiece, direction) {
    if (isEmpty(testPiece)) {
      return [];
    }

    let weldingPosition = get(testPiece, 'code');
    let { weldTypeCode: weldType } = this;

    if (weldType === WELD_TYPES.OVERLAY_WELD) {
      if (weldingPosition === 'PC') {
        let resultingPositions = ['PA', 'PB', 'PC'];
        return this.allWeldingPositions.filter((item) => {
          let currentPositionCode = item.get('code');
          return resultingPositions.includes(currentPositionCode);
        });
      } else {
        return this.allWeldingPositions.filter((item) => {
          return item.get('code') === weldingPosition;
        });
      }
    } else {
      let ascendantPositions = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'PH', 'H-L045', 'PF+PC'];
      let result = ascendantPositions.includes(weldingPosition);
      if (result) {
        return this.allWeldingPositions.filter((item) => {
          let currentPositionCode = item.get('code');
          return ascendantPositions.includes(currentPositionCode);
        });
      }
      let descendentPositions = ['PG', 'PJ', 'J-L045'];
      result = descendentPositions.includes(weldingPosition);
      if (result) {
        return this.allWeldingPositions.filter((item) => {
          let currentPositionCode = item.get('code');
          return descendentPositions.includes(currentPositionCode);
        });
      }
    }

    return [testPiece];
  }

  fixDecimals(result) {
    if (result == null) {
      return null;
    }
    if (this.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      return result;
    }
    return formatRangeWith2Decimals(result);
  }

  preHeat(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    let preHeat = parseFloat(testPiece);
    if (isNaN(preHeat)) {
      return this._emptyRange;
    }

    let result = {
      min: null,
      max: null,
    };

    result.min = Math.max(preHeat - 50, 0);
    result.max = null;

    return this.fixDecimals(result);
  }

  postHeat(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    let postHeat = parseFloat(testPiece);
    if (isNaN(postHeat)) {
      return this._emptyRange;
    }

    let result = this._emptyRange;
    let { weldTypeCode: weldType } = this;

    if (weldType === WELD_TYPES.OVERLAY_WELD) {
      result.min = postHeat;
      result.max = postHeat + 20;
    } else {
      result.min = postHeat;
      result.max = null;
    }

    if (result.min < 0) {
      result.min = 0;
    }
    if (result.max < 0) {
      result.max = 0;
    }

    return this.fixDecimals(result);
  }

  isCorrectGroupISO15614(group) {
    return (
      BaseMaterialRulesISO.isGroup8(group) ||
      BaseMaterialRulesISO.isGroupExactly40(group) ||
      BaseMaterialRulesISO.isGroupExactly48(group) ||
      BaseMaterialRulesISO.isGroup10(group)
    );
  }

  interpass(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    let result = {
      min: null,
      max: null,
    };

    if (!isEmpty(this.baseMaterial1GroupName) && !isEmpty(this.baseMaterial2GroupName)) {
      if (
        this.isCorrectGroupISO15614(this.baseMaterial1GroupName) &&
        this.isCorrectGroupISO15614(this.baseMaterial2GroupName)
      ) {
        result.min = 0;
        result.max = testPiece;
      } else {
        result.min = 0;
        result.max = testPiece + 50;
      }
    } else {
      result.min = 0;
      result.max = testPiece;
    }

    // Don't update the minimum if there was a previous value there
    if (this.interpassMinimum > 0 && result.min === 0) {
      result.min = this.interpassMinimum;
    }

    return this.fixDecimals(result);
  }

  pwhtTemperature(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    let postWeldHeatTreament = parseFloat(testPiece);
    if (isNaN(postWeldHeatTreament)) {
      return this._emptyRange;
    }

    let result = this._emptyRange;
    let min = postWeldHeatTreament - 20;
    if (min < 0) {
      min = 0;
    }
    let max = postWeldHeatTreament + 20;
    if (max > 999) {
      max = 999;
    }
    result.min = min;
    result.max = max;

    return this._toRange(testPiece, testPiece);
  }

  pwhtTime(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    let result = this._emptyRange;

    let time = parseFloat(testPiece);
    if (isNaN(time)) {
      return this._emptyRange;
    }

    result.min = 0;
    result.max = time;

    return this.fixDecimals(result);
  }

  get isMetricSystem() {
    return this.unitSystem === UNIT_SYSTEM.METRIC;
  }

  get isImperialSystem() {
    return this.unitSystem === UNIT_SYSTEM.IMPERIAL;
  }

  _toRange(min, max) {
    return {
      min,
      max,
    };
  }

  get _emptyRange() {
    return {
      min: null,
      max: null,
    };
  }

  get isButtWeldOrPartialPenetrationWeld() {
    return (
      isButtWeld(this.weldTypeTestPiece) || isPartialPenetrationButtWeld(this.weldTypeTestPiece)
    );
  }

  get isFilletWeld() {
    return isFilletWeld(this.weldTypeTestPiece);
  }

  get isOverlayWeld() {
    return isOverlayWeld(this.weldTypeTestPiece);
  }

  get isMultiLayer() {
    return isMultiLayer(this.weldingDetailsValues);
  }

  get isSingleLayer() {
    return isSingleLayer(this.weldingDetailsValues);
  }

  get impactRequirements() {
    let { impactTemperature, impactRequired } = this;
    return impactRequired && !isNaN(parseFloat(impactTemperature));
  }

  get overlayType() {
    return this.pqr.get('overlayType');
  }

  get isOverlayHardFacing() {
    let { overlayType } = ThirtyDaysRoute;
    return overlayType === OVERLAY_TYPE_OPTIONS.HARD_FACING;
  }

  get baseMaterial1ThicknessApprovalRangeMinimum() {
    return this.pqr.get('baseMaterial1ThicknessMinimumApprovalRange');
  }

  get baseMaterial1ThicknessApprovalRangeMaximum() {
    return this.pqr.get('baseMaterial1ThicknessMaximumApprovalRange');
  }

  get baseMaterial2ThicknessApprovalRangeMinimum() {
    return this.pqr.get('baseMaterial2ThicknessMinimumApprovalRange');
  }

  get baseMaterial2ThicknessApprovalRangeMaximum() {
    return this.pqr.get('baseMaterial2ThicknessMaximumApprovalRange');
  }

  get smallestBaseMaterialThickness() {
    let bm1Thickness = parseFloat(this.baseMaterial1ThicknessValue, 10);
    let bm2Thickness = parseFloat(this.baseMaterial2ThicknessValue, 10);

    if (!isNaN(bm1Thickness) && !isNaN(bm2Thickness)) {
      return bm1Thickness <= bm2Thickness ? bm1Thickness : bm2Thickness;
    } else if (!isNaN(bm1Thickness)) {
      return bm1Thickness;
    } else if (!isNaN(bm2Thickness)) {
      return bm2Thickness;
    }
    return null;
  }

  thicknessProcess(testPiece /* ,layer */) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    return this._calculateWeldingThicknessRange(testPiece);
  }

  get baseMaterial1GroupName() {
    let baseMaterial1 = this.pqr.get('baseMaterial1TestPiece');
    if (!isEmpty(baseMaterial1)) {
      let materialGroup = get(baseMaterial1, 'materialGroup.groupName');
      if (!isEmpty(materialGroup)) {
        return materialGroup;
      }
    }
    return '';
  }

  get baseMaterial2GroupName() {
    let baseMaterial2 = this.pqr.get('baseMaterial2TestPiece');
    if (!isEmpty(baseMaterial2)) {
      let materialGroup = get(baseMaterial2, 'materialGroup.groupName');
      if (!isEmpty(materialGroup)) {
        return materialGroup;
      }
    }
    return '';
  }

  _filterGroups(groups) {
    let result = A();
    groups.forEach((group) => {
      let groupModel = this.allBaseMaterials.findBy('groupName', group);
      if (groupModel) {
        result.push(groupModel);
      }
    });
    return result;
  }

  _calculateBaseMaterialGroup(baseMaterial1Group, baseMaterial2Group, isMaterial1) {
    let { weldTypeCode: weldType } = this;
    let result = null;
    let result2 = null;
    let group = get(baseMaterial1Group, 'materialGroup.groupName');
    let group2 = get(baseMaterial2Group, 'materialGroup.groupName');
    if (weldType === WELD_TYPES.OVERLAY_WELD) {
      // Rules for overlay weld
      if (group === '1.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor1dot1());
      } else if (group === '1.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor1dot2());
      } else if (group === '1.3') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor1dot3());
      } else if (group === '2.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor2dot1());
      } else if (group === '2.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor2dot2());
      } else if (group === '3.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor3dot1());
      } else if (group === '3.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor3dot2());
      } else if (group === '3.3') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor3dot3());
      } else if (group === '4.1') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor4dot1());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor4dot1());
        }
      } else if (group === '4.2') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor4dot2());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor4dot2());
        }
      } else if (group === '5.1') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor5dot1());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor5dot1());
        }
      } else if (group === '5.2') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor5dot2());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor5dot2());
        }
      } else if (group === '5.3') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor5dot3());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor5dot3());
        }
      } else if (group === '5.4') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor5dot4());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor5dot4());
        }
      } else if (group === '6.1') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor6dot1());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor6dot1());
        }
      } else if (group === '6.2') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor6dot2());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor6dot2());
        }
      } else if (group === '6.3') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor6dot3());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor6dot3());
        }
      } else if (group === '6.4') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor6dot4());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor6dot4());
        }
      } else if (group === '7.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor7dot1());
      } else if (group === '7.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor7dot2());
      } else if (group === '8.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor8dot1());
      } else if (group === '8.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor8dot2());
      } else if (group === '8.3') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor8dot3());
      } else if (group === '10.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor10dot1());
      } else if (group === '10.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor10dot2());
      } else if (group === '11.1') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor11dot1());
        } else {
          result = this._filterGroups(
            BaseMaterialRulesISO.getRuleFor11dot1().concat(['1.4', '1.3', '1.2', '1.1'])
          );
        }
      } else if (group === '11.2') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor11dot2());
        } else {
          result = this._filterGroups(
            BaseMaterialRulesISO.getRuleFor11dot2().concat(['1.4', '1.3', '1.2', '1.1'])
          );
        }
      } else if (group === '11.3') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor11dot3());
        } else {
          result = this._filterGroups(
            BaseMaterialRulesISO.getRuleFor11dot3().concat(['1.4', '1.3', '1.2', '1.1'])
          );
        }
      } else {
        result = this._filterGroups([group]);
      }
      // End of Rules for Overlay Welds
    } else {
      // Rules for general case
      if (group === '1.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor1dot1());
      } else if (group === '1.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor1dot2());
      } else if (group === '1.3') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor1dot3());
      } else if (group === '2.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor2dot1());
      } else if (group === '2.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor2dot2());
      } else if (group === '3.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor3dot1());
      } else if (group === '3.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor3dot2());
      } else if (group === '3.3') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor3dot3());
      } else if (group === '4.1') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor4dot1());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor4dot1());
        }
      } else if (group === '4.2') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor4dot2());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor4dot2());
        }
      } else if (group === '5.1') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor5dot1());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor5dot1());
        }
      } else if (group === '5.2') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor5dot2());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor5dot2());
        }
      } else if (group === '5.3') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor5dot3());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor5dot3());
        }
      } else if (group === '5.4') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor5dot4());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor5dot4());
        }
      } else if (group === '6.1') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor6dot1());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor6dot1());
        }
      } else if (group === '6.2') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor6dot2());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor6dot2());
        }
      } else if (group === '6.3') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor6dot3());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor6dot3());
        }
      } else if (group === '6.4') {
        if (isMaterial1) {
          result = this._filterGroups(BaseMaterialRulesISO.getSimpleRuleFor6dot4());
        } else {
          result = this._filterGroups(BaseMaterialRulesISO.getRuleFor6dot4());
        }
      } else if (group === '7.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor7dot1());
      } else if (group === '7.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor7dot2());
      } else if (group === '7.3') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor7dot3());
      } else if (group === '8.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor8dot1());
      } else if (group === '8.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor8dot2());
      } else if (group === '8.3') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor8dot3());
      } else if (group === '9.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor9dot1());
      } else if (group === '9.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor9dot2());
      } else if (group === '9.3') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor9dot3());
      } else if (group === '10.1') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor10dot1());
      } else if (group === '10.2') {
        result = this._filterGroups(BaseMaterialRulesISO.getRuleFor10dot2());
      } else if (['41', '42', '44', '46', '48'].indexOf(group) > -1) {
        result = this._filterGroups(BaseMaterialRulesISO.getRulesFor40(group));
      } else {
        result = this._filterGroups([group]);
      }

      // Special Combinations of groups (8.x + 6.x, 8.x + 5.x)
      // Missing rules of 6x -> 8.x && 5.x -> 8.x           6.x -> 10.x && 5.x -> 10.x
      if (BaseMaterialRulesISO.isGroup8(group) && BaseMaterialRulesISO.isGroup6(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getSimpleRulesFor8(group));
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor6(group2).concat(['4.2', '4.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup6(group) && BaseMaterialRulesISO.isGroup8(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getSimpleRulesFor8(group2));
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor6(group).concat(['4.2', '4.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup8(group) && BaseMaterialRulesISO.isGroup2(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getSimpleRulesFor8(group));
        result2 = this._filterGroups(BaseMaterialRulesISO.getRulesFor2(group2));
      } else if (BaseMaterialRulesISO.isGroup2(group) && BaseMaterialRulesISO.isGroup8(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getSimpleRulesFor8(group2));
        result2 = this._filterGroups(BaseMaterialRulesISO.getRulesFor2(group));
      } else if (BaseMaterialRulesISO.isGroup8(group) && BaseMaterialRulesISO.isGroup5(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getSimpleRulesFor8(group));
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor5(group2).concat(['6.2', '6.1', '4.2', '4.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup5(group) && BaseMaterialRulesISO.isGroup8(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getSimpleRulesFor8(group2));
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor5(group).concat(['6.2', '6.1', '4.2', '4.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup10(group) && BaseMaterialRulesISO.isGroup6(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getRulesFor10(group));
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor6(group2).concat(['4.2', '4.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup6(group) && BaseMaterialRulesISO.isGroup10(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getRulesFor10(group2));
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor6(group).concat(['4.2', '4.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup10(group) && BaseMaterialRulesISO.isGroup5(group2)) {
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor5(group2).concat(['6.2', '6.1', '4.2', '4.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup5(group) && BaseMaterialRulesISO.isGroup10(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getRulesFor10(group2));
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor5(group).concat(['6.2', '6.1', '4.2', '4.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup10(group) && BaseMaterialRulesISO.isGroup8(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getRulesFor10(group));
        result2 = this._filterGroups(BaseMaterialRulesISO.getSimpleRulesFor8(group2));
      } else if (BaseMaterialRulesISO.isGroup8(group) && BaseMaterialRulesISO.isGroup10(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getRulesFor10(group2));
        result2 = this._filterGroups(BaseMaterialRulesISO.getSimpleRulesFor8(group));
      } else if (BaseMaterialRulesISO.isGroup11(group) && BaseMaterialRulesISO.isGroup11(group2)) {
        result = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor11(group).concat(['1.4', '1.3', '1.2', '1.1'])
        );
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor11(group2).concat(['1.4', '1.3', '1.2', '1.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup40(group) && BaseMaterialRulesISO.isGroup6(group2)) {
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor6(group2).concat(['4.2', '4.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup6(group) && BaseMaterialRulesISO.isGroup40(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getRulesFor40(group2));
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor6(group).concat(['4.2', '4.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup40(group) && BaseMaterialRulesISO.isGroup5(group2)) {
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor5(group2).concat(['6.2', '6.1', '4.2', '4.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup5(group) && BaseMaterialRulesISO.isGroup40(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getRulesFor40(group2));
        result2 = this._filterGroups(
          BaseMaterialRulesISO.getRulesFor5(group).concat(['6.2', '6.1', '4.2', '4.1'])
        );
      } else if (BaseMaterialRulesISO.isGroup5(group) && BaseMaterialRulesISO.isGroup5(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getSimpleRulesFor5(group));
        result2 = this._filterGroups(BaseMaterialRulesISO.getRulesFor5(group2));
      } else if (BaseMaterialRulesISO.isGroup10(group) && BaseMaterialRulesISO.isGroup10(group2)) {
        result = this._filterGroups(BaseMaterialRulesISO.getRulesFor10(group));
        result2 = this._filterGroups(BaseMaterialRulesISO.getRulesFor10(group2));
      }
    }
    return {
      material1: result,
      material2: result2,
    };
  }

  baseMaterial(baseMaterial1, baseMaterial2) {
    if (isEmpty(baseMaterial1) || isEmpty(baseMaterial2)) {
      return {
        material1: [],
        material2: [],
      };
    }
    return this._calculateBaseMaterialGroup(baseMaterial1, baseMaterial2, true);
  }

  branchAngle(/* testPiece */) {
    return this._emptyRange;
  }

  _calculateFillerMaterialRootCommercialDesignation(testPiece, currentProcess) {
    if (isEmpty(testPiece)) {
      return [];
    }

    let result = [];
    let processes = ['111', '114', '121', '122', '123', '124', '125', '136', '137'];
    let processName = '';
    if (!isEmpty(currentProcess)) {
      processName = currentProcess.shortDesignation;
    }
    if (
      processes.includes(processName) &&
      this.impactRequirements & (this.impactTemperature <= -20)
    ) {
      result = [testPiece];
    } else {
      // Not needed, but just wanted to make the rule explicit
      result = [];
    }
    return result;
  }

  fillerMaterialRootCommercialDesignation(designation) {
    if (isEmpty(designation)) {
      return [];
    }
    return this._calculateFillerMaterialRootCommercialDesignation(
      designation,
      this.weldingProcessRoot
    );
  }

  fillerMaterialFillCommercialDesignation(designation) {
    if (isEmpty(designation)) {
      return [];
    }
    return this._calculateFillerMaterialRootCommercialDesignation(
      designation,
      this.weldingProcessFill
    );
  }

  fillerMaterialCapCommercialDesignation(designation) {
    if (isEmpty(designation)) {
      return [];
    }
    return this._calculateFillerMaterialRootCommercialDesignation(
      designation,
      this.weldingProcessCap
    );
  }

  throatThickness(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    let result = this._emptyRange;

    let weldedThickness = parseFloat(this.thicknessTotal);
    if (isNaN(weldedThickness)) {
      return this._emptyRange;
    }

    if (!isATypeOfButtWeld(this.weldTypeTestPiece)) {
      return result;
    }

    if (this.isSingleLayer) {
      result.min = 0.75 * this.thicknessTotal;
      result.max = 1.5 * this.thicknessTotal;
    } else if (this.isMultiLayer) {
      result.min = 0;
      result.max = null;
    }
    return this.fixDecimals(result);
  }

  shielding(weldingProcess) {
    if (isEmpty(weldingProcess)) {
      return null;
    }
    return calculateShielding(weldingProcess);
  }

  _calculateBaseMaterialFilletWeldThickness(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    let thickness = parseFloat(testPiece);
    if (isNaN(thickness)) {
      return this._emptyRange;
    }

    let result = this._emptyRange;

    let { weldTypeCode: weldType } = this;
    if (
      weldType === WELD_TYPES.BUTT_WELD ||
      weldType === WELD_TYPES.PARTIAL_PENETRATION_BUTT_WELD
    ) {
      if (thickness <= 3) {
        result.min = 0.7 * thickness;
        result.max = 2 * thickness;
      } else if (thickness > 3 && thickness < 30) {
        result.min = 3;
        result.max = 2 * thickness;
      } else {
        result.min = 5;
        result.max = null;
      }
    }
    return result;
  }

  baseMaterial1FilletWeldThickness() {
    return this._calculateBaseMaterialFilletWeldThickness(this.baseMaterial1ThicknessValue);
  }

  baseMaterial2FilletWeldThickness() {
    return this._calculateBaseMaterialFilletWeldThickness(this.baseMaterial2ThicknessValue);
  }

  pjpButtWeldBaseMaterial1Thickness() {
    return this._emptyRange;
  }

  pjpButtWeldBaseMaterial2Thickness() {
    return this._emptyRange;
  }

  productType(testPiece) {
    let result = {
      productType: [],
      restrictions: [],
    };
    if (isButtWeld(weldType) && testPiece === PRODUCT_TYPE.PIPE) {
      result.productType = [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
    } else if (testPiece === PRODUCT_TYPE.PLATE) {
      result.productType = [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
      result.restrictions.pushObject({
        productType: PRODUCT_TYPE.PIPE,
        diameter: {
          value: 150,
          unitSystem: UNIT_SYSTEM.METRIC,
        },

        weldingPositions: ['PA', 'PC'],
      });
      result.restrictions.pushObject({
        productType: PRODUCT_TYPE.PIPE,
        diameter: {
          value: 500,
          unitSystem: UNIT_SYSTEM.METRIC,
        },
      });
    } else if (testPiece === PRODUCT_TYPE.PIPE) {
      result.productType = [PRODUCT_TYPE.PIPE];
    }
    return result;
  }
}
