import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class ProcessMecanization extends BaseModel {
  @attr('string')
  declare name: string;

  get visualLabel(): string {
    return this.name || '';
  }

  get isManual() {
    return this.name === ProcessMecanizationType.MANUAL;
  }

  get isAutomatic() {
    return this.name === ProcessMecanizationType.AUTOMATIC;
  }

  get isMechanized() {
    return this.name === ProcessMecanizationType.MECHANIZED;
  }

  validations = {
    name: {
      presence: {
        message(_key: string, _value: string, model: ProcessMecanization): string {
          return model.intl.t('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'process-mecanization',
    name: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'process-mecanization': ProcessMecanization;
  }
}

export enum ProcessMecanizationType {
  MANUAL = 'Manual',
  SEMI_AUTOMATIC = 'Semi-automatic',
  AUTOMATIC = 'Automatic',
  MECHANIZED = 'Mechanized',
}
