import { notEmpty, equal, alias } from '@ember/object/computed';
import { hasMany, belongsTo, attr } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import { memberAction } from 'ember-api-actions';
import Rules from 'weldnote/utils/welding-book-rules';
import Constants from 'weldnote/utils/constants';

const { WELDING_BOOK } = Rules;
const { WPS_INCLUSION, ARCHIVAL_STATE } = Constants;

export default class WeldingBook extends BaseModel {
  @attr('string')
  designation;

  @attr('string', { defaultValue: '0' })
  revision;

  @attr('string')
  revisionStatus;

  @attr('string')
  customer;

  @belongsTo('company')
  supplier;

  @belongsTo('pqr-standard')
  standard;

  @belongsTo('welder-standard')
  welderStandard;

  @attr('date')
  revisionDate;

  @belongsTo('staff')
  writtenBy;

  @attr('date')
  writtenByDate;

  @belongsTo('staff')
  verifiedBy;

  @attr('date')
  verifiedByDate;

  @belongsTo('staff')
  approvedBy;

  @attr('date')
  approvedByDate;

  @belongsTo('welding-book-status')
  status;

  @attr('string')
  notes;

  @attr('boolean')
  includePqrs;

  @hasMany('welding-procedure-specification')
  wps;

  @hasMany('pqr')
  pqrs;

  @hasMany('welder')
  welders;

  @belongsTo('file-upload')
  weldingBookDocument;

  @attr('string')
  weldingBookDocumentPath;

  @hasMany('component-drawing', { async: false })
  componentDrawings;

  @hasMany('welding-book-weld')
  welds;

  @belongsTo('file-upload')
  coverImage;

  @attr('string')
  coverImagePath;

  @belongsTo('project')
  weldingProject;

  @belongsTo('project-component')
  component;

  @attr('string')
  archivalState;

  @attr('string', { defaultValue: WPS_INCLUSION.GENERATE_PDF })
  typeOfWpsInclusion;

  @alias('designation')
  visualLabel;

  validations = {
    designation: { presence: true },
    revision: { presence: true },
    weldingProject: { presence: true },
    revisionDate: { presence: true },
    supplier: { presence: true },
    standard: {
      custom: {
        if(/* key, value, model */) {
          return false;
          // let welderStandard = get(model, WELDING_BOOK.WELDER_STANDARD);
          // let standardId = get(value, 'id');
          // let welderStandardId = get(welderStandard, 'id');
          // return !isEmpty(standardId) && !isEmpty(welderStandardId);
        },

        validation(/* key, value, model*/) {
          return true;
          // let currentStandard = value;
          // let certificateStandard = get(model, WELDING_BOOK.WELDER_STANDARD);
          // return isSameStandard(currentStandard, certificateStandard);
        },

        message(key, value, model) {
          return model.translate('model-validations.welding-book.standard');
        },
      },
    },

    welderStandard: {
      custom: {
        if(/* key, value, model */) {
          return false;
          // let standard = get(model, WELDING_BOOK.STANDARD);
          // return !isEmpty(get(value, 'id') && !isEmpty(get(standard, 'id')));
        },

        validation(/* key, value, model*/) {
          return true;
          // let welderStandard = value;
          // let standard = get(model, WELDING_BOOK.STANDARD);
          // return isSameStandard(welderStandard, standard);
        },

        message(key, value, model) {
          return model.translate('model-validations.welding-book.welder-standard');
        },
      },
    },
  };

  metadata = {
    modelName: 'welding-book',
    designation: { required: true },
    revision: { required: true },
    revisionDate: { required: true },
    weldingProject: { required: true },
    supplier: { required: true },
  };

  // Since we're saving the parameters as a JSON raw string, the "old parameters" are not saved
  // and become duplicated, this removes them
  clearNonSavedWelds() {
    let parametersCollection = this.get(WELDING_BOOK.WELDS);
    let toRemove = [];
    parametersCollection.forEach((parameter) => {
      if (isEmpty(parameter.get('id'))) {
        toRemove.pushObject(parameter);
      }
    });
    if (!isEmpty(toRemove)) {
      toRemove.forEach((param) => {
        parametersCollection.removeObject(param);
      });
    }
  }

  @notEmpty('approvedBy.id')
  isApproved;

  @notEmpty('verifiedBy.id')
  isVerified;

  @equal('revisionStatus', 'ACT')
  isActiveRevision;

  @equal('archivalState', ARCHIVAL_STATE.ARCHIVED)
  isArchived;

  createBook = memberAction({ path: 'createBook' });

  fetchRevisions = memberAction({ path: 'fetchRevisions', type: 'GET' });

  updateRevisionId = memberAction({ path: 'updateRevisionId', type: 'PUT' });

  verifyBook = memberAction({ path: 'verifyBook', type: 'PUT' });

  approveBook = memberAction({ path: 'approveBook', type: 'PUT' });

  revokeApproval = memberAction({ path: 'revokeApproval', type: 'PUT' });

  createRevision = memberAction({ path: 'createRevision', type: 'PUT' });

  archive = memberAction({ path: 'archive', type: 'PUT' });

  unarchive = memberAction({ path: 'unarchive', type: 'PUT' });

  deleteRevision = memberAction({ path: 'deleteRevision', type: 'PUT' });

  duplicate = memberAction({ path: 'duplicate', type: 'PUT' });

  copyWeldsToProject = memberAction({ path: 'copyWeldsToProject', type: 'PUT' });
}
