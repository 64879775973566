import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { isEmpty } from '@ember/utils';
import { set, get, action, computed } from '@ember/object';
import { t } from 'ember-intl';
import Constants from 'weldnote/utils/constants';
import { task, timeout } from 'ember-concurrency';

const { WELDER_CERTIFICATE_REVALIDATION_DEADLINES } = Constants;

@classic
export default class ThirtyDaysController extends Controller {
  queryParams = ['page'];

  page = 1;

  @service
  intl;

  @service
  userSession;

  @service
  welderCertificateData;

  @alias('model.list')
  certificates;

  @t('welder-certificate-expiration.externally-expire-30-days-title')
  certificateExpirationTitle;

  @computed('welderCertificateData.deadlineOptions.[]')
  get selectedDeadline() {
    let options = get(this, 'welderCertificateData.deadlineOptions');
    return options.findBy('key', WELDER_CERTIFICATE_REVALIDATION_DEADLINES.MONTH);
  }

  reloadDataTask = task(
    {
      drop: true,
    },
    async (searchOptions = {}) => {
      let options = {};
      options.page = this.page;
      if (!isEmpty(searchOptions.company)) {
        options.company = searchOptions.company;
      }
      if (!isEmpty(searchOptions.deadlineTime)) {
        options.deadlineTime = searchOptions.deadlineTime;
      } else {
        options.deadlineTime = 30;
      }
      await timeout(400);
      let results =
        await this.welderCertificateData.getWelderCertificatesToExternallyValidateCustom(options);
      set(this, 'model', results);
    }
  );

  @action
  postValidate() {
    get(this, 'welderCertificateData').clearCacheExternal();
  }

  @action
  validateSingle(certificate, report1 = '', report2 = '') {
    if (!isEmpty(certificate)) {
      return certificate.validateExternallyToday({
        report1,
        report2,
      });
    }
  }
}
