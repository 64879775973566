import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { set, get, getProperties, action, computed } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class ProjectsController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  sort = null;

  dir = 'asc';

  totalCount = 0;

  currentPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'project';

  @computed
  get gridColumns() {
    let designationLabel = this.getAttributeLabel('designation');
    let projectLabel = this.getAttributeLabel('projectName');
    let customerLabel = this.getAttributeLabel('projectClient');
    let supplierLabel = this.getAttributeLabel('supplier');
    let standardLabel = this.getAttributeLabel('standard');
    let weldsPerformedLabel = this.intl.t('generic.welding.welds-performed');
    let weldsRepairedLabel = this.intl.t('generic.welding.welds-repaired');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'designation',
        label: designationLabel,
        width: '200px',
        cellComponent: 'weldnote-grid/project-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'projectName',
        label: projectLabel,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'customer',
        label: customerLabel,
        cellComponent: 'weldnote-grid/project-client-link',
        breakpoints: ['tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'totalWelds',
        label: weldsPerformedLabel,
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'repairRate',
        label: weldsRepairedLabel,
        cellComponent: 'weldnote-grid/repair-rate',
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
    ];
  }

  buildFilters() {
    let filters = getProperties(this, 'designation', 'projectName', 'supplier');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });

    let selectedProjectClient = get(this, 'selectedProjectClient');
    if (!isEmpty(selectedProjectClient)) {
      finalFilters.projectClient = get(selectedProjectClient, 'id');
    }

    if (filters.supplier) {
      finalFilters.supplier = get(filters, 'supplier.id');
    }
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      let { dir = 'asc', sort } = this;
      if (!isEmpty(sort)) {
        options.sort = {};
        options.sort[sort] = dir;
      }

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await get(this, 'data').listAllProjects(options, this.buildFilters());
      set(this, 'model', data.list);
      set(this, 'totalCount', data.count);
    }
  );

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setDesignation(designation) {
    set(this, 'designation', designation);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setProject(project) {
    set(this, 'projectName', project);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setSupplier(supplier) {
    set(this, 'supplier', supplier);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setClient(client) {
    set(this, 'selectedProjectClient', client);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  clearFields() {
    this.resetPage();
    this.setProperties({
      designation: null,
      projectName: null,
      customer: null,
      supplier: null,
    });
    this.loadData.perform();
  }
}
