import classic from 'ember-classic-decorator';
import EditRoute from 'weldnote/routes/_base/edit';
import { service } from '@ember/service';

@classic
export default class ComponentDrawingRoute extends EditRoute {
  @service('weldnote-data')
  data;

  modelName = 'component-drawing';

  listRoute = 'component-drawings';

  model({ id }) {
    return this.data.loadAllDataComponentDrawing(id);
  }
}
