export default {
  LAYER_OPTIONS: {
    SINGLE_LAYER: 'SL',
    MULTI_LAYER: 'ML',
  },

  WELDING_POSITION_DIRECTION_OPTIONS: {
    UPHILL: 'UH',
    DOWNHILL: 'DH',
  },

  WELD_TYPES: {
    BUTT_WELD: 'BW',
    FILLET_WELD: 'FW',
    BRANCH_CONNECTION: 'BC',
    TACK_WELD: 'TW',
    COMPLETE_JOINT_PENETRATION_BUTT_WELD: 'CJP BW',
    PARTIAL_JOINT_PENETRATION_BUTT_WELD: 'PJP BW',
    OVERLAY_WELD: 'OW',
    PARTIAL_PENETRATION_BUTT_WELD: 'PP BW',
  },

  WELDING_DETAILS: {
    MULTI_LAYER: 'ml',
    SINGLE_LAYER: 'sl',
    SINGLE_SIDE_NO_BACKING: 'ss nb',
    SINGLE_SIDE_MATERIAL_BACKING: 'ss mb',
    SINGLE_SIDE_GAS_BACKING: 'ss gb',
    SINGLE_SIDE_FLUX_BACKING: 'ss fb',
    BOTH_SIDES: 'bs',
    CONSUMABLE_INSERT: 'ci',
  },

  OVERLAY_TYPE_OPTIONS: {
    HARD_FACING: 'HF',
    CORROSION_RESISTANT: 'CR',
  },

  SHIELDING_OPTIONS: {
    GAS: 'G',
    FLUX: 'F',
    NONE: 'N',
  },

  PRODUCT_TYPE: {
    PIPE: 'PI',
    PLATE: 'PL',
    PIPE_PLATE: 'PP',
  },

  ARC_TRANSFER_MODES: {
    SPRAY: 'Spray',
    GLOBULAR: 'Globular',
    PULSED_SPRAY: 'Pulsed-spray',
    SHORT_ARC: 'Short-arc',
  },

  CERTIFICATE_STATE: {
    ACTIVE: 'AC',
    ARCHIVED: 'AR',
    INTERNALLY_EXPIRED: 'IE',
    EXTERNALLY_EXPIRED: 'EE',
  },

  CERTIFICATE_TYPE: {
    WELDER: 'WELDER',
    OPERATOR: 'OPERATOR',
  },

  MISCELLANEOUS_CERTIFICATE_STATE: {
    ACTIVE: 'AC',
    ARCHIVED: 'AR',
    EXPIRED: 'EX',
  },

  JOB_KNOWLEDGE_OPTIONS: {
    ACCEPTABLE: 'A',
    NOT_TESTED: 'N',
  },

  HTTP: {
    DEBOUNCE: 400,
  },

  WELDING_LAYER_OPTIONS: {
    ROOT: 'ROOT',
    FILL: 'FILL',
    CAP: 'CAP',
  },

  ERROR_CODES: {
    DUPLICATED_INSTANCE: '23000',
    ERROR_SAVING: '2000',
    PERMISSION_DENIED: '1000',
    MISSING_STAFF: '1100',
    MISSING_USER: '2010',
    FOREIGN_KEY: '23503',
    INVALID_PQR_FOR_WPS: '8005',
    CERTIFICATE_INTERNAL_DEADLINE_AFTER_EXTERNAL_DEADLINE: '4050',
    WELDER_STANDARD_NOT_SUPPORTED: '31000',
    SESSION_EXPIRED: '40010',
    PROJECT_WELD_INTERMEDIATE_RESTORE: '41012',
    PROJECT_WELD_USED_BY_OTHERS: '41013',
  },

  GROOVE_SHAPE_OPTIONS: {
    V_SHAPE: 'GROOVE_V',
    DOUBLE_V_SHAPE: 'GROOVE_DOUBLE_V',
    HALF_V_SHAPE: 'GROOVE_HALF_V',
    J_SHAPE: 'GROOVE_J',
    DOUBLE_J_SHAPE: 'GROOVE_DOUBLE_J',
    U_SHAPE: 'GROOVE_U',
    DOUBLE_U_SHAPE: 'GROOVE_DOUBLE_U',
    K_SHAPE: 'GROOVE_K',
    X_SHAPE: 'GROOVE_X',
    SQUARE_SHAPE: 'GROOVE_SQUARE',
    NO_SHAPE: 'GROOVE_NONE',
  },

  JOINT_TYPE_OPTIONS: {
    BUTT_JOINT: 'BJ',
    CORNER_JOINT: 'CJ',
    T_JOINT: 'TJ',
    SOCKET_JOINT: 'SJ',
  },

  PERMISSIONS: {
    ACCOUNT_ADMIN: 'admin',
    PQR_READ: 'pqr-read',
    PQR_CREATE: 'pqr-create',
    PQR_DELETE: 'pqr-delete',
    PQR_CREATE_WPS: 'pqr-create-wps',
    PQR_EDIT_APPROVAL_RANGE: 'pqr-edit-approval-range',
    PQR_ARCHIVE: 'pqr-archive',
    WPS_READ: 'wps-read',
    WPS_CREATE: 'wps-create',
    WPS_CREATE_REVISION: 'wps-create-revision',
    WPS_ASSIGN_STAFF: 'wps-assign-staff',
    WPS_DELETE: 'wps-delete',
    WPS_ARCHIVE: 'wps-archive',
    WPS_VERIFY: 'wps-verify',
    WPS_APPROVE: 'wps-approve',
    WPS_REVOKE: 'wps-revoke',
    PWPS_READ: 'pwps-read',
    PWPS_CREATE: 'pwps-create',
    PWPS_DELETE: 'pwps-delete',
    PWPS_CREATE_CERTIFICATE: 'pwps-create-certificate',
    PWPS_CREATE_PQR: 'pwps-create-pqr',
    WELDER_CERTIFICATE_READ: 'certificate-read',
    WELDER_CERTIFICATE_CREATE: 'certificate-create',
    WELDER_CERTIFICATE_DELETE: 'certificate-delete',
    WELDER_CERTIFICATE_ARCHIVE: 'certificate-archive',
    WELDER_CERTIFICATE_REVALIDATE: 'certificate-revalidate',
    WELDER_CERTIFICATE_EDIT_APPROVAL_RANGE: 'certificate-edit-approval-range',
    WELDING_BOOK_READ: 'welding-book-read',
    WELDING_BOOK_CREATE: 'welding-book-create',
    WELDING_BOOK_CREATE_REVISION: 'welding-book-create-revision',
    WELDING_BOOK_VERIFY: 'welding-book-verify',
    WELDING_BOOK_APPROVE: 'welding-book-approve',
    WELDING_BOOK_ARCHIVE: 'welding-book-archive',
    WELDING_BOOK_DELETE: 'welding-book-delete',
    FILLER_MATERIAL_CREATE: 'filler-material-create',
    FILLER_MATERIAL_DELETE: 'filler-material-delete',
    BASE_MATERIAL_CREATE: 'base-material-create',
    BASE_MATERIAL_DELETE: 'base-material-delete',
    WELDER_ARCHIVE: 'welder-archive',
    WELDER_CREATE: 'welder-create',
    WELDER_DELETE: 'welder-delete',
    PROJECT_ARCHIVE: 'project-archive',
    PROJECT_READ: 'project-read',
    PROJECT_CREATE: 'project-create',
    PROJECT_DELETE: 'project-delete',
    PROJECT_WELD_CREATE: 'project-weld-create',
    PROJECT_WELD_DELETE: 'project-weld-delete',
    GENERIC_WRITE_PERMISSION: 'generic-write-permission',
    QMATRIX_CREATE: 'qmatrix-create',
    QMATRIX_DELETE: 'qmatrix-delete',
  },

  UNIT_SYSTEM: {
    METRIC: 'M',
    IMPERIAL: 'I',
  },

  UNITS: {
    MILLIMETER: 'mm',
    INCH: 'in',
    CELSIUS: 'ºC',
    FAHRENHEIT: 'ºF',
    LITER_MINUTE: 'l/min',
    CUBIC_FEET_HOUR: 'CFH',
    KILO_JOULE_MILLIMETER: 'kjmm',
    KILO_JOULE_INCH: 'kjin',
    MILLIMETER_SECOND: 'mms',
    MILLIMETER_MINUTE: 'mmmin',
    METER_MINUTE: 'mmin',
    INCH_MINUTE: 'inmin',
    NEWTON: 'N',
    POUNDS: 'lbs',
    NEWTON_SQUARE_MILIMETER: 'N/mm^2',
    PSI: 'psi',
    SQUARE_MILLIMETER: 'mm^2',
    SQUARE_INCH: 'sq. in.',
    JOULE: 'J',
    FEET_PER_POUND: 'ft. lbs',
  },

  WELDING_PROCESSES: {
    GTAW: 'GTAW',
    FCAW: 'FCAW',
    SMAW: 'SMAW',
    SAW: 'SAW',
    GMAW: 'GMAW',
    MCAW: 'MCAW',
    FCAWS: 'FCAW-S',
    ESW: 'ESW',
    111: '111',
    121: '121',
    122: '122',
    123: '123',
    124: '124',
    125: '125',
    131: '131',
    135: '135',
    136: '136',
    137: '137',
    138: '138',
    141: '141',
    142: '142',
    143: '143',
    145: '145',
  },

  PROCESS_MECANIZATION: {
    MANUAL: 'Manual',
    SEMI_AUTOMATIC: 'Semi-automatic',
    AUTOMATIC: 'Automatic',
    MECHANIZED: 'Mechanized',
  },

  TEST_RESULTS: {
    PASSED: 'P',
    FAILED: 'F',
  },

  BEND_TEST_TYPE: {
    SIDE_BEND: 'SIDE_BEND',
    TRANSVERSAL: 'TRANSVERSAL',
    FACE_BEND: 'FACE_BEND',
    LONGITUDINAL: 'LONGITUDINAL',
    ROOT_BEND: 'ROOT_BEND',
    BEND_BREAK: 'BEND_BREAK',
  },

  DEFAULT_DATE_FORMAT: 'DD/MM/YYYY',
  DEFAULT_SMALL_YEAR_FORMAT: 'DD/MM/YY',
  DEFAULT_DECIMAL_SEPARATOR: '.',

  FEATURES: {},

  WELDING_POSITIONS: {
    PA: 'PA',
    PC: 'PC',
  },

  PWHT_PRESENCE: {
    YES: 'YES',
    NO: 'NO',
    ABSENT: 'ABSENT',
  },

  PROJECT_STATUS: {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    FINISHED: 'FINISHED',
  },

  WPS_TYPE: {
    PRIMARY: 'PRI',
    PROJECT: 'PRO',
    PRELIMINARY: 'PWPS',
    PREQUALIFIED: 'PRE',
  },

  PRODUCT_FORM: {
    PLATE: 'PLATE',
    SHEET: 'SHEET',
    BAR: 'BAR',
    ROD: 'ROD',
    FUSION_WELDED_PIPE: 'FUSION_WELDED_PIPE',
    RESISTANCE_WELDED_PIPE: 'RESISTANCE_WELDED_PIPE',
    SEAMLESS_PIPE: 'SEAMLESS_PIPE',
    CAST_PIPE: 'CAST_PIPE',
    FORGED_PIPE: 'FORGED_PIPE',
    FLANGE: 'FLANGE',
    FITTING: 'FITTING',
    FORGING: 'FORGING',
    CASTING: 'CASTING',
    I_BEAM: 'I_BEAM',
    H_BEAM: 'H_BEAM',
    T_BEAM: 'T_BEAM',
    L_BEAN: 'L_BEAN',
    U_BEAM: 'U_BEAM',
    ELBOW_90_DEGREES: 'ELBOW_90_DEGREES',
    ELBOW_45_DEGREES: 'ELBOW_45_DEGREES',
    FLANGE_RTJ: 'FLANGE_RTJ',
    FLANGE_RF: 'FLANGE_RF',
    FLANGE_FF: 'FLANGE_FF',
    ECC_RED: 'ECC_RED',
    CONC_RED: 'CONC_RED',
    TEE_EQ: 'TEE_EQ',
    TEE_RED: 'TEE_RED',
    CAP: 'CAP',
    STUB_END: 'STUB_END',
    WELDOLET: 'WELDOLET',
    WELDOFLANGEL: 'WELDOFLANGEL',
    THREADOLET: 'THREADOLET',
    GASKET_RTJ: 'GASKET_RTJ',
    GASKET_FLAT: 'GASKET_FLAT',
    GASKET: 'GASKET',
    TUBE: 'TUBE',
    ANGLE: 'ANGLE'
  },

  FILLER_MATERIAL_ELECTRODE_FORM: {
    WIRE: 'WIRE',
    PLATE: 'PLATE',
  },

  FILLER_MATERIAL_GUIDE: {
    CONSUMABLE: 'CONSUMABLE',
    NON_CONSUMABLE: 'NON_CONSUMABLE',
  },

  CERTIFICATE_REVALIDATION_TYPE: {
    INTERNAL: 'INTERNAL',
    EXTERNAL: 'EXTERNAL',
  },

  REVISION_STATUS: {
    ACTIVE: 'ACT',
    REVISION: 'REV',
  },

  WELD_CONDITION: {
    GOOD: 'OK',
    DAMAGED: 'NOK',
  },

  WELD_SITUATION: {
    ACTIVE: 'ACT',
    DELETED: 'DEL',
  },

  COMPONENT_PROGRESS_STATE: {
    INITIAL: 1,
    COMPLETED: 2,
    TESTED: 4,
    PROCESSED: 8,
    SHIPPED: 16,
  },

  COMPONENT_SITUATION: {
    ACTIVE: 'ACT',
    DELETED: 'DEL',
  },

  WELD_PROGRESS_STATE: {
    INITIAL: 1,
    FIT: 2,
    WELDED: 4,
    PWHT_COMPLETED: 8,
    TESTED: 16,
    PROCESSED: 32,
    SHIPPED: 64,
  },

  WELD_PROGRESS_STATES_ARRAY: [
    {
      name: 'Initial',
      labelKey: 'components.model-project-weld/edit.weld-progress.waiting',
    },
    {
      name: 'Fit',
      labelKey: 'components.model-project-weld/edit.weld-progress.fitted',
    },
    {
      name: 'Welded',
      labelKey: 'components.model-project-weld/edit.weld-progress.welded',
    },
    {
      name: 'Completed',
      labelKey: 'components.model-project-weld/edit.weld-progress.pwht-complete',
    },
    {
      name: 'Tested',
      labelKey: 'components.model-project-weld/edit.weld-progress.tested',
    },
    {
      name: 'Processed',
      labelKey: 'components.model-project-weld/edit.weld-progress.processed',
    },
    {
      name: 'Shipped',
      labelKey: 'components.model-project-weld/edit.weld-progress.shipped',
    },
  ],

  DOCUMENT_TYPE_OPTIONS: {
    COATING_REPORT: 'COATING',
    PAINTING_REPORT: 'PAINTING',
    INSPECTION_TEST_PLAN: 'INSPECTION',
    RECEIVED_MATERIAL_REPORT: 'MATERIAL_REPORT',
    NON_CONFORMITY_REPORT: 'NON_CONFORMITY',
    PROCEDURE: 'PROCEDURE',
    PURCHASE_ORDER: 'PURCHASE',
    QUALITY_PLAN: 'QUALITY_PLAN',
    REQUEST_FOR_INFORMATION: 'RFI',
    SCOPE_OF_WORK: 'SCOPE',
    SPECIFICATION: 'SPECIFICATION',
    OTHER: 'OTHER',
  },

  WELDER_CERTIFICATE_REVALIDATION_DEADLINES: {
    TODAY: 'today',
    WEEK: '7days',
    MONTH: '30days',
    TWO_MONTH: '60days',
    THREE_MONTH: '90days',
    CUSTOM: 'custom',
  },

  ARCHIVAL_STATE: {
    ACTIVE: 'AC',
    ARCHIVED: 'AR',
  },

  CERTIFICATE_INTERNAL_REVALIDATION_TYPE: {
    REGULAR: 'regular',
    INTERNAL: 'internal',
    EXTERNAL: 'external',
  },

  TYPE_OF_PERSON: {
    WELDER: 'WELDER',
    FITTER: 'FITTER',
    INSPECTOR: 'INSPECTOR',
  },

  WPS_INCLUSION: {
    INCLUDE_UPLOADED: 'UP',
    GENERATE_PDF: 'GEN',
  },

  PROJECT_REFERENCE_TYPE: {
    MANUAL: 'M',
    REFERENCE: 'R',
  },

  WELDNOTE_PLANS: {
    WPS_ONLY: 'WPS_ONLY',
    CERTIFICATES: 'CERTIFICATES',
    QUALITY: 'QUALITY',
  },

  TASK_STATUS: {
    INITIAL: 100,
    IN_PROGRESS: 200,
    FINISHED: 250,
    IN_ERROR: 500,
  },

  OVERLAY_FILLER_MATERIAL_FORM: {
    WIRE: 'WIRE',
    STRIP: 'STRIP',
  },

  FILE_CONTEXT: {
    GROOVE_DESIGN: 'groove-design',
    WELD_LAYER_CONFIGURATION: 'weld-layer',
    COMPANY_LOGO: 'logo',
    PQR_PDF_DOCUMENT: 'pqr-pdf-document',
    PQR_REPORT_DOCUMENT: 'pqr-report',
    PQR_TEST: 'pqr-test',
    WPS_PDF_DOCUMENT: 'wps-pdf-document',
    IEIS_PDF_DOCUMENT: 'ieis-pdf-document',
    WELDER_CERTIFICATE_REPORT: 'welder-certificate-report',
    WELDER_CERTIFICATE_PDF_DOCUMENT: 'welder-certificate-pdf-document',
    WELDER_CERTIFICATE_TEST: 'welder-certificate-test',
    MISCELLANEOUS_CERTIFICATE_PDF_DOCUMENT: 'miscellaneous-certificate-pdf-document',
    WPS_TEMPLATE: 'wps-template',
    WELDER_REPORT: 'welder-report',
    FITTER_REPORT: 'fitter-report',
    INSPECTOR_REPORT: 'inspector-report',
    PROJECT_BASE_MATERIAL_CERTIFICATE: 'project-base-material-certificate',
    PROJECT_FILLER_MATERIAL_CERTIFICATE: 'project-filler-material-certificate',
    PROJECT_REPORT_DOCUMENT: 'project-report',
    PROJECT_EXCEL_WELD_EXPORT: 'project-weld-excel-report',
    PROJECT_DRAWING: 'project-drawing',
    PROJECT_COMPONENT_DRAWING: 'project-component-drawing',
    PROJECT_TEST: 'project-test',
    PROJECT_DOCUMENT: 'project-document',
    PROJECT_COVER: 'project-cover',
    WELDING_BOOK_REPORT: 'welding-book-report',
    WELDING_BOOK_COVER: 'welding-book-cover',
    WELDING_BOOK_DRAWING: 'welding-book-drawing',
    WPS_PDF: 'wps-pdf',
    SIGNATURE: 'signature',
  },

  NON_DESTRUCTIVE_TEST_TYPE: {
    VT: 'VT', 
    MT: 'MT',
    RT: 'RT',
    UT: 'UT',
    PT: 'PT'
  },
};
