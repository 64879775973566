export default {
  FITTER: {
    FITTER_NAME: 'fitterName',
    FITTER_STAMP: 'fitterStamp',
    FITTER_ID: 'fitterId',
    FITTER_TYPE: 'fitterType',
    DATE_BIRTH: 'dateBirth',
    COMPANY: 'company',
  },
};
