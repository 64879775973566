export default {
  GENERAL_DRAWING: {
    PROJECT: 'project',
    TITLE: 'title',
    REFERENCE_NUMBER: 'referenceNumber',
    DRAWING_CONTENT: 'drawingContent',
    REVISION: 'revision',
    REVISION_STATUS: 'revisionStatus',
  },
};
