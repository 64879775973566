import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (has-value @value)}}\n  {{this.displayValue}}\n{{/if}}", {"contents":"{{#if (has-value @value)}}\n  {{this.displayValue}}\n{{/if}}","moduleName":"weldnote/components/weldnote-grid/base-material-product-form.hbs","parseOptions":{"srcName":"weldnote/components/weldnote-grid/base-material-product-form.hbs"}});
import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Component from '@ember/component';
import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

@classic
export default class BaseMaterialProductForm extends Component {
  @service('weldnote-data')
  data;

  @computed('value', 'data.productFormOptions')
  get displayValue() {
    let value = get(this, 'value');
    if (!isEmpty(value)) {
      let result = get(this, 'data.productFormOptions').filter((v) => {
        if (get(v, 'key') === value) {
          return true;
        }
      });
      if (!isEmpty(result)) {
        return get(result, 'firstObject.label');
      }
    }
    return '';
  }
}
