import { isEmpty } from '@ember/utils';

export default {
  isGroup1(group: string): boolean {
    return '1.1' === group || '1.2' === group || '1.3' === group || '1.4' === group;
  },

  isGroup2(group: string): boolean {
    return '2.1' === group || '2.2' === group;
  },

  isGroup3(group: string): boolean {
    return '3.1' === group || '3.2' === group || '3.3' === group;
  },

  isGroup4(group: string): boolean {
    return '4.1' === group || '4.2' === group;
  },

  isGroup5(group: string): boolean {
    return '5.1' === group || '5.2' === group || '5.3' === group || '5.4' === group;
  },

  isGroup6(group: string): boolean {
    return '6.1' === group || '6.2' === group || '6.3' === group || '6.4' === group;
  },

  isGroup8(group: string): boolean {
    return '8.1' === group || '8.2' === group || '8.3' === group || '8.4' === group;
  },

  isGroup10(group: string): boolean {
    return '10.1' === group || '10.2' === group;
  },

  isGroup11(group: string): boolean {
    return '11.1' === group || '11.2' === group;
  },

  isGroupExactly40(group: string): boolean {
    let groupInt = parseInt(group, 10);
    return groupInt === 40;
  },

  isGroupExactly48(group: string): boolean {
    let groupInt = parseInt(group, 10);
    return groupInt === 48;
  },

  isGroup40(group: string): boolean {
    let groupInt = parseInt(group, 10);
    return groupInt >= 40 && groupInt <= 49;
  },

  isGroup80(group: string): boolean {
    let groupInt = parseInt(group, 10);
    return groupInt >= 80 && groupInt <= 89;
  },

  isGroup8OrAnyGroup40(group: string): boolean {
    let groupInt = parseInt(group, 10);
    let isGroup40 = groupInt >= 40 && groupInt <= 49;
    let isGroup80 = groupInt >= 80 && groupInt <= 89;
    return isGroup40 || isGroup80;
  },

  isSameGroup(group1: string, group2: string) {
    if (isEmpty(group1) || isEmpty(group2)) {
      return false;
    }
    if (group1 === group2) {
      return true;
    }

    let dotPositionGroup1 = group1.indexOf('.');
    let dotPositionGroup2 = group1.indexOf('.');

    if (dotPositionGroup1 > -1 && dotPositionGroup2 > -1) {
      let majorGroup1 = group1.substring(0, dotPositionGroup1);
      let majorGroup2 = group2.substring(0, dotPositionGroup2);
      if (majorGroup1 === majorGroup2) {
        return true;
      } else {
        return false;
      }
    }
    return group1 === group2;
  },

  getRuleFor1dot1(): string[] {
    return ['1.1'];
  },

  getRuleFor1dot2(): string[] {
    return ['1.2'].concat(this.getRuleFor1dot1());
  },

  getRuleFor1dot3(): string[] {
    return ['1.3'].concat(this.getRuleFor1dot2());
  },

  getRuleFor1dot4(): string[] {
    return ['1.4'];
  },

  getRuleFor1All(): string[] {
    return this.getRuleFor1dot4().concat(this.getRuleFor1dot3());
  },

  getRuleFor2dot1(): string[] {
    return ['2.1'].concat(this.getRuleFor1All());
  },

  getRuleFor2dot2(): string[] {
    return ['2.2'].concat(this.getRuleFor2dot1());
  },

  getRuleFor3dot1(): string[] {
    return ['3.1'].concat(this.getRuleFor2dot2());
  },

  getRuleFor3dot2(): string[] {
    return ['3.2'].concat(this.getRuleFor3dot1());
  },

  getRuleFor3dot3(): string[] {
    return ['3.3'].concat(this.getRuleFor3dot2());
  },

  getRuleFor4dot1(): string[] {
    return ['4.1'].concat(this.getRuleFor2dot2());
  },

  getSimpleRuleFor4dot1(): string[] {
    return ['4.1'];
  },

  getRuleFor4dot2(): string[] {
    return ['4.2'].concat(this.getRuleFor4dot1());
  },

  getSimpleRuleFor4dot2(): string[] {
    return ['4.2'].concat(this.getSimpleRuleFor4dot1());
  },

  getRuleFor5dot1(): string[] {
    return ['5.1'].concat(this.getRuleFor2dot2());
  },

  getSimpleRuleFor5dot1(): string[] {
    return ['5.1'];
  },

  getRuleFor5dot2(): string[] {
    return ['5.2'].concat(this.getRuleFor5dot1());
  },

  getSimpleRuleFor5dot2(): string[] {
    return ['5.2'].concat(this.getSimpleRuleFor5dot1());
  },

  getRuleFor5dot3(): string[] {
    return ['5.3'].concat(this.getRuleFor5dot2());
  },

  getSimpleRuleFor5dot3(): string[] {
    return ['5.3'].concat(this.getSimpleRuleFor5dot2());
  },

  getRuleFor5dot4(): string[] {
    return ['5.4'].concat(this.getRuleFor5dot3());
  },

  getSimpleRuleFor5dot4(): string[] {
    return ['5.4'].concat(this.getSimpleRuleFor5dot3());
  },

  getRuleFor6dot1(): string[] {
    return ['6.1'].concat(this.getRuleFor2dot2());
  },

  getSimpleRuleFor6dot1(): string[] {
    return ['6.1'];
  },

  getRuleFor6dot2(): string[] {
    return ['6.2'].concat(this.getRuleFor6dot1());
  },

  getSimpleRuleFor6dot2(): string[] {
    return ['6.2'].concat(this.getSimpleRuleFor6dot1());
  },

  getRuleFor6dot3(): string[] {
    return ['6.3'].concat(this.getRuleFor6dot2());
  },

  getSimpleRuleFor6dot3(): string[] {
    return ['6.3'].concat(this.getSimpleRuleFor6dot2());
  },

  getRuleFor6dot4(): string[] {
    return ['6.4'].concat(this.getRuleFor6dot3());
  },

  getSimpleRuleFor6dot4(): string[] {
    return ['6.4'].concat(this.getSimpleRuleFor6dot3());
  },

  getRuleFor7dot1(): string[] {
    return ['7.1'];
  },

  getRuleFor7dot2(): string[] {
    return ['7.2'];
  },

  getRuleFor7dot3(): string[] {
    return ['7.3'];
  },

  getRuleFor8dot1(): string[] {
    return ['8.1'];
  },

  getRuleFor8dot2(): string[] {
    return ['8.2'].concat(this.getRuleFor8dot1());
  },

  getRuleFor8dot3(): string[] {
    return ['8.3'].concat(this.getRuleFor8dot2());
  },

  getRuleFor8dot4(): string[] {
    return ['8.4'].concat(this.getRuleFor8dot3());
  },

  getRuleFor9dot1(): string[] {
    return ['9.1'];
  },

  getRuleFor9dot2(): string[] {
    return ['9.2'].concat(this.getRuleFor9dot1());
  },

  getRuleFor9dot3(): string[] {
    return ['9.3'].concat(this.getRuleFor9dot2());
  },

  getRuleFor10dot1(): string[] {
    return ['10.1'];
  },

  getRuleFor10dot2(): string[] {
    return ['10.2'].concat(this.getRuleFor10dot1());
  },

  getRuleFor11dot1(): string[] {
    return ['11.1'];
  },

  getRuleFor11dot2(): string[] {
    return ['11.2'].concat(this.getRuleFor11dot1());
  },

  getRuleFor11dot3(): string[] {
    return ['11.3'].concat(this.getRuleFor11dot2());
  },

  // Rules for arrays
  getRulesFor2(group: string): string[] {
    if (group === '2.2') {
      return this.getRuleFor2dot2();
    } else if (group === '2.1') {
      return this.getRuleFor2dot1();
    }
    return [];
  },

  getRulesFor5(group: string): string[] {
    if (group === '5.1') {
      return this.getRuleFor5dot1();
    } else if (group === '5.2') {
      return this.getRuleFor5dot2();
    } else if (group === '5.3') {
      return this.getRuleFor5dot3();
    } else if (group === '5.4') {
      return this.getRuleFor5dot4();
    }
    return [];
  },

  getRulesFor6(group: string): string[] {
    if (group === '6.1') {
      return this.getRuleFor6dot1();
    } else if (group === '6.2') {
      return this.getRuleFor6dot2();
    } else if (group === '6.3') {
      return this.getRuleFor6dot3();
    } else if (group === '6.4') {
      return this.getRuleFor6dot4();
    }
    return [];
  },

  getRulesFor7(group: string): string[] {
    if (group === '7.1') {
      return this.getRuleFor7dot1();
    } else if (group === '7.2') {
      return this.getRuleFor7dot2();
    } else if (group === '7.3') {
      return this.getRuleFor7dot3();
    }
    return [];
  },

  getRulesFor8(group: string): string[] {
    if (group === '8.1') {
      return this.getRuleFor8dot1();
    } else if (group === '8.2') {
      return this.getRuleFor8dot2();
    } else if (group === '8.3') {
      return this.getRuleFor8dot3();
    } else if (group === '8.4') {
      return this.getRuleFor8dot4();
    }
    return [];
  },

  getRulesFor10(group: string): string[] {
    if (group === '10.1') {
      return this.getRuleFor10dot1();
    } else if (group === '10.2') {
      return this.getRuleFor10dot2();
    }
    return [];
  },

  getSimpleRulesFor8(group: string): string[] {
    return [group];
  },

  getSimpleRulesFor5(group: string): string[] {
    if (group === '5.4') {
      return this.getSimpleRuleFor5dot4();
    } else if (group === '5.3') {
      return this.getSimpleRuleFor5dot3();
    } else if (group === '5.2') {
      return this.getSimpleRuleFor5dot2();
    } else if (group === '5.1') {
      return this.getSimpleRuleFor5dot1();
    }
    return [];
  },

  getRulesFor11(group: string): string[] {
    if (group === '11.1') {
      return this.getRuleFor11dot1();
    } else if (group === '11.2') {
      return this.getRuleFor11dot2();
    } else if (group === '11.3') {
      return this.getRuleFor11dot3();
    }
    return [];
  },

  getRulesFor40(group: string): string[] {
    return [group];
  },
};
