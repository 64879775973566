import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import EditRoute from 'weldnote/routes/_base/edit';

@classic
export default class PqrRoute extends EditRoute {
  @service
  userSession;

  @service('weldnote-data')
  data;

  @service
  weldcloudNotesData;

  @service
  router;

  modelName = 'pqr';

  listRoute = 'pqrs';

  async beforeModel() {
    super.beforeModel(...arguments);
    let session = this.userSession;
    await this.weldcloudNotesData.fetchAllData.perform();
    if (!session.canCreatePQR) {
      this.router.transitionTo('pqrs');
    }
  }

  model({ id }) {
    return this.data.loadAllDataPQR(id);
  }
}
