import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import EditController from 'weldnote/controllers/_base/edit';
import { service } from '@ember/service';

@classic
export default class WeldingProcedureSpecificationController extends EditController {
  @service
  router;

  queryParams = ['section', 'project', 'autoScroll'];

  section = 'details';

  autoScroll = null;

  project = null;

  @action
  revisionCreated(wps) {
    this.router.transitionTo('welding-procedure-specification', wps);
  }

  @action
  newWPSCreated(wps) {
    this.router.transitionTo('welding-procedure-specification', wps);
  }

  @action
  afterCreateWps(wps) {
    this.router.transitionTo('welding-procedure-specification-view', wps.id);
  }

  @action
  deleteInstance(type) {
    if (type === 'wps') {
      this.router.transitionTo('welding-procedure-specifications');
    } else if (type === 'pwps') {
      this.router.transitionTo('welding-procedure-specifications-preliminary');
    }
  }
}
