import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{@row.fillerMaterialType.specification.specification}}\n{{@row.fillerMaterialType.classification.classification}}\n{{#unless (is-empty @row.fillerMaterialDiameters)}}\n  <span class=\"block text-gray-600 text-lg italic\">\n    {{array-join (map (action \"formatDiameter\") @row.fillerMaterialDiameters) separator=\" ; \"}}\n    ({{this.unit}})\n  </span>\n{{/unless}}", {"contents":"{{@row.fillerMaterialType.specification.specification}}\n{{@row.fillerMaterialType.classification.classification}}\n{{#unless (is-empty @row.fillerMaterialDiameters)}}\n  <span class=\"block text-gray-600 text-lg italic\">\n    {{array-join (map (action \"formatDiameter\") @row.fillerMaterialDiameters) separator=\" ; \"}}\n    ({{this.unit}})\n  </span>\n{{/unless}}","moduleName":"weldnote/components/weldnote-grid/pqr-view/filler-material.hbs","parseOptions":{"srcName":"weldnote/components/weldnote-grid/pqr-view/filler-material.hbs"}});
import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { isKnownFractionString, toFraction } from 'weldnote/utils/unit-system/units';

@classic
export default class FillerMaterial extends Component {
  @service
  userSession;

  @alias('userSession.distanceUnit')
  unit;

  @action
  formatDiameter(value) {
    if (this.userSession.isImperialSystem) {
      let fraction = toFraction(value);
      if (isKnownFractionString(fraction)) {
        return fraction;
      }
    }
    return value;
  }
}
