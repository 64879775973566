export default {
  FILLER_MATERIAL_CERTIFICATE: {
    PROJECT: 'project',
    FILLER_MATERIAL: 'fillerMaterial',
    COMMERCIAL_DESIGNATION: 'commercialDesignation',
    BATCH_NUMBER: 'batchNumber',
    CERTIFICATE: 'certificate',
    DIAMETER: 'diameter',
  },
};
