import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class ActionLog extends BaseModel {
  @attr('date')
  declare actionTime: Date;

  @attr('number')
  declare instanceId: number;

  @attr('string')
  declare actionId: string;

  @attr('string')
  declare instanceType: string;

  @attr('string')
  declare author: string;

  @attr('string')
  declare label?: string;

  @attr('boolean')
  declare exists?: boolean;

  metadata = {
    modelName: 'action-log',
    actionId: { required: true },
    instanceType: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'action-log': ActionLog;
  }
}
