import { alias } from '@ember/object/computed';
import { belongsTo, attr } from '@ember-data/model';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';

export default class WeldingBookWeldInspection extends BaseModel {
  @belongsTo('welding-book-weld', { async: false })
  weld;

  @belongsTo('non-destructive-testing', { async: false })
  test;

  @attr('number')
  percentage;

  @belongsTo('test-stage-name', { async: false })
  testStageName;

  @belongsTo('test-location', { async: false })
  testLocation;

  @alias('weld')
  visualLabel;

  validations = {
    weld: {
      custom: {
        validation(_key, value) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key, _value, model) {
          return model.translate('generic.error.input-value');
        },
      },
    },

    test: {
      custom: {
        validation(_key, value) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key, _value, model) {
          return model.translate('generic.error.input-value');
        },
      },
    },

    percentage: {
      numericality: {
        allowBlank: false,
        lessThanOrEqualTo: 100,
        greaterThanOrEqualTo: 0,
        onlyInteger: true,
      },
    },
  };

  metadata = {
    modelName: 'welding-book-weld-inspection',
  };
}
