import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { set, get, computed } from '@ember/object';
import { task } from 'ember-concurrency';

@classic
export default class ArchivedController extends Controller {
  @service
  intl;

  @service('weldnote-data')
  data;

  queryParams = ['page'];

  @service
  welderCertificateData;

  @alias('model.list')
  certificates;

  @alias('model.count')
  certificateCount;

  page = 1;

  loadData = task(async (pageNumber = 1) => {
    let results = await this.welderCertificateData.getMiscellaneousCertificatesArchived({
      page: pageNumber,
    });
    set(this, 'model', results);
  });

  @computed
  get gridColumns() {
    let { intl } = this;
    let certificateNumberLabel = intl.t('model.miscellaneous-certificate.certificateNumber.label');
    let stampLabel = intl.t('model.miscellaneous-certificate.welder.label');
    let typeLabel = intl.t('model.miscellaneous-certificate.type.label');
    let expirationDateLabel = intl.t('model.miscellaneous-certificate.expirationDate.label');
    let personLabel = intl.t('model.miscellaneous-certificate.person.label');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'certificateNumber',
        label: certificateNumberLabel,
        cellComponent: 'weldnote-grid/miscellaneous-certificate-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'type',
        label: typeLabel,
        breakpoints: ['tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'personName',
        label: personLabel,
        cellComponent: 'weldnote-grid/person-name',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'expirationDate',
        label: expirationDateLabel,
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
    ];
  }
}
