import { isEmpty } from '@ember/utils';

export function inRange(valueRaw: string, minimumRaw: string, maximumRaw: string) {
  if (isEmpty(valueRaw)) {
    return true;
  }

  let value = parseFloat(valueRaw);
  let minimum = null;
  if (!isEmpty(minimumRaw)) {
    minimum = parseFloat(minimumRaw);
  }
  let maximum = null;
  if (!isEmpty(maximumRaw)) {
    maximum = parseFloat(maximumRaw);
  }

  if (minimum && !isEmpty(minimum) && maximum && !isEmpty(maximum)) {
    return value <= maximum && value >= minimum;
  }

  if (minimum && !isEmpty(minimum)) {
    return value >= minimum;
  }

  if (maximum && !isEmpty(maximum)) {
    return value <= maximum;
  }

  return true;
}

export function rangeSerialization(
  min: string | number | null,
  max: string | number | null
): string {
  if (!isEmpty(min) && !isEmpty(max)) {
    if (min === max) {
      return `${max}`;
    } else {
      return `${min} ≤ X ≤ ${max}`;
    }
  }
  if (!isEmpty(min)) {
    return ` ≥ ${min}`;
  }

  if (!isEmpty(max)) {
    return ` ≤ ${max}`;
  }

  return '';
}
