import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { hash } from 'rsvp';
import { getProperties, get, set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import EditRoute from 'weldnote/routes/_base/edit';
import Rules from 'weldnote/utils/welding-procedure-specification-rules';
import WpsValidation from 'weldnote/utils/validation/wps-from-pqr';
import AwsB21Validator from 'weldnote/src-weldnote/wps/validation/aws-b2-1';
import { isStandardAWSB21 } from 'weldnote/utils/standards';

const { WELDING_PROCEDURE_SPECIFICATION } = Rules;

@classic
export default class WeldingProcedureSpecificationRoute extends EditRoute {
  @service
  intl;

  @service
  userSession;

  @service('weldnote-data')
  data;

  @service
  weldcloudNotesData;

  modelName = 'welding-procedure-specification';

  listRoute = 'welding-procedure-specifications';

  async beforeModel() {
    super.beforeModel(...arguments);
    await this.weldcloudNotesData.fetchAllData.perform();
  }

  model({ id }) {
    return this.data.loadAllDataWPS(id);
  }

  afterModel(model) {
    return hash({
      pqr: get(model, WELDING_PROCEDURE_SPECIFICATION.MAIN_PQR),
      ranges: model.validationRange(),
    }).then(({ pqr, ranges }) => {
      let units = getProperties(
        this.userSession,
        'temperatureUnit',
        'distanceUnit',
        'flowUnit',
        'heatDistanceUnit',
        'travelSpeedUnit',
        'wireSpeedUnit',
        'forceUnit',
        'areaUnit',
        'pressureUnit',
        'unitSystem'
      );
      let validator = null;
      if (isStandardAWSB21(model.standard)) {
        validator = new AwsB21Validator(ranges, model, this.intl, units);
      } else {
        validator = new WpsValidation(ranges, model, this.intl, units);
      }
      set(model, 'validator', validator);
      if (!isEmpty(pqr)) {
        pqr.fetchAllRelationsForWPSValidation();
      }
    });
  }
}
