import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class TestInspectionClassRoute extends Route {
  @service session;

  @service
  userSession;

  @service
  store;

  @service
  router;

  beforeModel(transition) {
    super.beforeModel(...arguments);
    let { userSession } = this;
    this.session.requireAuthentication(transition, 'login');
    if (userSession.isAccountLocked) {
      this.router.transitionTo('index');
    }
  }

  model({ id }) {
    return this.store.findRecord('test-inspection-class', id, {
      include: 'stages,stages.tests,stages.name,stages.tests.typeOfTest,stages.location',
    });
  }
}
