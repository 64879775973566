import { isEmpty } from '@ember/utils';
import Constants from 'weldnote/utils/constants';

const { PERMISSIONS } = Constants;

export function isAllowedByPlan(activity = '') {
  if (isEmpty(activity)) {
    return false;
  }
  if (activity.indexOf('pwps') > 0) {
    if (
      activity === PERMISSIONS.PWPS_CREATE_CERTIFICATE ||
      activity === PERMISSIONS.PWPS_CREATE_PQR
    ) {
      return false;
    }
    return true;
  }
  return false;
}
