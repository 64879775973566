import { action } from '@ember/object';
import Controller from '@ember/controller';
import { service } from '@ember/service';
import RouterService from '@ember/routing/router-service';

export default class EditController extends Controller {
  @service
  declare router: RouterService;

  declare listRoute: string;

  @action
  deleteInstance() {
    this.router.transitionTo(this.listRoute);
  }
}
