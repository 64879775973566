import Model, { hasMany, SyncHasMany } from '@ember-data/model';
import ArcTransferMode from './arc-transfer-mode';
import BaseMaterialGroup from './base-material-group';
import ElectrodeType from './electrode-type';
import FillerMaterialGroup from './filler-material-group';
import FluxSpecification from './flux-specification';
import GasSpecification from './gas-specification';
import PostWeldTreatmentMethod from './post-weld-treatment-method';
import PreparationMethod from './preparation-method';
import ProcessMecanization from './process-mecanization';
import TypeCurrentPolarity from './type-current-polarity';
import WeldType from './weld-type';
import WeldingDetail from './welding-detail';
import WeldingPosition from './welding-position';
import WeldingProcess from './welding-process';

export default class WeldingResource extends Model {
  @hasMany('weld-type', { async: false })
  declare weldTypes: SyncHasMany<WeldType>;

  @hasMany('arc-transfer-mode', { async: false })
  declare arcTransferModes: SyncHasMany<ArcTransferMode>;

  @hasMany('welding-detail', { async: false })
  declare weldingDetails: SyncHasMany<WeldingDetail>;

  @hasMany('welding-position', { async: false })
  declare weldingPositions: SyncHasMany<WeldingPosition>;

  @hasMany('base-material-group', { async: false })
  declare baseMaterialGroups: SyncHasMany<BaseMaterialGroup>;

  @hasMany('filler-material-group', { async: false })
  declare fillerMaterialGroups: SyncHasMany<FillerMaterialGroup>;

  @hasMany('electrode-type', { async: false })
  declare electrodeTypes: SyncHasMany<ElectrodeType>;

  @hasMany('type-current-polarity', { async: false })
  declare typeCurrentPolarities: SyncHasMany<TypeCurrentPolarity>;

  @hasMany('welding-process', { async: false })
  declare weldingProcesses: SyncHasMany<WeldingProcess>;

  @hasMany('process-mecanization', { async: false })
  declare processMecanizations: SyncHasMany<ProcessMecanization>;

  @hasMany('preparation-method', { async: false })
  declare preparationMethods: SyncHasMany<PreparationMethod>;

  @hasMany('gas-specification', { async: false })
  declare gasSpecifications: SyncHasMany<GasSpecification>;

  @hasMany('flux-specification', { async: false })
  declare fluxSpecifications: SyncHasMany<FluxSpecification>;

  @hasMany('post-weld-treatment-method', { async: false })
  declare postWeldHeatTreatmentMethods: SyncHasMany<PostWeldTreatmentMethod>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'welding-data': WeldingResource;
  }
}
