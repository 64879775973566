import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ProjectController extends Controller {
  queryParams = ['section'];

  @tracked
  section = 'details';

  @action
  updateSection(newSection) {
    this.section = newSection;
  }
}
