import Controller from '@ember/controller';
import { service } from '@ember/service';
import RouterService from '@ember/routing/router-service';

export default class ProjectWelders extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service
  declare router: RouterService;

  get isWeldersActive() {
    return this.router.isActive('project.welders.welders');
  }

  get isWelderCertificatesActive() {
    return this.router.isActive('project.welders.certificates');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'project.welders': ProjectWelders;
  }
}
