import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { assert } from '@ember/debug';
import { set, get } from '@ember/object';

export default class NewRoute extends Route {
  @service
  userSession;

  @service session;

  @service
  store;

  @service
  router;

  modelName = '';

  init() {
    super.init(...arguments);
    assert('Route must have a model name', !isEmpty(get(this, 'modelName')));
  }

  beforeModel(transition) {}

  beforeModel(transition) {
    super.beforeModel(...arguments);
    this.session.requireAuthentication(transition, 'login');
    if (this.userSession.isAccountLocked) {
      this.router.transitionTo('index');
    }
  }

  model() {
    return this.store.createRecord(get(this, 'modelName'));
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(this, 'currentModel', model);
    set(controller, 'modelName', get(this, 'modelName'));
  }

  /**
   * @public
   * When we're leaving the route with an unsaved instance, destroy the instance.
   */
  deactivate() {
    super.deactivate(...arguments);
    let model = this.currentModel;
    if (model && model.isNew && !model.isSaving && !model.isDeleted) {
      // Here was a store.unloadRecord() but Ember 4.4 says 
      // that store was destroyed when it reaches here... so :(
      set(this, 'currentModel', null);
    }
  }
}
