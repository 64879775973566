import { get } from '@ember/object';
import RSVP from 'rsvp';
import { isEmpty } from '@ember/utils';

function authorizeRequest(request, session) {
  let { accessToken } = get(session, 'data.authenticated');
  request.setRequestHeader('Authorization', `Bearer ${accessToken}`);
}

// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
// Downloads a File by Posting to a URL and getting the file back as binary (parameters are sent in the url)
export function downloadAjaxFilePromise(
  url,
  session,
  mimeType = 'application/pdf',
  extension = 'pdf'
) {
  let promise = new RSVP.Promise(function (resolve, reject) {
    let request = new XMLHttpRequest();
    request.open('POST', url);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    authorizeRequest(request, session);
    request.responseType = 'blob';
    request.onload = function () {
      // Only handle status code 200
      if (request.status === 200) {
        // Try to find out the filename from the content disposition `filename` value
        let dispositionHeader = request.getResponseHeader('content-disposition');
        let fileName = `unnamed.${extension}`;
        if (!isEmpty(dispositionHeader)) {
          let matches = /"([^"]*)"/.exec(dispositionHeader);
          fileName = matches != null && matches[1] ? matches[1] : `file.${extension}`;
        }
        // The actual download
        let blob = new Blob([request.response], { type: mimeType });

        if (window.navigator.msSaveOrOpenBlob) {
          try {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } catch (e) {
            alert('An error ocurred while saving the file, please contact support');
          }
        } else {
          let link = document.createElement('a');
          let objectUrl = window.URL.createObjectURL(blob);
          link.href = objectUrl;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(objectUrl);
          }, 100);
        }
        resolve(request.response);
      } else {
        reject(this);
      }
    };
    request.send();
    return request;
  });
  return promise;
}

export function downloadExcel(url, session) {
  return downloadAjaxFilePromise(url, session, 'application/vnd.ms-excel', 'xls');
}

export function downloadPdf(url, session) {
  return downloadAjaxFilePromise(url, session, 'application/pdf', 'pdf');
}
