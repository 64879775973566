import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import EditController from 'weldnote/controllers/_base/edit';

@classic
export default class PqrController extends EditController {
  @service
  userSession;

  @service('weldnote-data')
  data;

  queryParams = ['section'];

  section = 'details';
}
