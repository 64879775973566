import Route from '@ember/routing/route';
import { service } from '@ember/service';
import Constants from 'weldnote/utils/constants';

const { ARCHIVAL_STATE } = Constants;

export default class WeldingBooksArchivedRoute extends Route {
  @service('weldnote-data')
  data;

  model() {
    return this.data.listAllWeldingBooks(
      {},
      {
        archivalState: ARCHIVAL_STATE.ARCHIVED,
      }
    );
  }
}
