import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';

@classic
export default class WeldingPositionsRoute extends ListRoute {
  @service('weldnote-data')
  data;

  model() {
    return this.data.listAllWeldingPositions();
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('totalCount', model.meta.records);
  }
}
