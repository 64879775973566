import { isEmpty } from '@ember/utils';

const knownFractions = [
  '1/32',
  '1/16',
  '3/32',
  '1/8',
  '5/32',
  '3/16',
  '7/32',
  '1/4',
  '9/32',
  '5/16',
  '11/32',
  '3/8',
  '13/32',
  '7/16',
  '15/32',
  '1/2',
  '17/32',
  '9/16',
  '19/32',
  '5/8',
  '21/32',
  '11/16',
  '23/32',
  '3/4',
  '25/32',
  '13/16',
  '27/32',
  '7/8',
  '29/32',
  '15/16',
  '31/32',
  '1',
  '1 1/4',
  '1 1/2',
  '1 3/4',
  '2',
  '2 1/4',
  '2 1/2',
  '2 3/4',
  '3',
  '3 1/4',
  '3 1/2',
  '3 3/4',
];

// Source http://jsfiddle.net/5QrhQ/5/, which came from a Stackoverflow link
function gcd(a, b) {
  if (b < 0.0000001) {
    // Since there is a limited precision we need to limit the value.
    return a;
  }
  return gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
}

function toFractionInput(numerator, denominator) {
  return `${numerator}/${denominator}`;
}

function toFractionString(numerator, denominator) {
  return `<sup>${numerator}</sup>&frasl;<sub>${denominator}</sub>`;
}

export function toFraction(fraction, type = 'input') {
  let len = fraction.toString().length - 2;

  let denominator = Math.pow(10, len);
  let numerator = fraction * denominator;

  if (isNaN(numerator) || isNaN(denominator)) {
    return;
  }

  let divisor = gcd(numerator, denominator);

  numerator /= divisor;
  denominator /= divisor;

  if (numerator > denominator) {
    let integer = Math.trunc(numerator / denominator);
    numerator = parseInt(numerator) % parseInt(denominator);
    if (numerator === 0) {
      return `${integer}`;
    } else {
      if (type === 'input') {
        return `${integer} ${toFractionInput(numerator, denominator)}`;
      } else {
        return `${integer} ${toFractionString(numerator, denominator)}`;
      }
    }
  } else {
    if (numerator === denominator) {
      return `${numerator}`;
    } else {
      if (type === 'input') {
        return `${toFractionInput(numerator, denominator)}`;
      } else {
        return `${toFractionString(numerator, denominator)}`;
      }
    }
  }
}

export function toNumber(valueFraction, separator = '.') {
  if (isEmpty(valueFraction)) {
    return NaN;
  }

  if (separator !== '.') {
    valueFraction = `${valueFraction}`.replace(separator, '.');
  }

  let value = valueFraction.trim().split(' ');
  if (value.length > 1) {
    let [integer, fraction] = value;
    let [numerator, denominator] = fraction.split('/');
    let result = parseInt(integer) + parseFloat(numerator) / parseInt(denominator);
    if (result !== Infinity) {
      return result;
    } else {
      return NaN;
    }
  } else {
    let [numerator, denominator] = valueFraction.split('/');
    if (!isEmpty(denominator)) {
      let result = parseFloat(numerator) / parseInt(denominator);
      if (result !== Infinity) {
      return result;
    } else {
      return NaN;
    }
    }
    return parseFloat(numerator);
  }
}

export function isKnownFraction(valueDecimal) {
  if (!isEmpty(valueDecimal)) {
    let value = toFraction(valueDecimal);
    return knownFractions.includes(value);
  }
  return false;
}

export function isKnownFractionString(fraction) {
  if (!isEmpty(fraction)) {
    return knownFractions.includes(fraction);
  }
  return false;
}

export function isFraction(value) {
  if (!isEmpty(value)) {
    let copy = `${value}`.trim();
    if (copy.indexOf('/') > -1) {
      return true;
    }
  }
  return false;
}

export function convertMilimetersToInches(value) {
  if (!isEmpty(value)) {
    let valueParsed = parseFloat(value);
    if (!isNaN(valueParsed)) {
      return valueParsed / 25.4;
    }
  }
  return null;
}

export function convertInchesToMilimeters(value) {
  if (!isEmpty(value)) {
    let valueParsed = parseFloat(value);
    if (!isNaN(valueParsed)) {
      return valueParsed * 25.4;
    }
  }
  return null;
}
