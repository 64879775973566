import { service } from '@ember/service';
import NewRoute from 'weldnote/routes/_base/new';

export default class _NewRoute extends NewRoute {
  @service
  store;

  modelName = 'groove-design';

  model() {
    return this.store.createRecord('groove-design', {
      imageSituation: 'TEMPLATE',
      image: this.store.createRecord('file-upload'),
    });
  }
}
