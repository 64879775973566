import EditRoute from 'weldnote/routes/_base/edit';
import { service } from '@ember/service';

export default class StaffRoute extends EditRoute {
  @service
  store;

  modelName = 'staff';

  listRoute = 'staffs';

  model({ id }) {
    return this.store.findRecord('staff', id, {
      include: 'company,position',
    });
  }
}
