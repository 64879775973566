import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';
import { A } from '@ember/array';
import { get } from '@ember/object';
import WeldingParameter from './pqr-welding-parameter';
import Constants from './constants';
import { formatRangeWith2Decimals, formatRangeWith3Decimals } from './unit-system/format';
import { isStandardISO, isStandardASME, isStandardAWS } from './standards';
import { isButtWeld, isPartialPenetrationButtWeld } from 'weldnote/utils/rules/weld-type';

const { WELDING } = WeldingParameter;

const { PRODUCT_TYPE, WELD_TYPES, ARC_TRANSFER_MODES, UNIT_SYSTEM, OVERLAY_FILLER_MATERIAL_FORM } =
  Constants;

export default {
  PQR: {
    CERTIFICATE_NUMBER: 'certificateNumber',
    COMPANY: 'company',
    ADDRESS: 'address',
    STANDARD: 'standard',
    CONSTRUCTION_STANDARD: 'constructionStandard',
    EXAMINING_BODY: 'examiningBody',
    EMISSION_DATE: 'emissionDate',
    // Weld Type
    WELDTYPE_TESTPIECE: 'weldTypeTestPiece',
    WELDTYPE_APPROVALRANGE: 'weldTypeApprovalRange',
    // Welding Process
    WELDING_PROCESS_ROOT_TESTPIECE: 'weldingProcessRootTestPiece',
    WELDING_PROCESS_FILL_TESTPIECE: 'weldingProcessFillTestPiece',
    WELDING_PROCESS_ROOT_APPROVALRANGE: 'weldingProcessRootApprovalRange',
    WELDING_PROCESS_FILL_APPROVALRANGE: 'weldingProcessFillApprovalRange',
    // Welding Details
    WELDING_DETAILS_TESTPIECE: 'weldingDetailsTestPiece',
    WELDING_DETAILS_APPROVALRANGE: 'weldingDetailsApprovalRange',
    // Welding Position
    WELDING_POSITION_ROOT_TESTPIECE: 'weldingPositionRootTestPiece',
    WELDING_POSITION_FILL_TESTPIECE: 'weldingPositionFillTestPiece',
    WELDING_POSITION_ROOT_APPROVALRANGE: 'weldingPositionRootApprovalRange',
    WELDING_POSITION_FILL_APPROVALRANGE: 'weldingPositionFillApprovalRange',

    ARC_TRANSFER_MODE_ROOT_TESTPIECE: 'arcTransferModeRootTestPiece',
    ARC_TRANSFER_MODE_FILL_TESTPIECE: 'arcTransferModeFillTestPiece',
    ARC_TRANSFER_MODE_ROOT_APPROVALRANGE: 'arcTransferModeRootApprovalRange',
    ARC_TRANSFER_MODE_FILL_APPROVALRANGE: 'arcTransferModeFillApprovalRange',

    TYPE_CURRENT_ROOT_TESTPIECE: 'typeCurrentRootTestPiece',
    TYPE_CURRENT_FILL_TESTPIECE: 'typeCurrentFillTestPiece',
    TYPE_CURRENT_ROOT_APPROVALRANGE: 'typeCurrentRootApprovalRange',
    TYPE_CURRENT_FILL_APPROVALRANGE: 'typeCurrentFillApprovalRange',

    PRODUCT_TYPE_TESTPIECE: 'productTypeTestPiece',
    BASE_MATERIAL1_TESTPIECE: 'baseMaterial1TestPiece',
    BASE_MATERIAL2_TESTPIECE: 'baseMaterial2TestPiece',
    BASE_MATERIAL1_APPROVAL_RANGE: 'baseMaterial1ApprovalRange',
    BASE_MATERIAL2_APPROVAL_RANGE: 'baseMaterial2ApprovalRange',

    THICKNESS_ROOT_TESTPIECE: 'thicknessRootTestPiece',
    THICKNESS_ROOT_LAYER_TESTPIECE: 'thicknessRootLayerTestPiece',
    THICKNESS_FILL_TESTPIECE: 'thicknessFillTestPiece',
    THICKNESS_FILL_LAYER_TESTPIECE: 'thicknessFillLayerTestPiece',
    THICKNESS_TOTAL_TESTPIECE: 'thicknessTotalTestPiece',
    THICKNESS_ROOT_MINIMUM_APPROVALRANGE: 'thicknessRootMinimumApprovalRange',
    THICKNESS_ROOT_MAXIMUM_APPROVALRANGE: 'thicknessRootMaximumApprovalRange',
    THICKNESS_FILL_MINIMUM_APPROVALRANGE: 'thicknessFillMinimumApprovalRange',
    THICKNESS_FILL_MAXIMUM_APPROVALRANGE: 'thicknessFillMaximumApprovalRange',
    THICKNESS_TOTAL_MINIMUM_APPROVALRANGE: 'thicknessTotalMinimumApprovalRange',
    THICKNESS_TOTAL_MAXIMUM_APPROVALRANGE: 'thicknessTotalMaximumApprovalRange',

    DIAMETER_TESTPIECE: 'diameterTestPiece',
    DIAMETER_MINIMUM_APPROVALRANGE: 'diameterMinimumApprovalRange',
    DIAMETER_MAXIMUM_APPROVALRANGE: 'diameterMaximumApprovalRange',

    BRANCH_ANGLE_TESTPIECE: 'branchAngleTestPiece',
    BRANCH_ANGLE_MINIMUM_APPROVALRANGE: 'branchAngleMinimumApprovalRange',
    BRANCH_ANGLE_MAXIMUM_APPROVALRANGE: 'branchAngleMaximumApprovalRange',

    THROAT_THICKNESS_TESTPIECE: 'throatThicknessTestPiece',
    THROAT_THICKNESS_MINIMUM_APPROVALRANGE: 'throatThicknessMinimumApprovalRange',
    THROAT_THICKNESS_MAXIMUM_APPROVALRANGE: 'throatThicknessMaximumApprovalRange',

    FILLET_WELD_BASE_MATERIAL1_THICKNESS_TESTPIECE: 'filletWeldBaseMaterial1ThicknessTestPiece',
    FILLET_WELD_BASE_MATERIAL1_THICKNESS_MINIMUM_APPROVALRANGE:
      'filletWeldBaseMaterial1ThicknessMinimumApprovalRange',

    FILLET_WELD_BASE_MATERIAL1_THICKNESS_MAXIMUM_APPROVALRANGE:
      'filletWeldBaseMaterial1ThicknessMaximumApprovalRange',

    FILLET_WELD_BASE_MATERIAL2_THICKNESS_TESTPIECE: 'filletWeldBaseMaterial2ThicknessTestPiece',
    FILLET_WELD_BASE_MATERIAL2_THICKNESS_MINIMUM_APPROVALRANGE:
      'filletWeldBaseMaterial2ThicknessMinimumApprovalRange',

    FILLET_WELD_BASE_MATERIAL2_THICKNESS_MAXIMUM_APPROVALRANGE:
      'filletWeldBaseMaterial2ThicknessMaximumApprovalRange',

    PJP_BUTT_WELD_BASE_MATERIAL1_THICKNESS_MINIMUM_APPROVAL_RANGE:
      'pjpButtWeldBaseMaterialThicknessMinimumApprovalRange',

    PJP_BUTT_WELD_BASE_MATERIAL1_THICKNESS_MAXIMUM_APPROVAL_RANGE:
      'pjpButtWeldBaseMaterialThicknessMaximumApprovalRange',

    PJP_BUTT_WELD_BASE_MATERIAL2_THICKNESS_MINIMUM_APPROVAL_RANGE:
      'pjpButtWeldBaseMaterialThicknessMinimumApprovalRange',

    PJP_BUTT_WELD_BASE_MATERIAL2_THICKNESS_MAXIMUM_APPROVAL_RANGE:
      'pjpButtWeldBaseMaterialThicknessMaximumApprovalRange',

    FILLER_MATERIAL_ROOT_TESTPIECE: 'fillerMaterialRootTestPiece',
    FILLER_MATERIAL_ROOT_COMMERCIAL_DESIGNATION_TESTPIECE:
      'fillerMaterialRootCommercialDesignationTestPiece',

    FILLER_MATERIAL_ROOT_COMMERCIAL_DESIGNATION_APPROVALRANGE:
      'fillerMaterialRootCommercialDesignationApprovalRange',

    FILLER_MATERIAL_FILL_TESTPIECE: 'fillerMaterialFillTestPiece',
    FILLER_MATERIAL_FILL_COMMERCIAL_DESIGNATION_TESTPIECE:
      'fillerMaterialFillCommercialDesignationTestPiece',

    FILLER_MATERIAL_FILL_COMMERCIAL_DESIGNATION_APPROVALRANGE:
      'fillerMaterialFillCommercialDesignationApprovalRange',

    FILLER_MATERIAL_ROOT_DIAMETERS: 'fillerMaterialRootDiameters',
    FILLER_MATERIAL_FILL_DIAMETERS: 'fillerMaterialFillDiameters',
    FILLER_MATERIAL_CAP_DIAMETERS: 'fillerMaterialCapDiameters',

    SHIELDING_ROOT: 'shieldingRoot',
    SHIELDING_ROOT_GAS_TESTPIECE: 'shieldingRootGasTestPiece',
    SHIELDING_ROOT_GAS_APPROVALRANGE: 'shieldingRootGasApprovalRange',
    SHIELDING_ROOT_GAS_COMMERCIAL_DESIGNATION_TESTPIECE:
      'shieldingRootGasCommercialDesignationTestPiece',

    SHIELDING_ROOT_GAS_MIXTURE: 'shieldingRootGasMixture',
    SHIELDING_ROOT_GAS_COMMERCIAL_DESIGNATION_APPROVALRANGE:
      'shieldingRootGasCommercialDesignationApprovalRange',

    SHIELDING_ROOT_FLUX_TESTPIECE: 'shieldingRootFluxTestPiece',
    SHIELDING_ROOT_FLUX_APPROVALRANGE: 'shieldingRootFluxApprovalRange',
    SHIELDING_ROOT_FLUX_COMMERCIAL_DESIGNATION_TESTPIECE:
      'shieldingRootFluxCommercialDesignationTestPiece',

    SHIELDING_ROOT_FLUX_COMMERCIAL_DESIGNATION_APPROVALRANGE:
      'shieldingRootFluxCommercialDesignationApprovalRange',

    SHIELDING_FILL: 'shieldingFill',
    SHIELDING_FILL_GAS_TESTPIECE: 'shieldingFillGasTestPiece',
    SHIELDING_FILL_GAS_APPROVALRANGE: 'shieldingFillGasApprovalRange',
    SHIELDING_FILL_GAS_COMMERCIAL_DESIGNATION_TESTPIECE:
      'shieldingFillGasCommercialDesignationTestPiece',

    SHIELDING_FILL_GAS_MIXTURE: 'shieldingFillGasMixture',
    SHIELDING_FILL_GAS_COMMERCIAL_DESIGNATION_APPROVALRANGE:
      'shieldingFillGasCommercialDesignationApprovalRange',

    SHIELDING_FILL_FLUX_TESTPIECE: 'shieldingFillFluxTestPiece',
    SHIELDING_FILL_FLUX_APPROVALRANGE: 'shieldingFillFluxApprovalRange',
    SHIELDING_FILL_FLUX_COMMERCIAL_DESIGNATION_TESTPIECE:
      'shieldingFillFluxCommercialDesignationTestPiece',

    SHIELDING_FILL_FLUX_COMMERCIAL_DESIGNATION_APPROVALRANGE:
      'shieldingFillFluxCommercialDesignationApprovalRange',

    BACKING_TESTPIECE: 'backingTestPiece',
    BACKING_GAS_TESTPIECE: 'backingGasTestPiece',
    BACKING_GAS_APPROVALRANGE: 'backingGasApprovalRange',
    BACKING_GAS_COMMERCIAL_DESIGNATION_TESTPIECE: 'backingGasCommercialDesignationTestPiece',
    BACKING_GAS_MIXTURE: 'backingGasMixture',
    BACKING_GAS_COMMERCIAL_DESIGNATION_APPROVALRANGE:
      'backingGasCommercialDesignationApprovalRange',

    BACKING_FLUX_TESTPIECE: 'backingFluxTestPiece',
    BACKING_FLUX_APPROVALRANGE: 'backingFluxApprovalRange',
    BACKING_FLUX_COMMERCIAL_DESIGNATION_TESTPIECE: 'backingFluxCommercialDesignationTestPiece',
    BACKING_FLUX_COMMERCIAL_DESIGNATION_APPROVALRANGE:
      'backingFluxCommercialDesignationApprovalRange',

    BACKING_FLOW_RATE_ROOT: 'backingFlowRateRoot',
    BACKING_FLOW_RATE_FILL: 'backingFlowRateFill',

    FLOW_RATE_ROOT: 'flowRateRoot',
    FLOW_RATE_FILL: 'flowRateFill',

    ELECTRODE_TYPE: 'electrodeType',
    NUMBER_OF_ELECTRODES: 'numberOfElectrodes',
    PULSED_WELDING_DETAILS: 'pulsedWeldingDetails',
    WEAVING: 'weaving',
    OSCILLATION: 'oscillation',
    CONTACT_TUBE_DISTANCE: 'contactTubeDistance',
    BACK_GAUGING_DETAILS: 'backGaugingDetails',

    PREHEAT_TEMPERATURE_TESTPIECE: 'preheatTemperatureTestPiece',
    INTERPASS_TEMPERATURE_TESTPIECE: 'interpassTemperatureTestPiece',
    POST_HEAT_TEMPERATURE_TESTPIECE: 'postHeatTemperatureTestPiece',
    POST_WELD_HEAT_TREATMENT_TIME_TESTPIECE: 'postWeldHeatTreatmentTimeTestPiece',
    POST_WELD_HEAT_TREATMENT_TIME_MINIMUM_APPROVAL_RANGE:
      'postWeldHeatTreatmentTimeMinimumApprovalRange',

    POST_WELD_HEAT_TREATMENT_TIME_MAXIMUM_APPROVAL_RANGE:
      'postWeldHeatTreatmentTimeMaximumApprovalRange',

    POST_WELD_HEAT_TREATMENT_TEMPERATURE_TESTPIECE: 'postWeldHeatTreatmentTemperatureTestPiece',
    POST_WELD_HEAT_TREATMENT_METHOD_TESTPIECE: 'postWeldHeatTreatmentMethodTestPiece',
    POST_WELD_HEAT_TREATMENT_HEATING_RATE_TESTPIECE: 'postWeldHeatTreatmentHeatingRateTestPiece',
    POST_WELD_HEAT_TREATMENT_COOLING_RATE_TESTPIECE: 'postWeldHeatTreatmentCoolingRateTestPiece',
    HEAT_INPUT_MINIMUM_APPROVALRANGE: 'heatInputMinimumApprovalRange',
    HEAT_INPUT_MAXIMUM_APPROVALRANGE: 'heatInputMaximumApprovalRange',
    PREHEAT_TEMPERATURE_MINIMUM_APPROVALRANGE: 'preheatTemperatureMinimumApprovalRange',
    PREHEAT_TEMPERATURE_MAXIMUM_APPROVALRANGE: 'preheatTemperatureMaximumApprovalRange',
    INTERPASS_TEMPERATURE_MINIMUM_APPROVALRANGE: 'interpassTemperatureMinimumApprovalRange',
    INTERPASS_TEMPERATURE_MAXIMUM_APPROVALRANGE: 'interpassTemperatureMaximumApprovalRange',
    POST_HEAT_TEMPERATURE_MINIMUM_APPROVALRANGE: 'postHeatTemperatureMinimumApprovalRange',
    POST_HEAT_TEMPERATURE_MAXIMUM_APPROVALRANGE: 'postHeatTemperatureMaximumApprovalRange',
    POST_WELD_HEAT_TREATMENT_TEMPERATURE_MINIMUM_APPROVALRANGE:
      'postWeldHeatTreatmentTemperatureMinimumApprovalRange',

    POST_WELD_HEAT_TREATMENT_TEMPERATURE_MAXIMUM_APPROVALRANGE:
      'postWeldHeatTreatmentTemperatureMaximumApprovalRange',

    WELDING_PARAMETERS: 'weldingParameters',

    TENSILE_TEST_NAME: 'tensileTestName',
    TENSILE_TEST_TEMPERATURE: 'tensileTestTemperature',
    TENSILE_TEST_LINES: 'tensileTestLines',

    BEND_TEST_REPORT: 'bendTestReport',
    BEND_TEST_MANDRILL_DIAMETER: 'bendTestMandrillDiameter',
    BEND_TEST_LINES: 'bendTestLines',

    MACROSCOPIC_EXAMINATION_TEST_REPORT: 'macroscopicExaminationTestReport',
    MACROSCOPIC_EXAMINATION_OBSERVATIONS: 'macroscopicExaminationObservations',

    IMPACT_TEST_REPORT: 'impactTestReport',
    IMPACT_TEST_REQUIRED: 'impactTestRequired',
    IMPACT_TEST_TYPE: 'impactTestType',
    IMPACT_TEST_DIMENSIONS: 'impactTestDimensions',
    IMPACT_TEST_TEMPERATURE: 'impactTestTemperature',
    IMPACT_TEST_LINES: 'impactTestLines',
    IMPACT_TEST_DOCUMENT: 'impactTestDocument',

    HARDNESS_TEST_REPORT: 'hardnessTestReport',
    HARDNESS_TEST_REQUIRED: 'hardnessTestRequired',
    HARDNESS_TEST_TYPE: 'hardnessTestType',
    HARDNESS_TEST_LINES: 'hardnessTestLines',
    HARDNESS_TEST_DOCUMENT: 'hardnessTestDocument',

    NON_DESTRUCTIVE_VISUAL_TESTING: 'nonDestructiveVisualTesting',
    NON_DESTRUCTIVE_PENETRANT_TESTING: 'nonDestructivePenetrantTesting',
    NON_DESTRUCTIVE_MAGNETIC_TESTING: 'nonDestructiveMagneticTesting',
    NON_DESTRUCTIVE_ULTRASONIC_TESTING: 'nonDestructiveUltrasonicTesting',
    NON_DESTRUCTIVE_RADIOGRAPHIC_TESTING: 'nonDestructiveRadiographicTesting',

    GROOVE_ANGLE_MINIMUM: 'grooveAngleMinimum',
    GROOVE_ANGLE_MAXIMUM: 'grooveAngleMaximum',
    ROOT_FACE_MINIMUM: 'rootFaceMinimum',
    ROOT_FACE_MAXIMUM: 'rootFaceMaximum',
    ROOT_GAP_MINIMUM: 'rootGapMinimum',
    ROOT_GAP_MAXIMUM: 'rootGapMaximum',

    GROOVE_DESIGNS: 'grooveDesigns',
    WELD_LAYER_CONFIGURATIONS: 'weldLayerConfigurations',

    PREPARATION_METHODS_TESTPIECE: 'preparationMethodsTestPiece',

    TORCH_ANGLE_MINIMUM: 'torchAngleMinimum',
    TORCH_ANGLE_MAXIMUM: 'torchAngleMaximum',

    GAS_NOZZLE_DIAMETER_ROOT: 'gasNozzleDiameterRoot',
    GAS_NOZZLE_DIAMETER_FILL: 'gasNozzleDiameterFill',
    PROCESS_MECANIZATION_ROOT_TEST_PIECE: 'processMecanizationRootTestPiece',
    PROCESS_MECANIZATION_FILL_TEST_PIECE: 'processMecanizationFillTestPiece',
    PROCESS_MECANIZATION_ROOT_APPROVAL_RANGE: 'processMecanizationRootApprovalRange',
    PROCESS_MECANIZATION_FILL_APPROVAL_RANGE: 'processMecanizationFillApprovalRange',

    WELDING_PROCESS_CAP_TEST_PIECE: 'weldingProcessCapTestPiece',
    WELDING_PROCESS_CAP_APPROVAL_RANGE: 'weldingProcessCapApprovalRange',
    ARC_TRANSFER_MODE_CAP_TEST_PIECE: 'arcTransferModeCapTestPiece',
    ARC_TRANSFER_MODE_CAP_APPROVAL_RANGE: 'arcTransferModeCapApprovalRange',
    PROCESS_MECANIZATION_CAP_TEST_PIECE: 'processMecanizationCapTestPiece',
    PROCESS_MECANIZATION_CAP_APPROVAL_RANGE: 'processMecanizationCapApprovalRange',
    GAS_NOZZLE_DIAMETER_CAP: 'gasNozzleDiameterCap',
    WELDING_POSITION_CAP_TEST_PIECE: 'weldingPositionCapTestPiece',
    WELDING_POSITION_CAP_APPROVAL_RANGE: 'weldingPositionCapApprovalRange',
    TYPE_CURRENT_CAP_TEST_PIECE: 'typeCurrentCapTestPiece',
    TYPE_CURRENT_CAP_APPROVAL_RANGE: 'typeCurrentCapApprovalRange',
    THICKNESS_CAP_TEST_PIECE: 'thicknessCapTestPiece',
    THICKNESS_CAP_LAYER_TEST_PIECE: 'thicknessCapLayerTestPiece',
    THICKNESS_CAP_MINIMUM_APPROVAL_RANGE: 'thicknessCapMinimumApprovalRange',
    THICKNESS_CAP_MAXIMUM_APPROVAL_RANGE: 'thicknessCapMaximumApprovalRange',
    SHIELDING_CAP: 'shieldingCap',
    SHIELDING_CAP_GAS_TEST_PIECE: 'shieldingCapGasTestPiece',
    SHIELDING_CAP_GAS_APPROVAL_RANGE: 'shieldingCapGasApprovalRange',
    SHIELDING_CAP_GAS_COMMERCIAL_DESIGNATION_TEST_PIECE:
      'shieldingCapGasCommercialDesignationTestPiece',

    SHIELDING_CAP_GAS_COMMERCIAL_DESIGNATION_APPROVAL_RANGE:
      'shieldingCapGasCommercialDesignationApprovalRange',

    SHIELDING_CAP_GAS_MIXTURE: 'shieldingCapGasMixture',
    SHIELDING_CAP_FLUX_TEST_PIECE: 'shieldingCapFluxTestPiece',
    SHIELDING_CAP_FLUX_APPROVAL_RANGE: 'shieldingCapFluxApprovalRange',
    SHIELDING_CAP_FLUX_COMMERCIAL_DESIGNATION_TEST_PIECE:
      'shieldingCapFluxCommercialDesignationTestPiece',

    SHIELDING_CAP_FLUX_COMMERCIAL_DESIGNATION_APPROVAL_RANGE:
      'shieldingCapFluxCommercialDesignationApprovalRange',

    FLOW_RATE_CAP: 'flowRateCap',
    BACKING_FLOW_RATE_CAP: 'backingFlowRateCap',
    NUMBER_OF_ELECTRODES_FILL: 'numberOfElectrodesFill',
    NUMBER_OF_ELECTRODES_CAP: 'numberOfElectrodesCap',
    FILLER_MATERIAL_CAP_TEST_PIECE: 'fillerMaterialCapTestPiece',
    FILLER_MATERIAL_CAP_COMMERCIAL_DESIGNATION_TEST_PIECE:
      'fillerMaterialCapCommercialDesignationTestPiece',

    FILLER_MATERIAL_CAP_COMMERCIAL_DESIGNATION_APPROVAL_RANGE:
      'fillerMaterialCapCommercialDesignationApprovalRange',

    BASE_MATERIAL1_THICKNESS: 'baseMaterial1Thickness',
    BASE_MATERIAL1_THICKNESS_MINIMUM_APPROVAL_RANGE: 'baseMaterial1ThicknessMinimumApprovalRange',
    BASE_MATERIAL1_THICKNESS_MAXIMUM_APPROVAL_RANGE: 'baseMaterial1ThicknessMaximumApprovalRange',
    BASE_MATERIAL1_DIAMETER: 'baseMaterial1Diameter',
    BASE_MATERIAL2_THICKNESS: 'baseMaterial2Thickness',
    BASE_MATERIAL2_THICKNESS_MINIMUM_APPROVAL_RANGE: 'baseMaterial2ThicknessMinimumApprovalRange',
    BASE_MATERIAL2_THICKNESS_MAXIMUM_APPROVAL_RANGE: 'baseMaterial2ThicknessMaximumApprovalRange',
    BASE_MATERIAL2_DIAMETER: 'baseMaterial2Diameter',
    WELDING_POSITION_ROOT_DIRECTION: 'weldingPositionRootDirection',
    WELDING_POSITION_FILL_DIRECTION: 'weldingPositionFillDirection',
    WELDING_POSITION_CAP_DIRECTION: 'weldingPositionCapDirection',
    SPECIAL_REQUIREMENTS: 'specialRequirements',
    OVERLAY_TYPE: 'overlayType',
    POST_HEAT_TIME_TEST_PIECE: 'postHeatTimeTestPiece',
    POST_HEAT_TIME_MINIMUM_APPROVAL_RANGE: 'postHeatTimeMinimumApprovalRange',
    POST_HEAT_TIME_MAXIMUM_APPROVAL_RANGE: 'postHeatTimeMaximumApprovalRange',
    JOINT_TYPE: 'jointType',
    ELECTRODE_TYPE_FILL: 'electrodeTypeFill',
    ELECTRODE_TYPE_CAP: 'electrodeTypeCap',
    CONTACT_TUBE_DISTANCE_FILL: 'contactTubeDistanceFill',
    CONTACT_TUBE_DISTANCE_CAP: 'contactTubeDistanceCap',
    WEAVING_FILL: 'weavingFill',
    WEAVING_CAP: 'weavingCap',
    TUNGSTEN_DIAMETER_ROOT: 'tungstenDiameterRoot',
    TUNGSTEN_DIAMETER_FILL: 'tungstenDiameterFill',
    TUNGSTEN_DIAMETER_CAP: 'tungstenDiameterCap',
    CHEMICAL_REPORT_NAME: 'chemicalReportName',
    CHEMICAL_CARBON_CONTENT: 'chemicalCarbonContent',
    CHEMICAL_MANGANESE_CONTENT: 'chemicalManganeseContent',
    CHEMICAL_SILICON_CONTENT: 'chemicalSiliconContent',
    CHEMICAL_CHROMIUM_CONTENT: 'chemicalChromiumContent',
    CHEMICAL_NICKEL_CONTENT: 'chemicalNickelContent',
    CHEMICAL_MOLYBDENUM_CONTENT: 'chemicalMolybdenumContent',
    CHEMICAL_COPPER_CONTENT: 'chemicalCopperContent',
    CHEMICAL_PHOSPHORUS_CONTENT: 'chemicalPhosphorusContent',
    CHEMICAL_SULPHUR_CONTENT: 'chemicalSulphurContent',
    CHEMICAL_ALUMINUM_CONTENT: 'chemicalAluminumContent',
    CHEMICAL_TITANIUM_CONTENT: 'chemicalTitaniumContent',
    OVERLAY_NUMBER_OF_LAYERS: 'overlayNumberOfLayers',
    FILLER_MATERIAL_ELECTRODE_FORM_ROOT: 'fillerMaterialElectrodeFormRoot',
    FILLER_MATERIAL_ELECTRODE_FORM_FILL: 'fillerMaterialElectrodeFormFill',
    FILLER_MATERIAL_ELECTRODE_FORM_CAP: 'fillerMaterialElectrodeFormCap',
    FILLER_MATERIAL_GUIDE_ROOT: 'fillerMaterialGuideRoot',
    FILLER_MATERIAL_GUIDE_FILL: 'fillerMaterialGuideFill',
    FILLER_MATERIAL_GUIDE_CAP: 'fillerMaterialGuideCap',
    OSCILLATION_WIDTH_ROOT: 'oscillationWidthRoot',
    OSCILLATION_WIDTH_FILL: 'oscillationWidthFill',
    OSCILLATION_WIDTH_CAP: 'oscillationWidthCap',
    OSCILLATION_FREQUENCY_ROOT: 'oscillationFrequencyRoot',
    OSCILLATION_FREQUENCY_FILL: 'oscillationFrequencyFill',
    OSCILLATION_FREQUENCY_CAP: 'oscillationFrequencyCap',
    OSCILLATION_DWELL_TIME_ROOT: 'oscillationDwellTimeRoot',
    OSCILLATION_DWELL_TIME_FILL: 'oscillationDwellTimeFill',
    OSCILLATION_DWELL_TIME_CAP: 'oscillationDwellTimeCap',
    SUPPLEMENTAL_DEVICE_ROOT: 'supplementalDeviceRoot',
    SUPPLEMENTAL_DEVICE_FILL: 'supplementalDeviceFill',
    SUPPLEMENTAL_DEVICE_CAP: 'supplementalDeviceCap',
    ARCHIVAL_STATE: 'archivalState',
    FLOW_RATE_PROCESS1_MINIMUM:'flowRateProcess1Minimum',
    FLOW_RATE_PROCESS1_MAXIMUM:'flowRateProcess1Maximum',
    FLOW_RATE_PROCESS2_MINIMUM:'flowRateProcess2Minimum',
    FLOW_RATE_PROCESS2_MAXIMUM:'flowRateProcess2Maximum',
    FLOW_RATE_PROCESS3_MINIMUM:'flowRateProcess3Minimum',
    FLOW_RATE_PROCESS3_MAXIMUM:'flowRateProcess3Maximum'
  },

  // Disabled Rules
  // Diameter should be disabled when the product type is not Pipe
  isDiameterDisabled(productType) {
    return productType !== PRODUCT_TYPE.PIPE;
  },

  isBranchAngleDisabled(weldType) {
    return weldType !== WELD_TYPES.BRANCH_CONNECTION;
  },

  _groupBelongs(groupName, groupList) {
    return !isEmpty(groupName) && groupList.indexOf(groupName) > -1;
  },

  _fixDecimals(result, unitSystem = UNIT_SYSTEM.METRIC) {
    if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
      return result;
    }
    return formatRangeWith2Decimals(result);
  },

  getSmallestBaseMaterialThickness(baseMaterial1ThicknessValue, baseMaterial2ThicknessValue) {
    let bm1Thickness = parseFloat(baseMaterial1ThicknessValue, 10);
    let bm2Thickness = parseFloat(baseMaterial2ThicknessValue, 10);

    if (!isNaN(bm1Thickness) && !isNaN(bm2Thickness)) {
      return bm1Thickness <= bm2Thickness ? bm1Thickness : bm2Thickness;
    } else if (!isNaN(bm1Thickness)) {
      return bm1Thickness;
    } else if (!isNaN(bm2Thickness)) {
      return bm2Thickness;
    }
    return null;
  },

  updateHeatInputApprovalRange(pqr, unitSystem = UNIT_SYSTEM.METRIC) {
    let standard = pqr.get(this.PQR.STANDARD);
    let parameters = pqr.get(this.PQR.WELDING_PARAMETERS);
    let weldType = pqr.get(this.PQR.WELDTYPE_TESTPIECE);
    let hardnessTestRequired = pqr.get(this.PQR.HARDNESS_TEST_REQUIRED);
    let impactTestRequired = this.hasImpactRequirements(pqr);

    let heatInputMinimum = this.calculateParametersMinimumHeatInput(parameters);
    let heatInputMaximum = this.calculateParametersMaximumHeatInput(parameters);

    if (isNaN(heatInputMinimum) && isNaN(heatInputMaximum)) {
      pqr.set(this.PQR.HEAT_INPUT_MINIMUM_APPROVALRANGE, null);
      pqr.set(this.PQR.HEAT_INPUT_MAXIMUM_APPROVALRANGE, null);
      return;
    }

    let weldTypeCode = '';
    if (!isEmpty(weldType)) {
      weldTypeCode = weldType.get('weldType');
    }

    let result = this.calculateHeatInputApprovalRange(
      standard,
      hardnessTestRequired,
      impactTestRequired,
      heatInputMinimum,
      heatInputMaximum,
      weldTypeCode,
      unitSystem
    );
    pqr.set(this.PQR.HEAT_INPUT_MINIMUM_APPROVALRANGE, result.min);
    pqr.set(this.PQR.HEAT_INPUT_MAXIMUM_APPROVALRANGE, result.max);
  },

  calculateParametersMinimumHeatInput(parameters) {
    let minimum = 9999999;
    if (isEmpty(parameters)) {
      return null;
    }
    parameters.forEach((p) => {
      let heatInputMin = parseFloat(p.get(WELDING.HEAT_INPUT_MINIMUM));
      if (!isNaN(heatInputMin)) {
        if (minimum > heatInputMin) {
          minimum = heatInputMin;
        }
      }
    });

    if (minimum === 9999999) {
      return null;
    }

    return minimum;
  },

  calculateParametersMaximumHeatInput(parameters) {
    let maximum = -1;
    if (isEmpty(parameters)) {
      return null;
    }
    parameters.forEach((p) => {
      let heatInputMax = parseFloat(p.get(WELDING.HEAT_INPUT_MAXIMUM));
      if (!isNaN(heatInputMax)) {
        if (maximum < heatInputMax) {
          maximum = heatInputMax;
        }
      }
    });

    if (maximum === -1) {
      return null;
    }

    return maximum;
  },

  calculateHeatInputApprovalRange(
    standard,
    hardnessTestRequired,
    impactTestRequired,
    heatInputMin,
    heatInputMax,
    weldType,
    unitSystem
  ) {
    let result = {
      min: null,
      max: null,
    };

    let heatInputMinParsed = parseFloat(heatInputMin, 10);
    let heatInputMaxParsed = parseFloat(heatInputMax, 10);
    if (isStandardISO(standard)) {
      if (weldType === WELD_TYPES.OVERLAY_WELD) {
        if (!isNaN(heatInputMinParsed)) {
          result.min = heatInputMinParsed * 0.9;
        }
        if (!isNaN(heatInputMaxParsed)) {
          result.max = heatInputMaxParsed * 1.25;
        }
      } else if (!isNaN(heatInputMinParsed) && heatInputMinParsed >= 0) {
        if (hardnessTestRequired) {
          result.min = parseFloat((heatInputMinParsed * 0.75).toFixed(2));
        }
        if (!isNaN(heatInputMaxParsed) && impactTestRequired) {
          result.max = parseFloat((heatInputMaxParsed * 1.25).toFixed(2));
        }
      }
    } else if (isStandardASME(standard)) {
      result.min = null;
      if (!isNaN(heatInputMaxParsed) && impactTestRequired) {
        result.max = heatInputMaxParsed;
      } else {
        result.max = null;
      }
    } else if (isStandardAWS(standard)) {
      result.min = 0;
      if (!isNaN(heatInputMaxParsed) && impactTestRequired) {
        result.max = heatInputMaxParsed;
      } else {
        result.max = null;
      }
    }
    return this._fixDecimals(result, unitSystem);
  },

  updateArcTransferModeTestPieceByWeldingProcess(
    pqr,
    weldingProcessProperty,
    arcTransferModeProperty
  ) {
    let processRoot = pqr.get(weldingProcessProperty);
    let standard = get(pqr, this.PQR.STANDARD);
    if (isStandardASME(standard)) {
      let weldingProcesses = ['GMAW', 'FCAW', 'MCAW', 'FCAW-S'];
      if (!isEmpty(processRoot)) {
        let designation = processRoot.get('shortDesignation');
        if (weldingProcesses.indexOf(designation) === -1) {
          pqr.set(arcTransferModeProperty, null);
        }
      }
    }
  },

  calculateArcTransferModeApprovalRange(standard, weldingProcessName, transferMode, transferModes) {
    let weldingProcesses = ['114', '131', '135', '136', '137', '138'];
    if (!isEmpty(transferMode)) {
      if (isStandardISO(standard)) {
        if (!isEmpty(weldingProcessName) && weldingProcesses.indexOf(weldingProcessName) > -1) {
          let transferModeName = transferMode.get('transferMode');
          if (
            transferModeName === ARC_TRANSFER_MODES.SPRAY ||
            transferModeName === ARC_TRANSFER_MODES.GLOBULAR
          ) {
            return transferModes.filter((mode) => {
              if (
                mode.get('transferMode') === ARC_TRANSFER_MODES.SPRAY ||
                mode.get('transferMode') === ARC_TRANSFER_MODES.GLOBULAR
              ) {
                return true;
              }
            });
          } else {
            return [transferMode];
          }
        } else {
          return [];
        }
      } else if (isStandardASME(standard)) {
        let weldingProcesses = ['GMAW', 'FCAW', 'MCAW', 'FCAW-S'];
        if (!isEmpty(weldingProcessName) && weldingProcesses.indexOf(weldingProcessName) > -1) {
          let arcModeName = transferMode.get('transferMode');
          if (
            arcModeName === ARC_TRANSFER_MODES.SPRAY ||
            arcModeName === ARC_TRANSFER_MODES.GLOBULAR ||
            arcModeName === ARC_TRANSFER_MODES.PULSED_SPRAY
          ) {
            return transferModes.filter((mode) => {
              if (
                mode.get('transferMode') === ARC_TRANSFER_MODES.SPRAY ||
                mode.get('transferMode') === ARC_TRANSFER_MODES.GLOBULAR ||
                mode.get('transferMode') === ARC_TRANSFER_MODES.PULSED_SPRAY
              ) {
                return true;
              }
            });
          } else {
            return [transferMode];
          }
        } else {
          return [];
        }
      } else if (isStandardAWS(standard)) {
        return [transferMode];
      }
    }
    return [];
  },

  isDiameterMaxDisabled(standard, weldType, productType) {
    if (
      isStandardISO(standard) &&
      weldType === WELD_TYPES.OVERLAY_WELD &&
      productType === PRODUCT_TYPE.PLATE
    ) {
      return true;
    }
    return false;
  },

  isArcTransferModeDisabled(standard, weldingProcess) {
    let currentProcessName = '';
    if (!isEmpty(weldingProcess)) {
      currentProcessName = weldingProcess.get('shortDesignation');
    }
    let weldingProcessesISO = ['114', '131', '135', '136', '137', '138'];
    if (weldingProcessesISO.indexOf(currentProcessName) > -1) {
      return false;
    }
    let weldingProcessASMEOrAWS = ['GMAW', 'FCAW', 'MCAW', 'FCAW-S'];
    if (weldingProcessASMEOrAWS.indexOf(currentProcessName) > -1) {
      return false;
    }
    return true;
  },

  _filterGroups(groupList, groups) {
    let result = A();
    groups.forEach((group) => {
      let groupModel = groupList.findBy('groupName', group);
      if (groupModel) {
        result.push(groupModel);
      }
    });
    return result;
  },

  calculateImpactTestRequirements(
    standard,
    largestBaseMaterialThickness,
    weldType,
    hasImpactValues
  ) {
    let result = {
      update: false,
      value: false,
    };
    if (isStandardISO(standard)) {
      if (
        largestBaseMaterialThickness >= 12 &&
        (isButtWeld(weldType) || isPartialPenetrationButtWeld(weldType))
      ) {
        result.update = true;
        result.value = true;
      } else if (!hasImpactValues) {
        result.update = true;
        result.value = false;
      }
    }
    return result;
  },

  updateHardnessTestRequirements(pqr) {
    let standard = pqr.get(this.PQR.STANDARD);
    let bm1 = pqr.get(this.PQR.BASE_MATERIAL1_TESTPIECE);
    let bm2 = pqr.get(this.PQR.BASE_MATERIAL2_TESTPIECE);
    let group1 = !isEmpty(bm1) ? bm1.get('materialGroup') : null;
    let group2 = !isEmpty(bm2) ? bm2.get('materialGroup') : null;
    let group1Name = !isEmpty(group1) ? group1.get('groupName') : null;
    let group2Name = !isEmpty(group2) ? group2.get('groupName') : null;
    let result = this.calculateHardnessTestRequirements(standard, group1Name, group2Name);
    if (result !== null) {
      pqr.set(this.PQR.HARDNESS_TEST_REQUIRED, result);
    }
  },

  calculateHardnessTestRequirements(standard, group1, group2) {
    if (isStandardISO(standard)) {
      let groups = ['1.1', '8.1', '8.2', '8.3', '41', '42', '43', '44', '45', '46', '47', '48'];
      if (
        (!isEmpty(group1) && groups.indexOf(group1) > -1) ||
        (!isEmpty(group2) && groups.indexOf(group2) > -1)
      ) {
        return false;
      } else {
        return true;
      }
    }
    return null;
  },

  isImpactTestRequiredDisabled(standard, thickness, weldType) {
    if (isStandardISO(standard)) {
      if (thickness >= 12 && !isEmpty(weldType) && weldType.get('weldType') === 'BW') {
        return true;
      }
    }
    return false;
  },

  isHardnessTestRequirementsDisabled(standard, group1, group2) {
    if (isStandardISO(standard)) {
      let groups = ['1.1', '8.1', '8.2', '8.3', '41', '42', '43', '44', '45', '46', '47', '48'];
      if (
        (!isEmpty(group1) && groups.indexOf(group1) > -1) ||
        (!isEmpty(group2) && groups.indexOf(group2) > -1)
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  },

  updateParameterHeatInputMinimum(pqr, pqrParameter, unitSystem = UNIT_SYSTEM.METRIC) {
    let standard = pqr.get('standard');
    let weldingProcess = pqrParameter.get('weldingProcess');

    let voltageMin = pqrParameter.get(WELDING.VOLTAGE_MINIMUM);
    let currentMin = pqrParameter.get(WELDING.CURRENT_MINIMUM);
    let travelSpeedMax = pqrParameter.get(WELDING.TRAVEL_SPEED_MAXIMUM);
    let stripOrWire = pqrParameter.get('additionalData.overlayFillerMaterialForm');
    let stripWidth = null;
    if (stripOrWire === OVERLAY_FILLER_MATERIAL_FORM.STRIP) {
      let materialDiameters = pqrParameter.get(WELDING.FILLER_MATERIAL_DIAMETER);
      if (!isEmpty(materialDiameters)) {
        stripWidth = materialDiameters.get(0);
      }
    }

    let result = this.calculateParameterHeatInput(
      standard,
      voltageMin,
      currentMin,
      travelSpeedMax,
      weldingProcess,
      stripWidth,
      unitSystem
    );

    if (!isEmpty(result)) {
      result = result.toFixed(2);
      pqrParameter.set(WELDING.HEAT_INPUT_MINIMUM, result);
    }

    this.updateHeatInputApprovalRange(pqr, unitSystem);
  },

  updateParameterHeatInputMaximum(pqr, pqrParameter, unitSystem = UNIT_SYSTEM.METRIC) {
    let standard = pqr.get('standard');
    let weldingProcess = pqrParameter.get('weldingProcess');

    let voltageMax = pqrParameter.get(WELDING.VOLTAGE_MAXIMUM);
    let currentMax = pqrParameter.get(WELDING.CURRENT_MAXIMUM);
    let travelSpeedMin = pqrParameter.get(WELDING.TRAVEL_SPEED_MINIMUM);
    let stripOrWire = pqrParameter.get('additionalData.overlayFillerMaterialForm');
    let stripWidth = null;
    if (stripOrWire === OVERLAY_FILLER_MATERIAL_FORM.STRIP) {
      let materialDiameters = pqrParameter.get(WELDING.FILLER_MATERIAL_DIAMETER);
      if (!isEmpty(materialDiameters)) {
        stripWidth = materialDiameters.get(0);
      }
    }

    let result = this.calculateParameterHeatInput(
      standard,
      voltageMax,
      currentMax,
      travelSpeedMin,
      weldingProcess,
      stripWidth,
      unitSystem
    );

    if (!isEmpty(result)) {
      result = result.toFixed(2);
      pqrParameter.set(WELDING.HEAT_INPUT_MAXIMUM, result);
    }

    this.updateHeatInputApprovalRange(pqr, unitSystem);
  },

  calculateParameterHeatInput(
    standard,
    voltage,
    current,
    travelSpeed,
    weldingProcess,
    stripWidth = 1,
    unitSystem = UNIT_SYSTEM.METRIC
  ) {
    let voltageNum = parseFloat(voltage, 10);
    let currentNum = parseFloat(current, 10);
    let travelSpeedNum = parseFloat(travelSpeed);
    let thermalEfficiency = 0;
    let weldingProcessName = '';
    if (!isEmpty(weldingProcess)) {
      weldingProcessName = weldingProcess.get('shortDesignation');
    }

    if (isStandardASME(standard) || isStandardAWS(standard)) {
      thermalEfficiency = 1;
    } else if (isStandardISO(standard)) {
      if (['141', '142', '143', '145', '15'].indexOf(weldingProcessName) > -1) {
        thermalEfficiency = 0.6;
      } else if (['131', '135', '136', '138', '111'].indexOf(weldingProcessName) > -1) {
        thermalEfficiency = 0.8;
      } else if (['121', '122', '123', '125', '72'].indexOf(weldingProcessName) > -1) {
        thermalEfficiency = 1;
      } else {
        thermalEfficiency = 0;
      }
    } else {
      // AWS D1.2, AWS D1.6, ISO15614-2
      thermalEfficiency = 1;
    }

    let stripWidthNum = 1;
    if (!isEmpty(stripWidth)) {
      stripWidthNum = parseFloat(stripWidth);
    }

    if (!isNaN(voltageNum) && !isNaN(currentNum) && !isNaN(travelSpeedNum)) {
      if (unitSystem === UNIT_SYSTEM.METRIC) {
        return (
          (voltageNum * currentNum * thermalEfficiency) / (travelSpeedNum * stripWidthNum * 1000)
        );
      } else if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
        return formatRangeWith3Decimals(
          (voltageNum * currentNum * (thermalEfficiency * 60)) /
            (travelSpeedNum * stripWidthNum * 1000)
        );
      }
    }
    return null;
  },

  hasImpactRequirements(pqr) {
    let temperature = get(pqr, this.PQR.IMPACT_TEST_TEMPERATURE);
    let required = get(pqr, this.PQR.IMPACT_TEST_REQUIRED);
    return (!isEmpty(temperature) && !isNaN(parseFloat(temperature))) || required;
  },

  updateBackingGasApprovalRange(pqr, data) {
    let backingGasTestPiece = pqr.get(this.PQR.BACKING_GAS_TESTPIECE);
    pqr.set(this.PQR.BACKING_GAS_APPROVALRANGE, [backingGasTestPiece]);
  },
};
