import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { get } from '@ember/object';
import ListRoute from 'weldnote/routes/_base/list';

@classic
export default class WelderStandardsRoute extends ListRoute {
  @service
  userSession;

  @service
  store;

  @service
  router;

  beforeModel() {
    super.beforeModel(...arguments);
    let session = this.userSession;
    if (!get(session, 'isAdmin')) {
      this.router.transitionTo('settings');
    }
  }

  model() {
    return this.store.findAll('welder-standard');
  }
}
