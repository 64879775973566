import { helper } from '@ember/component/helper';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

function formatMaterial(material, options = {}) {
  let specification = get(material, 'specification.visualLabel');
  let grade = get(material, 'grade.visualLabel');
  let group = get(material, 'materialGroup.visualLabel');
  if (!isEmpty(specification) && !isEmpty(grade) && !isEmpty(group)) {
    return `${specification} - ${grade} - ${group}`;
  } else if (!isEmpty(specification) && !isEmpty(group)) {
    return `${specification} - ${group}`;
  }
}

function isSameMaterial(material1, material2) {
  return get(material1, 'id') === get(material2, 'id');
}

export default helper(function formatBaseMaterial(params, hash) {
  let { material, material1, material2 } = hash;
  if (!isEmpty(material)) {
    return formatMaterial(material);
  }
  if (!isEmpty(material1) && !isEmpty(material2)) {
    if (isSameMaterial(material1, material2)) {
      return formatMaterial(material1);
    } else {
      return `${formatMaterial(material1)} - ${formatMaterial(material2)}`;
    }
  }
  if (!isEmpty(material1)) {
    return formatMaterial(material1);
  }
  if (!isEmpty(material2)) {
    return formatMaterial(material2);
  }
  return '';
});
