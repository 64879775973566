import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<td\n  class=\"welding-passes-field-title-pass\"\n  data-test-selector=\"welding-passes-field-title\"\n>{{this.pass}}</td>\n<td\n  class=\"welding-passes-field-title-value\"\n  data-test-selector=\"welding-passes-field-value\"\n>{{yield}}</td>", {"contents":"<td\n  class=\"welding-passes-field-title-pass\"\n  data-test-selector=\"welding-passes-field-title\"\n>{{this.pass}}</td>\n<td\n  class=\"welding-passes-field-title-value\"\n  data-test-selector=\"welding-passes-field-value\"\n>{{yield}}</td>","moduleName":"weldnote/components/welding-pass-field-row.hbs","parseOptions":{"srcName":"weldnote/components/welding-pass-field-row.hbs"}});
import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import { isBothSides } from 'weldnote/utils/rules/welding-detail';

@classic
@tagName('tr')
export default class WeldingPassFieldRow extends Component {
  @service
  intl;

  @service
  userSession;

  sides = null;

  showSequence = false;

  @computed('type', 'sides.[]', 'showSequence')
  get pass() {
    if (isBothSides(this.sides) || this.showSequence) {
      return this.intl.t(`components.welding-pass-field-row-alternate.${this.type}`);
    } else {
      return this.intl.t(`components.welding-pass-field-row.${this.type}`);
    }
  }
}
