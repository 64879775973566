import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/browser';
import { task, didCancel } from 'ember-concurrency';
import ENV from 'weldnote/config/environment';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default class OrgApiService extends Service {
  @service
  ajax;

  @service
  loading;

  @service
  userSession;

  @service('weldnote-data')
  data;

  @service('alert-message')
  alert;

  @service
  intl;

  @tracked
  currentTaskId = null;

  @tracked
  currentSiteTaskId = null;

  async getTaskProgress(taskId) {
    let value = await this.ajax.request(`/api/sync-task-progress/${taskId}`);
    return value;
  }

  async startSyncProcess() {
    let task = await this.ajax.post('/accounts/sync-account', {
      dataType: 'json',
      contentType: 'application/json',
      data: {},
    });
    return task;
  }

  async startSiteSyncProcess() {
    let task = await this.ajax.post('/accounts/sync-sites', {
      dataType: 'json',
      contentType: 'application/json',
      data: {},
    });
    return task;
  }

  async syncSites() {
    if (ENV.environment === 'test') {
      return;
    }
    if (this.currentSiteTaskId !== null) {
      console.log('Attempt to sync, while sync was already in progress');
      return;
    }
    let task = await this.startSiteSyncProcess();
    this.currentSiteTaskId = task.taskIdentifier;
    if (task.contextInfo && task.contextInfo.syncType === 'sites') {
      try {
        while (this.isTaskRunning(task)) {
          await delay(1000);
          task = await this.getTaskProgress(task.taskIdentifier);
        }
        if (task.progress === 100) {
          // When syncing finishes...  bust company cache
          this.data.bustCompanyCache();
        }
      } catch (reason) {
        Sentry.captureException(new Error(reason));
        console.log(`A problem happened while syncing sites ${reason}`);
      } finally {
        this.currentSiteTaskId = null;
      }
    } else {
      this.alert.error(this.intl.t('services.org-api.errors.sync-sites-failed'));
      console.warn('Task doesnt have a contextInfo, or type is not sites');
    }
  }

  async syncAccount() {
    if (ENV.environment === 'test') {
      return;
    }
    if (this.currentTaskId !== null) {
      console.log('Attempt to sync, while sync was already in progress');
      return;
    }
    let task = await this.startSyncProcess();
    let value = await this.getTaskProgress(task.taskIdentifier);
    this.currentTaskId = task.taskIdentifier;
    if (task.contextInfo && task.contextInfo.syncType === 'existing-account') {
      // when the user already exists, we immediately load user with what we have in our backend so that they can use Notes
      try {
        await this.userSession.loadUser.perform();
      } catch (e) {
        if (!didCancel(e)) {
          // re-throw the non-cancelation error
          throw e;
        }
      }
      this.syncForExistingAccount.perform(value);
    } else if (task.contextInfo && task.contextInfo.syncType === 'new-account') {
      await this.syncForNewAccount(value);
    }
  }

  isTaskRunning(task) {
    let { progress, state, status } = task;
    if (progress === 100 || progress === '100') {
      return false;
    }
    // Abort
    if (status === '500' || status === 500) {
      return false;
    }
    // Finished
    if (state === '250' || state === 250) {
      return false;
    }
    // State = Abort
    if (state === '500' || state === 500) {
      return false;
    }
    return true;
  }

  async syncForNewAccount(task) {
    let currentTaskProgress = task;
    let { loading } = this;
    loading.block('Setting up your account');
    // We're dealing with a new user... we must block usage until the end
    try {
      while (this.isTaskRunning(currentTaskProgress)) {
        await delay(1000);
        loading.replaceMessage(`Setting account - ${currentTaskProgress.progress}% done`);
        currentTaskProgress = await this.getTaskProgress(task.taskIdentifier);
      }
      await this.userSession.reloadUser();
      this.data.bustCompanyCache();
    } finally {
      this.currentTaskId = null;
      loading.unblock();
    }
  }

  syncForExistingAccount = task(
    {
      drop: true,
    },
    async (task) => {
      let currentTaskProgress = task;
      // In this situation we're dealing with an existing user... let him navigate the app while sync
      // occurs in the background
      try {
        while (this.isTaskRunning(currentTaskProgress)) {
          await delay(1000);
          currentTaskProgress = await this.getTaskProgress(currentTaskProgress.taskIdentifier);
        }
        if (currentTaskProgress.progress === 100) {
          // When syncing finishes... reload the user and bust company cache
          await this.userSession.reloadUser();
          this.data.bustCompanyCache();
        }
      } catch (reason) {
        Sentry.captureException(new Error(reason));
        console.log(`A problem happened ${reason}`);
        // One last attempt at reloading the user, in case something else failed
        await this.userSession.reloadUser();
      } finally {
        this.currentTaskId = null;
      }
    }
  );
}
