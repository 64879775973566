// This files holds rules that are valid for WPS, PQR and Welder Certificates
import Constants from './constants';

const { SHIELDING_OPTIONS } = Constants;

export function calculateShielding(weldingProcess: string): string | null {
  let processesNone = ['111', '114', 'SMAW', 'FCAW-S', 'FCAW'];
  let processGas = [
    '141',
    '142',
    '143',
    '145',
    '131',
    '135',
    '136',
    '138',
    'GTAW',
    'GMAW',
    'FCAW',
    'MCAW',
    'PAW',
    'EGW',
  ];
  let processFlux = ['121', '122', '123', '124', '125', 'ESW', 'SAW'];
  if (processesNone.includes(weldingProcess)) {
    return SHIELDING_OPTIONS.NONE;
  } else if (processGas.includes(weldingProcess)) {
    return SHIELDING_OPTIONS.GAS;
  } else if (processFlux.includes(weldingProcess)) {
    return SHIELDING_OPTIONS.FLUX;
  }
  return null;
}
