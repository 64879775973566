import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { assert } from '@ember/debug';
import Constants from 'weldnote/utils/constants';
import WeldingDetail from 'weldnote/models/welding-detail';

const { WELDING_DETAILS } = Constants;

function findIn(value: WeldingDetail[], shortDesignation: string): WeldingDetail | null {
  assert(`Must pass a valid attribute - ${shortDesignation}`, !isEmpty(shortDesignation));
  let result: WeldingDetail | undefined = value.findBy('shortDesignation', shortDesignation);
  if (result) {
    return result;
  } else {
    return null;
  }
}

function findInAndGetDesignation(value: WeldingDetail[], shortDesignation: string) {
  let resultDetail = value.findBy('shortDesignation', shortDesignation);
  if (resultDetail) {
    return resultDetail.get('completeDesignation');
  }
  return '';
}

function getCompleteDesignation(value: WeldingDetail | null): string {
  if (value) {
    return `${get(value, 'completeDesignation')}; `;
  }
  return '';
}

// Custom Validation for WeldingDetails in test piece for Ember-model-validator
let weldingDetailValidation = {
  if(_key: string, selectedDetails: WeldingDetail[]): boolean {
    return selectedDetails.length > 1;
  },

  validation(_key: string, selectedDetails: WeldingDetail[]) {
    if (
      findIn(selectedDetails, WELDING_DETAILS.SINGLE_LAYER) &&
      findIn(selectedDetails, WELDING_DETAILS.MULTI_LAYER)
    ) {
      return false;
    }
    let ssNb = !isEmpty(findIn(selectedDetails, WELDING_DETAILS.SINGLE_SIDE_NO_BACKING));
    let ssMb = !isEmpty(findIn(selectedDetails, WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING));
    let ssGb = !isEmpty(findIn(selectedDetails, WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING));
    let ssFb = !isEmpty(findIn(selectedDetails, WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING));
    let bs = !isEmpty(findIn(selectedDetails, WELDING_DETAILS.BOTH_SIDES));
    let count = 0;
    let total = [ssNb, ssMb, ssGb, ssFb, bs];
    total.forEach((detail) => {
      if (detail) {
        count++;
      }
    });
    if (count > 1) {
      return false;
    }
    return true;
  },

  message(_key: string, selectedDetails: WeldingDetail[], model: any) {
    if (
      findIn(selectedDetails, WELDING_DETAILS.SINGLE_LAYER) &&
      findIn(selectedDetails, WELDING_DETAILS.MULTI_LAYER)
    ) {
      return model.translate('model-validations.welding-details.layer', {
        layer1: findInAndGetDesignation(selectedDetails, WELDING_DETAILS.SINGLE_LAYER),
        layer2: findInAndGetDesignation(selectedDetails, WELDING_DETAILS.MULTI_LAYER),
      });
    }
    let ssNb = findIn(selectedDetails, WELDING_DETAILS.SINGLE_SIDE_NO_BACKING);
    let ssMb = findIn(selectedDetails, WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING);
    let ssGb = findIn(selectedDetails, WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING);
    let ssFb = findIn(selectedDetails, WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING);
    let bs = findIn(selectedDetails, WELDING_DETAILS.BOTH_SIDES);
    let details = '';
    if (!isEmpty(ssNb)) {
      details += getCompleteDesignation(ssNb);
    }
    if (!isEmpty(ssMb)) {
      details += getCompleteDesignation(ssMb);
    }
    if (!isEmpty(ssGb)) {
      details += getCompleteDesignation(ssGb);
    }
    if (!isEmpty(ssFb)) {
      details += getCompleteDesignation(ssFb);
    }
    if (!isEmpty(bs)) {
      details += getCompleteDesignation(bs);
    }
    return model.translate('model-validations.welding-details.backing', {
      details,
    });
  },
};

export { weldingDetailValidation };
