import Model, { belongsTo, attr } from '@ember-data/model';
import GrooveDesign from './groove-design';

export default class WeldLayerGrooveCopy extends Model {
  @attr('string')
  declare description?: string;

  @attr('string')
  declare longDescription?: string;

  @belongsTo('groove-design', { async: false })
  declare grooveDesign: GrooveDesign;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'weld-layer-groove-copy': WeldLayerGrooveCopy;
  }
}
