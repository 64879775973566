import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { get } from '@ember/object';

export default class FileServiceService extends Service {
  @service
  ajax;

  @service
  store;

  getContent(url, params = {}) {
    return this.ajax.request(url, {
      method: 'GET',
      data: params,
    });
  }

  generateSignedUploadLink = task(
    {
      drop: true,
    },
    async (filename, context, filesize) => {
      return await this.ajax.post('/files/generate-signed-upload-link', {
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          filename,
          context,
          filesize,
        }),
      });
    }
  );

  generateSignedUploadLinkForReplace = task(
    {
      drop: true,
    },
    async (uploadId, context, filesize) => {
      return await this.ajax.post(`/files/${uploadId}/generate-signed-upload-link-replace`, {
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          uploadId,
          context,
          filesize,
        }),
      });
    }
  );

  getFileRetrieveRequest(file, contextValue, idValue, originalFilename) {
    return this.getContent('/files/download', {
      filename: file,
      context: contextValue,
      id: idValue,
      original: originalFilename,
    });
  }

  getFileRetrieveRequestForUpload(upload) {
    return this.getFileRetrieveRequest(
      get(upload, 'filePath'),
      get(upload, 'context'),
      get(upload, 'id'),
      get(upload, 'originalFilename')
    );
  }

  getFileUploadRequest(filenameValue, contextValue, contentTypeValue, size) {
    return this.getContent('/files/upload', {
      filename: filenameValue,
      context: contextValue,
      contentType: contentTypeValue,
      size,
    });
  }

  getFileUploadSignupRequest(token, filenameValue, contextValue, contentTypeValue, size) {
    return this.getContent('/files/upload-signup', {
      token,
      filename: filenameValue,
      context: contextValue,
      contentType: contentTypeValue,
      size,
    });
  }

  getFileDownloadSignupRequest(token, file, originalFilename) {
    return this.getContent('/files/download-signup', {
      token,
      filename: file,
      original: originalFilename,
    });
  }

  uploadNewBase64Image = task({ drop: true }, async (imageName, base64Image, fileType) => {
    let { ajax, store } = this;
    let binaryData = this.dataURItoBlob(base64Image);
    let { url, uploadId } = await this.generateSignedUploadLink.perform(
      imageName,
      fileType,
      binaryData.size
    );

    await ajax.put(url, {
      dataType: 'text',
      processData: false,
      contentType: 'image/png',
      data: binaryData,
    });

    let upload = await store.findRecord('file-upload', uploadId);
    return upload;
  });

  updateExistingBase64Image = task({ drop: true }, async (fileUploadId, base64Image, fileType) => {
    let { fileService, ajax, store } = this;
    let binaryData = this.dataURItoBlob(base64Image);
    let { url } = await this.generateSignedUploadLinkForReplace.perform(
      fileUploadId,
      fileType,
      binaryData.size
    );

    await ajax.put(url, {
      dataType: 'text',
      processData: false,
      contentType: 'image/png',
      data: binaryData,
    });

    return await store.findRecord('file-upload', fileUploadId);
  });

  dataURItoBlob(base64Image) {
    // remove data prefixes, eg:data:image/png;base64,xxxx
    if (base64Image.includes('')) {
      base64Image = base64Image.substr(base64Image.indexOf(',') + 1);
    }

    let binary = atob(base64Image);
    let array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/png',
    });
  }
}
