import { service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import ENV from 'weldnote/config/environment';
import { tracked } from '@glimmer/tracking';

export default class ApplicationController extends Controller {
  @service
  session;

  @service('instanceTracker')
  tracker;

  @service
  userSession;

  @service
  router;

  @service('crisp')
  chat;

  @service
  intl;

  @service
  media;

  @tracked
  showAlertLogout = false;

  constructor() {
    super(...arguments);
    this.chat.setup();
  }

  get esabCloudLink() {
    return ENV.esabCloudLink;
  }

  get isMobile() {
    let { media } = this;
    return media.isMobile || media.isTablet || media.isLaptop;
  }

  get hasSession() {
    return this.session.isAuthenticated;
  }

  @action
  logout() {
    if (this.tracker.hasUnsavedElements) {
      this.showAlertLogout = true;
    } else {
      this.session.invalidate();
    }
  }

  @action
  confirmLogout() {
    this.tracker.discardAll();
    this.router.transitionTo('logout');
  }
}
