import ListRoute from 'weldnote/routes/_base/list';
import { service } from '@ember/service';

export default class TypeCurrentPolaritiesRoute extends ListRoute {
  @service
  store;

  model() {
    return this.store.findAll('type-current-polarity');
  }
}
