import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ProjectComponent extends Route {
  @service session;

  @service store;

  @service
  router;

  model(params) {
    return this.store.findRecord('project-component', params.component_id);
  }

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  afterModel(model) {
    this.router.transitionTo('project.components.component', get(model, 'project.id'), model.id, {
      queryParams: { section: 'components' },
    });
  }
}
