export default {
  PROJECT_DOCUMENT: {
    PROJECT: 'project',
    DOCUMENT_TITLE: 'documentTitle',
    DOCUMENT_NUMBER: 'documentNumber',
    REVISION: 'revision',
    TYPE: 'type',
    COMMENTS: 'comments',
    INCLUDE_IN_DATA_BOOK: 'includeInDataBook',
    DOCUMENT: 'document',
    REVISION_STATUS: 'revisionStatus',
  },
};
