import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { set, get, getProperties, action, computed } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class BaseMaterialCertificatesController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  sort = null;

  dir = 'asc';

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'base-material-certificate';

  isLoading = false;

  @computed
  get gridColumns() {
    let heatNumberLabel = this.getAttributeLabel('heatNumber');
    let baseMaterialLabel = this.getAttributeLabel('baseMaterial');
    let productTypeLabel = this.getAttributeLabel('productType');
    let thicknessLabel = this.getAttributeLabel('thickness', {
      unit: get(this, 'userSession.distanceUnit'),
    });

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'heatNumber',
        label: heatNumberLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/base-material-certificate-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'baseMaterial.grade.grade',
        label: baseMaterialLabel,
        sortable: false,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'productType',
        label: productTypeLabel,
        cellComponent: 'weldnote-grid/product-type-static',
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'thickness',
        label: thicknessLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
    ];
  }

  buildFilters() {
    let filters = getProperties(this, 'heatNumber');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let result = await this.data.listAllBaseMaterialCertificates(options, this.buildFilters());
      set(this, 'model', result);
      set(this, 'totalCount', result.meta.records);
    }
  );

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setHeatNumber(heatNumber) {
    set(this, 'heatNumber', heatNumber);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  clearFields() {
    this.resetPage();
    this.setProperties({
      heatNumber: null,
    });
    this.loadData.perform();
  }
}
