import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

export function somePropertyHasValue(model, ...properties) {
  return computed(model, ...properties, {
    get() {
      let content = get(this, model);
      let someOk = false;
      for (let i = 0; i < properties.length; i++) {
        let property = get(content, properties[i]);
        if (!isEmpty(property)) {
          someOk = true;
        }
      }
      return someOk;
    },
  });
}
