import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"form-group\">\n  {{#if this.renderLabel}}\n    <label class=\"control-label\">{{t this.labelReference}}</label>\n  {{/if}}\n  <p class=\"form-control-static\">\n    {{#if (is-array this.content)}}\n      {{#if this.isSorted}}\n        {{#each this.sortedContent as |model|}}\n          {{get model this.attributeReference}}{{#if (not-eq this.sortedContent.lastObject model)}},\n          {{/if}}\n        {{/each}}\n      {{else}}\n        {{#each this.content as |model|}}\n          {{get model this.attributeReference}}{{#if (not-eq this.content.lastObject model)}},\n          {{/if}}\n        {{/each}}\n      {{/if}}\n    {{else}}\n      {{get this.content this.attributeReference}}\n    {{/if}}\n  </p>\n</div>", {"contents":"<div class=\"form-group\">\n  {{#if this.renderLabel}}\n    <label class=\"control-label\">{{t this.labelReference}}</label>\n  {{/if}}\n  <p class=\"form-control-static\">\n    {{#if (is-array this.content)}}\n      {{#if this.isSorted}}\n        {{#each this.sortedContent as |model|}}\n          {{get model this.attributeReference}}{{#if (not-eq this.sortedContent.lastObject model)}},\n          {{/if}}\n        {{/each}}\n      {{else}}\n        {{#each this.content as |model|}}\n          {{get model this.attributeReference}}{{#if (not-eq this.content.lastObject model)}},\n          {{/if}}\n        {{/each}}\n      {{/if}}\n    {{else}}\n      {{get this.content this.attributeReference}}\n    {{/if}}\n  </p>\n</div>","moduleName":"weldnote/components/certificate-approval-range.hbs","parseOptions":{"srcName":"weldnote/components/certificate-approval-range.hbs"}});
import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { notEmpty, sort } from '@ember/object/computed';
import Component from '@ember/component';
import { isEmpty } from '@ember/utils';

@classic
export default class CertificateApprovalRange extends Component {
  sortDirection = 'ASC';

  @notEmpty('sortBy')
  isSorted;

  renderLabel = true;

  @sort('content', function (a, b) {
    let sortProperty = this.sortBy;
    let { sortDirection } = this;
    let aProp = a.get(sortProperty);
    let bProp = b.get(sortProperty);
    if (aProp > bProp) {
      if (sortDirection === 'ASC') {
        return 1;
      } else {
        return -1;
      }
    } else if (aProp < bProp) {
      if (sortDirection === 'ASC') {
        return -1;
      } else {
        return 1;
      }
    }
    return 0;
  })
  sortedContent;

  @computed('label')
  get labelReference() {
    return `model.welder-certificate.${this.label}.label`;
  }

  @computed('attribute')
  get attributeReference() {
    if (isEmpty(this.attribute)) {
      return 'visualLabel';
    }
    return this.attribute;
  }
}
