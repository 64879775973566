import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import Constants from 'weldnote/utils/constants';
import Project from './project';

const { PROJECT_REFERENCE_TYPE } = Constants;

export default class ProjectWps extends BaseModel {
  @belongsTo('project')
  declare project: AsyncBelongsTo<Project>;

  @belongsTo('welding-procedure-specification')
  declare wps: any;

  @attr('string')
  declare referenceType?: string;

  @attr('string')
  declare wpsNumber?: string;

  @attr('string')
  declare wpsType?: string;

  @attr('string')
  declare wpsId?: string;

  @attr('string')
  declare standard?: string;

  @attr('string')
  declare weldingProcess?: string;

  @attr('string')
  declare baseMaterials?: string;

  @attr('string')
  declare thickness?: string;

  @attr('string')
  declare projectDesignation?: string;

  @attr('number')
  declare totalWelds?: number;

  @attr('number')
  declare repairedWelds?: number;

  @attr('number')
  declare repairRate?: number;

  @attr('string')
  declare revision?: string;

  get visualLabel(): string {
    return this.wpsNumber || '';
  }

  validations = {
    project: { presence: true },
    wps: { presence: true },
  };

  metadata = {
    modelName: 'project-wps',
    project: { required: true },
    wps: { required: true },
  };

  get isManualReference(): boolean {
    return this.referenceType === PROJECT_REFERENCE_TYPE.MANUAL;
  }

  get isAutomaticReference(): boolean {
    return this.referenceType === PROJECT_REFERENCE_TYPE.REFERENCE;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-wps': ProjectWps;
  }
}
