
import { action } from '@ember/object';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import NewController from 'weldnote/controllers/_base/new';

export default class _NewController extends NewController {
  @service
  router;
  queryParams = ['section'];

  section = 'details';

  @service('weldnote-data')
  data;

  @action
  instanceCreated(wps) {
    this.router.transitionTo('welding-procedure-specification', wps, {
      queryParams: {
        isEditing: true,
      },
    });
  }
}
