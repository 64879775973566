import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import WelderCertificate from 'weldnote/models/welder-certificate';
import WopqApprovalRange from 'weldnote/src-weldnote/wopq/approval-ranges/wopq-approval-range';
import {
  AutomaticJointTracking,
  WelderCertificateTestPieceValues,
  WelderOperatorTypes,
} from 'weldnote/src-weldnote/wopq/types';
import { service } from '@ember/service';
interface ModelWelderCertificateJointTrackingApprovalRangeArgs {
  jointTracking: AutomaticJointTracking | null;
  certificate: WelderCertificate;
  approvalRange: WopqApprovalRange;
  process: string;
}

export default class ModelWelderCertificateJointTrackingApprovalRange extends Component<ModelWelderCertificateJointTrackingApprovalRangeArgs> {
  @service
  declare intl: IntlService;

  constructor(owner: unknown, args: ModelWelderCertificateJointTrackingApprovalRangeArgs) {
    super(owner, args);
  }

  get value(): string {
    if (this.args.jointTracking) {
      let result = '';
      let tracking = WelderOperatorTypes.jointTrackingFromString(this.args.jointTracking);
      if (tracking.isPresent()) {
        let trackingValue = tracking.get();
        let possibleProcess = WelderOperatorTypes.processFromString(this.args.process);
        let process = null;
        if (possibleProcess.isEmpty()) {
          return result;
        } else {
          process = possibleProcess.get();
        }
        let testPieces = new WelderCertificateTestPieceValues(this.args.certificate, process);
        let approvalRange = this.args.approvalRange.automaticJointTracking(
          trackingValue,
          testPieces
        );
        result = approvalRange
          .map((t) => {
            if (t === AutomaticJointTracking.WITH) {
              return this.intl.t('lov.joint-tracking.with');
            } else if (t === AutomaticJointTracking.WITHOUT) {
              return this.intl.t('lov.joint-tracking.without');
            }
            return '';
          })
          .join('+');
        return result;
      }
    }
    return '';
  }
}
