import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericSearchLov\n  @label={{@label}}\n  @helpText={{@helpText}}\n  @isRequired={{@isRequired}}\n  @searchEnabled={{true}}\n  @selected={{this.selectedProject}}\n  @onChange={{pipe (set this \"selectedProject\") @onProjectSelected}}\n  @onOpen={{this.fetchInitialOptions}}\n  @availableOptions={{this.options}}\n  @search={{perform this.data.findProjects @standard}}\n  @renderInPlace={{true}}\n  @noMatchesMessage={{t\n    \"components.model-project/chooser.messages.no-projects\"\n    standard=@standard.standard\n  }}\n  @errors={{@errors}}\n  @searchPlaceholder={{t \"components.model-project/chooser.messages.type-to-search\"}}\n  ...attributes\n  as |project|\n>\n  {{project.projectName}}\n  -\n  {{project.designation}}\n</GenericSearchLov>", {"contents":"<GenericSearchLov\n  @label={{@label}}\n  @helpText={{@helpText}}\n  @isRequired={{@isRequired}}\n  @searchEnabled={{true}}\n  @selected={{this.selectedProject}}\n  @onChange={{pipe (set this \"selectedProject\") @onProjectSelected}}\n  @onOpen={{this.fetchInitialOptions}}\n  @availableOptions={{this.options}}\n  @search={{perform this.data.findProjects @standard}}\n  @renderInPlace={{true}}\n  @noMatchesMessage={{t\n    \"components.model-project/chooser.messages.no-projects\"\n    standard=@standard.standard\n  }}\n  @errors={{@errors}}\n  @searchPlaceholder={{t \"components.model-project/chooser.messages.type-to-search\"}}\n  ...attributes\n  as |project|\n>\n  {{project.projectName}}\n  -\n  {{project.designation}}\n</GenericSearchLov>","moduleName":"weldnote/components/model-project/chooser.hbs","parseOptions":{"srcName":"weldnote/components/model-project/chooser.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';

export default class ProjectChooser extends Component {
  @service('weldnote-data')
  data;

  @tracked
  selectedProject = null;

  @tracked
  options;

  @action
  async fetchInitialOptions() {
    if (isEmpty(this.options)) {
      this.options = await this.data.findProjects.perform(this.args.standard, '', {
        pageSize: 5,
        sort: '-id',
      });
    }
  }
}
