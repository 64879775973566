import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';
import WelderStandard from 'weldnote/models/welder-standard';
import PqrStandard from 'weldnote/models/pqr-standard';
import { WeldingStandard } from 'weldnote/config/types/welding-standard';

function getStandardName(standard: WeldingStandard) {
  if (!isEmpty(standard)) {
    let standardName = get(standard, 'standard');
    if (!isEmpty(standardName)) {
      return standardName.trim();
    }
  }
  return '';
}

function getWelderStandardName(standard: WelderStandard) {
  if (!isEmpty(standard)) {
    let standardName = get(standard, 'standard');
    if (!isEmpty(standardName)) {
      return standardName.trim();
    }
  }
  return '';
}

export function isStandardISO(standard: PqrStandard) {
  return (
    isStandardISO15614(standard) ||
    isStandardISO15611(standard) ||
    isStandardISO15612(standard) ||
    isStandardISO15613(standard) ||
    isStandardISO15614Part2(standard)
  );
}

export function isStandardISO15614(standard: PqrStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'ISO 15614-1';
  }
  return false;
}

export function isStandardISO15613(standard: PqrStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'ISO 15613';
  }
  return false;
}

export function isStandardISO15611(standard: PqrStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'ISO 15611';
  }
  return false;
}

export function isStandardISO15612(standard: PqrStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'ISO 15612';
  }
  return false;
}

export function isStandardAWSB21(standard: PqrStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'AWS B2.1';
  }
  return false;
}

export function isStandardISO15614Part2(standard: PqrStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'ISO 15614-2';
  }
  return false;
}

export function isStandardISO15614Part5(standard: PqrStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'ISO 15614-5';
  }
  return false;
}

export function isWelderStandardISO96061(standard: WelderStandard) {
  if (!isEmpty(standard)) {
    return getWelderStandardName(standard) === 'ISO 9606-1';
  }
  return false;
}

export function isWelderStandardISO14732(standard: WelderStandard) {
  if (!isEmpty(standard)) {
    return getWelderStandardName(standard) === 'ISO 14732';
  }
  return false;
}

export function isWelderStandardISO96062(standard: WelderStandard) {
  if (!isEmpty(standard)) {
    return getWelderStandardName(standard) === 'ISO 9606-2';
  }
  return false;
}

export function isWelderStandardISO96065(standard: WelderStandard) {
  if (!isEmpty(standard)) {
    return getWelderStandardName(standard) === 'ISO 9606-5';
  }
  return false;
}

export function isStandardASME(standard: WeldingStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'ASME IX';
  }
  return false;
}

export function isStandardAWS(standard: WeldingStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'AWS D1.1';
  }
  return false;
}

export function isWelderStandardAWSD11(standard: WeldingStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'AWS D1.1';
  }
  return false;
}

export function isStandardAWSD11(standard: PqrStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'AWS D1.1';
  }
  return false;
}

export function isStandardAWSD143(standard: PqrStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'AWS D14.3';
  }
  return false;
}

export function isStandardAPI(standard: WeldingStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard) === 'API 1104';
  }
  return false;
}

export function isSameStandard(standard1: WeldingStandard, standard2: WeldingStandard) {
  if (!isEmpty(standard1) && !isEmpty(standard2)) {
    let standard1Name = get(standard1, 'standard');
    let standard2Name = get(standard2, 'standard');
    if (!isEmpty(standard1Name) && !isEmpty(standard2Name)) {
      return standard1Name === standard2Name;
    }
  }
  return false;
}

export function isSamePQRAndWelderStandard(
  pqrStandard: PqrStandard,
  welderStandard: WelderStandard
) {
  if (isWelderStandardISO96062(welderStandard)) {
    return isStandardAnyOfIso1561Type2(pqrStandard);
  }

  if (isStandardAnyOfISO1561X(pqrStandard)) {
    return isWelderStandardISO96061(welderStandard);
  } else if (isStandardISO15614Part5(pqrStandard)) {
    return isWelderStandardISO96065(welderStandard);
  } else if (isStandardASME(pqrStandard)) {
    return isStandardASME(welderStandard);
  } else if (isStandardAWS(pqrStandard)) {
    return isStandardAWS(welderStandard);
  } else if (isStandardAPI(pqrStandard)) {
    return isStandardAPI(welderStandard);
  } else {
    return isSameStandard(pqrStandard, welderStandard);
  }
}

export function isStandardAnyOfISO1561X(standard: PqrStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard).indexOf('ISO 1561') > -1;
  }
  return false;
}

export function isStandardAnyOfIso1561Type2(standard: PqrStandard) {
  if (!isEmpty(standard)) {
    return getStandardName(standard).indexOf('ISO 15614-2') > -1;
  }
  return false;
}

export function isStandardOfTypeAWS(standard: WeldingStandard) {
  if (!isEmpty(standard)) {
    let code = standard?.code?.code;
    return code === 'AWS';
  }
  return false;
}

export function isStandardOfTypeISO(standard: WeldingStandard) {
  if (!isEmpty(standard)) {
    let code = standard?.code?.code;
    return code === 'ISO';
  }
  return false;
}
