import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import Constants from 'weldnote/utils/constants';
import Project from './project';
import ComponentDrawing from './component-drawing';

const { PROJECT_REFERENCE_TYPE } = Constants;

export default class ProjectComponentDrawing extends BaseModel {
  @belongsTo('project')
  declare project: AsyncBelongsTo<Project>;

  @belongsTo('component-drawing')
  declare drawing: AsyncBelongsTo<ComponentDrawing>;

  @attr('string')
  declare referenceNumber?: string;

  @attr('string')
  declare title?: string;

  @attr('string')
  declare revision?: string;

  @attr('string')
  declare revisionStatus?: string;

  @attr('string')
  declare referencedDrawing?: string;

  @attr('string')
  declare referencedDrawingId?: string;

  @attr('string')
  declare referencedDrawingPdf?: string;

  @attr('string')
  declare drawingPdf?: string;

  @attr('string')
  declare referenceType?: string;

  get visualLabel(): string {
    return this.referenceNumber || '';
  }

  validations = {
    project: { presence: true },
    drawing: { presence: true },
  };

  metadata = {
    modelName: 'project-component-drawing',
    project: { required: true },
    drawing: { required: true },
  };

  get isManualReference(): boolean {
    return this.referenceType === PROJECT_REFERENCE_TYPE.MANUAL;
  }

  get isAutomaticReference(): boolean {
    return this.referenceType === PROJECT_REFERENCE_TYPE.REFERENCE;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-component-drawing': ProjectComponentDrawing;
  }
}
