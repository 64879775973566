import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<input\n  {{pikaday\n    @options\n    value=this.value\n    onSelect=this.onSelect\n    setDefaultDate=true\n    defaultDate=@defaultDate\n    onOpen=@onOpen\n    onDraw=this.onDraw\n    onClose=this.onClose\n    format=this.format\n    minDate=@minDate\n    maxDate=@maxDate\n    theme=@theme\n    yearRange=this.yearRange\n    i18n=this.i18n\n    firstDay=this.firstDay\n    container=@container\n    bound=@bound\n    register=@register\n  }}\n  {{on \"change\" this.didChange}}\n  type=\"text\"\n  readonly={{@readonly}}\n  placeholder={{@placeholder}}\n  required={{@required}}\n  disabled={{@disabled}}\n  autocomplete={{@autocomplete}}\n  ...attributes\n/>", {"contents":"<input\n  {{pikaday\n    @options\n    value=this.value\n    onSelect=this.onSelect\n    setDefaultDate=true\n    defaultDate=@defaultDate\n    onOpen=@onOpen\n    onDraw=this.onDraw\n    onClose=this.onClose\n    format=this.format\n    minDate=@minDate\n    maxDate=@maxDate\n    theme=@theme\n    yearRange=this.yearRange\n    i18n=this.i18n\n    firstDay=this.firstDay\n    container=@container\n    bound=@bound\n    register=@register\n  }}\n  {{on \"change\" this.didChange}}\n  type=\"text\"\n  readonly={{@readonly}}\n  placeholder={{@placeholder}}\n  required={{@required}}\n  disabled={{@disabled}}\n  autocomplete={{@autocomplete}}\n  ...attributes\n/>","moduleName":"weldnote/components/pikaday/pikaday-input.hbs","parseOptions":{"srcName":"weldnote/components/pikaday/pikaday-input.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment';

export default class PikadayInputComponent extends Component {
  constructor(owner, args) {
    super(owner, args);
  }

  get moment() {
    return this.args.moment || moment;
  }

  get format() {
    return this.args.format || 'DD.MM.YYYY';
  }

  get value() {
    let { value, useUTC } = this.args;
    let moment = this.moment;
    if (useUTC && value) {
      let format = 'YYYY-MM-DD';
      value = moment(moment.utc(value).format(format), format).toDate();
    }
    return value;
  }

  get yearRange() {
    const yearRange = this.args.yearRange;
    if (!yearRange) {
      return 10;
    }
    if (yearRange.indexOf(',') > -1) {
      const yearArray = yearRange.split(',');
      if (yearArray[1] === 'currentYear') {
        yearArray[1] = new Date().getFullYear();
      }
      return yearArray;
    } else {
      return yearRange;
    }
  }

  get i18n() {
    let i18n = this.args.i18n;
    if (!i18n) {
      return undefined;
    }
    if (!i18n.t) {
      return i18n;
    }
    return {
      previousMonth: i18n.t('previousMonth').toString(),
      nextMonth: i18n.t('nextMonth').toString(),
      months: i18n.t('months').toString().split(','),
      weekdays: i18n.t('weekdays').toString().split(','),
      weekdaysShort: i18n.t('weekdaysShort').toString().split(','),
    };
  }

  get firstDay() {
    return this.args.firstDay == null ? 1 : parseInt(this.args.firstDay, 10);
  }

  @action
  onClose() {
    if (this.isDestroying) {
      return;
    }
    if (this.#heardValue === undefined) {
      // Do nothing, this is here just because there's an issue with the DatePicker
      // that cleans the value if you open and close. Because when you open and close, there's no change event
      // but since the #heardValue was not changed, it's undefined and `!this.#heardValue` is truthy
      // but when you actually remove a value, this.#heardValue is equal to '' so the same expression
      // is also truthy, so we need to check against undefined to protect against the close without
      // changing
    } else if (!this.#heardValue) {
      // This is the original code, we want to pick either '' or null
      this.onSelect(null);
    }
    this.args.onClose?.();
  }


  #heardValue;

  @action
  onDraw() {
    // this is here because apparently the classic behavior is to pass no
    // arguments to the onDraw callback, but Pikaday's own ownDraw has an
    // argument.
    this.args.onDraw?.();
  }

  @action
  didChange(event) {
    this.#heardValue = event.target.value;
  }

  @action
  onSelect(date) {
    if (this.args.useUTC && date) {
      date = this.moment
        .utc([date.getFullYear(), date.getMonth(), date.getDate()])
        .toDate();
    }
    this.args.onSelection?.(date);
  }
}