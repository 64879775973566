import { belongsTo, attr } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';

export default class TensileTestLine extends BaseModel {
  @attr('number')
  declare orderNum?: number;

  @attr('string')
  declare type?: string;

  @attr('decimal-value')
  declare tensileStrength?: number;

  @attr('string')
  declare ruptureLocation?: string;

  @attr('string')
  declare observation?: string;

  @attr('decimal-value')
  declare width?: number;

  @attr('decimal-value')
  declare thickness?: number;

  @attr('decimal-value')
  declare areaSurface?: number;

  @attr('decimal-value')
  declare ultimateTotalLoad?: number;

  @belongsTo('pqr')
  declare pqr: any;

  metadata = {
    modelName: 'tensile-test-line',
  };

  isLineEmpty(): boolean {
    let allEmpty =
      isEmpty(this.type) &&
      isEmpty(this.width) &&
      isEmpty(this.tensileStrength) &&
      isEmpty(this.areaSurface) &&
      isEmpty(this.ruptureLocation) &&
      isEmpty(this.observation) &&
      isEmpty(this.thickness && isEmpty(this.ultimateTotalLoad));
    return allEmpty;
  }

  validations = {
    type: {
      length: {
        maximum: 30,
      },
    },

    tensileStrength: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999999.999,
      },
    },

    width: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
      },
    },

    thickness: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
      },
    },

    areaSurface: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
      },
    },

    ultimateTotalLoad: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
      },
    },

    ruptureLocation: {
      length: {
        maximum: 100,
      },
    },

    observation: {
      length: {
        maximum: 50,
      },
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tensile-test-line': TensileTestLine;
  }
}
