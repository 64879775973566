import { belongsTo, attr } from '@ember-data/model';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import IndustryCode from './industry-code';
import Constants from 'weldnote/utils/constants';

const { WELD_TYPES } = Constants;

export default class WeldType extends BaseModel {
  @attr('string')
  declare weldType: string;

  @attr('string')
  declare designation: string;

  @belongsTo('industry-code', { async: false })
  declare industryCode: IndustryCode;

  @attr('boolean')
  declare system: boolean;

  get isOverlayWeld() {
    return this.weldType === WELD_TYPES.OVERLAY_WELD;
  }

  get isButtWeld() {
    return this.weldType === WELD_TYPES.BUTT_WELD;
  }

  get visualLabel(): string {
    let type = this.weldType;
    if (isEmpty(type)) {
      type = '';
    }

    let industryCode = get(this.industryCode, 'code');
    if (industryCode === 'ASME' || industryCode === 'AWS') {
      if (type === 'BW') {
        return 'GW - Groove Weld';
      } else if (type === 'PJP BW') {
        return 'PJP GW - Partial Joint Penetration Groove Weld';
      } else if (type === 'CJP BW') {
        return 'CJP GW - Complete Joint Penetration Groove Weld';
      } else if (type === 'PP BW') {
        return 'PP GW - Partial Penetration Groove Weld';
      }
    }
    return `${this.weldType} - ${this.designation}`;
  }

  validations = {
    weldType: { presence: true },
    industryCode: { presence: true },
  };

  metadata = {
    modelName: 'weld-type',
    weldType: {
      required: true,
    },

    industryCode: {
      required: true,
    },
  };
}
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'weld-type': WeldType;
  }
}
