import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<LinkTo @route={{this.newRoutePath}}>\n  <button\n    class=\"btn btn-primary\"\n    data-test=\"list-new-button\"\n    title={{if (has-value @title) @title @label}}\n    type=\"button\"\n  >\n    {{#if (has-value @label)}}\n      {{@label}}\n    {{else}}\n      {{t \"toolbar.new.label\"}}\n    {{/if}}\n  </button>\n</LinkTo>", {"contents":"<LinkTo @route={{this.newRoutePath}}>\n  <button\n    class=\"btn btn-primary\"\n    data-test=\"list-new-button\"\n    title={{if (has-value @title) @title @label}}\n    type=\"button\"\n  >\n    {{#if (has-value @label)}}\n      {{@label}}\n    {{else}}\n      {{t \"toolbar.new.label\"}}\n    {{/if}}\n  </button>\n</LinkTo>","moduleName":"weldnote/components/w-toolbar/new-button.hbs","parseOptions":{"srcName":"weldnote/components/w-toolbar/new-button.hbs"}});
import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

@classic
export default class NewButton extends Component {
  route = '';

  @alias('route')
  newRoutePath;
}
