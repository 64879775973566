import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';
import Constants from 'weldnote/utils/constants';

const { ARCHIVAL_STATE } = Constants;

export default class ProjectsArchivedRoute extends ListRoute {
  @service('weldnote-data')
  data;

  model() {
    return this.data.listAllProjects(
      {},
      {
        archivalState: ARCHIVAL_STATE.ARCHIVED,
      }
    );
  }

  setupController(controller, model) {
    controller.projects = model.list;
    controller.totalCount = model.count;
  }
}
