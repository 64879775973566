import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class PostWeldTreatmentMethod extends BaseModel {
  @attr('string')
  declare designation: string;

  @attr('boolean', { defaultValue: false })
  declare system: boolean;

  get visualLabel(): string {
    return this.designation || '';
  }

  validations = {
    designation: {
      presence: {
        message(_key: string, _value: string, model: PostWeldTreatmentMethod): string {
          return model.intl.t('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'post-weld-treatment-method',
    designation: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'post-weld-treatment-method': PostWeldTreatmentMethod;
  }
}
