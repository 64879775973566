import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.componentStatus}}", {"contents":"{{this.componentStatus}}","moduleName":"weldnote/components/model-project-component/component-progress-list-display.hbs","parseOptions":{"srcName":"weldnote/components/model-project-component/component-progress-list-display.hbs"}});
import Component from '@glimmer/component';
import { service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import Constants from 'weldnote/utils/constants';
import ProjectComponent from 'weldnote/models/project-component';
import { formatDate } from 'weldnote/helpers/format-date';

const { COMPONENT_PROGRESS_STATE } = Constants;

interface ModelProjectComponentComponentProgressListDisplayArgs {
  component: ProjectComponent;
}

export default class ModelProjectComponentComponentProgressListDisplay extends Component<ModelProjectComponentComponentProgressListDisplayArgs> {
  @service
  declare intl: IntlService;

  formatTheDate(date?: Date | null) {
    if (date) {
      return formatDate([], { date });
    }
    return '';
  }

  get componentStatus() {
    if (this.args.component.progressState) {
      let state: number = parseInt(`${this.args.component.progressState}`);
      let { intl } = this;
      if (state === COMPONENT_PROGRESS_STATE.INITIAL) {
        return intl.t('lov.component-progress-state.initial');
      } else if (state === COMPONENT_PROGRESS_STATE.COMPLETED) {
        return `${intl.t('lov.component-progress-state.completed')} - ${this.formatTheDate(
          this.args.component.completedDate
        )}`;
      } else if (state === COMPONENT_PROGRESS_STATE.TESTED) {
        return `${intl.t('lov.component-progress-state.tested')} - ${this.formatTheDate(
          this.args.component.testedDate
        )}`;
      } else if (state === COMPONENT_PROGRESS_STATE.PROCESSED) {
        return `${intl.t('lov.component-progress-state.processed')} - ${this.formatTheDate(
          this.args.component.processedDate
        )}`;
      } else if (state === COMPONENT_PROGRESS_STATE.SHIPPED) {
        return `${intl.t('lov.component-progress-state.shipped')} - ${this.formatTheDate(
          this.args.component.shippedDate
        )}`;
      }
      return '';
    }
    return '';
  }
}
