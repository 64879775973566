import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (has-value @value)}}\n  {{format-date date=@value}}\n  <span class=\"block text-gray-600 text-lg\">\n    ({{this.age}}\n    {{t \"helpers.years\"}})\n  </span>\n{{/if}}", {"contents":"{{#if (has-value @value)}}\n  {{format-date date=@value}}\n  <span class=\"block text-gray-600 text-lg\">\n    ({{this.age}}\n    {{t \"helpers.years\"}})\n  </span>\n{{/if}}","moduleName":"weldnote/components/weldnote-grid/person-age.hbs","parseOptions":{"srcName":"weldnote/components/weldnote-grid/person-age.hbs"}});
import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import moment from 'moment';

@classic
export default class PersonAge extends Component {
  @service
  intl;

  @computed('value')
  get age() {
    let momentDate = moment(this.value);
    let age = moment().diff(momentDate, 'years');
    return age;
  }
}
