export default {
  isFillerMaterialGroup1(group: string): boolean {
    return (
      group === '1 (1)' ||
      group === '1 (2)' ||
      group === '1 (3)' ||
      group === '1 (4)' ||
      group === '1 (5)' ||
      group === '1 (6)' ||
      group === '1 (7)' ||
      group === '1 (8)' ||
      group === '1 (9)' ||
      group === '1 (10)' ||
      group === '1 (11)' ||
      group === '1 (12)' ||
      group === '1 (N/A)'
    );
  },

  isFillerMaterialGroup2(group: string): boolean {
    return (
      group === '2 (1)' ||
      group === '2 (2)' ||
      group === '2 (3)' ||
      group === '2 (4)' ||
      group === '2 (5)' ||
      group === '2 (6)' ||
      group === '2 (7)' ||
      group === '2 (8)' ||
      group === '2 (9)' ||
      group === '2 (10)' ||
      group === '2 (11)' ||
      group === '2 (12)' ||
      group === '2 (N/A)'
    );
  },

  isFillerMaterialGroup3(group: string): boolean {
    return (
      group === '3 (1)' ||
      group === '3 (2)' ||
      group === '3 (3)' ||
      group === '3 (4)' ||
      group === '3 (5)' ||
      group === '3 (6)' ||
      group === '3 (7)' ||
      group === '3 (8)' ||
      group === '3 (9)' ||
      group === '3 (10)' ||
      group === '3 (11)' ||
      group === '3 (12)' ||
      group === '3 (N/A)'
    );
  },

  isFillerMaterialGroup4(group: string): boolean {
    return (
      group === '4 (1)' ||
      group === '4 (2)' ||
      group === '4 (3)' ||
      group === '4 (4)' ||
      group === '4 (5)' ||
      group === '4 (6)' ||
      group === '4 (7)' ||
      group === '4 (8)' ||
      group === '4 (9)' ||
      group === '4 (10)' ||
      group === '4 (11)' ||
      group === '4 (12)' ||
      group === '4 (N/A)'
    );
  },

  isFillerMaterialGroup5(group: string): boolean {
    return (
      group === '5 (1)' ||
      group === '5 (2)' ||
      group === '5 (3)' ||
      group === '5 (4)' ||
      group === '5 (5)' ||
      group === '5 (6)' ||
      group === '5 (7)' ||
      group === '5 (8)' ||
      group === '5 (9)' ||
      group === '5 (10)' ||
      group === '5 (11)' ||
      group === '5 (12)' ||
      group === '5 (N/A)'
    );
  },

  isFillerMaterialGroup6(group: string): boolean {
    return (
      group === '6 (1)' ||
      group === '6 (2)' ||
      group === '6 (3)' ||
      group === '6 (4)' ||
      group === '6 (5)' ||
      group === '6 (6)' ||
      group === '6 (7)' ||
      group === '6 (8)' ||
      group === '6 (9)' ||
      group === '6 (10)' ||
      group === '6 (11)' ||
      group === '6 (12)' ||
      group === '6 (N/A)'
    );
  },

  isFillerMaterialGroupRange30to40(group: string): boolean {
    return (
      group === '34 (N/A)' ||
      group === '41 (N/A)' ||
      group === '42 (N/A)' ||
      group === '43 (N/A)' ||
      group === '44 (N/A)' ||
      group === '45 (N/A)' ||
      group === '46 (N/A)'
    );
  },

  isFillerMaterialGroup50(group: string): boolean {
    return (
      group === '51 (N/A)' ||
      group === '52 (N/A)' ||
      group === '53 (N/A)' ||
      group === '54 (N/A)' ||
      group === '55 (N/A)'
    );
  },

  getFillerMaterial1Groups(): string[] {
    return [
      '1 (1)',
      '1 (2)',
      '1 (3)',
      '1 (4)',
      '1 (5)',
      '1 (6)',
      '1 (7)',
      '1 (8)',
      '1 (9)',
      '1 (10)',
      '1 (11)',
      '1 (12)',
      '1 (N/A)',
    ];
  },

  getFillerMaterial2Groups(): string[] {
    return [
      '2 (1)',
      '2 (2)',
      '2 (3)',
      '2 (4)',
      '2 (5)',
      '2 (6)',
      '2 (7)',
      '2 (8)',
      '2 (9)',
      '2 (10)',
      '2 (11)',
      '2 (12)',
      '2 (N/A)',
    ];
  },

  getFillerMaterial3Groups(): string[] {
    return [
      '3 (1)',
      '3 (2)',
      '3 (3)',
      '3 (4)',
      '3 (5)',
      '3 (6)',
      '3 (7)',
      '3 (8)',
      '3 (9)',
      '3 (10)',
      '3 (11)',
      '3 (12)',
      '3 (N/A)',
    ];
  },

  getFillerMaterial4Groups(): string[] {
    return [
      '4 (1)',
      '4 (2)',
      '4 (3)',
      '4 (4)',
      '4 (5)',
      '4 (6)',
      '4 (7)',
      '4 (8)',
      '4 (9)',
      '4 (10)',
      '4 (11)',
      '4 (12)',
      '4 (N/A)',
    ];
  },

  getFillerMaterial5Groups(): string[] {
    return [
      '5 (1)',
      '5 (2)',
      '5 (3)',
      '5 (4)',
      '5 (5)',
      '5 (6)',
      '5 (7)',
      '5 (8)',
      '5 (9)',
      '5 (10)',
      '5 (11)',
      '5 (12)',
      '5 (N/A)',
    ];
  },

  getFillerMaterial6Groups(): string[] {
    return [
      '6 (1)',
      '6 (2)',
      '6 (3)',
      '6 (4)',
      '6 (5)',
      '6 (6)',
      '6 (7)',
      '6 (8)',
      '6 (9)',
      '6 (10)',
      '6 (11)',
      '6 (12)',
      '6 (N/A)',
    ];
  },

  getFillerMaterial30to40Groups(): string[] {
    return ['34 (N/A)', '41 (N/A)', '42 (N/A)', '43 (N/A)', '44 (N/A)', '45 (N/A)', '46 (N/A)'];
  },

  getFillerMaterial50Groups(): string[] {
    return ['51 (N/A)', '52 (N/A)', '53 (N/A)', '54 (N/A)', '55 (N/A)'];
  },
};
