import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class FillerMaterialSpecification extends BaseModel {
  @attr('string')
  declare specification?: string;

  @attr('string')
  declare description?: string;

  @attr('boolean')
  declare system?: boolean;

  get visualLabel(): string {
    return this.specification || '';
  }

  validations = {
    specification: {
      presence: {
        message(_key: string, _value: string, model: FillerMaterialSpecification): string {
          return model.intl.t('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'filler-material-specification',
    specification: {
      required: true,
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'filler-material-specification': FillerMaterialSpecification;
  }
}
