import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import { memberAction } from 'ember-api-actions';
import Person from './person';
import FileUpload from './file-upload';
import Company from './company';

export default class Fitter extends BaseModel {
  @attr('string')
  declare fitterName?: string;

  @attr('string')
  declare fitterStamp?: string;

  @attr('string')
  declare fitterId?: string;

  @attr('string')
  declare fitterType?: string;

  @attr('date')
  declare dateBirth?: Date;

  @belongsTo('company')
  declare company: AsyncBelongsTo<Company>;

  @belongsTo('person')
  declare person: AsyncBelongsTo<Person>;

  @belongsTo('file-upload')
  declare fitterReport: AsyncBelongsTo<FileUpload>;

  @attr('string')
  declare fitterReportPath?: string;

  get visualLabel(): string {
    return this.fitterName || '';
  }

  validations = {
    fitterName: { presence: true },
    fitterStamp: { presence: true },
  };

  metadata = {
    modelName: 'fitter',
    fitterName: { required: true },
    fitterStamp: { required: true },
  };

  get name() {
    return this.fitterName;
  }

  get stamp() {
    return this.fitterStamp;
  }

  createFitterReport = memberAction({ path: 'createFitterReport' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    fitter: Fitter;
  }
}
