import Service from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

/**
 * Service keep track of changes in a single weld of a project
 * This allows to implement a 'warning when you leave the route with an unsaved weld'
 */
export default class ProjectWeldChangeTrackerService extends Service {
  initialWeldState = {};

  @tracked
  currentWeld;

  @tracked
  showSaveAlert = false;

  @tracked
  weldChanged = false;

  trackWeld(weld) {
    this.currentWeld = weld;
    this._saveInitialState(weld);
    this.weldChanged = false;
  }

  _saveInitialState(weld) {
    weld.eachRelationship((name, descriptor) => {
      if (descriptor.kind === 'hasMany') {
        let nameIds = weld.hasMany(name).ids();
        this.initialWeldState[name] = nameIds;
      } else if (descriptor.kind === 'belongsTo') {
        this.initialWeldState[name] = weld.belongsTo(name).id();
      }
    });
  }

  weldSaved(weld) {
    this.trackWeld(weld);
  }

  _didBelongsToChange(attributeName, newValue) {
    let previousValue = this.initialWeldState[attributeName];
    if (isEmpty(previousValue) && isEmpty(newValue)) {
      return false;
    } else if (isEmpty(previousValue) && !isEmpty(newValue)) {
      return true;
    } else if (!isEmpty(previousValue) && isEmpty(newValue)) {
      return true;
    } else {
      return previousValue !== newValue;
    }
  }

  _didHasManyChange(attributeName, newValue) {
    let previousValue = this.initialWeldState[attributeName];
    if (isEmpty(previousValue) && isEmpty(newValue)) {
      return false;
    } else if (isEmpty(previousValue) && !isEmpty(newValue)) {
      return true;
    } else if (!isEmpty(previousValue) && isEmpty(newValue)) {
      return true;
    } else {
      if (previousValue.length !== newValue.length) {
        return true;
      } else {
        let previousSet = new Set(previousValue);
        let difference = [...new Set(newValue.filter((x) => !previousSet.has(x)))];
        if (isEmpty(difference)) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  get shouldShowSaveAlert() {
    return this.showSaveAlert;
  }

  get isChanged() {
    return this.weldChanged;
  }

  didWeldChange() {
    let { currentWeld } = this;
    if (isEmpty(currentWeld)) {
      return false;
    }

    if (currentWeld.isNew) {
      let changed = currentWeld.changedAttributes();
      // A new DS.Model object returns `hasDirtyAttributes` as true
      // although no attributes are changed, as such we need to check for the actual
      // changed attributes
      if (Object.keys(changed).length > 0) {
        return true;
      }
    } else {
      if (currentWeld.hasDirtyAttributes) {
        return true;
      }
    }
    let isDifferent = false;
    currentWeld.eachRelationship((name, descriptor) => {
      if (descriptor.kind === 'hasMany') {
        let nameIds = currentWeld.hasMany(name).ids();
        if (this._didHasManyChange(name, nameIds)) {
          isDifferent = true;
        }
      } else if (descriptor.kind === 'belongsTo') {
        let relationId = currentWeld.belongsTo(name).id();
        if (this._didBelongsToChange(name, relationId)) {
          isDifferent = true;
        }
      }
      if (isDifferent) {
        return;
      }
    });
    this.weldChanged = isDifferent;
    return isDifferent;
  }

  @action
  cancelShowSaveAlert() {
    this.showSaveAlert = false;
  }

  @action
  displaySaveAlert() {
    this.showSaveAlert = true;
  }

  @action
  clearState() {
    this.currentWeld = null;
    this.initialWeldState = {};
    this.showSaveAlert = false;
  }
}
