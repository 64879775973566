import { service } from '@ember/service';
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import WelderCertificate from 'weldnote/models/welder-certificate';
import WopqApprovalRange from 'weldnote/src-weldnote/wopq/approval-ranges/wopq-approval-range';
import {
  AutomaticArcVoltageControl,
  WelderCertificateTestPieceValues,
  WelderOperatorTypes,
} from 'weldnote/src-weldnote/wopq/types';

interface ModelWelderCertificateArcLengthControlApprovalRangeArgs {
  arcLengthControl: AutomaticArcVoltageControl | null;
  certificate: WelderCertificate;
  approvalRange: WopqApprovalRange;
  process: string;
}

export default class ModelWelderCertificateArcLengthControlApprovalRange extends Component<ModelWelderCertificateArcLengthControlApprovalRangeArgs> {
  @service
  declare intl: IntlService;

  constructor(owner: unknown, args: ModelWelderCertificateArcLengthControlApprovalRangeArgs) {
    super(owner, args);
  }

  get value(): string {
    if (this.args.arcLengthControl) {
      let result = '';
      let possibleArcLength = WelderOperatorTypes.arcVoltageControlFromString(
        this.args.arcLengthControl
      );
      if (possibleArcLength.isPresent()) {
        let arcLengthValue = possibleArcLength.get();
        let possibleProcess = WelderOperatorTypes.processFromString(this.args.process);
        let process = null;
        if (possibleProcess.isEmpty()) {
          return result;
        } else {
          process = possibleProcess.get();
        }
        let testPieces = new WelderCertificateTestPieceValues(this.args.certificate, process);
        let approvalRange = this.args.approvalRange.automaticVoltageControl(
          arcLengthValue,
          testPieces
        );
        result = approvalRange
          .map((t) => {
            if (t === AutomaticArcVoltageControl.WITH) {
              return this.intl.t('lov.arc-voltage.with');
            } else if (t === AutomaticArcVoltageControl.WITHOUT) {
              return this.intl.t('lov.arc-voltage.without');
            }
            return '';
          })
          .join('+');
        return result;
      }
    }
    return '';
  }
}
