import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get, action } from '@ember/object';
import { service } from '@ember/service';

@classic
export default class WeldingProcedureSpecificationPwpsToStandardWpsController extends Controller {
  @alias('model')
  wps;

  @service
  router;

  @action
  cancel() {
    this.router.transitionTo('welding-procedure-specification', this.wps.id);
  }

  @action
  afterCreateWps(id) {
    this.router.transitionTo('welding-procedure-specification', id);
  }
}
