import { registerWarnHandler } from '@ember/debug';

const IGNORED_WARNINGS = ['ds.store.push-link-for-sync-relationship'];

export default {
  name: 'warnings-to-ignore',
  initialize() {
    registerWarnHandler(function (message, options, next) {
      if (!ignoreWarning(options)) {
        next(...arguments);
      }
    });
  },
};

function ignoreWarning(options) {
  return options && options.id && IGNORED_WARNINGS.includes(options.id);
}
