import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @isEditing}}\n  <PowerSelectMultiple\n    @options={{@options}}\n    @searchEnabled={{this.isSearchEnabled}}\n    @onOpen={{@onOpen}}\n    @searchField={{@searchField}}\n    @disabled={{@disabled}}\n    @selected={{@value}}\n    @optionsComponent={{@optionsComponent}}\n    @onChange={{@onUpdate}}\n    @search={{@search}}\n    @triggerClass={{concat \"form-control \" @cssClass}}\n    @afterOptionsComponent={{@afterOptionsComponent}}\n    @noMatchesMessage={{@noMatchesMessage}}\n    @placeholder={{@placeholder}}\n    as |option|\n  >\n    {{#if (has-block)}}\n      {{yield option}}\n    {{else}}\n      {{option.visualLabel}}\n    {{/if}}\n  </PowerSelectMultiple>\n{{else}}\n  <p class=\"form-control-static\">{{@value.visualLabel}}</p>\n{{/if}}", {"contents":"{{#if @isEditing}}\n  <PowerSelectMultiple\n    @options={{@options}}\n    @searchEnabled={{this.isSearchEnabled}}\n    @onOpen={{@onOpen}}\n    @searchField={{@searchField}}\n    @disabled={{@disabled}}\n    @selected={{@value}}\n    @optionsComponent={{@optionsComponent}}\n    @onChange={{@onUpdate}}\n    @search={{@search}}\n    @triggerClass={{concat \"form-control \" @cssClass}}\n    @afterOptionsComponent={{@afterOptionsComponent}}\n    @noMatchesMessage={{@noMatchesMessage}}\n    @placeholder={{@placeholder}}\n    as |option|\n  >\n    {{#if (has-block)}}\n      {{yield option}}\n    {{else}}\n      {{option.visualLabel}}\n    {{/if}}\n  </PowerSelectMultiple>\n{{else}}\n  <p class=\"form-control-static\">{{@value.visualLabel}}</p>\n{{/if}}","moduleName":"weldnote/components/ui-form/input/multi-lov.hbs","parseOptions":{"srcName":"weldnote/components/ui-form/input/multi-lov.hbs"}});
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';

export default class MultiLov extends Component {
  get isSearchEnabled() {
    if (!isEmpty(this.args.searchEnabled)) {
      return this.args.searchEnabled;
    }
    return false;
  }
}
