import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ModelProject::ComponentChooserWithOptions\n  @label={{@label}}\n  @availableOptions={{this.selectableComponents}}\n  @selectedComponent={{@selectedComponent}}\n  @onComponentSelected={{@onComponentSelected}}\n  @errors={{@errors}}\n  @disabled={{@disabled}}\n  @isRequired={{@isRequired}}\n  {{did-update this.fetchProjectComponents @project}}\n/>", {"contents":"<ModelProject::ComponentChooserWithOptions\n  @label={{@label}}\n  @availableOptions={{this.selectableComponents}}\n  @selectedComponent={{@selectedComponent}}\n  @onComponentSelected={{@onComponentSelected}}\n  @errors={{@errors}}\n  @disabled={{@disabled}}\n  @isRequired={{@isRequired}}\n  {{did-update this.fetchProjectComponents @project}}\n/>","moduleName":"weldnote/components/model-project/component-chooser.hbs","parseOptions":{"srcName":"weldnote/components/model-project/component-chooser.hbs"}});
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ProjectComponentChooser extends Component {
  @service('weldnote-data')
  data;

  @tracked
  selectableComponents = [];

  constructor() {
    super(...arguments);
    this.fetchProjectComponents();
  }

  fetchedComponentsProjectId = null;

  // TODO: instead of fetching all components of a project, we should instead have a backend search
  @action
  async fetchProjectComponents() {
    if (isEmpty(this.args.project)) {
      this.selectableComponents = [];
    } else {
      if (
        this.fetchedComponentsProjectId == null ||
        this.fetchedComponentsProjectId !== this.args.project.id
      ) {
        this.fetchedComponentsProjectId = this.args.project.id;
        this.selectableComponents = await this.data.listAllProjectComponents({
          project: this.args.project.id,
          pageSize: 999,
        });
      }
    }
  }
}
