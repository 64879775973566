import { belongsTo, attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import NdtReportCertificate from './ndt-report-certificate';
import WelderCertificate from './welder-certificate';

export default class WelderCertificateRevalidation extends BaseModel {
  @attr('date')
  declare previousDate?: Date;

  @attr('date')
  declare nextDate?: Date;

  @attr('date')
  declare dateOfRevalidation?: Date;

  @attr('string')
  declare revalidator?: string;

  @belongsTo('welder-certificate')
  declare certificate: WelderCertificate;

  @attr('number')
  declare timePeriodMonths?: number;

  @attr('string')
  declare type?: string;

  @belongsTo('ndt-report-certificate', {
    async: false,
  })
  declare revalidationEvidenceReport1: NdtReportCertificate | null;

  @belongsTo('ndt-report-certificate', {
    async: false,
  })
  declare revalidationEvidenceReport2: NdtReportCertificate | null;

  @attr('string')
  declare report1Path?: string;

  @attr('string')
  declare report2Path?: string;

  get visualLabel() {
    return this.dateOfRevalidation;
  }

  validations = {
    previousDate: { presence: true },
    nextDate: { presence: true },
    dateOfRevalidation: { presence: true },
    certificate: { presence: true },
    type: { presence: true },
  };

  metadata = {
    modelName: 'welder-certificate-revalidation',
    previousDate: { required: true },
    nextDate: { required: true },
    dateOfRevalidation: { required: true },
    certificate: { required: true },
    type: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'welder-certificate-revalidation': WelderCertificateRevalidation;
  }
}
