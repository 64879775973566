export default {
  PROJECT_COMPONENT: {
    COMPONENT_NUMBER: 'componentNumber',
    COMPONENT_NAME: 'componentName',
    ORIGINAL_DRAWING: 'originalDrawing',
    COMPONENT_DRAWING: 'componentDrawing',
    LINE_CLASS: 'lineClass',
    PROJECT: 'project',
  },
};
