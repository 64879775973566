import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import Account from './account';
import Company from './company';
import StaffPosition from './staff-position';

export default class Staff extends BaseModel {
  @attr('string')
  declare name?: string;

  @belongsTo('staff-position')
  declare position: AsyncBelongsTo<StaffPosition>;

  @belongsTo('company')
  declare company: AsyncBelongsTo<Company>;

  @belongsTo('account', { async: false })
  declare account: Account;

  get visualLabel(): string {
    return this.name || '';
  }

  validations = {
    name: { presence: true },
    position: { presence: true },
    email: {
      email: {
        email: true,
        allowBlank: true,
      },
    },
  };

  metadata = {
    modelName: 'staff',
    listRoute: 'staffs',
    editRoute: 'staff',
    name: { required: true },
    position: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    staff: Staff;
  }
}
