import classic from 'ember-classic-decorator';
import { attributeBindings } from '@ember-decorators/component';
import { get, computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Row from 'ember-light-table/components/lt-row';
import { service } from '@ember/service';

@classic
@attributeBindings('style')
export default class WpsParameterRow extends Row {
  @service
  highlightedPassHelper;

  @computed('row.guid')
  get style() {
    let { row } = this;
    let guid = get(row, 'guid');

    if (this.highlightedPassHelper.isHighlighted(guid)) {
        return htmlSafe('background-color: #eff8ff; border: 2px solid #2569a9');
    }
    return htmlSafe('');
  }
}
