import { service } from '@ember/service';
import Controller from '@ember/controller';
import { isEmpty } from '@ember/utils';
import { assert } from '@ember/debug';
import { action, computed } from '@ember/object';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';

export default class ListController extends Controller {
  @service
  intl;

  @service
  router;

  /**
   * The name of the model associated with this controller
   * @type {String}
   */

  modelName = null;

  /**
   * @private
   * The colum definition for the grid
   * @type {[array]}
   */
  columns = null;

  newRoutePath;

  routeTitle;

  /**
   * @private
   * Retrieves the localized label of a given attribute of the model
   * @param  {string} attribute [The attribute name]
   * @return {string}           [The localized label of the attribute]
   */
  getAttributeLabel(attribute, options = {}) {
    let labelPath = `model.${this.modelName}.${attribute}.label`;
    return this.intl.t(labelPath, options);
  }

  getLabel(name) {
    let labelPath = `model.${this.modelName}.${name}`;
    return this.intl.t(labelPath);
  }

  getModelPluralLabel() {
    return this.getLabel('model-plural');
  }

  @computed('modelName')
  get breadcrumbs() {
    return [
      {
        label: this.getModelPluralLabel(),
      },
    ];
  }

  init() {
    super.init(...arguments);
    assert('Must have a model name property "ModelName" ', !isEmpty(this.modelName));
    this.newRoutePath = `${this.modelName}.new`;
    this.routeTitle = this.getModelPluralLabel();
  }

  /**
   * @public
   * Transitions to the model's edit screen
   *
   * @param  {number} id [The model instance identifier]
   */
  @action
  goToModel(id) {
    if (this.modelName) {
      this.router.transitionTo(this.modelName, id);
    }
  }
}
