import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get, action } from '@ember/object';
import { service } from '@ember/service';

@classic
export default class WeldingProcedureSpecificationPwpsToCertificateController extends Controller {
  @alias('model')
  wps;

  @service
  router;

  @action
  cancel() {
    this.router.transitionTo('welding-procedure-specification', get(this, 'model.id'));
  }

  @action
  afterCreateCertificate(id) {
    this.router.transitionTo('welder-certificate', id, {
      queryParams: {
        isEditing: true,
      },
    });
  }
}
