export default {
  GROOVE_DESIGN: {
    DESCRIPTION: 'description',
    LONG_DESCRIPTION: 'longDescription',
    IMAGE: 'image',
    WELDING_DETAILS: 'weldingDetails',
    WELD_TYPES: 'weldTypes',
    GROOVE_TYPE: 'grooveType',
    JOINT_TYPE: 'jointType',
  },
};
