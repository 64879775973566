import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @isEditing}}\n  <input\n    value={{this.decimalValue}}\n    {{on \"input\" (pick \"target.value\" @onUpdate)}}\n    class=\"form-control\"\n    placeholder={{@placeholder}}\n    type=\"text\"\n    disabled={{@disabled}}\n    id={{@componentId}}\n    data-test-id={{@attribute}}\n  />\n{{else}}\n  <p class=\"form-control-static\" data-test-id={{@attribute}}>{{this.decimalValue}}</p>\n{{/if}}", {"contents":"{{#if @isEditing}}\n  <input\n    value={{this.decimalValue}}\n    {{on \"input\" (pick \"target.value\" @onUpdate)}}\n    class=\"form-control\"\n    placeholder={{@placeholder}}\n    type=\"text\"\n    disabled={{@disabled}}\n    id={{@componentId}}\n    data-test-id={{@attribute}}\n  />\n{{else}}\n  <p class=\"form-control-static\" data-test-id={{@attribute}}>{{this.decimalValue}}</p>\n{{/if}}","moduleName":"weldnote/components/ui-form/input/decimal.hbs","parseOptions":{"srcName":"weldnote/components/ui-form/input/decimal.hbs"}});
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default class Decimal extends Component {
  @service
  userSession;

  get separator() {
    return this.userSession.decimalSeparator;
  }

  get decimalValue() {
    let { separator } = this;
    if (separator !== '.') {
      let value = get(this.args, `model.${this.args.attribute}`);
      if (!isEmpty(value)) {
        let valueString = `${value}`;
        valueString = valueString.replace('.', separator);
        return valueString;
      }
      return value;
    } else {
      return get(this.args, `model.${this.args.attribute}`);
    }
  }
}
