import { htmlSafe } from '@ember/template';
import { isEmpty } from '@ember/utils';
import Helper from '@ember/component/helper';
import Ember from 'ember';

const { Handlebars } = Ember;

export function progressWidth(params /* , hash*/) {
  let [value, minWidth] = params;
  value = Handlebars.Utils.escapeExpression(value);
  if (isEmpty(minWidth)) {
    minWidth = 10;
  }
  return htmlSafe(`width: ${value}%;min-width: ${minWidth}px`);
}

export default Helper.helper(progressWidth);
