import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { set } from '@ember/object';

export default class NewRoute extends Route {
  @service('instanceTracker')
  tracker;

  @service
  store;

  @service session;

  modelName = 'welding-map';

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  model() {
    if (this.get('tracker.hasWeldingMap')) {
      return this.get('tracker.currentWeldingMap');
    }
    return this.tracker.getWeldingMap();
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(this, 'currentModel', model);
    controller.set('modelName', this.modelName);
  }
}
