import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class NewRoute extends Route {
  @service
  store;

  @service
  userSession;

  @service
  router;

  beforeModel() {
    super.beforeModel(...arguments);
    let { userSession: session } = this;
    if (!session.hasFeature('iies')) {
      this.router.transitionTo('index');
    }
  }

  model() {
    return this.store.createRecord('ieis');
  }
}
