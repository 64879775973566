import classic from 'ember-classic-decorator';
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';

@classic
export default class SettingsController extends Controller {
  queryParams = ['category'];

  @service
  userSession;

  category = 'user-settings';

  @alias('userSession.isAccountAdmin')
  isAccountAdmin;

  @alias('userSession.isAdmin')
  isAdmin;

  @action
  setType(type) {
    set(this, 'category', type);
  }
}
