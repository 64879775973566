import { get } from '@ember/object';
import { formatDate } from 'weldnote/helpers/format-date';
import Constants from 'weldnote/utils/constants';

const { WELD_PROGRESS_STATE } = Constants;

export function createStatesList(weld) {
  let state = parseInt(get(weld, 'state'));
  let stateList = [];
  stateList.pushObject({
    complete: true,
    labelKey: 'components.model-project-weld/edit.weld-progress.waiting',
    date: '',
  });
  if (state >= WELD_PROGRESS_STATE.FIT) {
    stateList.pushObject({
      complete: true,
      labelKey: 'components.model-project-weld/edit.weld-progress.fitted',
      date: formatDate([], { date: get(weld, 'fitDate') }),
    });
  } else {
    stateList.pushObject({
      complete: false,
      labelKey: 'components.model-project-weld/edit.weld-progress.fitted',
      date: '',
    });
  }
  if (state >= WELD_PROGRESS_STATE.WELDED) {
    stateList.pushObject({
      complete: true,
      labelKey: 'components.model-project-weld/edit.weld-progress.welded',
      date: formatDate([], { date: get(weld, 'weldDate') }),
    });
  } else {
    stateList.pushObject({
      complete: false,
      labelKey: 'components.model-project-weld/edit.weld-progress.welded',
      date: '',
    });
  }
  if (get(weld, 'requiresPwht')) {
    if (state >= WELD_PROGRESS_STATE.PWHT_COMPLETED) {
      stateList.pushObject({
        complete: true,
        labelKey: 'components.model-project-weld/edit.weld-progress.pwht-complete',
        date: formatDate([], { date: get(weld, 'pwhtDateCompleted') }),
      });
    } else {
      stateList.pushObject({
        complete: false,
        labelKey: 'components.model-project-weld/edit.weld-progress.pwht-complete',
        date: '',
      });
    }
  }
  if (state >= WELD_PROGRESS_STATE.TESTED) {
    stateList.pushObject({
      complete: true,
      labelKey: 'components.model-project-weld/edit.weld-progress.tested',
      date: formatDate([], { date: get(weld, 'testDate') }),
    });
  } else {
    stateList.pushObject({
      complete: false,
      labelKey: 'components.model-project-weld/edit.weld-progress.tested',
      date: '',
    });
  }
  if (state >= WELD_PROGRESS_STATE.PROCESSED) {
    stateList.pushObject({
      complete: true,
      labelKey: 'components.model-project-weld/edit.weld-progress.processed',
      date: formatDate([], { date: get(weld, 'processedDate') }),
    });
  } else {
    stateList.pushObject({
      complete: false,
      labelKey: 'components.model-project-weld/edit.weld-progress.processed',
      date: '',
    });
  }
  if (state >= WELD_PROGRESS_STATE.SHIPPED) {
    stateList.pushObject({
      complete: true,
      labelKey: 'components.model-project-weld/edit.weld-progress.shipped',
      date: formatDate([], { date: get(weld, 'shippedDate') }),
    });
  } else {
    stateList.pushObject({
      complete: false,
      labelKey: 'components.model-project-weld/edit.weld-progress.shipped',
      date: '',
    });
  }
  return stateList;
}

export function calculateCurrentState(weld) {
  let statesList = createStatesList(weld);
  let lastCompletedState;
  statesList.forEach((state) => {
    if (state.complete) {
      lastCompletedState = state;
    }
  });
  return lastCompletedState;
}
