import { isEmpty } from '@ember/utils';
import Constants from 'weldnote/utils/constants';

const { PERMISSIONS } = Constants;

export function isAllowedByPlan(activity = '') {
  if (isEmpty(activity)) {
    return false;
  }
  if (activity.indexOf('pwps') > 0) {
    if (activity === PERMISSIONS.PWPS_CREATE_CERTIFICATE) {
      return false;
    }
    return true;
  } else if (activity.indexOf('certificate') > 0) {
    return true;
  }
  return false;
}
