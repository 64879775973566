import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';
import Constants from 'weldnote/utils/constants';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';

const { ARC_TRANSFER_MODES } = Constants;

export function isGlobular(transferMode: ArcTransferMode): boolean {
  return (
    !isEmpty(transferMode) && get(transferMode, 'transferMode') === ARC_TRANSFER_MODES.GLOBULAR
  );
}

export function isShortArc(transferMode: ArcTransferMode): boolean {
  return (
    !isEmpty(transferMode) && get(transferMode, 'transferMode') === ARC_TRANSFER_MODES.SHORT_ARC
  );
}

export function isPulsedSpray(transferMode: ArcTransferMode): boolean {
  return (
    !isEmpty(transferMode) && get(transferMode, 'transferMode') === ARC_TRANSFER_MODES.PULSED_SPRAY
  );
}

export function isSpray(transferMode: ArcTransferMode): boolean {
  return !isEmpty(transferMode) && get(transferMode, 'transferMode') === ARC_TRANSFER_MODES.SPRAY;
}
