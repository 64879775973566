import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    isError=this.isTaskInError\n    isFinished=this.isTaskFinished\n    isRunning=this.isTaskRunning\n    taskStep=this.taskDescription\n    currentTask=this.task\n  )\n}}", {"contents":"{{yield\n  (hash\n    isError=this.isTaskInError\n    isFinished=this.isTaskFinished\n    isRunning=this.isTaskRunning\n    taskStep=this.taskDescription\n    currentTask=this.task\n  )\n}}","moduleName":"weldnote/components/task-progress-provider.hbs","parseOptions":{"srcName":"weldnote/components/task-progress-provider.hbs"}});
import { observes } from '@ember-decorators/object';
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import classic from 'ember-classic-decorator';
import Constants from 'weldnote/utils/constants';

const { TASK_STATUS } = Constants;

@classic
export default class TaskProgressProvider extends Component {
  task = null;

  onFinish /* task */() {
    // Dummy empty action
  }

  @computed('task.state')
  get isTaskInError() {
    if (!isEmpty(this.task)) {
      return parseInt(this.task.state) === TASK_STATUS.IN_ERROR;
    }
    return false;
  }

  @notEmpty('task.taskIdentifier')
  hasTask;

  @computed('task.progressDescription')
  get taskDescription() {
    return get(this, 'task.progressDescription');
  }

  @computed('task.{state,progress}')
  get isTaskRunning() {
    let { hasTask, task } = this;
    if (hasTask) {
      let state = parseInt(task.state);
      let progress = parseInt(task.progress);
      return (state === TASK_STATUS.IN_PROGRESS || state === TASK_STATUS.INITIAL) && progress < 100;
    }
    return false;
  }

  @computed('task.{state,progress}')
  get isTaskFinished() {
    let { hasTask, task } = this;
    if (hasTask) {
      return parseInt(task.state) === TASK_STATUS.FINISHED || parseInt(task.progress) === 100;
    }
    return false;
  }

  @observes('isTaskFinished')
  taskFinishedObserver() {
    if (this.isTaskFinished) {
      this.onFinish(this.task);
    }
  }
}
