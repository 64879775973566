import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { set, getProperties, action, computed } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';
import ENV from 'weldnote/config/environment';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class NdtReportCertificatesController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'ndt-report-certificate';

  get assemblyLink() {
    return `${ENV.esabCloudLink}/assembly/non-destructive-tests`;
  }

  @computed
  get gridColumns() {
    let reportNumberLabel = this.getAttributeLabel('ndtReportNumber');
    let typeOfTestLabel = this.getAttributeLabel('typeOfTest');
    let inspectorLabel = this.getAttributeLabel('inspector');
    let dateLabel = this.getAttributeLabel('reportDate');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'ndtReportNumber',
        label: reportNumberLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/ndt-report-certificate-link',
        cellClassNames: 'data-test-report-number',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'typeOfTest.designation',
        label: typeOfTestLabel,
        sortable: false,
        cellClassNames: 'data-test-type-of-test',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'inspector.inspectorName',
        label: inspectorLabel,
        sortable: false,
        cellClassNames: 'data-test-inspector-name',
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'reportDate',
        width: '120px',
        label: dateLabel,
        sortable: false,
        cellClassNames: 'data-test-report-date',
        cellComponent: 'weldnote-grid/date-cell',
        breakpoints: ['laptop', 'desktop'],
      },
    ];
  }

  buildFilters() {
    let filters = getProperties(this, 'reportNumber');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let result = await this.data.listAllNDTReportCertificates(options, this.buildFilters());
      set(this, 'model', result);
      set(this, 'totalCount', result.meta.records);
    }
  );

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setReportNumber(reportNumber) {
    set(this, 'reportNumber', reportNumber);
    this.resetPage();
  }

  @action
  clearFields() {
    this.resetPage();
    set(this, 'reportNumber', null);
    this.loadData.perform();
  }
}
