import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.hasAll}}\n  {{#if this.bothEqual}}\n    {{format-decimal this.minimum convertToFraction=@convertToFraction}}\n    {{! The else is formatted this way to make the spaces in the beggining and ending not appear }}\n  {{else}}{{format-decimal this.minimum convertToFraction=@convertToFraction}}\n    -\n    {{format-decimal this.maximum convertToFraction=@convertToFraction}}{{/if}}{{else}}\n  {{#if this.hasMin}}\n    ≥\n    {{format-decimal this.minimum convertToFraction=@convertToFraction}}\n  {{else}}\n    {{#if this.hasMax}}\n      ≤\n      {{format-decimal this.maximum convertToFraction=@convertToFraction}}\n    {{/if}}\n  {{/if}}\n{{/if}}", {"contents":"{{#if this.hasAll}}\n  {{#if this.bothEqual}}\n    {{format-decimal this.minimum convertToFraction=@convertToFraction}}\n    {{! The else is formatted this way to make the spaces in the beggining and ending not appear }}\n  {{else}}{{format-decimal this.minimum convertToFraction=@convertToFraction}}\n    -\n    {{format-decimal this.maximum convertToFraction=@convertToFraction}}{{/if}}{{else}}\n  {{#if this.hasMin}}\n    ≥\n    {{format-decimal this.minimum convertToFraction=@convertToFraction}}\n  {{else}}\n    {{#if this.hasMax}}\n      ≤\n      {{format-decimal this.maximum convertToFraction=@convertToFraction}}\n    {{/if}}\n  {{/if}}\n{{/if}}","moduleName":"weldnote/components/model-pqr/display-decimal-range.hbs","parseOptions":{"srcName":"weldnote/components/model-pqr/display-decimal-range.hbs"}});
import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { readOnly, notEmpty, or, and } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@classNames('display-inline')
export default class DisplayDecimalRange extends Component {
  @readOnly('min')
  minimum;

  @readOnly('max')
  maximum;

  @and('hasMin', 'hasMax')
  hasAll;

  @notEmpty('min')
  hasMin;

  @notEmpty('max')
  hasMax;

  @or('hasMin', 'hasMax')
  hasAny;

  convertToFraction = false;

  @computed('min', 'max')
  get bothEqual() {
    let { min, max } = this;
    return min === max;
  }
}
