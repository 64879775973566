import { attr, belongsTo } from '@ember-data/model';
import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import BaseMaterialGrade from './base-material-grade';
import BaseMaterialGroup from './base-material-group';
import BaseMaterialSpecification from './base-material-specification';
import IndustryCode from './industry-code';

export default class BaseMaterial extends BaseModel {
  @belongsTo('industry-code', { async: false })
  declare industryCode: IndustryCode;

  @attr('string')
  declare baseMaterialLabel?: string;

  @belongsTo('base-material-specification', { async: false })
  declare specification: BaseMaterialSpecification;

  @belongsTo('base-material-grade', { async: false })
  declare grade: BaseMaterialGrade;

  @belongsTo('base-material-group', { async: false })
  declare materialGroup: BaseMaterialGroup;

  @attr('boolean')
  declare system?: boolean;

  @computed('specification', 'grade')
  get visualLabel(): string {
    let specification = this.specification ? get(this.specification, 'id') : '';
    let grade = this.grade ? get(this.grade, 'id') : '';
    let label = '';

    if (!isEmpty(specification)) {
      label += get(this.specification, 'visualLabel');
    }
    if (!isEmpty(specification) && !isEmpty(grade)) {
      label += ' ';
    }
    if (!isEmpty(grade)) {
      label += get(this.grade, 'visualLabel');
    }
    return label;
  }

  @computed('materialGroup.groupName')
  get isNA() {
    let group = get(this.materialGroup, 'groupName');
    if (group && !isEmpty(group)) {
      return 'N/A' === group.trim();
    }
    return false;
  }

  metadata = {
    modelName: 'base-material',
    industryCode: {
      required: true,
    },

    specification: {
      required: true,
    },

    materialGroup: {
      required: true,
    },

    grade: {
      required: true,
    },
  };

  validations = {
    industryCode: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    specification: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    materialGroup: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    grade: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'base-material': BaseMaterial;
  }
}
