import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"input-group\">\n  <input\n    value={{@value}}\n    {{on \"input\" (pick \"target.value\" this.onInput)}}\n    {{on \"keyup\" this.onKeyDown}}\n    class=\"form-control\"\n    placeholder={{@placeholder}}\n    type=\"text\"\n    disabled={{@disabled}}\n    id={{if @id (concat @id \"_input\")}}\n    data-id={{@data-id}}\n    ...attributes\n  />\n  {{#if this.showSearch}}\n    <span class=\"input-group-btn\">\n      <button class=\"btn btn-default\" type=\"button\" {{on \"click\" @onSearch}}><FaIcon @icon=\"search\" /></button>\n    </span>\n  {{/if}}\n</div>", {"contents":"<div class=\"input-group\">\n  <input\n    value={{@value}}\n    {{on \"input\" (pick \"target.value\" this.onInput)}}\n    {{on \"keyup\" this.onKeyDown}}\n    class=\"form-control\"\n    placeholder={{@placeholder}}\n    type=\"text\"\n    disabled={{@disabled}}\n    id={{if @id (concat @id \"_input\")}}\n    data-id={{@data-id}}\n    ...attributes\n  />\n  {{#if this.showSearch}}\n    <span class=\"input-group-btn\">\n      <button class=\"btn btn-default\" type=\"button\" {{on \"click\" @onSearch}}><FaIcon @icon=\"search\" /></button>\n    </span>\n  {{/if}}\n</div>","moduleName":"weldnote/components/input-search.hbs","parseOptions":{"srcName":"weldnote/components/input-search.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

const ENTER_KEY = 13;

export default class InputSearch extends Component {
  get showSearch() {
    return this.args.showSearch ?? true;
  }

  @action
  onInput(value) {
    if (this.args.onChange) {
      this.args.onChange(value);
    }
  }

  @action
  onKeyDown(event) {
    let { keyCode } = event;
    let {
      target: { value },
    } = event;
    if (value) {
      if (keyCode === ENTER_KEY) {
        if (this.args.onSearch) {
          this.args.onSearch(value);
        }
      }
    }
  }
}
