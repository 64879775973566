import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import { memberAction } from 'ember-api-actions';
import FileUpload from './file-upload';

export default class Drawing extends BaseModel {
  @attr('string')
  declare title?: string;

  @attr('string')
  declare referenceNumber?: string;

  @belongsTo('file-upload')
  declare drawingContent: AsyncBelongsTo<FileUpload>;

  @attr('string')
  declare drawingContentPath?: string;

  @attr('string')
  declare revision?: string;

  get visualLabel(): string {
    return this.title || '';
  }

  validations = {
    title: { presence: true },
    referenceNumber: { presence: true },
    revision: { presence: true },
  };

  metadata = {
    modelName: 'drawing',
    title: { required: true },
    referenceNumber: { required: true },
    revision: { required: true },
    revisionStatus: { required: true },
  };

  deleteDrawing = memberAction({ path: 'deleteDrawing', type: 'PUT' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    drawing: Drawing;
  }
}
