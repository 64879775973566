import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { set, get, computed } from '@ember/object';
import { task } from 'ember-concurrency';

@classic
export default class IeisArchivedController extends Controller {
  @service
  intl;

  @service
  userSession;

  @service('weldnote-data')
  data;

  queryParams = ['page'];

  @service('weldnote-data')
  data;

  @alias('model')
  ieis;

  @alias('model.meta.records')
  ieisCount;

  page = 1;

  getGridColumnLabel(label, options = {}) {
    return this.intl.t(`wps-list.grid-columns.${label}`, options);
  }

  @computed
  get gridColumns() {
    let wpsNumberLabel = this.intl.t('model.welding-procedure-specification.wpsNumber.label');
    let weldingProcessesLabel = this.getGridColumnLabel('welding-processes');
    let weldTypeLabel = this.intl.t('model.welding-procedure-specification.weldType.label');
    let baseMaterialsLabel = this.getGridColumnLabel('base-materials');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet', 'laptop'],
      },
      {
        valuePath: 'ieisNumber',
        label: this.intl.t('model.ieis.ieisNumber.label'),
        width: '200px',
        cellComponent: 'weldnote-grid/ieis-link',
        sortable: false,
      },
      {
        valuePath: 'wps',
        label: wpsNumberLabel,
        width: '200px',
        cellComponent: 'weldnote-grid/ieis-wps-link',
        sortable: false,
      },
      {
        valuePath: 'wps.weldType',
        width: '100px',
        label: weldTypeLabel,
        cellComponent: 'weldnote-grid/weld-type',
        sortable: false,
      },
      {
        valuePath: 'wps.weldingProcessRoot',
        label: weldingProcessesLabel,
        width: '120px',
        sortable: false,
        cellComponent: 'weldnote-grid/ieis-wps-process',
        breakpoints: ['tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'wps.baseMaterial1',
        label: baseMaterialsLabel,
        cellComponent: 'weldnote-grid/ieis-base-materials',
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'lineClass',
        label: this.intl.t('model.ieis.lineClass.label'),
        cellComponent: 'weldnote-grid/ieis-test-stages',
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
    ];
  }

  loadData = task(async (pageNumber = 1) => {
    let results = await this.data.listAllIeis(
      { page: pageNumber },
      {
        archived: 'AR',
      }
    );
    set(this, 'model', results);
  });
}
