import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span>\n  {{#if this.shouldShowLink}}\n    <LinkTo @route={{this.targetRoute}} @model={{this.instanceId}} class=\"font-bold\">\n      {{this.messageText}}\n    </LinkTo>\n  {{else}}\n    {{this.messageText}}\n    <FaIcon @icon=\"ban\" title={{t \"components.action-log/display.missing-item\"}} />\n  {{/if}}\n</span>", {"contents":"<span>\n  {{#if this.shouldShowLink}}\n    <LinkTo @route={{this.targetRoute}} @model={{this.instanceId}} class=\"font-bold\">\n      {{this.messageText}}\n    </LinkTo>\n  {{else}}\n    {{this.messageText}}\n    <FaIcon @icon=\"ban\" title={{t \"components.action-log/display.missing-item\"}} />\n  {{/if}}\n</span>","moduleName":"weldnote/components/action-log/display.hbs","parseOptions":{"srcName":"weldnote/components/action-log/display.hbs"}});
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';

export default class Display extends Component {
  @service
  intl;

  get instanceId() {
    return this.args.log.instanceId;
  }

  get actionId() {
    return this.args.log.actionId;
  }

  get logExists() {
    return this.args.log.exists ?? false;
  }

  get targetRoute() {
    let type = this.args.log.instanceType;
    if (type === 'pqr') {
      return 'pqr-view';
    } else if (type === 'wps') {
      return 'welding-procedure-specification-view';
    } else if (type === 'certificate') {
      return 'welder-certificate';
    } else if (type === 'welder') {
      return 'welder';
    } else if (type === 'welding-map') {
      return 'welding-map';
    } else if (type === 'welding-book') {
      return 'welding-book';
    } else if (type === 'miscellaneous-certificate') {
      return 'miscellaneous-certificate';
    } else if (type === 'project-weld') {
      return 'project-weld';
    } else if (type === 'project-component') {
      return 'project-component';
    } else if (type === 'project') {
      return 'project';
    } else if (type === 'ndt-certificate') {
      return 'ndt-report-certificate';
    } else {
      return undefined;
    }
  }

  get messageText() {
    if (!isEmpty(this.args.message)) {
      return this.args.message;
    }
    let messageId = `components.action-log/display.${this.actionId}`;
    return this.intl.t(messageId);
  }

  get shouldShowLink() {
    if (!this.logExists || !this.targetRoute) {
      return false;
    }
    return true;
  }
}
