import { helper } from '@ember/component/helper';

export function csvModels(params, namedArgs) {
  let { models } = namedArgs;
  if (models && models.get('length') > 0) {
    let attributeName = 'visualLabel';
    if (namedArgs.attribute) {
      let { attribute } = namedArgs;
      attributeName = attribute;
    }
    let content = models
      .filter((item) => {
        return item.get(attributeName) !== undefined;
      })
      .map((item) => {
        return item.get(attributeName);
      });
    return content.join(', ');
  } else if (models) {
    let modelLabel = '';
    if (namedArgs.attribute) {
      modelLabel = models.get(namedArgs.attribute);
      if (modelLabel) {
        return modelLabel;
      }
    }
    modelLabel = models.get('visualLabel');
    if (modelLabel) {
      return modelLabel;
    }
  }
  return '';
}

export default helper(csvModels);
