import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Rules from 'weldnote/utils/welding-procedure-specification-rules';
import Constants from 'weldnote/utils/constants';
import { rangeSerialization } from 'weldnote/utils/range-utils';

import { serializeCollection } from 'weldnote/utils/collection-utils';

import { calculateShielding } from 'weldnote/utils/certification-rules';

import { isFCAW, isSMAW, isSAW, isGMAW, isGTAW, isESW } from 'weldnote/utils/rules/welding-process';

import {
  isSpecification51or55,
  isSpecification51or55withFillerMaterial,
  isSpecification517or523,
  isSpecification517or523withFiller,
  isSpecification518or528or536,
  isSpecification518or528or536withFiller,
  isSpecification520or529or536,
  isSpecification520or529or536withFiller,
  isSameSpecification,
} from 'weldnote/utils/validation/filler-material';

import {
  optionsOnlyIncludePositions,
  serializePositions,
} from 'weldnote/utils/rules/welding-position';

import {
  fromMilimeterSecondToMilimeterMinute,
  fromMilimeterSecondToMeterMinute,
} from 'weldnote/utils/unit-system/speed';

import { isFilletWeld, isOverlayWeld } from 'weldnote/utils/rules/weld-type';
import WPSModel from 'weldnote/models/welding-procedure-specification';
import IntlService from 'ember-intl/services/intl';
import WpsValidationType from 'weldnote/src-weldnote/types/wps/wps-validation';
import Enumerable from '@ember/array/-private/enumerable';
import BaseModel from 'weldnote/models/base-model';
import WpsWeldingParameter from 'weldnote/models/wps-welding-parameter';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import WeldingProcess from 'weldnote/models/welding-process';
import { NumericalRange } from 'weldnote/src-weldnote/wopq/approval-ranges/wopq-range-interface';
import WeldingPosition from 'weldnote/models/welding-position';

const { WELDING_PROCEDURE_SPECIFICATION } = Rules;

const { UNIT_SYSTEM, PRODUCT_TYPE, WELDING_POSITIONS, WELDING_LAYER_OPTIONS, SHIELDING_OPTIONS } =
  Constants;

export default class WpsValidation implements WpsValidationType {
  private wps: WPSModel;

  private intl: IntlService;

  private validations: any;

  private units: any;

  constructor(validations: any, wps: WPSModel, intl: IntlService, units: any) {
    this.validations = validations;
    this.wps = wps;
    this.intl = intl;
    this.units = units;
  }

  // Thickness
  get isThicknessTotalMinimumValid() {
    let isWPSFilletWeld = get(this.validations, 'meta.isFilletWeld');
    let isPqrButtWeld = get(this.validations, 'meta.isSomeTypeOfButtWeld');
    if (isWPSFilletWeld && isPqrButtWeld) {
      let value = get(this.wps, 'thicknessMinimum');
      return this._rangeValidation('throatThickness', value);
    } else {
      let value = get(this.wps, 'thicknessMinimum');
      return this._rangeValidation('thicknessTotal', value);
    }
  }

  get thicknessTotalMinimumInvalidMessage() {
    return this._thicknessTotalInvalidMessage('thicknessTotal', 'thicknessMinimum');
  }

  get isThicknessTotalMaximumValid() {
    let isWPSFilletWeld = get(this.validations, 'meta.isFilletWeld');
    let isPqrButtWeld = get(this.validations, 'meta.isSomeTypeOfButtWeld');
    if (isWPSFilletWeld && isPqrButtWeld) {
      let value = get(this.wps, 'thicknessMaximum');
      return this._rangeValidation('throatThickness', value);
    } else {
      let value = get(this.wps, 'thicknessMaximum');
      return this._rangeValidation('thicknessTotal', value);
    }
  }

  get thicknessTotalMaximumInvalidMessage() {
    return this._thicknessTotalInvalidMessage('thicknessTotal', 'thicknessMaximum');
  }

  get isThicknessRootMinimumValid() {
    let value = get(this.wps, 'thicknessRootMinimum');
    return this._rangeValidation('thicknessRoot', value);
  }

  get thicknessRootMinimumInvalidMessage() {
    let value = get(this.wps, 'thicknessRootMinimum');
    return this._rangeMessageValidator(
      get(this.validations, 'thicknessRoot'),
      value,
      this.units.distanceUnit
    );
  }

  get isThicknessRootMaximumValid() {
    let value = get(this.wps, 'thicknessRootMaximum');
    return this._rangeValidation('thicknessRoot', value);
  }

  get thicknessRootMaximumInvalidMessage() {
    let value = get(this.wps, 'thicknessRootMaximum');
    return this._rangeMessageValidator(
      get(this.validations, 'thicknessRoot'),
      value,
      this.units.distanceUnit
    );
  }

  get isThicknessFillMinimumValid() {
    let value = get(this.wps, 'thicknessFillMinimum');
    return this._rangeValidation('thicknessFill', value);
  }

  get thicknessFillMinimumInvalidMessage() {
    let value = get(this.wps, 'thicknessFillMinimum');
    return this._rangeMessageValidator(
      get(this.validations, 'thicknessFill'),
      value,
      this.units.distanceUnit
    );
  }

  get isThicknessFillMaximumValid() {
    let value = get(this.wps, 'thicknessFillMaximum');
    return this._rangeValidation('thicknessFill', value);
  }

  get thicknessFillMaximumInvalidMessage() {
    let value = get(this.wps, 'thicknessFillMaximum');
    return this._rangeMessageValidator(
      get(this.validations, 'thicknessFill'),
      value,
      this.units.distanceUnit
    );
  }

  get isThicknessCapMinimumValid() {
    let value = get(this.wps, 'thicknessCapMinimum');
    return this._rangeValidation('thicknessCap', value);
  }

  get thicknessCapMinimumInvalidMessage() {
    let value = get(this.wps, 'thicknessCapMinimum');
    return this._rangeMessageValidator(
      get(this.validations, 'thicknessCap'),
      value,
      this.units.distanceUnit
    );
  }

  get isThicknessCapMaximumValid() {
    let value = get(this.wps, 'thicknessCapMaximum');
    return this._rangeValidation('thicknessCap', value);
  }

  get thicknessCapMaximumInvalidMessage() {
    let value = get(this.wps, 'thicknessCapMaximum');
    return this._rangeMessageValidator(
      get(this.validations, 'thicknessCap'),
      value,
      this.units.distanceUnit
    );
  }

  // Diameter
  isDiameterValid(value: string) {
    return this._rangeValidation('diameter', value);
  }

  isDiameterValidForPipe(value: string) {
    if (!this._shouldValidateDiameter(value)) {
      return true;
    }
    if (this._isPQRPlateAndWPSPipe()) {
      let diameterMin = parseFloat(`${this.wps.diameterMinimum}`);
      if (this._isISO()) {
        if (!isNaN(diameterMin)) {
          if (diameterMin >= 500) {
            return true;
          } else {
            if (diameterMin >= 150) {
              return true;
            } else {
              return false;
            }
          }
        }
      } else if (this._isAWS()) {
        if (!isNaN(diameterMin)) {
          return diameterMin >= this.maxDistanceForAWSPipe;
        }
      }
    }
    return true;
  }

  get maxDistanceForAWSPipe() {
    let unitSystem = get(this.units, 'unitSystem');
    if (unitSystem === UNIT_SYSTEM.METRIC) {
      return 600;
    } else if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
      return 24;
    }
    return 600;
  }

  diameterInvalidMessage(value: string) {
    return this._rangeMessageValidator(
      get(this.validations, 'diameter'),
      value,
      this.units.distanceUnit
    );
  }

  get diameterForPipeInvalidMessage() {
    if (this._isISO()) {
      return this.intl.t('model-validations.wps.diameter-product-type');
    } else if (this._isAWS()) {
      return this.intl
        .t('model-validations.wps.diameter-product-type-aws', {
          unit: get(this.units, 'distanceUnit'),
          max: this.maxDistanceForAWSPipe,
        })
        .toString();
    }
    return '';
  }

  // Number of Electrodes
  get isNumberElectrodesRootValid() {
    if (!isEmpty(get(this.validations, 'numberOfElectrodesRoot'))) {
      return this._singleValueValidation(
        'numberOfElectrodesRoot',
        get(this.wps, 'numberOfElectrodes')
      );
    }
    return true;
  }

  get numberOfElectrodesRootInvalidMessage() {
    return this._singleValueMessage(get(this.validations, 'numberOfElectrodesRoot'));
  }

  get isNumberElectrodesFillValid() {
    if (!isEmpty(get(this.validations, 'numberOfElectrodesFill'))) {
      return this._singleValueValidation('numberOfElectrodesFill', this.wps.numberOfElectrodes);
    }
    return true;
  }

  get numberOfElectrodesFillInvalidMessage() {
    return this._singleValueMessage(get(this.validations, 'numberOfElectrodesFill'));
  }

  get isNumberElectrodesCapValid() {
    if (!isEmpty(get(this.validations, 'numberOfElectrodesCap'))) {
      return this._singleValueValidation('numberOfElectrodesCap', this.wps.numberOfElectrodesFill);
    }
    return true;
  }

  get numberOfElectrodesCapInvalidMessage() {
    return this._singleValueMessage(get(this.validations, 'numberOfElectrodesCap'));
  }

  // Gas Nozzle Diameter
  get isGasNozzleDiameterRootValid() {
    if (!isEmpty(get(this.validations, 'gasNozzleDiameterRoot'))) {
      return this._singleValueValidation('gasNozzleDiameterRoot', this.wps.gasNozzleDiameterRoot);
    }
    return true;
  }

  get gasNozzleDiameterRootInvalidMessage() {
    return this._singleValueMessage(get(this.validations, 'gasNozzleDiameterRoot'));
  }

  get gasNozzleDiameterFillInvalidMessage() {
    return this._singleValueMessage(get(this.validations, 'gasNozzleDiameterFill'));
  }

  get isGasNozzleDiameterFillValid() {
    if (!isEmpty(get(this.validations, 'gasNozzleDiameterFill'))) {
      return this._singleValueValidation('gasNozzleDiameterFill', this.wps.gasNozzleDiameterFill);
    }
    return true;
  }

  get gasNozzleDiameterCapInvalidMessage() {
    return this._singleValueMessage(get(this.validations, 'gasNozzleDiameterCap'));
  }

  get isGasNozzleDiameterCapValid() {
    if (!isEmpty(get(this.validations, 'gasNozzleDiameterCap'))) {
      return this._singleValueValidation('gasNozzleDiameterCap', this.wps.gasNozzleDiameterCap);
    }
    return true;
  }

  // PWHT Time
  isPwhtTimeValid(value: any) {
    return this._rangeValidation('pwhtTime', value);
  }

  pwthTimeInvalidMessage(value: any) {
    return this._pwhtRangeMessage(get(this.validations, 'pwhtTime'), value, 'h');
  }

  // PWHT Temperature
  isPwhtTemperatureValid(value: any) {
    return this._rangeValidation('pwhtTemperature', value);
  }

  pwthTemperatureInvalidMessage(value: any) {
    return this._pwhtRangeMessage(
      get(this.validations, 'pwhtTemperature'),
      value,
      this.units.temperatureUnit
    );
  }

  isPWHTMinimumPresenceValid(value: any) {
    return this._presenceMinimumValidation('meta.pwhtTemperatureMinimumPresence', value);
  }

  pWHTMinimumPresenceInvalidMessage(value: any) {
    return this._presenceMinimumValidationMessage(
      'meta.pwhtTemperatureMinimumPresence',
      value,
      'pwhtTemperature',
      this.units.temperatureUnit
    );
  }

  isPWHTMaximumPresenceValid(value: any) {
    return this._presenceMaximumValidation('meta.pwhtTemperatureMaximumPresence', value);
  }

  pWHTMaximumPresenceInvalidMessage(value: any) {
    return this._presenceMaximumValidationMessage(
      'meta.pwhtTemperatureMaximumPresence',
      value,
      'pwhtTemperature',
      this.units.temperatureUnit
    );
  }

  isPWHTTimeMinimumPresenceValid(value: any) {
    return this._presenceMinimumValidation('meta.pwhtTimeMinimumPresence', value);
  }

  pWHTTimeMinimumPresenceInvalidMessage(value: any) {
    return this._presenceMinimumValidationMessage(
      'meta.pwhtTimeMinimumPresence',
      value,
      'pwhtTime',
      'h'
    );
  }

  isPWHTTimeMaximumPresenceValid(value: any) {
    return this._presenceMaximumValidation('meta.pwhtTimeMaximumPresence', value);
  }

  pWHTTimeMaximumPresenceInvalidMessage(value: any) {
    return this._presenceMaximumValidationMessage(
      'meta.pwhtTimeMaximumPresence',
      value,
      'pwhtTime',
      'h'
    );
  }

  isInterpassTemperatureMinimumPresenceValid(value: any) {
    let correctValue = get(this.validations, 'meta.interpassMinimumPresence');
    if (correctValue === 'MUST_HAVE') {
      return !isEmpty(value);
    }
    return true;
  }

  isInterpassTemperatureMaximumPresenceValid(value: any) {
    let correctValue = get(this.validations, 'meta.interpassMaximumPresence');
    if (correctValue === 'MUST_HAVE') {
      return !isEmpty(value);
    }
    return true;
  }

  interpassTemperatureMinimumPresenceInvalidMessage(value: any): string {
    let correctValue = get(this.validations, 'meta.interpassMinimumPresence');
    if (correctValue === 'MUST_HAVE' && isEmpty(value)) {
      let approvalRange = this._getApprovalRange('interpass');
      return this.intl
        .t('model-validations.wps.pqr-with-value-but-wps-empty-pwht', {
          approvalRange,
          unit: this.units.temperatureUnit,
        })
        .toString();
    }
    return '';
  }

  interpassTemperatureMaximumPresenceInvalidMessage(value: any): string {
    let correctValue = get(this.validations, 'meta.interpassMaximumPresence');
    if (correctValue === 'MUST_HAVE' && isEmpty(value)) {
      let approvalRange = this._getApprovalRange('interpass');
      return this.intl
        .t('model-validations.wps.pqr-with-value-but-wps-empty-pwht', {
          approvalRange,
          unit: this.units.temperatureUnit,
        })
        .toString();
    }
    return '';
  }

  // Branch Angle
  isBranchAngleValid(value: any) {
    if (!isEmpty(get(this.validations, 'branchAngle.max'))) {
      return this._rangeValidation('branchAngle', value);
    }
    return true;
  }

  branchAngleInvalidMessage(value: any) {
    return this._rangeMessageValidator(get(this.validations, 'branchAngle'), value, 'º');
  }

  // PreHeat
  isPreheatTemperatureValid(value: any) {
    return this._rangeValidation('preheat', value);
  }

  preheatTemperatureInvalidMessage(value: any) {
    return this._rangeMessageValidator(
      get(this.validations, 'preheat'),
      value,
      this.units.temperatureUnit
    );
  }

  isPreheatMinimumPresenceValid(value: any) {
    return this._presenceMinimumValidation('meta.preHeatMinimumPresence', value);
  }

  isPreheatMaximumPresenceValid(value: any) {
    return this._presenceMaximumValidation('meta.preHeatMaximumPresence', value);
  }

  preheatMinimumPresenceInvalidMessage(value: any) {
    return this._presenceMinimumValidationMessage(
      'meta.preHeatMinimumPresence',
      value,
      'preheat',
      this.units.temperatureUnit
    );
  }

  preheatMaximumPresenceInvalidMessage(value: any) {
    return this._presenceMinimumValidationMessage(
      'meta.preHeatMaximumPresence',
      value,
      'preheat',
      this.units.temperatureUnit
    );
  }

  // Interpass
  isInterpassTemperatureValid(value: any) {
    return this._rangeValidation('interpass', value);
  }

  interpassTemperatureInvalidMessage(value: any) {
    return this._rangeMessageValidator(
      get(this.validations, 'interpass'),
      value,
      this.units.temperatureUnit
    );
  }

  // Post Heat Temperature
  isPostHeatTemperatureValid(value: any) {
    return this._rangeValidation('postheat', value);
  }

  postHeatTemperatureInvalidMessage(value: any) {
    return this._rangeMessageValidator(
      get(this.validations, 'postheat'),
      value,
      this.units.temperatureUnit
    );
  }

  isPostHeatTimeValid(value: any) {
    return this._rangeValidation('postheatTime', value);
  }

  postHeatTimeInvalidMessage(value: any) {
    return this._rangeMessageValidator(get(this.validations, 'postheatTime'), value, 'h');
  }

  isPostHeatMinimumPresenceValid(value: any) {
    return this._presenceMinimumValidation('meta.postHeatMinimumPresence', value);
  }

  postHeatMinimumPresenceInvalidMessage(value: any) {
    return this._presenceMinimumValidationMessage(
      'meta.postHeatMinimumPresence',
      value,
      'postheat',
      this.units.temperatureUnit
    );
  }

  isPostHeatMaximumPresenceValid(value: any) {
    return this._presenceMaximumValidation('meta.postHeatMaximumPresence', value);
  }

  postHeatMaximumPresenceInvalidMessage(value: any) {
    return this._presenceMaximumValidationMessage(
      'meta.postHeatMaximumPresence',
      value,
      'postheat',
      this.units.temperatureUnit
    );
  }

  isPostHeatTimeMinimumPresenceValid(value: any) {
    return this._presenceMinimumValidation('meta.postHeatTimeMinimumPresence', value);
  }

  postHeatTimeMinimumPresenceInvalidMessage(value: any) {
    return this._presenceMinimumValidationMessage(
      'meta.postHeatTimeMinimumPresence',
      value,
      'postheatTime',
      'h'
    );
  }

  isPostHeatTimeMaximumPresenceValid(value: any) {
    return this._presenceMaximumValidation('meta.postHeatTimeMaximumPresence', value);
  }

  postHeatTimeMaximumPresenceInvalidMessage(value: any) {
    return this._presenceMaximumValidationMessage(
      'meta.postHeatTimeMaximumPresence',
      value,
      'postheatTime',
      'h'
    );
  }

  // Welding Processes
  get isWeldingProcessRootValid() {
    return this._isValidInstance('weldingProcessRoot', 'weldingProcessRoot');
  }

  get weldingProcessRootInvalidMessage() {
    return this._invalidMessage('weldingProcessRoot', 'shortDesignation', 'weldingProcessRoot');
  }

  get isWeldingProcessFillValid() {
    return this._isValidInstance('weldingProcessFill', 'weldingProcessFill');
  }

  get weldingProcessFillInvalidMessage() {
    return this._invalidMessage('weldingProcessFill', 'shortDesignation', 'weldingProcessFill');
  }

  get isWeldingProcessCapValid() {
    return this._isValidInstance('weldingProcessCap', 'weldingProcessCap');
  }

  get weldingProcessCapInvalidMessage() {
    return this._invalidMessage('weldingProcessCap', 'shortDesignation', 'weldingProcessCap');
  }

  // Process Mecanization
  get isProcessMecanizationRootValid() {
    return this._isValidInstance('processMecanizationRoot', 'processMecanizationRoot');
  }

  get processMecanizationRootInvalidMessage() {
    return this._invalidMessage('processMecanizationRoot', 'name', 'processMecanizationRoot');
  }

  get isProcessMecanizationFillValid() {
    return this._isValidInstance('processMecanizationFill', 'processMecanizationFill');
  }

  get processMecanizationFillInvalidMessage() {
    return this._invalidMessage('processMecanizationFill', 'name', 'processMecanizationFill');
  }

  get isProcessMecanizationCapValid() {
    return this._isValidInstance('processMecanizationCap', 'processMecanizationCap');
  }

  get processMecanizationCapInvalidMessage() {
    return this._invalidMessage('processMecanizationCap', 'name', 'processMecanizationCap');
  }

  // Welding Details
  get isWeldingDetailsValid() {
    let details = get(this.wps, 'weldingDetails');
    let approvalRange = get(this.validations, 'weldingDetails');
    return this._collectionInCollectionById(approvalRange, details);
  }

  get weldingDetailsInvalidMessage() {
    let values = get(this.wps, 'weldingDetails');
    let approvalRange = get(this.validations, 'weldingDetails');
    let notQualified = this._notInCollection(approvalRange, values);
    let notQualifiedSerialized = serializeCollection(notQualified, 'completeDesignation', '; ');
    let serialized = this._collectionApprovalRange('weldingDetails');
    let message = this.intl.t('model-validations.wps.pqr-validation', {
      value: notQualifiedSerialized,
      approvalRange: serialized,
    });
    return message.toString();
  }

  // Type Current
  get isTypeCurrentRootValid() {
    return this._isValidInstance('typeCurrentRoot', 'typeCurrentRoot');
  }

  get typeCurrentRootInvalidMessage() {
    return this._invalidMessage('typeCurrentRoot', 'typeCurrent', 'typeCurrentRoot');
  }

  get isTypeCurrentFillValid() {
    return this._isValidInstance('typeCurrentFill', 'typeCurrentFill');
  }

  get typeCurrentFillInvalidMessage() {
    return this._invalidMessage('typeCurrentFill', 'typeCurrent', 'typeCurrentFill');
  }

  get isTypeCurrentCapValid() {
    return this._isValidInstance('typeCurrentCap', 'typeCurrentCap');
  }

  get typeCurrentCapInvalidMessage() {
    return this._invalidMessage('typeCurrentCap', 'typeCurrent', 'typeCurrent');
  }

  // Arc Transfer Mode
  get isArcTransferModeRootValid() {
    return this._isValidInstance('arcTransferModeRoot', 'arcTransferModeRoot');
  }

  get arcTransferModeRootInvalidMessage() {
    return this._invalidMessage('arcTransferModeRoot', 'transferMode', 'arcTransferModeRoot');
  }

  get isArcTransferModeFillValid() {
    return this._isValidInstance('arcTransferModeFill', 'arcTransferModeFill');
  }

  get arcTransferModeFillInvalidMessage() {
    return this._invalidMessage('arcTransferModeFill', 'transferMode', 'arcTransferModeFill');
  }

  get isArcTransferModeCapValid() {
    return this._isValidInstance('arcTransferModeCap', 'arcTransferModeCap');
  }

  get arcTransferModeCapInvalidMessage() {
    return this._invalidMessage('arcTransferModeCap', 'transferMode', 'arcTransferModeCap');
  }

  // Weld Type
  get isWeldTypeValid() {
    return this._isValidInstance('weldType', 'weldType');
  }

  get weldTypeInvalidMessage() {
    return this._invalidMessage('weldType', 'weldType', 'weldType');
  }

  // Base Materials
  get isBaseMaterial1Valid() {
    if (!isEmpty(get(this.validations, 'baseMaterial1Instance.value'))) {
      return this._isSameInstance('baseMaterial1Instance', 'baseMaterial1');
    } else {
      return this._isValidInstance('baseMaterial1', 'baseMaterial1', 'materialGroup.id');
    }
  }

  get baseMaterial1InvalidMessage() {
    if (!isEmpty(get(this.validations, 'baseMaterial1Instance.value'))) {
      let baseMaterial1 = get(this.wps, 'baseMaterial1');
      let materialLabel = get(baseMaterial1, 'baseMaterialLabel');
      let serialized = get(this.validations, 'baseMaterial1Instance.label');
      let message = this.intl.t('model-validations.wps.base-material-instance-validation', {
        value: materialLabel,
        approvalRange: serialized,
      });
      return message.toString();
    } else {
      let baseMaterial1 = get(this.wps, 'baseMaterial1');
      let baseMaterialGroup = get(baseMaterial1, 'materialGroup');
      let groupName = get(baseMaterialGroup, 'groupName');
      let serialized = this._collectionApprovalRange('baseMaterial1');
      let message = this.intl.t('model-validations.wps.base-material-validation', {
        value: groupName,
        approvalRange: serialized,
      });
      return message.toString();
    }
  }

  get isBaseMaterial2Valid() {
    if (!isEmpty(get(this.validations, 'baseMaterial2Instance.value'))) {
      return this._isSameInstance('baseMaterial2Instance', 'baseMaterial2');
    } else {
      return this._isValidInstance('baseMaterial2', 'baseMaterial2', 'materialGroup.id');
    }
  }

  get baseMaterial2InvalidMessage() {
    if (!isEmpty(get(this.validations, 'baseMaterial2Instance.value'))) {
      let baseMaterial2 = get(this.wps, 'baseMaterial2');
      let materialLabel = get(baseMaterial2, 'baseMaterialLabel');
      let serialized = get(this.validations, 'baseMaterial2Instance.label');
      let message = this.intl.t('model-validations.wps.base-material-instance-validation', {
        value: materialLabel,
        approvalRange: serialized,
      });
      return message.toString();
    } else {
      let baseMaterial2 = get(this.wps, 'baseMaterial2');
      let baseMaterial2Group = get(baseMaterial2, 'materialGroup');
      let groupName = get(baseMaterial2Group, 'groupName');
      let serialized = this._collectionApprovalRange('baseMaterial2');
      let message = this.intl.t('model-validations.wps.base-material-validation', {
        value: groupName,
        approvalRange: serialized,
      });
      return message.toString();
    }
  }

  isBaseMaterial1ThicknessValid(value: any) {
    // Only validate the thickness if we have a base material
    if (this._instanceHasValue('baseMaterial1')) {
      // Check if the BaseMaterial1 and BaseMaterial2 are valid in the correct order
      if (this._isValidInstance('baseMaterial1', 'baseMaterial1', 'materialGroup.id')) {
        return this._rangeValidation('baseMaterial1Thickness', value);
      }
      return true;
    }
    return true;
  }

  isBaseMaterial2ThicknessValid(value: any) {
    if (this._instanceHasValue('baseMaterial2')) {
      // Check if the BaseMaterial2 and BaseMaterial1 are valid in the correct order
      if (this._isValidInstance('baseMaterial2', 'baseMaterial2', 'materialGroup.id')) {
        return this._rangeValidation('baseMaterial2Thickness', value);
      }
      return true;
    }
    return true;
  }

  baseMaterial1ThicknessInvalidMessage(value: any) {
    if (this._isValidInstance('baseMaterial1', 'baseMaterial1', 'materialGroup.id')) {
      return this._rangeMessageValidator(
        get(this.validations, 'baseMaterial1Thickness'),
        value,
        this.units.distanceUnit
      );
    }
    return '';
  }

  baseMaterial2ThicknessInvalidMessage(value: any) {
    if (this._isValidInstance('baseMaterial2', 'baseMaterial2', 'materialGroup.id')) {
      return this._rangeMessageValidator(
        get(this.validations, 'baseMaterial2Thickness'),
        value,
        this.units.distanceUnit
      );
    }
    return '';
  }

  // Welding Positions
  get isWeldingPositionRootDirectionValid() {
    return this._isWeldingPositionDirectionValid(
      'weldingPositionRootDirection',
      'weldingPositionRootDirection'
    );
  }

  get weldingPositionRootDirectionInvalidMessage() {
    return this._weldingPositionDirectionInvalidMessage('weldingPositionRootDirection');
  }

  get isWeldingPositionFillDirectionValid() {
    return this._isWeldingPositionDirectionValid(
      'weldingPositionFillDirection',
      'weldingPositionFillDirection'
    );
  }

  get weldingPositionFillDirectionInvalidMessage() {
    return this._weldingPositionDirectionInvalidMessage('weldingPositionFillDirection');
  }

  get isWeldingPositionCapDirectionValid() {
    return this._isWeldingPositionDirectionValid(
      'weldingPositionCapDirection',
      'weldingPositionCapDirection'
    );
  }

  get weldingPositionCapDirectionInvalidMessage() {
    return this._weldingPositionDirectionInvalidMessage('weldingPositionCapDirection');
  }

  // Shielding
  get isShieldingRootValid() {
    return this._isSameLovValue('shieldingRoot', 'shieldingRoot');
  }

  get shieldingRootInvalidMessage() {
    return this._lovInvalidMessage('shieldingRoot', 'shielding');
  }

  get isShieldingFillValid() {
    return this._isSameLovValue('shieldingFill', 'shieldingFill');
  }

  get shieldingFillInvalidMessage() {
    return this._lovInvalidMessage('shieldingFill', 'shielding');
  }

  get isShieldingCapValid() {
    return this._isSameLovValue('shieldingCap', 'shieldingCap');
  }

  get shieldingCapInvalidMessage() {
    return this._lovInvalidMessage('shieldingCap', 'shielding');
  }

  get isShieldingRootValidForProcess() {
    return this._isShieldingValidForProcess('meta.weldingProcessRoot.label', 'shieldingRoot');
  }

  get shieldingRootInvalidMessageForProcess() {
    return this._shieldingInvalidMessageForProcess('meta.weldingProcessRoot.label', 'ROOT');
  }

  get isShieldingFillValidForProcess() {
    return this._isShieldingValidForProcess('meta.weldingProcessFill.label', 'shieldingFill');
  }

  get shieldingFillInvalidMessageForProcess() {
    return this._shieldingInvalidMessageForProcess('meta.weldingProcessFill.label', 'FILL');
  }

  get isShieldingCapValidForProcess() {
    return this._isShieldingValidForProcess('meta.weldingProcessCap.label', 'shieldingCap');
  }

  get shieldingCapInvalidMessageForProcess() {
    return this._shieldingInvalidMessageForProcess('meta.weldingProcessCap.label', 'CAP');
  }

  // Shielding Gas

  get isShieldingRootGasValid() {
    return this._isValidInstance('shieldingRootGas', 'shieldingRootGas');
  }

  get shieldingRootGasInvalidMessage() {
    return this._invalidMessage('shieldingRootGas', 'symbol', 'shieldingRootGas');
  }

  get isShieldingFillGasValid() {
    return this._isValidInstance('shieldingFillGas', 'shieldingFillGas');
  }

  get shieldingFillGasInvalidMessage() {
    return this._invalidMessage('shieldingFillGas', 'symbol', 'shieldingFillGas');
  }

  get isShieldingCapGasValid() {
    return this._isValidInstance('shieldingCapGas', 'shieldingCapGas');
  }

  get shieldingCapGasInvalidMessage() {
    return this._invalidMessage('shieldingCapGas', 'symbol', 'shieldingCapGas');
  }

  // Shielding Flux
  get isShieldingRootFluxValid() {
    return this._isSameInstance('shieldingRootFlux', 'shieldingRootFlux');
  }

  get shieldingRootFluxInvalidMessage() {
    return this._isSameInstanceInvalidMessage('shieldingRootFlux');
  }

  get isShieldingFillFluxValid() {
    return this._isSameInstance('shieldingFillFlux', 'shieldingFillFlux');
  }

  get shieldingFillFluxInvalidMessage() {
    return this._isSameInstanceInvalidMessage('shieldingFillFlux');
  }

  get isShieldingCapFluxValid() {
    return this._isSameInstance('shieldingCapFlux', 'shieldingCapFlux');
  }

  get shieldingCapFluxInvalidMessage() {
    return this._isSameInstanceInvalidMessage('shieldingCapFlux');
  }

  get isShieldingRootFluxCommercialDesignationValid() {
    return this._isValidInstance(
      'shieldingRootFluxCommercialDesignation',
      'shieldingRootFluxCommercialDesignation'
    );
  }

  get shieldingRootFluxCommercialDesignationInvalidMessage() {
    return this._invalidMessage(
      'shieldingRootFluxCommercialDesignation',
      'commercialDesignation',
      'shieldingRootFluxCommercialDesignation'
    );
  }

  get isShieldingFillFluxCommercialDesignationValid() {
    return this._isValidInstance(
      'shieldingFillFluxCommercialDesignation',
      'shieldingFillFluxCommercialDesignation'
    );
  }

  get shieldingFillFluxCommercialDesignationInvalidMessage() {
    return this._invalidMessage(
      'shieldingFillFluxCommercialDesignation',
      'commercialDesignation',
      'shieldingFillFluxCommercialDesignation'
    );
  }

  get isShieldingCapFluxCommercialDesignationValid() {
    return this._isValidInstance(
      'shieldingCapFluxCommercialDesignation',
      'shieldingCapFluxCommercialDesignation'
    );
  }

  get shieldingCapFluxCommercialDesignationInvalidMessage() {
    return this._invalidMessage(
      'shieldingCapFluxCommercialDesignation',
      'commercialDesignation',
      'shieldingCapFluxCommercialDesignation'
    );
  }

  // Backing
  get isBackingValid() {
    return this._isSameLovValue('backing', 'backing');
  }

  get backingInvalidMessage() {
    return this._lovInvalidMessage('backing', 'shielding');
  }

  get isBackingGasValid() {
    return this._isValidInstance('backingGas', 'backingGas');
  }

  get backingGasInvalidMessage() {
    return this._invalidMessage('backingGas', 'symbol', 'backingGas');
  }

  get isBackingFluxValid() {
    return this._isSameInstance('backingFlux', 'backingFlux');
  }

  get backingFluxInvalidMessage() {
    return this._isSameInstanceInvalidMessage('backingFlux');
  }

  get isBackingFluxCommercialDesignationValid() {
    return this._isValidInstance(
      'backingFluxCommercialDesignation',
      'backingFluxCommercialDesignation'
    );
  }

  get backingFluxCommercialDesignationInvalidMessage() {
    return this._invalidMessage(
      'backingFluxCommercialDesignation',
      'commercialDesignation',
      'backingFluxCommercialDesignation'
    );
  }

  // Filler Material
  get isFillerMaterialRootValid() {
    return this._isFillerMaterialValid(
      'fillerMaterialRoot',
      'fillerMaterialRoot',
      'pqrRootFillerMaterialAsmeMatchMaterial'
    );
  }

  get fillerMaterialRootInvalidMessage() {
    return this._fillerMaterialInvalidMessage(
      'fillerMaterialRoot',
      'fillerMaterialRoot',
      'pqrRootFillerMaterialAsmeMatchMaterial'
    );
  }

  get isFillerMaterialFillValid() {
    return this._isFillerMaterialValid(
      'fillerMaterialFill',
      'fillerMaterialFill',
      'pqrFillFillerMaterialAsmeMatchMaterial'
    );
  }

  get fillerMaterialFillInvalidMessage() {
    return this._fillerMaterialInvalidMessage(
      'fillerMaterialFill',
      'fillerMaterialFill',
      'pqrFillFillerMaterialAsmeMatchMaterial'
    );
  }

  get isFillerMaterialCapValid() {
    return this._isFillerMaterialValid(
      'fillerMaterialCap',
      'fillerMaterialCap',
      'pqrCapFillerMaterialAsmeMatchMaterial'
    );
  }

  get fillerMaterialCapInvalidMessage() {
    return this._fillerMaterialInvalidMessage(
      'fillerMaterialCap',
      'fillerMaterialCap',
      'pqrCapFillerMaterialAsmeMatchMaterial'
    );
  }

  get isFillerMaterialRootAWSValid() {
    return this._isFillerMaterialValidAWS(
      'fillerMaterialRoot',
      'fillerMaterialRoot',
      'weldingProcessRoot'
    );
  }

  get fillerMaterialRootAWSInvalidMessage() {
    return this._fillerMaterialForAWSInvalidMessage('fillerMaterialRoot', 'weldingProcessRoot');
  }

  get isFillerMaterialFillAWSValid() {
    return this._isFillerMaterialValidAWS(
      'fillerMaterialFill',
      'fillerMaterialFill',
      'weldingProcessFill'
    );
  }

  get fillerMaterialFillAWSInvalidMessage() {
    return this._fillerMaterialForAWSInvalidMessage('fillerMaterialFill', 'weldingProcessFill');
  }

  get isFillerMaterialCapAWSValid() {
    return this._isFillerMaterialValidAWS(
      'fillerMaterialCap',
      'fillerMaterialCap',
      'weldingProcessCap'
    );
  }

  get fillerMaterialCapAWSInvalidMessage() {
    return this._fillerMaterialForAWSInvalidMessage('fillerMaterialCap', 'weldingProcessCap');
  }

  // Filler Material Commercial Designation
  get isFillerMaterialRootCommercialDesignationValid() {
    return this._isFillerMaterialCommercialDesignationValid(
      'fillerMaterialRootCommercialDesignation',
      'fillerMaterialRootCommercialDesignation',
      'meta.pqrRootFillerMaterialAsmeMatchMaterial',
      'meta.pqrRootImpactRequirements'
    );
  }

  get isFillerMaterialFillCommercialDesignationValid() {
    return this._isFillerMaterialCommercialDesignationValid(
      'fillerMaterialFillCommercialDesignation',
      'fillerMaterialFillCommercialDesignation',
      'meta.pqrFillFillerMaterialAsmeMatchMaterial',
      'meta.pqrFillImpactRequirements'
    );
  }

  get isFillerMaterialCapCommercialDesignationValid() {
    return this._isFillerMaterialCommercialDesignationValid(
      'fillerMaterialCapCommercialDesignation',
      'fillerMaterialCapCommercialDesignation',
      'meta.pqrCapFillerMaterialAsmeMatchMaterial',
      'meta.pqrCapImpactRequirements'
    );
  }

  get fillerMaterialRootCommercialDesignationInvalidMessage() {
    return this._fillerMaterialCommercialDesignationInvalidMessage(
      'fillerMaterialRootCommercialDesignation',
      'fillerMaterialRootCommercialDesignation'
    );
  }

  get fillerMaterialFillCommercialDesignationInvalidMessage() {
    return this._fillerMaterialCommercialDesignationInvalidMessage(
      'fillerMaterialFillCommercialDesignation',
      'fillerMaterialFillCommercialDesignation'
    );
  }

  get fillerMaterialCapCommercialDesignationInvalidMessage() {
    return this._fillerMaterialCommercialDesignationInvalidMessage(
      'fillerMaterialCapCommercialDesignation',
      'fillerMaterialCapCommercialDesignation'
    );
  }

  // Filler Material Diameter
  get isFillerMaterialDiameterRootValid() {
    return this._isFillerMaterialDiameterValid(
      'fillerMaterialRootDiameter',
      'fillerMaterialRootDiameters',
      'weldingProcessRoot'
    );
  }

  get fillerMaterialDiameterRootInvalidMessage() {
    return this._fillerMaterialDiameterInvalidMessage(
      'fillerMaterialRootDiameter',
      'fillerMaterialRootDiameters',
      'weldingProcessRoot'
    );
  }

  get isFillerMaterialDiameterFillValid() {
    return this._isFillerMaterialDiameterValid(
      'fillerMaterialFillDiameter',
      'fillerMaterialFillDiameters',
      'weldingProcessFill'
    );
  }

  get fillerMaterialDiameterFillInvalidMessage() {
    return this._fillerMaterialDiameterInvalidMessage(
      'fillerMaterialFillDiameter',
      'fillerMaterialFillDiameters',
      'weldingProcessFill'
    );
  }

  get isFillerMaterialDiameterCapValid() {
    return this._isFillerMaterialDiameterValid(
      'fillerMaterialCapDiameter',
      'fillerMaterialCapDiameters',
      'weldingProcessCap'
    );
  }

  get fillerMaterialDiameterCapInvalidMessage() {
    return this._fillerMaterialDiameterInvalidMessage(
      'fillerMaterialCapDiameter',
      'fillerMaterialCapDiameters',
      'weldingProcessCap'
    );
  }

  // Overlay Type
  get isOverlayTypeValid() {
    if (!isEmpty(get(this.validations, 'overlayType'))) {
      return this._singleValueValidation('overlayType', get(this.wps, 'overlayType'));
    }
    return true;
  }

  get overlayTypeInvalidMessage() {
    let value = get(this.wps, 'overlayType');
    let approvalRange = this.intl.t(`lov.overlay-type.${value}`);
    return this.intl
      .t('model-validations.wps.overlay-type', {
        approvalRange,
      })
      .toString();
  }

  // Product Type
  get isProductTypeValid() {
    let wpsProductType = get(this.wps, 'productType');
    if (this._isASME() || isEmpty(wpsProductType)) {
      return true;
    }

    if (get(this.validations, 'meta.isMultiPQR')) {
      let approvalRange = get(this.validations, 'productType');
      return approvalRange.includes(wpsProductType);
    } else {
      let pqrProductType = get(this.validations, 'productTypeSource');
      if (this._isISO()) {
        if (pqrProductType === PRODUCT_TYPE.PLATE && wpsProductType === PRODUCT_TYPE.PIPE) {
          let diameterMin = get(this.wps, 'diameterMinimum');
          if (diameterMin && !isNaN(diameterMin)) {
            if (diameterMin >= 500) {
              return true;
            } else {
              let allPositions: WeldingPosition[] = [];
              let positionsRoot = get(this.wps, 'weldingPositionsRoot');
              let positionsFill = get(this.wps, 'weldingPositionsFill');
              let positionsCap = get(this.wps, 'weldingPositionsCap');
              positionsRoot.forEach((p: WeldingPosition) => {
                allPositions.pushObject(p);
              });
              positionsFill.forEach((p: WeldingPosition) => {
                allPositions.pushObject(p);
              });
              positionsCap.forEach((p: WeldingPosition) => {
                allPositions.pushObject(p);
              });
              if (
                diameterMin >= 150 &&
                optionsOnlyIncludePositions(allPositions, [
                  WELDING_POSITIONS.PA,
                  WELDING_POSITIONS.PC,
                ])
              ) {
                return true;
              } else {
                return false;
              }
            }
          } else {
            return false;
          }
        } else {
          let validationRange = get(this.validations, 'productType');
          return validationRange.includes(wpsProductType);
        }
      } else if (this._isAWS()) {
        if (pqrProductType === PRODUCT_TYPE.PLATE && wpsProductType === PRODUCT_TYPE.PIPE) {
          let diameterMin = this.wps.diameterMinimum;
          if (diameterMin && !isNaN(diameterMin)) {
            return diameterMin >= this.maxDistanceForAWSPipe;
          }
          return false;
        }
      }
    }
    return true;
  }

  get productTypeInvalidMessage() {
    let { intl, validations, wps } = this;
    if (get(validations, 'meta.isMultiPQR')) {
      let approvalRange = get(validations, 'productType');
      let productTypes = approvalRange.map((type: any) => {
        return intl.t(`lov.product-type.${type}`);
      });
      return intl
        .t('model-validations.wps.product-type', {
          productType: productTypes,
        })
        .toString();
    } else {
      let pqrProductType = get(validations, 'productTypeSource');
      let wpsProductType = wps.productType;
      if (this._isISO()) {
        if (pqrProductType === PRODUCT_TYPE.PLATE && wpsProductType === PRODUCT_TYPE.PIPE) {
          let weldingPositions = serializePositions(
            wps,
            WELDING_PROCEDURE_SPECIFICATION.WELDING_POSITIONS_ROOT,
            WELDING_PROCEDURE_SPECIFICATION.WELDING_POSITIONS_FILL,
            WELDING_PROCEDURE_SPECIFICATION.WELDING_POSITIONS_CAP
          );
          return intl
            .t('model-validations.wps.product-type-diameter', {
              diameter: wps.diameterMinimum,
              positions: weldingPositions,
            })
            .toString();
        } else {
          let approvalRange = get(validations, 'productType');
          let productTypes = approvalRange.map((type: any) => {
            return intl.t(`lov.product-type.${type}`);
          });
          return intl
            .t('model-validations.wps.product-type', {
              productType: productTypes,
            })
            .toString();
        }
      } else if (this._isAWS()) {
        if (pqrProductType === PRODUCT_TYPE.PLATE && wpsProductType === PRODUCT_TYPE.PIPE) {
          return intl
            .t('model-validations.wps.product-type-diameter-aws', {
              diameter: wps.diameterMinimum,
              unit: this.units.distanceUnit,
              max: this.maxDistanceForAWSPipe,
            })
            .toString();
        }
      }
    }
    return '';
  }

  get isWeldingPositionRootValid() {
    let positions = get(this.wps, 'weldingPositionsRoot');
    if (this._isPQRPlateAndWPSPipe() && this._isISO()) {
      return this._isWeldingPositionValid('weldingPositionsRoot');
    }

    let approvalRange = get(this.validations, 'weldingPositionsRoot');
    return this._collectionInCollectionById(approvalRange, positions);
  }

  get isWeldingPositionFillValid() {
    let positions = get(this.wps, 'weldingPositionsFill');
    if (this._isPQRPlateAndWPSPipe() && this._isISO()) {
      return this._isWeldingPositionValid('weldingPositionsFill');
    }

    let approvalRange = get(this.validations, 'weldingPositionsFill');
    return this._collectionInCollectionById(approvalRange, positions);
  }

  get isWeldingPositionCapValid() {
    let positions = get(this.wps, 'weldingPositionsCap');
    if (this._isPQRPlateAndWPSPipe() && this._isISO()) {
      return this._isWeldingPositionValid('weldingPositionsCap');
    }
    let approvalRange = get(this.validations, 'weldingPositionsCap');
    return this._collectionInCollectionById(approvalRange, positions);
  }

  get weldingPositionRootInvalidMessage() {
    if (this._isPQRPlateAndWPSPipe()) {
      if (!this._isWeldingPositionValid('weldingPositionsRoot')) {
        return this.intl.t('model-validations.wps.welding-position-product-type');
      }
    }
    let values = get(this.wps, 'weldingPositionsRoot');
    let approvalRange = get(this.validations, 'weldingPositionsRoot');
    let notQualified = this._notInCollection(approvalRange, values, 'value', 'id');
    let notQualifiedSerialized = serializeCollection(notQualified, 'code', '; ');
    let serialized = this._collectionApprovalRange('weldingPositionsRoot');
    let message = this.intl.t('model-validations.wps.pqr-validation', {
      value: notQualifiedSerialized,
      approvalRange: serialized,
    });
    return message.toString();
  }

  get weldingPositionFillInvalidMessage() {
    if (this._isPQRPlateAndWPSPipe()) {
      if (!this._isWeldingPositionValid('weldingPositionsFill')) {
        return this.intl.t('model-validations.wps.welding-position-product-type');
      }
    }
    let values = get(this.wps, 'weldingPositionsFill');
    let approvalRange = get(this.validations, 'weldingPositionsFill');
    let notQualified = this._notInCollection(approvalRange, values, 'value', 'id');
    let notQualifiedSerialized = serializeCollection(notQualified, 'code', '; ');
    let serialized = this._collectionApprovalRange('weldingPositionsFill');
    let message = this.intl.t('model-validations.wps.pqr-validation', {
      value: notQualifiedSerialized,
      approvalRange: serialized,
    });
    return message.toString();
  }

  get weldingPositionCapInvalidMessage() {
    if (this._isPQRPlateAndWPSPipe()) {
      if (!this._isWeldingPositionValid('weldingPositionsCap')) {
        return this.intl.t('model-validations.wps.welding-position-product-type');
      }
    }
    let values = get(this.wps, 'weldingPositionsCap');
    let approvalRange = get(this.validations, 'weldingPositionsCap');
    let notQualified = this._notInCollection(approvalRange, values, 'value', 'id');
    let notQualifiedSerialized = serializeCollection(notQualified, 'code', '; ');
    let serialized = this._collectionApprovalRange('weldingPositionsCap');
    let message = this.intl.t('model-validations.wps.pqr-validation', {
      value: notQualifiedSerialized,
      approvalRange: serialized,
    });
    return message.toString();
  }

  get isHardnessRequirementsValid() {
    let hardnessStatus = get(this.validations, 'hardnessRequirements');
    let currentValue = this.wps.hardnessTestRequired;

    if (hardnessStatus === 'CANNOT_HAVE' && currentValue) {
      return false;
    }

    return true;
  }

  get hardnessRequirementsInvalidMessage() {
    return this.intl.t('model-validations.wps.hardness-requirements');
  }

  get isImpactTestTemperatureValid() {
    let impactStatus = get(this.validations, 'impactRequirements');
    let currentValue = this.wps.impactTestTemperature;

    if (impactStatus === 'CANNOT_HAVE' && !isEmpty(currentValue)) {
      return false;
    }

    let approvalRange = parseFloat(get(this.validations, 'impactTestTemperature'));

    if (isNaN(approvalRange)) {
      return true;
    }

    let currentValueNumber = parseFloat(`${currentValue}`);
    if (approvalRange > 0 && currentValueNumber < approvalRange) {
      return false;
    } else if (approvalRange < 0 && currentValueNumber < approvalRange) {
      return false;
    }

    return true;
  }

  get impactTestTemperatureInvalidMessage() {
    let impactStatus = get(this.validations, 'impactRequirements');
    let currentValue: string | number | undefined = get(this.wps, 'impactTestTemperature');

    if (impactStatus === 'CANNOT_HAVE' && !isEmpty(currentValue)) {
      return this.intl.t('model-validations.wps.impact-requirements-presence');
    }

    let impactTemperature = get(this.validations, 'impactTestTemperature');
    if (currentValue && !isEmpty(currentValue)) {
      currentValue = `${currentValue}`.replace(',', '.');
    }
    if (!isEmpty(impactTemperature)) {
      impactTemperature = `${impactTemperature}`.replace(',', '.');
    }
    let currentValueNumber = parseFloat(`${currentValue}`);
    let approvalRange = parseFloat(impactTemperature);
    if (approvalRange > 0 && currentValueNumber < approvalRange) {
      return this.intl
        .t('model-validations.wps.impact-requirements-temperature', {
          temperature: impactTemperature,
        })
        .toString();
    } else if (approvalRange < 0 && currentValueNumber < approvalRange) {
      return this.intl
        .t('model-validations.wps.impact-requirements-temperature', {
          temperature: impactTemperature,
        })
        .toString();
    }

    return '';
  }

  get isFillerMaterialElectrodeFormRootValid() {
    if (!isEmpty(get(this.validations, 'fillerMaterialElectrodeFormRoot'))) {
      return this._singleValueValidation(
        'fillerMaterialElectrodeFormRoot',
        this.wps.fillerMaterialElectrodeFormRoot
      );
    }
    return true;
  }

  get fillerMaterialElectrodeFormRootInvalidMessage() {
    let value = get(this.validations, 'fillerMaterialElectrodeFormRoot');
    let valueTranslation = this.intl.t(`lov.filler-material-electrode.${value}`);
    return this._lovMessage(valueTranslation);
  }

  get isFillerMaterialElectrodeFormFillValid() {
    if (!isEmpty(get(this.validations, 'fillerMaterialElectrodeFormFill'))) {
      return this._singleValueValidation(
        'fillerMaterialElectrodeFormFill',
        this.wps.fillerMaterialElectrodeFormFill
      );
    }
    return true;
  }

  get fillerMaterialElectrodeFormFillInvalidMessage() {
    let value = get(this.validations, 'fillerMaterialElectrodeFormFill');
    let valueTranslation = this.intl.t(`lov.filler-material-electrode.${value}`);
    return this._lovMessage(valueTranslation);
  }

  get isFillerMaterialElectrodeFormCapValid() {
    if (!isEmpty(get(this.validations, 'fillerMaterialElectrodeFormCap'))) {
      return this._singleValueValidation(
        'fillerMaterialElectrodeFormCap',
        this.wps.fillerMaterialElectrodeFormCap
      );
    }
    return true;
  }

  get fillerMaterialElectrodeFormCapInvalidMessage() {
    let value = get(this.validations, 'fillerMaterialElectrodeFormCap');
    let valueTranslation = this.intl.t(`lov.filler-material-electrode.${value}`);
    return this._lovMessage(valueTranslation);
  }

  get isFillerMaterialGuideRootValid() {
    if (!isEmpty(get(this.validations, 'fillerMaterialGuideRoot'))) {
      return this._singleValueValidation(
        'fillerMaterialGuideRoot',
        this.wps.fillerMaterialGuideRoot
      );
    }
    return true;
  }

  get fillerMaterialGuideRootInvalidMessage() {
    let value = get(this.validations, 'fillerMaterialGuideRoot');
    let valueTranslation = this.intl.t(`lov.filler-material-guide.${value}`);
    return this._lovMessage(valueTranslation);
  }

  get isFillerMaterialGuideFillValid() {
    if (!isEmpty(get(this.validations, 'fillerMaterialGuideFill'))) {
      return this._singleValueValidation(
        'fillerMaterialGuideFill',
        this.wps.fillerMaterialGuideFill
      );
    }
    return true;
  }

  get fillerMaterialGuideFillInvalidMessage() {
    let value = get(this.validations, 'fillerMaterialGuideFill');
    let valueTranslation = this.intl.t(`lov.filler-material-guide.${value}`);
    return this._lovMessage(valueTranslation);
  }

  get isFillerMaterialGuideCapValid() {
    if (!isEmpty(get(this.validations, 'fillerMaterialGuideCap'))) {
      return this._singleValueValidation('fillerMaterialGuideCap', this.wps.fillerMaterialGuideCap);
    }
    return true;
  }

  get fillerMaterialGuideCapInvalidMessage() {
    let value = get(this.validations, 'fillerMaterialGuideCap');
    let valueTranslation = this.intl.t(`lov.filler-material-guide.${value}`);
    return this._lovMessage(valueTranslation);
  }

  get isOverlayNumberOfLayerValid() {
    return true;
  }

  get overlayNumberOfLayerInvalidMessage() {
    return '';
  }

  isHeatInputValid(value: any, layer: string) {
    if (layer === WELDING_LAYER_OPTIONS.ROOT) {
      return this._rangeValidation('heatInputRoot', value);
    } else if (layer === WELDING_LAYER_OPTIONS.FILL) {
      return this._rangeValidation('heatInputFill', value);
    } else if (layer === WELDING_LAYER_OPTIONS.CAP) {
      return this._rangeValidation('heatInputCap', value);
    }
    return true;
  }

  heatInputInvalidMessage(value: any, layer: string) {
    if (layer === WELDING_LAYER_OPTIONS.ROOT) {
      return this._rangeMessageWithTranslationKey(
        get(this.validations, 'heatInputRoot'),
        value,
        this.units.heatDistanceUnit,
        'model-validations.wps-parameter.heat-input'
      );
    } else if (layer === WELDING_LAYER_OPTIONS.FILL) {
      return this._rangeMessageWithTranslationKey(
        get(this.validations, 'heatInputFill'),
        value,
        this.units.heatDistanceUnit,
        'model-validations.wps-parameter.heat-input'
      );
    } else if (layer === WELDING_LAYER_OPTIONS.CAP) {
      return this._rangeMessageWithTranslationKey(
        get(this.validations, 'heatInputCap'),
        value,
        this.units.heatDistanceUnit,
        'model-validations.wps-parameter.heat-input'
      );
    }
    return '';
  }

  isParameterCurrentMinimumValid(valueRaw: string, parameter: WpsWeldingParameter) {
    let id = get(parameter, 'id');
    let value = parseFloat(valueRaw);
    let pqrMinimum = parseFloat(get(this.validations, `parameters.${id}.current.min`));
    let weldingProcess = get(parameter, 'weldingProcess');
    if (
      get(parameter, 'isLinkedToPass') &&
      !isEmpty(value) &&
      !isNaN(pqrMinimum) &&
      !isNaN(value)
    ) {
      if (this._isAWS() && weldingProcess) {
        if (isSAW(weldingProcess) || isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
          if (value < pqrMinimum * 0.9) {
            return false;
          }
        } else if (isGTAW(weldingProcess)) {
          if (value < pqrMinimum * 0.75) {
            return false;
          }
        }
      } else if (this._isASME() && weldingProcess) {
        if (isESW(weldingProcess)) {
          if (value < pqrMinimum * 0.85) {
            return false;
          }
        }
      }
    }
    return true;
  }

  parameterCurrentMinimumInvalidMessage(valueRaw: string, parameter: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let id = get(parameter, 'id');
    let weldingProcess = get(parameter, 'weldingProcess');
    let pqrMinimum = parseFloat(get(this.validations, `parameters.${id}.current.min`));
    if (
      get(parameter, 'isLinkedToPass') &&
      !isEmpty(value) &&
      !isNaN(pqrMinimum) &&
      weldingProcess
    ) {
      if (isSAW(weldingProcess) || isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
        if (value < pqrMinimum * 0.9) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-minimum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 90,
              pqrValue: pqrMinimum,
            })
            .toString();
        }
      } else if (isGTAW(weldingProcess)) {
        if (value < pqrMinimum * 0.75) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-minimum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 75,
              pqrValue: pqrMinimum,
            })
            .toString();
        }
      } else if (isESW(weldingProcess)) {
        if (value < pqrMinimum * 0.85) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-minimum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 85,
              pqrValue: pqrMinimum,
            })
            .toString();
        }
      }
    }
    return '';
  }

  isParameterCurrentMaximumValid(valueRaw: string, parameter: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(parameter);
    let pqrMaximum = parseFloat(get(this.validations, `parameters.${id}.current.max`));
    if (
      get(parameter, 'isLinkedToPass') &&
      !isEmpty(value) &&
      !isNaN(pqrMaximum) &&
      !isNaN(value)
    ) {
      if (this._isAWS() && weldingProcess) {
        if (isSAW(weldingProcess) || isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
          if (value > pqrMaximum * 1.1) {
            return false;
          }
        } else if (isGTAW(weldingProcess)) {
          if (value > pqrMaximum * 1.25) {
            return false;
          }
        }
      } else if (this._isASME() && weldingProcess) {
        if (isESW(weldingProcess)) {
          if (value > pqrMaximum * 1.15) {
            return false;
          }
        }
      }
    }
    return true;
  }

  parameterCurrentMaximumInvalidMessage(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMaximum = parseFloat(get(this.validations, `parameters.${id}.current.max`));
    if (get(model, 'isLinkedToPass') && !isEmpty(value) && weldingProcess) {
      if (isSAW(weldingProcess) || isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
        if (value > pqrMaximum * 1.1) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-maximum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 110,
              pqrValue: pqrMaximum,
            })
            .toString();
        }
      } else if (isGTAW(weldingProcess)) {
        if (value > pqrMaximum * 1.25) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-maximum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 125,
              pqrValue: pqrMaximum,
            })
            .toString();
        }
      } else if (isESW(weldingProcess)) {
        if (value > pqrMaximum * 1.15) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-maximum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 115,
              pqrValue: pqrMaximum,
            })
            .toString();
        }
      }
    }
    return '';
  }

  isParameterVoltageMinimumValid(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMinimum = parseFloat(get(this.validations, `parameters.${id}.voltage.min`));
    if (get(model, 'isLinkedToPass') && !isNaN(value) && !isNaN(pqrMinimum) && weldingProcess) {
      if (this._isAWS()) {
        if (isSAW(weldingProcess) || isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
          if (value < pqrMinimum * 0.93) {
            return false;
          }
        }
      } else if (this._isASME()) {
        if (isESW(weldingProcess)) {
          if (value < pqrMinimum * 0.85) {
            return false;
          }
        }
      }
    }
    return true;
  }

  parameterVoltageMinimumInvalidMessage(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMinimum = parseFloat(get(this.validations, `parameters.${id}.voltage.min`));
    if (get(model, 'isLinkedToPass') && !isEmpty(value) && weldingProcess) {
      if (isSAW(weldingProcess) || isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
        if (value < pqrMinimum * 0.93) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-minimum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 93,
              pqrValue: pqrMinimum,
            })
            .toString();
        }
      } else if (isESW(weldingProcess)) {
        if (value < pqrMinimum * 0.85) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-minimum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 85,
              pqrValue: pqrMinimum,
            })
            .toString();
        }
      }
    }
    return '';
  }

  isParameterVoltageMaximumValid(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMaximum = parseFloat(get(this.validations, `parameters.${id}.voltage.max`));
    if (get(model, 'isLinkedToPass') && !isNaN(value) && !isNaN(pqrMaximum) && weldingProcess) {
      if (this._isAWS()) {
        if (isSAW(weldingProcess) || isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
          if (value > pqrMaximum * 1.07) {
            return false;
          }
        }
      } else if (this._isASME()) {
        if (isESW(weldingProcess)) {
          if (value > pqrMaximum * 1.15) {
            return false;
          }
        }
      }
    }
    return true;
  }

  parameterVoltageMaximumInvalidMessage(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMaximum = parseFloat(get(this.validations, `parameters.${id}.voltage.max`));
    if (get(model, 'isLinkedToPass') && !isNaN(value) && weldingProcess) {
      if (isSAW(weldingProcess) || isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
        if (value > pqrMaximum * 1.07) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-maximum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 107,
              pqrValue: pqrMaximum,
            })
            .toString();
        }
      } else if (isESW(weldingProcess)) {
        if (value > pqrMaximum * 1.15) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-maximum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 115,
              pqrValue: pqrMaximum,
            })
            .toString();
        }
      }
    }
    return true;
  }

  isParameterTypeCurrentValid(value: BaseModel, model: WpsWeldingParameter) {
    let { id } = this._getParameterProcessId(model);
    let currentId = get(this.validations, `parameters.${id}.typeCurrent.value`);
    if (!isEmpty(currentId)) {
      return currentId === get(value, 'id');
    }
    return true;
  }

  parameterTypeCurrentInvalidMessage(_value: BaseModel, model: WpsWeldingParameter) {
    let { id } = this._getParameterProcessId(model);
    let approvalRange = get(this.validations, `parameters.${id}.typeCurrent.label`);
    return this.intl
      .t('model-validations.wps-parameter.same-value', {
        approvalRange,
      })
      .toString();
  }

  isParameterWireFeedSpeedMinimumValid(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMinimum = parseFloat(get(this.validations, `parameters.${id}.wireFeedSpeed.min`));
    if (get(model, 'isLinkedToPass') && !isNaN(value) && this._isAWS() && weldingProcess) {
      if (isSAW(weldingProcess) || isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
        if (value < pqrMinimum * 0.9) {
          return false;
        }
      }
    }
    return true;
  }

  parameterWireFeedSpeedMinimumInvalidMessage(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMinimum = parseFloat(get(this.validations, `parameters.${id}.wireFeedSpeed.min`));
    let convertedMinimum = `${pqrMinimum}`;
    if (get(model, 'isMetricSystem')) {
      convertedMinimum = fromMilimeterSecondToMeterMinute(pqrMinimum);
    }
    if (get(model, 'isLinkedToPass') && !isNaN(value) && weldingProcess) {
      if (isSAW(weldingProcess) || isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
        if (value < pqrMinimum * 0.9) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-minimum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 90,
              pqrValue: convertedMinimum,
            })
            .toString();
        }
      }
    }
    return '';
  }

  isParameterWireFeedSpeedMaximumValid(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMaximum = parseFloat(get(this.validations, `parameters.${id}.wireFeedSpeed.max`));
    if (get(model, 'isLinkedToPass') && !isNaN(value) && weldingProcess) {
      if (isSAW(weldingProcess) || isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
        if (value > pqrMaximum * 1.1) {
          return false;
        }
      }
    }
    return true;
  }

  parameterWireFeedSpeedMaximumInvalidMessage(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMaximum = parseFloat(get(this.validations, `parameters.${id}.wireFeedSpeed.max`));
    let convertedMaximum = `${pqrMaximum}`;
    if (get(model, 'isMetricSystem')) {
      convertedMaximum = fromMilimeterSecondToMeterMinute(pqrMaximum);
    }
    if (get(model, 'isLinkedToPass') && !isNaN(value) && weldingProcess) {
      if (isSAW(weldingProcess) || isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
        if (value > pqrMaximum * 1.1) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-maximum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 110,
              pqrValue: convertedMaximum,
            })
            .toString();
        }
      }
    }
    return '';
  }

  isParameterTravelSpeedMinimumValid(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMinimum = parseFloat(get(this.validations, `parameters.${id}.travelSpeed.min`));
    if (get(model, 'isLinkedToPass') && !isNaN(value) && this._isAWS() && weldingProcess) {
      if (isSAW(weldingProcess)) {
        if (value < pqrMinimum * 0.85) {
          return false;
        }
      } else if (isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
        if (value < pqrMinimum * 0.75) {
          return false;
        }
      } else if (isGTAW(weldingProcess)) {
        if (value < pqrMinimum * 0.5) {
          return false;
        }
      }
    }
    return true;
  }

  parameterTravelSpeedMinimumInvalidMessage(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMinimum = parseFloat(get(this.validations, `parameters.${id}.travelSpeed.min`));
    let convertedMinimum = `${pqrMinimum}`;
    if (get(model, 'isMetricSystem')) {
      convertedMinimum = fromMilimeterSecondToMilimeterMinute(pqrMinimum);
    }
    if (get(model, 'isLinkedToPass') && !isNaN(value) && weldingProcess) {
      if (isSAW(weldingProcess)) {
        if (value < pqrMinimum * 0.85) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-minimum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 85,
              pqrValue: convertedMinimum,
            })
            .toString();
        }
      } else if (isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
        if (value < pqrMinimum * 0.75) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-minimum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 75,
              pqrValue: convertedMinimum,
            })
            .toString();
        }
      } else if (isGTAW(weldingProcess)) {
        if (value < pqrMinimum * 0.5) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-minimum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 50,
              pqrValue: convertedMinimum,
            })
            .toString();
        }
      }
    }
    return '';
  }

  isParameterTravelSpeedMaximumValid(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMaximum = parseFloat(get(this.validations, `parameters.${id}.travelSpeed.max`));
    if (get(model, 'isLinkedToPass') && !isNaN(value) && weldingProcess) {
      if (isSAW(weldingProcess)) {
        if (value > pqrMaximum * 1.15) {
          return false;
        }
      } else if (isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
        if (value > pqrMaximum * 1.25) {
          return false;
        }
      } else if (isGTAW(weldingProcess)) {
        if (value > pqrMaximum * 1.5) {
          return false;
        }
      }
    }
    return true;
  }

  parameterTravelSpeedMaximumInvalidMessage(valueRaw: string, model: WpsWeldingParameter) {
    let value = parseFloat(valueRaw);
    let { id, weldingProcess } = this._getParameterProcessId(model);
    let pqrMaximum = parseFloat(get(this.validations, `parameters.${id}.travelSpeed.max`));
    let convertedMaximum = `${pqrMaximum}`;
    if (model.isMetricSystem) {
      convertedMaximum = fromMilimeterSecondToMilimeterMinute(pqrMaximum);
    }
    if (!weldingProcess) {
      return '';
    }

    if (get(model, 'isLinkedToPass') && !isNaN(value)) {
      if (isSAW(weldingProcess)) {
        if (value > pqrMaximum * 1.15) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-maximum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 115,
              pqrValue: convertedMaximum,
            })
            .toString();
        }
      } else if (isGMAW(weldingProcess) || isFCAW(weldingProcess)) {
        if (value > pqrMaximum * 1.25) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-maximum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 125,
              pqrValue: convertedMaximum,
            })
            .toString();
        }
      } else if (isGTAW(weldingProcess)) {
        if (value > pqrMaximum * 1.5) {
          return this.intl
            .t('model-validations.wps.wps-welding-parameter-maximum', {
              process: get(weldingProcess, 'shortDesignation'),
              percentage: 150,
              pqrValue: convertedMaximum,
            })
            .toString();
        }
      }
    }
    return '';
  }

  isParameterLayerValid(value: string, model: WpsWeldingParameter) {
    let { id } = this._getParameterProcessId(model);
    let layer = get(this.validations, `parameters.${id}.layer`);
    if (!isEmpty(layer)) {
      return layer === value;
    }
    return true;
  }

  parameterLayerInvalidMessage(_value: string, model: WpsWeldingParameter) {
    let { id } = this._getParameterProcessId(model);
    let pqrLayer = get(this.validations, `parameters.${id}.layer`);
    let approvalRange = '';
    if (this.wps.isBothSides()) {
      approvalRange = this.intl.t(`lov.welding-layer-both-sides.${pqrLayer}`);
    } else {
      approvalRange = this.intl.t(`lov.welding-layer.${pqrLayer}`);
    }
    return this.intl
      .t('model-validations.wps-parameter.same-value', {
        approvalRange,
      })
      .toString();
  }

  isParameterArcTransferModeValid(value: ArcTransferMode, model: WpsWeldingParameter) {
    let { id } = this._getParameterProcessId(model);
    let currentId = get(this.validations, `parameters.${id}.arcTransferMode.value`);
    if (!isEmpty(currentId)) {
      return currentId === get(value, 'id');
    }
    return true;
  }

  parameterArcTransferModeInvalidMessage(_value: ArcTransferMode, model: WpsWeldingParameter) {
    let { id } = this._getParameterProcessId(model);
    let approvalRange = get(this.validations, `parameters.${id}.arcTransferMode.label`);
    return this.intl
      .t('model-validations.wps-parameter.same-value', {
        approvalRange,
      })
      .toString();
  }

  isParameterWeldingProcessValid(value: WeldingProcess, model: WpsWeldingParameter) {
    let { id } = this._getParameterProcessId(model);
    let currentId = get(this.validations, `parameters.${id}.weldingProcess.value`);
    if (!isEmpty(currentId)) {
      return currentId === get(value, 'id');
    }
    return true;
  }

  parameterWeldingProcessInvalidMessage(_value: WeldingProcess, model: WpsWeldingParameter) {
    let { id } = this._getParameterProcessId(model);
    let approvalRange = get(this.validations, `parameters.${id}.weldingProcess.label`);
    return this.intl
      .t('model-validations.wps-parameter.same-value', {
        approvalRange,
      })
      .toString();
  }

  _getParameterProcessId(model: WpsWeldingParameter) {
    return {
      id: get(model, 'id'),
      weldingProcess: get(model, 'weldingProcess'),
    };
  }

  heatInputMinimumSource(parameter: WpsWeldingParameter) {
    return parseFloat(get(this.validations, `parameters.${get(parameter, 'id')}.heatInput.min`));
  }

  heatInputMaximumSource(parameter: WpsWeldingParameter) {
    return parseFloat(get(this.validations, `parameters.${get(parameter, 'id')}.heatInput.max`));
  }

  heatInputMinimumSourcePQR(parameter: WpsWeldingParameter) {
    let layer = get(parameter, 'layer');
    if (layer === WELDING_LAYER_OPTIONS.ROOT) {
      return parseFloat(get(this.validations, 'heatInputRoot.min'));
    } else if (layer === WELDING_LAYER_OPTIONS.FILL) {
      return parseFloat(get(this.validations, 'heatInputFill.min'));
    } else if (layer === WELDING_LAYER_OPTIONS.CAP) {
      return parseFloat(get(this.validations, 'heatInputCap.min'));
    }
    return null;
  }

  heatInputMaximumSourcePQR(parameter: WpsWeldingParameter) {
    let layer = get(parameter, 'layer');
    if (layer === WELDING_LAYER_OPTIONS.ROOT) {
      return parseFloat(get(this.validations, 'heatInputRoot.max'));
    } else if (layer === WELDING_LAYER_OPTIONS.FILL) {
      return parseFloat(get(this.validations, 'heatInputFill.max'));
    } else if (layer === WELDING_LAYER_OPTIONS.CAP) {
      return parseFloat(get(this.validations, 'heatInputCap.max'));
    }
    return null;
  }

  //
  // Helper methods
  //
  _presenceMinimumValidation(property: string, value: any) {
    let correctValue = get(this.validations, property);
    if (correctValue === 'CANNOT_HAVE') {
      return isEmpty(value);
    } else if (correctValue === 'MUST_HAVE') {
      return !isEmpty(value);
    }
    return true;
  }

  _presenceMaximumValidation(property: string, value: any) {
    let correctValue = get(this.validations, property);
    if (correctValue === 'MUST_HAVE') {
      return !isEmpty(value);
    } else if (correctValue === 'CANNOT_HAVE') {
      return isEmpty(value);
    }
    return true;
  }

  _presenceMinimumValidationMessage(
    property: string,
    value: any,
    rangeProperty: string,
    unit: string
  ) {
    let correctValue = get(this.validations, property);
    if (correctValue === 'CANNOT_HAVE') {
      if (!isEmpty(value)) {
        return this.intl.t('model-validations.wps.pqr-empty-but-wps-have-value-pwht');
      }
    } else if (correctValue === 'MUST_HAVE') {
      if (isEmpty(value)) {
        let approvalRange = this._getApprovalRange(rangeProperty);
        return this.intl
          .t('model-validations.wps.pqr-with-value-but-wps-empty-pwht', {
            approvalRange,
            unit,
          })
          .toString();
      }
    }
    return '';
  }

  _presenceMaximumValidationMessage(
    property: string,
    value: any,
    rangeProperty: string,
    unit: string
  ) {
    let correctValue = get(this.validations, property);
    if (correctValue === 'MUST_HAVE') {
      if (isEmpty(value)) {
        let approvalRange = this._getApprovalRange(rangeProperty);
        return this.intl
          .t('model-validations.wps.pqr-with-value-but-wps-empty-pwht', {
            approvalRange,
            unit,
          })
          .toString();
      }
    } else if (correctValue === 'CANNOT_HAVE') {
      if (!isEmpty(value)) {
        return this.intl.t('model-validations.wps.pqr-empty-but-wps-have-value-pwht');
      }
    }
    return '';
  }

  _getApprovalRange(rangeProperty: string) {
    let { min, max } = get(this.validations, rangeProperty);
    let approvalRange = rangeSerialization(min, max);
    return approvalRange;
  }

  _shouldValidateDiameter(value: any) {
    if (
      !isEmpty(value) &&
      get(this.wps, 'isLinkedToPQR') &&
      (this._isISO() || this._isAWS()) &&
      !get(this.validations, 'meta.isMultiPQR')
    ) {
      return true;
    }
    return false;
  }

  _isWeldingPositionValid(weldingPositionProperty: keyof WPSModel) {
    let diameterMin = get(this.wps, 'diameterMinimum') || NaN;
    if (!isNaN(diameterMin)) {
      if (diameterMin >= 500) {
        return true;
      } else {
        let allPositions = this._getAllWeldingPositions(this.wps, weldingPositionProperty);
        if (
          !optionsOnlyIncludePositions(allPositions, [WELDING_POSITIONS.PA, WELDING_POSITIONS.PC])
        ) {
          return false;
        }
      }
    }
    return true;
  }

  _isPQRPlateAndWPSPipe() {
    let pqrProductType = get(this.validations, 'productTypeSource');
    let wpsProductType = get(this.wps, 'productType');
    return pqrProductType === PRODUCT_TYPE.PLATE && wpsProductType === PRODUCT_TYPE.PIPE;
  }

  _getAllWeldingPositions(wps: WPSModel, layer: keyof WPSModel) {
    let allPositions: WeldingPosition[] = [];
    let positions = get(wps, layer);
    positions.forEach((p: WeldingPosition) => {
      allPositions.pushObject(p);
    });
    return allPositions;
  }

  _isFillerMaterialValidAWS(
    validationProperty: string,
    wpsProperty: keyof WPSModel,
    wpsWeldingProcessProperty: keyof WPSModel
  ) {
    let isWPSStandardAWS = this._isAWS();
    if (isWPSStandardAWS) {
      let pqrFillerMaterial = get(this.validations, validationProperty);
      let wpsFillerMaterial = get(this.wps, wpsProperty);
      let weldingProcess = get(this.wps, wpsWeldingProcessProperty);
      if (this._hasImpactRequirements()) {
        if (isSAW(weldingProcess)) {
          return this._isSameInstance(pqrFillerMaterial, wpsFillerMaterial);
        } else {
          return isSameSpecification(pqrFillerMaterial, wpsFillerMaterial);
        }
      } else {
        if (isSMAW(weldingProcess)) {
          if (isSpecification51or55(pqrFillerMaterial)) {
            return isSpecification51or55withFillerMaterial(wpsFillerMaterial);
          } else {
            return this._isSameInstance(pqrFillerMaterial, wpsFillerMaterial);
          }
        } else if (isSAW(weldingProcess)) {
          if (isSpecification517or523(pqrFillerMaterial)) {
            return isSpecification517or523withFiller(wpsFillerMaterial);
          } else {
            return this._isSameInstance(pqrFillerMaterial, wpsFillerMaterial);
          }
        } else if (isGMAW(weldingProcess)) {
          if (isSpecification518or528or536(pqrFillerMaterial)) {
            return isSpecification518or528or536withFiller(wpsFillerMaterial);
          } else {
            return this._isSameInstance(pqrFillerMaterial, wpsFillerMaterial);
          }
        } else if (isFCAW(weldingProcess)) {
          if (isSpecification520or529or536(pqrFillerMaterial)) {
            return isSpecification520or529or536withFiller(wpsFillerMaterial);
          } else {
            return this._isSameInstance(pqrFillerMaterial, wpsFillerMaterial);
          }
        }
      }
    }
    return true;
  }

  _fillerMaterialForAWSInvalidMessage(
    validationProperty: string,
    wpsWeldingProcessProperty: keyof WPSModel
  ) {
    let pqrFillerMaterial = get(this.validations, validationProperty);
    let approvalRange = get(pqrFillerMaterial, 'label');
    let specification = get(pqrFillerMaterial, 'specificationLabel');
    let weldingProcess = get(this.wps, wpsWeldingProcessProperty);
    if (this._hasImpactRequirements()) {
      if (isSAW(weldingProcess)) {
        return this.intl
          .t('model-validations.wps.same-filler-material', {
            approvalRange: `${approvalRange}`,
          })
          .toString();
      } else {
        return this.intl
          .t('model-validations.wps.same-filler-material-specification', {
            specification,
          })
          .toString();
      }
    } else {
      if (isSMAW(weldingProcess)) {
        if (isSpecification51or55(pqrFillerMaterial)) {
          return this.intl
            .t('model-validations.wps.same-filler-material-specification', {
              specification,
            })
            .toString();
        } else {
          return this.intl
            .t('model-validations.wps.same-filler-material', {
              approvalRange: `${approvalRange}`,
            })
            .toString();
        }
      } else if (isSAW(weldingProcess)) {
        if (isSpecification517or523(pqrFillerMaterial)) {
          return this.intl
            .t('model-validations.wps.same-filler-material-specification', {
              specification,
            })
            .toString();
        } else {
          return this.intl
            .t('model-validations.wps.same-filler-material', {
              approvalRange: `${approvalRange}`,
            })
            .toString();
        }
      } else if (isGMAW(weldingProcess)) {
        if (isSpecification518or528or536(pqrFillerMaterial)) {
          return this.intl
            .t('model-validations.wps.same-filler-material-specification', {
              specification,
            })
            .toString();
        } else {
          return this.intl
            .t('model-validations.wps.same-filler-material', {
              approvalRange: `${approvalRange}`,
            })
            .toString();
        }
      } else if (isFCAW(weldingProcess)) {
        if (isSpecification520or529or536(pqrFillerMaterial)) {
          return this.intl
            .t('model-validations.wps.same-filler-material-specification', {
              specification,
            })
            .toString();
        } else {
          return this.intl
            .t('model-validations.wps.same-filler-material', {
              approvalRange: `${approvalRange}`,
            })
            .toString();
        }
      }
    }
    return '';
  }

  _isFillerMaterialDiameterValid(
    validationProperty: string,
    wpsFillerMaterialDiameterProperty: keyof WPSModel,
    wpsWeldingProcessProperty: keyof WPSModel
  ) {
    let unitSystem = get(this.units, 'unitSystem');
    if (this._isAWS()) {
      let weldingProcess = get(this.wps, wpsWeldingProcessProperty);
      let diameterWPS = parseFloat(get(this.wps, wpsFillerMaterialDiameterProperty));
      let diameterPQR = parseFloat(get(this.validations, validationProperty));
      if (isNaN(diameterWPS) || isNaN(diameterPQR)) {
        return true;
      }
      if (isSMAW(weldingProcess)) {
        if (unitSystem === UNIT_SYSTEM.METRIC) {
          if (diameterWPS - diameterPQR > 0.8) {
            return false;
          }
        } else if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
          if (diameterWPS - diameterPQR > 0.03125) {
            return false;
          }
        }
      } else if (isSAW(weldingProcess)) {
        return diameterWPS <= diameterPQR;
      } else if (isGMAW(weldingProcess)) {
        return diameterPQR === diameterWPS;
      } else if (isFCAW(weldingProcess)) {
        return diameterWPS <= diameterPQR;
      } else if (isGTAW(weldingProcess)) {
        if (unitSystem === UNIT_SYSTEM.METRIC) {
          return Math.abs(diameterWPS - diameterPQR) < 1.6 && diameterWPS > 0.6;
        } else if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
          return Math.abs(diameterWPS - diameterPQR) < 0.0625 && diameterWPS > 0.0625;
        }
      }
    }
    return true;
  }

  _fillerMaterialDiameterInvalidMessage(
    validationProperty: string,
    wpsFillerMaterialDiameterProperty: keyof WPSModel,
    wpsWeldingProcessProperty: keyof WPSModel
  ) {
    let unitSystem = get(this.units, 'unitSystem');
    let diameterWPS = get(this.wps, wpsFillerMaterialDiameterProperty);
    let diameterPQR = get(this.validations, validationProperty);
    let weldingProcess = get(this.wps, wpsWeldingProcessProperty);
    let unit =
      unitSystem === UNIT_SYSTEM.METRIC ? this.intl.t('lov.units.mm') : this.intl.t('lov.units.in');
    if (this._isAWS()) {
      if (isSMAW(weldingProcess)) {
        if (unitSystem === UNIT_SYSTEM.METRIC) {
          return this.intl
            .t('model-validations.wps.filler-material-diameter-over-specific', {
              distance: 0.8,
              distancePQR: diameterPQR,
              unit,
              process: 'SMAW',
            })
            .toString();
        } else if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
          return this.intl
            .t('model-validations.wps.filler-material-diameter-over-specific', {
              distance: 0.03125,
              distancePQR: diameterPQR,
              unit,
              process: 'SMAW',
            })
            .toString();
        }
      } else if (isSAW(weldingProcess)) {
        return this.intl
          .t('model-validations.wps.filler-material-diameter-over', {
            distance: diameterPQR,
            unit,
            process: 'SAW',
          })
          .toString();
      } else if (isGMAW(weldingProcess)) {
        return this.intl
          .t('model-validations.wps.filler-material-diameter-equal', {
            distance: diameterPQR,
            unit,
            process: 'GMAW',
          })
          .toString();
      } else if (isFCAW(weldingProcess)) {
        return this.intl
          .t('model-validations.wps.filler-material-diameter-over', {
            distance: diameterPQR,
            unit,
            process: 'FCAW',
          })
          .toString();
      } else if (isGTAW(weldingProcess)) {
        if (unitSystem === UNIT_SYSTEM.METRIC) {
          if (diameterWPS < 0.6) {
            return this.intl
              .t('model-validations.wps.filler-material-diameter-minimum', {
                distance: 0.6,
                unit,
                process: 'GTAW',
              })
              .toString();
          }
          if (Math.abs(diameterWPS - diameterPQR) > 1.6) {
            return this.intl
              .t('model-validations.wps.filler-material-diameter-above-or-below', {
                distance: 1.6,
                distancePQR: diameterPQR,
                unit,
                process: 'GTAW',
              })
              .toString();
          }
        } else if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
          if (diameterWPS < 0.0625) {
            return this.intl
              .t('model-validations.wps.filler-material-diameter-minimum', {
                distance: 0.0625,
                unit,
                process: 'GTAW',
              })
              .toString();
          }
          if (Math.abs(diameterWPS - diameterPQR) > 0.0625) {
            return this.intl
              .t('model-validations.wps.filler-material-diameter-above-or-below', {
                distance: 0.0625,
                distancePQR: diameterPQR,
                unit,
                process: 'GTAW',
              })
              .toString();
          }
        }
      }
    }
    return '';
  }

  _thicknessTotalInvalidMessage(validationProperty: string, wpsProperty: keyof WPSModel) {
    let isWPSFilletWeld = get(this.validations, 'meta.isFilletWeld');
    let isPqrButtWeld = get(this.validations, 'meta.isSomeTypeOfButtWeld');
    let value = get(this.wps, wpsProperty);
    let isWPSPartialJointPenetrationButtWeld = get(
      this.validations,
      'meta.isPartialJointPenetrationWeld'
    );
    if (isWPSFilletWeld) {
      if (isPqrButtWeld) {
        return this._rangeMessageValidator(
          get(this.validations, 'throatThickness'),
          value,
          this.units.distanceUnit
        );
      } else {
        return this._rangeMessageWithTranslationKey(
          get(this.validations, validationProperty),
          value,
          this.units.distanceUnit,
          'model-validations.wps.fillet-numeric-validation'
        );
      }
    } else if (isWPSPartialJointPenetrationButtWeld) {
      return this._rangeMessageWithTranslationKey(
        get(this.validations, validationProperty),
        value,
        this.units.distanceUnit,
        'model-validations.wps.partial-joint-penetration-numeric-validation'
      );
    } else {
      return this._rangeMessageValidator(
        get(this.validations, validationProperty),
        value,
        this.units.distanceUnit
      );
    }
  }

  _isFillerMaterialCommercialDesignationValid(
    validationProperty: any,
    wpsProperty: keyof WPSModel,
    asmeMatchProperty: string,
    impactRequirementsProperty: string
  ) {
    if (this._isISO()) {
      return this._isValidInstance(wpsProperty, validationProperty);
    } else if (this._isASME() && get(this.validations, asmeMatchProperty)) {
      return this._isValidInstance(wpsProperty, validationProperty);
    } else if (this._isAWS() && get(this.validations, impactRequirementsProperty)) {
      return this._isValidInstance(wpsProperty, validationProperty);
    }
    return true;
  }

  _fillerMaterialCommercialDesignationInvalidMessage(
    validationProperty: string,
    wpsProperty: keyof WPSModel
  ) {
    if (this._isISO() || this._isASME()) {
      let commercialDesignation = get(this.wps, wpsProperty);
      let serialized = this._collectionApprovalRange(validationProperty);
      let message = this.intl.t('model-validations.wps.collection-validation', {
        value: get(commercialDesignation, 'commercialDesignation'),
        approvalRange: serialized,
      });
      return message.toString();
    } else if (this._isAWS()) {
      let approvalRange = this._collectionApprovalRange(validationProperty);
      return this.intl
        .t('model-validations.wps.same-filler-material-commercial-designation', {
          approvalRange,
        })
        .toString();
    }
    return '';
  }

  _isFillerMaterialValid(
    validationProperty: string,
    wpsProperty: keyof WPSModel,
    metaProperty: string
  ) {
    let value = get(this.wps, wpsProperty);
    let fillerMaterialId = get(this.validations, `${validationProperty}.value`);
    if (!isEmpty(value) && !isEmpty(get(value, 'id')) && !isEmpty(fillerMaterialId)) {
      if (this._isISO()) {
        return this._isEqualId(get(value, 'id'), fillerMaterialId);
      } else if (this._isASME()) {
        if (get(this.validations, `meta.${metaProperty}`)) {
          // In this situation must have the same filler material
          return this._isEqualId(get(value, 'id'), fillerMaterialId);
        } else {
          // In this situation any filler material from the same group is valid
          let currentGroup = get(value, 'group.id');
          return this._isEqualId(currentGroup, fillerMaterialId);
        }
      } else {
        return true;
      }
    }
    return true;
  }

  _fillerMaterialInvalidMessage(
    validationProperty: string,
    wpsProperty: keyof WPSModel,
    metaProperty: string
  ) {
    let value = get(this.wps, wpsProperty);
    let approvalRange = get(this.validations, `${validationProperty}.label`);
    if (this._isISO()) {
      return this.intl
        .t('model-validations.wps.filler-material-iso', {
          approvalRange,
        })
        .toString();
    } else if (this._isASME()) {
      if (get(this.validations, `meta.${metaProperty}`)) {
        // In this situation must have the same filler material
        return this.intl
          .t('model-validations.wps.filler-material-asme-impact-requirements', {
            fillerMaterial: approvalRange,
          })
          .toString();
      } else {
        // In this situation any filler material from the same group is valid
        let approvalRange = get(value, 'group.groupName');
        return this.intl
          .t('model-validations.wps.filler-material-asme', {
            approvalRange,
          })
          .toString();
      }
    } else {
      return '';
    }
  }

  _isShieldingValidForProcess(weldingProcessProperty: string, valueProperty: keyof WPSModel) {
    let value = get(this.wps, valueProperty);
    let weldingProcess = get(this.validations, weldingProcessProperty);
    if (!isEmpty(value) && !isEmpty(weldingProcess)) {
      let correctShielding = calculateShielding(weldingProcess);
      if (!isEmpty(correctShielding)) {
        // Due to not having FCAW-S (which is the process which can have gas)
        // we now have a check here to allow the WPS to have FCAW with gas
        if (weldingProcess === 'FCAW') {
          return value === SHIELDING_OPTIONS.GAS || SHIELDING_OPTIONS.NONE;
        }
        return correctShielding === value;
      }
    }
    return true;
  }

  _isISO() {
    return get(this.validations, 'meta.isISO');
  }

  _isASME() {
    return get(this.validations, 'meta.isASME');
  }

  _isAWS() {
    return get(this.validations, 'meta.isAWS');
  }

  _hasImpactRequirements() {
    return get(this.validations, 'meta.wpsImpactRequirements');
  }

  _shieldingInvalidMessageForProcess(validationProperty: string, layer = 'ROOT') {
    let { intl } = this;
    let process = get(this.validations, validationProperty);
    let correctShielding = calculateShielding(process);
    let shielding = intl.t(`lov.shielding.${correctShielding}`);
    let translationKey = '';
    if (layer === 'ROOT') {
      translationKey = 'model-validations.shielding.root';
    } else if (layer === 'FILL') {
      translationKey = 'model-validations.shielding.fill';
    } else if (layer === 'CAP') {
      translationKey = 'model-validations.shielding.cap';
    }
    return this.intl
      .t(translationKey, {
        process,
        shielding,
      })
      .toString();
  }

  _isSameLovValue(validationProperty: string, valueProperty: keyof WPSModel) {
    let approvalRange = get(this.validations, validationProperty);
    if (isEmpty(approvalRange)) {
      return true;
    }
    let value = get(this.wps, valueProperty);
    return approvalRange === value;
  }

  _lovInvalidMessage(validationProperty: string, lovKey: string) {
    let lovValue = get(this.validations, validationProperty);
    let approvalRange = this.intl.t(`lov.${lovKey}.${lovValue}`);
    return this.intl
      .t('model-validations.wps.same-value', {
        approvalRange,
        unit: '',
      })
      .toString();
  }

  _isWeldingPositionDirectionValid(validationProperty: string, valueProperty: keyof WPSModel) {
    let pqrDirection = get(this.validations, validationProperty);
    let wpsDirection = get(this.wps, valueProperty);
    if (!isEmpty(pqrDirection)) {
      if (isEmpty(wpsDirection)) {
        return true;
      } else {
        return wpsDirection === pqrDirection;
      }
    } else {
      if (!isEmpty(wpsDirection)) {
        return false;
      }
    }
    return true;
  }

  _weldingPositionDirectionInvalidMessage(validationProperty: string) {
    let direction = get(this.validations, validationProperty);
    if (!isEmpty(direction)) {
      let approvalRange = this.intl.t(`lov.welding-position-direction.${direction}`);
      return this.intl
        .t('model-validations.wps.same-value', {
          approvalRange,
          unit: '',
        })
        .toString();
    } else {
      return this.intl.t('model-validations.wps.welding-position-direction');
    }
  }

  _invalidMessage(wpsProperty: keyof WPSModel, valueProperty: string, validationProperty: string) {
    let instance = get(this.wps, wpsProperty);
    let message = this.intl.t('model-validations.wps.pqr-validation', {
      value: get(instance, valueProperty),
      approvalRange: this._collectionApprovalRange(validationProperty),
    });
    return message.toString();
  }

  _isValidInstance(wpsProperty: keyof WPSModel, validationProperty: string, property = 'id') {
    let instance = get(this.wps, wpsProperty);
    if (!isEmpty(instance) && !isEmpty(get(instance, 'id'))) {
      return this._collectionValidation(validationProperty, get(instance, property));
    }
    return true;
  }

  _pwhtRangeMessage(range: NumericalRange, value: any, unit = '') {
    let { min, max } = range;
    if (isEmpty(min) && isEmpty(max) && !isEmpty(value)) {
      return this.intl.t('model-validations.wps.pqr-empty-but-wps-have-value-pwht');
    }
    if ((!isEmpty(min) || !isEmpty(max)) && isEmpty(value)) {
      return this.intl.t('model-validations.wps.pqr-with-value-but-wps-empty-pwht');
    }
    return this._rangeMessageValidator(range, value, unit);
  }

  _rangeMessageWithTranslationKey(
    range: NumericalRange,
    value: any,
    unit: string,
    messageKey: string
  ) {
    let { min, max } = range;
    let approvalRange = rangeSerialization(min, max);
    let message = this.intl.t(messageKey, {
      value,
      approvalRange,
      unit,
    });
    return message.toString();
  }

  _singleValueValidation(property: string, value: string | number | undefined) {
    let current = get(this.validations, property);
    return `${current}` === `${value}`;
  }

  _rangeValidation(property: string, value: string | number | undefined) {
    let set = get(this.validations, property);
    let isValid = true;
    if (set && set.min) {
      if (parseFloat(set.min) > parseFloat(`${value}`)) {
        isValid = false;
      }
    }
    if (set && set.max) {
      if (parseFloat(set.max) < parseFloat(`${value}`)) {
        isValid = false;
      }
    }
    return isValid;
  }

  _collectionValidation(property: string, value: string) {
    let collection = get(this.validations, property);
    if (!isEmpty(collection)) {
      let result = collection.mapBy('value').find((item: string) => {
        // Values come from the server as numbers, but from frontend as string,
        // need to convert them as strings
        return `${item}` === `${value}`;
      });
      return !isEmpty(result);
    }
    return true;
  }

  _collectionApprovalRange(property: string) {
    let collection = get(this.validations, property);
    if (!isEmpty(collection)) {
      return collection.mapBy('label').toArray().join(', ');
    }
    return true;
  }

  _rangeMessageValidator(range: NumericalRange, value: any, unit: string) {
    return this._rangeMessageWithTranslationKey(
      range,
      value,
      unit,
      'model-validations.wps.numeric-validation'
    );
  }

  _singleValueMessage(value: string) {
    return this.intl
      .t('model-validations.wps.same-value', { approvalRange: value, unit: '' })
      .toString();
  }

  _lovMessage(value: string) {
    return this.intl.t('model-validations.wps.same-value-lov', { value });
  }

  _angleRangeMessage(range: NumericalRange, value: any) {
    return this._rangeMessageValidator(range, value, 'º');
  }

  _notInCollection(
    collection: any,
    values: Enumerable<BaseModel>,
    collectionPropertyId = 'id',
    valueId: keyof BaseModel = 'id'
  ) {
    let notInCollection: any[] = [];
    if (!isEmpty(collection)) {
      values.forEach((v) => {
        let result = collection.findBy(collectionPropertyId, get(v, valueId));
        if (isEmpty(result)) {
          notInCollection.pushObject(v);
        }
      });
    }
    return notInCollection;
  }

  _collectionInCollectionById(haystack: [], needles: Enumerable<BaseModel>) {
    let inApprovalRange = true;
    if (!isEmpty(needles)) {
      needles.forEach((needle) => {
        let found = haystack.find((item) => {
          return `${get(item, 'value')}` === `${get(needle, 'id')}`;
        });
        if (isEmpty(found)) {
          inApprovalRange = false;
        }
      });
    }
    return inApprovalRange;
  }

  _isSameInstance(validationProperty: string, wpsProperty: keyof WPSModel) {
    let approvalRangeId = get(this.validations, `${validationProperty}.value`);
    let valueId = this.wps.getBelongsToId(wpsProperty);
    if (isEmpty(approvalRangeId)) {
      return true;
    }
    return `${approvalRangeId}` === `${valueId}`;
  }

  _isSameInstanceInvalidMessage(validationProperty: string) {
    let approvalRange = get(this.validations, `${validationProperty}.label`);
    return this.intl
      .t('model-validations.wps.same-value', {
        approvalRange,
        unit: '',
      })
      .toString();
  }

  _isEqualId(firstId: string, secondId: string) {
    return `${firstId}` === `${secondId}`;
  }

  _instanceHasValue(property: keyof WPSModel) {
    let value = get(this.wps, property);
    if (!isEmpty(value)) {
      return !isEmpty(get(value, 'id'));
    }
    return false;
  }

  get _wpsFillerMaterialRoot() {
    return get(this.wps, 'fillerMaterialRoot');
  }

  get _wpsFillerMaterialFill() {
    return get(this.wps, 'fillerMaterialFill');
  }

  get _wpsFillerMaterialCap() {
    return get(this.wps, 'fillerMaterialCap');
  }

  get isFilletWeld() {
    return isFilletWeld(this.wps.weldType);
  }

  get isOverlayWeld() {
    return isOverlayWeld(this.wps.weldType);
  }
}
