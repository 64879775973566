import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @isEditing}}\n  <PowerSelect\n    @triggerClass={{\"form-control\"}}\n    @options={{@options}}\n    @disabled={{@disabled}}\n    @allowClear={{true}}\n    @selected={{this.currentValue}}\n    @onChange={{this.onChange}}\n    @renderInPlace={{@renderInPlace}}\n    as |option|\n  >\n    {{option.label}}\n  </PowerSelect>\n{{else}}\n  <p class=\"form-control-static\">{{this.displayValue}}</p>\n{{/if}}", {"contents":"{{#if @isEditing}}\n  <PowerSelect\n    @triggerClass={{\"form-control\"}}\n    @options={{@options}}\n    @disabled={{@disabled}}\n    @allowClear={{true}}\n    @selected={{this.currentValue}}\n    @onChange={{this.onChange}}\n    @renderInPlace={{@renderInPlace}}\n    as |option|\n  >\n    {{option.label}}\n  </PowerSelect>\n{{else}}\n  <p class=\"form-control-static\">{{this.displayValue}}</p>\n{{/if}}","moduleName":"weldnote/components/ui-form/input/select.hbs","parseOptions":{"srcName":"weldnote/components/ui-form/input/select.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class Select extends Component {
  get currentValue() {
    let { value, options } = this.args;
    if (options) {
      let result = options.findBy('key', value);
      if (result) {
        return result;
      }
    }
    return null;
  }

  get displayValue() {
    let { value, options } = this.args;
    if (options) {
      let result = options.findBy('key', value);
      if (result) {
        return result.label;
      }
    }
    return '';
  }

  @action
  onChange(value) {
    if (this.args.onUpdate) {
      if (value) {
        this.args.onUpdate(value.key);
      } else {
        this.args.onUpdate(value);
      }
    }
  }
}
