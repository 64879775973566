import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { set, get } from '@ember/object';
import EditRoute from 'weldnote/routes/_base/edit';
import WeldingBook from 'weldnote/utils/welding-book-rules';

const { WELDING_BOOK } = WeldingBook;

@classic
export default class WeldingBookRoute extends EditRoute {
  @service
  userSession;

  @service('weldnote-data')
  data;

  modelName = 'welding-book';

  listRoute = 'welding-books';

  model({ id }) {
    return this.data.loadAllDataWeldingBook(id);
  }

  afterModel(weldingBook) {
    let staff = get(this, 'userSession.account.staff');
    if (!isEmpty(staff) && !isEmpty(get(staff, 'id')) && get(weldingBook, 'isNew')) {
      set(weldingBook, WELDING_BOOK.WRITTEN_BY, staff);
      set(weldingBook, WELDING_BOOK.WRITTEN_BY_DATE, new Date());
    }
  }
}
