import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import { service } from '@ember/service';

@classic
export default class NewMultipleController extends Controller {
  @service
  router;

  @action
  saveWpsFromMultiplePQR(newWps) {
    this.router.transitionTo('welding-procedure-specification', newWps.id, {
      queryParams: {
        isEditing: 'true',
      },
    });
  }

  @action
  returnToList() {
    this.router.transitionTo('welding-procedure-specifications');
  }
}
