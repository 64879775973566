import Controller from '@ember/controller';

export default class WelderCertificateNew extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  queryParams = [
    {
      welderType: { type: 'string' as const },
    },
  ];
  // normal class body definition here
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'welder-certificate/new': WelderCertificateNew;
  }
}
