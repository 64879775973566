import { helper } from '@ember/component/helper';
import { isEmpty } from '@ember/utils';

export function arrayJoin(params, hash) {
  if (isEmpty(params[0])) {
    return '';
  } else {
    let { separator } = hash;
    if (isEmpty(separator)) {
      separator = ',';
    }
    return hash.ignoreEmpty ? params[0].filter(Boolean).join(separator) : params[0].join(separator);
  }
}

export default helper(arrayJoin);
