import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Service, { service } from '@ember/service';

@classic
export default class HelpService extends Service {
  @service
  userSession;

  @alias('userSession.isTrial')
  isHelpActive;

  pwpsVideo = '26zDeAB5vZc';

  pqrVideo = 'cT7innKAOig';

  wpsSinglePqrVideo = 'zQC8X5u0EW0';

  wpsMultiplePqrVideo = 'Usu9djmhRdU';

  wpqVideo = 'amusQjhHndA';

  qmatrixSearchVideo = '-L6YKAGYcEw';

  weldingBookVideo = 'YYu3ZmJNviw';

  projectVideo = 'ZorjE0daiQw';

  convertVideoIdToAnalyticsEvent(videoId) {
    if (videoId === this.pqrVideo) {
      return 'video-pqr-create';
    } else if (videoId === this.wpsSinglePqrVideo) {
      return 'video-wps-from-single-pqr-create';
    } else if (videoId === this.wpsMultiplePqrVideo) {
      return 'video-wps-from-multi-pqr-create';
    } else if (videoId === this.qmatrixSearchVideo) {
      return 'video-qmatrix-search';
    } else if (videoId === this.wpqVideo) {
      return 'video-wpq-create';
    } else if (videoId === this.weldingBookVideo) {
      return 'video-welding-book-create';
    } else if (videoId === this.pwpsVideo) {
      return 'video-pwps-create';
    } else if (videoId === this.projectVideo) {
      return 'video-project-manage';
    }
    throw new Error(`Unknown Video ID ${videoId}`);
  }
}
