import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import FileUpload from './file-upload';

export default class Company extends BaseModel {
  @attr('string')
  declare name?: string;

  @attr('string')
  declare address?: string;

  @belongsTo('file-upload')
  declare companyLogo: AsyncBelongsTo<FileUpload>;

  @belongsTo('file-upload')
  declare secondaryLogo: AsyncBelongsTo<FileUpload>;

  @attr('string')
  declare language?: string;

  @attr('number')
  declare totalWelds?: number;

  @attr('number')
  declare goodWelds?: number;

  @attr('number')
  declare repairedWelds?: number;

  @attr('number')
  declare repairRate?: number;

  @belongsTo('company', { inverse: null })
  declare parentCompany: AsyncBelongsTo<Company>;

  get visualLabel(): string {
    return this.name || '';
  }

  validations = {
    name: { presence: true },
  };

  metadata = {
    modelName: 'company',
    name: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    company: Company;
  }
}
