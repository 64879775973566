import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class WeldingBookStatus extends BaseModel {
  @attr('string')
  declare designation?: string;

  @attr('string')
  declare abbreviation?: string;

  get visualLabel(): string {
    return `${this.designation} - ${this.abbreviation}`;
  }

  validations = {
    designation: {
      presence: {
        message(_key: string, _value: string, model: WeldingBookStatus): string {
          return model.intl.t('generic.error.input-value');
        },
      },
    },

    abbreviation: {
      presence: {
        message(_key: string, _value: string, model: WeldingBookStatus): string {
          return model.intl.t('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'welding-book-status',
    designation: { required: true },
    abbreviation: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'welding-book-status': WeldingBookStatus;
  }
}
