import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import ENV from 'weldnote/config/environment';

@classic
export default class LoginRoute extends Route {
  @service
  session;

  @service
  router;

  @service
  ajax;

  @service
  orgApi;

  model() {
    return {};
  }

  beforeModel(transition) {
    this.session.prohibitAuthentication('index');
  }

  async afterModel() {
    let { session, router, orgApi } = this;
    if (!session.isAuthenticated) {
      try {
        await session.authenticate('authenticator:hacked-msal');
        await orgApi.syncAccount();
      } catch (e) {
        // window.location.href = `${ENV.esabCloudLink}/login`;
        // in case we try to automatically sign in but there are no SSO tokens, we end up here
      }
    } else {
      router.transitionTo('/');
    }
  }
}
