import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import Constants from 'weldnote/utils/constants';
import Project from './project';
import FillerMaterialCertificate from './filler-material-certificate';

const { PROJECT_REFERENCE_TYPE } = Constants;

export default class ProjectFillerMaterialCertificate extends BaseModel {
  @belongsTo('project')
  declare project: AsyncBelongsTo<Project>;

  @belongsTo('filler-material-certificate')
  declare certificate: AsyncBelongsTo<FillerMaterialCertificate>;

  @attr('string')
  declare batchNumber?: string;

  @attr('string')
  declare fillerMaterialLabel?: string;

  @attr('string')
  declare fillerMaterialCommercialDesignationLabel?: string;

  @attr('string')
  declare pdfPath?: string;

  @attr('string')
  declare referenceType?: string;

  get visualLabel(): string {
    return this.batchNumber || '';
  }

  validations = {
    project: { presence: true },
    certificate: { presence: true },
  };

  metadata = {
    modelName: 'project-filler-material-certificate',
    project: { required: true },
    certificate: { required: true },
  };

  get isManualReference(): boolean {
    return this.referenceType === PROJECT_REFERENCE_TYPE.MANUAL;
  }

  get isAutomaticReference(): boolean {
    return this.referenceType === PROJECT_REFERENCE_TYPE.REFERENCE;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-filler-material-certificate': ProjectFillerMaterialCertificate;
  }
}
