import classic from 'ember-classic-decorator';
import Service, { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { assert } from '@ember/debug';
import ENV from 'weldnote/config/environment';
import EVENTS from 'weldnote/utils/analytics-events';

const GOOGLE_ANALYTICS = 'GoogleAnalytics';
const MIX_PANEL = 'Mixpanel';

@classic
export default class AnalyticsService extends Service {
  @service
  metrics;

  init() {
    super.init(...arguments);
    if (ENV.environment === 'test') {
      this.metrics.set('enabled', false);
    }
  }

  trackPage(options) {
    let { metrics } = this;
    if (!isEmpty(metrics)) {
      // We only send pages navigation to Google Analytics
      metrics.trackPage(GOOGLE_ANALYTICS, options);
    }
  }

  trackEvent(name, options = {}) {
    assert('Must pass a valid event name', !isEmpty(name));
    let { metrics } = this;
    if (!isEmpty(metrics)) {
      Object.assign(options, { event: name });
      if (metrics.enabled) {
        // We only send events to Mixpanel
        metrics.trackEvent(MIX_PANEL, options);
      } else {
        console.log(`Tracking Event ${name} with options ${JSON.stringify(options)}`);
      }
    }
  }

  identify(id, options) {
    let { metrics } = this;
    if (!isEmpty(metrics)) {
      if (options.email) {
        metrics.set('context.$email', options.email);
      }
      if (options.name) {
        metrics.set('context.$name', options.name);
      }
      if (options.plan) {
        metrics.set('context.plan', options.plan);
      }
      if (options.typeOfPlan) {
        metrics.set('context.typeOfPlan', options.typeOfPlan);
      }
      if (options.client) {
        metrics.set('context.client', options.client);
      }
      let newOptions = {};
      Object.assign(newOptions, options, { distinctId: id });
      metrics.identify(newOptions);
    }
  }

  alias(options) {
    let { metrics } = this;
    if (!isEmpty(metrics)) {
      this.metrics.alias(options);
    }
  }

  // Specific Events
  trackSaveNew(model, options = {}) {
    let context = {
      type: model,
      new: true,
    };
    Object.assign(context, options);
    this.trackEvent(EVENTS.SAVE, context);
  }

  trackDelete(model, options = {}) {
    let context = {
      type: model,
    };
    Object.assign(context, options);
    this.trackEvent(EVENTS.DELETE, context);
  }
}
