import Model, { attr } from '@ember-data/model';

export default class Person extends Model {
  @attr('string')
  declare sourceId: string;

  @attr('string')
  declare name: string;

  @attr('string')
  declare stamp: string;

  @attr('string')
  declare personType: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    person: Person;
  }
}
