import { isEmpty } from '@ember/utils';
import moment from 'moment';
import WelderCertificate from 'weldnote/models/welder-certificate';
import NonSupportedApprovalRange from './non-supported';

export default class Iso96062ApprovalRanges extends NonSupportedApprovalRange {
  certificate: WelderCertificate;

  constructor(certificate: WelderCertificate, _allData: any) {
    super();
    this.certificate = certificate;
  }

  internalDeadline(previousDate: Date | null) {
    if (isEmpty(previousDate)) {
      return null;
    }

    let newDate = moment(previousDate).add(6, 'M');
    return newDate.toDate();
  }

  externalDeadline(previousDate: Date | null) {
    if (isEmpty(previousDate)) {
      return null;
    }

    let requalificationPeriod = this.certificate.get('requalificationPeriod');
    if (!isNaN(parseInt(`${requalificationPeriod}`))) {
      let newDate = moment(previousDate).add(parseInt(`${requalificationPeriod}`), 'y');
      return newDate.toDate();
    }

    return null;
  }
}
