import { isStandardISO, isStandardASME, isStandardAWS } from 'weldnote/utils/standards';
import { isEmpty } from '@ember/utils';

import { allGTAW } from 'weldnote/utils/rules/welding-process';

import Constants from 'weldnote/utils/constants';

import { formatRangeWith2Decimals } from 'weldnote/utils/unit-system/format';
import { NumericalRange } from 'weldnote/src-weldnote/wopq/approval-ranges/wopq-range-interface';
import PqrStandard from 'weldnote/models/pqr-standard';

const { UNIT_SYSTEM } = Constants;

export function calculatePreHeatApprovalRange(
  preHeat: number,
  {
    standard,
    impactRequirements,
    processes,
    unitSystem = UNIT_SYSTEM.METRIC,
  }: {
    standard: PqrStandard;
    impactRequirements: boolean;
    processes: any;
    unitSystem: string;
  }
) {
  let result: NumericalRange = {
    min: null,
    max: null,
  };

  if (isEmpty(preHeat)) {
    return result;
  }

  if (isStandardISO(standard)) {
    result.min = preHeat - 50;
    result.max = null;
  } else if (isStandardASME(standard)) {
    if (unitSystem === UNIT_SYSTEM.METRIC) {
      result.min = Math.max(0, preHeat - 55);
      if (impactRequirements) {
        result.max = preHeat + 55;
      } else {
        result.max = null;
      }
    } else if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
      result.min = Math.max(0, preHeat - 100);
      if (impactRequirements) {
        result.max = preHeat + 55;
      } else {
        result.max = null;
      }
    }
  } else if (isStandardAWS(standard)) {
    let { root, fill, cap } = processes;
    if (allGTAW(root, fill, cap)) {
      result.min = preHeat - 55;
    } else {
      result.min = preHeat - 15;
    }
    if (impactRequirements) {
      result.max = preHeat + 56;
    }
  }

  return fixDecimals(result, unitSystem);
}

function fixDecimals(result: NumericalRange, unitSystem = UNIT_SYSTEM.METRIC) {
  if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
    return result;
  }
  return formatRangeWith2Decimals(result);
}
