import { computed } from '@ember/object';
import { attr } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';

export default class PipingPiece extends BaseModel {
  @attr('decimal-value')
  declare diameter?: number;

  @attr('decimal-value')
  declare thickness?: number;

  @attr('decimal-value')
  declare diameterInches?: number;

  @attr('decimal-value')
  declare thicknessInches?: number;

  @attr('string')
  declare schedule?: string;

  @attr('string')
  declare nominalDiameter?: string;

  @attr('boolean')
  declare system?: boolean;

  @computed('diameter', 'thickness', 'schedule')
  get visualLabel() {
    let label = `${this.diameter} - ${this.thickness}`;
    if (!isEmpty(this.schedule)) {
      label = `${label} - ${this.schedule}`;
    }
    return label;
  }

  validations = {
    diameter: {
      presence: {
        message(_key: string, _value: any, model: any): string {
          return model.translate('generic.error.input-value');
        },
      },

      numericality: {
        allowBlank: false,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 99999.99,
      },
    },

    thickness: {
      presence: {
        message(_key: string, _value: any, model: any): string {
          return model.translate('generic.error.input-value');
        },
      },

      numericality: {
        allowBlank: false,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    diameterInches: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 999.99,
      },
    },
  };

  metadata = {
    modelName: 'piping-piece',
    diameter: { required: true },
    thickness: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'piping-piece': PipingPiece;
  }
}
