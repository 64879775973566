import { service } from '@ember/service';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';

export default class ListController extends Controller {
  @service
  declare intl: IntlService;

  @service
  declare router: RouterService;

  /**
   * @private
   * The name of the model associated with this controller
   * @type {String}
   */
  modelName: string | null = null;

  /**
   * @private
   * The colum definition for the grid
   * @type {[array]}
   */
  columns: [] | null = null;

  /**
   * @private
   * Retrieves the localized label of a given attribute of the model
   * @param  {string} attribute [The attribute name]
   * @return {string}           [The localized label of the attribute]
   */
  getAttributeLabel(attribute: string, options = {}): string {
    let labelPath = `model.${this.modelName}.${attribute}.label`;
    return this.intl.t(labelPath, options);
  }

  getLabel(name: string): string {
    let labelPath = `model.${this.modelName}.${name}`;
    return this.intl.t(labelPath);
  }

  getModelPluralLabel(): string {
    return this.getLabel('model-plural');
  }

  @computed('modelName')
  get breadcrumbs(): any {
    return [
      {
        label: this.getModelPluralLabel(),
      },
    ];
  }

  get routeTitle() {
    return this.getModelPluralLabel();
  }

  get newRoutePath() {
    return `${this.modelName}.new`;
  }

  /**
   * @public
   * Transitions to the model's edit screen
   *
   * @param  {number} id [The model instance identifier]
   */
  @action
  goToModel(id: string) {
    if (this.modelName) {
      this.router.transitionTo(this.modelName, id);
    }
  }
}
