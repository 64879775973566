import { isEmpty } from '@ember/utils';

export function getFilenameWithoutExtension(filename: string = ''): string {
  if (isEmpty(filename)) {
    return '';
  }
  if (filename.lastIndexOf('.') > 0) {
    return filename.substring(0, filename.lastIndexOf('.'));
  } else {
    return filename;
  }
}
