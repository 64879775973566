import { action } from '@ember/object';
import NewController from 'weldnote/controllers/_base/new';
import { service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

export default class _NewController extends NewController {

  @tracked
  model: DS.Model | null = null;

  @service
  declare router: RouterService;

  @action
  createdBook(id: string) {
    this.router.transitionTo('welding-book', id);
  }

  @action
  cancelWeldingBook() {
    this.router.transitionTo('welding-books');
    if (this.model) {
      this.model.deleteRecord();
    }
  }
}
