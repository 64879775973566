import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';

export default class WeldingBookWeld extends BaseModel {
  @attr('string')
  weldNumber;

  @attr('number')
  baseMaterial1Thickness;

  @attr('number')
  baseMaterial1Diameter;

  @attr('number')
  baseMaterial2Thickness;

  @attr('number')
  baseMaterial2Diameter;

  @belongsTo('welding-procedure-specification')
  wps;

  @belongsTo('welding-book')
  weldingBook;

  @attr('number')
  orderNum;

  @attr()
  visualData;

  @hasMany('welding-book-weld-inspection', { async: false })
  inspections;

  @alias('weldNumber')
  visualLabel;

  validations = {
    weldNumber: { presence: true },
    baseMaterial1Thickness: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    baseMaterial1Diameter: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    baseMaterial2Thickness: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    baseMaterial2Diameter: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
      },
    },

    weldingBook: {
      // Ideally, we wanted to validate the presence of a weld. But it is giving an unexpected
      // "weld id is null" error in some cases. Making the relationship async will make it work,
      // but it's not what we want here. In official documentation it is only mentioned that it
      // works on async relationships: https://github.com/esbanarango/ember-model-validator#presence
      // presence: {
      //   message() {
      //     return this.translate('generic.error.input-value');
      //   },
      // },
    },

    orderNum: {
      numericality: {
        allowBlank: false,
        onlyInteger: true,
      },
    },
  };

  metadata = {
    modelName: 'welding-book-weld',
    weldNumber: { required: true },
    weldingBook: { required: true },
    orderNum: { required: true },
  };

  @computed(
    'weldNumber',
    'baseMaterial1Thickness',
    'baseMaterial2Thickness',
    'baseMaterial1Diameter',
    'baseMaterial2Diameter',
    'wps',
    'inspections'
  )
  get isEmpty() {
    let allEmpty =
      !isEmpty(this.weldNumber) &&
      isEmpty(this.baseMaterial1Thickness) &&
      isEmpty(this.baseMaterial2Thickness) &&
      isEmpty(this.baseMaterial1Diameter) &&
      isEmpty(this.baseMaterial2Diameter) &&
      isEmpty(this.get('wps.id')) &&
      isEmpty(this.inspections);
    return allEmpty;
  }

  @computed('visualData')
  get hasAssociatedPqr() {
    return (
      this.visualData?.pqrRootId ||
      this.visualData?.pqrFillId ||
      this.visualData?.pqrCapId ||
      this.visualData?.pqrPcId
    );
  }

  @computed('visualData')
  get hasRootPqr() {
    return this.visualData?.pqrRootId;
  }

  @computed('visualData')
  get hasUniqueFillPqr() {
    return this.visualData?.pqrFillId && this.visualData?.pqrRootId !== this.visualData?.pqrFillId;
  }

  @computed('visualData')
  get hasUniqueCapPqr() {
    return (
      this.visualData?.pqrCapId &&
      this.visualData?.pqrRootId !== this.visualData?.pqrCapId &&
      this.visualData?.pqrFillId !== this.visualData?.pqrCapId
    );
  }
}
