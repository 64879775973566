import { task, timeout } from 'ember-concurrency';
import Service, { service } from '@ember/service';
import DS from 'ember-data';
import WelderStandard from 'weldnote/models/welder-standard';
import MutableArray from '@ember/array/mutable';
import { tracked } from '@glimmer/tracking';
import {
  isStandardASME,
  isStandardAWSB21,
  isStandardAWSD11,
  isStandardAWSD143,
  isWelderStandardISO14732,
} from 'weldnote/utils/standards';
import ProcessMecanization from 'weldnote/models/process-mecanization';
import {
  isAutomaticMecanization,
  isMechanizedMecanization,
} from 'weldnote/utils/rules/process-mecanization';
import {
  AutomaticArcVoltageControl,
  AutomaticJointTracking,
  VisualControl,
  WeldingOperatorSpecificWeldingDetails,
} from 'weldnote/src-weldnote/wopq/types';
import IntlService from 'ember-intl/services/intl';
import { WeldTypeGroup } from 'weldnote/config/types/weld-type-group';
import TestInspectionClass from 'weldnote/models/test-inspection-class';
import IndustryCode from 'weldnote/models/industry-code';
import PqrStandard from 'weldnote/models/pqr-standard';
import WeldType from 'weldnote/models/weld-type';
import WeldingProcess from 'weldnote/models/welding-process';
import BaseMaterialGroup from 'weldnote/models/base-material-group';
import FillerMaterialGroup from 'weldnote/models/filler-material-group';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import WeldingDetail from 'weldnote/models/welding-detail';
import WeldingPosition from 'weldnote/models/welding-position';
import TypeCurrentPolarity from 'weldnote/models/type-current-polarity';
import ElectrodeType from 'weldnote/models/electrode-type';
import FluxSpecification from 'weldnote/models/flux-specification';
import Staff from 'weldnote/models/staff';
import GasSpecification from 'weldnote/models/gas-specification';
import PreparationMethod from 'weldnote/models/preparation-method';
import PostWeldTreatmentMethod from 'weldnote/models/post-weld-treatment-method';
import WeldingResource from 'weldnote/models/welding-resource';

export default class WeldcloudNotesData extends Service.extend({
  // anything which *must* be merged to prototype here
}) {
  @service
  declare store: DS.Store;

  @service
  declare intl: IntlService;

  @tracked
  private _welderStandards: WelderStandard[] = [];

  @tracked
  private _standards: PqrStandard[] = [];

  @tracked
  private _processMecanizations: ProcessMecanization[] = [];

  @tracked
  private _industryCodes: IndustryCode[] = [];

  @tracked
  private _weldTypes: WeldType[] = [];

  @tracked
  private _weldingProcesses: WeldingProcess[] = [];

  @tracked
  private _baseMaterialGroups: BaseMaterialGroup[] = [];

  @tracked
  private _fillerMaterialGroups: FillerMaterialGroup[] = [];

  @tracked
  private _arcTransferModes: ArcTransferMode[] = [];

  @tracked
  private _weldingDetails: WeldingDetail[] = [];

  @tracked
  private _weldingPositions: WeldingPosition[] = [];

  @tracked
  private _typeCurrentPolarities: TypeCurrentPolarity[] = [];

  @tracked
  private _electrodeTypes: ElectrodeType[] = [];

  @tracked
  private _fluxSpecifications: FluxSpecification[] = [];

  @tracked
  private _gasSpecifications: GasSpecification[] = [];

  @tracked
  private _preparationMethods: PreparationMethod[] = [];

  @tracked
  private _postWeldHeatTreatmentMethods: PostWeldTreatmentMethod[] = [];

  @tracked
  private _staff: Staff[] = [];

  fetchAllData = task({ drop: true }, async () => {
    if (this.isPrimaryDataLoaded) {
      return;
    }
    await this.fetchIndustryCodes.perform();
    await this.fetchStandards.perform();
    await this.fetchWelderStandards.perform();
    await this.fetchWeldingData.perform();
  });

  fetchSecondaryData = task({ drop: true }, async () => {
    if (this.isSecondaryDataLoaded) {
      return;
    }
    await this.fetchStaff.perform();
    await this.fetchPreparationMethods.perform();
  });

  get isPrimaryDataLoaded(): boolean {
    return (
      this.industryCodes.length > 0 &&
      this.pqrStandards.length > 0 &&
      this.welderStandards.length > 0 &&
      this.weldingDetails.length > 0 &&
      this.weldTypes.length > 0 &&
      this.baseMaterialGroups.length > 0 &&
      this.fillerMaterialGroups.length > 0 &&
      this.arcTransferModes.length > 0 &&
      this.electrodeTypes.length > 0 &&
      this.typeCurrentPolarities.length > 0 &&
      this.weldingProcesses.length > 0 &&
      this.processMecanizations.length > 0 &&
      this.gasSpecifications.length > 0 &&
      this.fluxSpecifications.length > 0 &&
      this.postWeldHeatTreatmentMethods.length > 0
    );
  }

  get isSecondaryDataLoaded(): boolean {
    return this.staff.length > 0 && this.preparationMethods.length > 0;
  }

  fetchWelderStandards = task(
    {
      drop: true,
    },
    async () => {
      if (this._welderStandards?.length === 0) {
        let data: MutableArray<WelderStandard> = await this.store.query('welder-standard', {
          'paging[limit]': 50,
        });
        let sortedStandards = data.sortBy('standard');
        this._welderStandards = sortedStandards;
        return sortedStandards;
      }
      return this._welderStandards;
    }
  );

  fetchWeldingData = task(
    {
      drop: true,
    },
    async () => {
      let result: MutableArray<WeldingResource> = await this.store.findAll('welding-resource', {
        include:
          'weld-types,arc-transfer-modes,welding-details,welding-positions,base-material-groups,filler-material-groups,electrode-types,type-current-polarities,welding-processes,process-mecanizations,preparation-methods,gas-specifications,flux-specifications,post-weld-heat-treatment-methods',
      });
      let data: WeldingResource | undefined = result.firstObject;
      if (data) {
        this._weldTypes = data.weldTypes.toArray();
        this._arcTransferModes = data.arcTransferModes.toArray();
        this._weldingDetails = data.weldingDetails.toArray();
        this._weldingPositions = data.weldingPositions.toArray();
        this._baseMaterialGroups = data.baseMaterialGroups.toArray();
        this._fillerMaterialGroups = data.fillerMaterialGroups.toArray();
        this._electrodeTypes = data.electrodeTypes.toArray();
        this._typeCurrentPolarities = data.typeCurrentPolarities.toArray();
        this._weldingProcesses = data.weldingProcesses.toArray();
        this._processMecanizations = data.processMecanizations.toArray();
        this._preparationMethods = data.preparationMethods.toArray();
        this._gasSpecifications = data.gasSpecifications.toArray();
        this._fluxSpecifications = data.fluxSpecifications.toArray();
        this._postWeldHeatTreatmentMethods = data.postWeldHeatTreatmentMethods.toArray();
      }
    }
  );

  fetchStandards = task(
    {
      drop: true,
    },
    async () => {
      if (this._standards?.length === 0) {
        let data: MutableArray<PqrStandard> = await this.store.query('pqr-standard', {
          'paging[limit]': 50,
        });
        let sortedStandards = data.sortBy('standard');
        this._standards = sortedStandards;
        return sortedStandards;
      }
      return this._standards;
    }
  );

  fetchProcessMecanizations = task(
    {
      drop: true,
    },
    async () => {
      if (this._processMecanizations?.length === 0) {
        let data: MutableArray<ProcessMecanization> = await this.store.query(
          'process-mecanization',
          { 'paging[limit]': 50 }
        );
        let sortedMecanizations = data.sortBy('name');
        this._processMecanizations = sortedMecanizations;
        return sortedMecanizations;
      }
      return this.processMecanizations;
    }
  );

  fetchPreparationMethods = task({ drop: true }, async () => {
    if (this._preparationMethods.length === 0) {
      let data = await this.store.query('preparation-method', { 'paging[limit]': 50 });
      let sortedData = data.sortBy('method');
      this._preparationMethods = sortedData;
      return sortedData;
    }
    return this._preparationMethods;
  });

  fetchIndustryCodes = task(
    {
      drop: true,
    },
    async () => {
      if (this._industryCodes?.length === 0) {
        let data: MutableArray<IndustryCode> = await this.store.query('industry-code', {
          'paging[limit]': 50,
        });
        let sortedIndustryCodes = data.sortBy('code');
        this._industryCodes = sortedIndustryCodes;
        return sortedIndustryCodes;
      }
      return this.processMecanizations;
    }
  );

  fetchStaff = task({ drop: true }, async () => {
    let data = await this.store.query('staff', { 'paging[limit]': 300 });
    let sortedData = data.sortBy('name');
    this._staff = sortedData;
    return sortedData;
  });

  findWelder = task(
    {
      restartable: true,
    },
    async (options = {}) => {
      await timeout(250);
      let query: any = {};
      this._parsePagingInformation(query, options);
      if (options.name) {
        query['filter[welder-name]'] = options.name;
        query['filter[welder-stamp]'] = options.name;
      }
      if (options.company) {
        query['filter[company.id]'] = options.company;
      }
      if (options.type) {
        query['filter[person-type]'] = options.type;
      }

      return this.store.query('welder', query);
    }
  );

  get industryCodes(): IndustryCode[] {
    return this._industryCodes;
  }

  get welderStandards(): WelderStandard[] {
    return this._welderStandards;
  }

  get pqrStandards(): PqrStandard[] {
    return this._standards;
  }

  get weldTypes(): WeldType[] {
    return this._weldTypes;
  }

  get weldingProcesses(): WeldingProcess[] {
    return this._weldingProcesses;
  }

  get weldingPositions(): WeldingPosition[] {
    return this._weldingPositions;
  }

  get arcTransferModes(): ArcTransferMode[] {
    return this._arcTransferModes;
  }

  get baseMaterialGroups(): BaseMaterialGroup[] {
    return this._baseMaterialGroups;
  }

  get fillerMaterialGroups(): FillerMaterialGroup[] {
    return this._fillerMaterialGroups;
  }

  get weldingDetails(): WeldingDetail[] {
    return this._weldingDetails;
  }

  get electrodeTypes(): ElectrodeType[] {
    return this._electrodeTypes;
  }

  get typeCurrentPolarities(): TypeCurrentPolarity[] {
    return this._typeCurrentPolarities;
  }

  get welderCertificateStandards() {
    return this._welderStandards.filter((standard: WelderStandard) => {
      if (isWelderStandardISO14732(standard)) {
        return false;
      }
      return true;
    });
  }

  get weldingOperatorStandards() {
    return this._welderStandards.filter((standard) => {
      return (
        isWelderStandardISO14732(standard) ||
        isStandardASME(standard) ||
        isStandardAWSB21(standard) ||
        isStandardAWSD11(standard) ||
        isStandardAWSD143(standard)
      );
    });
  }

  get fluxSpecifications() {
    return this._fluxSpecifications;
  }

  get gasSpecifications() {
    return this._gasSpecifications;
  }

  get preparationMethods() {
    return this._preparationMethods;
  }

  get postWeldHeatTreatmentMethods() {
    return this._postWeldHeatTreatmentMethods;
  }

  get staff() {
    return this._staff;
  }

  findInspectionClasses = task(
    {
      drop: true,
    },
    async (term: string) => {
      await timeout(300);
      let data: MutableArray<TestInspectionClass> | null = await this.store.query(
        'test-inspection-class',
        {
          'filter[name]': term,
          include:
            'test-inspection-class,test-inspection-class.stages,test-inspection-class.stages.location,test-inspection-class.stages.name,test-inspection-class.stages.tests.type-of-test',
        }
      );
      return data;
    }
  );

  get processMecanizations(): ProcessMecanization[] {
    return this._processMecanizations;
  }

  get processMecanizationsForWeldingOperators() {
    return this.processMecanizations?.filter(
      (mecanization) =>
        isAutomaticMecanization(mecanization) || isMechanizedMecanization(mecanization)
    );
  }

  get arcVoltageControlOptions() {
    let { intl } = this;
    let withLabel = intl.t('lov.arc-voltage.with');
    let withoutLabel = intl.t('lov.arc-voltage.without');
    return [
      {
        key: AutomaticArcVoltageControl.WITH,
        label: withLabel,
      },
      {
        key: AutomaticArcVoltageControl.WITHOUT,
        label: withoutLabel,
      },
    ];
  }

  get jointTrackingOptions() {
    let { intl } = this;
    let withLabel = intl.t('lov.joint-tracking.with');
    let withoutLabel = intl.t('lov.joint-tracking.without');
    return [
      {
        key: AutomaticJointTracking.WITH,
        label: withLabel,
      },
      {
        key: AutomaticJointTracking.WITHOUT,
        label: withoutLabel,
      },
    ];
  }

  get visualControlOptions() {
    let { intl } = this;
    let directLabel = intl.t('lov.visual-control.direct');
    let remoteLabel = intl.t('lov.visual-control.remote');
    return [
      {
        key: VisualControl.DIRECT,
        label: directLabel,
      },
      {
        key: VisualControl.REMOTE,
        label: remoteLabel,
      },
    ];
  }

  get weldingOperatorWeldingDetailsOptions() {
    let { intl } = this;
    let arcLabel = intl.t('lov.welding-detail-operator.arc');
    let jointLabel = intl.t('lov.welding-detail-operator.joint');
    let noneLabel = intl.t('lov.welding-detail-operator.none');
    return [
      {
        key: `${WeldingOperatorSpecificWeldingDetails.ARC}`,
        label: arcLabel,
      },
      {
        key: `${WeldingOperatorSpecificWeldingDetails.JOINT}`,
        label: jointLabel,
      },
      {
        key: `${WeldingOperatorSpecificWeldingDetails.NONE}`,
        label: noneLabel,
      },
    ];
  }

  get weldTypeGroupsOptions() {
    let { intl } = this;
    return [
      {
        key: WeldTypeGroup.ALL,
        label: intl.t('lov.weld-type-group.ALL'),
      },
      {
        key: WeldTypeGroup.FULL_PENETRATION_BUTT_WELD,
        label: intl.t('lov.weld-type-group.FPBW'),
      },
      {
        key: WeldTypeGroup.PARTIAL_PENETRATION_BUTT_WELD,
        label: intl.t('lov.weld-type-group.PPBW'),
      },
      {
        key: WeldTypeGroup.FILLET_WELD,
        label: intl.t('lov.weld-type-group.FW'),
      },
      {
        key: WeldTypeGroup.OVERLAY_WELD,
        label: intl.t('lov.weld-type-group.OW'),
      },
    ];
  }

  bustStaffCache() {
    this._staff = [];
    this.fetchStaff.perform();
  }
}

declare module '@ember/service' {
  interface Registry {
    'weldcloud-notes-data': WeldcloudNotesData;
  }
}
