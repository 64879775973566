import { A as emberA, isArray } from '@ember/array';

export function setDiff(setA, setB) {
  if (!isArray(setA)) {
    return emberA();
  }
  if (!isArray(setB)) {
    return emberA(setA);
  }

  return setA.filter((x) => setB.indexOf(x) === -1);
}
