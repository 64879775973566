import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"form-group\">\n  <label class=\"control-label\">{{this.labelReference}}</label>\n  <p class=\"form-control-static\">\n    {{#if (has-block)}}\n      {{yield (get @model @attribute)}}\n    {{else}}\n      {{get @model @attribute}}\n    {{/if}}\n  </p>\n  {{#if @showEmptyHelpBlock}}\n    <span class=\"help-block\">&nbsp;</span>\n  {{/if}}\n</div>", {"contents":"<div class=\"form-group\">\n  <label class=\"control-label\">{{this.labelReference}}</label>\n  <p class=\"form-control-static\">\n    {{#if (has-block)}}\n      {{yield (get @model @attribute)}}\n    {{else}}\n      {{get @model @attribute}}\n    {{/if}}\n  </p>\n  {{#if @showEmptyHelpBlock}}\n    <span class=\"help-block\">&nbsp;</span>\n  {{/if}}\n</div>","moduleName":"weldnote/components/pqr-model-value.hbs","parseOptions":{"srcName":"weldnote/components/pqr-model-value.hbs"}});
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';

export default class PqrModelValue extends Component {
  @service
  intl;

  get labelReference() {
    let { intl } = this;
    let { attribute, label, labelParams } = this.args;
    if (!isEmpty(label)) {
      if (!isEmpty(labelParams)) {
        return intl.t(`model.pqr.${label}.label`, labelParams);
      } else {
        return intl.t(`model.pqr.${label}.label`);
      }
    }
    if (!isEmpty(labelParams)) {
      return intl.t(`model.pqr.${attribute}.label`, labelParams);
    } else {
      return intl.t(`model.pqr.${attribute}.label`);
    }
  }
}
