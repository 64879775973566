import { service } from '@ember/service';
import { set, get } from '@ember/object';
import ListRoute from 'weldnote/routes/_base/list';

export default class ProjectDocumentsRoute extends ListRoute {
  @service('weldnote-data')
  data;

  model() {
    return this.data.listAllProjectDocuments();
  }

  setupController(controller, model) {
    set(controller, 'model', model);
    set(controller, 'totalCount', model.meta.records);
  }
}
