import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { get } from '@ember/object';
import NewRoute from 'weldnote/routes/_base/new';

@classic
export default class _NewRoute extends NewRoute {
  @service
  userSession;

  @service
  router;

  modelName = 'welding-position';

  beforeModel() {
    super.beforeModel(...arguments);
    let session = this.userSession;
    if (!get(session, 'isAdmin')) {
      this.router.transitionTo('welding-positions');
    }
  }
}
