import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import ListController from 'weldnote/controllers/_base/list-js';
import { set } from '@ember/object';

@classic
export default class TypeCurrentPolaritiesController extends ListController {
  @service
  userSession;

  modelName = 'type-current-polarity';

  init() {
    super.init(...arguments);
    let typeCurrentLabel = this.getAttributeLabel('typeCurrent');
    set(this, 'columns', [
      {
        data: 'typeCurrent',
        title: typeCurrentLabel,
      },
    ]);
  }
}
