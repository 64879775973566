import Service from '@ember/service';
import { isEmpty } from '@ember/utils';

/** This service exist so that references to Window can be mocked in tests */
export default class WindowService extends Service {
  downloadFile(newLocation: string) {
    if (newLocation && !isEmpty(newLocation)) {
      window.location.assign(newLocation);
    }
  }
}
