import Service, { service } from '@ember/service';
import { AjaxServiceClass } from 'ember-ajax/services/ajax';
import ENV from 'weldnote/config/environment';
import fetch from 'fetch';

export declare interface PowerSourceList {
  total: number;
  list: PowerSource[];
}

export declare interface PowerSource {
  id: string;
  displayName: string;
}

const PAGE_SIZE = 20;

export default class WeldcloudData extends Service.extend({
  // anything which *must* be merged to prototype here
}) {
  @service
  declare session: any;

  @service
  declare ajax: AjaxServiceClass;

  get queryHeaders(): any {
    return {
      Authorization: `Bearer ${this.get('session.session.authenticated.accessToken')}`,
      'Ocp-Apim-Subscription-Key': ENV.azureSubscriptionManagementId,
    };
  }

  async listOfApplications() {
    let result = await fetch(`${ENV.orgApiUrl}/org/odata/users/me`, {
        headers: this.queryHeaders
      });
    let textResult = await result.text();
    let modules = JSON.parse(textResult)?.modules
    if (modules) {
      let applications = modules
        .split(',')
        .map((e: string) => e.toLowerCase())
        .map((e: String) => e.trim());
      return applications;
    }
    return [];
  }

  async queryMachines(filterName: string, page = 1): Promise<PowerSourceList> {
    let filterQuery = `&$filter=indexof(displayName, '${filterName}') gt - 1`;
    let pagination = `&$top=${PAGE_SIZE}&$skip=${(page - 1) * PAGE_SIZE}`;
    if (!filterName) {
      filterQuery = '';
    }
    let result = await this.ajax.request(
      `${ENV.orgApiUrl}/org/odata/assets?$select=id,displayName&$count=true${filterQuery}${pagination}`,
      {
        headers: this.queryHeaders,
      }
    );
    if (result.value) {
      let { value } = result;
      let finalResult: PowerSource[] = [];
      value.forEach((powerSource: { id: any; displayName: any }) => {
        finalResult.push({
          id: powerSource.id,
          displayName: powerSource.displayName,
        });
      });
      let total = result['@odata.count'];
      return {
        total,
        list: finalResult,
      };
    } else {
      return {
        total: 0,
        list: [],
      };
    }
  }

  async getMachineNameById(machineId: string): Promise<PowerSource | null> {
    if (!machineId) {
      return null;
    }
    let result = await this.ajax.request(
      `${ENV.orgApiUrl}/org/odata/assets/${machineId}?$select=displayName`,
      {
        headers: this.queryHeaders,
      }
    );
    return result;
  }
  // normal class body definition here
}

declare module '@ember/service' {
  interface Registry {
    'weldcloud-data': WeldcloudData;
  }
}
