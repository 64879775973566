
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import Service, { service } from '@ember/service';
import ENV from 'weldnote/config/environment';
import fetch from 'fetch';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

const { environment } = ENV;
const updateInterval = 5 * 60000; // 5 minutes

export default class NewVersionCheckerService extends Service {
  @service('alert-message')
  alert;

  @service
  intl;

  @tracked
  currentVersion = '';

  @tracked
  newVersion = false;

  @alias('newVersion')
  isNewVersion;

  isElementInViewport(el) {
    if (el) {
      let rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  }

  updateVersionTask = task(
    {
      drop: true,
    },
    async() => {
      while (true) {
        let response = (await fetch('/api/currentVersion'));
        if (!response.ok) {
          await timeout(updateInterval);
        } else {
          let newVersion = await response.text();
          if (!isEmpty(this.currentVersion) && this.currentVersion !== newVersion) {
            this.newVersion = true;
            this.currentVersion = newVersion;
            if (!this.isElementInViewport(document.getElementById('new-version-notifier'))) {
              let message = this.intl.t('components.new-version-notifier.message').toString();
              let title = this.intl.t('components.new-version-notifier.update-title').toString();
              this.alert.info(message, title, { timeOut: 15000, extendedTimeOut: 25000 });
            }
          }
          this.currentVersion = newVersion;
          await timeout(updateInterval)
        }
    }
  })
}
