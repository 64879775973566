import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (eq @select.results.length this.maxPageSize)}}\n  <ShowSearchMessageMoreResults />\n{{/if}}\n\n{{#if (has-value @fillerMaterial)}}\n  <span class=\"show-search-message-more-results\">\n    {{t\n      \"components.model-filler-material-commercial-designation/creator.filtered-results\"\n      fillerMaterial=@fillerMaterial.visualLabel\n    }}\n  </span>\n{{/if}}", {"contents":"{{#if (eq @select.results.length this.maxPageSize)}}\n  <ShowSearchMessageMoreResults />\n{{/if}}\n\n{{#if (has-value @fillerMaterial)}}\n  <span class=\"show-search-message-more-results\">\n    {{t\n      \"components.model-filler-material-commercial-designation/creator.filtered-results\"\n      fillerMaterial=@fillerMaterial.visualLabel\n    }}\n  </span>\n{{/if}}","moduleName":"weldnote/components/generic-filler-material-commercial-designation/after-options.hbs","parseOptions":{"srcName":"weldnote/components/generic-filler-material-commercial-designation/after-options.hbs"}});
import Component from '@glimmer/component';
import { service } from '@ember/service';

export default class GenericFillerMaterialCommercialDesignationAfterOptionsComponent extends Component {
  @service
  weldnoteData;

  get maxPageSize() {
    return this.weldnoteData.pageSize;
  }
}
