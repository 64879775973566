import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';
import Constants from 'weldnote/utils/constants';
import CertificateFillerRules from 'weldnote/utils/certificate-filler-material-rules-asme';
import CertificateBaseMaterialRules from 'weldnote/utils/welder-certificate-base-material-rules-asme';
import {
  isButtWeld,
  isOverlayWeld,
  isFilletWeld,
  isPartialPenetrationButtWeld,
} from 'weldnote/utils/rules/weld-type';
import { calculateShielding } from 'weldnote/utils/certification-rules';
import moment from 'moment';
import WelderCertificate from 'weldnote/models/welder-certificate';
import ElectrodeType from 'weldnote/models/electrode-type';
import FillerMaterial from 'weldnote/models/filler-material';
import FillerMaterialGroup from 'weldnote/models/filler-material-group';
import WeldingPosition from 'weldnote/models/welding-position';
import WeldType from 'weldnote/models/weld-type';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import WeldingDetail from 'weldnote/models/welding-detail';
import BaseMaterial from 'weldnote/models/base-material';
import BaseMaterialGroup from 'weldnote/models/base-material-group';
import IndustryCode from 'weldnote/models/industry-code';
import WeldingProcess from 'weldnote/models/welding-process';
import ProcessMecanization from 'weldnote/models/process-mecanization';
import {
  NumericalRange,
  WelderCertificateApprovalRange,
} from 'weldnote/src-weldnote/wopq/approval-ranges/wopq-range-interface';
import {
  AutomaticJointTracking,
  TestPieceValuesSingleProcess,
  AutomaticArcVoltageControl,
  VisualControl,
  WeldingOperatorSpecificWeldingDetails,
} from 'weldnote/src-weldnote/wopq/types';

const { UNIT_SYSTEM, ARC_TRANSFER_MODES, WELDING_DETAILS, PRODUCT_TYPE } = Constants;

export default class CertificateAsmeIxApprovalRanges implements WelderCertificateApprovalRange {
  private certificate: WelderCertificate;

  private unitSystem: string;

  private allElectrodes: ElectrodeType[];

  private allWeldingDetails: WeldingDetail[];

  private allBaseMaterials: BaseMaterialGroup[];

  private allTransferModes: ArcTransferMode[];

  private allWeldingPositions: WeldingPosition[];

  private allFillerMaterials: FillerMaterialGroup[];

  private allWeldTypes: WeldType[];

  constructor(certificate: WelderCertificate, data: any, unitSystem = UNIT_SYSTEM.METRIC) {
    this.certificate = certificate;
    this.unitSystem = unitSystem;
    this.allElectrodes = data.allElectrodes;
    this.allWeldingDetails = data.allWeldingDetails;
    this.allBaseMaterials = data.allBaseMaterials;
    this.allTransferModes = data.allTransferModes.filter(
      (transferMode: ArcTransferMode) =>
        this.getIndustryCode(get(transferMode, 'industryCode')) === this.industryCodeId
    );
    this.allWeldingPositions = data.allWeldingPositions.filter(
      (position: WeldingPosition) =>
        this.getIndustryCode(get(position, 'industryCode')) === this.industryCodeId
    );
    this.allFillerMaterials = data.allFillerMaterials.filter(
      (material: FillerMaterialGroup) =>
        this.getIndustryCode(get(material, 'code')) === this.industryCodeId
    );
    this.allWeldTypes = data.allWeldTypes.filter((weldType: WeldType) => {
      return this.getIndustryCode(get(weldType, 'industryCode')) === this.industryCodeId;
    });
  }

  automaticJointTracking(
    _testPiece: AutomaticJointTracking | null,
    _process: TestPieceValuesSingleProcess
  ): AutomaticJointTracking[] {
    return [];
  }

  automaticVoltageControl(
    _testPiece: AutomaticArcVoltageControl | null,
    _process: TestPieceValuesSingleProcess
  ): AutomaticArcVoltageControl[] {
    return [];
  }

  visualControl(
    _testPiece: VisualControl | null,
    _process: TestPieceValuesSingleProcess
  ): VisualControl[] {
    return [];
  }

  processMecanization(_testPiece: ProcessMecanization): ProcessMecanization[] {
    return [];
  }

  getIndustryCode(code: IndustryCode | undefined): string {
    if (code) {
      return get(code, 'id');
    }
    return '';
  }

  get industryCodeId() {
    let { standard } = this;
    let code = get(standard, 'code');
    if (code) {
      return get(code, 'id');
    }
    return '';
  }

  get standard() {
    return this.certificate.get('standard');
  }

  get diameterValue() {
    return this.certificate.get('diameterTestPiece');
  }

  get weldingProcessRootName() {
    let weldingProcess = this.certificate.get('weldingProcessRootTestPiece');
    if (!isEmpty(weldingProcess)) {
      return weldingProcess.shortDesignation;
    }
    return '';
  }

  get weldingProcessFillName() {
    let weldingProcess = this.certificate.get('weldingProcessFillTestPiece');
    if (!isEmpty(weldingProcess)) {
      return weldingProcess.shortDesignation;
    }
    return '';
  }

  get weldingProcessCapName() {
    let weldingProcess = this.certificate.get('weldingProcessCapTestPiece');
    if (!isEmpty(weldingProcess)) {
      return weldingProcess.shortDesignation;
    }
    return '';
  }

  get weldTypeTestPiece() {
    return this.certificate.get('weldTypeTestPiece');
  }

  get weldTypeCode() {
    let weldType = this.certificate.get('weldTypeTestPiece');
    if (!isEmpty(weldType)) {
      return weldType.weldType;
    }
    return '';
  }

  get supplementaryFilletWeldTestRequired() {
    return this.certificate.get('supplementaryFilletWeldTestRequired');
  }

  get requalificationPeriod() {
    return this.certificate.get('requalificationPeriod');
  }

  electrodeTypeRoot(electrode: ElectrodeType): ElectrodeType[] {
    return this.electrodeType(electrode);
  }

  electrodeTypeFill(electrode: ElectrodeType): ElectrodeType[] {
    return this.electrodeType(electrode);
  }

  electrodeTypeCap(electrode: ElectrodeType): ElectrodeType[] {
    return this.electrodeType(electrode);
  }

  electrodeType(electrode: ElectrodeType): ElectrodeType[] {
    if (isEmpty(electrode)) {
      return [];
    }
    let { shortDesignation } = electrode;
    return this._filterElectrodeTypes([shortDesignation]);
  }

  _filterElectrodeTypes(electrodeCodes: string[]): ElectrodeType[] {
    let results: ElectrodeType[] = [];
    electrodeCodes.forEach((designation) => {
      let candidate = this.allElectrodes.findBy('shortDesignation', designation);
      if (candidate) {
        results.pushObject(candidate);
      }
    });
    return results;
  }

  get _emptyRange(): NumericalRange {
    return {
      min: null,
      max: null,
    };
  }

  get isFilletWeld() {
    return isFilletWeld(this.weldTypeTestPiece);
  }

  get isButtWeld() {
    return isButtWeld(this.weldTypeTestPiece);
  }

  get isOverlayWeld() {
    return isOverlayWeld(this.weldTypeTestPiece);
  }

  get isPartialPenetrationButtWeld() {
    return isPartialPenetrationButtWeld(this.weldTypeTestPiece);
  }

  get productType() {
    return this.certificate.get('productTypeTestPiece');
  }

  get isPipe() {
    let { productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PIPE;
    }
    return false;
  }

  get isMetric() {
    return this.unitSystem === UNIT_SYSTEM.METRIC;
  }

  get isImperial() {
    return this.unitSystem === UNIT_SYSTEM.IMPERIAL;
  }

  thickness(testPiece: number): NumericalRange {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    let result: NumericalRange = { min: null, max: null };
    if (this.isFilletWeld && this.isPipe) {
      if (this.isMetric) {
        result.min = 0;
        result.max = null;
      } else if (this.isImperial) {
        result.min = 0;
        result.max = null;
      }
    } else {
      if (this.isMetric) {
        if (testPiece < 13) {
          result.min = 0;
          result.max = 2 * testPiece;
        } else {
          result.min = 0;
          result.max = null;
        }
      } else if (this.isImperial) {
        if (testPiece < 0.5) {
          result.min = 0;
          result.max = 2 * testPiece;
        } else {
          result.min = 0;
          result.max = null;
        }
      }
    }
    return result;
  }

  weldType(testPiece: WeldType): WeldType[] {
    if (isEmpty(testPiece)) {
      return [];
    }

    let results: WeldType[] = [];
    results.pushObject(testPiece);

    if (this.isButtWeld) {
      results.pushObjects(
        this.allWeldTypes.filter(
          (weldType) => isFilletWeld(weldType) || isPartialPenetrationButtWeld(weldType)
        )
      );
    }

    if (isPartialPenetrationButtWeld(testPiece)) {
      results.pushObjects(this.allWeldTypes.filter((weldType) => isFilletWeld(weldType)));
    }

    if (this.isOverlayWeld) {
      results.pushObjects(this.allWeldTypes.filter((weldType) => isFilletWeld(weldType)));
    }

    return results;
  }

  transferMode(testPiece: ArcTransferMode): ArcTransferMode[] {
    if (isEmpty(testPiece)) {
      return [];
    }

    let arcModeName = testPiece.get('transferMode');
    if (
      arcModeName === ARC_TRANSFER_MODES.PULSED_SPRAY ||
      arcModeName === ARC_TRANSFER_MODES.GLOBULAR ||
      arcModeName === ARC_TRANSFER_MODES.SPRAY
    ) {
      return this.allTransferModes.filter((mode) => {
        if (
          mode.get('transferMode') === ARC_TRANSFER_MODES.SPRAY ||
          mode.get('transferMode') === ARC_TRANSFER_MODES.GLOBULAR ||
          mode.get('transferMode') === ARC_TRANSFER_MODES.PULSED_SPRAY
        ) {
          return true;
        }
        return false;
      });
    } else {
      return [testPiece];
    }
  }

  weldingDetails(testPiece: WeldingDetail[]): WeldingDetail[] {
    if (isEmpty(testPiece)) {
      return [];
    }

    let results: WeldingDetail[] = [];
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_NO_BACKING)) {
      results.pushObjects(
        this.allWeldingDetails.filter((detail) => {
          let { shortDesignation } = detail;
          return (
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_NO_BACKING ||
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING ||
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING ||
            shortDesignation === WELDING_DETAILS.BOTH_SIDES
          );
        })
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING)) {
      results.pushObjects(
        this.allWeldingDetails.filter((detail) => {
          let { shortDesignation } = detail;
          return (
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING ||
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING ||
            shortDesignation === WELDING_DETAILS.BOTH_SIDES
          );
        })
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING)) {
      results.pushObjects(
        this.allWeldingDetails.filter((detail) => {
          let { shortDesignation } = detail;
          return (
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING ||
            shortDesignation === WELDING_DETAILS.BOTH_SIDES
          );
        })
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_LAYER)) {
      results.pushObjects(
        this.allWeldingDetails.filter((detail) => {
          let { shortDesignation } = detail;
          return (
            shortDesignation === WELDING_DETAILS.MULTI_LAYER ||
            shortDesignation === WELDING_DETAILS.SINGLE_LAYER
          );
        })
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.MULTI_LAYER)) {
      results.pushObjects(
        this.allWeldingDetails.filter((detail) => {
          let { shortDesignation } = detail;
          return (
            shortDesignation === WELDING_DETAILS.MULTI_LAYER ||
            shortDesignation === WELDING_DETAILS.SINGLE_LAYER
          );
        })
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING)) {
      results.pushObjects(
        this.allWeldingDetails.filter((detail) => {
          let { shortDesignation } = detail;
          return (
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING ||
            shortDesignation === WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING ||
            shortDesignation === WELDING_DETAILS.BOTH_SIDES
          );
        })
      );
    }
    return results;
  }

  _filterBaseMaterials(wanted: string[]) {
    let results: BaseMaterialGroup[] = [];
    wanted.forEach((position) => {
      let candidate = this.allBaseMaterials.findBy('groupName', position);
      if (candidate) {
        results.pushObject(candidate);
      }
    });
    return results;
  }

  baseMaterial(testPiece: BaseMaterial): BaseMaterialGroup[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    let results = [];
    let baseMaterialGroupInstance = get(testPiece, 'materialGroup');
    let baseMaterialGroup = get(baseMaterialGroupInstance, 'groupName');
    if (CertificateBaseMaterialRules.isSteelAndNickelAlloys(baseMaterialGroup)) {
      results = this._filterBaseMaterials(
        CertificateBaseMaterialRules.getSteelAndNickelAlloysApprovalRange()
      );
    } else if (CertificateBaseMaterialRules.isAluminiumAlloys(baseMaterialGroup)) {
      results = this._filterBaseMaterials(
        CertificateBaseMaterialRules.getAluminiumAlloysApprovalRange()
      );
    } else if (CertificateBaseMaterialRules.isTitaniumAndZirconiumAlloys(baseMaterialGroup)) {
      results = this._filterBaseMaterials(
        CertificateBaseMaterialRules.getTitaniumAndZirconiumAlloysApprovalRange()
      );
    } else {
      results = this._filterBaseMaterials([baseMaterialGroup]);
    }
    return results;
  }

  weldingProcessRoot(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    return [process];
  }

  weldingProcessFill(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    return [process];
  }

  weldingProcessCap(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    return [process];
  }

  shielding(process: WeldingProcess) {
    if (isEmpty(process)) {
      return null;
    }

    return calculateShielding(process.shortDesignation);
  }

  diameter(testPiece: number): NumericalRange {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    let results = this._emptyRange;

    if (this.isMetric) {
      if (testPiece <= 25) {
        results.min = testPiece;
        results.max = null;
      } else if (testPiece > 25 && testPiece <= 73) {
        results.min = 25;
        results.max = null;
      } else {
        results.min = 73;
        results.max = null;
      }
    } else if (this.isImperial) {
      if (testPiece <= 1) {
        results.min = testPiece;
        results.max = null;
      } else if (testPiece > 1 && testPiece <= 2.875) {
        results.min = 1;
        results.max = null;
      } else if (testPiece > 2.875) {
        results.min = 2.875;
        results.max = null;
      }
    }
    return results;
  }

  _filterPositions(positions: string[]) {
    return this.allWeldingPositions.filter((position: WeldingPosition) => {
      let { code } = position;
      if (code) {
        return positions.indexOf(code) >= 0;
      } else {
        return false;
      }
    });
  }

  weldingPosition(testPiece: WeldingPosition): WeldingPosition[] {
    if (isEmpty(testPiece)) {
      return [];
    }

    let results = [];

    let weldingPosition = testPiece.code;
    if (!weldingPosition) {
      return [];
    }
    if (weldingPosition === '1G') {
      results = this._filterPositions(['1G', '1F']);
    } else if (weldingPosition === '2G') {
      results = this._filterPositions(['1G', '2G', '1F', '2F']);
    } else if (weldingPosition === '2G+3G+4G') {
      results = this._filterPositions([
        '1G',
        '2G',
        '3G',
        '4G',
        '5G',
        '6G',
        '1F',
        '2F',
        '3F',
        '4F',
        '5F',
      ]);
    } else if (weldingPosition === '3G') {
      results = this._filterPositions(['1G', '3G', '1F', '2F', '3F']);
    } else if (weldingPosition === '4G') {
      results = this._filterPositions(['1G', '4G', '1F', '2F', '4F']);
    } else if (weldingPosition === '3G+4G') {
      results = this._filterPositions(['1G', '3G', '4G', '1F', '2F', '3F', '4F', '5F']);
    } else if (weldingPosition === '5G') {
      results = this._filterPositions(['1G', '3G', '4G', '5G', '1F', '2F', '3F', '4F', '5F']);
    } else if (weldingPosition === '5G+2G') {
      results = this._filterPositions([
        '1G',
        '2G',
        '3G',
        '4G',
        '5G',
        '6G',
        '1F',
        '2F',
        '3F',
        '4F',
        '5F',
      ]);
    } else if (weldingPosition === '6G') {
      results = this._filterPositions([
        '1G',
        '2G',
        '3G',
        '4G',
        '5G',
        '6G',
        '1F',
        '2F',
        '3F',
        '4F',
        '5F',
      ]);
    } else if (weldingPosition === '6GR') {
      results = this._filterPositions([
        '1G',
        '2G',
        '3G',
        '4G',
        '5G',
        '6G',
        '6GR',
        '1F',
        '2F',
        '3F',
        '4F',
        '5F',
      ]);
    } else if (weldingPosition === '1F') {
      results = this._filterPositions(['1F']);
    } else if (weldingPosition === '2F') {
      results = this._filterPositions(['1F', '2F']);
    } else if (weldingPosition === '3F') {
      results = this._filterPositions(['1F', '2F', '3F']);
    } else if (weldingPosition === '4F') {
      results = this._filterPositions(['1F', '2F', '4F']);
    } else if (weldingPosition === '3F+4F') {
      results = this._filterPositions(['1F', '2F', '3F', '4F', '5F']);
    } else if (weldingPosition === '5F') {
      results = this._filterPositions(['1F', '2F', '3F', '4F', '5F']);
    } else {
      results = this._filterPositions([weldingPosition]);
    }
    return results;
  }

  _filterFillerMaterialGroups(groupToSelect: string[]) {
    let results: FillerMaterialGroup[] = [];
    groupToSelect.forEach((groupCode) => {
      let candidate = this.allFillerMaterials.findBy('groupName', groupCode);
      if (candidate) {
        results.pushObject(candidate);
      }
    });
    return results;
  }

  fillerMaterial(testPiece: FillerMaterial): FillerMaterialGroup[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    let group: FillerMaterialGroup = get(testPiece, 'group');
    let groupCode: string = '';
    if (group) {
      groupCode = get(group, 'groupName') || '';
    }

    let results: FillerMaterialGroup[] = [];
    if (CertificateFillerRules.isFillerMaterialGroup1(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial1Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroup2(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial1Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial2Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroup3(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial1Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial2Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial3Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroup4(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial1Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial2Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial3Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial4Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroup5(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial1Groups())
      );
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial5Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroup6(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial6Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroupRange30to40(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial30to40Groups())
      );
    } else if (CertificateFillerRules.isFillerMaterialGroup50(groupCode)) {
      results.pushObjects(
        this._filterFillerMaterialGroups(CertificateFillerRules.getFillerMaterial50Groups())
      );
    } else {
      results.pushObjects(this._filterFillerMaterialGroups([groupCode]));
    }
    return results;
  }

  internalDeadline(previousDate: Date): Date | null {
    if (isEmpty(previousDate)) {
      return null;
    }
    let newDate = moment(previousDate).add(6, 'M');
    return newDate.toDate();
  }

  externalDeadline(previousDate: Date): Date | null {
    if (isEmpty(previousDate)) {
      return null;
    }

    if (this.requalificationPeriod && !isNaN(parseInt(`${this.requalificationPeriod}`))) {
      let newDate = moment(previousDate).add(this.requalificationPeriod, 'y');
      return newDate.toDate();
    }

    return null;
  }

  weldingDetailsForOperator(
    _testPiece: WeldingOperatorSpecificWeldingDetails,
    _process: TestPieceValuesSingleProcess
  ): WeldingOperatorSpecificWeldingDetails[] {
    return [];
  }
}
