import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { get } from '@ember/object';

export default class NewMultipleRoute extends Route {
  @service
  userSession;

  @service session;

  @service
  router;

  beforeModel(transition) {
    super.beforeModel(...arguments);
    this.session.requireAuthentication(transition, 'login');
    if (this.userSession.isAccountLocked) {
      this.router.transitionTo('index');
    }
  }

  model() {
    return {
      revision: '0',
      errors: {},
    };
  }
}
