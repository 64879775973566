import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { set, get } from '@ember/object';

export default class NewRoute extends Route {
  @service
  store;

  @service
  userSession;

  @service
  router;

  @service session;

  modelName = 'pqr';

  beforeModel(transition) {
    super.beforeModel(...arguments);
    this.session.requireAuthentication(transition, 'login');
    let { userSession } = this;
    if (!userSession.canCreatePQR) {
      this.router.transitionTo('pqrs');
    }
    if (userSession.isAccountLocked) {
      this.router.transitionTo('index');
    }
  }

  model() {
    return this.store.createRecord('pqr');
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(this, 'currentModel', model);
    controller.set('modelName', get(this, 'modelName'));
  }
}
