import { service } from '@ember/service';
import { computed } from '@ember/object';
import Base from 'weldnote/components/base-edit';

export default class SystemAttributeComponent extends Base {

  @service
  userSession;

  get showDeleteButton() {
    return this.canEdit;
  }

  @computed('model.system', 'userSession.isAdmin')
  get canEdit() {
    return !this.model.system || this.userSession.isAdmin;
  }
  
}
