import classic from 'ember-classic-decorator';
import $ from 'jquery';
import Service, { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

@classic
export default class LoadingService extends Service {
  @service
  intl;

  init() {
    super.init(...arguments);
    $.blockUI.defaults.baseZ = 10000;
    $.blockUI.defaults.overlayCSS = { backgroundColor: '#ddd', opacity: 0.4 };
    let message = this.intl.t('services.loading.message');
    $.blockUI.defaults.message = `<h1 class='text-4xl'><i class='fa fa-cog fa-spin'></i> ${message}</h1>`;

    $.blockUI.defaults.css.padding = '15px';
    $.blockUI.defaults.css.border = 'none';
    $.blockUI.defaults.css.backgroundColor = '#000';
    $.blockUI.defaults.css.opacity = 0.6;
    $.blockUI.defaults.css.borderRadius = '10px';
    $.blockUI.defaults.css.color = '#fff';
  }

  block(message = '') {
    if (!isEmpty(message)) {
      $.blockUI({
        message: `<h1 class='text-4xl' ><i class='fa fa-cog fa-spin'></i><span data-id='block-ui-message'>${message}</span></h1>`,
      });
    } else {
      $.blockUI();
    }
  }

  unblock() {
    $.unblockUI();
  }

  replaceMessage(newMessage = '') {
    let uiMessage = document.querySelector('[data-id="block-ui-message"]');
    // Check here is to make sure the block wasn't removed by someone else (transition for instance)
    if (uiMessage && uiMessage.innerHTML) {
      uiMessage.innerHTML = newMessage;
    }
  }
}
