import { alias } from '@ember/object/computed';
import { belongsTo, attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import Gas from './gas';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

export default class GasCommercialDesignation extends BaseModel {
  @attr('string')
  declare commercialDesignation?: string;

  @belongsTo('gas', { async: false })
  declare gas: Gas;

  @attr('string')
  declare gasMixture?: string;

  @alias('commercialDesignation')
  get visualLabel(): string {
    return this.commercialDesignation || '';
  }

  validations = {
    commercialDesignation: { presence: true },
    gas: { custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      }, },
  };

  metadata = {
    modelName: 'gas-commercial-designation',
    commercialDesignation: { required: true },
    gas: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'gas-commercial-designation': GasCommercialDesignation;
  }
}
