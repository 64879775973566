import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { getProperties, action, computed, set } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class PlatePiecesController extends ListController {
  @service
  store;

  @service
  userSession;

  @service('weldnote-data')
  data;

  modelName = 'plate-piece';

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  @computed
  get gridColumns() {
    let thicknessLabel = this.getAttributeLabel('thickness', {
      unit: this.userSession.distanceUnit,
    });
    let thicknessInchesLabel = this.getAttributeLabel('thicknessInches', {
      unit: this.userSession.distanceUnit,
    });

    return [
      {
        valuePath: 'thickness',
        label: thicknessLabel,
        cellComponent: 'weldnote-grid/plate-piece-link',
      },
      {
        valuePath: 'thicknessInches',
        label: thicknessInchesLabel,
      },
    ];
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllPlatePieces(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  convertDecimal(value) {
    let separator = this.get('userSession.decimalSeparator');
    if (separator === ',') {
      value = value.replace(',', '.');
    }
    return value;
  }

  buildFilters() {
    let { thickness, thicknessInches } = getProperties(this, 'thickness', 'thicknessInches');
    let filters = {};
    if (!isEmpty(thickness)) {
      filters.thickness = this.convertDecimal(thickness);
    }
    if (!isEmpty(thicknessInches)) {
      filters.thicknessInches = this.convertDecimal(thicknessInches);
    }
    return filters;
  }

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.loadData.perform();
    this.resetPage();
  }

  @action
  clearFields() {
    this.setProperties({
      thicknessInches: null,
      thickness: null,
    });
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setThicknessInches(thickness) {
    set(this, 'thicknessInches', thickness);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setThickness(thickness) {
    set(this, 'thickness', thickness);
    this.resetPage();
    this.loadData.perform();
  }
}
