import { alias } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';
import { service } from '@ember/service';

export default Mixin.create({
  userSession: service(),
  showDeleteButton: alias('canEdit'),
  canEdit: computed('model.system', 'userSession.isAdmin', function () {
    return !this.get('model.system') || this.get('userSession.isAdmin');
  }),
});
