import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import Project from './project';
import Welder from './welder';

export default class ProjectWelder extends BaseModel {
  @belongsTo('project')
  declare project: AsyncBelongsTo<Project>;

  @belongsTo('welder')
  declare welder: AsyncBelongsTo<Welder>;

  @attr('string')
  declare welderName?: string;

  @attr('string')
  declare welderStamp?: string;

  @attr('number')
  declare welderId?: number;

  @attr('number')
  declare totalWelds?: number;

  @attr('number')
  declare repairedWelds?: number;

  @attr('number')
  declare goodWelds?: number;

  @attr('number')
  declare repairRate?: number;

  @attr('string')
  declare projectName?: number;

  async visualLabel() {
    let { welder } = this;
    if (welder) {
      let result = await welder;
      return result.welderName;
    }
    return '';
  }

  validations = {
    project: { presence: true },
    welder: { presence: true },
  };

  metadata = {
    modelName: 'project-welder',
    project: { required: true },
    welder: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-welder': ProjectWelder;
  }
}
