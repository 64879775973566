import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';

@classic
export default class WeldSketchesRoute extends Route {
  @service('weldnote-data')
  data;

  @service session;

  searchGrooveDesign = task(async () => {
    return await this.data.listAllGrooveDesigns();
  });

  searchLatestSketches = task(
    {
      drop: true,
    },
    async () => {
      let grooves = await this.data.listAllGrooveDesigns({}, { latest: 5, system: false });
      let weldLayers = await this.data.listAllWeldLayerConfigurations(
        {},
        { latest: 5, system: false }
      );
      return {
        grooves,
        weldLayers,
      };
    }
  );

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  model() {
    let { controller } = this;
    if (controller) {
      this.controller.search.perform();
      this.searchLatestSketches.perform();
    } else {
      this.searchGrooveDesign.perform();
      this.searchLatestSketches.perform();
      return {
        grooveDesigns: this.searchGrooveDesign,
        latestSketches: this.searchLatestSketches,
      };
    }
  }
}
