import { isEmpty } from '@ember/utils';
import Constants from 'weldnote/utils/constants';
import { NumericalRange } from 'weldnote/src-weldnote/wopq/approval-ranges/wopq-range-interface';

const { UNIT_SYSTEM } = Constants;

function formatRangeWithNDecimals(result: NumericalRange, decimals = 2): NumericalRange {
  if (result.min && !isEmpty(result.min)) {
    let { min } = result;

    if (!isNaN(min) && typeof min === 'number') {
      result.min = parseFloat(min.toFixed(decimals));
    }
  }
  if (result.max && !isEmpty(result.max)) {
    let { max } = result;
    if (!isNaN(max) && typeof max === 'number') {
      result.max = parseFloat(max.toFixed(decimals));
    }
  }

  return result;
}

export function formatRangeWith2Decimals(result: NumericalRange): NumericalRange {
  return formatRangeWithNDecimals(result, 2);
}

export function formatRangeWith3Decimals(result: NumericalRange): NumericalRange {
  return formatRangeWithNDecimals(result, 3);
}

export function fixDecimalsForUnitSystem(
  result: NumericalRange,
  unitSystem = UNIT_SYSTEM.METRIC
): NumericalRange {
  if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
    return result;
  }
  return formatRangeWith2Decimals(result);
}
