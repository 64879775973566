import ListRoute from 'weldnote/routes/_base/list';
import { service } from '@ember/service';

export default class StaffsRoute extends ListRoute {
  @service
  store;

  model() {
    return this.store.findAll('staff', { include: 'account' });
  }
}
