export default {
  SAVE: 'Save',
  DELETE: 'Delete',
  UPLOAD: 'Upload',
  EDIT: 'Edit',
  ARCHIVE: 'Archive',
  UNARCHIVE: 'Unarchive',
  GENERATE: 'Generate',
  SEARCH: 'Search',
  SELECT: 'Select',
  REVISION: 'Revision',
  MASTER_WPS: 'MasterWPS',
  CREATE_WPS_FROM_PQR: 'CreateWpsFromPQR',
  DISCARD: 'Discard',
  HELP: 'Help',
  LOGIN_METRIC: 'LoginMetric',
  LOGIN_IMPERIAL: 'LoginImperial',
  VALIDATION_FAILED: 'ValidationFailed',
};
