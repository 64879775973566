import Route from '@ember/routing/route';
import { set } from '@ember/object';
import { service } from '@ember/service';

export default class ComponentRoute extends Route {
  @service
  store;

  model(params) {
    return this.store.findRecord('project-component', params.component_id, {
      include:
        'original-drawing,component-drawing,line-class,test-inspection-class,test-inspection-class.stages,test-inspection-class.stages.location,test-inspection-class.stages.name,test-inspection-class.stages.tests.type-of-test',
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    set(controller, 'project', this.modelFor('project'));
  }
}
