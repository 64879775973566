import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import BaseMaterial from 'weldnote/models/base-material';
import BaseMaterialGroup from 'weldnote/models/base-material-group';
import ElectrodeType from 'weldnote/models/electrode-type';
import FillerMaterial from 'weldnote/models/filler-material';
import FillerMaterialGroup from 'weldnote/models/filler-material-group';
import ProcessMecanization from 'weldnote/models/process-mecanization';
import WeldType from 'weldnote/models/weld-type';
import WeldingDetail from 'weldnote/models/welding-detail';
import WeldingPosition from 'weldnote/models/welding-position';
import WeldingProcess from 'weldnote/models/welding-process';
import {
  NumericalRange,
  WelderCertificateApprovalRange,
} from 'weldnote/src-weldnote/wopq/approval-ranges/wopq-range-interface';
import {
  AutomaticJointTracking,
  TestPieceValuesSingleProcess,
  AutomaticArcVoltageControl,
  VisualControl,
  WeldingOperatorSpecificWeldingDetails,
} from 'weldnote/src-weldnote/wopq/types';

export default class NonSupportedApprovalRange implements WelderCertificateApprovalRange {
  constructor() {}

  automaticJointTracking(
    _testPiece: AutomaticJointTracking | null,
    _process: TestPieceValuesSingleProcess
  ): AutomaticJointTracking[] {
    return [];
  }

  automaticVoltageControl(
    _testPiece: AutomaticArcVoltageControl | null,
    _process: TestPieceValuesSingleProcess
  ): AutomaticArcVoltageControl[] {
    return [];
  }

  visualControl(
    _testPiece: VisualControl | null,
    _process: TestPieceValuesSingleProcess
  ): VisualControl[] {
    return [];
  }

  processMecanization(_testPiece: ProcessMecanization): ProcessMecanization[] {
    return [];
  }

  get _emptyRange(): NumericalRange {
    return {
      min: null,
      max: null,
    };
  }

  electrodeType(testPiece: ElectrodeType): ElectrodeType[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  electrodeTypeRoot(testPiece: ElectrodeType): ElectrodeType[] {
    return [testPiece];
  }

  electrodeTypeFill(testPiece: ElectrodeType): ElectrodeType[] {
    return [testPiece];
  }

  electrodeTypeCap(testPiece: ElectrodeType): ElectrodeType[] {
    return [testPiece];
  }

  thickness(): NumericalRange {
    return this._emptyRange;
  }

  weldType(testPiece: WeldType): WeldType[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  transferMode(testPiece: ArcTransferMode): ArcTransferMode[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  weldingDetails(testPiece: WeldingDetail[]): WeldingDetail[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    return testPiece;
  }

  baseMaterial(_testPiece: BaseMaterial): BaseMaterialGroup[] {
    return [];
  }

  weldingProcessRoot(process: WeldingProcess): WeldingProcess[] {
    if (isEmpty(process)) {
      return [];
    }

    return [process];
  }

  weldingProcessFill(process: WeldingProcess): WeldingProcess[] {
    if (isEmpty(process)) {
      return [];
    }

    return [process];
  }

  weldingProcessCap(process: WeldingProcess): WeldingProcess[] {
    if (isEmpty(process)) {
      return [];
    }

    return [process];
  }

  shielding(process: WeldingProcess): string | null {
    if (isEmpty(process)) {
      return null;
    }

    return null;
  }

  diameter(): NumericalRange {
    return this._emptyRange;
  }

  weldingPosition(testPiece: WeldingPosition) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  fillerMaterial(testPiece: FillerMaterial): FillerMaterialGroup[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    if (get(testPiece, 'group')) {
      return [get(testPiece, 'group')];
    }
    return [];
  }

  internalDeadline(_previousDate: Date): Date | null {
    return null;
  }

  externalDeadline(_previousDate: Date): Date | null {
    return null;
  }

  weldingDetailsForOperator(
    _testPiece: WeldingOperatorSpecificWeldingDetails,
    _process: TestPieceValuesSingleProcess
  ): WeldingOperatorSpecificWeldingDetails[] {
    throw new Error('Method not implemented.');
  }
}
