import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import NewRoute from 'weldnote/routes/_base/new';

@classic
export default class _NewRoute extends NewRoute {
  @service
  userSession;

  modelName = 'miscellaneous-certificate';
}
