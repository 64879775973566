import { computed, get } from '@ember/object';
import { attr, belongsTo } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import GasSpecification from './gas-specification';
import IndustryCode from './industry-code';

export default class Gas extends BaseModel {
  @attr('string')
  declare symbol?: string;

  @attr('string')
  declare composition?: string;

  @belongsTo('gas-specification', { async: false })
  declare specification: GasSpecification;

  @belongsTo('industry-code', { async: false })
  declare code: IndustryCode;

  @attr('boolean')
  declare system?: boolean;

  @computed('symbol', 'composition', 'specification')
  get visualLabel(): string {
    let specification: string = '';
    if (this.specification && !isEmpty(get(this.specification, 'id'))) {
      specification = get(this.specification, 'visualLabel');
    }
    if (isEmpty(specification)) {
      specification = '';
    }

    let { symbol } = this;
    if (isEmpty(symbol)) {
      symbol = '';
    }

    let { composition } = this;
    if (isEmpty(composition)) {
      composition = '';
    }

    let result = `${specification} - ${symbol} - ${composition}`;
    return result;
  }

  validations = {
    symbol: {
      presence: {
        message(_key: string, _value: string, model: Gas): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    specification: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    code: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    composition: {
      presence: {
        message(_key: string, _value: string, model: Gas): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'gas',
    symbol: { required: true },
    composition: { required: true },
    specification: { required: true },
    code: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    gas: Gas;
  }
}
