import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';
import Constants from 'weldnote/utils/constants';
import WeldingDetail from 'weldnote/models/welding-detail';
import Enumerable from '@ember/array/-private/enumerable';

const { WELDING_DETAILS } = Constants;

export function isSingleLayer(details: Enumerable<WeldingDetail>): boolean {
  if (!isEmpty(details)) {
    if (details.findBy('shortDesignation', WELDING_DETAILS.SINGLE_LAYER)) {
      return true;
    }
  }
  return false;
}

export function isMultiLayer(details: Enumerable<WeldingDetail>): boolean {
  if (!isEmpty(details)) {
    if (details.findBy('shortDesignation', WELDING_DETAILS.MULTI_LAYER)) {
      return true;
    }
  }
  return false;
}

export function isSingleSideNoBacking(details: WeldingDetail[]): boolean {
  if (!isEmpty(details)) {
    if (details.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_NO_BACKING)) {
      return true;
    }
  }
  return false;
}

export function isSingleSideMaterialBacking(details: WeldingDetail[]): boolean {
  if (!isEmpty(details)) {
    if (details.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING)) {
      return true;
    }
  }
  return false;
}

export function isSingleSideGasBacking(details: WeldingDetail[]): boolean {
  if (!isEmpty(details)) {
    if (details.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING)) {
      return true;
    }
  }
  return false;
}

export function isSingleOrMultiLayer(details: WeldingDetail[]): boolean {
  return isMultiLayer(details) || isSingleLayer(details);
}

export function isBothSides(details: Enumerable<WeldingDetail>): boolean {
  if (!isEmpty(details)) {
    if (details.findBy('shortDesignation', WELDING_DETAILS.BOTH_SIDES)) {
      return true;
    }
  }
  return false;
}

export function filterDetailsIncompatibleWithFilletWeld(details: WeldingDetail[]): WeldingDetail[] {
  if (!isEmpty(details)) {
    return details.reject((detail: WeldingDetail) => {
      let name: string = get(detail, 'shortDesignation');
      let result =
        name === WELDING_DETAILS.SINGLE_SIDE_NO_BACKING ||
        name === WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING ||
        name === WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING;
      return result;
    });
  }
  return [];
}

export function filterDetailsIncompatibleWithOverlayWeld(
  details: WeldingDetail[]
): WeldingDetail[] {
  if (!isEmpty(details)) {
    return details.reject((item) => {
      let name = get(item, 'shortDesignation');
      let result =
        name === WELDING_DETAILS.SINGLE_SIDE_NO_BACKING ||
        name === WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING ||
        name === WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING ||
        name === WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING ||
        name === WELDING_DETAILS.BOTH_SIDES ||
        name === WELDING_DETAILS.CONSUMABLE_INSERT;
      return result;
    });
  }
  return [];
}

export function filterDetailsIncompatibleWithPartialPenetrationButtWeld(
  details: WeldingDetail[]
): WeldingDetail[] {
  if (!isEmpty(details)) {
    return details.reject((item) => {
      let name = get(item, 'shortDesignation');
      let result =
        name === WELDING_DETAILS.SINGLE_SIDE_NO_BACKING ||
        name === WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING;
      return result;
    });
  }
  return [];
}
