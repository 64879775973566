import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get, action } from '@ember/object';
import { service } from '@ember/service';

export default class WeldingProcedureSpecificationPwpsToPrequalifiedController extends Controller {
  @alias('model')
  wps;

  @service
  router;

  @action
  cancel() {
    this.router.transitionTo('welding-procedure-specification', get(this, 'model.id'));
  }

  @action
  afterCreateWps(id) {
    this.router.transitionTo('welding-procedure-specification', id, {
      queryParams: {
        isEditing: true,
      },
    });
  }
}
