import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

@classic
export default class ActionLogsRoute extends Route {
  @service('weldnote-data')
  data;

  model(params) {
    let page = 1;
    if (params.page) {
      page = parseInt(params.page);
      if (isNaN(page) || page < 0) {
        page = 1;
      }
    }
    return this.data.getActionLogs(page);
  }
}
