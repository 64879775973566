import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { set, get, getProperties, action, computed } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';
import { tracked } from '@glimmer/tracking';

const {
  HTTP: { DEBOUNCE },
  ARCHIVAL_STATE,
} = Constants;

export default class ProjectsArchivedController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  @alias('data.pageSize')
  pageSize;

  modelName = 'project';

  @tracked
  totalCount = 0;

  @tracked
  currentPage = 1;

  @tracked
  projects = [];

  @tracked
  designation = '';

  @tracked
  projectName = '';

  @tracked
  supplier = null;

  @tracked
  selectedProjectClient = null;

  @computed
  get gridColumns() {
    let designationLabel = this.getAttributeLabel('designation');
    let projectLabel = this.getAttributeLabel('projectName');
    let customerLabel = this.getAttributeLabel('projectClient');
    let weldsPerformedLabel = this.intl.t('generic.welding.welds-performed');
    let weldsRepairedLabel = this.intl.t('generic.welding.welds-repaired');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'designation',
        label: designationLabel,
        width: '200px',
        cellComponent: 'weldnote-grid/project-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'projectName',
        label: projectLabel,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'customer',
        label: customerLabel,
        cellComponent: 'weldnote-grid/project-client-link',
        breakpoints: ['tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'totalWelds',
        label: weldsPerformedLabel,
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'repairRate',
        label: weldsRepairedLabel,
        cellComponent: 'weldnote-grid/repair-rate',
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
    ];
  }

  buildFilters() {
    let filters = getProperties(this, 'designation', 'projectName', 'supplier');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });

    finalFilters.archivalState = ARCHIVAL_STATE.ARCHIVED;

    let { selectedProjectClient } = this;
    if (!isEmpty(selectedProjectClient)) {
      finalFilters.projectClient = get(selectedProjectClient, 'id');
    }

    if (filters.supplier) {
      finalFilters.supplier = get(filters, 'supplier.id');
    }
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async (pageNumber = 1) => {
      let options = {};
      this.currentPage = pageNumber;
      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllProjects(options, this.buildFilters());
      this.projects = data.list;
      this.totalCount = data.count;
    }
  );

  resetPage() {
    this.currentPage = 1;
  }

  @action
  search(pageNumber) {
    this.currentPage = pageNumber;
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setDesignation(designation) {
    this.designation = designation;
  }

  @action
  setProject(project) {
    this.projectName = project;
  }

  @action
  setSupplier(supplier) {
    this.supplier = supplier;
  }

  @action
  setClient(client) {
    this.selectedProjectClient = client;
  }

  @action
  clearFields() {
    this.resetPage();
    this.designation = null;
    this.projectName = null;
    this.selectedProjectClient = null;
    this.supplier = null;
    this.loadData.perform();
  }
}
