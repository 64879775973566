import EditController from 'weldnote/controllers/_base/edit';
import { get, action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class WelderCertificateController extends EditController {
  @service
  router;

  queryParams = ['section'];

  @tracked
  section = 'validation';

  @action
  goToNewCertificate(result) {
    this.router.transitionTo('welder-certificate', result.id);
  }

  @action
  replaceCertificate(newCertificate) {
    this.model = newCertificate;
  }

  @action
  selectSection(section) {
    if (section) {
      this.section = section;
    }
  }
}
