import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { getProperties, action, computed, set } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class BaseMaterialsController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  modelName = 'base-material';

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  @computed
  get gridColumns() {
    let specificationLabel = this.getAttributeLabel('specification');
    let materialGroupLabel = this.getAttributeLabel('materialGroup');
    let gradeLabel = this.getAttributeLabel('grade');
    let codeLabel = this.getAttributeLabel('industryCode');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet', 'laptop'],
      },
      {
        valuePath: 'specification.specification',
        label: specificationLabel,
        cellComponent: 'weldnote-grid/base-material-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'grade.grade',
        label: gradeLabel,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'materialGroup.groupName',
        label: materialGroupLabel,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'materialGroup.description',
        label: 'Description',
        width: '400px',
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'industryCode.code',
        label: codeLabel,
        sortable: false,
        breakpoints: ['desktop'],
      },
    ];
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllBaseMaterials(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  buildFilters() {
    let { specification, grade, group, industryCode } = getProperties(
      this,
      'specification',
      'grade',
      'group',
      'industryCode'
    );
    let filters = {};
    if (!isEmpty(specification)) {
      filters.specification = specification;
    }
    if (!isEmpty(grade)) {
      filters.grade = grade;
    }
    if (!isEmpty(group)) {
      filters.group = group;
    }
    if (!isEmpty(industryCode)) {
      filters.industryCode = industryCode.get('id');
    }
    return filters;
  }

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.loadData.perform();
    this.resetPage();
  }

  @action
  clearFields() {
    this.setProperties({
      specification: null,
      grade: null,
      group: null,
      industryCode: null,
    });
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setSpecification(spec) {
    set(this, 'specification', spec);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setGrade(grade) {
    set(this, 'grade', grade);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setGroup(group) {
    set(this, 'group', group);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setIndustryCode(industryCode) {
    set(this, 'industryCode', industryCode);
    this.resetPage();
    this.loadData.perform();
  }
}
