import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import { get, action, computed, set } from '@ember/object';

@classic
export default class ActionLogsController extends Controller {
  @service('weldnote-data')
  data;

  @service
  userSession;

  queryParams = ['page'];

  page = 1;

  @computed('userSession.dateFormat')
  get dateTimeFormat() {
    return `${get(this, 'userSession.dateFormat')} HH:mm:ss`;
  }

  @action
  reloadData(page) {
    this.data.getActionLogs(page).then((data) => {
      set(this, 'model', data);
    });
  }
}
