import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { get, set } from '@ember/object';
import ListRoute from 'weldnote/routes/_base/list';

export default class WeldersArchivedRoute extends ListRoute {
  @service('weldnote-data')
  data;

  model(params) {
    return this.data.listAllWelders(
      {
        page: params.page,
      },
      {
        archived: 'AR',
        personType: 'ALL',
      }
    );
  }

  setupController(controller, model) {
    set(controller, 'welders', model);
    set(controller, 'welderCount', model.meta.records);
  }
}
