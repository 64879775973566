import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { isStandardISO, isStandardASME } from 'weldnote/utils/standards';
import Constants from 'weldnote/utils/constants';

const { WELD_TYPES } = Constants;

export default {
  WPS_WELDING_PARAMETER: {
    ORDER_NUM: 'orderNum',
    PASS_NUMBER: 'passNumber',
    WELDING_PROCESS: 'weldingProcess',
    FILLER_MATERIAL_TYPE: 'fillerMaterialType',
    FILLER_MATERIAL_DIAMETER: 'fillerMaterialDiameters',
    CURRENT_MINIMUM: 'currentMinimum',
    CURRENT_MAXIMUM: 'currentMaximum',
    VOLTAGE_MINIMUM: 'voltageMinimum',
    VOLTAGE_MAXIMUM: 'voltageMaximum',
    TYPE_CURRENT: 'typeCurrent',
    WIRE_FEED_SPEED_MINIMUM: 'wireFeedSpeedMinimum',
    WIRE_FEED_SPEED_MAXIMUM: 'wireFeedSpeedMaximum',
    TRAVEL_SPEED_MINIMUM: 'travelSpeedMinimum',
    TRAVEL_SPEED_MAXIMUM: 'travelSpeedMaximum',
    HEAT_INPUT_MINIMUM: 'heatInputMinimum',
    HEAT_INPUT_MAXIMUM: 'heatInputMaximum',
    ARC_TRANSFER_MODE: 'arcTransferMode',
    WPS: 'wps',
    LAYER: 'layer',
    SOURCE_PARAMETER: 'sourceParameter',
    WELDING_POSITIONS: 'weldingPositions',
    PROGRAM: 'program',
  },

  // Calculates the minimum heat input valid for a wps-welding-parameter instance
  // given that it can be linked to a PQR Pass, only to a PQR or not to anything
  calculateMinimumHeatInput(parameter) {
    let isLinkedToPass = get(parameter, 'isLinkedToPass');
    let isISO =
      !isEmpty(get(parameter, 'wps.standard')) && isStandardISO(get(parameter, 'wps.standard'));
    let isOverlayWeld = get(parameter, 'wps.weldType.weldType') === WELD_TYPES.OVERLAY_WELD;
    if (isLinkedToPass && isISO && isOverlayWeld) {
      let heatInputMinimumPass = parameter.getSourceInputMinimum();
      if (!isEmpty(heatInputMinimumPass)) {
        let result = parseFloat(heatInputMinimumPass) * 0.9;
        return result.toFixed(2);
      }
    } else {
      let hasPQR = get(parameter, 'wps.isLinkedToPQR');
      if (hasPQR) {
        let heatInputMinimum = parameter.getSourcePQRInputMinimum();
        if (!isEmpty(heatInputMinimum) && !isNaN(heatInputMinimum)) {
          let result = parseFloat(heatInputMinimum) * 0.9;
          return result.toFixed(2);
        }
      }
    }
    return null;
  },

  calculateMaximumHeatInput(parameter, standard) {
    let standardFactor = 1;
    let isOverlayWeld = get(parameter, 'wps.weldType.weldType') === WELD_TYPES.OVERLAY_WELD;
    if (isStandardISO(standard)) {
      standardFactor = 1.25;
    } else if (isStandardASME(standard) && isOverlayWeld) {
      standardFactor = 1.1;
    }
    let isISO = isStandardISO(standard);
    let isLinkedToPass = get(parameter, 'isLinkedToPass');
    if (isLinkedToPass && isISO && isOverlayWeld) {
      let heatInputMaximumPass = parameter.getSourceInputMaximum();
      if (!isEmpty(heatInputMaximumPass) && !isNaN(parseFloat(heatInputMaximumPass))) {
        let result = parseFloat(heatInputMaximumPass) * standardFactor;
        return result.toFixed(2);
      }
    } else {
      let hasPQR = get(parameter, 'wps.isLinkedToPQR');
      if (hasPQR) {
        let heatInputMaximum = parameter.getSourcePQRInputMaximum();
        if (!isEmpty(heatInputMaximum) && !isNaN(heatInputMaximum)) {
          let result = parseFloat(heatInputMaximum) * standardFactor;
          return result.toFixed(2);
        }
      }
    }
    return null;
  },
};
