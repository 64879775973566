import classic from 'ember-classic-decorator';
import { attributeBindings } from '@ember-decorators/component';
import { get, computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Row from 'ember-light-table/components/lt-row';
import { isEmpty } from '@ember/utils';

@classic
@attributeBindings('style')
export default class WelderCertificateRow extends Row {
  @computed('row.certificateState')
  get style() {
    let { row } = this;
    let certificateState = get(row, 'certificateState');
    if (!isEmpty(certificateState)) {
      if (certificateState === 'AR') {
        return htmlSafe('background-color: #eff8ff');
      } else if (certificateState === 'IE' || certificateState === 'EE') {
        return htmlSafe('background-color: #fcebea');
      }
    }
    return htmlSafe('');
  }
}
