import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n  (hash\n    text=(component \"ui-form/group/text\" model=@model isEditing=@isEditing)\n    checkbox=(component \"ui-form/group/checkbox\" model=@model isEditing=@isEditing)\n    lov=(component \"ui-form/group/lov\" model=@model isEditing=@isEditing)\n    multi-lov=(component \"ui-form/group/multi-lov\" model=@model isEditing=@isEditing)\n    select=(component \"ui-form/group/select\" model=@model isEditing=@isEditing)\n    date=(component \"ui-form/group/date\" model=@model isEditing=@isEditing)\n    decimal=(component \"ui-form/group/decimal\" model=@model isEditing=@isEditing)\n    text-area=(component \"ui-form/group/text-area\" model=@model isEditing=@isEditing)\n    upload=(component \"ui-form/group/upload\" model=@model isEditing=@isEditing)\n  )\n}}", {"contents":"{{yield\n  (hash\n    text=(component \"ui-form/group/text\" model=@model isEditing=@isEditing)\n    checkbox=(component \"ui-form/group/checkbox\" model=@model isEditing=@isEditing)\n    lov=(component \"ui-form/group/lov\" model=@model isEditing=@isEditing)\n    multi-lov=(component \"ui-form/group/multi-lov\" model=@model isEditing=@isEditing)\n    select=(component \"ui-form/group/select\" model=@model isEditing=@isEditing)\n    date=(component \"ui-form/group/date\" model=@model isEditing=@isEditing)\n    decimal=(component \"ui-form/group/decimal\" model=@model isEditing=@isEditing)\n    text-area=(component \"ui-form/group/text-area\" model=@model isEditing=@isEditing)\n    upload=(component \"ui-form/group/upload\" model=@model isEditing=@isEditing)\n  )\n}}","moduleName":"weldnote/components/ui-form/provider.hbs","parseOptions":{"srcName":"weldnote/components/ui-form/provider.hbs"}});
import Component from '@glimmer/component';
import { set, action } from '@ember/object';

export default class Provider extends Component {
  @action
  onUpdate(attribute, value) {
    set(this.args.model, attribute, value);
  }
}
