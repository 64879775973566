import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import BaseMaterial from 'weldnote/models/base-material';
import BaseMaterialGroup from 'weldnote/models/base-material-group';
import ElectrodeType from 'weldnote/models/electrode-type';
import FillerMaterial from 'weldnote/models/filler-material';
import FillerMaterialGroup from 'weldnote/models/filler-material-group';
import IndustryCode from 'weldnote/models/industry-code';
import ProcessMecanization from 'weldnote/models/process-mecanization';
import WeldType from 'weldnote/models/weld-type';
import WelderCertificate from 'weldnote/models/welder-certificate';
import WeldingDetail from 'weldnote/models/welding-detail';
import WeldingPosition from 'weldnote/models/welding-position';
import WeldingProcess from 'weldnote/models/welding-process';
import { calculateShielding } from 'weldnote/utils/certification-rules';
import Constants from 'weldnote/utils/constants';
import {
  isAutomaticMecanization,
  isMechanizedMecanization,
} from 'weldnote/utils/rules/process-mecanization';
import {
  isCompleteJointPenetrationButtWeld,
  isFilletWeld,
  isPartialJointPenetrationButtWeld,
} from 'weldnote/utils/rules/weld-type';
import {
  AutomaticJointTracking,
  TestPieceValuesSingleProcess,
  AutomaticArcVoltageControl,
  VisualControl,
  WeldingOperatorSpecificWeldingDetails,
} from '../types';
import { NumericalRange, WelderCertificateApprovalRange } from './wopq-range-interface';

const { ARC_TRANSFER_MODES, WELDING_DETAILS, UNIT_SYSTEM, PRODUCT_TYPE } = Constants;

export default class AwsD11ApprovalRanges implements WelderCertificateApprovalRange {
  private certificate: WelderCertificate;

  private _weldTypes: WeldType[];

  private _baseMaterials: BaseMaterialGroup[];

  private allWeldingDetails: WeldingDetail[];

  private _fillerMaterials: FillerMaterialGroup[];

  private _weldingProcesses: WeldingProcess[];

  private _weldingPositions: WeldingPosition[];

  private _transferModes: ArcTransferMode[];

  private unitSystem: string;

  constructor(certificate: WelderCertificate, data: any, unitSystem = UNIT_SYSTEM.METRIC) {
    this.certificate = certificate;
    this._weldTypes = data.allWeldTypes;
    this._baseMaterials = data.allBaseMaterials;
    this.allWeldingDetails = data.allWeldingDetails;
    this._fillerMaterials = data.allFillerMaterials;
    this._weldingProcesses = data.allWeldingProcesses;
    this._weldingPositions = data.allWeldingPositions;
    this._transferModes = data.allTransferModes;
    this.unitSystem = unitSystem;
  }

  automaticJointTracking(
    _testPiece: AutomaticJointTracking | null,
    _process: TestPieceValuesSingleProcess
  ): AutomaticJointTracking[] {
    return [];
  }

  automaticVoltageControl(
    _testPiece: AutomaticArcVoltageControl | null,
    _process: TestPieceValuesSingleProcess
  ): AutomaticArcVoltageControl[] {
    return [];
  }

  visualControl(
    _testPiece: VisualControl | null,
    _process: TestPieceValuesSingleProcess
  ): VisualControl[] {
    return [];
  }

  processMecanization(testPiece: ProcessMecanization): ProcessMecanization[] {
    if (!testPiece && isEmpty(testPiece)) {
      return [];
    }
    if (isAutomaticMecanization(testPiece)) {
      return [testPiece];
    }
    if (isMechanizedMecanization(testPiece)) {
      return [testPiece];
    }
    return [];
  }

  get industryCodeId() {
    let { standard } = this;
    let code = get(standard, 'code');
    if (code) {
      return get(code, 'id');
    }
    return '';
  }

  getIndustryCode(code: IndustryCode | undefined): string {
    if (code) {
      return get(code, 'id');
    }
    return '';
  }

  get allWeldingProcesses() {
    return this._weldingProcesses.filter(
      (process) => this.getIndustryCode(get(process, 'industryCode')) === this.industryCodeId
    );
  }

  get allWeldingPositions() {
    return this._weldingPositions.filter(
      (position) => this.getIndustryCode(get(position, 'industryCode')) === this.industryCodeId
    );
  }

  get allTransferModes() {
    return this._transferModes.filter(
      (transferMode) =>
        this.getIndustryCode(get(transferMode, 'industryCode')) === this.industryCodeId
    );
  }

  get allWeldTypes() {
    return this._weldTypes.filter(
      (weldType) => this.getIndustryCode(get(weldType, 'industryCode')) === this.industryCodeId
    );
  }

  get allBaseMaterials() {
    return this._baseMaterials.filter(
      (baseMaterial) => this.getIndustryCode(get(baseMaterial, 'code')) === this.industryCodeId
    );
  }

  get allFillerMaterials() {
    return this._fillerMaterials.filter(
      (fillerMaterial) => this.getIndustryCode(get(fillerMaterial, 'code')) === this.industryCodeId
    );
  }

  get standard() {
    return this.certificate.get('standard');
  }

  get productType() {
    return this.certificate.get('productTypeTestPiece');
  }

  get isPipe() {
    let { productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PIPE;
    }
    return false;
  }

  get isPlate() {
    let { productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PLATE;
    }
    return false;
  }

  get isPipePlate() {
    let { productType } = this;
    if (!isEmpty(productType)) {
      return productType === PRODUCT_TYPE.PIPE_PLATE;
    }
    return false;
  }

  get requalificationPeriod() {
    return this.certificate.get('requalificationPeriod');
  }

  get weldTypeTestPiece() {
    return this.certificate.get('weldTypeTestPiece');
  }

  get transferModeRoot() {
    return this.certificate.get('arcTransferModeRootTestPiece');
  }

  get transferModeFill() {
    return this.certificate.get('arcTransferModeFillTestPiece');
  }

  get transferModeCap() {
    return this.certificate.get('arcTransferModeCapTestPiece');
  }

  get isMetric() {
    return this.unitSystem === UNIT_SYSTEM.METRIC;
  }

  get isImperial() {
    return this.unitSystem === UNIT_SYSTEM.IMPERIAL;
  }

  get isFilletWeld() {
    return isFilletWeld(this.weldTypeTestPiece);
  }

  get isPartialJointPenetrationButtWeld() {
    return isPartialJointPenetrationButtWeld(this.weldTypeTestPiece);
  }

  get isCompleteJointPenetrationButtWeld() {
    return isCompleteJointPenetrationButtWeld(this.weldTypeTestPiece);
  }

  get _emptyRange(): NumericalRange {
    return {
      min: null,
      max: null,
    };
  }

  electrodeType(
    electrode: ElectrodeType,
    _processValues: TestPieceValuesSingleProcess
  ): ElectrodeType[] {
    return this.electrodeTypeRoot(electrode);
  }

  electrodeTypeRoot(electrode: ElectrodeType) {
    return this._electrodeType(electrode);
  }

  electrodeTypeFill(electrode: ElectrodeType) {
    return this._electrodeType(electrode);
  }

  electrodeTypeCap(electrode: ElectrodeType) {
    return this._electrodeType(electrode);
  }

  _electrodeType(electrode: ElectrodeType /* , weldingProcess */) {
    if (isEmpty(electrode)) {
      return [];
    }
    return [electrode];
  }

  thickness(testPiece: number): NumericalRange {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    let thickness = parseFloat(`${testPiece}`);
    if (isNaN(thickness)) {
      return this._emptyRange;
    }

    let diameter = this.certificate.get('diameterTestPiece');

    let result = this._emptyRange;

    if (
      (this.isCompleteJointPenetrationButtWeld || this.isPartialJointPenetrationButtWeld) &&
      this.isPlate
    ) {
      if (this.isMetric) {
        if (thickness <= 10) {
          result.min = 3;
          result.max = 20;
        } else if (thickness > 10 && thickness < 25) {
          result.min = 3;
          result.max = thickness * 2;
        } else {
          result.min = 3;
          result.max = null;
        }
      } else if (this.isImperial) {
        if (thickness <= 0.375) {
          result.min = 0.125;
          result.max = 0.75;
        } else if (thickness > 0.375 && thickness < 1) {
          result.min = 0.125;
          result.max = thickness * 2;
        } else {
          result.min = 0.125;
          result.max = null;
        }
      }
    } else if (this.isFilletWeld) {
      if (!isEmpty(thickness)) {
        if (this.isMetric) {
          result.min = 3;
          result.max = null;
        } else if (this.isImperial) {
          result.min = 0.125;
          result.max = null;
        }
      }
    } else if (
      (this.isCompleteJointPenetrationButtWeld || this.isPartialJointPenetrationButtWeld) &&
      this.isPipe
    ) {
      if (!diameter) {
        throw new Error(`Certificate ${get(this.certificate, 'id')} is Pipe but has no diameter`);
      }
      if (this.isMetric) {
        if (thickness > 10 && diameter > 100) {
          result.min = 5;
          result.max = null;
        } else if (thickness <= 10 && diameter > 100) {
          result.min = 3;
          result.max = 20;
        } else if (diameter <= 100 && !isEmpty(thickness)) {
          result.min = 3;
          result.max = 20;
        }
      } else if (this.isImperial) {
        if (thickness <= 0.375 && diameter > 4) {
          result.min = 0.125;
          result.max = 0.75;
        } else if (thickness > 0.375 && diameter > 4) {
          result.min = 0.1875;
          result.max = null;
        } else if (diameter <= 4 && !isEmpty(thickness)) {
          result.min = 0.125;
          result.max = 0.75;
        }
      }
    }

    return result;
  }

  weldType(testPiece: WeldType): WeldType[] {
    if (isEmpty(testPiece)) {
      return [];
    }

    if (isCompleteJointPenetrationButtWeld(testPiece)) {
      return this.allWeldTypes.filter((e) => {
        return (
          isFilletWeld(e) ||
          isPartialJointPenetrationButtWeld(e) ||
          isCompleteJointPenetrationButtWeld(e)
        );
      });
    } else {
      return [testPiece];
    }
  }

  transferMode(testPiece: ArcTransferMode): ArcTransferMode[] {
    if (isEmpty(testPiece)) {
      return [];
    }

    let arcTransferMode = get(testPiece, 'transferMode');

    if (arcTransferMode === ARC_TRANSFER_MODES.SHORT_ARC) {
      return [testPiece];
    }
    if (
      arcTransferMode === ARC_TRANSFER_MODES.GLOBULAR ||
      arcTransferMode === ARC_TRANSFER_MODES.PULSED_SPRAY ||
      arcTransferMode === ARC_TRANSFER_MODES.SPRAY
    ) {
      return this.allTransferModes.filter((e) => {
        let currentMode = get(e, 'transferMode');
        return (
          currentMode === ARC_TRANSFER_MODES.PULSED_SPRAY ||
          currentMode === ARC_TRANSFER_MODES.GLOBULAR ||
          currentMode === ARC_TRANSFER_MODES.SPRAY
        );
      });
    }
    return [];
  }

  findWeldingDetails(wanted: string[], property: keyof WeldingDetail): WeldingDetail[] {
    let results: WeldingDetail[] = [];
    wanted.forEach((position) => {
      let candidate = this.allWeldingDetails.findBy(property, position);
      if (candidate) {
        results.pushObject(candidate);
      }
    });
    return results;
  }

  weldingDetails(testPiece: WeldingDetail[]): WeldingDetail[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    let results: WeldingDetail[] = [];
    let finalResults: WeldingDetail[] = [];
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_NO_BACKING)) {
      results.pushObjects(
        this.findWeldingDetails(
          [
            WELDING_DETAILS.SINGLE_SIDE_NO_BACKING,
            WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
            WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING,
            WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING,
            WELDING_DETAILS.BOTH_SIDES,
          ],
          'shortDesignation'
        )
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING)) {
      results.pushObjects(
        this.findWeldingDetails(
          [WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING, WELDING_DETAILS.BOTH_SIDES],
          'shortDesignation'
        )
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING)) {
      results.pushObjects(
        this.findWeldingDetails(
          [WELDING_DETAILS.BOTH_SIDES, WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING],
          'shortDesignation'
        )
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING)) {
      results.pushObjects(
        this.findWeldingDetails(
          [
            WELDING_DETAILS.BOTH_SIDES,
            WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING,
            WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
          ],
          'shortDesignation'
        )
      );
    }
    if (testPiece.findBy('shortDesignation', WELDING_DETAILS.BOTH_SIDES)) {
      results.pushObjects(
        this.findWeldingDetails([WELDING_DETAILS.BOTH_SIDES], 'shortDesignation')
      );
    }

    testPiece.forEach((detail) => {
      results.pushObject(detail);
    });
    results.forEach((detail) => {
      if (!finalResults.findBy('shortDesignation', detail.get('shortDesignation'))) {
        finalResults.pushObject(detail);
      }
    });
    return finalResults;
  }

  baseMaterial(testPiece: BaseMaterial): BaseMaterialGroup[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    return this.allBaseMaterials.filter((group) => {
      let { groupName } = group;
      return (
        groupName === 'I' ||
        groupName === 'II' ||
        groupName === 'III' ||
        groupName === 'IV' ||
        groupName === 'Table 4.9'
      );
    });
  }

  weldingProcessRoot(process: WeldingProcess): WeldingProcess[] {
    if (isEmpty(process)) {
      return [];
    }
    return [process];
  }

  weldingProcessFill(process: WeldingProcess): WeldingProcess[] {
    if (isEmpty(process)) {
      return [];
    }
    return [process];
  }

  weldingProcessCap(process: WeldingProcess): WeldingProcess[] {
    if (isEmpty(process)) {
      return [];
    }
    return [process];
  }

  shielding(process: WeldingProcess): string | null {
    if (isEmpty(process)) {
      return '';
    }
    return calculateShielding(process.shortDesignation);
  }

  diameter(testPiece: number): NumericalRange {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    let results: NumericalRange = { min: null, max: null };

    let diameter = parseFloat(`${testPiece}`);
    if (diameter < 0) {
      return results;
    }
    if (this.isMetric) {
      if (this.isPlate) {
        results.min = 600;
        results.max = null;
      } else if (this.isPipe) {
        if (!isNaN(diameter)) {
          if (diameter <= 100) {
            results.min = 20;
            results.max = 100;
          } else {
            results.min = Math.max(100, 0.5 * diameter);
            results.max = null;
          }
        }
      }
    } else if (this.isImperial) {
      if (this.isPlate) {
        results.min = 24;
        results.max = null;
      } else if (this.isPipe) {
        if (!isNaN(diameter)) {
          if (diameter <= 4) {
            results.min = 0.75;
            results.max = 4;
          } else {
            results.min = Math.max(4, 0.5 * diameter);
            results.max = null;
          }
        }
      }
    }
    return results;
  }

  filterPositions(wanted: string[]): WeldingPosition[] {
    let results: WeldingPosition[] = [];
    wanted.forEach((position) => {
      let candidate = this.allWeldingPositions.findBy('code', position);
      if (candidate) {
        results.pushObject(candidate);
      }
    });
    return results;
  }

  weldingPosition(testPiece: WeldingPosition): WeldingPosition[] {
    if (isEmpty(testPiece)) {
      return [];
    }

    let results = [];
    let weldingPosition = get(testPiece, 'code');
    if (!weldingPosition) {
      return [];
    }

    if (weldingPosition === '1G') {
      results = this.filterPositions(['1G', '1F', '2F']);
    } else if (weldingPosition === '2G') {
      results = this.filterPositions(['1G', '2G', '1F', '2F']);
    } else if (weldingPosition === '3G') {
      results = this.filterPositions(['1G', '2G', '3G', '1F', '2F', '3F']);
    } else if (weldingPosition === '4G') {
      results = this.filterPositions(['1G', '4G', '1F', '2F', '4F']);
    } else if (weldingPosition === '3G + 4G') {
      results = this.filterPositions([
        '1G',
        '2G',
        '3G',
        '4G',
        '5G',
        '6G',
        '6GR',
        '1F',
        '2F',
        '3F',
        '4F',
        '5F',
      ]);
    } else if (weldingPosition === '5G') {
      results = this.filterPositions(['1G', '3G', '4G', '5G', '1F', '3F', '4F', '5F']);
    } else if (weldingPosition === '5G+2G') {
      results = this.filterPositions([
        '1G',
        '2G',
        '3G',
        '4G',
        '5G',
        '6G',
        '1F',
        '2F',
        '3F',
        '4F',
        '5F',
      ]);
    } else if (weldingPosition === '6G') {
      results = this.filterPositions([
        '1G',
        '2G',
        '3G',
        '4G',
        '5G',
        '6G',
        '1F',
        '2F',
        '3F',
        '4F',
        '5F',
      ]);
    } else if (weldingPosition === '6GR') {
      results = this.filterPositions([
        '1G',
        '2G',
        '3G',
        '4G',
        '5G',
        '6G',
        '6GR',
        '1F',
        '2F',
        '3F',
        '4F',
        '5F',
      ]);
    } else if (weldingPosition === '1F') {
      results = this.filterPositions(['1F']);
    } else if (weldingPosition === '2F') {
      results = this.filterPositions(['1F', '2F']);
    } else if (weldingPosition === '3F') {
      results = this.filterPositions(['1F', '2F', '3F']);
    } else if (weldingPosition === '4F') {
      results = this.filterPositions(['1F', '2F', '4F']);
    } else if (weldingPosition === '5F') {
      results = this.filterPositions(['1F', '2F', '3F', '4F', '5F']);
    } else if (weldingPosition === '3F + 4F') {
      results = this.filterPositions(['1F', '2F', '3F', '4F', '5F']);
    } else {
      results = this.filterPositions([weldingPosition]);
    }
    return results.sortBy('code');
  }

  fillerMaterial(testPiece: FillerMaterial): FillerMaterialGroup[] {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [];
  }

  internalDeadline(previousDate: Date): Date | null {
    if (isEmpty(previousDate)) {
      return null;
    }

    let newDate = moment(previousDate).add(6, 'M');
    return newDate.toDate();
  }

  externalDeadline(previousDate: Date): Date | null {
    if (isEmpty(previousDate)) {
      return null;
    }

    let { requalificationPeriod } = this.certificate;
    if (requalificationPeriod) {
      let newDate = moment(previousDate).add(requalificationPeriod, 'y');
      return newDate.toDate();
    }

    return null;
  }

  weldingDetailsForOperator(
    _testPiece: WeldingOperatorSpecificWeldingDetails,
    _process: TestPieceValuesSingleProcess
  ): WeldingOperatorSpecificWeldingDetails[] {
    return [];
  }
}
