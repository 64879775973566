import { assert } from '@ember/debug';
import { isEmpty } from '@ember/utils';

export function convertDecimals(rawValue: number, separator: string): string {
  assert('Must pass a separator', !isEmpty(separator));

  let rawValueString = `${rawValue}`;
  if (separator !== '.') {
    rawValueString = rawValueString.replace(separator, '.');
  }
  if (rawValueString.indexOf(',') >= 0) {
    rawValueString = rawValueString.replace(',', '.');
  }
  return rawValueString;
}

export function displayDecimals(rawValue: number, separator: string): string {
  assert('Must pass a separator', !isEmpty(separator));

  let rawValueString = `${rawValue}`;
  let result = rawValueString;
  if (separator !== '.') {
    return (result = rawValueString.replace('.', separator));
  }
  return result;
}
