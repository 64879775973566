import { service } from '@ember/service';
import Component from '@glimmer/component';
import { get, set, action } from '@ember/object';
import {
  isPermissionDenied,
  isDuplicateError,
  isForeignKeyError,
} from 'weldnote/utils/json-api-errors';
import EVENTS from 'weldnote/utils/analytics-events';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { assert } from '@ember/debug';
import { isEmpty } from '@ember/utils';

export default class BaseEdit extends Component {
  @service
  intl;

  @service
  store;

  @service
  analytics;

  @service
  alertMessage;

  @service
  userSession;

  @service
  loading;

  @service
  router;

  @tracked
  isShowingDeleteModal = false;

  // Just an alias for the alert message service
  get alert() {
    return this.alertMessage;
  }

  get modelName() {
    return this.args.model.metadata.modelName;
  }

  get listRoute() {
    return this.args.model.metadata.listRoute;
  }

  get editRoute() {
    return this.args.model.metadata.editRoute;
  }

  get visualLabel() {
    return this.args.model.visualLabel;
  }

  get showDeleteButton() {
    return this.canEdit && !this.args.model.isNew;
  }

  get canEdit() {
    return this.args.canEdit ?? true;
  }

  get title() {
    let { intl } = this;
    if (this.args.model.isNew) {
      let modelLabel = intl.t(`model.${this.modelName}.model-name`);
      return intl.t('edit-view.new-item', { name: modelLabel });
    }
    return this.visualLabel;
  }

  get subtitle() {
    let { hasDirtyAttributes: isDirty } = this.args.model;
    let changedAttributes = this.args.model.changedAttributes();
    // Something's odd, changedAttributes returns empty, but hasDirtyAttributes returns true?
    if (isDirty && Object.keys(changedAttributes).length > 0) {
      return '*';
    } else {
      return '';
    }
  }

  get isModelSaving() {
    return this.saveModel.isRunning;
  }

  get breadcrumbs() {
    return [
      {
        route: this.listRoute,
        label: this.getModelLabel('model-plural'),
      },
      {
        label: this.title,
      },
    ];
  }

  constructor() {
    super(...arguments);
    assert(`Requires a model`, !isEmpty(this.args.model));
    assert(
      'Requires the list route present in model metadata under the listRoute key',
      !isEmpty(this.listRoute)
    );
    assert(
      'Requires the edit route present in model metadata under the editRoute key',
      !isEmpty(this.editRoute)
    );
  }

  getModelValue(attribute) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    return get(this.args.model, attribute);
  }

  setModelValue(attribute, value) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    set(this.args.model, attribute, value);
  }

  setModelDecimalValue(attribute, value) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    let {
      userSession: { decimalSeparator: separator },
    } = this;
    if (separator === ',') {
      if (!isEmpty(value)) {
        value = `${value}`.replace(',', '.');
      }
    }
    set(this.args.model, attribute, value);
  }

  setModelCollection(attribute, values) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    let collection = get(this.args.model, attribute);
    collection.clear();
    if (!isEmpty(values)) {
      values.forEach((value) => {
        collection.pushObject(value);
      });
    }
  }

  setModelCollectionValue(attribute, value) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    if (!isEmpty(value)) {
      this.setModelCollection(attribute, [value]);
    }
  }

  clearModelCollection(attribute) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    let collection = get(this.args.model, attribute);
    collection.clear();
  }

  getActionLabel(title) {
    return this.intl.t(title, { name: this.visualLabel });
  }

  getModelLabel(label) {
    return this.intl.t(`model.${this.modelName}.${label}`);
  }

  validationFailed() {
    // empty method to override
  }

  postDelete() {
    this.router.transitionTo(this.listRoute);
  }

  beforeSave() {
    // empty method to override
  }

  postSave(saved, { isNew }) {
    if (isNew) {
      this.router.transitionTo(this.editRoute, saved.id);
    }
  }

  showSaveSuccessMessage() {
    this.alertMessage.success(this.getActionLabel('edit-view.actions.saved'));
  }

  deleteModel = task(
    {
      drop: true,
    },
    async () => {
      let { model } = this.args;
      let { loading, alertMessage, intl, store } = this;
      let deleteInfo = {
        id: model.id,
        type: this.modelName,
      };
      let translation = this.getActionLabel('edit-view.actions.deleted');
      loading.block();
      try {
        model.deleteRecord();
        await model.save();
        store.unloadRecord(model);
        alertMessage.success(translation);
        this.postDelete(deleteInfo);
      } catch (reason) {
        model.rollbackAttributes();
        if (isPermissionDenied(reason)) {
          alertMessage.error(intl.t('edit-view.actions.permission-denied'));
        } else {
          if (isForeignKeyError(reason)) {
            let foreignKeyMessage = intl.t('edit-view.actions.foreign-key-error', {
              name: this.visualLabel,
            });
            alertMessage.error(foreignKeyMessage);
            this.isShowingDeleteModal = false;
          } else {
            alertMessage.error(
              intl.t('edit-view.actions.generic-delete-error', {
                name: this.visualLabel,
              })
            );
          }
        }
      } finally {
        loading.unblock();
        this.isShowingDeleteModal = false;
      }
    }
  );

  saveModel = task(
    {
      drop: true,
    },
    async () => {
      let { model } = this.args;
      let { alertMessage, intl, loading } = this;
      let { isNew } = model;
      this.beforeSave();

      if (model.validate()) {
        loading.block();
        try {
          let saved = await model.save();
          this.showSaveSuccessMessage();
          this.postSave(saved, { isNew });
        } catch (reason) {
          if (isPermissionDenied(reason)) {
            alertMessage.error(intl.t('edit-view.actions.permission-denied'));
          } else {
            if (isDuplicateError(reason)) {
              let duplicateMessage = intl.t('edit-view.actions.unique-violation');
              alertMessage.error(duplicateMessage);
            } else {
              alertMessage.error(intl.t('edit-view.actions.error', { name: this.title }));
            }
          }
        } finally {
          loading.unblock();
        }
      } else {
        this.validationFailed(model);
        let errors = [];
        model.errors.forEach((error) => {
          errors.pushObject(error);
        });
        this.analytics.trackEvent(EVENTS.VALIDATION_FAILED, {
          id: model.id,
          type: this.modelName,
          errors,
        });

        alertMessage.error(intl.t('edit-view.actions.validation-failed'));
      }
    }
  );

  @action
  showDeleteModal() {
    this.isShowingDeleteModal = true;
  }

  @action
  hideDeleteModal() {
    this.isShowingDeleteModal = false;
  }
}
