import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';

export default class NonDestructiveTestingsRoute extends ListRoute {
  @service
  store;

  model() {
    return this.store.query('non-destructive-testing', {});
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('totalCount', model.meta.records);
  }
}
