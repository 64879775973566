import { computed } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import AjaxService from 'ember-ajax/services/ajax';
import Env from 'weldnote/config/environment';

export default class _AjaxService extends AjaxService {
  namespace = '/api';

  @service
  session;

  @service
  store;

  @computed('session.session.authenticated.accessToken')
  get headers() {
    let headers = {
      'Client-Application': 'weldcloud-notes' 
    };
    let authToken = this.get('session.session.authenticated.accessToken');
    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    }
    return headers;
  }

  // Builds a url given the host and protocol and appends a part given by the consumer
  buildServerUrl(url) {
    let namespace = this.store.adapterFor('application').get('namespace');
    let host = `${location.protocol}//${location.hostname}/${namespace}`;
    if (!isEmpty(Env.apiHost)) {
      host = Env.apiHost;
    }
    return `${host}/${url}`;
  }
}
