export default {
  WELDING_MAP: {
    DESIGNATION: 'designation',
    COMPANY: 'company',
    MAP_DATE: 'mapDate',
    PIPING_PIECES: 'pipingPieces',
    PLATE_PIECES: 'platePieces',
    PQR_STANDARD: 'pqrStandard',
    PQR_EXAMINING_BODY: 'pqrExaminingBody',
    PQR_WELD_TYPE: 'pqrWeldType',
    PQR_WELDING_PROCESS_ROOT: 'pqrWeldingProcessRoot',
    PQR_WELDING_PROCESS_FILL: 'pqrWeldingProcessFill',
    PQR_WELDING_DETAILS: 'pqrWeldingDetails',
    PQR_BASE_MATERIAL1_GROUP: 'pqrBaseMaterial1Group',
    PQR_BASE_MATERIAL2_GROUP: 'pqrBaseMaterial2Group',
    PQR_FILLER_MATERIAL_ROOT: 'pqrFillerMaterialRoot',
    PQR_FILLER_MATERIAL_FILL: 'pqrFillerMaterialFill',
    PQR_FILLER_MATERIAL_GROUP_ROOT: 'pqrFillerMaterialGroupRoot',
    PQR_FILLER_MATERIAL_GROUP_FILL: 'pqrFillerMaterialGroupFill',
    PQR_HEAT_INPUT: 'pqrHeatInput',
    PQR_PREHEAT_TEMPERATURE: 'pqrPreheatTemperature',
    PQR_POST_WELD_HEAT_TREATMENT_TEMPERATURE: 'pqrPostWeldHeatTreatmentTemperature',
    PQR_IMPACT_TEST_TEMPERATURE: 'pqrImpactTestTemperature',
    PQR_HARDNESS_TEST_REQUIRED: 'pqrHardnessTestRequired',
    CERTIFICATE_STANDARD: 'certificateStandard',
    CERTIFICATE_EXAMINING_BODY: 'certificateExaminingBody',
    CERTIFICATE_WELD_TYPE: 'certificateWeldType',
    CERTIFICATE_WELDING_PROCESS_ROOT: 'certificateWeldingProcessRoot',
    CERTIFICATE_WELDING_PROCESS_FILL: 'certificateWeldingProcessFill',
    CERTIFICATE_WELDING_DETAILS: 'certificateWeldingDetails',
    CERTIFICATE_BASE_MATERIAL1_GROUP: 'certificateBaseMaterial1Group',
    CERTIFICATE_BASE_MATERIAL2_GROUP: 'certificateBaseMaterial2Group',
    CERTIFICATE_FILLER_MATERIAL_GROUP_ROOT: 'certificateFillerMaterialGroupRoot',
    CERTIFICATE_FILLER_MATERIAL_GROUP_FILL: 'certificateFillerMaterialGroupFill',
    CERTIFICATE_FILLER_MATERIAL_GROUP_CAP: 'certificateFillerMaterialGroupCap',
    CERTIFICATE_WELDING_POSITION_ROOT: 'certificateWeldingPositionRoot',
    CERTIFICATE_WELDING_POSITION_FILL: 'certificateWeldingPositionFill',
    CERTIFICATE_WELDERS: 'certificateWelders',
    WPS_STANDARD: 'wpsStandard',
    WPS_WELD_TYPE: 'wpsWeldType',
    WPS_WELDING_PROCESS_ROOT: 'wpsWeldingProcessRoot',
    WPS_WELDING_PROCESS_FILL: 'wpsWeldingProcessFill',
    WPS_WELDING_DETAILS: 'wpsWeldingDetails',
    WPS_BASE_MATERIAL1_GROUP: 'wpsBaseMaterial1Group',
    WPS_BASE_MATERIAL2_GROUP: 'wpsBaseMaterial2Group',
    WPS_FILLER_MATERIAL_ROOT: 'wpsFillerMaterialRoot',
    WPS_FILLER_MATERIAL_FILL: 'wpsFillerMaterialFill',
    WPS_FILLER_MATERIAL_GROUP_ROOT: 'wpsFillerMaterialGroupRoot',
    WPS_FILLER_MATERIAL_GROUP_FILL: 'wpsFillerMaterialGroupFill',
    WPS_HEAT_INPUT: 'wpsHeatInput',
    WPS_PREHEAT_TEMPERATURE: 'wpsPreheatTemperature',
    WPS_POST_WELD_HEAT_TREATMENT_TEMPERATURE: 'wpsPostWeldHeatTreatmentTemperature',
    WPS_WELDING_PROCEDURE_SPECIFICATIONS: 'wpsWeldingProcedureSpecifications',
    PQR_WELDING_PROCESS_CAP: 'pqrWeldingProcessCap',
    PQR_FILLER_MATERIAL_CAP: 'pqrFillerMaterialCap',
    CERTIFICATE_WELDING_PROCESS_CAP: 'certificateWeldingProcessCap',
    CERTIFICATE_WELDING_POSITION_CAP: 'certificateWeldingPositionCap',
    WPS_WELDING_PROCESS_CAP: 'wpsWeldingProcessCap',
    WPS_FILLER_MATERIAL_CAP: 'wpsFillerMaterialCap',
    PQR_SEARCH_FILLER_MATERIAL_ROOT_GROUP: 'pqrSearchFillerMaterialRootGroup',
    PQR_SEARCH_FILLER_MATERIAL_FILL_GROUP: 'pqrSearchFillerMaterialFillGroup',
    PQR_SEARCH_FILLER_MATERIAL_CAP_GROUP: 'pqrSearchFillerMaterialCapGroup',
    WPS_SEARCH_FILLER_MATERIAL_ROOT_GROUP: 'wpsSearchFillerMaterialRootGroup',
    WPS_SEARCH_FILLER_MATERIAL_FILL_GROUP: 'wpsSearchFillerMaterialFillGroup',
    WPS_SEARCH_FILLER_MATERIAL_CAP_GROUP: 'wpsSearchFillerMaterialCapGroup',
    PQR_THROAT_THICKNESS: 'pqrThroatThickness',
    WPS_THROAT_THICKNESS: 'wpsThroatThickness',
    PQR_SPECIAL_REQUIREMENTS: 'pqrSpecialRequirements',
    WPS_SPECIAL_REQUIREMENTS: 'wpsSpecialRequirements',
    CERTIFICATE_SPECIAL_REQUIREMENTS: 'certificateSpecialRequirements',
    PQR_OVERLAY_TYPE: 'pqrOverlayType',
    CERTIFICATE_OVERLAY_TYPE: 'certificateOverlayType',
    WPS_OVERLAY_TYPE: 'wpsOverlayType',
    CERTIFICATE_SEARCH_EXPIRED: 'certificateSearchExpired',
    PQR_WELDING_POSITION_ROOT: 'pqrWeldingPositionRoot',
    PQR_WELDING_POSITION_FILL: 'pqrWeldingPositionFill',
    PQR_WELDING_POSITION_CAP: 'pqrWeldingPositionCap',
    PQR_POST_WELD_HEAT_TREATMENT_PRESENCE: 'pqrPostWeldHeatTreatmentPresence',
    WPS_POST_WELD_HEAT_TREATMENT_PRESENCE: 'wpsPostWeldHeatTreatmentPresence',
    PQR_SEARCH_BY_WELDED_THICKNESS: 'pqrSearchByWeldedThickness',
    WPS_SEARCH_BY_WELDED_THICKNESS: 'wpsSearchByWeldedThickness',
  },
};
