import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"form-group\">\n  <label class=\"control-label\">{{@label}}</label>\n  <p class=\"form-control-static\">\n    {{#if this.hasValue}}\n      {{#if this.hasOnlyOneValue}}\n        {{this.onlyValue}}\n      {{else}}\n        {{#if this.sameValues}}\n          {{@min}}\n        {{else}}\n          {{@min}}\n          -\n          {{@max}}\n        {{/if}}\n      {{/if}}\n    {{/if}}\n  </p>\n</div>", {"contents":"<div class=\"form-group\">\n  <label class=\"control-label\">{{@label}}</label>\n  <p class=\"form-control-static\">\n    {{#if this.hasValue}}\n      {{#if this.hasOnlyOneValue}}\n        {{this.onlyValue}}\n      {{else}}\n        {{#if this.sameValues}}\n          {{@min}}\n        {{else}}\n          {{@min}}\n          -\n          {{@max}}\n        {{/if}}\n      {{/if}}\n    {{/if}}\n  </p>\n</div>","moduleName":"weldnote/components/model-pqr/flow-rate-display.hbs","parseOptions":{"srcName":"weldnote/components/model-pqr/flow-rate-display.hbs"}});
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';

interface ModelPqrFlowRateDisplayArgs {
  min?: Number,
  max?: Number,
  unit: String,
}

export default class ModelPqrFlowRateDisplay extends Component<ModelPqrFlowRateDisplayArgs> {

  get hasValue() {
    return !isEmpty(this.args.min) || !isEmpty(this.args.max);
  }

  get hasOnlyOneValue() {
    return isEmpty(this.args.min) || isEmpty(this.args.max);
  }

  get onlyValue() {
    if (!isEmpty(this.args.min)) {
      return this.args.min;
    } else if (!isEmpty(this.args.max)) {
      return this.args.max;
    }
    return '';
  }

  get sameValues() {
    if (!isEmpty(this.args.min) && !isEmpty(this.args.max)) {
      return this.args.min == this.args.max;
    }
    return true;
  }

}
