import { computed, get } from '@ember/object';
import { hasMany, belongsTo, attr, AsyncBelongsTo, SyncHasMany } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import { memberAction } from 'ember-api-actions';
import Constants from 'weldnote/utils/constants';
import { calculateCurrentState } from 'weldnote/utils/project-weld-state-parser';
import Project from './project';
import ProjectComponent from './project-component';
import WeldType from './weld-type';
import BaseMaterialCertificate from './base-material-certificate';
import BaseMaterial from './base-material';
import FillerMaterialCertificate from './filler-material-certificate';
import FillerMaterial from './filler-material';
import PwhtReportCertificate from './pwht-report-certificate';
import Fitter from './fitter';
import Welder from './welder';
import ProjectWeldInspection from './project-weld-inspection';
import { isEmpty } from '@ember/utils';

const { WELD_CONDITION, WELD_SITUATION } = Constants;

export default class ProjectWeld extends BaseModel {
  @attr('string')
  declare weldId?: string;

  @attr('number')
  declare weldNumber?: number;

  @belongsTo('project')
  declare project: AsyncBelongsTo<Project>;

  @belongsTo('project-component', { async: false })
  declare component: ProjectComponent;

  @attr('string')
  declare weldComponentNumber?: string;

  @belongsTo('weld-type', { async: false })
  declare weldType: WeldType;

  @belongsTo('base-material-certificate', { async: false })
  declare baseMaterial1: BaseMaterialCertificate;

  @belongsTo('base-material-certificate', { async: false })
  declare baseMaterial2: BaseMaterialCertificate;

  @belongsTo('base-material', { async: false })
  declare specificBaseMaterial1: BaseMaterial;

  @belongsTo('base-material', { async: false })
  declare specificBaseMaterial2: BaseMaterial;

  @belongsTo('welding-procedure-specification', { async: false })
  declare wps: any;

  @belongsTo('filler-material-certificate', { async: false })
  declare fillerMaterialRoot: FillerMaterialCertificate;

  @belongsTo('filler-material-certificate', { async: false })
  declare fillerMaterialFill: FillerMaterialCertificate;

  @belongsTo('filler-material-certificate', { async: false })
  declare fillerMaterialCap: FillerMaterialCertificate;

  @belongsTo('filler-material', { async: false })
  declare specificFillerMaterialProcess1: FillerMaterial;

  @belongsTo('filler-material', { async: false })
  declare specificFillerMaterialProcess2: FillerMaterial;

  @belongsTo('filler-material', { async: false })
  declare specificFillerMaterialProcess3: FillerMaterial;

  @belongsTo('pwht-report-certificate', { async: false })
  declare pwhtReport: PwhtReportCertificate;

  @belongsTo('fitter', { async: false })
  declare fitter: Fitter;

  @belongsTo('welder', { async: false })
  declare tackWelder: Welder;

  @hasMany('welder', { async: false })
  declare weldersRootLayer: SyncHasMany<Welder>;

  @hasMany('welder', { async: false })
  declare weldersFillLayer: SyncHasMany<Welder>;

  @hasMany('welder', { async: false })
  declare weldersCapLayer: SyncHasMany<Welder>;

  @attr('date')
  declare fitDate?: Date;

  @attr('date')
  declare weldDate?: Date;

  @attr('date')
  declare rootWeldDate?: Date;

  @attr('date')
  declare fillWeldDate?: Date;

  @attr('date')
  declare capWeldDate?: Date;

  @attr('date')
  declare pwhtDateCompleted?: Date;

  @attr('date')
  declare testDate?: Date;

  @attr('date')
  declare processedDate?: Date;

  @attr('date')
  declare shippedDate?: Date;

  @attr('string')
  declare status?: string;

  @attr('number')
  declare state?: number;

  @attr('string')
  declare type?: string;

  @hasMany('project-weld-inspection', { async: false })
  declare inspections: SyncHasMany<ProjectWeldInspection>;

  @attr('number')
  declare repairCountFromCleanState: number;

  @attr('boolean')
  declare requiresPwht?: boolean;

  @attr('date')
  declare completedDate?: Date;

  @attr('string')
  declare completedStatus?: string;

  @attr('string')
  declare situation?: string;

  @attr('boolean')
  declare componentDeleted?: boolean;

  get visualLabel() {
    return this.weldNumber;
  }

  validations = {
    component: {custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },},
  };

  metadata = {
    modelName: 'project-weld',

    component: {
      required: true,
    },
  };

  @computed('status', 'situation')
  get isActiveWeld() {
    let { status, situation } = this;
    if (status === WELD_CONDITION.GOOD && situation === WELD_SITUATION.ACTIVE) {
      return true;
    }
    return false;
  }

  @computed(
    'state',
    'fitDate',
    'weldDate',
    'pwhtDateCompleted',
    'testDate',
    'processedDate',
    'shippedDate'
  )
  get currentStatus() {
    return calculateCurrentState(this);
  }

  @computed('weldersRootLayer', 'weldersFillLayer', 'weldersCapLayer')
  get uniqueLayerWelders() {
    let { weldersRootLayer, weldersFillLayer, weldersCapLayer } = this;
    return [
      ...new Set( // we use the Set to exclude duplicates
        weldersRootLayer.toArray().concat(weldersFillLayer.toArray(), weldersCapLayer.toArray())
      ),
    ];
  }

  get isWeldDeleted(): boolean {
    return this.situation === WELD_SITUATION.DELETED;
  }

  repairWeld = memberAction({ path: 'repairWeld', type: 'PUT' });

  cutWeld = memberAction({ path: 'cutWeld', type: 'PUT' });

  renameWeld = memberAction({ path: 'renameWeld', type: 'PUT' });

  updateComponentNumber = memberAction({ path: 'updateComponentNumber', type: 'PUT' });

  duplicateWeld = memberAction({ path: 'duplicateWeld', type: 'PUT' });

  restoreWeld = memberAction({ path: 'restoreWeld', type: 'PUT' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-weld': ProjectWeld;
  }
}
