import classic from 'ember-classic-decorator';
import Service from '@ember/service';
import ENV from 'weldnote/config/environment';
import $ from 'jquery';
import { isEmpty } from '@ember/utils';

@classic
export default class CrispService extends Service {
  setup() {
    if (!isEmpty(ENV.chatKey)) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = ENV.chatKey;
      $.getScript('https://client.crisp.chat/l.js');
    }
  }

  hasCrisp() {
    return !!window.$crisp;
  }

  _callDo() {
    if (this.hasCrisp()) {
      return window.$crisp.do(...arguments);
    }
  }

  _callIs() {
    if (this.hasCrisp()) {
      return window.$crisp.is(...arguments);
    }
  }

  _callSet() {
    if (this.hasCrisp()) {
      return window.$crisp.set(...arguments);
    }
  }

  _callGet() {
    if (this.hasCrisp()) {
      return window.$crisp.get(...arguments);
    }
  }

  sendMessage(type, content) {
    this._callDo('message:send', [type, content]);
  }

  getIdentifier() {
    return this._callGet('session:identifier');
  }

  getData(key) {
    return this._callGet('session:data', key);
  }

  setData(key, value) {
    return this._callSet('session:data', [key, value]);
  }

  identifyUser(username, email) {
    if (this.hasCrisp()) {
      if (isEmpty(email)) {
        email = 'unknown@unknown.com';
      } else if (email.indexOf('@') < 0) {
        email = `${email}@unknown.com`;
      }
      if (isEmpty(username)) {
        username = 'unknown';
      }
      window.$crisp.push(['set', 'user:nickname', username]);
      window.$crisp.push(['set', 'user:email', email]);
    }
  }
}
