import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class CertificationSpecialRequirement extends BaseModel {
  @attr('string')
  declare name?: string;

  @attr('string')
  declare description?: string;

  get visualLabel(): string {
    return this.name || '';
  }

  validations = {
    name: { presence: true },
    description: { presence: true },
  };

  metadata = {
    modelName: 'certification-special-requirement',
    name: { required: true },
    description: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'certification-special-requirement': CertificationSpecialRequirement;
  }
}
