import NewRoute from 'weldnote/routes/_base/new';
import { service } from '@ember/service';

export default class _NewRoute extends NewRoute {
  @service
  store;

  modelName = 'weld-layer-configuration';

  model() {
    return this.store.createRecord('weld-layer-configuration', {
      imageSituation: 'TEMPLATE',
      upload: this.store.createRecord('file-upload'),
    });
  }
}
