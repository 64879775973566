import { set, get } from '@ember/object';
import PQRRules from 'weldnote/utils/pqr-rules';
import WPSRules from 'weldnote/utils/welding-procedure-specification-rules';

const { PQR } = PQRRules;

const { WELDING_PROCEDURE_SPECIFICATION } = WPSRules;

// Compares the weld types for PQR and WPS and copies the thickness from the correct fields
export function applyThicknessFromPQR(wps, pqr) {
  let applied = false;
  if (wps.isFilletWeld() && !pqr.isFilletWeld()) {
    set(
      wps,
      WELDING_PROCEDURE_SPECIFICATION.THICKNESS_MINIMUM,
      get(pqr, PQR.FILLET_WELD_BASE_MATERIAL_THICKNESS_MINIMUM_APPROVALRANGE)
    );
    set(
      wps,
      WELDING_PROCEDURE_SPECIFICATION.THICKNESS_MAXIMUM,
      get(pqr, PQR.FILLET_WELD_BASE_MATERIAL_THICKNESS_MAXIMUM_APPROVALRANGE)
    );
    applied = true;
  }
  if (wps.isPartialPenetrationButtWeld() && pqr.isCompletePenetrationButtWeld()) {
    set(
      wps,
      WELDING_PROCEDURE_SPECIFICATION.THICKNESS_MINIMUM,
      get(pqr, PQR.PJP_BUTT_WELD_BASE_MATERIAL_THICKNESS_MINIMUM_APPROVAL_RANGE)
    );
    set(
      wps,
      WELDING_PROCEDURE_SPECIFICATION.THICKNESS_MAXIMUM,
      get(pqr, PQR.PJP_BUTT_WELD_BASE_MATERIAL_THICKNESS_MAXIMUM_APPROVAL_RANGE)
    );
    applied = true;
  }
  if (!applied) {
    set(
      wps,
      WELDING_PROCEDURE_SPECIFICATION.THICKNESS_MINIMUM,
      get(pqr, PQR.THICKNESS_TOTAL_MINIMUM_APPROVALRANGE)
    );
    set(
      wps,
      WELDING_PROCEDURE_SPECIFICATION.THICKNESS_MAXIMUM,
      get(pqr, PQR.THICKNESS_TOTAL_MAXIMUM_APPROVALRANGE)
    );
  }
}
