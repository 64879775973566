import JSONAPISerializer from '@ember-data/serializer/json-api';

export default JSONAPISerializer.extend({
  // This is because of this issue
  // https://github.com/emberjs/data/issues/3881
  modelNameFromPayloadKey(key) {
    if (key === 'gases') {
      return 'gas';
    }
    return this._super(...arguments);
  },
});
