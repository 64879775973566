import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { set, get, action, computed } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';
import Certificate from 'weldnote/pods/miscellaneous-certificate/attributes';
import Env from 'weldnote/config/environment';

const {
  HTTP: { DEBOUNCE },
} = Constants;

const { MISCELLANEOUS_CERTIFICATE } = Certificate;

@classic
export default class MiscellaneousCertificatesController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  @service
  ajax;

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'miscellaneous-certificate';

  @alias('data.companyOptions')
  companyOptions;

  @alias('data.miscellaneousCertificateTypeOptions')
  typeOptions;

  searchWelder = task(
    {
      restartable: true,
    },
    async (term) => {
      await timeout(250);
      return get(this, 'data').findWelderByName(term);
    }
  );

  @computed
  get gridColumns() {
    let certificateNumberLabel = this.getAttributeLabel('certificateNumber');
    let typeLabel = this.getAttributeLabel('type');
    let expirationDateLabel = this.getAttributeLabel('expirationDate');
    let personLabel = this.getAttributeLabel('person');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'certificateNumber',
        label: certificateNumberLabel,
        cellComponent: 'weldnote-grid/miscellaneous-certificate-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'type.type',
        label: typeLabel,
        breakpoints: ['tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'certificatePerson.welderName',
        label: personLabel,
        cellComponent: 'weldnote-grid/person-name',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'expirationDate',
        label: expirationDateLabel,
        cellComponent: 'weldnote-grid/date-cell',
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
    ];
  }

  buildSingleFilter(controllerProperty, apiProperty, filters) {
    if (!isEmpty(this.get(controllerProperty))) {
      filters[apiProperty] = this.get(controllerProperty);
    }
  }

  buildFilters() {
    let finalFilters = {};
    this.buildSingleFilter('selectedCompany.id', MISCELLANEOUS_CERTIFICATE.COMPANY, finalFilters);
    this.buildSingleFilter('selectedType.id', MISCELLANEOUS_CERTIFICATE.TYPE, finalFilters);
    this.buildSingleFilter('personName', 'personName', finalFilters);
    this.buildSingleFilter(
      'certificateNumber',
      MISCELLANEOUS_CERTIFICATE.CERTIFICATE_NUMBER,
      finalFilters
    );
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllMiscellaneousCertificates(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setCertificateNumber(number) {
    set(this, 'certificateNumber', number);
    this.resetPage();
  }

  @action
  setPerson(person) {
    set(this, 'personName', person);
    this.resetPage();
  }

  @action
  setCompany(company) {
    set(this, 'selectedCompany', company);
    this.resetPage();
  }

  @action
  clearFields() {
    this.resetPage();
    this.setProperties({
      personName: null,
      selectedCompany: null,
      selectedType: null,
      certificateNumber: null,
    });
    this.loadData.perform();
  }

  @action
  download() {
    let { ajax, store } = this;
    let filters = this.buildFilters();
    filters.exportType = 'miscellaneous-certificate';
    ajax
      .request('/export/get-token', {
        method: 'POST',
        data: JSON.stringify(filters),
        dataType: 'json',
      })
      .then((data) => {
        let namespace = store.adapterFor('application').get('namespace');
        let host = `${location.protocol}//${location.hostname}/${namespace}`;
        if (!isEmpty(Env.apiHost)) {
          host = Env.apiHost;
        }
        window.location = `${host}/export/download?token=${data}`;
      });
  }
}
