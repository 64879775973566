import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { set, get } from '@ember/object';

@classic
export default class BaseMaterialRoute extends Route {
  @service('weldnote-data')
  data;

  model() {
    let materials = get(this, 'data').listAllProjectBaseMaterials({
      project: get(this.modelFor('project'), 'id'),
    });
    return materials;
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    set(controller, 'project', this.modelFor('project'));
    set(controller, 'materials', model);
    set(controller, 'totalCount', get(model, 'meta.records'));
  }
}
