import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import Project from './project';

export default class ProjectLineClass extends BaseModel {
  @belongsTo('project')
  declare project: AsyncBelongsTo<Project>;

  @attr('string')
  declare lineIdentifier?: string;

  @attr('string')
  declare name?: string;

  get visualLabel(): string {
    return this.lineIdentifier || '';
  }

  validations = {
    lineIdentifier: { presence: true },
  };

  metadata = {
    modelName: 'project-line-class',
    lineIdentifier: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-line-class': ProjectLineClass;
  }
}
