import { isEmpty } from '@ember/utils';
import {
  isStandardAWSB21,
  isStandardAWSD143,
  isStandardASME,
  isStandardAWSD11,
  isWelderStandardISO96061,
  isWelderStandardISO96062,
} from 'weldnote/utils/standards';
import AwsB21ApprovalRanges from './aws-b2-1';
import AwsD143ApprovalRanges from './aws-d14-3';
import CertificateAsmeIxApprovalRanges from './asme-ix';
import AwsD11ApprovalRanges from './aws-d1-1';
import Iso96061ApprovalRanges from './iso-9606-1';
import Iso96062ApprovalRanges from './iso-9606-2';

import Constants from 'weldnote/utils/constants';
import NotSupportedApprovalRanges from './non-supported';
import WelderCertificate from 'weldnote/models/welder-certificate';
import {
  NumericalRange,
  WelderCertificateApprovalRange,
} from 'weldnote/src-weldnote/wopq/approval-ranges/wopq-range-interface';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import BaseMaterial from 'weldnote/models/base-material';
import ElectrodeType from 'weldnote/models/electrode-type';
import FillerMaterial from 'weldnote/models/filler-material';
import WeldType from 'weldnote/models/weld-type';
import WeldingDetail from 'weldnote/models/welding-detail';
import WeldingPosition from 'weldnote/models/welding-position';
import WeldingProcess from 'weldnote/models/welding-process';
import ProcessMecanization from 'weldnote/models/process-mecanization';
import {
  AutomaticJointTracking,
  TestPieceValuesSingleProcess,
  AutomaticArcVoltageControl,
  VisualControl,
  WeldingOperatorSpecificWeldingDetails,
} from 'weldnote/src-weldnote/wopq/types';

const { UNIT_SYSTEM } = Constants;

export default class WpqApprovalRange implements WelderCertificateApprovalRange {
  certificate: WelderCertificate;

  approvalRange: WelderCertificateApprovalRange;

  data: any;

  constructor(certificate: WelderCertificate, data: any, unitSystem = UNIT_SYSTEM.METRIC) {
    this.certificate = certificate;
    this.data = data;
    let allData = {
      allWeldTypes: data.weldTypeOptions,
      allBaseMaterials: data.allBaseMaterialGroups,
      allWeldingDetails: data.weldingDetailOptions,
      allProcessMecanizations: data.processMecanizationOptions,
      allTypeCurrentPolarity: data.typeCurrentPolarityOptions,
      allWeldingPositions: data.weldingPositionOptions,
      allTransferModes: data.arcTransferModeOptions,
      allWeldingProcesses: data.weldingProcessOptions,
      allFillerMaterials: data.allFillerMaterialGroups,
      allElectrodes: data.electrodeTypeOptions,
      unitSystem,
    };

    if (isStandardASME(this.standard)) {
      this.approvalRange = new CertificateAsmeIxApprovalRanges(certificate, allData, unitSystem);
    } else if (isStandardAWSD11(this.standard)) {
      this.approvalRange = new AwsD11ApprovalRanges(certificate, allData, unitSystem);
    } else if (isWelderStandardISO96061(this.standard)) {
      this.approvalRange = new Iso96061ApprovalRanges(certificate, allData, unitSystem);
    } else if (isWelderStandardISO96062(this.standard)) {
      this.approvalRange = new Iso96062ApprovalRanges(certificate, allData);
    } else if (isStandardAWSB21(this.standard)) {
      this.approvalRange = new AwsB21ApprovalRanges(certificate, allData, unitSystem);
    } else if (isStandardAWSD143(this.standard)) {
      this.approvalRange = new AwsD143ApprovalRanges(certificate, allData, unitSystem);
    } else {
      this.approvalRange = new NotSupportedApprovalRanges();
    }
  }

  automaticJointTracking(
    testPiece: AutomaticJointTracking | null,
    process: TestPieceValuesSingleProcess
  ): AutomaticJointTracking[] {
    return this.approvalRange.automaticJointTracking(testPiece, process);
  }

  automaticVoltageControl(
    testPiece: AutomaticArcVoltageControl | null,
    process: TestPieceValuesSingleProcess
  ): AutomaticArcVoltageControl[] {
    return this.approvalRange.automaticVoltageControl(testPiece, process);
  }

  visualControl(
    testPiece: VisualControl | null,
    process: TestPieceValuesSingleProcess
  ): VisualControl[] {
    return this.approvalRange.visualControl(testPiece, process);
  }

  processMecanization(
    testPiece: ProcessMecanization,
    processValues: TestPieceValuesSingleProcess
  ): ProcessMecanization[] {
    return this.approvalRange.processMecanization(testPiece, processValues);
  }

  get standard() {
    return this.certificate.get('standard');
  }

  get productType() {
    return this.certificate.get('productTypeTestPiece');
  }

  get diameterValue() {
    return this.certificate.get('diameterTestPiece');
  }

  get weldingProcessRootName() {
    let weldingProcess = this.certificate.get('weldingProcessRootTestPiece');
    if (!isEmpty(weldingProcess)) {
      return weldingProcess.shortDesignation;
    }
    return '';
  }

  get weldingProcessFillName() {
    let weldingProcess = this.certificate.get('weldingProcessFillTestPiece');
    if (!isEmpty(weldingProcess)) {
      return weldingProcess.shortDesignation;
    }
    return '';
  }

  get weldingProcessCapName() {
    let weldingProcess = this.certificate.get('weldingProcessCapTestPiece');
    if (!isEmpty(weldingProcess)) {
      return weldingProcess.shortDesignation;
    }
    return '';
  }

  get weldTypeCode() {
    let weldType = this.certificate.get('weldTypeTestPiece');
    if (!isEmpty(weldType)) {
      return weldType.weldType;
    }
    return '';
  }

  get supplementaryFilletWeldTestRequired() {
    return this.certificate.get('supplementaryFilletWeldTestRequired');
  }

  get requalificationPeriod() {
    return this.certificate.get('requalificationPeriod');
  }

  electrodeType(electrode: ElectrodeType, processValues: TestPieceValuesSingleProcess) {
    return this.approvalRange.electrodeType(electrode, processValues);
  }

  thickness(testPiece: number) {
    return this.approvalRange.thickness(testPiece);
  }

  weldType(testPiece: WeldType) {
    return this.approvalRange.weldType(testPiece);
  }

  transferMode(testPiece: ArcTransferMode, processValues: TestPieceValuesSingleProcess) {
    return this.approvalRange.transferMode(testPiece, processValues);
  }

  weldingDetails(testPiece: WeldingDetail[]) {
    return this.approvalRange.weldingDetails(testPiece);
  }

  baseMaterial(testPiece: BaseMaterial) {
    return this.approvalRange.baseMaterial(testPiece);
  }

  weldingProcessRoot(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }

    return this.approvalRange.weldingProcessRoot(process);
  }

  weldingProcessFill(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }

    return this.approvalRange.weldingProcessFill(process);
  }

  weldingProcessCap(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    return this.approvalRange.weldingProcessCap(process);
  }

  shielding(process: WeldingProcess) {
    if (isEmpty(process)) {
      return null;
    }

    return this.approvalRange.shielding(process);
  }

  diameter(testPiece: number): NumericalRange {
    return this.approvalRange.diameter(testPiece);
  }

  weldingPosition(testPiece: WeldingPosition, processValues: TestPieceValuesSingleProcess) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return this.approvalRange.weldingPosition(testPiece, processValues);
  }

  fillerMaterial(testPiece: FillerMaterial, processValues: TestPieceValuesSingleProcess) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return this.approvalRange.fillerMaterial(testPiece, processValues);
  }

  internalDeadline(previousDate: Date) {
    return this.approvalRange.internalDeadline(previousDate);
  }

  externalDeadline(previousDate: Date) {
    return this.approvalRange.externalDeadline(previousDate);
  }

  weldingDetailsForOperator(
    testPiece: WeldingOperatorSpecificWeldingDetails,
    process: TestPieceValuesSingleProcess
  ): WeldingOperatorSpecificWeldingDetails[] {
    return this.approvalRange.weldingDetailsForOperator(testPiece, process);
  }
}
