import Helper from '@ember/component/helper';
import { isEmpty } from '@ember/utils';
import ENV from 'weldnote/config/environment';

export function hasFeature(params /* , hash*/) {
  let [collection, name] = params;

  if (isEmpty(collection) || isEmpty(name)) {
    return false;
  }

  if (ENV.environment === 'development') {
    // Don't enable GE in development
    if (name === 'ge-requirements' || name === 'ge-wps-pass') {
      return false;
    } else {
      return true;
    }
  }

  return collection.includes(name);
}

export default Helper.helper(hasFeature);
