export default {
  isSteelAndNickelAlloys(baseMaterialGroup) {
    return (
      baseMaterialGroup === '1 (1)' ||
      baseMaterialGroup === '1 (2)' ||
      baseMaterialGroup === '1 (3)' ||
      baseMaterialGroup === '1 (4)' ||
      baseMaterialGroup === '3 (1)' ||
      baseMaterialGroup === '3 (2)' ||
      baseMaterialGroup === '3 (3)' ||
      baseMaterialGroup === '4 (1)' ||
      baseMaterialGroup === '4 (2)' ||
      baseMaterialGroup === '5A (1)' ||
      baseMaterialGroup === '5B (1)' ||
      baseMaterialGroup === '5C (1)' ||
      baseMaterialGroup === '5C (3)' ||
      baseMaterialGroup === '5C (4)' ||
      baseMaterialGroup === '5C (5)' ||
      baseMaterialGroup === '6 (1)' ||
      baseMaterialGroup === '6 (2)' ||
      baseMaterialGroup === '6 (3)' ||
      baseMaterialGroup === '6 (4)' ||
      baseMaterialGroup === '7 (1)' ||
      baseMaterialGroup === '7 (2)' ||
      baseMaterialGroup === '8 (1)' ||
      baseMaterialGroup === '8 (2)' ||
      baseMaterialGroup === '8 (3)' ||
      baseMaterialGroup === '8 (4)' ||
      baseMaterialGroup === '9A (1)' ||
      baseMaterialGroup === '9B (1)' ||
      baseMaterialGroup === '9C (1)' ||
      baseMaterialGroup === '10A (1)' ||
      baseMaterialGroup === '10B (1)' ||
      baseMaterialGroup === '10C (1)' ||
      baseMaterialGroup === '10F (1)' ||
      baseMaterialGroup === '10H (1)' ||
      baseMaterialGroup === '10I (1)' ||
      baseMaterialGroup === '10J (1)' ||
      baseMaterialGroup === '10K (1)' ||
      baseMaterialGroup === '11A (1)' ||
      baseMaterialGroup === '11A (2)' ||
      baseMaterialGroup === '11A (3)' ||
      baseMaterialGroup === '11A (4)' ||
      baseMaterialGroup === '11A (5)' ||
      baseMaterialGroup === '11B (1)' ||
      baseMaterialGroup === '11B (2)' ||
      baseMaterialGroup === '11B (3)' ||
      baseMaterialGroup === '11B (4)' ||
      baseMaterialGroup === '11B (6)' ||
      baseMaterialGroup === '11B (8)' ||
      baseMaterialGroup === '11B (9)' ||
      baseMaterialGroup === '11B (10)' ||
      baseMaterialGroup === '11C (1)' ||
      baseMaterialGroup === '15E (1)' ||
      baseMaterialGroup === '15E (2)' ||
      baseMaterialGroup === '15E (3)' ||
      baseMaterialGroup === '15E (4)' ||
      baseMaterialGroup === '15E (5)' ||
      baseMaterialGroup === '15E (6)' ||
      baseMaterialGroup === '15F (1)' ||
      baseMaterialGroup === '15F (2)' ||
      baseMaterialGroup === '15F (3)' ||
      baseMaterialGroup === '15F (4)' ||
      baseMaterialGroup === '15F (5)' ||
      baseMaterialGroup === '15F (6)' ||
      baseMaterialGroup === '34 (N/A)' ||
      baseMaterialGroup === '41 (N/A)' ||
      baseMaterialGroup === '42 (N/A)' ||
      baseMaterialGroup === '43 (N/A)' ||
      baseMaterialGroup === '44 (N/A)' ||
      baseMaterialGroup === '45 (N/A)' ||
      baseMaterialGroup === '46 (N/A)' ||
      baseMaterialGroup === '49 (N/A)'
    );
  },

  isAluminiumAlloys(baseMaterialGroup) {
    return (
      baseMaterialGroup === '21 (N/A)' ||
      baseMaterialGroup === '22 (N/A)' ||
      baseMaterialGroup === '23 (N/A)' ||
      baseMaterialGroup === '25 (N/A)' ||
      baseMaterialGroup === '26 (N/A)'
    );
  },

  isTitaniumAndZirconiumAlloys(baseMaterialGroup) {
    return (
      baseMaterialGroup === '51 (N/A)' ||
      baseMaterialGroup === '52 (N/A)' ||
      baseMaterialGroup === '53 (N/A)' ||
      baseMaterialGroup === '61 (N/A)' ||
      baseMaterialGroup === '62 (N/A)'
    );
  },

  getSteelAndNickelAlloysApprovalRange() {
    return [
      '1 (1)',
      '1 (2)',
      '1 (3)',
      '1 (4)',
      '3 (1)',
      '3 (2)',
      '3 (3)',
      '4 (1)',
      '4 (2)',
      '5A (1)',
      '5B (1)',
      '5C (1)',
      '5C (3)',
      '5C (4)',
      '5C (5)',
      '6 (1)',
      '6 (2)',
      '6 (3)',
      '6 (4)',
      '7 (1)',
      '7 (2)',
      '8 (1)',
      '8 (2)',
      '8 (3)',
      '8 (4)',
      '9A (1)',
      '9B (1)',
      '9C (1)',
      '10A (1)',
      '10B (1)',
      '10C (1)',
      '10F (1)',
      '10H (1)',
      '10I (1)',
      '10J (1)',
      '10K (1)',
      '11A (1)',
      '11A (2)',
      '11A (3)',
      '11A (4)',
      '11A (5)',
      '11B (1)',
      '11B (2)',
      '11B (3)',
      '11B (4)',
      '11B (6)',
      '11B (8)',
      '11B (9)',
      '11B (10)',
      '11C (1)',
      '15E (1)',
      ' 15E (2)',
      '15E (3)',
      '15E (4)',
      '15E (5)',
      '15E (6)',
      '15F (1)',
      '15F (2)',
      '15F (3)',
      '15F (4)',
      '15F (5)',
      '15F (6)',
      '34 (N/A)',
      '41 (N/A)',
      '42 (N/A)',
      '43 (N/A)',
      '44 (N/A)',
      '45 (N/A)',
      '46 (N/A)',
      '49 (N/A)',
    ];
  },

  getAluminiumAlloysApprovalRange() {
    return ['21 (N/A)', '22 (N/A)', '23 (N/A)', '25 (N/A)', '26 (N/A)'];
  },

  getTitaniumAndZirconiumAlloysApprovalRange() {
    return ['51 (N/A)', '52 (N/A)', '53 (N/A)', '61 (N/A)', '62 (N/A)'];
  },
};
