export default {
  MISCELLANEOUS_CERTIFICATE: {
    CERTIFICATE_NUMBER: 'certificateNumber',
    EXPIRATION_DATE: 'expirationDate',
    REQUALIFICATION_PERIOD: 'requalificationPeriod',
    CERTIFICATE_DOCUMENT: 'certificateDocument',
    TYPE: 'type',
    CERTIFICATE_STATE: 'certificateState',
    COMPANY: 'company',
    PERSON: 'person',
  },
};
