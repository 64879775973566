import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias, equal } from '@ember/object/computed';
import Controller from '@ember/controller';
import { set, getProperties, action, computed } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';
import { A } from '@ember/array';

@classic
export default class WeldSketchesController extends Controller {
  @service
  intl;

  @service
  userSession;

  @service('weldnote-data')
  data;

  sketchType = 'grooveDesign';

  @equal('sketchType', 'grooveDesign')
  showGrooveDesignTab;

  @equal('sketchType', 'weldLayer')
  showWeldLayerConfigurationTab;

  description = null;

  longDescription = null;

  @alias('sketches.meta.records')
  totalCount;

  currentPage = 1;

  emptyCards = null;

  init() {
    super.init(...arguments);
    // This is just to have an array of five
    set(this, 'emptyCards', Array(5).fill(''));
  }

  @computed('model.grooveDesigns.lastSuccessful.value', 'sketches.[]')
  get currentSketches() {
    if (!isEmpty(this.sketches)) {
      return this.sketches;
    }
    if (this.showGrooveDesignTab) {
      let {
        model: { grooveDesigns },
      } = this;
      if (grooveDesigns) {
        let { lastSuccessful } = grooveDesigns;
        if (lastSuccessful) {
          return lastSuccessful.value;
        }
      }
    }
    return [];
  }

  @computed
  get grooveColumns() {
    let descriptionLabel = this.intl.t('model.groove-design.description.label');
    let longDescriptionLabel = this.intl.t('model.groove-design.longDescription.label');
    let imageLabel = this.intl.t('list-view.image-preview');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'description',
        label: descriptionLabel,
        cellComponent: 'weldnote-grid/groove-design-link',
      },
      {
        valuePath: 'longDescription',
        label: longDescriptionLabel,
        sortable: false,
      },
      {
        valuePath: 'renderedImagePath',
        label: imageLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
        cellComponent: 'weldnote-grid/image-cell',
      },
    ];
  }

  @computed
  get weldLayerColumns() {
    let descriptionLabel = this.intl.t('model.weld-layer-configuration.description.label');
    let longDescriptionLabel = this.intl.t('model.weld-layer-configuration.longDescription.label');
    let imageLabel = this.intl.t('list-view.image-preview');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'description',
        label: descriptionLabel,
        cellComponent: 'weldnote-grid/weld-layer-configuration-link',
      },
      {
        valuePath: 'longDescription',
        label: longDescriptionLabel,
        sortable: false,
      },
      {
        valuePath: 'renderedImagePath',
        label: imageLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
        cellComponent: 'weldnote-grid/image-cell',
      },
    ];
  }

  buildFilters() {
    let filters = getProperties(this, 'description', 'longDescription');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });
    return finalFilters;
  }

  @computed('model.latestSketches.lastSuccessful.value')
  get latestSketches() {
    let { lastSuccessful } = this.model.latestSketches;
    if (!isEmpty(lastSuccessful)) {
      let { value } = lastSuccessful;
      if (!isEmpty(value)) {
        let { grooves, weldLayers } = value;
        let result = A();
        grooves.forEach((g) => result.pushObject(g));
        weldLayers.forEach((w) => result.pushObject(w));
        result.sort((document1, document2) => {
          if (document1.modifiedOn < document2.modifiedOn) {
            return 1;
          }
          if (document1.modifiedOn > document2.modifiedOn) {
            return -1;
          }
          return 0;
        });
        return result.slice(0, 5);
      }
    }
    return [];
  }

  loadGrooveDesigns = task(
    {
      drop: true,
    },
    async () => {
      let options = {};
      options.page = this.currentPage;
      await timeout(300);
      let data = await this.data.listAllGrooveDesigns(options, this.buildFilters());
      set(this, 'sketches', data);
    }
  );

  loadWeldLayerConfigurations = task(
    {
      drop: true,
    },
    async () => {
      let options = {};
      options.page = this.currentPage;
      await timeout(300);
      let data = await this.data.listAllWeldLayerConfigurations(options, this.buildFilters());
      set(this, 'sketches', data);
    }
  );

  search = task(
    {
      drop: true,
    },
    async (pageNumber = 1) => {
      set(this, 'currentPage', pageNumber);
      if (this.showGrooveDesignTab) {
        await this.loadGrooveDesigns.perform();
      } else if (this.showWeldLayerConfigurationTab) {
        await this.loadWeldLayerConfigurations.perform();
      }
    }
  );

  @action
  setSection(value) {
    set(this, 'sketchType', value);
    this.search.perform();
  }

  @action
  clearFields() {
    set(this, 'description', null);
    set(this, 'longDescription', null);
    this.search.perform();
  }
}
