import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import IndustryCode from './industry-code';

export default class ConstructionStandard extends BaseModel {
  @belongsTo('industry-code')
  declare industryCode: AsyncBelongsTo<IndustryCode>;

  @attr('string')
  declare designation: string;

  @attr('boolean')
  declare system: boolean;

  get visualLabel(): string {
    return this.designation || '';
  }

  validations = {
    industryCode: { presence: true },
    designation: { presence: true },
  };

  metadata = {
    modelName: 'construction-standard',
    industryCode: { required: true },
    designation: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'construction-standard': ConstructionStandard;
  }
}
