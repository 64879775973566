import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { empty, alias, equal, not } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { set, get, action, computed } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { convertDecimals } from 'weldnote/utils/decimal-utils';
import Constants from 'weldnote/utils/constants';
import filterFromCollectionByKey from 'weldnote/macros/find-from-collection-by-key';
import { task, timeout, restartableTask } from 'ember-concurrency';
import { toNumber, isFraction } from 'weldnote/utils/unit-system/units';
import Env from 'weldnote/config/environment';

const {
  WELD_TYPES,
  HTTP: { DEBOUNCE },
  UNIT_SYSTEM,
} = Constants;

@classic
export default class IeisController extends ListController {
  modelName = 'welding-procedure-specification';

  @service
  weldnoteData;

  @alias('weldnoteData')
  data;

  @service
  scroller;

  @service
  intl;

  @service
  userSession;

  @service
  ajax;

  @service
  store;

  @alias('data.productTypeOptions')
  productTypeOptions;

  @alias('data.standardOptions')
  standardOptions;

  @alias('data.weldingProcessOptions')
  weldingProcessesAll;

  @alias('data.weldTypeOptions')
  weldTypeOptionsAll;

  @alias('data.companyOptions')
  companyOptions;

  @alias('selectedStandard.code')
  industryCode;

  @alias('selectedStandard.code')
  currentIndustryCode;

  @alias('userSession.distanceUnit')
  distanceUnit;

  @alias('userSession.decimalSeparator')
  decimalSeparator;

  selectedStandard = null;

  showSearchParameters = false;

  showAdvancedParameters = false;

  dissmilarMaterials = false;

  baseMaterialThickness = null;

  selectedFillerMaterialRoot = null;

  selectedFillerMaterialFill = null;

  @empty('selectedStandard')
  fieldsDisabled;

  @not('fieldsDisabled')
  isStandardSelected;

  @filterFromCollectionByKey('weldTypeOptionsAll', 'industryCode.id', 'currentIndustryCode.id')
  weldTypeOptions;

  @filterFromCollectionByKey('weldingProcessesAll', 'industryCode.id', 'currentIndustryCode.id')
  weldingProcessOptions;

  @equal('selectedWeldType.weldType', WELD_TYPES.OVERLAY_WELD)
  isOverlayWeld;

  @not('isOverlayWeld')
  showDissimilarMaterials;

  @alias('data.overlayTypeOptions')
  overlayTypeOptions;

  getGridColumnLabel(label, options = {}) {
    return this.intl.t(`wps-list.grid-columns.${label}`, options);
  }

  @computed
  get gridColumns() {
    let wpsNumberLabel = this.getAttributeLabel('wpsNumber');
    let weldingProcessesLabel = this.getGridColumnLabel('welding-processes');
    let weldTypeLabel = this.getAttributeLabel('weldType');
    let baseMaterialsLabel = this.getGridColumnLabel('base-materials');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet', 'laptop'],
      },
      {
        valuePath: 'ieisNumber',
        label: this.intl.t('model.ieis.ieisNumber.label'),
        width: '200px',
        cellComponent: 'weldnote-grid/ieis-link',
        sortable: false,
      },
      {
        valuePath: 'wps',
        label: wpsNumberLabel,
        width: '200px',
        cellComponent: 'weldnote-grid/ieis-wps-link',
        sortable: false,
      },
      {
        valuePath: 'wps.weldType',
        width: '100px',
        label: weldTypeLabel,
        cellComponent: 'weldnote-grid/weld-type',
        sortable: false,
      },
      {
        valuePath: 'wps.weldingProcessRoot',
        label: weldingProcessesLabel,
        width: '120px',
        sortable: false,
        cellComponent: 'weldnote-grid/ieis-wps-process',
        breakpoints: ['tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'wps.baseMaterial1',
        label: baseMaterialsLabel,
        cellComponent: 'weldnote-grid/ieis-base-materials',
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'lineClass',
        label: this.intl.t('model.ieis.lineClass.label'),
        cellComponent: 'weldnote-grid/ieis-test-stages',
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
    ];
  }

  buildSingleFilter(controllerProperty, apiProperty, filters) {
    if (!isEmpty(get(this, controllerProperty))) {
      filters[apiProperty] = get(this, controllerProperty);
    }
  }

  buildDecimalFilter(controllerProperty, apiProperty, filters) {
    if (!isEmpty(get(this, controllerProperty))) {
      let decimalValue = get(this, controllerProperty);
      decimalValue = convertDecimals(decimalValue, this.userSession.decimalSeparator);
      filters[apiProperty] = decimalValue;
    }
  }

  buildPWHTFilter(filters) {
    let pwhtPresence = get(this, 'selectedPostWeldHeatTreamentPresence');
    if (!isEmpty(pwhtPresence)) {
      filters.pwhtPresence = pwhtPresence;
    } else {
      let pwhtTemp = get(this, 'selectedPostWeldHeatTreament');
      if (!isNaN(parseFloat(pwhtTemp))) {
        filters.pwhtTemperature = pwhtTemp;
      }
    }
  }

  buildFilters() {
    let filters = {};

    this.buildSingleFilter('selectedStandard.id', 'standard', filters);
    this.buildSingleFilter('selectedWeldType.id', 'weldType', filters);
    this.buildSingleFilter('selectedProcessRoot.id', 'weldingProcessRoot', filters);
    this.buildSingleFilter('selectedProcessFill.id', 'weldingProcessFill', filters);
    this.buildSingleFilter('selectedProcessCap.id', 'weldingProcessCap', filters);
    this.buildSingleFilter('selectedCompany.id', 'company', filters);
    this.buildSingleFilter('selectedInspectionClass.id', 'inspectionClass', filters);
    this.buildDecimalFilter('diameter', 'diameter', filters);
    this.buildDecimalFilter('thickness', 'thickness', filters);
    this.buildSingleFilter('selectedProductType.key', 'productType', filters);
    this.buildSingleFilter('selectedBaseMaterial1Group.id', 'baseMaterial1Group', filters);
    this.buildSingleFilter('selectedBaseMaterial2Group.id', 'baseMaterial2Group', filters);
    this.buildSingleFilter('selectedBaseMaterialGroup.id', 'baseMaterialGroup', filters);
    this.buildSingleFilter('selectedFillerMaterialRoot.id', 'fillerMaterialRoot', filters);
    this.buildSingleFilter('selectedFillerMaterialFill.id', 'fillerMaterialFill', filters);
    this.buildSingleFilter('selectedFillerMaterialCap.id', 'fillerMaterialCap', filters);
    this.buildSingleFilter('certificateNumber', 'ieisNumber', filters);
    this.buildSingleFilter('wpsNumber', 'wpsNumber', filters);
    this.buildSingleFilter('drawingNumber', 'drawingNumber', filters);
    this.buildPWHTFilter(filters);

    return filters;
  }

  loadData = restartableTask(async (otherOptions = {}) => {
    let options = {};
    options.page = this.currentPage;

    await timeout(DEBOUNCE);
    let data = await this.data.listAllIeis(options, this.buildFilters());
    set(this, 'model', data);
    set(this, 'totalCount', data.meta.records);
  });

  searchInspectionClasses = task(
    {
      drop: true,
    },
    async (term) => {
      return await this.data.listAllTestInspectionClasses(
        {},
        {
          name: term,
        }
      );
    }
  );

  clearProperties() {
    this.setProperties({
      selectedStandard: null,
      selectedWeldType: null,
      selectedProcessRoot: null,
      selectedProcessFill: null,
      selectedProcessCap: null,
      diameter: null,
      diameterDisplay: null,
      thickness: null,
      thicknessDisplay: null,
      selectedProductType: null,
      selectedBaseMaterial1Group: null,
      selectedBaseMaterial2Group: null,
      selectedFillerMaterialRoot: null,
      selectedFillerMaterialFill: null,
      selectedFillerMaterialCap: null,
      selectedOverlayType: null,
      certificateNumber: null,
      wpsNumber: null,
      drawingNumber: null,
      selectedCompany: null,
      selectedPostWeldHeatTreament: null,
      selectedPostWeldHeatTreamentPresence: null,
      selectedBaseMaterialGroup: null,
      baseMaterialThickness: null,
      baseMaterialThicknessDisplay: null,
      baseMaterial1Thickness: null,
      baseMaterial1ThicknessDisplay: null,
      baseMaterial2Thickness: null,
      baseMaterial2ThicknessDisplay: null,
    });
    this.resetPage();
    this.loadData.perform();
  }

  scrollToSearchResults() {
    this.scroller.scrollVertical('#ieis-search-grid');
  }

  resetPage() {
    set(this, 'currentPage', 1);
  }

  searchWPS(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform({
      userSearch: true,
    });
  }

  @action
  updateStandard(selectedStandard) {
    this.clearProperties();
    set(this, 'selectedStandard', selectedStandard);
    if (isEmpty(this.weldTypeOptionsAll)) {
      this.weldnoteData.getWeldTypes().then((types) => {
        set(this, 'weldTypeOptionsAll', types);
      });
    }
  }

  @action
  searchBaseMaterialGroup(term) {
    return this.data.findBaseMaterialGroup(term, get(this, 'currentIndustryCode.id'));
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.searchWPS(pageNumber);
    this.scrollToSearchResults();
  }

  @action
  clearSearch() {
    this.clearProperties();
  }

  @action
  fillerMaterialRootRemoved() {
    set(this, 'selectedFillerMaterialRoot', null);
  }

  @action
  fillerMaterialRootChosen(fillerMaterial) {
    set(this, 'selectedFillerMaterialRoot', fillerMaterial);
  }

  @action
  fillerMaterialFillChosen(fillerMaterial) {
    set(this, 'selectedFillerMaterialFill', fillerMaterial);
  }

  @action
  fillerMaterialCapChosen(fillerMaterial) {
    set(this, 'selectedFillerMaterialCap', fillerMaterial);
  }

  @action
  fillerMaterialFillRemoved() {
    set(this, 'selectedFillerMaterialFill', null);
  }

  @action
  fillerMaterialCapRemoved() {
    set(this, 'selectedFillerMaterialCap', null);
  }

  @action
  setSelectedWeldType(type) {
    if (isEmpty(type)) {
      set(this, 'selectedOverlayType', null);
      set(this, 'selectedWeldType', null);
    } else {
      set(this, 'selectedWeldType', type);
      if (type.get('weldType') !== WELD_TYPES.OVERLAY_WELD) {
        set(this, 'selectedOverlayType', null);
      }
    }
  }

  @action
  setCertificateNumber(number) {
    set(this, 'certificateNumber', number);
  }

  @action
  setWpsNumber(number) {
    set(this, 'wpsNumber', number);
  }

  @action
  setThickness(value) {
    set(this, 'thicknessDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'thickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'thickness', value);
      }
    } else {
      set(this, 'thickness', value);
    }
  }

  @action
  setDiameter(value) {
    set(this, 'diameterDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'diameter', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'diameter', value);
      }
    } else {
      set(this, 'diameter', value);
    }
  }

  @action
  setBaseMaterial1Thickness(value) {
    set(this, 'baseMaterial1ThicknessDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'baseMaterial1Thickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'baseMaterial1Thickness', value);
      }
    } else {
      set(this, 'baseMaterial1Thickness', value);
    }
  }

  @action
  setBaseMaterial2Thickness(value) {
    set(this, 'baseMaterial2ThicknessDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'baseMaterial2Thickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'baseMaterial2Thickness', value);
      }
    } else {
      set(this, 'baseMaterial2Thickness', value);
    }
  }

  @action
  setBaseMaterialThickness(value) {
    set(this, 'baseMaterialThicknessDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'baseMaterialThickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'baseMaterialThickness', value);
      }
    } else {
      set(this, 'baseMaterialThickness', value);
    }
  }

  @action
  setDissimilarMaterials(value) {
    if (value) {
      set(this, 'selectedBaseMaterialGroup', null);
      set(this, 'baseMaterialThickness', null);
    } else {
      set(this, 'selectedBaseMaterial1Group', null);
      set(this, 'selectedBaseMaterial2Group', null);
      set(this, 'baseMaterial1Thickness', null);
      set(this, 'baseMaterial2Thickness', null);
    }
    set(this, 'dissimilarMaterials', value);
  }

  download = task(
    {
      drop: true,
    },
    async () => {
      let filters = this.buildFilters();
      filters.exportType = 'ieis';
      let data = await this.ajax.request('/export/get-token', {
        method: 'POST',
        data: JSON.stringify(filters),
        dataType: 'json',
      });

      let namespace = this.store.adapterFor('application').get('namespace');
      let host = `${location.protocol}//${location.hostname}/${namespace}`;
      if (!isEmpty(Env.apiHost)) {
        host = Env.apiHost;
      }
      window.location = `${host}/export/download?token=${data}`;
    }
  );
}
