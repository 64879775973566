import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { get, getProperties, set, action, computed } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class WeldingBooksController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  @service
  analytics;

  @service
  help;

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'welding-book';

  isLoading = false;

  @computed
  get gridColumns() {
    let designationLabel = this.getAttributeLabel('designation');
    let projectLabel = this.getAttributeLabel('weldingProject');
    let customerLabel = this.getAttributeLabel('customer');
    let supplierLabel = this.getAttributeLabel('supplier');
    let standardLabel = this.getAttributeLabel('standard');
    let componentLabel = this.getAttributeLabel('component');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'designation',
        label: designationLabel,
        cellComponent: 'weldnote-grid/welding-book-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
        width: '250px',
      },
      {
        valuePath: 'projectName',
        cellComponent: 'weldnote-grid/project-link-from-welding-book',
        label: projectLabel,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'customer',
        cellComponent: 'weldnote-grid/project-client-link',
        label: customerLabel,
        breakpoints: ['tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'supplier',
        label: supplierLabel,
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'standard',
        label: standardLabel,
        breakpoints: ['desktop'],
        sortable: false,
      },
      {
        valuePath: 'componentName',
        label: componentLabel,
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
    ];
  }

  buildFilters() {
    let filters = getProperties(this, 'designation', 'weldingProject', 'customer', 'supplier');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });

    if (filters.supplier) {
      finalFilters.supplier = get(filters, 'supplier.id');
    }
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      let { dir = 'asc', sort } = getProperties(this, 'dir', 'sort');
      if (!isEmpty(sort)) {
        options.sort = {};
        options.sort[sort] = dir;
      }

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllWeldingBooks(options, this.buildFilters());

      set(this, 'model', data.list);
      set(this, 'totalCount', data.count);
    }
  );

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setDesignation(designation) {
    set(this, 'designation', designation);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setProject(project) {
    set(this, 'weldingProject', project);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setCustomer(customer) {
    set(this, 'customer', customer);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setSupplier(supplier) {
    set(this, 'supplier', supplier);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  clearFields() {
    this.resetPage();
    this.setProperties({
      designation: null,
      weldingProject: null,
      customer: null,
      supplier: null,
    });
    this.loadData.perform();
  }

  @action
  displayHelpVideo() {
    set(this, 'showVideo', true);
    let { analytics, help } = this;
    analytics.trackEvent(help.convertVideoIdToAnalyticsEvent(help.weldingBookVideo), {
      location: 'welding-book-list',
    });
  }
}
