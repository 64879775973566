import { isEmpty } from '@ember/utils';
import WelderStandard from 'weldnote/models/welder-standard';
import { isStandardAWS, isWelderStandardISO96061, isStandardASME } from 'weldnote/utils/standards';
import { isStandardAWSB21 } from './standards';

export default {
  WELDER_CERTIFICATE: {
    CERTIFICATE_NUMBER: 'certificateNumber',
    EXAMINING_BODY: 'examiningBody',
    DESIGNATION: 'designation',
    WELDER: 'welder',
    COMPANY: 'company',
    STANDARD: 'standard',
    CONSTRUCTION_STANDARD: 'constructionStandard',
    JOB_KNOWLEDGE: 'jobKnowledge',
    EMISSION_DATE: 'emissionDate',
    PREVIOUS_EXTERNAL_REQUALIFICATION_DATE: 'previousExternalRequalificationDate',
    INTERNAL_REQUALIFICATION_DATE: 'internalRequalificationDate',
    INTERNAL_REQUALIFICATION_DEADLINE: 'internalRequalificationDeadline',
    EXTERNAL_REQUALIFICATION_DEADLINE: 'externalRequalificationDeadline',
    WELDING_PROCESS_ROOT_TEST_PIECE: 'weldingProcessRootTestPiece',
    WELDING_PROCESS_FILL_TEST_PIECE: 'weldingProcessFillTestPiece',
    WELD_TYPE_TEST_PIECE: 'weldTypeTestPiece',
    SUPPLEMENTARY_FILLET_WELD_TEST: 'supplementaryFilletWeldTest',
    ARC_TRANSFER_MODE_ROOT_TEST_PIECE: 'arcTransferModeRootTestPiece',
    ARC_TRANSFER_MODE_FILL_TEST_PIECE: 'arcTransferModeFillTestPiece',
    WELDING_DETAIL_TEST_PIECE: 'weldingDetailTestPiece',
    WELDING_POSITION_ROOT_TEST_PIECE: 'weldingPositionRootTestPiece',
    WELDING_POSITION_FILL_TEST_PIECE: 'weldingPositionFillTestPiece',
    TYPE_CURRENT_ROOT_TEST_PIECE: 'typeCurrentRootTestPiece',
    TYPE_CURRENT_FILL_TEST_PIECE: 'typeCurrentFillTestPiece',
    BASE_MATERIAL1_TEST_PIECE: 'baseMaterial1TestPiece',
    BASE_MATERIAL2_TEST_PIECE: 'baseMaterial2TestPiece',
    PRODUCT_TYPE_TEST_PIECE: 'productTypeTestPiece',
    THICKNESS_ROOT_TEST_PIECE: 'thicknessRootTestPiece',
    THICKNESS_ROOT_LAYER_TEST_PIECE: 'thicknessRootLayerTestPiece',
    THICKNESS_FILL_TEST_PIECE: 'thicknessFillTestPiece',
    THICKNESS_FILL_LAYER_TEST_PIECE: 'thicknessFillLayerTestPiece',
    THICKNESS_TOTAL_TEST_PIECE: 'thicknessTotalTestPiece',
    DIAMETER_TEST_PIECE: 'diameterTestPiece',
    FILLER_MATERIAL_ROOT_TEST_PIECE: 'fillerMaterialRootTestPiece',
    FILLER_MATERIAL_ROOT_COMMERCIAL_DESIGNATION_TEST_PIECE:
      'fillerMaterialRootCommercialDesignationTestPiece',

    FILLER_MATERIAL_FILL_TEST_PIECE: 'fillerMaterialFillTestPiece',
    FILLER_MATERIAL_FILL_COMMERCIAL_DESIGNATION_TEST_PIECE:
      'fillerMaterialFillCommercialDesignationTestPiece',

    ELECTRODE_TYPE_ROOT_TEST_PIECE: 'electrodeTypeRootTestPiece',
    ELECTRODE_TYPE_FILL_TEST_PIECE: 'electrodeTypeFillTestPiece',
    SHIELDING_ROOT: 'shieldingRoot',
    SHIELDING_ROOT_GAS_TEST_PIECE: 'shieldingRootGasTestPiece',
    SHIELDING_ROOT_GAS_COMMERCIAL_DESIGNATION_TEST_PIECE:
      'shieldingRootGasCommercialDesignationTestPiece',

    SHIELDING_ROOT_GAS_MIXTURE: 'shieldingRootGasMixture',
    SHIELDING_ROOT_FLUX_TEST_PIECE: 'shieldingRootFluxTestPiece',
    SHIELDING_ROOT_FLUX_COMMERCIAL_DESIGNATION_TEST_PIECE:
      'shieldingRootFluxCommercialDesignationTestPiece',

    FLOW_RATE_ROOT: 'flowRateRoot',
    SHIELDING_FILL: 'shieldingFill',
    SHIELDING_FILL_GAS_TEST_PIECE: 'shieldingFillGasTestPiece',
    SHIELDING_FILL_GAS_COMMERCIAL_DESIGNATION_TEST_PIECE:
      'shieldingFillGasCommercialDesignationTestPiece',

    SHIELDING_FILL_GAS_MIXTURE: 'shieldingFillGasMixture',
    SHIELDING_FILL_FLUX_TEST_PIECE: 'shieldingFillFluxTestPiece',
    SHIELDING_FILL_FLUX_COMMERCIAL_DESIGNATION_TEST_PIECE:
      'shieldingFillFluxCommercialDesignationTestPiece',

    FLOW_RATE_FILL: 'flowRateFill',
    BACKING_TEST_PIECE: 'backingTestPiece',
    BACKING_GAS_TEST_PIECE: 'backingGasTestPiece',
    BACKING_GAS_COMMERCIAL_DESIGNATION_TEST_PIECE: 'backingGasCommercialDesignationTestPiece',
    BACKING_GAS_MIXTURE: 'backingGasMixture',
    BACKING_FLUX_TEST_PIECE: 'backingFluxTestPiece',
    BACKING_FLUX_COMMERCIAL_DESIGNATION_TEST_PIECE: 'backingFluxCommercialDesignationTestPiece',
    BACKING_FLOW_RATE_ROOT: 'backingFlowRateRoot',
    BACKING_FLOW_RATE_FILL: 'backingFlowRateFill',
    WELDING_PROCESS_ROOT_APPROVAL_RANGE: 'weldingProcessRootApprovalRange',
    WELDING_PROCESS_FILL_APPROVAL_RANGE: 'weldingProcessFillApprovalRange',
    WELD_TYPE_APPROVAL_RANGE: 'weldTypeApprovalRange',
    ARC_TRANSFER_MODE_ROOT_APPROVAL_RANGE: 'arcTransferModeRootApprovalRange',
    ARC_TRANSFER_MODE_FILL_APPROVAL_RANGE: 'arcTransferModeFillApprovalRange',
    WELDING_DETAILS_APPROVAL_RANGE: 'weldingDetailsApprovalRange',
    WELDING_POSITION_ROOT_APPROVAL_RANGE: 'weldingPositionRootApprovalRange',
    WELDING_POSITION_FILL_APPROVAL_RANGE: 'weldingPositionFillApprovalRange',
    BASE_MATERIAL1_APPROVAL_RANGE: 'baseMaterial1ApprovalRange',
    BASE_MATERIAL2_APPROVAL_RANGE: 'baseMaterial2ApprovalRange',
    THICKNESS_ROOT_MINIMUM_APPROVAL_RANGE: 'thicknessRootMinimumApprovalRange',
    THICKNESS_ROOT_MAXIMUM_APPROVAL_RANGE: 'thicknessRootMaximumApprovalRange',
    THICKNESS_FILL_MINIMUM_APPROVAL_RANGE: 'thicknessFillMinimumApprovalRange',
    THICKNESS_FILL_MAXIMUM_APPROVAL_RANGE: 'thicknessFillMaximumApprovalRange',
    THICKNESS_TOTAL_MINIMUM_APPROVAL_RANGE: 'thicknessTotalMinimumApprovalRange',
    THICKNESS_TOTAL_MAXIMUM_APPROVAL_RANGE: 'thicknessTotalMaximumApprovalRange',
    DIAMETER_MINIMUM_APPROVAL_RANGE: 'diameterMinimumApprovalRange',
    DIAMETER_MAXIMUM_APPROVAL_RANGE: 'diameterMaximumApprovalRange',
    FILLER_MATERIAL_ROOT_APPROVAL_RANGE: 'fillerMaterialRootApprovalRange',
    FILLER_MATERIAL_FILL_APPROVAL_RANGE: 'fillerMaterialFillApprovalRange',
    FILLER_MATERIAL_CAP_APPROVAL_RANGE: 'fillerMaterialCapApprovalRange',
    ELECTRODE_TYPE_ROOT_APPROVAL_RANGE: 'electrodeTypeRootApprovalRange',
    ELECTRODE_TYPE_FILL_APPROVAL_RANGE: 'electrodeTypeFillApprovalRange',
    SHIELDING_ROOT_GAS_APPROVAL_RANGE: 'shieldingRootGasApprovalRange',
    SHIELDING_ROOT_GAS_COMMERCIAL_DESIGNATION_APPROVAL_RANGE:
      'shieldingRootGasCommercialDesignationApprovalRange',

    SHIELDING_ROOT_FLUX_APPROVAL_RANGE: 'shieldingRootFluxApprovalRange',
    SHIELDING_ROOT_FLUX_COMMERCIAL_DESIGNATION_APPROVAL_RANGE:
      'shieldingRootFluxCommercialDesignationApprovalRange',

    SHIELDING_FILL_GAS_APPROVAL_RANGE: 'shieldingFillGasApprovalRange',
    SHIELDING_FILL_GAS_COMMERCIAL_DESIGNATION_APPROVAL_RANGE:
      'shieldingFillGasCommercialDesignationApprovalRange',

    SHIELDING_FILL_FLUX_APPROVAL_RANGE: 'shieldingFillFluxApprovalRange',
    SHIELDING_FILL_FLUX_COMMERCIAL_DESIGNATION_APPROVAL_RANGE:
      'shieldingFillFluxCommercialDesignationApprovalRange',

    BACKING_GAS_APPROVAL_RANGE: 'backingGasApprovalRange',
    BACKING_GAS_COMMERCIAL_DESIGNATION_APPROVAL_RANGE:
      'backingGasCommercialDesignationApprovalRange',

    BACKING_FLUX_APPROVAL_RANGE: 'backingFluxApprovalRange',
    BACKING_FLUX_COMMERCIAL_DESIGNATION_APPROVAL_RANGE:
      'backingFluxCommercialDesignationApprovalRange',

    SUPPLEMENTARY_FILLET_WELD_TEST_REQUIRED: 'supplementaryFilletWeldTestRequired',
    EXPIRED: 'expired',
    REQUALIFICATION_PERIOD: 'requalificationPeriod',
    CERTIFICATE_STATE: 'certificateState',
    WELDING_PROCESS_CAP_TEST_PIECE: 'weldingProcessCapTestPiece',
    WELDING_PROCESS_CAP_APPROVAL_RANGE: 'weldingProcessCapApprovalRange',
    ARC_TRANSFER_MODE_CAP_TEST_PIECE: 'arcTransferModeCapTestPiece',
    ARC_TRANSFER_MODE_CAP_APPROVAL_RANGE: 'arcTransferModeCapApprovalRange',
    WELDING_POSITION_CAP_TEST_PIECE: 'weldingPositionCapTestPiece',
    WELDING_POSITION_CAP_APPROVAL_RANGE: 'weldingPositionCapApprovalRange',
    TYPE_CURRENT_CAP_TEST_PIECE: 'typeCurrentCapTestPiece',
    THICKNESS_CAP_TEST_PIECE: 'thicknessCapTestPiece',
    THICKNESS_CAP_LAYER_TEST_PIECE: 'thicknessCapLayerTestPiece',
    THICKNESS_CAP_MINIMUM_APPROVAL_RANGE: 'thicknessCapMinimumApprovalRange',
    THICKNESS_CAP_MAXIMUM_APPROVAL_RANGE: 'thicknessCapMaximumApprovalRange',
    FILLER_MATERIAL_CAP_TEST_PIECE: 'fillerMaterialCapTestPiece',
    FILLER_MATERIAL_CAP_COMMERCIAL_DESIGNATION_TEST_PIECE:
      'fillerMaterialCapCommercialDesignationTestPiece',

    ELECTRODE_TYPE_CAP_TEST_PIECE: 'electrodeTypeCapTestPiece',
    ELECTRODE_TYPE_CAP_APPROVAL_RANGE: 'electrodeTypeCapApprovalRange',
    SHIELDING_CAP: 'shieldingCap',
    SHIELDING_CAP_GAS_TEST_PIECE: 'shieldingCapGasTestPiece',
    SHIELDING_CAP_GAS_COMMERCIAL_DESIGNATION_TEST_PIECE:
      'shieldingCapGasCommercialDesignationTestPiece',

    SHIELDING_CAP_GAS_MIXTURE: 'shieldingCapGasMixture',
    SHIELDING_CAP_FLUX_TEST_PIECE: 'shieldingCapFluxTestPiece',
    SHIELDING_CAP_FLUX_COMMERCIAL_DESIGNATION_TEST_PIECE:
      'shieldingCapFluxCommercialDesignationTestPiece',

    FLOW_RATE_CAP: 'flowRateCap',
    BACKING_FLOW_RATE_CAP: 'backingFlowRateCap',
    BASE_MATERIAL1_THICKNESS: 'baseMaterial1Thickness',
    BASE_MATERIAL1_DIAMETER: 'baseMaterial1Diameter',
    BASE_MATERIAL2_THICKNESS: 'baseMaterial2Thickness',
    BASE_MATERIAL2_DIAMETER: 'baseMaterial2Diameter',
    WELDING_POSITION_ROOT_DIRECTION: 'weldingPositionRootDirection',
    WELDING_POSITION_FILL_DIRECTION: 'weldingPositionFillDirection',
    WELDING_POSITION_CAP_DIRECTION: 'weldingPositionCapDirection',
    SPECIAL_REQUIREMENTS: 'specialRequirements',
    OVERLAY_TYPE: 'overlayType',
    JOINT_TYPE: 'jointType',
    PROCESS_MECANIZATION_ROOT_TEST_PIECE: 'processMecanizationRootTestPiece',
    PROCESS_MECANIZATION_FILL_TEST_PIECE: 'processMecanizationFillTestPiece',
    PROCESS_MECANIZATION_CAP_TEST_PIECE: 'processMecanizationCapTestPiece',
  },

  isArcTransferModeDisabled(standard: WelderStandard, weldingProcess: string): boolean {
    if (isEmpty(weldingProcess)) {
      return false;
    }
    if (isWelderStandardISO96061(standard)) {
      let weldingProcesses = ['114', '131', '135', '136', '138'];
      if (weldingProcesses.includes(weldingProcess)) {
        return false;
      }
      return true;
    } else if (isStandardASME(standard) || isStandardAWS(standard) || isStandardAWSB21(standard)) {
      let weldingProcesses = ['GMAW', 'FCAW', 'MCAW', 'FCAW-S'];
      if (weldingProcesses.includes(weldingProcess)) {
        return false;
      }
      return true;
    }
    return true;
  },
};
