import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (not this.isMobile)}}\n  <AttachTooltip @class={{concat \"tooltip-weldnote \" @class}}>\n    {{@text}}\n  </AttachTooltip>\n{{/if}}", {"contents":"{{#if (not this.isMobile)}}\n  <AttachTooltip @class={{concat \"tooltip-weldnote \" @class}}>\n    {{@text}}\n  </AttachTooltip>\n{{/if}}","moduleName":"weldnote/components/w-tooltip.hbs","parseOptions":{"srcName":"weldnote/components/w-tooltip.hbs"}});
import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { service } from '@ember/service';
import { or } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@tagName('')
export default class WTooltip extends Component {
  @service
  media;

  @or('media.{isMobile,isTablet}')
  isMobile;
}
