import { belongsTo, attr } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';

export default class ImpactTestLine extends BaseModel {
  @attr('number')
  declare orderNum?: number;

  @attr('string')
  declare testPieceLocation?: string;

  @attr('number')
  declare requirements?: number;

  @attr('number')
  declare value1Energy?: number;

  @attr('number')
  declare value2Energy?: number;

  @attr('number')
  declare value3Energy?: number;

  @attr('number')
  declare shearPercentage?: number;

  @attr('number')
  declare lateralExpansion?: number;

  @attr('decimal-value')
  declare averageEnergy?: number;

  @belongsTo('pqr')
  declare pqr: any;

  metadata = {
    modelName: 'impact-test-line',
  };

  isLineEmpty(): boolean {
    let allEmpty =
      isEmpty(this.testPieceLocation) &&
      isEmpty(this.requirements) &&
      isEmpty(this.value1Energy) &&
      isEmpty(this.value2Energy) &&
      isEmpty(this.value3Energy) &&
      isEmpty(this.shearPercentage) &&
      isEmpty(this.lateralExpansion) &&
      isEmpty(this.averageEnergy);
    return allEmpty;
  }

  validations = {
    testPieceLocation: {
      length: [0, 30],
    },

    requirements: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    value1Energy: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    value2Energy: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    value3Energy: {
      numericality: {
        allowBlank: true,
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },

    averageEnergy: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 999,
      },
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'impact-test-line': ImpactTestLine;
  }
}
