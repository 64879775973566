import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';

@classic
export default class IeisRoute extends ListRoute {
  @service('weldnote-data')
  data;

  @service
  userSession;

  @service
  router;

  beforeModel() {
    super.beforeModel(...arguments);
    let { userSession: session } = this;
    if (!session.hasFeature('iies')) {
      this.router.transitionTo('index');
    }
  }

  model() {
    return this.data.listAllIeis();
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('totalCount', model.meta.records);
  }
}
