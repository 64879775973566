import { belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import TestStageName from './test-stage-name';
import TestStateTest from './test-stage-test';
import TestInspectionClass from './test-inspection-class';
import TestLocation from './test-location';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default class TestStage extends BaseModel {
  @belongsTo('test-stage-name', { async: false })
  declare name: TestStageName;

  @hasMany('test-stage-test', { async: false })
  declare tests: SyncHasMany<TestStateTest>;

  @belongsTo('test-inspection-class', { async: false })
  declare inspectionClass: TestInspectionClass;

  @belongsTo('test-location', { async: false })
  declare location: TestLocation;

  get visualLabel() {
    let name = get(this, 'name');
    if (name) {
      return name.name;
    }
    return '';
  }

  validations = {
    name: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'test-stage',
    name: {
      required: true,
    },
  };
}
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'test-stage': TestStage;
  }
}
