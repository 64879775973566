import { isEmpty } from '@ember/utils';

import AwsD11ApprovalRanges from './aws-d1-1';

import Constants from 'weldnote/utils/constants';
import WelderCertificate from 'weldnote/models/welder-certificate';
import ElectrodeType from 'weldnote/models/electrode-type';
import WeldingProcess from 'weldnote/models/welding-process';
import WeldType from 'weldnote/models/weld-type';
import ArcTransferMode from 'weldnote/models/arc-transfer-mode';
import WeldingDetail from 'weldnote/models/welding-detail';
import BaseMaterial from 'weldnote/models/base-material';
import WeldingPosition from 'weldnote/models/welding-position';
import FillerMaterial from 'weldnote/models/filler-material';
import { NumericalRange, WelderCertificateApprovalRange } from './wopq-range-interface';
import {
  isStandardASME,
  isStandardAWSB21,
  isStandardAWSD11,
  isWelderStandardISO14732,
} from 'weldnote/utils/standards';
import NonSupportedApprovalRange from './non-supported';
import WeldingOperatorAsmeIxApprovalRanges from './asme-ix';
import AwsB21ApprovalRanges from './aws-b2-1';
import Iso14732ApprovalRanges from './iso-14732';
import processMecanization from 'weldnote/models/process-mecanization';
import {
  AutomaticJointTracking,
  TestPieceValuesSingleProcess,
  AutomaticArcVoltageControl,
  VisualControl,
  WeldingOperatorSpecificWeldingDetails,
} from '../types';
const { UNIT_SYSTEM } = Constants;

export default class WopqApprovalRange implements WelderCertificateApprovalRange {
  private certificate: WelderCertificate;

  private approvalRange: WelderCertificateApprovalRange;

  constructor(certificate: WelderCertificate, data: any, unitSystem = UNIT_SYSTEM.METRIC) {
    this.certificate = certificate;
    let allData = {
      allWeldTypes: data.weldTypeOptions,
      allBaseMaterials: data.allBaseMaterialGroups,
      allWeldingDetails: data.weldingDetailOptions,
      allProcessMecanizations: data.processMecanizationOptions,
      allTypeCurrentPolarity: data.typeCurrentPolarityOptions,
      allWeldingPositions: data.weldingPositionOptions,
      allTransferModes: data.arcTransferModeOptions,
      allWeldingProcesses: data.weldingProcessOptions,
      allFillerMaterials: data.allFillerMaterialGroups,
      allElectrodes: data.allElectrodes,
      unitSystem,
    };
    if (isWelderStandardISO14732(this.standard)) {
      this.approvalRange = new Iso14732ApprovalRanges(certificate, allData, unitSystem);
    } else if (isStandardASME(this.standard)) {
      this.approvalRange = new WeldingOperatorAsmeIxApprovalRanges(
        certificate,
        allData,
        unitSystem
      );
    } else if (isStandardAWSD11(this.standard)) {
      this.approvalRange = new AwsD11ApprovalRanges(certificate, allData, unitSystem);
    } else if (isStandardAWSB21(this.standard)) {
      this.approvalRange = new AwsB21ApprovalRanges(certificate, allData, unitSystem);
    } else {
      this.approvalRange = new NonSupportedApprovalRange();
    }
  }

  automaticJointTracking(
    testPiece: AutomaticJointTracking | null,
    process: TestPieceValuesSingleProcess
  ): AutomaticJointTracking[] {
    return this.approvalRange.automaticJointTracking(testPiece, process);
  }

  automaticVoltageControl(
    testPiece: AutomaticArcVoltageControl | null,
    process: TestPieceValuesSingleProcess
  ): AutomaticArcVoltageControl[] {
    return this.approvalRange.automaticVoltageControl(testPiece, process);
  }

  visualControl(
    testPiece: VisualControl | null,
    process: TestPieceValuesSingleProcess
  ): VisualControl[] {
    return this.approvalRange.visualControl(testPiece, process);
  }

  processMecanization(
    testPiece: processMecanization,
    processValues: TestPieceValuesSingleProcess
  ): processMecanization[] {
    return this.approvalRange.processMecanization(testPiece, processValues);
  }

  get standard() {
    return this.certificate.get('standard');
  }

  electrodeType(electrode: ElectrodeType, processValues: TestPieceValuesSingleProcess) {
    return this.approvalRange.electrodeType(electrode, processValues);
  }

  thickness(testPiece: number) {
    return this.approvalRange.thickness(testPiece);
  }

  weldType(testPiece: WeldType) {
    return this.approvalRange.weldType(testPiece);
  }

  transferMode(testPiece: ArcTransferMode, processValues: TestPieceValuesSingleProcess) {
    return this.approvalRange.transferMode(testPiece, processValues);
  }

  weldingDetails(testPiece: WeldingDetail[]) {
    return this.approvalRange.weldingDetails(testPiece);
  }

  baseMaterial(testPiece: BaseMaterial) {
    return this.approvalRange.baseMaterial(testPiece);
  }

  weldingProcessRoot(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }

    return this.approvalRange.weldingProcessRoot(process);
  }

  weldingProcessFill(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }

    return this.approvalRange.weldingProcessFill(process);
  }

  weldingProcessCap(process: WeldingProcess) {
    if (isEmpty(process)) {
      return [];
    }
    return this.approvalRange.weldingProcessCap(process);
  }

  shielding(process: WeldingProcess) {
    if (isEmpty(process)) {
      return null;
    }

    return this.approvalRange.shielding(process);
  }

  diameter(testPiece: number): NumericalRange {
    return this.approvalRange.diameter(testPiece);
  }

  weldingPosition(testPiece: WeldingPosition, processValues: TestPieceValuesSingleProcess) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return this.approvalRange.weldingPosition(testPiece, processValues);
  }

  fillerMaterial(testPiece: FillerMaterial, processValues: TestPieceValuesSingleProcess) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return this.approvalRange.fillerMaterial(testPiece, processValues);
  }

  internalDeadline(previousDate: Date) {
    return this.approvalRange.internalDeadline(previousDate);
  }

  externalDeadline(previousDate: Date) {
    return this.approvalRange.externalDeadline(previousDate);
  }

  weldingDetailsForOperator(
    testPiece: WeldingOperatorSpecificWeldingDetails,
    process: TestPieceValuesSingleProcess
  ): WeldingOperatorSpecificWeldingDetails[] {
    return this.approvalRange.weldingDetailsForOperator(testPiece, process);
  }
}
