import Model, { belongsTo, attr } from '@ember-data/model';
import WPSModel from './welding-procedure-specification';

export default class WeldingProcedureSpecificationMetadata extends Model {
  @attr('boolean')
  declare printBaseMaterialGroup?: boolean;

  @attr('boolean')
  declare printWeldTypeApprovalRange?: boolean;

  @attr('boolean')
  declare printPqrBaseMaterialApprovalRange?: boolean;

  @belongsTo('welding-procedure-specification')
  declare wps: WPSModel;

  metadata = {
    modelName: 'welding-procedure-specification-metadata',
  };
}
