import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import { service } from '@ember/service';

@classic
export default class NewFromPqrController extends Controller {
  queryParams = ['pqr'];

  pqr = null;

  @service
  router;

  @action
  saveWpsFromPQR(newWps) {
    this.router.transitionTo('welding-procedure-specification', newWps.id);
  }
}
