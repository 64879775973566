import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { getProperties, action, computed, set } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class PipingPiecesController extends ListController {
  @service
  store;

  @service
  userSession;

  @service('weldnote-data')
  data;

  modelName = 'piping-piece';

  sort = null;

  dir = 'asc';

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  isLoading = false;

  @alias('userSession.isMetricSystem')
  isMetric;

  @alias('userSession.isImperialSystem')
  isImperial;

  @computed
  get gridColumns() {
    let diameterLabel = this.getAttributeLabel('diameter');
    let diameterInchesLabel = this.getAttributeLabel('diameterInches');
    let thicknessLabel = this.getAttributeLabel('thickness');
    let thicknessInchesLabel = this.getAttributeLabel('thicknessInches');
    let scheduleLabel = this.getAttributeLabel('schedule');
    let nominalDiameterLabel = this.getAttributeLabel('nominalDiameter');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet', 'laptop'],
      },
      {
        valuePath: 'diameter',
        label: diameterLabel,
        cellComponent: 'weldnote-grid/piping-piece-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'diameterInches',
        label: diameterInchesLabel,
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'schedule',
        label: scheduleLabel,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'thickness',
        label: thicknessLabel,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'thicknessInches',
        label: thicknessInchesLabel,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'nominalDiameter',
        label: nominalDiameterLabel,
        sortable: false,
        breakpoints: ['desktop'],
      },
    ];
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      let { dir = 'asc', sort } = getProperties(this, 'dir', 'sort');
      if (!isEmpty(sort)) {
        options.sort = {};
        options.sort[sort] = dir;
      }

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllPipingPieces(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  convertDecimal(value) {
    let {
      userSession: { decimalSeparator: separator },
    } = this;
    if (separator === ',') {
      value = value.replace(',', '.');
    } else if (value.indexOf(',') > 0) {
      value = value.replace(',', '.');
    }
    return value;
  }

  buildFilters() {
    let { diameter, schedule, thickness, nominalDiameter, diameterInches, thicknessInches } =
      getProperties(
        this,
        'diameter',
        'schedule',
        'thickness',
        'nominalDiameter',
        'diameterInches',
        'thicknessInches'
      );
    let filters = {};
    if (!isEmpty(diameter)) {
      filters.diameter = this.convertDecimal(diameter);
    }
    if (!isEmpty(diameterInches)) {
      filters.diameterInches = this.convertDecimal(diameterInches);
    }
    if (!isEmpty(schedule)) {
      filters.schedule = schedule;
    }
    if (!isEmpty(thickness)) {
      filters.thickness = this.convertDecimal(thickness);
    }
    if (!isEmpty(thicknessInches)) {
      filters.thicknessInches = this.convertDecimal(thicknessInches);
    }
    if (!isEmpty(nominalDiameter)) {
      filters.nominalDiameter = nominalDiameter;
    }
    return filters;
  }

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.loadData.perform();
    this.resetPage();
  }

  @action
  onColumnClick(column) {
    if (column.sorted) {
      this.setProperties({
        dir: column.ascending ? 'asc' : 'desc',
        sort: column.get('valuePath'),
      });
      this.loadData.perform();
    }
  }

  @action
  clearFields() {
    this.setProperties({
      diameter: null,
      thickness: null,
      schedule: null,
      nominalDiameter: null,
      diameterInches: null,
      thicknessInches: null,
    });
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setDiameter(spec) {
    set(this, 'diameter', spec);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setDiameterInches(spec) {
    set(this, 'diameterInches', spec);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setThickness(thickness) {
    set(this, 'thickness', thickness);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setThicknessInches(thickness) {
    set(this, 'thicknessInches', thickness);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setSchedule(schedule) {
    set(this, 'schedule', schedule);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setNominalDiameter(nominalDiameter) {
    set(this, 'nominalDiameter', nominalDiameter);
    this.resetPage();
    this.loadData.perform();
  }
}
