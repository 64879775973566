import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { set, get } from '@ember/object';
import constants from 'weldnote/utils/constants';

const { CERTIFICATE_TYPE } = constants;

export default class NewRoute extends Route {
  @service
  store;

  @service
  userSession;

  @service
  router;

  @service session;

  beforeModel(transition) {
    super.beforeModel(...arguments);
    this.session.requireAuthentication(transition, 'login');
    let { userSession } = this;
    if (!userSession.canCreateCertificate) {
      this.router.transitionTo('welder-certificates');
    }
    if (userSession.isAccountLocked) {
      this.router.transitionTo('index');
    }
  }

  model({ welderType }) {
    let finalWelderType = CERTIFICATE_TYPE.WELDER;
    if (welderType && 'operator' === welderType.toLowerCase()) {
      finalWelderType = CERTIFICATE_TYPE.OPERATOR;
    }
    return this.store.createRecord('welder-certificate', {
      certificateType: finalWelderType,
    });
  }
}
