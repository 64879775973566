import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiPanel @title={{this.title}} @classes=\"mt-8\">\n  <div class=\"text-center\">\n    <div class=\"btn-group\" role=\"group\" style=\"float:initial\">\n      <LinkTo @route={{this.pathToList}}>\n        <button class=\"btn btn-outline btn-primary\" type=\"button\">\n          <FaIcon @icon=\"list\" />\n          {{t \"components.settings-menu.list\"}}\n        </button>\n      </LinkTo>\n    </div>\n    {{#if this.showNewLink}}\n      <div class=\"btn-group\" role=\"group\" style=\"float:initial\">\n        <LinkTo @route={{this.pathToNew}}>\n          <button class=\"btn btn-outline btn-primary\" type=\"button\">\n            <FaIcon @icon=\"plus\" />\n            {{t \"components.settings-menu.new\"}}\n          </button>\n        </LinkTo>\n      </div>\n    {{/if}}\n  </div>\n</UiPanel>", {"contents":"<UiPanel @title={{this.title}} @classes=\"mt-8\">\n  <div class=\"text-center\">\n    <div class=\"btn-group\" role=\"group\" style=\"float:initial\">\n      <LinkTo @route={{this.pathToList}}>\n        <button class=\"btn btn-outline btn-primary\" type=\"button\">\n          <FaIcon @icon=\"list\" />\n          {{t \"components.settings-menu.list\"}}\n        </button>\n      </LinkTo>\n    </div>\n    {{#if this.showNewLink}}\n      <div class=\"btn-group\" role=\"group\" style=\"float:initial\">\n        <LinkTo @route={{this.pathToNew}}>\n          <button class=\"btn btn-outline btn-primary\" type=\"button\">\n            <FaIcon @icon=\"plus\" />\n            {{t \"components.settings-menu.new\"}}\n          </button>\n        </LinkTo>\n      </div>\n    {{/if}}\n  </div>\n</UiPanel>","moduleName":"weldnote/components/settings-menu.hbs","parseOptions":{"srcName":"weldnote/components/settings-menu.hbs"}});
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { pluralize } from 'ember-inflector';

export default class SettingsMenu extends Component {
  @service
  intl;

  get showNewLink() {
    return this.args.showNewLink ?? true;
  }

  get title() {
    if (!isEmpty(this.args.title)) {
      return this.args.title;
    }
    let label = `model.${this.args.modelName}.model-plural`;
    return this.intl.t(label);
  }

  get pathToList() {
    if (isEmpty(this.args.listRoute)) {
      return pluralize(this.args.modelName);
    }
    return this.args.listRoute;
  }

  get pathToNew() {
    if (isEmpty(this.args.newRoute)) {
      return `${this.args.modelName}.new`;
    }
    return this.args.newRoute;
  }
}
