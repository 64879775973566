import Mixin from '@ember/object/mixin';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import { assert } from '@ember/debug';

export default Mixin.create({
  userSession: service(),

  getModelValue(attribute) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    return this.get(`model.${attribute}`);
  },

  setModelValue(attribute, value) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    this.set(`model.${attribute}`, value);
  },

  setModelDecimalValue(attribute, value) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    let separator = this.get('userSession.decimalSeparator');
    if (separator === ',') {
      if (!isEmpty(value)) {
        value = `${value}`.replace(',', '.');
      }
    }
    this.set(`model.${attribute}`, value);
  },

  setModelCollection(attribute, values) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    let collection = this.get(`model.${attribute}`);
    collection.clear();
    if (!isEmpty(values)) {
      values.forEach((value) => {
        collection.pushObject(value);
      });
    }
  },

  setModelCollectionValue(attribute, value) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    this.get(`model.${attribute}`).clear();
    if (!isEmpty(value)) {
      this.get(`model.${attribute}`).pushObject(value);
    }
  },

  clearModelCollection(attribute) {
    assert(`Must pass a valid attribute - ${attribute}`, !isEmpty(attribute));
    let collection = this.get(`model.${attribute}`);
    collection.clear();
  },
});
