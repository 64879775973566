import { helper } from '@ember/component/helper';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

function hasLayer(attribute, model) {
  let layer = get(model, `metadata.${attribute}.layer`);
  return !isEmpty(layer);
}

function hasUnit(attribute, model) {
  let layer = get(model, `metadata.${attribute}.unit`);
  return !isEmpty(layer);
}

function getLayer(attribute, model) {
  return get(model, `metadata.${attribute}.layer`);
}

function getUnit(attribute, model) {
  return get(model, `metadata.${attribute}.unit`);
}

export function wpsInvalidAttributeName(params, hash) {
  let { attribute, intl, wps, userSession } = hash;
  if (isEmpty(attribute)) {
    return '';
  }
  let process = getLayer(attribute, wps);
  let unitName = getUnit(attribute, wps);
  let unit = '';
  if (unitName === 'distance') {
    unit = userSession.distanceUnit;
  } else if (unitName === 'temperature') {
    unit = userSession.temperatureUnit;
  } else if (unitName === 'flow') {
    unit = userSession.flowUnit;
  }
  return intl
    .t(`model.welding-procedure-specification.${attribute}.label`, {
      layer: intl.t(`generic.welding.processes.${process}`),
      unit,
    })
    .toString();
}

export default helper(wpsInvalidAttributeName);
