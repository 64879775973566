export default {
  BASE_MATERIAL_CERTIFICATE: {
    PROJECT: 'project',
    BASE_MATERIAL: 'baseMaterial',
    THICKNESS: 'thickness',
    DIAMETER: 'diameter',
    PRODUCT_TYPE: 'productType',
    HEAT_NUMBER: 'heatNumber',
    CERTIFICATE: 'certificate',
    PRODUCT_FORM: 'productForm',
  },
};
