import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { getProperties, action, computed, set } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class WeldingPositionsController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'welding-position';

  isLoading = false;

  @computed
  get gridColumns() {
    let codeLabel = this.getAttributeLabel('code');
    let industryCodeLabel = this.getAttributeLabel('industryCode');

    return [
      {
        valuePath: 'code',
        label: codeLabel,
        cellComponent: 'weldnote-grid/welding-position-link',
      },
      {
        valuePath: 'industryCode.code',
        label: industryCodeLabel,
        sortable: false,
      },
    ];
  }

  buildFilters() {
    let filters = getProperties(this, 'code');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      set(this, 'isLoading', true);
      let options = {};
      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllWeldingPositions(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setCode(code) {
    set(this, 'code', code);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  clearFields() {
    this.resetPage();
    this.setProperties({
      code: null,
    });
    this.loadData.perform();
  }
}
