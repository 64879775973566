const DEFAULT_PAGE_SIZE = 20;

interface PaginationOptions {
  pageSize: number | null;
  page: number | null;
  sort?: any;
}

/*
 * Convert A set of filters to an object query to be send to the back end
 *
 * @query - The query object to append the values to
 * @filters - The list of filters
 *
 * filters: {
 *   fieldName: {
 *     value: 'value',
 *     type: 'eq'
 *   }
 * }
 *
 */
function convertFilters(query: any, filters: any = {}) {
  let keys = Object.keys(filters);
  let index = 0;
  keys.forEach((key) => {
    let { value, type } = filters[key];
    query[`filter[filters][${index}][field]`] = key;
    query[`filter[filters][${index}][operator]`] = type;
    query[`filter[filters][${index}][value]`] = value;
    index++;
  });
}

function convertPaging(
  query: any,
  options: PaginationOptions = {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
    sort: '',
  }
) {
  let pageSize = DEFAULT_PAGE_SIZE;
  if (options.pageSize) {
    pageSize = parseInt(`${options.pageSize}`, 10);
    if (pageSize < 0 || isNaN(pageSize)) {
      pageSize = DEFAULT_PAGE_SIZE;
    }
    query['paging[limit]'] = pageSize;
  } else {
    query['paging[limit]'] = pageSize;
  }
  if (options.page) {
    let pageValue = parseInt(`${options.page}`, 10);
    if (isNaN(pageValue) || pageValue < 1) {
      pageValue = 1;
    }
    let offset = pageSize * (pageValue - 1);
    query['paging[offset]'] = offset;
  } else {
    query['paging[offset]'] = 0;
  }
}

function convertSorting(query: any, sorting: any) {
  let keys = Object.keys(sorting);
  let index = 0;
  keys.forEach((key) => {
    let value = sorting[key];
    if (value !== 'asc' && value !== 'desc') {
      value = 'asc';
    }
    query[`sort[${index}][field]`] = key;
    query[`sort[${index}][dir]`] = value;
    index++;
  });
}

function convertToUrlParameters(query: any, options: PaginationOptions) {
  convertPaging(query, options);
  convertSorting(query, options.sort);
}

export default { convertFilters, convertPaging, convertSorting, convertToUrlParameters };
