import { hash } from 'rsvp';
import { isEmpty } from '@ember/utils';
import { set, get } from '@ember/object';
import PQRRules from 'weldnote/utils/pqr-rules';
import Rules from 'weldnote/utils/wps-welding-parameter-rules';
import Constants from 'weldnote/utils/constants';
import { calculateShielding } from 'weldnote/utils/certification-rules';
import { isStandardISO, isStandardASME } from 'weldnote/utils/standards';

const { WPS_WELDING_PARAMETER } = Rules;

const { WELD_TYPES, UNIT_SYSTEM, OVERLAY_FILLER_MATERIAL_FORM } = Constants;

const WPS = {
  WPS_NUMBER: 'wpsNumber',
  COMPANY: 'company',
  MAIN_PQR: 'mainPqr',
  STANDARD: 'standard',
  PRODUCT_TYPE: 'productType',
  WELD_TYPE: 'weldType',
  WELDING_PROCESS_ROOT: 'weldingProcessRoot',
  WELDING_PROCESS_FILL: 'weldingProcessFill',
  BASE_MATERIAL1: 'baseMaterial1',
  BASE_MATERIAL2: 'baseMaterial2',
  THICKNESS_MINIMUM: 'thicknessMinimum',
  THICKNESS_MAXIMUM: 'thicknessMaximum',
  DIAMETER_MINIMUM: 'diameterMinimum',
  DIAMETER_MAXIMUM: 'diameterMaximum',
  WELDING_POSITIONS_ROOT: 'weldingPositionsRoot',
  WELDING_POSITIONS_FILL: 'weldingPositionsFill',
  FILLER_MATERIAL_ROOT: 'fillerMaterialRoot',
  FILLER_MATERIAL_ROOT_COMMERCIAL_DESIGNATION: 'fillerMaterialRootCommercialDesignation',
  FILLER_MATERIAL_FILL: 'fillerMaterialFill',
  FILLER_MATERIAL_FILL_COMMERCIAL_DESIGNATION: 'fillerMaterialFillCommercialDesignation',
  SHIELDING_ROOT: 'shieldingRoot',
  SHIELDING_ROOT_GAS: 'shieldingRootGas',
  SHIELDING_ROOT_GAS_COMMERCIAL_DESIGNATION: 'shieldingRootGasCommercialDesignation',
  SHIELDING_ROOT_GAS_MIXTURE: 'shieldingRootGasMixture',
  SHIELDING_ROOT_FLUX: 'shieldingRootFlux',
  SHIELDING_ROOT_FLUX_COMMERCIAL_DESIGNATION: 'shieldingRootFluxCommercialDesignation',
  FLOW_RATE_ROOT: 'flowRateRoot',
  SHIELDING_FILL: 'shieldingFill',
  SHIELDING_FILL_GAS: 'shieldingFillGas',
  SHIELDING_FILL_GAS_COMMERCIAL_DESIGNATION: 'shieldingFillGasCommercialDesignation',
  SHIELDING_FILL_GAS_MIXTURE: 'shieldingFillGasMixture',
  SHIELDING_FILL_FLUX: 'shieldingFillFlux',
  SHIELDING_FILL_FLUX_COMMERCIAL_DESIGNATION: 'shieldingFillFluxCommercialDesignation',
  FLOW_RATE_FILL: 'flowRateFill',
  BACKING: 'backing',
  BACKING_GAS: 'backingGas',
  BACKING_GAS_COMMERCIAL_DESIGNATION: 'backingGasCommercialDesignation',
  BACKING_GAS_MIXTURE: 'backingGasMixture',
  BACKING_FLUX: 'backingFlux',
  BACKING_FLUX_COMMERCIAL_DESIGNATION: 'backingFluxCommercialDesignation',
  BACKING_FLOW_RATE_ROOT: 'backingFlowRateRoot',
  BACKING_FLOW_RATE_FILL: 'backingFlowRateFill',
  ROOT_FACE_MINIMUM: 'rootFaceMinimum',
  ROOT_FACE_MAXIMUM: 'rootFaceMaximum',
  ROOT_GAP_MINIMUM: 'rootGapMinimum',
  ROOT_GAP_MAXIMUM: 'rootGapMaximum',
  ELECTRODE_TYPE: 'electrodeType',
  NUMBER_OF_ELECTRODES: 'numberOfElectrodes',
  PULSED_WELDING_DETAILS: 'pulsedWeldingDetails',
  WEAVING: 'weaving',
  OSCILLATION: 'oscillation',
  CONTACT_TUBE_DISTANCE: 'contactTubeDistance',
  BACK_GAUGING_DETAILS: 'backGaugingDetails',
  HEAT_INPUT_MINIMUM: 'heatInputMinimum',
  HEAT_INPUT_MAXIMUM: 'heatInputMaximum',
  POST_WELD_HEAT_TREATMENT_TIME_MINIMUM: 'postWeldHeatTreatmentTimeMinimum',
  POST_WELD_HEAT_TREATMENT_TIME_MAXIMUM: 'postWeldHeatTreatmentTimeMaximum',
  POST_WELD_HEAT_TREATMENT_METHOD: 'postWeldHeatTreatmentMethod',
  POST_WELD_HEAT_TREATMENT_HEATING_RATE: 'postWeldHeatTreatmentHeatingRate',
  POST_WELD_HEAT_TREATMENT_COOLING_RATE: 'postWeldHeatTreatmentCoolingRate',
  REVISION: 'revision',
  WRITTEN_BY: 'writtenBy',
  WRITTEN_BY_DATE: 'writtenByDate',
  VERIFIED_BY: 'verifiedBy',
  VERIFIED_BY_DATE: 'verifiedByDate',
  APPROVED_BY: 'approvedBy',
  APPROVED_BY_DATE: 'approvedByDate',
  STATUS: 'status',
  WELDING_PARAMETERS: 'weldingParameters',
  WPS_DOCUMENT: 'wpsDocument',
  GROOVE_DESIGNS: 'grooveDesigns',
  WELD_LAYER_CONFIGURATIONS: 'weldLayerConfigurations',
  WELDING_DETAILS: 'weldingDetails',
  BRANCH_ANGLE_MINIMUM: 'branchAngleMinimum',
  BRANCH_ANGLE_MAXIMUM: 'branchAngleMaximum',
  GROOVE_ANGLE_MINIMUM: 'grooveAngleMinimum',
  GROOVE_ANGLE_MAXIMUM: 'grooveAngleMaximum',
  POST_WELD_HEAT_TREATMENT_TEMPERATURE_MINIMUM: 'postWeldHeatTreatmentTemperatureMinimum',
  POST_WELD_HEAT_TREATMENT_TEMPERATURE_MAXIMUM: 'postWeldHeatTreatmentTemperatureMaximum',
  THROAT_THICKNESS_MINIMUM: 'throatThicknessMinimum',
  THROAT_THICKNESS_MAXIMUM: 'throatThicknessMaximum',
  IMPACT_TEST_TEMPERATURE: 'impactTestTemperature',
  HARDNESS_TEST_REQUIRED: 'hardnessTestRequired',
  PREHEAT_TEMPERATURE_MINIMUM: 'preheatTemperatureMinimum',
  PREHEAT_TEMPERATURE_MAXIMUM: 'preheatTemperatureMaximum',
  INTERPASS_TEMPERATURE_MINIMUM: 'interpassTemperatureMinimum',
  INTERPASS_TEMPERATURE_MAXIMUM: 'interpassTemperatureMaximum',
  POST_HEAT_TEMPERATURE_MINIMUM: 'postHeatTemperatureMinimum',
  POST_HEAT_TEMPERATURE_MAXIMUM: 'postHeatTemperatureMaximum',
  SECONDARY_PQRS: 'secondaryPqrs',
  NOTES: 'notes',
  PREPARATION_METHODS: 'preparationMethods',
  TORCH_ANGLE_MINIMUM: 'torchAngleMinimum',
  TORCH_ANGLE_MAXIMUM: 'torchAngleMaximum',
  REVISION_STATUS: 'revisionStatus',
  MASTER_WPS: 'masterWps',
  GAS_NOZZLE_DIAMETER_ROOT: 'gasNozzleDiameterRoot',
  GAS_NOZZLE_DIAMETER_FILL: 'gasNozzleDiameterFill',
  PROCESS_MECANIZATION_ROOT: 'processMecanizationRoot',
  PROCESS_MECANIZATION_FILL: 'processMecanizationFill',
  ARC_TRANSFER_MODE_ROOT: 'arcTransferModeRoot',
  ARC_TRANSFER_MODE_FILL: 'arcTransferModeFill',
  ARC_TRANSFER_MODE_CAP: 'arcTransferModeCap',
  WELDING_PROCESS_CAP: 'weldingProcessCap',
  GAS_NOZZLE_DIAMETER_CAP: 'gasNozzleDiameterCap',
  PROCESS_MECANIZATION_CAP: 'processMecanizationCap',
  TYPE_CURRENT_ROOT: 'typeCurrentRoot',
  TYPE_CURRENT_FILL: 'typeCurrentFill',
  TYPE_CURRENT_CAP: 'typeCurrentCap',
  NUMBER_OF_ELECTRODES_FILL: 'numberOfElectrodesFill',
  NUMBER_OF_ELECTRODES_CAP: 'numberOfElectrodesCap',
  WELDING_POSITIONS_CAP: 'weldingPositionsCap',
  SHIELDING_CAP: 'shieldingCap',
  SHIELDING_CAP_GAS: 'shieldingCapGas',
  SHIELDING_CAP_GAS_COMMERCIAL_DESIGNATION: 'shieldingCapGasCommercialDesignation',
  SHIELDING_CAP_GAS_MIXTURE: 'shieldingCapGasMixture',
  SHIELDING_CAP_FLUX: 'shieldingCapFlux',
  SHIELDING_CAP_FLUX_COMMERCIAL_DESIGNATION: 'shieldingCapFluxCommercialDesignation',
  FLOW_RATE_CAP: 'flowRateCap',
  BACKING_FLOW_RATE_CAP: 'backingFlowRateCap',
  FILLER_MATERIAL_CAP: 'fillerMaterialCap',
  FILLER_MATERIAL_CAP_COMMERCIAL_DESIGNATION: 'fillerMaterialCapCommercialDesignation',
  BASE_MATERIAL1_THICKNESS_MINIMUM: 'baseMaterial1ThicknessMinimum',
  BASE_MATERIAL1_THICKNESS_MAXIMUM: 'baseMaterial1ThicknessMaximum',
  BASE_MATERIAL1_DIAMETER: 'baseMaterial1Diameter',
  BASE_MATERIAL2_THICKNESS_MINIMUM: 'baseMaterial2ThicknessMinimum',
  BASE_MATERIAL2_THICKNESS_MAXIMUM: 'baseMaterial2ThicknessMaximum',
  BASE_MATERIAL2_DIAMETER: 'baseMaterial2Diameter',
  WELDING_POSITION_ROOT_DIRECTION: 'weldingPositionRootDirection',
  WELDING_POSITION_FILL_DIRECTION: 'weldingPositionFillDirection',
  WELDING_POSITION_CAP_DIRECTION: 'weldingPositionCapDirection',
  SPECIAL_REQUIREMENTS: 'specialRequirements',
  OVERLAY_TYPE: 'overlayType',
  POST_HEAT_TIME_MINIMUM: 'postHeatTimeMinimum',
  POST_HEAT_TIME_MAXIMUM: 'postHeatTimeMaximum',
  JOINT_TYPE: 'jointType',
  ELECTRODE_TYPE_FILL: 'electrodeTypeFill',
  ELECTRODE_TYPE_CAP: 'electrodeTypeCap',
  CONTACT_TUBE_DISTANCE_FILL: 'contactTubeDistanceFill',
  CONTACT_TUBE_DISTANCE_CAP: 'contactTubeDistanceCap',
  WEAVING_FILL: 'weavingFill',
  WEAVING_CAP: 'weavingCap',
  TUNGSTEN_DIAMETER_ROOT: 'tungstenDiameterRoot',
  TUNGSTEN_DIAMETER_FILL: 'tungstenDiameterFill',
  TUNGSTEN_DIAMETER_CAP: 'tungstenDiameterCap',
  PQR_FILL: 'pqrFill',
  PQR_CAP: 'pqrCap',
  PQR_ROOT_LAYER: 'pqrRootLayer',
  PQR_FILL_LAYER: 'pqrFillLayer',
  PQR_CAP_LAYER: 'pqrCapLayer',
  THICKNESS_ROOT_MINIMUM: 'thicknessRootMinimum',
  THICKNESS_ROOT_MAXIMUM: 'thicknessRootMaximum',
  THICKNESS_FILL_MINIMUM: 'thicknessFillMinimum',
  THICKNESS_FILL_MAXIMUM: 'thicknessFillMaximum',
  THICKNESS_CAP_MINIMUM: 'thicknessCapMinimum',
  THICKNESS_CAP_MAXIMUM: 'thicknessCapMaximum',
  PROJECT_CLIENT: 'projectClient',
  PROJECT: 'project',
  CONSTRUCTION_STANDARD: 'constructionStandard',
  OVERLAY_NUMBER_OF_LAYERS: 'overlayNumberOfLayers',
  FILLER_MATERIAL_ELECTRODE_FORM_ROOT: 'fillerMaterialElectrodeFormRoot',
  FILLER_MATERIAL_ELECTRODE_FORM_FILL: 'fillerMaterialElectrodeFormFill',
  FILLER_MATERIAL_ELECTRODE_FORM_CAP: 'fillerMaterialElectrodeFormCap',
  FILLER_MATERIAL_GUIDE_ROOT: 'fillerMaterialGuideRoot',
  FILLER_MATERIAL_GUIDE_FILL: 'fillerMaterialGuideFill',
  FILLER_MATERIAL_GUIDE_CAP: 'fillerMaterialGuideCap',
  OSCILLATION_WIDTH_ROOT: 'oscillationWidthRoot',
  OSCILLATION_WIDTH_FILL: 'oscillationWidthFill',
  OSCILLATION_WIDTH_CAP: 'oscillationWidthCap',
  OSCILLATION_FREQUENCY_ROOT: 'oscillationFrequencyRoot',
  OSCILLATION_FREQUENCY_FILL: 'oscillationFrequencyFill',
  OSCILLATION_FREQUENCY_CAP: 'oscillationFrequencyCap',
  OSCILLATION_DWELL_TIME_ROOT: 'oscillationDwellTimeRoot',
  OSCILLATION_DWELL_TIME_FILL: 'oscillationDwellTimeFill',
  OSCILLATION_DWELL_TIME_CAP: 'oscillationDwellTimeCap',
  SUPPLEMENTAL_DEVICE_ROOT: 'supplementalDeviceRoot',
  SUPPLEMENTAL_DEVICE_FILL: 'supplementalDeviceFill',
  SUPPLEMENTAL_DEVICE_CAP: 'supplementalDeviceCap',
  ARCHIVAL_STATE: 'archivalState',
  FILLER_MATERIAL_ROOT_DIAMETERS: 'fillerMaterialRootDiameters',
  FILLER_MATERIAL_FILL_DIAMETERS: 'fillerMaterialFillDiameters',
  FILLER_MATERIAL_CAP_DIAMETERS: 'fillerMaterialCapDiameters',
  FLOW_RATE_PROCESS1_MINIMUM:'flowRateProcess1Minimum',
  FLOW_RATE_PROCESS1_MAXIMUM:'flowRateProcess1Maximum',
  FLOW_RATE_PROCESS2_MINIMUM:'flowRateProcess2Minimum',
  FLOW_RATE_PROCESS2_MAXIMUM:'flowRateProcess2Maximum',
  FLOW_RATE_PROCESS3_MINIMUM:'flowRateProcess3Minimum',
  FLOW_RATE_PROCESS3_MAXIMUM:'flowRateProcess3Maximum'
};

export default {
  WELDING_PROCEDURE_SPECIFICATION: WPS,

  updateParameterHeatInputMinimum(
    wps,
    wpsParameter,
    callback = null,
    unitSystem = UNIT_SYSTEM.METRIC
  ) {
    let standard = wps.get(this.WELDING_PROCEDURE_SPECIFICATION.STANDARD);
    let weldingProcess = wpsParameter.get(WPS_WELDING_PARAMETER.WELDING_PROCESS);

    let voltageMin = wpsParameter.get(WPS_WELDING_PARAMETER.VOLTAGE_MINIMUM);
    let currentMin = wpsParameter.get(WPS_WELDING_PARAMETER.CURRENT_MINIMUM);
    let travelSpeedMax = wpsParameter.get(WPS_WELDING_PARAMETER.TRAVEL_SPEED_MAXIMUM);
    let stripOrWire = wpsParameter.get('additionalData.overlayFillerMaterialForm');
    let stripWidth = null;
    if (stripOrWire === OVERLAY_FILLER_MATERIAL_FORM.STRIP) {
      let materialDiameters = wpsParameter.get(WPS_WELDING_PARAMETER.FILLER_MATERIAL_DIAMETER);
      if (!isEmpty(materialDiameters)) {
        stripWidth = materialDiameters.get(0);
      }
    }

    let result = PQRRules.calculateParameterHeatInput(
      standard,
      voltageMin,
      currentMin,
      travelSpeedMax,
      weldingProcess,
      stripWidth,
      unitSystem
    );

    if (!isEmpty(result)) {
      result = result.toFixed(2);
      wpsParameter.set(WPS_WELDING_PARAMETER.HEAT_INPUT_MINIMUM, result);
    }

    if (callback) {
      callback(wpsParameter);
    }

    this.updateHeatInputApprovalRange(wps);
  },

  updateParameterHeatInputMaximum(
    wps,
    wpsParameter,
    callback = null,
    unitSystem = UNIT_SYSTEM.METRIC
  ) {
    let standard = wps.get(this.WELDING_PROCEDURE_SPECIFICATION.STANDARD);
    let weldingProcess = wpsParameter.get(WPS_WELDING_PARAMETER.WELDING_PROCESS);

    let voltageMax = wpsParameter.get(WPS_WELDING_PARAMETER.VOLTAGE_MAXIMUM);
    let currentMax = wpsParameter.get(WPS_WELDING_PARAMETER.CURRENT_MAXIMUM);
    let travelSpeedMin = wpsParameter.get(WPS_WELDING_PARAMETER.TRAVEL_SPEED_MINIMUM);
    let stripOrWire = wpsParameter.get('additionalData.overlayFillerMaterialForm');
    let stripWidth = null;
    if (stripOrWire === OVERLAY_FILLER_MATERIAL_FORM.STRIP) {
      let materialDiameters = wpsParameter.get(WPS_WELDING_PARAMETER.FILLER_MATERIAL_DIAMETER);
      if (!isEmpty(materialDiameters)) {
        stripWidth = materialDiameters.get(0);
      }
    }

    let result = PQRRules.calculateParameterHeatInput(
      standard,
      voltageMax,
      currentMax,
      travelSpeedMin,
      weldingProcess,
      stripWidth,
      unitSystem
    );

    if (!isEmpty(result)) {
      result = result.toFixed(2);
      wpsParameter.set(WPS_WELDING_PARAMETER.HEAT_INPUT_MAXIMUM, result);
    }

    if (callback) {
      callback(wpsParameter);
    }
  },

  updateHeatInputApprovalRange(wps) {
    let standard = wps.get(this.WELDING_PROCEDURE_SPECIFICATION.STANDARD);
    let parameters = wps.get(this.WELDING_PROCEDURE_SPECIFICATION.WELDING_PARAMETERS);
    let weldType = wps.get(this.WELDING_PROCEDURE_SPECIFICATION.WELD_TYPE);
    let hardnessTestRequired = wps.get(this.WELDING_PROCEDURE_SPECIFICATION.HARDNESS_TEST_REQUIRED);
    let impactTestRequiredRaw = wps.get(
      this.WELDING_PROCEDURE_SPECIFICATION.IMPACT_TEST_TEMPERATURE
    );
    let impactTestRequired = !isEmpty(impactTestRequiredRaw) && !isNaN(impactTestRequiredRaw);

    let heatInputMinimum = this.calculateParametersMinimumHeatInput(parameters);
    let heatInputMaximum = this.calculateParametersMaximumHeatInput(parameters);

    let weldTypeCode = '';
    if (!isEmpty(weldType)) {
      weldTypeCode = get(weldType, 'weldType');
    }

    if (isNaN(heatInputMinimum) && isNaN(heatInputMaximum)) {
      wps.set(this.WELDING_PROCEDURE_SPECIFICATION.HEAT_INPUT_MINIMUM, null);
      wps.set(this.WELDING_PROCEDURE_SPECIFICATION.HEAT_INPUT_MAXIMUM, null);
      return;
    }

    let result = this.calculateHeatInputApprovalRange(
      standard,
      hardnessTestRequired,
      impactTestRequired,
      heatInputMinimum,
      heatInputMaximum,
      weldTypeCode
    );

    if (!isEmpty(result)) {
      if (result.min !== null && !isNaN(parseFloat(result.min))) {
        result.min = parseFloat(result.min).toFixed(2);
      }
      if (result.max !== null && !isNaN(parseFloat(result.max))) {
        result.max = parseFloat(result.max).toFixed(2);
      }
    }

    wps.set(this.WELDING_PROCEDURE_SPECIFICATION.HEAT_INPUT_MINIMUM, result.min);
    wps.set(this.WELDING_PROCEDURE_SPECIFICATION.HEAT_INPUT_MAXIMUM, result.max);
  },

  calculateParametersMinimumHeatInput(parameters) {
    let minimum = 9999999;
    if (isEmpty(parameters)) {
      return NaN;
    }
    parameters.forEach((p) => {
      let heatInputMin = parseFloat(p.get(WPS_WELDING_PARAMETER.HEAT_INPUT_MINIMUM));
      if (!isNaN(heatInputMin)) {
        if (minimum > heatInputMin) {
          minimum = heatInputMin;
        }
      }
    });

    if (minimum === 9999999) {
      return null;
    }

    return minimum;
  },

  calculateParametersMaximumHeatInput(parameters) {
    let maximum = -1;
    if (isEmpty(parameters)) {
      return NaN;
    }
    parameters.forEach((p) => {
      let heatInputMax = parseFloat(p.get(WPS_WELDING_PARAMETER.HEAT_INPUT_MAXIMUM));
      if (!isNaN(heatInputMax)) {
        if (maximum < heatInputMax) {
          maximum = heatInputMax;
        }
      }
    });

    if (maximum === -1) {
      return null;
    }

    return maximum;
  },

  calculateHeatInputApprovalRange(
    standard,
    hardnessTestRequired,
    impactTestRequired,
    heatInputMin,
    heatInputMax,
    weldType
  ) {
    let result = {
      min: null,
      max: null,
    };

    let heatInputMinParsed = parseFloat(heatInputMin, 10);
    let heatInputMaxParsed = parseFloat(heatInputMax, 10);

    if (!isEmpty(heatInputMinParsed) && !isNaN(heatInputMinParsed)) {
      result.min = heatInputMinParsed.toFixed(2);
    }
    if (!isEmpty(heatInputMaxParsed) && !isNaN(heatInputMaxParsed)) {
      result.max = heatInputMaxParsed.toFixed(2);
    }

    if (isStandardISO(standard)) {
      if (weldType === WELD_TYPES.OVERLAY_WELD) {
        if (!isNaN(heatInputMinParsed)) {
          result.min = heatInputMinParsed * 0.9;
        }
        if (!isNaN(heatInputMaxParsed)) {
          result.max = heatInputMaxParsed * 1.25;
        }
      } else {
        if (!isNaN(heatInputMinParsed) && heatInputMinParsed >= 0 && hardnessTestRequired) {
          result.min = parseFloat((heatInputMinParsed * 0.75).toFixed(2));
        }
        if (!isNaN(heatInputMaxParsed) && impactTestRequired) {
          result.max = parseFloat((heatInputMaxParsed * 1.25).toFixed(2));
        }
      }
    } else if (isStandardASME(standard)) {
      if (!isNaN(heatInputMaxParsed) && impactTestRequired) {
        result.max = heatInputMaxParsed;
      }
    }

    return result;
  },

  updateShieldingRoot(wps) {
    let weldingProcessRoot = get(wps, this.WELDING_PROCEDURE_SPECIFICATION.WELDING_PROCESS_ROOT);
    if (!isEmpty(weldingProcessRoot)) {
      let weldingProcessCode = get(weldingProcessRoot, 'shortDesignation');
      if (!isEmpty(weldingProcessCode)) {
        let shielding = calculateShielding(weldingProcessCode);
        let currentShielding = get(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_ROOT);
        if (!isEmpty(shielding) && shielding !== currentShielding) {
          set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_ROOT, shielding);
          this.clearShieldingRoot(wps);
        }
      }
    }
  },

  updateShieldingFill(wps) {
    let weldingProcessFill = get(wps, this.WELDING_PROCEDURE_SPECIFICATION.WELDING_PROCESS_FILL);
    if (!isEmpty(weldingProcessFill)) {
      let weldingProcessCode = get(weldingProcessFill, 'shortDesignation');
      if (!isEmpty(weldingProcessCode)) {
        let shielding = calculateShielding(weldingProcessCode);
        let currentShielding = get(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_FILL);
        if (!isEmpty(shielding) && shielding !== currentShielding) {
          set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_FILL, shielding);
          this.clearShieldingFill(wps);
        }
      }
    }
  },

  updateShieldingCap(wps) {
    let weldingProcessCap = get(wps, this.WELDING_PROCEDURE_SPECIFICATION.WELDING_PROCESS_CAP);
    if (!isEmpty(weldingProcessCap)) {
      let weldingProcessCode = get(weldingProcessCap, 'shortDesignation');
      if (!isEmpty(weldingProcessCode)) {
        let shielding = calculateShielding(weldingProcessCode);
        let currentShielding = get(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_CAP);
        if (!isEmpty(shielding) && shielding !== currentShielding) {
          set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_CAP, shielding);
          this.clearShieldingCap(wps);
        }
      }
    }
  },

  clearShieldingRoot(wps) {
    // Clear Gas
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_ROOT_GAS, null);
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_ROOT_GAS_COMMERCIAL_DESIGNATION, null);
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_ROOT_GAS_MIXTURE, null);
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.FLOW_RATE_ROOT, null);
    // Clear Flux
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_ROOT_FLUX, null);
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_ROOT_FLUX_COMMERCIAL_DESIGNATION, null);
  },

  clearShieldingFill(wps) {
    // Clear Gas
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_FILL_GAS, null);
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_FILL_GAS_COMMERCIAL_DESIGNATION, null);
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_FILL_GAS_MIXTURE, null);
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.FLOW_RATE_FILL, null);
    // Clear Flux
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_FILL_FLUX, null);
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_FILL_FLUX_COMMERCIAL_DESIGNATION, null);
  },

  clearShieldingCap(wps) {
    // Clear Gas
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_CAP_GAS, null);
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_CAP_GAS_COMMERCIAL_DESIGNATION, null);
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_CAP_GAS_MIXTURE, null);
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.FLOW_RATE_CAP, null);
    // Clear Flux
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_CAP_FLUX, null);
    set(wps, this.WELDING_PROCEDURE_SPECIFICATION.SHIELDING_CAP_FLUX_COMMERCIAL_DESIGNATION, null);
  },
};
