import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @searchEnabled}}\n  <div class=\"ember-power-select-search\">\n    <input\n      type=\"search\"\n      autocomplete=\"off\"\n      autocorrect=\"off\"\n      autocapitalize=\"off\"\n      spellcheck={{false}}\n      role=\"combobox\"\n      class=\"ember-power-select-search-input\"\n      value={{this.searchText}}\n      aria-controls={{@listboxId}}\n      placeholder={{@searchPlaceholder}}\n      {{on \"input\" @onInput}}\n      {{on \"focus\" @onFocus}}\n      {{on \"blur\" @onBlur}}\n      {{on \"keydown\" this.handleKeydown}}\n      {{did-insert this.focusInput}}\n      {{will-destroy this.clearSearch}}\n    />\n  </div>\n{{/if}}", {"contents":"{{#if @searchEnabled}}\n  <div class=\"ember-power-select-search\">\n    <input\n      type=\"search\"\n      autocomplete=\"off\"\n      autocorrect=\"off\"\n      autocapitalize=\"off\"\n      spellcheck={{false}}\n      role=\"combobox\"\n      class=\"ember-power-select-search-input\"\n      value={{this.searchText}}\n      aria-controls={{@listboxId}}\n      placeholder={{@searchPlaceholder}}\n      {{on \"input\" @onInput}}\n      {{on \"focus\" @onFocus}}\n      {{on \"blur\" @onBlur}}\n      {{on \"keydown\" this.handleKeydown}}\n      {{did-insert this.focusInput}}\n      {{will-destroy this.clearSearch}}\n    />\n  </div>\n{{/if}}","moduleName":"weldnote/components/power-select-pre-searched-before-options.hbs","parseOptions":{"srcName":"weldnote/components/power-select-pre-searched-before-options.hbs"}});
import { isEmpty } from '@ember/utils';
import BeforeOptionsComponent from 'ember-power-select/components/power-select/before-options';

/* The purpose of this component is
 * When the user uses the filler-material-chooser component and wants to create a new filler
 * material, a modal opens with the parts of the filler material to create, the problem
 * is that it looses what the user searched, because it's not an option in the list of options
 * This component allows to set the value of the search text
 */
export default class PowerSelectPreSearchedBeforeOptionsComponent extends BeforeOptionsComponent {
  hasUserEditedSearch = false;

  get searchText() {
    let {
      extra,
      select: { searchText },
    } = this.args;

    if (!this.hasUserEditedSearch) {
      this.hasUserEditedSearch = searchText !== extra;
    }

    if (!isEmpty(extra) && isEmpty(searchText) && !this.hasUserEditedSearch) {
      return extra;
    }
    if (isEmpty(searchText)) {
      return '';
    }
    return searchText;
  }
}
