import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';
import { isStandardAWS } from 'weldnote/utils/standards';
import PQRRules from 'weldnote/utils/pqr-rules';
import { isFCAW } from 'weldnote/utils/rules/welding-process';

const { PQR } = PQRRules;

export function validateFillerMaterialAWS(weldingProcessProperty) {
  return {
    if(key, value, model) {
      return (
        isFCAW(get(model, weldingProcessProperty)) &&
        isStandardAWS(get(model, PQR.STANDARD)) &&
        model.hasImpactRequirements()
      );
    },

    validation(key, value) {
      return !isEmpty(value) && !isEmpty(get(value, 'id'));
    },

    message(key, value, model) {
      return model.translate('model-validations.pqr.filler-material-aws');
    },
  };
}

export function isSpecification51or55(pqrValidationRange) {
  let spec = get(pqrValidationRange, 'specificationLabel');
  return spec === 'A5.1' || spec === 'A5.5';
}

export function isSpecification51or55withFillerMaterial(material) {
  let spec = get(material, 'specification.specification');
  return spec === 'A5.1' || spec === 'A5.5';
}

export function isSpecification517or523(pqrValidationRange) {
  let spec = get(pqrValidationRange, 'specificationLabel');
  return spec === 'A5.17' || spec === 'A5.23';
}

export function isSpecification517or523withFiller(material) {
  let spec = get(material, 'specification.specification');
  return spec === 'A5.17' || spec === 'A5.23';
}

export function isSpecification518or528or536(pqrValidationRange) {
  let spec = get(pqrValidationRange, 'specificationLabel');
  return spec === 'A5.18' || spec === 'A5.28' || spec === 'A5.36';
}

export function isSpecification518or528or536withFiller(material) {
  let spec = get(material, 'specification.specification');
  return spec === 'A5.18' || spec === 'A5.28' || spec === 'A5.36';
}

export function isSpecification520or529or536(pqrValidationRange) {
  let spec = get(pqrValidationRange, 'specificationLabel');
  return spec === 'A5.20' || spec === 'A5.29' || spec === 'A5.36';
}

export function isSpecification520or529or536withFiller(material) {
  let spec = get(material, 'specification.specification');
  return spec === 'A5.20' || spec === 'A5.29' || spec === 'A5.36';
}

export function isSameSpecification(pqrValidationRange, wpsMaterial) {
  let spec = get(pqrValidationRange, 'specificationLabel');
  let spec2 = get(wpsMaterial, 'specification.specification');
  return spec === spec2;
}
