import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield}}", {"contents":"{{yield}}","moduleName":"weldnote/components/sidebar-activator.hbs","parseOptions":{"srcName":"weldnote/components/sidebar-activator.hbs"}});
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import $ from 'jquery';

@classic
export default class SidebarActivator extends Component {
  domElement = null;

  didInsertElement() {
    super.didInsertElement(...arguments);
    $(`#${this.domElement}`).metisMenu();
  }
}
