import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';
import { isEmpty } from '@ember/utils';

@classic
export default class ThirtyDaysRoute extends ListRoute {
  @service
  session;

  @service
  userSession;

  @service
  welderCertificateData;

  model({ page }) {
    let options = { page };
    let { userSession } = this;
    let { subscribedCompanyIds } = userSession;
    if (!isEmpty(subscribedCompanyIds)) {
      options.company = subscribedCompanyIds;
    }
    return this.welderCertificateData.getWelderCertificatesToExternallyValidate30Days(options);
  }
}
