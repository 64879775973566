import { service } from '@ember/service';
import { attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import Constants from 'weldnote/utils/constants';
import {
  fromMilimeterSecondToMeterMinute,
  fromMilimeterSecondToMilimeterMinute,
} from 'weldnote/utils/unit-system/speed';
import TypeCurrentPolarity from './type-current-polarity';
import ArcTransferMode from './arc-transfer-mode';
import WPSModel from './welding-procedure-specification';
import WeldingPosition from './welding-position';

const { WELDING_LAYER_OPTIONS } = Constants;

export default class WpsWeldingParameter extends BaseModel {
  @service()
  declare userSession: any;

  @attr('string')
  declare guid?: string;

  @attr('number')
  declare orderNum?: number;

  @attr('string')
  declare passNumber?: string;

  @attr()
  declare fillerMaterialDiameters?: string;

  @attr('number')
  declare currentMinimum?: number;

  @attr('number')
  declare currentMaximum?: number;

  @attr('number')
  declare voltageMinimum?: number;

  @attr('number')
  declare voltageMaximum?: number;

  @belongsTo('type-current-polarity', { async: false })
  declare typeCurrent: TypeCurrentPolarity;

  @attr('decimal-value')
  declare wireFeedSpeedMinimum: number;

  @attr('decimal-value')
  declare wireFeedSpeedMaximum: number;

  @attr('decimal-value')
  declare travelSpeedMinimum: number;

  @attr('decimal-value')
  declare travelSpeedMaximum: number;

  @attr('decimal-value')
  declare heatInputMinimum: number;

  @attr('decimal-value')
  declare heatInputMaximum: number;

  @belongsTo('arc-transfer-mode', { async: false })
  declare arcTransferMode: ArcTransferMode;

  @belongsTo('welding-procedure-specification', { async: false })
  declare wps: WPSModel;

  @attr('string')
  declare layer?: string;

  @belongsTo('pqr-welding-parameter', { async: false })
  declare sourceParameter: any;

  @hasMany('welding-position', { async: false })
  declare weldingPositions: SyncHasMany<WeldingPosition>;

  @attr('string')
  declare program?: string;

  @attr({
    defaultValue() {
      return {};
    },
  })
  declare additionalData?: string;

  get travelSpeedUnit(): boolean {
    return this.userSession.travelSpeedUnit;
  }

  get wireSpeedUnit(): boolean {
    return this.userSession.wireSpeedUnit;
  }

  get isMetricSystem(): boolean {
    return this.userSession.isMetricSystem;
  }

  get isImperialSystem(): boolean {
    return this.userSession.isImperialSystem;
  }

  metadata = {
    modelName: 'wps-welding-parameter',
    passNumber: { required: true },
    currentMinimum: { required: true },
    currentMaximum: { required: true },
    voltageMinimum: { required: true },
    voltageMaximum: { required: true },
    typeCurrent: { required: true },
    layer: { required: true },
  };

  isEmptyParameter() {
    let allEmpty =
      isEmpty(this.passNumber) &&
      isEmpty(this.currentMinimum) &&
      isEmpty(this.currentMaximum) &&
      isEmpty(this.voltageMinimum) &&
      isEmpty(this.voltageMaximum) &&
      isEmpty(this.typeCurrent) &&
      isEmpty(this.layer);
    return allEmpty;
  }

  validations = {
    passNumber: {
      presence: {
        message(_key: string, _value: string, model: WpsWeldingParameter): string {
          return model.translate('generic.error.input-value');
        },
      },

      length: [0, 10],
    },

    currentMinimum: {
      numericality: {
        allowBlank: false,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WpsWeldingParameter) {
            if (!isEmpty(value)) {
              return model.validateMinimum('currentMaximum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: WpsWeldingParameter) {
            return model.getBelowMaximumMessage(get(model, 'currentMaximum'), '(A)');
          },
        },
        {
          validation(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            if (!model.isPreliminaryWps() && !isEmpty(valueRaw)) {
              return model.getWps().validator.isParameterCurrentMinimumValid(valueRaw, model);
            }
            return true;
          },

          message(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            return model.getWps().validator.parameterCurrentMinimumInvalidMessage(valueRaw, model);
          },
        },
      ],
    },

    currentMaximum: {
      numericality: {
        allowBlank: false,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WpsWeldingParameter) {
            if (!isEmpty(value)) {
              return model.validateMaximum('currentMinimum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: WpsWeldingParameter) {
            return model.getAboveMinimumMessage(get(model, 'currentMinimum'), '(A)');
          },
        },
        {
          validation(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            if (!model.isPreliminaryWps() && !isEmpty(valueRaw)) {
              return model.getWps().validator.isParameterCurrentMaximumValid(valueRaw, model);
            }
            return true;
          },

          message(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            return model.getWps().validator.parameterCurrentMaximumInvalidMessage(valueRaw, model);
          },
        },
      ],
    },

    voltageMinimum: {
      numericality: {
        allowBlank: false,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WpsWeldingParameter) {
            if (!isEmpty(value)) {
              return model.validateMinimum('voltageMaximum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: WpsWeldingParameter) {
            return model.getBelowMaximumMessage(get(model, 'voltageMaximum'), '(V)');
          },
        },
        {
          validation(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            if (!model.isPreliminaryWps() && !isEmpty(valueRaw)) {
              return model.getWps().validator.isParameterVoltageMinimumValid(valueRaw, model);
            }
            return true;
          },

          message(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            return model.getWps().validator.parameterVoltageMinimumInvalidMessage(valueRaw, model);
          },
        },
      ],
    },

    voltageMaximum: {
      numericality: {
        allowBlank: false,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WpsWeldingParameter) {
            if (!isEmpty(value)) {
              return model.validateMaximum('voltageMinimum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: WpsWeldingParameter) {
            return model.getAboveMinimumMessage(get(model, 'voltageMinimum'), '(V)');
          },
        },
        {
          validation(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            if (!model.isPreliminaryWps() && !isEmpty(valueRaw)) {
              return model.getWps().validator.isParameterVoltageMaximumValid(valueRaw, model);
            }
            return true;
          },

          message(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            return model.getWps().validator.parameterVoltageMaximumInvalidMessage(valueRaw, model);
          },
        },
      ],
    },

    typeCurrent: {
      custom: {
        if(_key: string, _value: string, model: WpsWeldingParameter) {
          let userSession = get(model, 'userSession');
          if (userSession.hasFeature('ge-requirements')) {
            return false;
          }
          return true;
        },

        validation(_key: string, value: any, model: WpsWeldingParameter) {
          if (!model.isPreliminaryWps()) {
            if (!isEmpty(value)) {
              return model.getWps().validator.isParameterTypeCurrentValid(value, model);
            } else {
              return false;
            }
          }
          return !isEmpty(value);
        },

        message(_key: string, value: any, model: WpsWeldingParameter) {
          if (!model.isPreliminaryWps()) {
            if (!isEmpty(value)) {
              return model.getWps().validator.parameterTypeCurrentInvalidMessage(value, model);
            } else {
              return model.intl
                .t('model-validations.wps-parameter.type-current-required')
                .toString();
            }
          }
          return '';
        },
      },
    },

    wireFeedSpeedMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },

      custom: [
        {
          if(_key: string, value: any) {
            return !isEmpty(value);
          },

          validation(_key: string, value: any, model: WpsWeldingParameter) {
            return model.validateMinimum('wireFeedSpeedMaximum', value);
          },

          message(_key: string, _value: any, model: WpsWeldingParameter): string {
            if (get(model, 'isMetricSystem')) {
              return model.getBelowMaximumMessage(
                fromMilimeterSecondToMeterMinute(get(model, 'wireFeedSpeedMaximum')),
                `(${get(model, 'wireSpeedUnit')})`
              );
            } else if (get(model, 'isImperialSystem')) {
              return model.getBelowMaximumMessage(
                get(model, 'wireFeedSpeedMaximum'),
                `(${get(model, 'wireSpeedUnit')})`
              );
            }
            return '';
          },
        },
        {
          validation(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            if (!model.isPreliminaryWps() && !isEmpty(valueRaw)) {
              return model.getWps().validator.isParameterWireFeedSpeedMinimumValid(valueRaw, model);
            }
            return true;
          },

          message(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            return model
              .getWps()
              .validator.parameterWireFeedSpeedMinimumInvalidMessage(valueRaw, model);
          },
        },
      ],
    },

    wireFeedSpeedMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },

      custom: [
        {
          if(_key: string, value: any) {
            return !isEmpty(value);
          },

          validation(_key: string, value: any, model: WpsWeldingParameter) {
            return model.validateMaximum('wireFeedSpeedMinimum', value);
          },

          message(_key: string, _value: any, model: WpsWeldingParameter) {
            if (get(model, 'isMetricSystem')) {
              return model.getAboveMinimumMessage(
                fromMilimeterSecondToMeterMinute(get(model, 'wireFeedSpeedMinimum')),
                `(${get(model, 'wireSpeedUnit')})`
              );
            } else if (get(model, 'isImperialSystem')) {
              return model.getAboveMinimumMessage(
                get(model, 'wireFeedSpeedMinimum'),
                `(${get(model, 'wireSpeedUnit')})`
              );
            }
            return '';
          },
        },
        {
          validation(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            if (!model.isPreliminaryWps() && !isEmpty(valueRaw)) {
              return model.getWps().validator.isParameterWireFeedSpeedMaximumValid(valueRaw, model);
            }
            return true;
          },

          message(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            return model
              .getWps()
              .validator.parameterWireFeedSpeedMaximumInvalidMessage(valueRaw, model);
          },
        },
      ],
    },

    heatInputMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WpsWeldingParameter): boolean {
            if (!model.isPreliminaryWps() && !isEmpty(value)) {
              return model.getWps().validator.isHeatInputValid(value, get(model, 'layer'));
            }
            return true;
          },

          message(_key: string, value: any, model: WpsWeldingParameter): string {
            return model.getWps().validator.heatInputInvalidMessage(value, get(model, 'layer'));
          },
        },
        {
          validation(_key: string, value: any, model: WpsWeldingParameter) {
            if (!isEmpty(value)) {
              return model.validateMinimum('heatInputMaximum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: WpsWeldingParameter) {
            return model.getBelowMaximumMessage(get(model, 'heatInputMaximum'), '(kJ/mm)');
          },
        },
      ],
    },

    heatInputMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },

      custom: [
        {
          validation(_key: string, value: any, model: WpsWeldingParameter): boolean {
            if (!model.isPreliminaryWps() && !isEmpty(value)) {
              return model.getWps().validator.isHeatInputValid(value, get(model, 'layer'));
            }
            return true;
          },

          message(_key: string, value: any, model: WpsWeldingParameter): string {
            return model.getWps().validator.heatInputInvalidMessage(value, get(model, 'layer'));
          },
        },
        {
          validation(_key: string, value: any, model: WpsWeldingParameter) {
            if (!isEmpty(value)) {
              return model.validateMaximum('heatInputMinimum', value);
            }
            return true;
          },

          message(_key: string, _value: any, model: WpsWeldingParameter) {
            return model.getAboveMinimumMessage(get(model, 'heatInputMinimum'), '(kJ/mm)');
          },
        },
      ],
    },

    travelSpeedMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },

      custom: [
        {
          if(_key: string, value: any) {
            return !isEmpty(value);
          },

          validation(_key: string, value: any, model: WpsWeldingParameter) {
            return model.validateMinimum('travelSpeedMaximum', value);
          },

          message(_key: string, _value: any, model: WpsWeldingParameter): string {
            if (get(model, 'isMetricSystem')) {
              return model.getBelowMaximumMessage(
                fromMilimeterSecondToMilimeterMinute(get(model, 'travelSpeedMaximum')),
                `(${get(model, 'travelSpeedUnit')})`
              );
            } else if (get(model, 'isImperialSystem')) {
              return model.getBelowMaximumMessage(
                get(model, 'travelSpeedMaximum'),
                `(${get(model, 'travelSpeedUnit')})`
              );
            }
            return '';
          },
        },
        {
          validation(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            if (!model.isPreliminaryWps() && !isEmpty(valueRaw)) {
              return model.getWps().validator.isParameterTravelSpeedMinimumValid(valueRaw, model);
            }
            return true;
          },

          message(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            return model
              .getWps()
              .validator.parameterTravelSpeedMinimumInvalidMessage(valueRaw, model);
          },
        },
      ],
    },

    travelSpeedMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },

      custom: [
        {
          if(_key: string, value: any) {
            return !isEmpty(value);
          },

          validation(_key: string, value: any, model: WpsWeldingParameter) {
            return model.validateMaximum('travelSpeedMinimum', value);
          },

          message(_key: string, _value: any, model: WpsWeldingParameter): string {
            if (get(model, 'isMetricSystem')) {
              return model.getAboveMinimumMessage(
                fromMilimeterSecondToMilimeterMinute(get(model, 'travelSpeedMinimum')),
                `(${get(model, 'travelSpeedUnit')})`
              );
            } else if (get(model, 'isImperialSystem')) {
              return model.getAboveMinimumMessage(
                get(model, 'travelSpeedMinimum'),
                `(${get(model, 'travelSpeedUnit')})`
              );
            }
            return '';
          },
        },
        {
          validation(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            if (!model.isPreliminaryWps() && !isEmpty(valueRaw)) {
              return model.getWps().validator.isParameterTravelSpeedMaximumValid(valueRaw, model);
            }
            return true;
          },

          message(_key: string, valueRaw: any, model: WpsWeldingParameter) {
            return model
              .getWps()
              .validator.parameterTravelSpeedMaximumInvalidMessage(valueRaw, model);
          },
        },
      ],
    },

    layer: {
      presence: {
        message(_key: string, _value: any, model: WpsWeldingParameter): string {
          return model.translate('generic.error.input-value');
        },
      },

      custom: {
        if(_key: string, value: any, model: WpsWeldingParameter) {
          return model.shouldValidateSingleValue(value);
        },

        validation(_key: string, value: any, model: WpsWeldingParameter) {
          if (!model.isPreliminaryWps()) {
            return model.getWps().validator.isParameterLayerValid(value, model);
          }
          return true;
        },

        message(_key: string, value: any, model: WpsWeldingParameter) {
          return model.getWps().validator.parameterLayerInvalidMessage(value, model);
        },
      },
    },

    arcTransferMode: {
      custom: {
        if(/* key, value, model*/) {
          // False purpose, since we have flag to display (or not) the
          // arc transfer mode, we don't want users that cannot see the transfer
          // mode to have an error they can't fix
          return false;
        },

        validation(_key: string, value: any, model: WpsWeldingParameter) {
          // This is not used because of the return false previously
          if (!model.isPreliminaryWps()) {
            return model.getWps().validator.isParameterArcTransferModeValid(value, model);
          }
          return true;
        },

        message(_key: string, value: any, model: WpsWeldingParameter) {
          return model.getWps().validator.parameterArcTransferModeInvalidMessage(value, model);
        },
      },
    },
  };

  getPqrPass() {
    return get(this, 'sourceParameter');
  }

  getPqr() {
    return get(get(this, 'wps'), 'mainPqr');
  }

  getWpsStandard() {
    return get(get(this, 'wps'), 'standard');
  }

  shouldValidateRelationValue(value: BaseModel) {
    return !isEmpty(value) && !isEmpty(get(value, 'id')) && this.isLinkedToPass;
  }

  getWps() {
    return this.store.peekRecord('welding-procedure-specification', this.wpsId);
  }

  shouldValidateSingleValue(value: any) {
    return !isEmpty(value) && this.isLinkedToPass;
  }

  getSourceInputMinimum() {
    return this.getWps().validator.heatInputMinimumSource(this);
  }

  getSourceInputMaximum() {
    return this.getWps().validator.heatInputMaximumSource(this);
  }

  getSourcePQRInputMinimum() {
    return this.getWps().validator.heatInputMinimumSourcePQR(this);
  }

  getSourcePQRInputMaximum() {
    return this.getWps().validator.heatInputMaximumSourcePQR(this);
  }

  isPreliminaryWps() {
    return isEmpty(this.wpsId) || isEmpty(this.getWps().validator);
  }

  get wpsId(): string {
    let wps = get(this, 'wps');
    if (wps && !isEmpty(wps)) {
      return get(wps, 'id');
    }
    return '';
  }

  @computed('sourceParameter')
  get isLinkedToPass() {
    let source = this.sourceParameter;
    return !isEmpty(source);
  }

  validateMinimum(propertyMaximumValue: any, value: string) {
    let maximum = parseFloat(get(this, propertyMaximumValue));
    if (!isEmpty(maximum) && !isNaN(maximum)) {
      let minimum = parseFloat(value);
      return minimum <= maximum;
    }
    return true;
  }

  validateMaximum(propertyMinimumValue: any, value: string) {
    let minimum = parseFloat(get(this, propertyMinimumValue));
    if (!isEmpty(minimum) && !isNaN(minimum)) {
      let maximum = parseFloat(value);
      return maximum >= minimum;
    }
    return true;
  }

  getBelowMaximumMessage(approvalRange: string | number | undefined, unit = '') {
    return this.translate('model-validations.wps-parameter.must-be-below-maximum', {
      approvalRange,
      unit,
    });
  }

  getAboveMinimumMessage(approvalRange: string | number | undefined, unit: string) {
    return this.translate('model-validations.wps-parameter.must-be-above-minimum', {
      approvalRange,
      unit,
    });
  }

  @computed('layer', 'wps.{fillerMaterialRoot,fillerMaterialFill,fillerMaterialCap}')
  get fillerMaterialType() {
    if (this.layer === WELDING_LAYER_OPTIONS.ROOT) {
      return this.wps.fillerMaterialRoot;
    } else if (this.layer === WELDING_LAYER_OPTIONS.FILL) {
      return this.wps.fillerMaterialFill;
    } else if (this.layer === WELDING_LAYER_OPTIONS.CAP) {
      return this.wps.fillerMaterialCap;
    }
    return null;
  }

  @computed('layer', 'wps.{weldingProcessRoot,weldingProcessFill,weldingProcessCap}')
  get weldingProcess() {
    if (this.layer === WELDING_LAYER_OPTIONS.ROOT) {
      return get(this.wps, 'weldingProcessRoot');
    } else if (this.layer === WELDING_LAYER_OPTIONS.FILL) {
      return get(this.wps, 'weldingProcessFill');
    } else if (this.layer === WELDING_LAYER_OPTIONS.CAP) {
      return get(this.wps, 'weldingProcessCap');
    }
    return null;
  }
}
