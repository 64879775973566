import { service } from '@ember/service';
import EditRoute from 'weldnote/routes/_base/edit';

export default class GrooveDesignRoute extends EditRoute {
  @service
  store;

  modelName = 'groove-design';

  listRoute = 'groove-designs';

  model({ id }) {
    return this.store.findRecord('groove-design', id, {
      include: 'welding-details,weld-types,rendered-image,image',
    });
  }
}
