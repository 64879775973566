import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Const from 'weldnote/utils/constants';
import {
  isSMAW,
  isGMAW,
  isFCAW,
  isFCAWS,
  isSAW,
  isMCAW,
  is111,
  is131,
  is135,
  is136,
  is137,
  is138,
  is121,
  is122,
  is123,
  is124,
  is125,
} from 'weldnote/utils/rules/welding-process';

const { PROCESS_MECANIZATION } = Const;

function filterProcesses(allItems, toReject) {
  return allItems.reject((item) => {
    let name = get(item, 'name');
    return toReject.includes(name);
  });
}

export default function processMecanizationOptions(allOptions, weldingProcess) {
  return computed(allOptions, weldingProcess, {
    get() {
      let process = get(this, weldingProcess);
      if (isEmpty(process)) {
        return get(this, allOptions);
      } else {
        let allItems = get(this, allOptions);
        if (isSMAW(process) || is111(process)) {
          return filterProcesses(allItems, [
            PROCESS_MECANIZATION.SEMI_AUTOMATIC,
            PROCESS_MECANIZATION.AUTOMATIC,
            PROCESS_MECANIZATION.MECHANIZED,
          ]);
        } else if (
          isGMAW(process) ||
          isMCAW(process) ||
          isFCAW(process) ||
          isFCAWS(process) ||
          is131(process) ||
          is135(process) ||
          is136(process) ||
          is137(process) ||
          is138(process)
        ) {
          return filterProcesses(allItems, [PROCESS_MECANIZATION.MANUAL]);
        } else if (
          is121(process) ||
          is122(process) ||
          is123(process) ||
          is124(process) ||
          is125(process) ||
          isSAW(process)
        ) {
          return filterProcesses(allItems, [PROCESS_MECANIZATION.MANUAL]);
        }
        return allItems;
      }
    },
  });
}
