import { action, computed, get, set } from '@ember/object';
import { alias, empty, equal, not } from '@ember/object/computed';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import classic from 'ember-classic-decorator';
import { task, timeout } from 'ember-concurrency';
import Env from 'weldnote/config/environment';
import ListController from 'weldnote/controllers/_base/list-js';
import filterFromCollectionByKey from 'weldnote/macros/find-from-collection-by-key';
import { downloadExcel, downloadPdf } from 'weldnote/utils/ajax/file-download';
import Constants from 'weldnote/utils/constants';
import { convertDecimals } from 'weldnote/utils/decimal-utils';
import { isFraction, toNumber } from 'weldnote/utils/unit-system/units';
import WPSRules from 'weldnote/utils/welding-procedure-specification-rules';

const { WELDING_PROCEDURE_SPECIFICATION } = WPSRules;

const WPS = WELDING_PROCEDURE_SPECIFICATION;

const {
  WELD_TYPES,
  HTTP: { DEBOUNCE },
  UNIT_SYSTEM,
} = Constants;

@classic
export default class WeldingProcedureSpecificationsController extends ListController {
  modelName = 'welding-procedure-specification';

  @service
  weldnoteData;

  @alias('weldnoteData')
  data;

  @service
  scroller;

  @service
  store;

  @service
  intl;

  @service('instanceTracker')
  tracker;

  @service
  userSession;

  @service
  ajax;

  @service
  analytics;

  @service
  help;

  @service
  loading;

  @service
  session;

  @service
  router;

  @alias('data.productTypeOptions')
  productTypeOptions;

  @alias('data.standardOptions')
  standardOptions;

  @alias('data.weldingDetailOptions')
  weldingDetailOptions;

  @alias('data.weldingProcessOptions')
  weldingProcessesAll;

  @alias('data.weldingPositionOptions')
  weldingPositionsAll;

  @alias('data.weldTypeOptions')
  weldTypeOptionsAll;

  @alias('data.companyOptions')
  companyOptions;

  @alias('selectedStandard.code')
  industryCode;

  @alias('data.specialRequirementOptions')
  specialRequirementOptions;

  @alias('selectedStandard.code')
  currentIndustryCode;

  @alias('userSession.temperatureUnit')
  temperatureUnit;

  @alias('userSession.distanceUnit')
  distanceUnit;

  @alias('userSession.heatDistanceUnit')
  heatDistanceUnit;

  @alias('userSession.decimalSeparator')
  decimalSeparator;

  selectedStandard = null;

  showSearchParameters = false;

  showAdvancedParameters = false;

  dissmilarMaterials = false;

  baseMaterialThickness = null;

  selectedFillerMaterialRoot = null;

  selectedFillerMaterialFill = null;

  @tracked
  baseMaterial1 = null;

  @tracked
  baseMaterial2 = null;

  @tracked
  baseMaterial = null;

  @tracked
  searchBaseMaterialByGroup = true;

  @tracked
  searchBaseMaterial1ByGroup = true;

  @tracked
  searchBaseMaterial2ByGroup = true;

  @empty('selectedStandard')
  fieldsDisabled;

  @not('fieldsDisabled')
  isStandardSelected;

  @filterFromCollectionByKey('weldTypeOptionsAll', 'industryCode.id', 'currentIndustryCode.id')
  weldTypeOptions;

  @filterFromCollectionByKey('weldingProcessesAll', 'industryCode.id', 'currentIndustryCode.id')
  weldingProcessOptions;

  @filterFromCollectionByKey('weldingPositionsAll', 'industryCode.id', 'currentIndustryCode.id')
  weldingPositionOptions;

  @equal('selectedWeldType.weldType', WELD_TYPES.OVERLAY_WELD)
  isOverlayWeld;

  @not('isOverlayWeld')
  showDissimilarMaterials;

  @alias('data.overlayTypeOptions')
  overlayTypeOptions;

  @tracked
  findProjectWPSs = false;

  getGridColumnLabel(label, options = {}) {
    return this.intl.t(`wps-list.grid-columns.${label}`, options);
  }

  @computed
  get gridColumns() {
    let wpsNumberLabel = this.intl.t('wps-list.grid-columns.wps-name');
    let pqrNumberLabel = this.intl.t('model.pqr.model-plural');
    let weldingProcessesLabel = this.getGridColumnLabel('welding-processes');
    let weldTypeLabel = this.getAttributeLabel('weldType');
    let diameterLabel = this.getGridColumnLabel('diameter', { unit: get(this, 'distanceUnit') });
    let thicknessLabel = this.getGridColumnLabel('base-material-thickness', {
      unit: get(this, 'distanceUnit'),
    });
    let baseMaterialsLabel = this.getGridColumnLabel('base-materials');
    let heatInputLabel = this.getGridColumnLabel('heat-input', {
      unit: get(this, 'heatDistanceUnit'),
    });
    let preHeatLabel = this.getGridColumnLabel('pre-heat', { unit: get(this, 'temperatureUnit') });
    let pwhtLabel = this.getGridColumnLabel('post-weld-heat-treatment', {
      unit: get(this, 'temperatureUnit'),
    });

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet', 'laptop'],
      },
      {
        valuePath: 'wpsNumber',
        label: wpsNumberLabel,
        width: '200px',
        cellComponent: 'weldnote-grid/wps-link',
        sortable: false,
      },
      {
        valuePath: 'pqrs',
        label: pqrNumberLabel,
        width: '200px',
        cellComponent: 'weldnote-grid/pqrs-link',
        sortable: false,
      },
      {
        valuePath: 'weldType',
        width: '100px',
        label: weldTypeLabel,
        cellComponent: 'weldnote-grid/weld-type',
        sortable: false,
      },
      {
        valuePath: 'weldingProcesses',
        label: weldingProcessesLabel,
        width: '120px',
        sortable: false,
        cellComponent: 'weldnote-grid/welding-processes',
        breakpoints: ['tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'baseMaterials',
        label: baseMaterialsLabel,
        cellComponent: 'weldnote-grid/base-material-for-list',
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'thickness',
        label: thicknessLabel,
        align: 'right',
        cellComponent: 'weldnote-grid/base-material-thickness-for-list',
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'diameter',
        label: diameterLabel,
        align: 'right',
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'heatInput',
        label: heatInputLabel,
        align: 'right',
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'preHeat',
        label: preHeatLabel,
        align: 'right',
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'postWeldHeatTreatment',
        label: pwhtLabel,
        align: 'right',
        sortable: false,
        breakpoints: ['desktop'],
      },
    ];
  }

  buildSingleFilter(controllerProperty, apiProperty, filters) {
    if (!isEmpty(get(this, controllerProperty))) {
      filters[apiProperty] = get(this, controllerProperty);
    }
  }

  buildDecimalFilter(controllerProperty, apiProperty, filters) {
    if (!isEmpty(get(this, controllerProperty))) {
      let decimalValue = get(this, controllerProperty);
      decimalValue = convertDecimals(decimalValue, get(this, 'userSession.decimalSeparator'));
      filters[apiProperty] = decimalValue;
    }
  }

  buildPWHTFilter(filters) {
    let pwhtPresence = get(this, 'selectedPostWeldHeatTreamentPresence');
    if (!isEmpty(pwhtPresence)) {
      filters.pwhtPresence = pwhtPresence;
    } else {
      let pwhtTemp = get(this, 'selectedPostWeldHeatTreament');
      if (!isNaN(parseFloat(pwhtTemp))) {
        filters.postWeldHeatTreatment = pwhtTemp;
      }
    }
  }

  buildFilters() {
    let filters = {};

    this.buildSingleFilter('selectedStandard.id', WPS.STANDARD, filters);
    this.buildSingleFilter('selectedConstructionStandard.id', WPS.CONSTRUCTION_STANDARD, filters);
    this.buildSingleFilter('selectedWeldType.id', WPS.WELDTYPE, filters);
    this.buildSingleFilter('selectedProcessRoot.id', WPS.WELDING_PROCESS_ROOT, filters);
    this.buildSingleFilter('selectedProcessFill.id', WPS.WELDING_PROCESS_FILL, filters);
    this.buildSingleFilter('selectedProcessCap.id', WPS.WELDING_PROCESS_CAP, filters);
    this.buildSingleFilter('selectedPQR.id', WPS.MAIN_PQR, filters);
    this.buildSingleFilter('selectedCompany.id', WPS.COMPANY, filters);
    this.buildDecimalFilter('diameter', 'diameter', filters);
    this.buildDecimalFilter('thickness', 'thickness', filters);
    this.buildSingleFilter('selectedWeldingPositionRoot.id', WPS.WELDING_POSITIONS_ROOT, filters);
    this.buildSingleFilter('selectedWeldingPositionFill.id', WPS.WELDING_POSITIONS_FILL, filters);
    this.buildSingleFilter('selectedWeldingPositionCap.id', WPS.WELDING_POSITIONS_CAP, filters);
    this.buildSingleFilter('selectedProductType.key', WPS.PRODUCT_TYPE, filters);
    if (!isEmpty(this.baseMaterial)) {
      if (this.searchBaseMaterialByGroup) {
        this.buildSingleFilter('baseMaterial.materialGroup.id', WPS.BASE_MATERIAL1, filters);
        this.buildSingleFilter('baseMaterial.materialGroup.id', WPS.BASE_MATERIAL2, filters);
      } else {
        this.buildSingleFilter('baseMaterial.id', 'baseMaterial1Instance', filters);
        this.buildSingleFilter('baseMaterial.id', 'baseMaterial2Instance', filters);
      }
    } else {
      if (this.searchBaseMaterial1ByGroup) {
        this.buildSingleFilter('baseMaterial1.materialGroup.id', WPS.BASE_MATERIAL1, filters);
      } else {
        this.buildSingleFilter('baseMaterial1.id', 'baseMaterial1Instance', filters);
      }
      if (this.searchBaseMaterial2ByGroup) {
        this.buildSingleFilter('baseMaterial2.materialGroup.id', WPS.BASE_MATERIAL2, filters);
      } else {
        this.buildSingleFilter('baseMaterial2.id', 'baseMaterial2Instance', filters);
      }
    }
    this.buildSingleFilter('selectedFillerMaterialRoot.id', WPS.FILLER_MATERIAL_ROOT, filters);
    this.buildSingleFilter('selectedFillerMaterialFill.id', WPS.FILLER_MATERIAL_FILL, filters);
    this.buildSingleFilter('selectedFillerMaterialCap.id', WPS.FILLER_MATERIAL_CAP, filters);
    this.buildSingleFilter('branchAngle', 'branchAngle', filters);
    this.buildDecimalFilter('heatInput', 'heatInput', filters);
    this.buildSingleFilter('preHeat', 'preHeat', filters);
    this.buildSingleFilter('postWeldHeatTreatment', 'postWeldHeatTreatment', filters);
    this.buildDecimalFilter('impactTemperature', WPS.IMPACT_TEST_TEMPERATURE, filters);
    this.buildSingleFilter('hardnessRequirements', 'hardnessRequirements', filters);
    this.buildSingleFilter('throatThickness', 'throatThickness', filters);
    this.buildDecimalFilter('baseMaterialThickness', 'baseMaterialThickness', filters);
    this.buildDecimalFilter('baseMaterial1Thickness', 'baseMaterial1Thickness', filters);
    this.buildDecimalFilter('baseMaterial2Thickness', 'baseMaterial2Thickness', filters);
    this.buildSingleFilter('selectedOverlayType.key', WPS.OVERLAY_TYPE, filters);
    this.buildSingleFilter('certificateNumber', WPS.WPS_NUMBER, filters);
    this.buildPWHTFilter(filters);

    if (!isEmpty(this.selectedDetails)) {
      let detailsSerialized = this.selectedDetails
        .map((item) => {
          return `${item.get('id')}`;
        })
        .join(',');
      filters.weldingDetails = detailsSerialized;
    }

    if (!isEmpty(this.selectedSpecialRequirements)) {
      let requirementsSerialized = this.selectedSpecialRequirements
        .map((item) => {
          return `${item.get('id')}`;
        })
        .join(',');
      filters.specialRequirements = requirementsSerialized;
    }

    if (this.findProjectWPSs) {
      filters.wpsSearchType = 'primaryPlusProject';
    }

    return filters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async (otherOptions = {}) => {
      let options = {};

      if (otherOptions.userSearch) {
        options.userSearch = true;
      }

      if (!options.sort) {
        options.sort = {};
      }
      options.sort.wpsNumber = 'asc';

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllWeldingProcedureSpecifications(
        options,
        this.buildFilters()
      );
      set(this, 'model', data.list);
      set(this, 'totalCount', data.count);
    }
  );

  searchPQR = task(
    {
      restartable: true,
    },
    async (term) => {
      await timeout(DEBOUNCE);
      return await this.weldnoteData.searchPQR(term);
    }
  );

  clearProperties() {
    this.setProperties({
      selectedStandard: null,
      selectedConstructionStandard: null,
      selectedDetails: [],
      selectedWeldType: null,
      selectedProcessRoot: null,
      selectedProcessFill: null,
      selectedProcessCap: null,
      diameter: null,
      diameterDisplay: null,
      thickness: null,
      thicknessDisplay: null,
      selectedProductType: null,
      selectedWeldingPositionRoot: null,
      selectedWeldingPositionFill: null,
      selectedWeldingPositionCap: null,
      branchAngle: null,
      preHeat: null,
      heatInput: null,
      postWeldHeatTreatment: null,
      thicknessRoot: null,
      thicknessFill: null,
      impactTemperature: null,
      hardnessRequirements: null,
      selectedFillerMaterialRoot: null,
      selectedFillerMaterialFill: null,
      selectedFillerMaterialCap: null,
      throatThickness: null,
      throatDisplay: null,
      selectedSpecialRequirements: [],
      selectedOverlayType: null,
      certificateNumber: null,
      selectedPQR: null,
      selectedCompany: null,
      selectedPostWeldHeatTreament: null,
      selectedPostWeldHeatTreamentPresence: null,
      selectedBaseMaterialGroup: null,
      baseMaterialThickness: null,
      baseMaterialThicknessDisplay: null,
      baseMaterial1Thickness: null,
      baseMaterial1ThicknessDisplay: null,
      baseMaterial2Thickness: null,
      baseMaterial2ThicknessDisplay: null,
    });
    this.baseMaterial2 = null;
    this.baseMaterial1 = null;
    this.baseMaterial = null;
    this.searchBaseMaterial1ByGroup = true;
    this.searchBaseMaterial2ByGroup = true;
    this.searchBaseMaterialByGroup = true;
    this.findProjectWPSs = false;
    this.resetPage();
    this.loadData.perform();
  }

  scrollToSearchResults() {
    this.scroller.scrollVertical('#wps-search-grid');
  }

  resetPage() {
    set(this, 'currentPage', 1);
  }

  searchWPS(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform({
      userSearch: true,
    });
  }

  exportExcel = task(
    {
      drop: true,
    },
    async (wpsId) => {
      let { loading, intl, session } = this;

      loading.block(
        intl.t(
          'components.model-welding-procedure-specification/edit.messages.block-wait-excel-download'
        )
      );

      try {
        await downloadExcel(
          `/api/welding-procedure-specifications/${wpsId}/downloadExcel`,
          session
        );
      } finally {
        loading.unblock();
      }
    }
  );

  exportPdf = task(
    {
      drop: true,
    },
    async (wpsId) => {
      let { loading, session, intl } = this;
      loading.block(
        intl.t(
          'components.model-welding-procedure-specification/edit.messages.block-wait-pdf-download'
        )
      );

      try {
        await downloadPdf(`/api/welding-procedure-specifications/${wpsId}/downloadPDF`, session);
      } finally {
        loading.unblock();
      }
    }
  );

  @action
  updateStandard(selectedStandard) {
    this.clearProperties();
    set(this, 'selectedStandard', selectedStandard);
    if (isEmpty(this.weldTypeOptionsAll)) {
      this.weldnoteData.getWeldTypes().then((types) => {
        set(this, 'weldTypeOptionsAll', types);
      });
    }
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.searchWPS(pageNumber);
    this.scrollToSearchResults();
  }

  @action
  clearSearch() {
    this.clearProperties();
  }

  @action
  fillerMaterialRootRemoved() {
    set(this, 'selectedFillerMaterialRoot', null);
  }

  @action
  fillerMaterialRootChosen(fillerMaterial) {
    set(this, 'selectedFillerMaterialRoot', fillerMaterial);
  }

  @action
  fillerMaterialFillChosen(fillerMaterial) {
    set(this, 'selectedFillerMaterialFill', fillerMaterial);
  }

  @action
  fillerMaterialCapChosen(fillerMaterial) {
    set(this, 'selectedFillerMaterialCap', fillerMaterial);
  }

  @action
  fillerMaterialFillRemoved() {
    set(this, 'selectedFillerMaterialFill', null);
  }

  @action
  fillerMaterialCapRemoved() {
    set(this, 'selectedFillerMaterialCap', null);
  }

  @action
  setSelectedWeldType(type) {
    if (isEmpty(type)) {
      set(this, 'selectedOverlayType', null);
      set(this, 'selectedWeldType', null);
    } else {
      set(this, 'selectedWeldType', type);
      if (type.get('weldType') !== WELD_TYPES.OVERLAY_WELD) {
        set(this, 'selectedOverlayType', null);
      }
    }
  }

  @action
  goToPQR(id) {
    this.router.transitionTo('pqr', id);
  }

  @action
  download() {
    let filters = this.buildFilters();
    filters.exportType = 'wps';
    this.ajax
      .request('/export/get-token', {
        method: 'POST',
        data: JSON.stringify(filters),
        dataType: 'json',
      })
      .then((data) => {
        let namespace = this.store.adapterFor('application').get('namespace');
        let host = `${location.protocol}//${location.hostname}/${namespace}`;
        if (!isEmpty(Env.apiHost)) {
          host = Env.apiHost;
        }
        window.location = `${host}/export/download?token=${data}`;
      });
  }

  @action
  setCertificateNumber(number) {
    set(this, 'certificateNumber', number);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setThickness(value) {
    set(this, 'thicknessDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'thickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'thickness', value);
      }
    } else {
      set(this, 'thickness', value);
    }
  }

  @action
  setDiameter(value) {
    set(this, 'diameterDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'diameter', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'diameter', value);
      }
    } else {
      set(this, 'diameter', value);
    }
  }

  @action
  setThroatThickness(value) {
    set(this, 'throatThicknessDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'throatThickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'throatThickness', value);
      }
    } else {
      set(this, 'throatThickness', value);
    }
  }

  @action
  setBaseMaterial1Thickness(value) {
    set(this, 'baseMaterial1ThicknessDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'baseMaterial1Thickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'baseMaterial1Thickness', value);
      }
    } else {
      set(this, 'baseMaterial1Thickness', value);
    }
  }

  @action
  setBaseMaterial2Thickness(value) {
    set(this, 'baseMaterial2ThicknessDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'baseMaterial2Thickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'baseMaterial2Thickness', value);
      }
    } else {
      set(this, 'baseMaterial2Thickness', value);
    }
  }

  @action
  setBaseMaterialThickness(value) {
    set(this, 'baseMaterialThicknessDisplay', value);
    if (this.userSession.unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'baseMaterialThickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'baseMaterialThickness', value);
      }
    } else {
      set(this, 'baseMaterialThickness', value);
    }
  }

  @action
  setDissimilarMaterials(value) {
    if (value) {
      set(this, 'baseMaterialThickness', null);
      this.baseMaterial = null;
      this.searchBaseMaterialByGroup = true;
    } else {
      this.baseMaterial1 = null;
      this.baseMaterial2 = null;
      this.searchBaseMaterial1ByGroup = true;
      this.searchBaseMaterial2ByGroup = true;
      set(this, 'baseMaterial1Thickness', null);
      set(this, 'baseMaterial2Thickness', null);
    }
    set(this, 'dissimilarMaterials', value);
  }

  @action
  displayHelpVideo(videoId) {
    set(this, 'showVideo', true);
    set(this, 'videoId', videoId);
    let { analytics, help } = this;
    analytics.trackEvent(help.convertVideoIdToAnalyticsEvent(videoId), {
      location: 'wps-list',
    });
  }

  @action
  setBaseMaterial1(material) {
    this.baseMaterial1 = material;
    if (material && material.isNA) {
      this.searchBaseMaterial1ByGroup = false;
    }
  }

  @action
  removeBaseMaterial1() {
    this.baseMaterial1 = null;
    this.searchBaseMaterial1ByGroup = true;
  }

  @action
  setBaseMaterial2(material) {
    this.baseMaterial2 = material;
    if (material && material.isNA) {
      this.searchBaseMaterial2ByGroup = false;
    }
  }

  @action
  removeBaseMaterial2() {
    this.baseMaterial2 = null;
    this.searchBaseMaterial2ByGroup = true;
  }

  @action
  setBaseMaterial(material) {
    this.baseMaterial = material;
    if (material && material.isNA) {
      this.searchBaseMaterialByGroup = false;
    }
  }

  @action
  removeBaseMaterial() {
    this.baseMaterial = null;
    this.searchBaseMaterialByGroup = false;
  }

  @action
  setSearchByMaterialGroup(value) {
    this.searchBaseMaterialByGroup = value;
  }

  @action
  setSearchByMaterial1Group(value) {
    this.searchBaseMaterial1ByGroup = value;
  }

  @action
  setSearchByMaterial2Group(value) {
    this.searchBaseMaterial2ByGroup = value;
  }

  @action
  setFindProjectWPSs(value) {
    this.findProjectWPSs = value;
  }
}
