import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.hasImage}}\n  <img style=\"max-width:100%\" src=\"{{this.imagePath}}\" alt=\"\" width={{@width}} height={{@height}} />\n{{else}}\n  <span class=\"fa-stack fa-lg\">\n    <i class=\"fa fa-camera fa-stack-1x\"></i>\n    <i class=\"fa fa-ban fa-stack-2x\"></i>\n  </span>\n{{/if}}", {"contents":"{{#if this.hasImage}}\n  <img style=\"max-width:100%\" src=\"{{this.imagePath}}\" alt=\"\" width={{@width}} height={{@height}} />\n{{else}}\n  <span class=\"fa-stack fa-lg\">\n    <i class=\"fa fa-camera fa-stack-1x\"></i>\n    <i class=\"fa fa-ban fa-stack-2x\"></i>\n  </span>\n{{/if}}","moduleName":"weldnote/components/w-image-preview.hbs","parseOptions":{"srcName":"weldnote/components/w-image-preview.hbs"}});
import classic from 'ember-classic-decorator';
import { alias, notEmpty } from '@ember/object/computed';
import Component from '@ember/component';

@classic
export default class WImagePreview extends Component {
  @notEmpty('imageLink')
  hasImage;

  @alias('imageLink')
  imagePath;
}
