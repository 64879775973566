import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a class=\"row-toggle\" {{action \"toggleRow\" @row}}>\n  <i class=\"fa {{if @row.expanded \"fa-chevron-down\" \"fa-chevron-right\"}}\"></i>\n</a>", {"contents":"<a class=\"row-toggle\" {{action \"toggleRow\" @row}}>\n  <i class=\"fa {{if @row.expanded \"fa-chevron-down\" \"fa-chevron-right\"}}\"></i>\n</a>","moduleName":"weldnote/components/weldnote-grid/row-toggle.hbs","parseOptions":{"srcName":"weldnote/components/weldnote-grid/row-toggle.hbs"}});
import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Component from '@ember/component';

@classic
export default class RowToggle extends Component {
  @action
  toggleRow(row) {
    if (row) {
      row.toggleProperty('expanded');
    }
  }
}
