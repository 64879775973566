import Route from '@ember/routing/route';
import { set, action } from '@ember/object';
import { service } from '@ember/service';

export default class WeldRoute extends Route {
  @service('project-weld-change-tracker')
  tracker;

  @service
  store;

  model(params) {
    // Reload: true option is important because the list of welds, loads actual welds
    // as such when find-record is called with a weld id that was already loaded
    // ember-data resolves from cache (and initiates the request to the backend)
    // but the list of welds query includes less data, as such when the template needs
    // one of the sync relations, it will fail because they are not loaded
    // the reload option makes the store skip the cache, and reload the data from the
    // backend
    return this.store.findRecord('project-weld', params.id, {
      include:
        'component,component.component-drawing,weld-type,wps,base-material1,tack-welder,welders-root-layer,welders-fill-layer,welders-cap-layer,inspections,inspections.test,inspections.report,inspections.test-stage-name,inspections.test-location,pwht-report,component.component-drawing,base-material1.base-material,base-material2,base-material2.base-material,specific-base-material1,specific-base-material1.base-material,specific-base-material2,specific-base-material2.base-material,filler-material-root,filler-material-root.filler-material,filler-material-fill,filler-material-fill.filler-material,filler-material-cap,filler-material-cap.filler-material,fitter,specific-filler-material-process1,specific-filler-material-process2,specific-filler-material-process3',

      reload: true,
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    this.tracker.trackWeld(model);
    set(controller, 'project', this.modelFor('project'));
  }

  @action
  willTransition(transition) {
    if (this.tracker.didWeldChange()) {
      transition.abort();
      this.tracker.displaySaveAlert();
    } else {
      this.tracker.clearState();
      // Bubble the `willTransition` action so that
      // parent routes can decide whether or not to abort.
      return true;
    }
  }
}
