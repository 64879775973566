import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import classic from 'ember-classic-decorator';
import { task, timeout } from 'ember-concurrency';
import ListController from 'weldnote/controllers/_base/list-js';

@classic
export default class CompaniesController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  @service
  ajax;

  @service
  loading;

  @service
  orgApi;

  @tracked
  totalCount = 0;

  @tracked
  name = '';

  @tracked
  model = null;

  @tracked
  currentPage = 1;

  get companyCount() {
    if (!isEmpty(this.result?.meta?.records)) {
      return this.result.meta.records;
    }
    return this.totalCount;
  }

  get pageSize() {
    return this.data.pageSize;
  }

  get result() {
    return this.loadData?.lastSuccessful?.value;
  }

  modelName = 'company';

  get gridColumns() {
    let nameLabel = this.getAttributeLabel('name');
    let addressLabel = this.getAttributeLabel('address');

    let { intl, userSession } = this;

    let columns = [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'name',
        label: nameLabel,
        cellComponent: 'weldnote-grid/company-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
        sortable: false,
      },
      {
        valuePath: 'address',
        label: addressLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      },
    ];

    if (userSession.isPlanQuality) {
      let weldsPerformedLabel = intl.t('generic.welding.welds-performed');
      let weldsRepairedLabel = intl.t('generic.welding.welds-repaired');
      columns.pushObject({
        valuePath: 'totalWelds',
        label: weldsPerformedLabel,
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      });
      columns.pushObject({
        valuePath: 'repairRate',
        label: weldsRepairedLabel,
        cellComponent: 'weldnote-grid/repair-rate',
        breakpoints: ['laptop', 'desktop'],
        sortable: false,
      });
    }

    return columns;
  }

  buildFilters() {
    let filters = { name: this.name };
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = this.currentPage;

      await timeout(500);
      let result = await this.data.listAllCompanies(options, this.buildFilters());
      this.model = result;
      return result;
    }
  );

  resetPage() {
    this.currentPage = 1;
  }

  @action
  search(pageNumber = 1) {
    this.currentPage = pageNumber;
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setName(name) {
    this.name = name;
    this.resetPage();
  }

  @action
  clearFields() {
    this.resetPage();
    if (!isEmpty(this.name)) {
      this.name = null;
      this.loadData.perform();
    }
  }

  @action
  async syncSites() {
    let { loading, intl } = this;
    try {
      loading.block(intl.t('routes.companies.messages.syncing-companies'));
      await this.orgApi.syncSites();
      await this.userSession.reloadUser();
      loading.replaceMessage(intl.t('routes.companies.messages.reloading-companies'));
      this.search();
    } finally {
      loading.unblock();
    }
  }
}
