import { computed, get } from '@ember/object';
import { AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import WeldingProcess from './welding-process';
import IndustryCode from './industry-code';
import FillerMaterialGroup from './filler-material-group';
import FillerMaterialClassification from './filler-material-classification';
import FillerMaterialSpecification from './filler-material-specification';

export default class FillerMaterial extends BaseModel {
  @belongsTo('filler-material-specification', { async: false })
  declare specification: FillerMaterialSpecification;

  @belongsTo('filler-material-classification', { async: false })
  declare classification: FillerMaterialClassification;

  @belongsTo('filler-material-group', { async: false })
  declare group: FillerMaterialGroup;

  @belongsTo('industry-code', { async: false })
  declare code: IndustryCode;

  @attr('boolean')
  declare system?: boolean;

  @hasMany('welding-process')
  declare weldingProcesses: AsyncHasMany<WeldingProcess>;

  @attr('string')
  declare fillerMaterialLabel?: string;

  get groupName(): FillerMaterialGroup {
    return this.group;
  }

  @computed('specification', 'classification')
  get visualLabel(): string {
    let spec: string = '';
    if (this.specification && !isEmpty(get(this.specification, 'id'))) {
      spec = get(this.specification, 'visualLabel');
    }

    let classification: string = '';
    if (this.classification && !isEmpty(get(this.classification, 'id'))) {
      classification = get(this.classification, 'visualLabel');
    }

    return `${spec} - ${classification}`;
  }

  validations = {
    specification: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    classification: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    group: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    code: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'filler-material',
    specification: { required: true },
    classification: { required: true },
    group: { required: true },
    code: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'filler-material': FillerMaterial;
  }
}
