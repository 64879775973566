import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { sort } from '@ember/object/computed';
import Controller from '@ember/controller';
import { set, get, action, computed } from '@ember/object';
import { isPermissionDenied } from 'weldnote/utils/json-api-errors';

@classic
export default class AccountAdminController extends Controller {
  @service('weldnote-data')
  data;

  @service('alert-message')
  alert;

  @service
  loading;

  @service
  userSession;

  @service
  intl;

  roleSorting = ['name'];

  @sort('data.roleOptions', 'roleSorting')
  sortedRoles;

  showConfirmModal = false;

  @computed('model')
  get accounts() {
    let accounts = get(this, 'model');
    return accounts
      .filter((account) => {
        return `${get(account, 'id')}` !== `${get(this, 'userSession.account.id')}`;
      })
      .sortBy('name');
  }

  internalClear() {
    set(this, 'currentAccount', null);
    set(this, 'newRole', null);
    set(this, 'oldRole', null);
  }

  @action
  confirmUpdate(account, role) {
    if (`${get(account, 'role.id')}` !== `${get(role, 'id')}`) {
      this.toggleProperty('showConfirmModal');
      set(this, 'currentAccount', account);
      set(this, 'newRole', role);
      set(this, 'oldRole', get(account, 'role'));
    }
  }

  @action
  updateRole() {
    let { intl, loading, data, alert, currentAccount: account, newRole: role } = this;
    loading.block();
    data
      .updateRole(get(account, 'id'), get(role, 'id'))
      .then(() => {
        set(account, 'role.id', get(role, 'id'));
        set(account, 'role.name', get(role, 'name'));
        alert.success(
          intl.t('account-admin.confirm-role-updated', {
            accountName: get(this, 'currentAccount.name'),
            role: get(this, 'newRole.name'),
          })
        );
      })
      .catch((reason) => {
        if (isPermissionDenied(reason)) {
          alert.error(intl.t('edit-view.actions.permission-denied'));
        } else {
          alert.error(
            intl.t('edit-view.actions.generic-error', { name: get(this, 'currentAccount.name') })
          );
        }
      })
      .finally(() => {
        loading.unblock();
        this.internalClear();
      });
  }

  @action
  clear() {
    this.internalClear();
    this.toggleProperty('showConfirmModal');
  }

  @action
  updateAccounts() {
    this.data.getAccounts().then((accounts) => {
      set(this, 'model', accounts);
    });
  }
}
