import { attr, belongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import FillerMaterial from './filler-material';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

export default class FillerMaterialCommercialDesignation extends BaseModel {
  @attr('string')
  declare commercialDesignation?: string;

  @belongsTo('filler-material', { async: false })
  declare fillerMaterial: FillerMaterial;

  get visualLabel(): string {
    return this.commercialDesignation || '';
  }

  validations = {
    commercialDesignation: { presence: true },
    fillerMaterial: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'filler-material-commercial-designation',
    commercialDesignation: { required: true },
    fillerMaterial: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'filler-material-commercial-designation': FillerMaterialCommercialDesignation;
  }
}
