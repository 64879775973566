export default function () {
  this.transition(
    this.hasClass('document-list'),

    // this makes our rule apply when the liquid-if transitions to the
    // true state.
    this.toValue(true),
    this.use('toDown', { duration: 300 }),

    // which means we can also apply a reverse rule for transitions to
    // the false state.
    this.reverse('toUp', { duration: 300 })
  );

  this.transition(
    this.hasClass('sketch-instructions'),

    // this makes our rule apply when the liquid-if transitions to the
    // true state.
    this.toValue(true),
    this.use('toDown', { duration: 800 }),

    // which means we can also apply a reverse rule for transitions to
    // the false state.
    this.reverse('toUp', { duration: 300 })
  );
}
