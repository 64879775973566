import Inflector from 'ember-inflector';

const { inflector } = Inflector;

// Fix for issue with ember-data mistaking 'ga' for 'gas'
inflector.irregular('gas', 'gases');
inflector.irregular('project-wps', 'project-wps');
inflector.irregular(
  'welding-procedure-specification-metadata',
  'welding-procedure-specification-metadata'
);
inflector.irregular('ieis', 'ieis');

export default {};
