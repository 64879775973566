import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { task, timeout } from 'ember-concurrency';

@classic
export default class IndexRoute extends Route {
  @service
  session;

  @service
  welderCertificateData;

  @service('weldnote-data')
  data;

  @service
  userSession;

  @service
  fileService;

  @service
  store;

  @service
  router;

  async beforeModel(transition) {
    super.beforeModel(...arguments);
    this.session.requireAuthentication(transition, 'login');
    if (this.userSession.isTrial) {
      this.router.transitionTo('help-center');
    }
  }

  loadPQR = task(
    {
      drop: true,
    },
    async () => {
      return await this.data.listAllPQR(
        {
          pageSize: 5,
        },
        {
          latest: true,
        }
      );
    }
  );

  loadCertificates = task(
    {
      drop: true,
    },
    async () => {
      return await this.data.listAllWelderCertificates(
        {
          pageSize: 5,
        },
        {
          latest: true,
        }
      );
    }
  );

  loadWPS = task(
    {
      drop: true,
    },
    async () => {
      return await this.data.listAllWeldingProcedureSpecifications(
        {
          pageSize: 5,
        },
        {
          latest: true,
        }
      );
    }
  );

  loadLatestDocuments = task(
    {
      drop: true,
    },
    async () => {
      let pqrs = { list: [] };
      if (this.userSession.isPlanQuality) {
        pqrs = await this.loadPQR.perform();
      }
      let certificates = await this.loadCertificates.perform();
      let wps = await this.loadWPS.perform();
      return {
        pqrs: pqrs.list,
        certificates: certificates.list,
        wps: wps.list,
      };
    }
  );

  loadActionLog = task(
    {
      drop: true,
    },
    async () => {
      await timeout(2000);
      return await this.store.query('action-log', { 'paging[limit]': 5 });
    }
  );

  loadInternalCertificates = task(
    {
      drop: true,
    },
    async () => {
      await timeout(200);
      return await this.welderCertificateData.getWelderCertificatesToInternallyValidate30Days();
    }
  );

  loadExternalCertificates = task(
    {
      drop: true,
    },
    async () => {
      await timeout(200);
      return await this.welderCertificateData.getWelderCertificatesToExternallyValidate30Days();
    }
  );

  loadMiscellaneousCertificates = task(
    {
      drop: true,
    },
    async () => {
      await timeout(200);
      return await this.welderCertificateData.getMiscellaneousCertificatesExpire30Days();
    }
  );

  loadUsageData = task(
    {
      drop: true,
    },
    async () => {
      await timeout(1500);
      return await this.data.getUsageLimits();
    }
  );

  model() {
    if (this.userSession.account) {
      this.loadLatestDocuments.perform();
      this.loadActionLog.perform();
      this.loadInternalCertificates.perform();
      this.loadExternalCertificates.perform();
      this.loadMiscellaneousCertificates.perform();
      this.loadUsageData.perform();

      return {
        actionLog: this.loadActionLog,
        internalCertificates: this.loadInternalCertificates,
        externalCertificates: this.loadExternalCertificates,
        miscellaneousCertificates: this.loadMiscellaneousCertificates,
        usageData: this.loadUsageData,
        latestDocuments: this.loadLatestDocuments,
        loaded: true,
      };
    }
    return {
      loaded: false,
      routeInstance: this,
    };
  }

  @action
  transitionToLoginRoute() {
    this.router.transitionTo('login');
  }
}
