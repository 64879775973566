import { get, action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import EditController from 'weldnote/controllers/_base/edit';
import ProjectDocument from 'weldnote/models/project-document';

export default class ProjectDocumentController extends EditController {
  @service
  declare router: RouterService;

  @action
  onSaveRevision(revision: ProjectDocument) {
    this.router.transitionTo('project-document', get(revision, 'id'));
  }
}
