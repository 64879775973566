import { attr, belongsTo } from '@ember-data/model';
import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import IndustryCode from './industry-code';

export default class BaseMaterialGroup extends BaseModel {
  @attr('string')
  declare groupName: string;

  @belongsTo('industry-code', { async: false })
  declare code: IndustryCode;

  @attr('string')
  declare description?: string;

  @attr('number')
  declare groupOrder?: number;

  @attr('boolean')
  declare system: number;

  @attr('string')
  declare groupNumber?: string;

  @attr('string')
  declare subgroupNumber?: string;

  @computed('groupName', 'groupNumber', 'subgroupNumber', 'code')
  get visualLabel(): string {
    let { groupName, groupNumber, subgroupNumber, code: industryCode } = this;
    if (!isEmpty(groupNumber) && !isEmpty(industryCode)) {
      let code = get(industryCode, 'code');
      if (code === 'ISO') {
        if (!isEmpty(subgroupNumber)) {
          return `${groupNumber}.${subgroupNumber}`;
        }
        return groupNumber || '';
      } else if (code === 'ASME') {
        if (!isEmpty(subgroupNumber)) {
          return `P${groupNumber} Gr${subgroupNumber}`;
        }
        return `P${groupNumber}`;
      } else if (code === 'AWS') {
        return groupNumber || '';
      }
    }
    return groupName || '';
  }

  validations = {
    groupName: {
      presence: {
        message(_key: string, _value: string, model: BaseMaterialGroup): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    code: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'base-material-group',
    groupName: {
      required: true,
    },

    code: {
      required: true,
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'base-material-group': BaseMaterialGroup;
  }
}
