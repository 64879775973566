export default {
  PIPING_PIECE: {
    DIAMETER: 'diameter',
    THICKNESS: 'thickness',
    DIAMETER_INCHES: 'diameterInches',
    THICKNESS_INCHES: 'thicknessInches',
    SCHEDULE: 'schedule',
    NOMINAL_DIAMETER: 'nominalDiameter',
  },
};
