import { attr } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';

export default class FileUpload extends BaseModel {
  @attr('string')
  declare filePath?: string;

  @attr('string')
  declare context?: string;

  @attr('number')
  declare fileSize?: number;

  @attr('string')
  declare originalFilename?: string;

  @attr('string')
  declare downloadPath?: string;

  get visualLabel(): string {
    return this.filePath || '';
  }

  validations = {
    filePath: { presence: true },
  };

  metadata = {
    modelName: 'file-upload',
    filePath: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'file-upload': FileUpload;
  }
}
