import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { set, get, getProperties, action, computed } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class FillerMaterialCertificatesController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'filler-material-certificate';

  isLoading = false;

  @computed
  get gridColumns() {
    let batchNumberLabel = this.getAttributeLabel('batchNumber');
    let fillerMaterialLabel = this.getAttributeLabel('fillerMaterial');
    let diameterLabel = this.getAttributeLabel('diameter', { unit: this.userSession.distanceUnit });
    let commercialDesignationLabel = this.getAttributeLabel('commercialDesignation');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'batchNumber',
        label: batchNumberLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/filler-material-certificate-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'fillerMaterial.classification.classification',
        label: fillerMaterialLabel,
        sortable: false,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'diameter',
        label: diameterLabel,
        align: 'right',
        sortable: false,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'commercialDesignation.commercialDesignation',
        label: commercialDesignationLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
    ];
  }

  buildFilters() {
    let filters = getProperties(this, 'batchNumber');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};

      options.page = get(this, 'currentPage');

      await timeout(DEBOUNCE);
      let data = await this.data.listAllFillerMaterialCertificates(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setBatchNumber(batchNumber) {
    set(this, 'batchNumber', batchNumber);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  clearFields() {
    this.resetPage();
    this.setProperties({
      batchNumber: null,
    });
    this.loadData.perform();
  }
}
