import { computed } from '@ember/object';
import { belongsTo, attr } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import IndustryCode from './industry-code';

export default class WeldingProcess extends BaseModel {
  @attr('string')
  declare processName: string;

  @attr('string')
  declare shortDesignation: string;

  @belongsTo('industry-code', { async: false })
  declare industryCode: IndustryCode;

  @attr('boolean')
  declare system: boolean;

  @computed('shortDesignation', 'processName')
  get visualLabel(): string {
    let { shortDesignation } = this;
    if (isEmpty(shortDesignation)) {
      shortDesignation = '';
    }
    return shortDesignation || '';
  }

  validations = {
    shortDesignation: { presence: true },
    industryCode: { presence: true },
  };

  metadata = {
    modelName: 'welding-process',
    shortDesignation: {
      required: true,
    },

    industryCode: {
      required: true,
    },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'welding-process': WeldingProcess;
  }
}
