import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { getProperties, action, computed, set } from '@ember/object';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class WeldingProcessesController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  @service
  userSession;

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'welding-process';

  @computed
  get gridColumns() {
    let shortDesignationLabel = this.getAttributeLabel('shortDesignation');
    let industryCodeLabel = this.getAttributeLabel('industryCode');
    let nameLabel = this.getAttributeLabel('processName');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile'],
      },
      {
        valuePath: 'shortDesignation',
        label: shortDesignationLabel,
        cellComponent: 'weldnote-grid/welding-process-link',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'processName',
        label: nameLabel,
        breakpoints: ['tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'industryCode.code',
        label: industryCodeLabel,
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
    ];
  }

  buildFilters() {
    let filters = getProperties(this, 'name', 'designation');
    let finalFilters = {};
    Object.keys(filters).forEach((item) => {
      if (!isEmpty(item)) {
        finalFilters[item] = filters[item];
      }
    });
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      set(this, 'isLoading', true);
      let options = {};

      let { dir = 'asc', sort } = getProperties(this, 'dir', 'sort');
      if (!isEmpty(sort)) {
        options.sort = {};
        options.sort[sort] = dir;
      }

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllWeldingProcesses(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  resetPage() {
    set(this, 'currentPage', 1);
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setName(name) {
    set(this, 'name', name);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setDesignation(designation) {
    set(this, 'designation', designation);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  clearFields() {
    this.resetPage();
    this.setProperties({
      name: null,
      designation: null,
    });
    this.loadData.perform();
  }
}
