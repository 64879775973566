import { isEmpty } from '@ember/utils';
import BaseMaterial from 'weldnote/models/base-material';

export function isSameMaterial(material1: BaseMaterial, material2: BaseMaterial) {
  return material1.id === material2.id;
}

type SimpleMaterial = {
  specification: string;
  grade: string;
  group: string;
};

export function formatMaterial({ specification, grade, group }: SimpleMaterial) {
  if (!isEmpty(specification) && !isEmpty(grade) && !isEmpty(group)) {
    return `${specification} - ${grade} - ${group}`;
  } else if (!isEmpty(specification) && !isEmpty(group)) {
    return `${specification} - ${group}`;
  }
  return '';
}

export function formatMaterialWithThickness(
  material: SimpleMaterial,
  thickness: number,
  distanceUnits: string
) {
  if (!isEmpty(thickness)) {
    return `${formatMaterial(material)} (${thickness} ${distanceUnits})`;
  } else {
    return formatMaterial(material);
  }
}
