import classic from 'ember-classic-decorator';
import Service from '@ember/service';

@classic
export default class HelpdeskWidgetService extends Service {
  setSearchItems(term) {
    if (window.zE) {
      zE(function () {
        zE.setHelpCenterSuggestions({ search: term });
      });
    }
  }
}
