import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"form-group\" ...attributes>\n  <label class=\"control-label\">{{@label}}</label>\n  <PowerSelectMultiple\n    @triggerClass={{concat \"form-control \" @cssTriggerClass}}\n    @options={{this.availableOptions}}\n    @disabled={{@disabled}}\n    @searchField={{@searchField}}\n    @selected={{@selected}}\n    @onChange={{@onChange}}\n    @placeholder={{@placeholder}}\n    @searchEnabled={{this.searchEnabled}}\n    @search={{@search}}\n    @renderInPlace={{@renderInPlace}}\n    @noMatchesMessage={{@noMatchesMessage}}\n    @beforeOptionsComponent={{@beforeOptionsComponent}}\n    @onOpen={{@onOpen}}\n    as |option|\n  >\n    {{yield option}}\n  </PowerSelectMultiple>\n  {{#if this.hasHelp}}\n    <span class=\"help-block\">{{@helpText}}</span>\n  {{/if}}\n</div>", {"contents":"<div class=\"form-group\" ...attributes>\n  <label class=\"control-label\">{{@label}}</label>\n  <PowerSelectMultiple\n    @triggerClass={{concat \"form-control \" @cssTriggerClass}}\n    @options={{this.availableOptions}}\n    @disabled={{@disabled}}\n    @searchField={{@searchField}}\n    @selected={{@selected}}\n    @onChange={{@onChange}}\n    @placeholder={{@placeholder}}\n    @searchEnabled={{this.searchEnabled}}\n    @search={{@search}}\n    @renderInPlace={{@renderInPlace}}\n    @noMatchesMessage={{@noMatchesMessage}}\n    @beforeOptionsComponent={{@beforeOptionsComponent}}\n    @onOpen={{@onOpen}}\n    as |option|\n  >\n    {{yield option}}\n  </PowerSelectMultiple>\n  {{#if this.hasHelp}}\n    <span class=\"help-block\">{{@helpText}}</span>\n  {{/if}}\n</div>","moduleName":"weldnote/components/generic-multi-lov.hbs","parseOptions":{"srcName":"weldnote/components/generic-multi-lov.hbs"}});
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { setDiff } from 'weldnote/utils/ember-computed-as-functions';

export default class GenericMultiLov extends Component {
  get searchEnabled() {
    return this.args.searchEnabled ?? false;
  }

  get hasHelp() {
    return !isEmpty(this.args.helpText);
  }

  get availableOptions() {
    return setDiff(this.args.options, this.args.selected);
  }
}
