import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @isEditing}}\n  <div class=\"block text-3xl\">\n    <input\n      type=\"checkbox\"\n      checked={{this.isChecked}}\n      {{on \"change\" (pick \"target.checked\" @onUpdate)}}\n      disabled={{@disabled}}\n      id={{@componentId}}\n    />\n  </div>\n{{else}}\n  <div class=\"ext-3xl block\">\n    <input\n      type=\"checkbox\"\n      checked={{this.isChecked}}\n      {{on \"change\" (pick \"target.checked\" @onUpdate)}}\n      disabled={{true}}\n      id={{@componentId}}\n      class=\"bg-white\"\n    />\n  </div>\n{{/if}}", {"contents":"{{#if @isEditing}}\n  <div class=\"block text-3xl\">\n    <input\n      type=\"checkbox\"\n      checked={{this.isChecked}}\n      {{on \"change\" (pick \"target.checked\" @onUpdate)}}\n      disabled={{@disabled}}\n      id={{@componentId}}\n    />\n  </div>\n{{else}}\n  <div class=\"ext-3xl block\">\n    <input\n      type=\"checkbox\"\n      checked={{this.isChecked}}\n      {{on \"change\" (pick \"target.checked\" @onUpdate)}}\n      disabled={{true}}\n      id={{@componentId}}\n      class=\"bg-white\"\n    />\n  </div>\n{{/if}}","moduleName":"weldnote/components/ui-form/input/checkbox.hbs","parseOptions":{"srcName":"weldnote/components/ui-form/input/checkbox.hbs"}});
import Component from '@glimmer/component';

export default class Checkbox extends Component {
  get isChecked() {
    if (this.args.value === true) {
      return true;
    }
    return false;
  }
}
