import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';

@classic
export default class WeldingProcedureSpecificationViewController extends Controller {
  queryParams = ['project'];

  @service
  userSession;

  @service
  router;

  project = null;

  @action
  editWps(wps) {
    this.router.transitionTo('welding-procedure-specification', wps, {
      queryParams: {
        isEditing: true,
      },
    });
  }

  @action
  afterCreateWps(wps) {
    this.router.transitionTo('welding-procedure-specification-view', wps.id);
  }

  @action
  deleteInstance(type) {
    if (type === 'pwps') {
      this.router.transitionTo('welding-procedure-specifications-preliminary');
    } else if (type === 'wps') {
      this.router.transitionTo('welding-procedure-specifications');
    }
  }
}
