import { attr, belongsTo } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import BaseModel from 'weldnote/models/base-model';
import Constants from 'weldnote/utils/constants';
import ComponentDrawing from './component-drawing';
import GeneralDrawing from './general-drawing';
import TestInspectionClass from './test-inspection-class';
import FileUpload from './file-upload';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

const { ARCHIVAL_STATE } = Constants;

export default class Ieis extends BaseModel {
  @attr('string')
  declare ieisNumber?: string;

  @attr('string', {
    defaultValue: '0',
  })
  declare revision: string;

  @belongsTo('welding-procedure-specification', { async: false })
  declare wps: any;

  @belongsTo('file-upload', { async: false })
  declare pdfFile: FileUpload;

  @attr('string')
  declare description?: string;

  @attr('string')
  declare notes?: string;

  @attr('string')
  declare pressureClass?: string;

  @attr('string')
  declare specification?: string;

  @attr('string')
  declare product?: string;

  @attr('number')
  declare operatingTemperature?: number;

  @attr('date')
  declare emissionDate?: Date;

  @attr('string')
  declare equipment?: string;

  @belongsTo('test-inspection-class', { async: false })
  declare lineClass: TestInspectionClass;

  @belongsTo('general-drawing', { async: false })
  declare referenceDrawing: GeneralDrawing;

  @belongsTo('component-drawing', { async: false })
  declare componentDrawing: ComponentDrawing;

  @attr('string', { defaultValue: ARCHIVAL_STATE.ACTIVE })
  declare archivalState: string;

  @attr({
    defaultValue() {
      return {};
    },
  })
  declare additionalData: any;

  get isArchived(): boolean {
    return this.archivalState === ARCHIVAL_STATE.ARCHIVED;
  }

  get isActive() {
    return this.archivalState === ARCHIVAL_STATE.ACTIVE;
  }

  validations = {
    ieisNumber: {
      presence: {
        message(_key: string, _value: any, model: any): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    revision: {
      presence: {
        message(_key: string, _value: any, model: any): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    wps: {
      custom: {
        validation(_key: string, value: any, _model: Ieis) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        presence: {
          message(_key: string, _value: any, model: any): string {
            return model.translate('generic.error.input-value');
          },
        },
      },
    },

    operatingTemperature: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: -9999.99,
        lessThanOrEqualTo: 9999.99,
      },
    },
  };

  metadata = {
    modelName: 'ieis',
    ieisNumber: {
      required: true,
    },

    revision: {
      required: true,
    },

    wps: {
      required: true,
    },
  };

  archive = memberAction({ path: 'archive', type: 'PUT' });

  unarchive = memberAction({ path: 'unarchive', type: 'PUT' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    ieis: Ieis;
  }
}
