import { service } from '@ember/service';
import ListController from 'weldnote/controllers/_base/list';
import UserSessionService from 'weldnote/services/user-session';

export default class StaffsController extends ListController {
  @service
  declare userSession: UserSessionService;

  modelName = 'staff';
}
