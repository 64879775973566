import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import BaseModel from 'weldnote/models/base-model';
import FileUpload from './file-upload';
import NonDestructiveTesting from './non-destructive-testing';
import Inspector from './inspector';

export default class NdtReportCertificate extends BaseModel {
  @attr('string')
  declare ndtReportNumber?: string;

  @belongsTo('file-upload')
  declare certificate: FileUpload;

  @attr('string')
  declare certificatePath?: string;

  @attr('date')
  declare reportDate?: string;

  @belongsTo('non-destructive-testing', { async: false })
  declare typeOfTest: NonDestructiveTesting;

  @attr('string')
  declare testResult?: string;

  @belongsTo('inspector')
  declare inspector: AsyncBelongsTo<Inspector>;

  get visualLabel() {
    return this.ndtReportNumber;
  }

  validations = {
    ndtReportNumber: { presence: true },
  };

  metadata = {
    modelName: 'ndt-report-certificate',
    listRoute: 'ndt-report-certificates',
    editRoute: 'ndt-report-certificate',
    ndtReportNumber: { required: true },
  };
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ndt-report-certificate': NdtReportCertificate;
  }
}
