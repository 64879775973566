import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import WeldingProcess from 'weldnote/models/welding-process';
import Const from 'weldnote/utils/constants';

const { WELDING_PROCESSES } = Const;

const PROCESS_NAME = 'shortDesignation';

// Returns all processes are the same, and at least one is not empty
export function isSameProcess(root: WeldingProcess, fill: WeldingProcess, cap: WeldingProcess) {
  let rootProcess = !isEmpty(root) ? get(root, PROCESS_NAME) : null;
  let fillProcess = !isEmpty(fill) ? get(fill, PROCESS_NAME) : null;
  let capProcess = !isEmpty(cap) ? get(cap, PROCESS_NAME) : null;
  let processes: string[] = [];

  if (rootProcess) {
    processes.pushObject(rootProcess);
  }
  if (fillProcess) {
    processes.pushObject(fillProcess);
  }
  if (capProcess) {
    processes.pushObject(capProcess);
  }

  return processes.uniq().length === 1;
}

export function areAllProcess(
  root: WeldingProcess,
  fill: WeldingProcess,
  cap: WeldingProcess,
  process: string
) {
  if (isSameProcess(root, fill, cap) && !isEmpty(process)) {
    if (!isEmpty(root)) {
      let processRoot = get(root, PROCESS_NAME);
      if (processRoot === process) {
        return true;
      }

      if (!isEmpty(fill)) {
        let processFill = get(fill, PROCESS_NAME);
        if (processFill === process) {
          return true;
        }
      }

      if (!isEmpty(cap)) {
        let processCap = get(cap, PROCESS_NAME);
        if (processCap === process) {
          return true;
        }
      }
    }
  }
  return false;
}

export function allGTAW(root: WeldingProcess, fill: WeldingProcess, cap: WeldingProcess) {
  return areAllProcess(root, fill, cap, WELDING_PROCESSES.GTAW);
}

export function isFCAW(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES.FCAW;
}

export function isSMAW(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES.SMAW;
}

export function isSAW(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES.SAW;
}

export function isGMAW(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES.GMAW;
}

export function isGTAW(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES.GTAW;
}

export function isMCAW(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES.MCAW;
}

export function isFCAWS(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES.FCAWS;
}

export function isESW(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES.ESW;
}

export function is111(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['111'];
}

export function is131(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['131'];
}

export function is135(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['135'];
}

export function is136(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['136'];
}

export function is137(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['137'];
}

export function is138(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['138'];
}

export function is121(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['121'];
}

export function is122(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['122'];
}

export function is123(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['123'];
}

export function is124(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['124'];
}

export function is125(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['125'];
}

export function is141(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['141'];
}

export function is142(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['142'];
}

export function is143(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['143'];
}

export function is145(process: WeldingProcess): boolean {
  return !isEmpty(process) && get(process, PROCESS_NAME) === WELDING_PROCESSES['145'];
}

export function isTungstenProcess(process: WeldingProcess): boolean {
  return is141(process) || is142(process) || is143(process) || is145(process) || isGTAW(process);
}
