import classic from 'ember-classic-decorator';
import { action, computed, set } from '@ember/object';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import ListController from 'weldnote/controllers/_base/list-js';
import { task, timeout } from 'ember-concurrency';
import Constants from 'weldnote/utils/constants';

const {
  HTTP: { DEBOUNCE },
} = Constants;

@classic
export default class FluxCommercialDesignationsController extends ListController {
  @service
  store;

  @service
  intl;

  @service('weldnote-data')
  data;

  totalCount = 0;

  currenPage = 1;

  @alias('data.pageSize')
  pageSize;

  modelName = 'flux-commercial-designation';

  commercialDesignation = '';

  @computed
  get gridColumns() {
    let commercialDesignationLabel = this.getAttributeLabel('commercialDesignation');
    let fluxLabel = this.intl.t('model.flux.flux.label');
    let specificationLabel = this.intl.t('model.flux.specification.label');

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet'],
      },
      {
        valuePath: 'commercialDesignation',
        label: commercialDesignationLabel,
        cellComponent: 'weldnote-grid/flux-commercial-designation-link',
      },
      {
        valuePath: 'flux.flux',
        label: fluxLabel,
        sortable: false,
      },
      {
        valuePath: 'flux.specification.specification',
        label: specificationLabel,
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
    ];
  }

  resetPage() {
    set(this, 'currentPage', 1);
  }

  buildFilters() {
    let { commercialDesignation, flux } = this;
    let finalFilters = {};
    if (!isEmpty(commercialDesignation)) {
      finalFilters.commercialDesignation = commercialDesignation;
    }
    if (!isEmpty(flux)) {
      finalFilters.fluxName = flux;
    }
    return finalFilters;
  }

  loadData = task(
    {
      restartable: true,
    },
    async () => {
      let options = {};
      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllFluxCommercialDesignations(options, this.buildFilters());
      set(this, 'model', data);
      set(this, 'totalCount', data.meta.records);
    }
  );

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform();
  }

  @action
  searchButton() {
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setCommercialDesignation(commercialDesignation) {
    set(this, 'commercialDesignation', commercialDesignation);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setFlux(flux) {
    set(this, 'flux', flux);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  clearFields() {
    this.resetPage();
    this.setProperties({
      commercialDesignation: null,
      flux: null,
    });
    this.loadData.perform();
  }
}
