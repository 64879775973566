import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showNotice}}\n  <div class=\"alert {{this.boxTypeClass}} notice-box\">\n    {{t \"components.trial-notice.trial-will-end\"}}\n    <strong>{{format-date date=this.trialEnd format=this.userSession.dateFormat}}</strong>\n    ({{t \"components.trial-notice.days-until-trial-end\" days=this.days}})\n    <a href=\"mailto:support@weldnote.com\">Please contact support</a>\n    <button\n      class=\"btn btn-xs {{this.buttonTypeClass}} m-l-md pull-right\"\n      type=\"button\"\n      {{on \"click\" this.closeNotice}}\n    ><FaIcon @icon=\"times-circle\" /></button>\n  </div>\n{{/if}}", {"contents":"{{#if this.showNotice}}\n  <div class=\"alert {{this.boxTypeClass}} notice-box\">\n    {{t \"components.trial-notice.trial-will-end\"}}\n    <strong>{{format-date date=this.trialEnd format=this.userSession.dateFormat}}</strong>\n    ({{t \"components.trial-notice.days-until-trial-end\" days=this.days}})\n    <a href=\"mailto:support@weldnote.com\">Please contact support</a>\n    <button\n      class=\"btn btn-xs {{this.buttonTypeClass}} m-l-md pull-right\"\n      type=\"button\"\n      {{on \"click\" this.closeNotice}}\n    ><FaIcon @icon=\"times-circle\" /></button>\n  </div>\n{{/if}}","moduleName":"weldnote/components/trial-notice.hbs","parseOptions":{"srcName":"weldnote/components/trial-notice.hbs"}});
import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import { reads } from '@ember/object/computed';
import Component from '@ember/component';
import { set, get, action, computed } from '@ember/object';

@classic
@classNames('trial-notice')
export default class TrialNotice extends Component {
  @service
  userSession;

  @reads('account.accountValidUntil')
  trialEnd;

  @reads('account.daysUntilEndOfTrial')
  days;

  showNotice = true;

  daysForWarning = 3;

  @computed('days')
  get boxTypeClass() {
    let days = get(this, 'days');
    if (parseInt(days) < get(this, 'daysForWarning')) {
      return 'alert-danger';
    }
    return 'alert-info';
  }

  @computed('days')
  get buttonTypeClass() {
    let days = get(this, 'days');
    if (parseInt(days) < get(this, 'daysForWarning')) {
      return 'btn-danger';
    }
    return 'btn-primary';
  }

  @action
  closeNotice() {
    set(this, 'showNotice', false);
  }
}
