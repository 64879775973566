import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';
import WeldingProcess from 'weldnote/models/welding-process';

export function calculateProcessLabels(
  process1: WeldingProcess | null = null,
  process2: WeldingProcess | null = null,
  process3: WeldingProcess | null = null
): {
  process1: string;
  process2: string;
  process3: string;
} {
  let process1Name = process1 && !isEmpty(process1) ? get(process1, 'shortDesignation') : '';
  let process2Name = process2 && !isEmpty(process2) ? get(process2, 'shortDesignation') : '';
  let process3Name = process3 && !isEmpty(process3) ? get(process3, 'shortDesignation') : '';
  if (!isEmpty(process1Name) && !isEmpty(process2Name) && !isEmpty(process3Name)) {
    if (process1Name === process2Name && process2Name === process3Name) {
      return {
        process1: `${process1Name} #1`,
        process2: `${process2Name} #2`,
        process3: `${process3Name} #3`,
      };
    } else if (process1Name === process2Name) {
      return {
        process1: `${process1Name} #1`,
        process2: `${process2Name} #2`,
        process3: process3Name,
      };
    } else if (process2Name === process3Name) {
      return {
        process1: process1Name,
        process2: `${process2Name} #1`,
        process3: `${process3Name} #2`,
      };
    } else {
      return {
        process1: process1Name,
        process2: process2Name,
        process3: process3Name,
      };
    }
  }
  if (!isEmpty(process1Name) && !isEmpty(process2Name)) {
    if (process1Name === process2Name) {
      return {
        process1: `${process1Name} #1`,
        process2: `${process2Name} #2`,
        process3: '',
      };
    } else {
      return {
        process1: process1Name,
        process2: process2Name,
        process3: '',
      };
    }
  }
  if (!isEmpty(process1Name)) {
    return {
      process1: process1Name,
      process2: '',
      process3: '',
    };
  }
  return {
    process1: '',
    process2: '',
    process3: '',
  };
}
