import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class LibrariesController extends Controller {
  @service
  userSession;

  @service
  router;

  get canEdit() {
    return this.userSession.canCreateProject && this.project.isActive;
  }

  get isNdtActive() {
    return this.router.isActive('project.libraries.ndt-report');
  }

  get isFillerMaterialActive() {
    return this.router.isActive('project.libraries.filler-material');
  }

  get isBaseMaterialActive() {
    return this.router.isActive('project.libraries.base-material');
  }

  get isLineClassActive() {
    return this.router.isActive('project.libraries.line-class');
  }

  get isPwhtActive() { 
    return this.router.isActive('project.libraries.pwht-report');
  }

  get isProjectDocumentActive() { 
    return this.router.isActive('project.libraries.document');
  }
}
