import { helper } from '@ember/component/helper';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';

export default helper(function formatWeldType([weldType], hash) {
  if (isEmpty(weldType)) {
    return '';
  }
  let { small } = hash;
  let industryCode = get(weldType, 'industryCode.code');
  if (industryCode === 'ASME' || industryCode === 'AWS') {
    let weldTypeCode = get(weldType, 'weldType');
    if (weldTypeCode === 'BW') {
      if (small) {
        return 'GW';
      } else {
        return 'GW - Groove Weld';
      }
    } else if (weldTypeCode === 'PJP BW') {
      if (small) {
        return 'PJP GW';
      } else {
        return 'PJP GW - Partial Joint Penetration Groove Weld';
      }
    } else if (weldTypeCode === 'CJP BW') {
      if (small) {
        return 'CJP GW';
      } else {
        return 'CJP GW - Complete Joint Penetration Groove Weld';
      }
    } else if (weldTypeCode === 'PP BW') {
      if (small) {
        return 'PP GW';
      } else {
        return 'PP GW - Partial Penetration Groove Weld';
      }
    }
  }
  if (small) {
    return `${get(weldType, 'weldType')}`;
  } else {
    return `${get(weldType, 'weldType')} - ${get(weldType, 'designation')}`;
  }
});
