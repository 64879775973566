import Component from '@glimmer/component';
import WelderCertificate from 'weldnote/models/welder-certificate';
import WopqApprovalRange from 'weldnote/src-weldnote/wopq/approval-ranges/wopq-approval-range';
import {
  WelderCertificateTestPieceValues,
  WelderOperatorTypes,
  WeldingOperatorSpecificWeldingDetails,
} from 'weldnote/src-weldnote/wopq/types';
import { service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface ModelWelderCertificateWeldingOperatorWeldingDetailApprovalRangeArgs {
  detail: WeldingOperatorSpecificWeldingDetails | null;
  certificate: WelderCertificate;
  approvalRange: WopqApprovalRange;
  process: string;
}

export default class ModelWelderCertificateWeldingOperatorWeldingDetailApprovalRange extends Component<ModelWelderCertificateWeldingOperatorWeldingDetailApprovalRangeArgs> {
  @service
  declare intl: IntlService;

  constructor(
    owner: unknown,
    args: ModelWelderCertificateWeldingOperatorWeldingDetailApprovalRangeArgs
  ) {
    super(owner, args);
  }

  get value(): string {
    if (this.args.detail) {
      let result = '';
      let detail = WelderOperatorTypes.weldingOperatorDetailFromString(this.args.detail);
      if (detail.isPresent()) {
        let detailValue = detail.get();
        let possibleProcess = WelderOperatorTypes.processFromString(this.args.process);
        let process = null;
        if (possibleProcess.isEmpty()) {
          return result;
        } else {
          process = possibleProcess.get();
        }
        let testPieces = new WelderCertificateTestPieceValues(this.args.certificate, process);
        let approvalRange = this.args.approvalRange.weldingDetailsForOperator(
          detailValue,
          testPieces
        );
        result = approvalRange
          .map((t) => {
            if (t === WeldingOperatorSpecificWeldingDetails.ARC) {
              return this.intl.t('lov.welding-detail-operator.arc');
            } else if (t === WeldingOperatorSpecificWeldingDetails.JOINT) {
              return this.intl.t('lov.welding-detail-operator.joint');
            } else if (t === WeldingOperatorSpecificWeldingDetails.NONE) {
              return this.intl.t('lov.welding-detail-operator.none');
            }
            return '';
          })
          .join('+');
        return result;
      }
    }
    return '';
  }
}
