import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';

@classic
export default class TestInspectionClassesRoute extends ListRoute {
  @service('weldnote-data')
  data;

  model() {
    return this.data.listAllTestInspectionClasses();
  }
}
