import EmberRouter from '@ember/routing/router';
import config from 'weldnote/config/environment';

const WeldnoteRouter = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL,
});

WeldnoteRouter.map(function () {
  this.route('login');
  this.route('loading');

  this.route('examining-bodies', { path: '/examining-bodies' });
  this.route('examining-body', { path: '/examining-bodies/:id' });
  this.route('examining-body.new', { path: '/examining-bodies/new' });

  this.route('base-materials');
  this.route('base-material', { path: '/base-materials/:id' });
  this.route('base-material.new', { path: '/base-materials/new' });

  this.route('welders');
  this.route('welder', { path: '/welders/:id' });
  this.route('welders-archived');

  this.route('fitters');
  this.route('fitter', { path: '/fitters/:id' });

  this.route('inspectors');
  this.route('inspector', { path: '/inspectors/:id' });

  this.route('companies');
  this.route('company', { path: '/companies/:id' });

  this.route('project-clients');
  this.route('project-client', { path: '/project-clients/:id' });
  this.route('project-client.new', { path: '/project-clients/new' });

  this.route('filler-materials');
  this.route('filler-material', { path: '/filler-materials/:id' });
  this.route('filler-material.new', { path: '/filler-materials/new' });

  this.route('projects');
  this.route('project', { path: '/projects/:project_id' }, function () {
    this.route('libraries', function () {
      this.route('ndt-report');
      this.route('filler-material');
      this.route('base-material');
      this.route('line-class');
      this.route('pwht-report');
      this.route('document');
    });
    this.route('drawings', function () {
      this.route('drawings');
      this.route('component-drawings');
    });
    this.route('components', function () {
      this.route('component', { path: '/:component_id' });
      this.route('new');
    });
    this.route('welds', function () {
      this.route('weld', { path: '/:id' });
      this.route('new');
    });
    this.route('welders', function () {
      this.route('welders');
      this.route('certificates');
    });
    this.route('wps');
  });
  this.route('project.new', { path: '/projects/new' });
  this.route('project-component', { path: 'project-component/:component_id' });
  this.route('project-weld', { path: 'project-weld/:weld_id' });

  //
  // Beginning of settings
  //

  this.route('settings');

  // Base Materials
  this.route('base-material-groups', { path: '/settings/base-material-groups' });
  this.route('base-material-group', { path: '/settings/base-material-groups/:id' });
  this.route('base-material-group.new', { path: '/settings/base-material-groups/new' });

  // Filler Materials
  this.route('filler-material-groups', { path: '/settings/filler-material-groups' });
  this.route('filler-material-group', { path: '/settings/filler-material-groups/:id' });
  this.route('filler-material-group.new', { path: '/settings/filler-material-groups/new' });

  this.route('filler-material-commercial-designations', {
    path: '/settings/filler-material-commercial-designations',
  });
  this.route('filler-material-commercial-designation', {
    path: '/settings/filler-material-commercial-designations/:id',
  });
  this.route('filler-material-commercial-designation.new', {
    path: '/settings/filler-material-commercial-designations/new',
  });

  // Gas
  this.route('gases', { path: '/settings/gases' });
  this.route('gas', { path: '/settings/gases/:id' });
  this.route('gas.new', { path: '/settings/gases/new' });

  this.route('gas-specifications', { path: '/settings/gas-specifications' });
  this.route('gas-specification', { path: '/settings/gas-specifications/:id' });
  this.route('gas-specification.new', { path: '/settings/gas-specifications/new' });

  this.route('gas-commercial-designations', { path: '/settings/gas-commercial-designations' });
  this.route('gas-commercial-designation', { path: '/settings/gas-commercial-designations/:id' });
  this.route('gas-commercial-designation.new', {
    path: '/settings/gas-commercial-designations/new',
  });

  // Flux
  this.route('fluxes', { path: '/settings/fluxes' });
  this.route('flux', { path: '/settings/fluxes/:id' });
  this.route('flux.new', { path: '/settings/fluxes/new' });

  this.route('flux-specifications', { path: '/settings/flux-specifications' });
  this.route('flux-specification', { path: '/settings/flux-specifications/:id' });
  this.route('flux-specification.new', { path: '/settings/flux-specifications/new' });

  this.route('flux-commercial-designations', { path: '/settings/flux-commercial-designations' });
  this.route('flux-commercial-designation', { path: '/settings/flux-commercial-designations/:id' });
  this.route('flux-commercial-designation.new', {
    path: '/settings/flux-commercial-designations/new',
  });

  // Testing
  this.route('destructive-testings', { path: '/settings/destructive-testings' });
  this.route('destructive-testing', { path: '/settings/destructive-testings/:id' });
  this.route('destructive-testing.new', { path: '/settings/destructive-testings/new' });

  this.route('non-destructive-testings', { path: '/settings/non-destructive-testings' });
  this.route('non-destructive-testing', { path: '/settings/non-destructive-testings/:id' });
  this.route('non-destructive-testing.new', { path: '/settings/non-destructive-testings/new' });

  this.route('test-stage-names', { path: '/settings/test-stage-names' });
  this.route('test-stage-name', { path: '/settings/test-stage-names/:id' });
  this.route('test-stage-name.new', { path: '/settings/test-stage-names/new' });

  this.route('test-locations', { path: '/settings/test-locations' });
  this.route('test-location', { path: '/settings/test-locations/:id' });
  this.route('test-location.new', { path: '/settings/test-locations/new' });

  // Pieces
  this.route('piping-pieces', { path: '/settings/piping-pieces' });
  this.route('piping-piece', { path: '/settings/piping-pieces/:id' });
  this.route('piping-piece.new', { path: '/settings/piping-pieces/new' });

  this.route('plate-pieces', { path: '/settings/plate-pieces' });
  this.route('plate-piece', { path: '/settings/plate-pieces/:id' });
  this.route('plate-piece.new', { path: '/settings/plate-pieces/new' });

  // Staff
  this.route('staffs', { path: '/settings/staff' });
  this.route('staff', { path: '/settings/staff/:id' });
  this.route('staff.new', { path: '/settings/staff/new' });

  this.route('staff-positions', { path: '/settings/staff-positions' });
  this.route('staff-position', { path: '/settings/staff-positions/:id' });
  this.route('staff-position.new', { path: '/settings/staff-positions/new' });

  // Welding Elements
  this.route('welding-processes', { path: '/settings/welding-processes' });
  this.route('welding-process', { path: '/settings/welding-processes/:id' });
  this.route('welding-process.new', { path: '/settings/welding-processes/new' });

  this.route('welding-positions', { path: '/settings/welding-positions' });
  this.route('welding-position', { path: '/settings/welding-positions/:id' });
  this.route('welding-position.new', { path: '/settings/welding-positions/new' });

  this.route('welding-details', { path: '/settings/welding-details' });
  this.route('welding-detail', { path: '/settings/welding-details/:id' });
  this.route('welding-detail.new', { path: '/settings/welding-details/new' });

  this.route('weld-types', { path: '/settings/weld-types' });
  this.route('weld-type', { path: '/settings/weld-types/:id' });
  this.route('weld-type.new', { path: '/settings/weld-types/new' });

  this.route('electrode-types', { path: '/settings/electrode-types' });
  this.route('electrode-type', { path: '/settings/electrode-types/:id' });
  this.route('electrode-type.new', { path: '/settings/electrode-types/new' });

  this.route('type-current-polarities', { path: '/settings/type-current-polarities' });
  this.route('type-current-polarity', { path: '/settings/type-current-polarities/:id' });
  this.route('type-current-polarity.new', { path: '/settings/type-current-polarities/new' });

  this.route('arc-transfer-modes', { path: '/settings/arc-transfer-modes' });
  this.route('arc-transfer-mode', { path: '/settings/arc-transfer-modes/:id' });
  this.route('arc-transfer-mode.new', { path: '/settings/arc-transfer-modes/new' });

  // Methods
  this.route('preparation-methods', { path: '/settings/preparation-methods' });
  this.route('preparation-method', { path: '/settings/preparation-methods/:id' });
  this.route('preparation-method.new', { path: '/settings/preparation-methods/new' });

  this.route('post-weld-treatment-methods', { path: '/settings/post-weld-treatment-methods' });
  this.route('post-weld-treatment-method', { path: '/settings/post-weld-treatment-methods/:id' });
  this.route('post-weld-treatment-method.new', {
    path: '/settings/post-weld-treatment-methods/new',
  });

  // Schematics
  this.route('groove-designs', { path: '/groove-designs' });
  this.route('groove-design', { path: '/groove-designs/:id' });
  this.route('groove-design.new', { path: '/groove-designs/new' });

  this.route('weld-layer-configurations', { path: '/weld-layer-configurations' });
  this.route('weld-layer-configuration', { path: '/weld-layer-configurations/:id' });
  this.route('weld-layer-configuration.new', { path: '/weld-layer-configurations/new' });

  // Welding Book
  this.route('welding-book-statuses', { path: '/settings/welding-book-status' });
  this.route('welding-book-status', { path: '/settings/welding-book-status/:id' });
  this.route('welding-book-status.new', { path: '/settings/welding-book-status/new' });

  // Standards
  this.route('pqr-standards', { path: '/settings/pqr-standards' });
  this.route('pqr-standard', { path: '/settings/pqr-standards/:id' });
  this.route('pqr-standard.new', { path: '/settings/pqr-standards/new' });

  this.route('construction-standards', { path: '/settings/construction-standards' });
  this.route('construction-standard', { path: '/settings/construction-standards/:id' });
  this.route('construction-standard.new', { path: '/settings/construction-standards/new' });

  this.route('welder-standards', { path: '/settings/welder-standards' });
  this.route('welder-standard', { path: '/settings/welder-standards/:id' });
  this.route('welder-standard.new', { path: '/settings/welder-standards/new' });

  this.route('certification-special-requirements', {
    path: '/settings/certification-special-requirements',
  });
  this.route('certification-special-requirement', {
    path: '/settings/certification-special-requirements/:id',
  });
  this.route('certification-special-requirement.new', {
    path: '/settings/certification-special-requirements/new',
  });

  this.route('miscellaneous-certificate-types', {
    path: '/settings/miscellaneous-certificate-types',
  });
  this.route('miscellaneous-certificate-type', {
    path: '/settings/miscellaneous-certificate-types/:id',
  });
  this.route('miscellaneous-certificate-type.new', {
    path: '/settings/miscellaneous-certificate-types/new',
  });

  //
  // End of Settings
  //

  this.route('pqrs');
  this.route('pqrs-archived');
  this.route('pqr', { path: '/pqrs/:id' });
  this.route('pqr.new', { path: '/pqrs/new' });
  this.route('pqr-view', { path: 'pqrs/view/:id' });

  this.route('welder-certificates');

  this.route('welder-certificate', { path: '/welder-certificates/:id' });
  this.route('welder-certificate.new', { path: '/welder-certificates/new' });
  this.route('welder-certificate-view', { path: 'welder-certificates/view/:id' });

  this.route('welder-certificates/internal/today');
  this.route('welder-certificates/internal/30days');
  this.route('welder-certificates/internal/expired');
  this.route('welder-certificates/external/today');
  this.route('welder-certificates/external/30days');
  this.route('welder-certificates/external/expired');
  this.route('welder-certificates/archived');

  this.route('miscellaneous-certificates', { path: '/miscellaneous-certificates' });
  this.route('miscellaneous-certificate', { path: '/miscellaneous-certificates/:id' });
  this.route('miscellaneous-certificate-duplicate', {
    path: '/miscellaneous-certificates/:id/duplicate',
  });
  this.route('miscellaneous-certificate.new', { path: '/miscellaneous-certificates/new' });

  this.route('miscellaneous-certificates/30days');
  this.route('miscellaneous-certificates/today');
  this.route('miscellaneous-certificates/expired');
  this.route('miscellaneous-certificates/archived');

  this.route('welding-procedure-specifications');
  this.route('welding-procedure-specification', { path: '/welding-procedure-specifications/:id' });
  this.route('welding-procedure-specification.new', {
    path: '/welding-procedure-specifications/new',
  });
  this.route('welding-procedure-specification-view', {
    path: '/welding-procedure-specifications/view/:id',
  });
  this.route('welding-procedure-specification-view-pass', {
    path: '/welding-procedure-specifications/view/:id/passes/:pass_id',
  });
  this.route('welding-procedure-specification.new-multiple', {
    path: '/welding-procedure-specifications/new-multiple',
  });
  this.route('welding-procedure-specification.new-from-pqr', {
    path: '/welding-procedure-specifications/new-from-pqr',
  });
  this.route('welding-procedure-specifications-archived');
  this.route('welding-procedure-specification-pwps-to-pqr', {
    path: '/welding-procedure-specifications/pqr/:id',
  });
  this.route('welding-procedure-specification-pwps-to-certificate', {
    path: '/welding-procedure-specifications/certificate/:id',
  });
  this.route('welding-procedure-specification-pwps-to-prequalified', {
    path: '/welding-procedure-specifications/pwps-to-prequalified/:id',
  });

  this.route('welding-procedure-specification-pwps-to-standard-wps', {
    path: '/welding-procedure-specifications/pwps-to-standard-wps/:id',
  });

  this.route('welding-books');
  this.route('welding-book', { path: '/welding-books/:id' });
  this.route('welding-book.new', { path: '/welding-books/new' });

  this.route('welding-maps');
  this.route('welding-map', { path: '/welding-maps/:id' }, function () {
    this.route('pqrMap');
    this.route('certificateMap');
    this.route('wpsMap');
  });
  this.route('welding-map.new', { path: '/welding-maps/new' });
  this.route('welding-map.pqr-map');

  this.route('user-settings');
  this.route('action-logs');
  this.route('logout');
  this.route('account-admin');

  // Libraries
  this.route('base-material-certificates');
  this.route('base-material-certificate', { path: '/base-material-certificates/:id' });
  this.route('base-material-certificate.new', { path: '/base-material-certificates/new' });

  this.route('filler-material-certificates');
  this.route('filler-material-certificate', { path: '/filler-material-certificates/:id' });
  this.route('filler-material-certificate.new', { path: '/filler-material-certificates/new' });

  this.route('ndt-report-certificates');
  this.route('ndt-report-certificate', { path: '/ndt-report-certificates/:id' });
  this.route('ndt-report-certificate.new', { path: '/ndt-report-certificates/new' });

  this.route('pwht-report-certificates');
  this.route('pwht-report-certificate', { path: '/pwht-report-certificates/:id' });
  this.route('pwht-report-certificate.new', { path: '/pwht-report-certificates/new' });

  this.route('general-drawings');
  this.route('general-drawing.new', { path: '/general-drawings/new' });
  this.route('general-drawing', { path: '/general-drawings/:id' });

  this.route('component-drawings');
  this.route('component-drawing.new', { path: '/component-drawings/new' });
  this.route('component-drawing', { path: '/component-drawings/:id' });

  this.route('test-inspection-classes');
  this.route('test-inspection-class.new', { path: '/test-inspection-classes/new' });
  this.route('test-inspection-class', { path: '/test-inspection-classes/:id' });

  this.route('project-documents');
  this.route('project-document.new', { path: '/project-documents/new' });
  this.route('project-document', { path: '/project-documents/:id' });
  this.route('welding-procedure-specifications-preliminary');
  this.route('projects-archived');
  this.route('verify-account', { path: 'verify-account/:token' });
  this.route('setup-account', { path: 'setup-account/:token' });
  this.route('help-center');
  this.route('ieis');
  this.route('ieis.new', { path: '/ieis/new' });
  this.route('ieis-edit', { path: '/ieis/:id' });
  this.route('ieis-archived');
  this.route('weld-sketch-design');
  this.route('weld-sketches');
  this.route('welding-books-archived');
});

export default WeldnoteRouter;
